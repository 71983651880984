import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import Card from "@material-ui/core/Card";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import background from "./Images/LoginPageBackground.webp";
import logo from "./Images/logo.png";
var fetch = require("node-fetch");

const useStyles = makeStyles((theme) => ({
  typographySty: {
    border: "1px solid #212121",
    borderRadius: "10px",
    background: "#1E00A5"
  },
  card:{
    margin: "4px",
    borderRadius: "4px",
    border: "1px solid #424242",
    background: "#F8FCFE"
  },
  // loginText:{
  //   // padding:"1px", 
  //   margin: "12px", 
  //   border: "solid 1px #1E00A5",
  //   fontFamily: "Garamond", 
  //   color: "#170564",
  //   textAlign: "center"
  // },
  loginText:{
    paddingLeft: "25%", 
    // margin: "12px",
    fontFamily: "Helvetica",
    fontWeight: "bold", 
    // color: "#170564",
    // textAlign: "center"
  },
  // kseText:{
  //   // margin: "12px", 
  //   fontFamily: "Helvetica",
  //   color: "#F7F6FB",
  //   textAlign: "left",
  //   fontSize: 40,
  //   letterSpacing: "0.2em",
  //   textShadow: "#0E0D11 30px 20px"
  // },
  kseText:{
    margin: "0px", 
    fontFamily: "Helvetica",
    color: "#4A99A1",
    textAlign: "center",
    fontSize: 60,
    // letterSpacing: "0.2em",
    // textShadow: "#0E0D11 30px 20px"
  },
  kseTextSmall:{
    margin: "0px", 
    fontFamily: "Helvetica",
    color: "#4A99A1",
    textAlign: "left",
    fontSize: 20,
  },
  textField:{
    margin: "20px",
    width: "30ch",    
    border: "solid 1px #e0e0e0",
    background: "#FFFAFA",
    borderRadius: "10px",
  },
  formControl:{
    borderRadius: "10px",
    border: "solid 1px #e0e0e0",
    background: "#FFFAFA", 
    width: "30ch"
  },
  // btn:{
  //   margin: "15px",
  //   color: "white",
  //   border: "solid 1px #455a64",
  //   backgroundColor: "#5124E1",      
  //   marginTop: "30px",
  //   marginBottom: "20px",
  //   fontFamily: "Garamond",
  //   fontSize: 14,
  //   width: "250px",
  //   textShadow: "Black 1px 1px"
  // }
  btn:{
    margin: "15px",
    color: "white",
    border: "solid 1px #4A99A1",
    backgroundColor: "#4A99A1",      
    marginTop: "30px",
    marginBottom: "20px",
    fontFamily: "Garamond",
    fontSize: 14,
    width: "50%",
    textShadow: "Black 1px 1px"
  }
  
}));

export default function Authentication(props) {  
  const classes = useStyles();
  const [kseRESTApi] = useState(props.kseRESTApi) //KFB main REST
  const [showLogin, setShowLogin] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const [error, setError] = useState(false);

  useEffect(()=>{
    // console.log("AUTH", props)
    let token = localStorage.getItem("token")
    if(token){
      // setShowLogin(true)
      // props.fetchUserProfile(token)
      console.log("TOKEN", token)
      fetchUserProfile(token)
    }
    else{
      setShowLogin(true)
      console.log("TOKEN NOT FOUND")
    }
  },[])

  async function fetchUserProfile(token) {
    // console.log("LOAD PROFILE")
    await fetch(
      kseRESTApi + "/api/users/GetUserInfo",
      {
        "headers": {"Authorization": "Bearer " + token},
        "method": "GET"
      }
    )
    .then((response) => {
      if(response.status === 200){
        console.log("TOKEN ALLOWED")
        props.fetchUserProfile(token)
      }
      else{
        console.log("LOAD PROFILE ERROR", response)
        setShowLogin(true)
        localStorage.removeItem("token")
      }
    })
    .catch(function (error){
      console.log("Authentication error: ", error)
    })
  }

  function handleLoginChange(event){
    // console.log("LOGIN", event.target.value)
    setUsername(event.target.value)
    setError(false)
  }
  function handlePasswordChange(event){
    // console.log("PASS", event.target.value)
    setPassword(event.target.value)
    setError(false)
  }
  function handleClickShowPassword(){
    // console.log("SH PASS", !showPassword)
    setShowPassword(!showPassword)
  }
  function handleRememberMeChange(event){
    // console.log("RM ME", event.target.checked)
    setRememberMe(event.target.checked)
  }
  async function LoginButtonClick() {
    let body = JSON.stringify({
          "userName": username,
          "password": password
        })
    await fetch(kseRESTApi + "/api/users/Login",
      {
        "headers": { "content-type": "application/json" },
        "method": "POST",
        "body": body
      }
    )
    .then(response => response.json())
    .then(async function(res){
        console.log("AUTH", res)
        if(res.isAuthSuccessful === true){
          // props.setToken(res.token)
          await props.fetchUserProfile(res.token)
          setUsername("")
          setPassword("")
          if(rememberMe === true){
            localStorage.setItem("token", res.token)
          }
        }
        else{
          setError(true)
        }
      }
    )
    .catch(function(er){
      console.log("ERR", er)
    })
  }

  function onKeyPressLogin(event){
    let code = event.charCode
    if(code === 13){
      // console.log("CODE", code)
      handleLoginChange(event)
      LoginButtonClick()
    }
  }
  function onKeyPressPassword(event){
    let code = event.charCode
    if(code === 13){
      // console.log("CODE", code)
      handlePasswordChange(event)
      LoginButtonClick()
    }
  }
  return (
    showLogin === true &&
    <Grid 
      container
      direction="row"
      justifyContent="space-around"
      alignItems="center"
    >
      <Grid item xs={6}>
        <div style={{height: "100vh"}}>
          <table width="100%">
            <tr>
              <td align="left" width="50%">
                <table style={{paddingTop: "100px", paddingLeft: "10%"}}>
                  <tr>
                    <td>
                      <img src={logo} alt="Logo" height={70} style={{paddingTop: "5px"}}/>
                    </td>
                    <td>
                      <table style={{paddingLeft: "5px"}}>
                        <tr>
                          <td className={classes.kseTextSmall}>KYRGYZ</td>
                        </tr>
                        <tr>
                          <td className={classes.kseTextSmall}>STOCK</td>
                        </tr>
                        <tr>
                          <td className={classes.kseTextSmall}>EXCHANGE</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td align="left" width="50%">
                <div className={classes.loginText}>
                  <h2>Вход в систему</h2>
                </div>
              </td>
            </tr>
            <tr>
              <td align="center" width="50%">
                <form align="center">
                  {error === true && <p style={{color: "red"}}>Не верное имя пользователя или пароль!</p>}
                  {/* username*/}
                  <div style={{paddingTop:"30px"}}>
                    <TextField
                      style={{width: "50%"}}
                      error={error}
                      size="small"
                      label="Имя пользователя"
                      variant="standard"
                      name="username"
                      id="username"
                      autoFocus={true}
                      // autoComplete={true}           
                      value={username}
                      onChange={handleLoginChange}
                      onKeyPress={onKeyPressLogin}
                      // className={classes.textField}
                    />
                  </div>
                  {/* password */}
                  <div style={{paddingTop:"30px"}}>
                    <TextField
                      style={{width: "50%"}}
                      error={error}
                      size="small"
                      label="Пароль"
                      variant="standard"
                      id="password"
                      // autoComplete={true}           
                      value={password}
                      onChange={handlePasswordChange}
                      onKeyPress={onKeyPressPassword}
                      // className={classes.textField}
                      name="password"
                      type="password"
                    />
                  </div>
                </form>
                {/* Remember Me */}
                <div align="center">
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position">
                      <FormControlLabel                  
                        value="end"
                        control={
                          <Checkbox 
                            size="small" 
                            style={{color:"#4A99A1"}} 
                            onChange={handleRememberMeChange}
                            checked={rememberMe}
                          />
                        }
                        label="Запомнить меня"
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </div>
                <div align="center" >
                  <Button
                    variant="contained" 
                    onClick={()=> LoginButtonClick()}
                    className={classes.btn}
                  >
                    <b>Вход</b>
                  </Button>
                </div>
              </td>              
            </tr>
          </table>
          </div>
      </Grid>
      <Grid item xs={6}>
        <div style={{background: "#ECECEC", height: "100vh"}}>
          <Grid 
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <img src={logo} alt="Logo" height={300} style={{paddingTop: "200px"}}/>
            </Grid>
            <Grid item xs={12}>
              <table>
                <tr>
                  <td className={classes.kseText}>KYRGYZ</td>
                </tr>
                <tr>
                  <td className={classes.kseText}>STOCK</td>
                </tr>
                <tr>
                  <td className={classes.kseText}>EXCHANGE</td>
                </tr>
              </table> 
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  )
}
