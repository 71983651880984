import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from "@material-ui/core/Table"; // Material ui table for usual form
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import GridSelect from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Drawer from "@mui/material/Drawer";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// Accordion
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// DOCS
import Card from '@material-ui/core/Card';
// import Modal from "@material-ui/core/Modal";
// Icons
// import FirstPageIcon from '@material-ui/icons/FirstPage';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
// import ReplayIcon from '@material-ui/icons/Replay';
import CloseIcon from '@material-ui/icons/Close';
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import GetAppIcon from '@material-ui/icons/GetApp';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { IoMdClose } from 'react-icons/io';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CancelIcon from '@mui/icons-material/Cancel';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
// import { BsArrowDown } from 'react-icons/bs';
import { BsSearch } from 'react-icons/bs';
import { IoMdAdd } from 'react-icons/io';
import { IoMdArrowBack } from 'react-icons/io';
import { BiEdit } from 'react-icons/bi';
import { FaRegSave } from 'react-icons/fa';
import { MdOutlineDeleteOutline } from 'react-icons/md';

// Form components
import TextField from '@material-ui/core/TextField';
import Select from 'react-select'; // https://react-select.com/home
import Checkbox from '@material-ui/core/Checkbox';

// Custom components
import Buttons from "../configuration/Buttons.json";
import GridFormButtons from '../configuration/GridFormButtons.json';
// Libraries
import swal from 'sweetalert'; // https://sweetalert.js.org/guides/
import Draggable from 'react-draggable';
// Style
import "../styles/generalStyles.css";

var fetch = require('node-fetch');
var generator = require('generate-password');
var moment = require('moment');
// const invert = require('invert-color'); // https://www.npmjs.com/package/invert-color

function FloatFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      decimalSeparator={"."}
      thousandSeparator={" "}
      isNumericString
    />
  );
}
FloatFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}
function IntegerFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={" "}
      isNumericString
    />
  );
}
IntegerFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}
const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  importFile: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  inputFile: {
    display: 'none',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  resizeForm: {
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    border: "1px solid #2B4D80",
    display: "flex",
    // backgroundColor:"#F5F5F5",
    "flex-flow": "column",
    // width: "80%",
    // height: "80%",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "150px",
    background: "white",
    position: 'absolute',
  },
  paper: {
    width: "100%",
    height: 150,
    overflow: 'auto',
  },
}))
// ToolTip стили (подсказки)
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white
  },
  tooltip: {
    backgroundColor: "white",
    border: "1px solid #7A7A7A",
    fontFamily: 'Helvetica',
    color: "#7A7A7A",
    fontSize: 13,
    textAlign: "center",
    boxShadow: "4px 4px 4px 3px #C3C3C3"
  }
}))

export default (props) => {
  const classes = useStyles()
  const [kseRESTApi] = useState(props.kseRESTApi)
  const [token] = useState(props.token)
  const [taskType] = useState(props.taskType)
  const [taskVariables, setTaskVariables] = useState(props.taskVariables)
  const [userProfile] = useState(props.userProfile)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [Form, setForm] = useState(null)
  const [buttons, setButtons] = useState([])
  const [gridForm, setGridForm] = useState(null)
  const [gridFormButtons, setGridFormButtons] = useState([])
  const [selectedDoc, setSelectedDoc] = useState(null)
  const [formType, setFormType] = useState("edit")

  const [isSearchForm, setIsSearchForm] = useState(null)
  const [accordionExpanded, setAccordionExpanded] = useState(false)
  const [docList, setDocList] = useState(null)
  const [filteredDocList, setFilteredDocList] = useState(null)
  const [initialDocList, setInitialDocList] = useState(null)
  const [enumData, setEnumData] = useState({})
  const [enumOptions, setEnumOptions] = useState({})
  const [selectedOptions, setSelectedOptions] = useState([])
  const [gridFormEnumData, setGridFormEnumData] = useState({})
  const [fieldValue, setFieldValue] = useState({})
  const [focusField, setFocusField] = useState(null)
  const [updateState, setUpdateState] = useState(null)

  // CHECKBOX LIST
  const [checkBoxList, setCheckBoxList] = useState({ marketSectors: [] })
  const [checkedCheckBoxListItems, setCheckedCheckBoxListItems] = useState({ marketSectors: [] })

  const [sortedColumn, setSortedColumn] = useState("null")
  const [sortedColumnOrder, setSortedColumnOrder] = useState(1)
  // PAGINATION
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [showSnackBar, setShowSnackBar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState("")

  // DOCS
  const [showSavedDocuments, setShowSavedDocuments] = useState(false)
  const [showAttachedDocuments, setShowAttachedDocuments] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [attachedImgs, setAttachedImgs] = useState([])
  const [attachedImgsFiles, setAttachedImgsFiles] = useState([])
  const [attachedDocs, setAttachedDocs] = useState([])
  const [Blobs, setBlobs] = useState([])
  const [selectedImg, setSelectedImg] = useState({})
  const [savedImgs, setSavedImgs] = useState([])
  const [savedImagesFiles, setSavedImagesFiles] = useState([])
  const [savedDocs, setSavedDocs] = useState([])
  const [buttonFiles, setButtonFiles] = useState(null)

  const [selectedItem, setSelectedItem] = useState({ id: null })
  const [anchorElMarketSectors, setAnchorElMarketSectors] = useState(null)
  const openMenuMarketSectors = Boolean(anchorElMarketSectors)

  const [anchorElRepoObligations, setAnchorElRepoObligations] = useState(null)
  const openMenuRepoObligations = Boolean(anchorElRepoObligations)

  // const [position, setPosition] = useState(props.taskVariables.position)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [tableKey, setTableKey] = useState(null)
  const [mainFormGridId] = useState(getUUID())

  const [openSearch, setOpenSearch] = useState(false)
  const [accordionState, setAccordionState] = useState({})

  const handleDocListItemContextMenu = (event) => {
    event.preventDefault()
    if (taskType === "showMarketEditForm" || taskType === "showSectorsSearchForm") {
      setAnchorElMarketSectors(event.currentTarget)
    }
    if (taskType === "showRepoObligationsSearchForm") {
      setAnchorElRepoObligations(event.currentTarget)
    }
    props.setComponentIndex(props.getComponentIndex() + 1)
  }
  const handleCloseMenu = () => {
    setAnchorElMarketSectors(null)
    setAnchorElRepoObligations(null)
  }
  function handleExpandAccordion(name) {
    setAccordionState({ ...accordionState, [name]: !accordionState[name] })
  }

  // Set data from props to state of component
  useEffect(async () => {
    setButtonFiles(getUUID())
    console.log("MAINFORM PROPS", props)
    let form = await props.fetchForm(taskVariables.formDefId)
    setForm(form)
    let newAcState = {}
    for (let s = 0; s < form.sections.length; s++) {
      if (s === 0) {
        if (taskType === "showMarketEditForm" || taskType === "showMarketSectorEditForm" || taskType === "showMarketSectorGroupEditForm" ||
          taskType === "showSectorEditForm" || taskType === "showSectorGroupEditForm" ||
          taskType === "showGroupEditForm"
        ) {
          newAcState[form.sections[s].name] = false
        }
        else {
          newAcState[form.sections[s].name] = true
        }
      }
      else {
        newAcState[form.sections[s].name] = false
      }
      setAccordionState(newAcState)
    }
    let enums = await props.getEnumDataByForm(form)
    setEnumData(enums)
    console.log("MAIN EN", enums)
    setEnumOptions(await props.createEnumOptions(enums))

    setButtons(Buttons[userProfile.role.name][taskVariables.buttons])
    // console.log("BUTTONS", taskVariables.buttons, Buttons[userProfile.role.name][taskVariables.buttons])
    if (taskVariables.gridFormDefId !== null) {
      let gForm = await props.fetchForm(taskVariables.gridFormDefId)
      setGridForm(gForm)
      let gEnums = await props.getEnumDataByForm(gForm)
      setGridFormEnumData(gEnums)
      let docL = await props.fetchDocList(taskVariables.docListApi)
      console.log("DOCL MAIN", docL, taskVariables.docListApi)
      let sortName = "id"
      let sortType = "Int"
      for (let s = 0; s < form.sections.length; s++) {
        for (let c = 0; c < form.sections[s].contents.length; c++) {
          let n = form.sections[s].contents[c].name
          if (n === "name" || n === "description") {
            sortName = n
            sortType = "Text"
          }
        }
      }
      console.log("")
      // if(taskType === "showMainRefSearchForm"){
      //   sortName = "description"
      //   sortType = "Text"
      // }
      let sortedDocList = docL.sort(dynamicSort(sortName, 1, sortType))
      setFilteredDocList(sortedDocList)
      setInitialDocList(sortedDocList)
      fetchBySize(0, 9, sortedDocList)
      setGridFormButtons(GridFormButtons[userProfile.role.name][taskVariables.gridFormButtons])
      console.log("G BUTTONS", taskVariables.gridFormButtons, GridFormButtons[userProfile.role.name][taskVariables.gridFormButtons])
      if (taskType === "showMarketEditForm" || taskType === "showMarketSectorEditForm" || taskType === "showMarketSectorGroupEditForm"
        || taskType === "showSectorEditForm" || taskType === "showSectorGroupEditForm"
        || taskType === "showGroupEditForm"
      ) {
        setIsSearchForm(false)
      }
      else {
        setIsSearchForm(true)
      }
    }
    else { setIsSearchForm(false) }
    if (taskVariables.selectedDoc !== undefined && taskVariables.selectedDoc !== null) {
      createCheckBoxListOptions(form, enums, taskVariables.selectedDoc)
      setSelectedDoc(taskVariables.selectedDoc)
      setFieldValue(taskVariables.selectedDoc)
      console.log("SDOC", taskVariables.selectedDoc)
    }
    if (taskVariables.formType !== undefined && taskVariables.formType !== null) {
      setFormType(taskVariables.formType)
    }
    if (props.taskVariables.position !== undefined && props.taskVariables.position !== null) {
      setPosition(props.taskVariables.position)
    }

    if (props.taskType === "showContractCreatingForm") {
      setShowAttachedDocuments(true)
    }
    if (props.taskType === "showContractEditForm") {
      console.log("SDOCS", props.savedDocs)
      for (let d = 0; d < props.savedDocs.length; d++) {
        let extension = props.savedDocs[d].extension
        if (extension === ".png") {
          convertBase64ToFile("data:image/png;base64," + props.savedDocs[d].content, props.savedDocs[d].fileName)
        }
        else if (extension === ".jpeg" || extension === ".jpg") {
          convertBase64ToFile("data:image/jpeg;base64," + props.savedDocs[d].content, props.savedDocs[d].fileName)
        }
        else if (extension === ".doc") {
          convertBase64ToFile("data:application/msword;base64," + props.savedDocs[d].content, props.savedDocs[d].fileName)
        }
        else if (extension === ".docx") {
          convertBase64ToFile("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64," + props.savedDocs[d].content, props.savedDocs[d].fileName)
        }
        else if (extension === ".xlsx") {
          convertBase64ToFile("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + props.savedDocs[d].content, props.savedDocs[d].fileName)
        }
        else if (extension === ".pdf") {
          convertBase64ToFile("data:application/pdf;base64," + props.savedDocs[d].content, props.savedDocs[d].fileName)
        }
      }
      setShowAttachedDocuments(true)
      setShowSavedDocuments(true)
    }
    setUpdateState(getUUID())
  }, [])
  useEffect(async () => {
    if (props.updateRepoOrderList !== null) {
      let docL = await props.fetchDocList(taskVariables.docListApi)
      // console.log("DOCL MAIN", docL, taskVariables.docListApi)
      let sortedDocList = docL.sort(dynamicSort("id", 1, "Int"))
      setFilteredDocList(sortedDocList)
      setInitialDocList(sortedDocList)
      fetchBySize(0, 9, sortedDocList)
      setTableKey(getUUID())
    }
  }, [props.updateRepoOrderList])
  const handleCheckboxChange = (event) => {
    console.log("CHBX", event.target.name, event.target.checked)
    setFieldValue({ ...fieldValue, [event.target.name]: event.target.checked })
  }
  // Custom ToolTip
  function BootstrapTooltip(props) {
    const clsToolTip = useStylesBootstrap();
    return <Tooltip arrow classes={clsToolTip} {...props} />;
  }
  // Integer input handler
  const handleIntChange = (event) => {
    // console.log("EVENT", event.target.name, event.target.value, fieldValue)
    if (event.target.value !== "") {
      let stringNum = event.target.value.toString().replace(/ /g, '')
      let int = parseInt(stringNum)
      setFieldValue({ ...fieldValue, [event.target.name]: int })
    }
    else {
      setFieldValue({ ...fieldValue, [event.target.name]: null })
    }
  }
  // Float input handler
  const handleFloatChange = (event) => {
    // console.log("FLOAT CHANGE", event.target.name, event.target.value, fieldValue)
    // setFieldValue({ ...fieldValue, [event.target.name]: parseFloat(event.target.value) })
    if (event.target.value !== "") {
      let stringNum = event.target.value.toString().replace(/ /g, '')
      let float = parseFloat(stringNum)
      setFieldValue({ ...fieldValue, [event.target.name]: float })
      // console.log("FLOAT CHANGE", event.target.name, float)
    }
    else {
      setFieldValue({ ...fieldValue, [event.target.name]: event.target.value })
    }
  }
  // Text input handler
  function handleTextChange(event) {
    // console.log("EVENT", event.target.name, event.target.value)
    // fieldValue[event.target.name] = event.target.value
    // setFieldValue(fieldValue)
    // console.log("FIELDVALUE", fieldValue)
    setFieldValue({ ...fieldValue, [event.target.name]: event.target.value })
  }
  // function handleSelectChange(option){
  //   fieldValue[option.name] = option.value
  //   console.log("OPT", option.name, option.value)
  //   var updateSelectedOptions = selectedOptions.slice()
  //   let noSuchOption = true
  //   for(let i=0; i<updateSelectedOptions.length; i++){
  //     if(option.name === updateSelectedOptions[i].name){
  //       updateSelectedOptions[i] = option
  //       noSuchOption = false
  //       setSelectedOptions(updateSelectedOptions)
  //       break
  //     }
  //     else {
  //       noSuchOption = true
  //     }
  //   }
  //   if(noSuchOption === true){
  //     updateSelectedOptions.push(option)
  //     setSelectedOptions(updateSelectedOptions)
  //   }
  // }
  function handleSelectChange(option) {
    console.log("OPT", option, fieldValue)
    if (option.name === "marketId") {
      let newFields = { ...fieldValue }
      let newEnumOptions = { ...enumOptions }
      let newSelOptions = { ...selectedOptions }

      newFields.marketId = option.value
      newSelOptions.marketId = option

      if (enumData.marketSectorId !== undefined) {
        newFields.marketSectorId = null
        newEnumOptions.marketSectorId = option.value === null ? returnAllSectors(enumData) : filterMarketSectors(option.value, enumData)
        newSelOptions.marketSectorId = {
          label: "Пусто",
          name: "marketSectorId",
          value: null
        }
      }
      if (enumData.instrumentGroupId !== undefined) {
        newFields.instrumentGroupId = null
        newEnumOptions.instrumentGroupId = []
        newSelOptions.instrumentGroupId = {
          label: "Пусто",
          name: "instrumentGroupId",
          value: null
        }
      }
      setFieldValue(newFields)
      setEnumOptions(newEnumOptions)
      setSelectedOptions(newSelOptions)
    }
    else if (option.name === "marketSectorId") {
      let newFields = { ...fieldValue }
      let newEnumOptions = { ...enumOptions }
      let newSelOptions = { ...selectedOptions }

      newFields.marketSectorId = option.value
      newSelOptions.marketSectorId = option

      if (enumData.instrumentGroupId !== undefined) {
        newFields.instrumentGroupId = null
        newEnumOptions.instrumentGroupId = option.value === null ? returnAllGroups(enumData) : filterSectorGroups(option.value, enumData)
        newSelOptions.instrumentGroupId = {
          label: "Пусто",
          name: "instrumentGroupId",
          value: null
        }

      }
      setFieldValue(newFields)
      setEnumOptions(newEnumOptions)
      setSelectedOptions(newSelOptions)
    }
    else {
      setSelectedOptions({ ...selectedOptions, [option.name]: option })
      setFieldValue({ ...fieldValue, [option.name]: option.value })
      console.log("OPT", option, fieldValue)
    }
  }
  function handleDateTimeChange(event) {
    let date = moment(event.target.value).format()
    setFieldValue({ ...fieldValue, [event.target.name]: date })
    // console.log("DATE CHANGE", date)
  }
  function getContentType(key) {
    for (let s = 0; s < Form.sections.length; s++) {
      for (let c = 0; c < Form.sections[s].contents.length; c++) {
        if (key === Form.sections[s].contents[c].name) {
          return Form.sections[s].contents[c].type
        }
      }
    }
  }
  // CheckBoxList 
  function createCheckBoxListOptions(form, enums, sdoc) {
    console.log("CH BX L OPTS", form, enums)
    let newCheckBoxList = {}
    let newCheckedCheckBoxListItems = {}
    for (let s = 0; s < form.sections.length; s++) {
      for (let c = 0; c < form.sections[s].contents.length; c++) {
        if (form.sections[s].contents[c].type === "CheckBoxList") {
          let cName = form.sections[s].contents[c].name
          let newCheckBoxItems = [0]
          let newChItems = []

          // create checkBoxList from enums
          for (let f = 0; f < enums[cName].length; f++) {
            newCheckBoxItems.push(enums[cName][f].id)

          }
          newCheckBoxList[cName] = newCheckBoxItems

          if (sdoc !== undefined && sdoc !== null) {
            console.log("SET CH LIST SDOC", cName, sdoc[cName])
            if (sdoc[cName] !== null && sdoc[cName] !== undefined) {
              newChItems = sdoc[cName]
            }
          }
          newCheckedCheckBoxListItems[cName] = newChItems
        }
      }
    }
    setCheckBoxList(newCheckBoxList)
    setCheckedCheckBoxListItems(newCheckedCheckBoxListItems)
  }
  function getCheckBoxList(items, name) {
    // console.log("G CH L", items, name)
    return (
      <Paper className={classes.paper}>
        <List dense component="div" role="list">
          {items.map((value) => {
            const labelId = `checkbox-list-item-${value}-label`;
            return (
              <ListItem size="small" style={{ padding: 0, paddingLeft: 6 }} key={value} role="listitem" button onClick={checkBoxListItemClick(value, name)}>
                <Checkbox
                  checked={checkedCheckBoxListItems[name].indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                  style={{ color: "#337E86", padding: 2 }}
                />
                <ListItemText id={labelId} primary={getCheckBoxListItemLabel(name, value)} />
              </ListItem>
            )
          })}
          <ListItem />
        </List>
      </Paper>
    )
  }
  function getCheckBoxListItemLabel(name, value) {
    if (value === 0) {
      return "Все"
    }
    for (let l = 0; l < enumData[name].length; l++) {
      if (enumData[name][l].id === value) {
        return enumData[name][l].label
      }
    }
  }
  const checkBoxListItemClick = (value, name) => () => {
    let currentIndex = checkedCheckBoxListItems[name].indexOf(value)
    let newChecked = [...checkedCheckBoxListItems[name]]
    console.log("IND", currentIndex, value)
    if (currentIndex === -1) { // true
      if (value === 0) { // Add all
        newChecked = checkBoxList[name]
        // console.log("ADD ALL", value, name, checkBoxList[name])
      }
      else { // Add selected one
        newChecked.push(value)
      }
    }
    else { // false
      if (value === 0) { // Remove all
        newChecked = []
        console.log("REM ALL", value, name)
      }
      else { // Remove selected one
        newChecked.splice(currentIndex, 1)
      }
    }

    // if (currentIndex === -1) {
    //   newChecked.push(value)
    // } else {
    //   newChecked.splice(currentIndex, 1)
    // }
    console.log("CH", newChecked)
    setCheckedCheckBoxListItems({ ...checkedCheckBoxListItems, [name]: newChecked })
  }

  function filterMarketSectors(marketId, enums) {
    let newSectors = [{
      "value": null,
      "label": "Пусто",
      "name": "marketSectorId"
    }]
    for (let i = 0; i < enums["marketSectorId"].length; i++) {
      if (enums["marketSectorId"][i].market === marketId) {
        newSectors.push({
          "value": enums["marketSectorId"][i].id,
          "label": enums["marketSectorId"][i].label,
          "name": "marketSectorId"
        })
      }
    }
    return newSectors
  }
  function returnAllSectors(enums) {
    let newSectors = [{
      "value": null,
      "label": "Пусто",
      "name": "marketSectorId"
    }]
    for (let i = 0; i < enums["marketSectorId"].length; i++) {
      newSectors.push({
        "value": enums["marketSectorId"][i].id,
        "label": enums["marketSectorId"][i].label,
        "name": "marketSectorId"
      })
    }
    return newSectors
  }

  function filterSectorGroups(marketSectorId, enums) {
    let newGroups = [{
      "value": null,
      "label": "Пусто",
      "name": "instrumentGroupId"
    }]
    for (let i = 0; i < enums["instrumentGroupId"].length; i++) {
      if (enums["instrumentGroupId"][i].sector === marketSectorId) {
        newGroups.push({
          "value": enums["instrumentGroupId"][i].id,
          "label": enums["instrumentGroupId"][i].label,
          "name": "instrumentGroupId"
        })
      }
    }
    return newGroups
  }
  function returnAllGroups(enums) {
    let newGroups = [{
      "value": null,
      "label": "Пусто",
      "name": "instrumentGroupId"
    }]
    for (let i = 0; i < enums["instrumentGroupId"].length; i++) {
      newGroups.push({
        "value": enums["instrumentGroupId"][i].id,
        "label": enums["instrumentGroupId"][i].label,
        "name": "instrumentGroupId"
      })
    }
    return newGroups
  }
  // filter docList by filled parameters
  function filterDocList(fetchFrom, fetchTo, Data, fields) {
    var newDocList = []
    if (Object.keys(fields).length === 0) {
      newDocList = Data
    }
    else {
      for (let i = 0; i < Data.length; i++) {
        let match = false
        for (let key in fields) {
          if (fields[key] === null || fields[key] === "" || fields[key] === undefined) {
            match = true
          }
          else {
            if (Data[i][key] !== null) {
              let contentType = getContentType(key)
              if (contentType === "Text") {
                try {
                  let searchField = fields[key].toLowerCase()
                  let dataField = Data[i][key].toLowerCase()
                  let includeSearch = dataField.includes(searchField)
                  if (includeSearch === true) {
                    match = true
                  }
                  else {
                    match = false
                    break
                  }
                }
                catch (er) {
                  console.log("ERR", er)
                  match = true
                }
              }
              else if (contentType === "Int" || contentType === "Float") {
                if (fields[key] >= 0 || fields[key] < 0) {
                  let searchField = fields[key].toString()
                  let dataField = Data[i][key].toString()
                  let includeSearch = dataField.includes(searchField)
                  if (includeSearch === true) {
                    match = true
                  }
                  else {
                    match = false
                    break
                  }
                }
                else { match = true }
              }
              else if (contentType === "Enum") {

                if (fields[key] === Data[i][key]) {
                  // console.log("ENUMS", key, fields[key], Data[i][key])
                  match = true
                }
                else {
                  match = false
                  break
                }
              }
              else if (contentType === "DateTime") {
                let searchField = moment(fields[key]).format('YYYY-MM-DD')
                let dataField = moment(Data[i][key]).format('YYYY-MM-DD')
                // console.log("DATES", searchField, dataField, searchField.includes(dataField))
                if (searchField === "NaN-NaN-NaN") {
                  match = true
                }
                else {
                  if (searchField.includes(dataField) === true) {
                    match = true
                  }
                  else {
                    match = false
                    break
                  }
                }
              }
              else if (contentType === "Bool") {
                if (fields[key] === Data[i][key]) {
                  match = true
                }
                else {
                  match = false
                  break
                }
              }
            }
            else {
              match = false
              break
            }
          }
        }
        if (match === true) {
          newDocList.push(Data[i])
        }
      }
    }
    setFilteredDocList(newDocList)
    fetchBySize(fetchFrom, fetchTo, newDocList)
  }
  // get rows amount of filtered docList by size
  function fetchBySize(fetchFrom, fetchTo, Data) {
    // console.log("fetchFrom", "fetchTo")
    let newDocList = []
    for (let i = fetchFrom; i <= fetchTo; i++) {
      if (Data[i] !== undefined) {
        newDocList.push(Data[i])
      }
    }
    // setFilteredDocList(Data)
    setDocList(newDocList)
  }
  // Attached documents
  async function pushNewBlob(f) {
    // console.log("WRITING BLOBS")
    let docUrl = URL.createObjectURL(f)
    fetch(docUrl)
      .then(res => res.blob())
      .then(blob => {
        // blob.name = f.name
        var blobToBase64 = function (f, cb) {
          let reader = new FileReader()
          reader.onload = function () {
            // converts blob to base64
            var dataUrl = reader.result
            var base64 = dataUrl.split(",")[1]
            cb(base64)
          }
          reader.readAsDataURL(f)
        }
        blobToBase64(f, async function (base64) {
          // encode blobs to send to socket
          let newBlob = { "name": f.name, "blob": base64, "size": f.size }
          Blobs.push(newBlob)
          setBlobs(Blobs)
          // console.log("BLOBS", Blobs)
        })
      })
  }
  function handleAttachFile(e) {
    let selectedFiles = e.target.files
    // console.log("A DOCS", selectedFiles)
    if (selectedFiles && selectedFiles[0]) {
      for (let i = 0; i < selectedFiles.length; i++) {
        if (selectedFiles[i].type === "application/pdf" ||
          selectedFiles[i].type === "application/msword" ||
          selectedFiles[i].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          selectedFiles[i].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
          attachedDocs.push(selectedFiles[i])
          setAttachedDocs(attachedDocs)
          setUpdateState(getUUID())
          // console.log("A DOCS", attachedDocs)
        }
        else {
          let reader = new FileReader()
          reader.onload = async function (e) {
            // push new images to list and show in UI
            let image = { item: <img name={selectedFiles[i].name} height="150" width="auto" src={e.target.result} onClick={() => attachedImgClick(selectedFiles[i].name)} /> }
            attachedImgs.push(image)
            setAttachedImgs(attachedImgs)
            setUpdateState(getUUID())
            // console.log("IMGLIST", attachedImgs)
          }
          reader.readAsDataURL(selectedFiles[i])
          attachedImgsFiles.push(selectedFiles[i])
          // pushAttachedImage(selectedFiles[i])
          setAttachedImgsFiles(attachedImgsFiles)
          // console.log("A IMGS", attachedImgsFiles)
        }
        pushNewBlob(selectedFiles[i])
      }
    }

  }
  async function attachedImgClick(name) {
    for (let l = 0; l < attachedImgs.length; l++) {
      if (attachedImgs[l].item.props.name === name) {
        console.log("IMG", attachedImgs[l])
        setSelectedImg({
          name: attachedImgs[l].item.props.name,
          height: "700",
          width: "auto",
          src: attachedImgs[l].item.props.src
        })
      }
    }
    setOpenModal(true)
    let newId = getUUID()
    setUpdateState(newId)
  }
  function attachedDocsList() {
    // console.log("A DOCS", attachedDocs)
    let selDocsList = []
    for (let i = 0; i < attachedDocs.length; i++) {
      selDocsList.push(
        <Card style={{ margin: 3, backgroundColor: "#E2E2E2", paddingLeft: 20, minWidth: "500px" }}>
          <table>
            <tr>
              <td style={{ color: "#1B2CE8", fontWeight: "bold", width: "95%" }}>
                {attachedDocs[i].name + " "}{"(" + Math.round(attachedDocs[i].size / 1000) + ")" + "КБ"}
              </td>
              <td>
                <IconButton>
                  <CloseIcon style={{ fontSize: "medium", color: "black" }} onClick={() => deleteAttachedDoc(attachedDocs[i].name)} />
                </IconButton>
              </td>
            </tr>
          </table>
        </Card>)

    }
    return (selDocsList)
  }
  function attachedImgsList() {
    var imgs = []
    for (let l = 0; l < attachedImgs.length; l++) {
      imgs.push(
        <TableBody>
          <TableRow>
            <TableCell>
              {attachedImgs[l].item}
            </TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell width="20%">
              {attachedImgs[l].item.props.name}
            </TableCell>
          </TableRow> */}
          <TableRow>
            <TableCell align="center">
              <IconButton onClick={() => deleteAttachedImage(attachedImgs[l].item.props.name)}>
                <DeleteForeverIcon fontSize="Large" style={{ color: "black", margin: 0 }} />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      )
    }
    if (Object.keys(imgs).length !== 0) {
      return (
        <Grid container direction="row" justify="center" alignItems="flex-start">
          {imgs}
        </Grid>
      )
    }
  }
  function deleteAttachedDoc(name) {
    let newDocList = []
    for (let i = 0; i < attachedDocs.length; i++) {
      if (name !== attachedDocs[i].name) {
        newDocList.push(attachedDocs[i])
      }
      else {
        deleteBlob(attachedDocs[i].name)
      }
    }
    // attachedDocs = newDocList
    setAttachedDocs(newDocList)
    // setUpdateState(getUUID())
    // console.log("FILES", newDocList)
  }
  function deleteAttachedImage(name) {
    let newImgList = []
    let newImgFilesList = []
    for (let i = 0; i < attachedImgs.length; i++) {
      if (name !== attachedImgs[i].item.props.name) {
        newImgList.push(attachedImgs[i])
        newImgFilesList.push(attachedImgsFiles[i])
      }
      else {
        deleteBlob(attachedImgs[i].item.props.name)
      }
    }
    // deleteBlob(name)
    // attachedImgs = newImgList
    setAttachedImgs(newImgList)
    // attachedImgsFiles = newImgFilesList
    setAttachedImgsFiles(newImgFilesList)
    // setUpdateState(getUUID())
    // console.log("IMGS", newImgFilesList)
  }
  function deleteBlob(name) {
    console.log("NAME", name)
    let newBlobs = []
    for (let b = 0; b < Blobs.length; b++) {
      if (Blobs[b].name !== undefined) {
        if (name !== Blobs[b].name) {
          newBlobs.push(Blobs[b])
        }
        else {
          console.log("DEL", Blobs[b].name)
        }
      }
    }
    console.log("BLOBS", newBlobs)
    setBlobs(newBlobs)
  }
  // Previosly saved documents
  function convertBase64ToFile(dataurl, fileName) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    let convFile = new File([u8arr], fileName, { type: mime })
    handleAttachSavedFile(convFile)
    // console.log("CONV FILE", convFile)
  }
  function handleAttachSavedFile(file) {
    // console.log("FILE", file)
    if (file.type === "application/pdf" || file.type === "application/msword" ||
      file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      savedDocs.push(file)
      setSavedDocs(savedDocs)
    }
    else {
      savedImagesFiles.push(file)
      setSavedImagesFiles(savedImagesFiles)
      pushSavedImage(file)
    }
    // setUpdateState(getUUID())
  }
  async function pushSavedImage(f) {
    let reader = new FileReader()
    reader.onload = async function (e) {
      // push new images to list and show in UI
      let image = { item: <img name={f.name} height="150" width="auto" src={e.target.result} onClick={() => savedImgClick(f.name)} /> }
      savedImgs.push(image)
      // console.log("IMGLIST", savedImgs)
      setSavedImgs(savedImgs)
      setUpdateState(getUUID())
    }
    reader.readAsDataURL(f)
  }
  async function savedImgClick(name) {
    for (let l = 0; l < savedImgs.length; l++) {
      if (savedImgs[l].item.props.name === name) {
        console.log("IMG", savedImgs[l])
        setSelectedImg({
          name: savedImgs[l].item.props.name,
          height: "700",
          width: "auto",
          src: savedImgs[l].item.props.src
        })
      }
    }
    setOpenModal(true)
    let newId = getUUID()
    setUpdateState(newId)
  }
  function savedDocsList() {
    // console.log("SAVEDOCS", savedDocs)
    let savedDocsList = []
    for (let i = 0; i < savedDocs.length; i++) {
      savedDocsList.push(
        <Card style={{ margin: 3, backgroundColor: "#E2E2E2", paddingLeft: 20, minWidth: "500px" }}>
          <table>
            <tr>
              <td style={{ color: "#1B2CE8", fontWeight: "bold", width: "95%" }}>
                {savedDocs[i].name + " "}{"(" + Math.round(savedDocs[i].size / 1000) + ")" + "КБ"}
              </td>
              <td>
                <IconButton>
                  <CloseIcon style={{ fontSize: "medium", color: "black" }} onClick={() => deleteSavedDoc(savedDocs[i].name)} />
                </IconButton>
              </td>
              <td>
                <IconButton
                  component="a"
                  href={URL.createObjectURL(savedDocs[i])}
                  download={savedDocs[i].name}
                >
                  <GetAppIcon style={{ fontSize: "medium", color: "black" }} />
                </IconButton>
              </td>
            </tr>
          </table>
        </Card>)
      // console.log("FILE", savedDocs[i])
    }
    return (savedDocsList)
  }
  function savedImgsList() {
    // console.log("SAVEDIMGS", savedImgs)
    var imgs = []
    for (let l = 0; l < savedImgs.length; l++) {
      let blob = converFileToBlob(savedImgs[l].item.props.src)
      imgs.push(
        <TableBody>
          <TableRow>
            <TableCell>
              {savedImgs[l].item}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">
              <IconButton onClick={() => deleteSavedImage(savedImgs[l].item.props.name)}>
                <DeleteForeverIcon fontSize="Large" style={{ color: "black", margin: 0 }} />
              </IconButton>
              <IconButton
                component="a"
                href={URL.createObjectURL(blob)}
                download={savedImgs[l].item.props.name}
              >
                <GetAppIcon style={{ fontSize: "medium", color: "black" }} />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      )
    }
    if (Object.keys(imgs).length !== 0) {
      return (
        <Grid container direction="row" justify="center" alignItems="flex-start">
          {imgs}
        </Grid>
      )
    }
  }
  function converFileToBlob(src) {
    var byteString = atob(src.split(',')[1])
    var ab = new ArrayBuffer(byteString.length)
    var ia = new Uint8Array(ab)
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ab], { type: 'image/jpeg' })
  }
  function deleteSavedDoc(name) {
    swal({
      text: "Вы действительно хотите удалить этот файл?",
      icon: "warning",
      buttons: { ok: "Да", cancel: "Отмена" },
    })
      .then((click) => {
        if (click === "ok") {
          let newDocList = []
          for (let i = 0; i < savedDocs.length; i++) {
            if (name !== savedDocs[i].name) {
              newDocList.push(savedDocs[i])
            }
          }
          setSavedDocs(newDocList)
          let commandJson = {
            commandType: "deleteSavedDoc",
            userId: userProfile.userId,
            directory: selectedDoc.files_directory,
            fileName: name
          }
          props.sendFieldValues(commandJson)
        }
      })
  }
  function deleteSavedImage(name) {
    swal({
      text: "Вы действительно хотите удалить этот файл?",
      icon: "warning",
      buttons: { ok: "Да", cancel: "Отмена" },
    })
      .then((click) => {
        if (click === "ok") {
          let newImgList = []
          for (let i = 0; i < savedImgs.length; i++) {
            if (name !== savedImgs[i].item.props.name) {
              newImgList.push(savedImgs[i])
            }
          }
          setSavedImgs(newImgList)
          // setUpdateState(getUUID())
          let commandJson =
          {
            commandType: "deleteSavedDoc",
            userId: userProfile.userId,
            directory: selectedDoc.files_directory,
            fileName: name
          }
          props.sendFieldValues(commandJson)
        }
      })
  }
  // Pagination functions
  function KeyboardArrowFirstClick() {
    if (page !== 1) {
      setPage(1)
      fetchBySize(0, size - 1, filteredDocList)
    }
    else {
      setSnackBarMessage("Вы на первой странице!")
      setShowSnackBar(true)
    }
  }
  function KeyboardArrowLeftClick(page) {
    if (page !== 1) {
      var prevPage = page - 1
      setPage(prevPage)
      let fetchFrom = ((prevPage - 1) * size) //10
      let fetchTo = (size * prevPage) - 1
      fetchBySize(fetchFrom, fetchTo, filteredDocList)
    }
    else {
      setSnackBarMessage("Вы на первой странице!")
      setShowSnackBar(true)
    }
  }
  function KeyboardArrowRightClick(page) {
    // console.log("RIGHT", filteredDocList.length, size)
    if (filteredDocList.length <= size * page) {
      // console.log("NO DATA")
      setSnackBarMessage("Больше нет данных!")
      setShowSnackBar(true)
    }
    else {
      setPage(page + 1)
      let fetchFrom = (size * page)
      let fetchTo = ((page + 1) * size) - 1
      fetchBySize(fetchFrom, fetchTo, filteredDocList)
    }
  }
  function handleChangeRowsPerPage(event) {
    setSize(event.target.value)
    setPage(1)
    fetchBySize(0, event.target.value - 1, filteredDocList)
  }
  // function GoToPage() {
  //   console.log("GO")
  //   let fetchFrom = (page * size - 1) - size
  //   let fetchTo = page * size - 1
  //   fetchBySize(fetchFrom, fetchTo, filteredDocList)
  // }
  function GoToPage(p) {
    setPage(p)
    if (p === 1) {
      fetchBySize(0, size - 1, filteredDocList)
    }
    else {
      let fetchFrom = p * size - size
      let fetchTo = p * size - 1
      fetchBySize(fetchFrom, fetchTo, filteredDocList)
    }
  }
  function handlePageChange(event) {
    setPage(event.target.value)
  }
  function handleCloseSnackBar() {
    setShowSnackBar(false)
  }
  function getPageAmount() {
    let pagesFloat = (filteredDocList.length) / size
    let mathRoundOfPages = Math.round(pagesFloat)
    if (pagesFloat > mathRoundOfPages) {
      return mathRoundOfPages + 1
    }
    else {
      return mathRoundOfPages
    }
  }
  function getPages(from, to) {
    let pArr = []
    for (let i = from; i <= to; i++) {
      if (i < Math.floor(filteredDocList.length / size) + 1) {
        pArr.push(
          getPage(i)
        )
      }
    }
    return pArr
  }
  function getPage(num) {
    return (
      <td>
        <div
          style={{
            cursor: "pointer",
            fontSize: 14,
            verticalAlign: "middle",
            textAlign: "center",
            height: "22px",
            width: "22px",
            color: page === num ? "white" : "black",
            background: page === num ? "#4FA4AD" : "white"
          }}
          onClick={() => GoToPage(num)}
        >
          <div style={{ marginTop: "2px", marginRight: "1px" }}>{num}</div>
        </div>
      </td>
    )
  }
  // random UUID generator
  function getUUID() {
    const uuid = require("uuid")
    return uuid.v1()
  }
  // random numbers generator
  function keyGen(length) {
    var password = generator.generate({
      length: length,
      numbers: true
    })
    return password
  }
  function swAllert(text, icon) {
    return (
      swal({
        text: text,
        icon: icon,
        buttons: { ok: "Ок" },
      })
    )
  }
  // Collect data to save doc
  function getFieldValuesSaveDoc() {
    let docToSave = {}
    for (let s = 0; s < Form.sections.length; s++) {
      for (let c = 0; c < Form.sections[s].contents.length; c++) {
        let fieldName = Form.sections[s].contents[c].name
        if (Form.sections[s].contents[c].type === "Bool" && fieldValue[fieldName] === undefined) {
          docToSave[fieldName] = false
        }
        else if (Form.sections[s].contents[c].type === "CheckBoxList") {
          docToSave[fieldName] = checkedCheckBoxListItems[fieldName]
        }
        else {
          docToSave[fieldName] = fieldValue[fieldName]
        }
      }
    }
    return docToSave
  }
  // Collect data to update doc
  function getFieldValuesUpdateDoc() {
    let docToUpdate = {}
    // docToUpdate["createdAt"] = selectedDoc.createdAt
    for (let s = 0; s < Form.sections.length; s++) {
      for (let c = 0; c < Form.sections[s].contents.length; c++) {
        let fieldName = Form.sections[s].contents[c].name
        if (Form.sections[s].contents[c].type === "Bool" && (fieldValue[fieldName] === undefined || fieldValue[fieldName] === null)) {
          docToUpdate[fieldName] = false
        }
        else {
          docToUpdate[fieldName] = fieldValue[fieldName]
        }
      }
    }
    return docToUpdate
  }
  //Проверка наличие полей для обязательного заполнения в форме
  function checkForRequieredFields() {
    let checkedSuccessfuly = null
    for (let s = 0; s < Form.sections.length; s++) {
      for (let c = 0; c < Form.sections[s].contents.length; c++) {
        let fieldName = Form.sections[s].contents[c].name
        if (Form.sections[s].contents[c].required === true) {
          if (fieldValue[fieldName] === undefined || fieldValue[fieldName] === null ||
            fieldValue[fieldName] === "NaN.NaN.NaN" || fieldValue[fieldName] === "") {
            checkedSuccessfuly = false
            swAllert("Внимание заполните поле \"" + Form.sections[s].contents[c].label + "\"", "warning")
            return checkedSuccessfuly
          }
          else {
            checkedSuccessfuly = true
          }
        }
        else {
          checkedSuccessfuly = true
        }
      }
    }
    return checkedSuccessfuly
  }
  // Simple Requests
  async function getRequest(api) {
    console.log("GET", api)
    let res = await fetch(kseRESTApi + api,
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
        "method": "GET"
      }
    )
      .then(response => response.json())
      .then(function (res) {
        console.log("RES", res)
        if (res.isSuccess !== true) {
          props.callErrorToast(res.errors[0])
        }
        return res
      })
    return res
  }
  async function putRequest(api, body) {
    let res = await fetch(
      kseRESTApi + api,
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
        "method": "PUT",
        "body": body
      }
    )
      .then(response => response.json())
      .then(function (res) {
        console.log("PUT", res)
        if (res.isSuccess !== true) {
          props.callErrorToast(res.errors[0])
        }
        return res
      })
    return res
  }
  async function postRequest(api, body) {
    let res = await fetch(kseRESTApi + api,
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
        "method": "POST",
        "body": body
      }
    )
      .then(response => response.json())
      .then(function (res) {
        if (res.isSuccess !== true) {
          props.callErrorToast(res.errors[0])
        }
        return res
      })
    return res
  }
  async function deleteRequest(api) {
    let res = await fetch(
      kseRESTApi + api,
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
        "method": "DELETE"
      }
    )
      .then(response => response.json())
      .then(function (res) {
        if (res.isSuccess !== true) {
          props.callErrorToast(res.errors[0])
        }
        return res
      })
    return res
  }

  async function buttonClick(buttonName, item) {
    console.log("BUT", buttonName)
    if (buttonName === "findDocument") {
      // console.log("findDocument")
      if (openSearch === true) {
        filterDocList(0, size - 1, initialDocList, fieldValue)
      }
      setOpenSearch(!openSearch)
    }
    //Назад к главному справочнику
    else if (buttonName === "backToMainRef") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        taskType: "showMainRefSearchForm",
        position: position,
        component: "mainForm",
        formDefId: "2aeadc9c-99f6-48fc-a2b3-a47c0670b109",
        buttons: "MainRefSearchBtns",
        gridFormDefId: "5fe6c5f6-ca17-4415-9d7d-57aed52cfad1",
        gridFormButtons: "MainRefGridBtns",
        docListApi: "/api/Directory/Gets"
      }
      props.setNewTask(taskVars)
      console.log("button backToMainRef: ", taskVars)
    }
    // Открыть список выбранного справочника
    else if (buttonName === "showRefSearchForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: item.search_form,
        buttons: "RefSearchBtns",
        gridFormDefId: item.grid_form,
        gridFormButtons: "RefGridBtns",
        docListApi: item.controller,
        creating_form: item.creating_form,
        search_form: item.search_form,
        edit_form: item.edit_form,
        grid_form: item.grid_form,
        controller: item.controller
      }
      props.setNewTask(taskVars)
      console.log("button showRefSearchForm: ", taskVars)

      // let taskVars = {
      //   formDefId: item.search_form,
      //   buttons: "RefSearchBtns",
      //   gridFormDefId: item.grid_form,
      //   gridFormButtons: "RefGridBtns",
      //   docListApi: item.controller,
      //   creating_form: item.creating_form,
      //   search_form: item.search_form,
      //   edit_form: item.edit_form,
      //   grid_form: item.grid_form,
      //   controller: item.controller
      // }
      // props.setNewTask("showRefSearchForm", taskVars)
      // console.log("button showRefSearchForm: ", taskVars)   
    }
    else if (buttonName === "showEditRef") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: taskVariables.edit_form,
        buttons: "RefEditBtns",
        selectedDoc: item,
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null,
        creating_form: taskVariables.creating_form,
        search_form: taskVariables.search_form,
        edit_form: taskVariables.edit_form,
        grid_form: taskVariables.grid_form,
        controller: taskVariables.controller
      }
      props.setNewTask(taskVars)
      console.log("button showEditRef: ", taskVars)
      // let taskVars = {
      //   formDefId: taskVariables.edit_form,
      //   buttons:"RefEditBtns", 
      //   selectedDoc: item,        
      //   gridFormDefId: null,
      //   gridFormButtons: null,
      //   docListApi: null,
      //   creating_form: taskVariables.creating_form,
      //   search_form: taskVariables.search_form,
      //   edit_form: taskVariables.edit_form,
      //   grid_form: taskVariables.grid_form,
      //   controller: taskVariables.controller
      // }
      // props.setNewTask("showEditRef", taskVars)
      // console.log("button showEditRef: ", taskVars)
    }
    else if (buttonName === "createRef") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: taskVariables.creating_form,
        buttons: "RefSaveBtns",
        creating_form: taskVariables.creating_form,
        search_form: taskVariables.search_form,
        edit_form: taskVariables.edit_form,
        grid_form: taskVariables.grid_form,
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null,
        selectedDoc: item,
        controller: taskVariables.controller,
      }
      props.setNewTask(taskVars)
      console.log("button createRef: ", taskVars)
    }
    else if (buttonName === "backToRefSearchForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: taskVariables.search_form, //"66fe97c9-cc89-46b0-ad64-af18c3e62419"
        buttons: "RefSearchBtns",
        gridFormDefId: taskVariables.grid_form,
        gridFormButtons: "RefGridBtns",
        docListApi: taskVariables.controller,
        creating_form: taskVariables.creating_form,
        search_form: taskVariables.search_form,
        edit_form: taskVariables.edit_form,
        grid_form: taskVariables.grid_form,
        controller: taskVariables.controller
      }
      props.setNewTask(taskVars)
      // console.log("button backToRefSearchForm: ", taskVars)   
    }
    else if (buttonName === "updateRef") {
      let updateBody = getFieldValuesUpdateDoc()
      console.log("UPD", updateBody)
      let res = await putRequest(taskVariables.controller + "?id=" + selectedDoc.id, JSON.stringify(updateBody))
      if (res.isSuccess === true) {
        props.callSuccessToast("Справочник обновлен!")
        buttonClick("backToRefSearchForm")
      }
    }
    //Сохранения спр
    else if (buttonName === "saveRef") {
      let saveBody = getFieldValuesSaveDoc()
      let res = await postRequest(taskVariables.controller, JSON.stringify(saveBody))
      if (res.isSuccess === true) {
        props.callSuccessToast("Справочник сохранен!")
        buttonClick("backToRefSearchForm")
      }
      console.log("button saveRef: ", taskVariables.controller)
    }
    //Удалить данные из спр
    else if (buttonName === "deleteRef") {
      swal({
        text: "Вы действительно хотите удалить этот рынок?",
        icon: "warning",
        buttons: { ok: "Да", cancel: "Отмена" },
      })
        .then(async (click) => {
          if (click === "ok") {
            let res = await deleteRequest(taskVariables.controller + "?id=" + selectedDoc.id)
            if (res.isSuccess === true) {
              props.callSuccessToast("Документ удален!")
              buttonClick("backToRefSearchForm")
            }
          }
        })

    }

    // CONTRACTS
    else if (buttonName === "showContractEditForm") {
      let taskVars = {
        key: getUUID(),
        component: "mainForm",
        position: position,
        formDefId: "c9b47a46-5b47-4112-b6cd-2a115de250f6",
        buttons: "ContractsEditBtns",
        selectedDoc: item,
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null
      }
      props.fetchSavedFiles(item.filesDirectory)
      props.setNewTask(taskVars)
      console.log("button showEditRef: ", taskVars)
    }
    else if (buttonName === "updateContract") {
      let updateBody = getFieldValuesUpdateDoc()
      updateBody.filesDirectory = selectedDoc.filesDirectory
      // console.log("BODY", updateBody)
      let res = await putRequest("/api/Contracts?id=" + selectedDoc.id, JSON.stringify(updateBody))
      if (res.isSuccess === true) {
        props.callSuccessToast("Договор обновлен!")
        let taskVars = {
          key: taskVariables.key,
          component: "mainForm",
          position: position,
          formDefId: "d8746abe-738f-4c36-865c-4aa18ec11a25",
          buttons: "ContractsSearchBtns",
          gridFormDefId: "97ecfa3d-5ac1-44a2-81be-f42d05d7b517",
          gridFormButtons: "ContractsGridBtns",
          docListApi: "/api/Contracts"
        }
        props.saveFilesToDir(selectedDoc.filesDirectory, Blobs)
        props.setNewTask(taskVars)
        props.setSavedDocs([])
      }
    }
    else if (buttonName === "showContractCreatingForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "115fa70f-4833-408f-9576-e870ad1c718d",
        buttons: "ContractsCreateBtns",
        selectedDoc: null,
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null
      }
      props.setNewTask(taskVars)
      props.setSavedDocs([])
      console.log("button " + buttonName, taskVars)
    }
    else if (buttonName === "saveContract") {
      let saveBody = getFieldValuesSaveDoc()
      let dir = getUUID()
      saveBody.filesDirectory = dir
      let res = await postRequest("/api/Contracts", JSON.stringify(saveBody))
      if (res.isSuccess === true) {
        props.callSuccessToast("Договор сохранен!")
        props.saveFilesToDir(dir, Blobs)
        props.setSavedDocs([])
      }
    }
    else if (buttonName === "backToContractsSearchForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "d8746abe-738f-4c36-865c-4aa18ec11a25",
        buttons: "ContractsSearchBtns",
        gridFormDefId: "97ecfa3d-5ac1-44a2-81be-f42d05d7b517",
        gridFormButtons: "ContractsGridBtns",
        docListApi: "/api/Contracts"
      }
      props.setNewTask(taskVars)
      props.setSavedDocs([])
      console.log("button showEditRef: ", taskVars)
    }
    // TRADING ACCOUNTS
    else if (buttonName === "showTradingAccountViewForm") {
      let taskVars = {
        key: getUUID(),
        component: "mainForm",
        position: position,
        formDefId: "05e8a787-8894-4b7f-8efd-65b0b1955603",
        buttons: "TradingAccountViewBtns",
        formType: "view",
        selectedDoc: item,
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "showTradingAccountEditForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "05e8a787-8894-4b7f-8efd-65b0b1955603",
        buttons: "TradingAccountEditBtns",
        formType: "edit",
        selectedDoc: taskVariables.selectedDoc,
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "createTradingAccount") {
      let taskVars = {
        key: getUUID(),
        component: "mainForm",
        position: position,
        formDefId: "de7b713e-5acb-4fc0-9998-408f489b0c44",
        buttons: "TradingAccountCreateBtns",
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null,
      }
      props.setNewTask(taskVars)
      // console.log(taskVars)
    }
    else if (buttonName === "saveTradingAccount") {
      let checkResult = checkForRequieredFields()
      if (checkResult === true) {
        let saveBody = getFieldValuesSaveDoc()
        let res = await postRequest("/api/Accounts/AddNewAccount", JSON.stringify(saveBody))
        if (res.isSuccess === true) {
          props.callSuccessToast("Торговый счет создан!")
          props.closeTask(taskVariables.key)
          // let taskVars = {
          //   key: taskVariables.key,
          //   component: "mainForm",
          //   position: position,
          //   formDefId: "27799e9b-fdae-48ed-9d3b-f038b60e0918",
          //   buttons: "TradingAccountsSearchBtns",
          //   gridFormDefId: "633103b1-9c22-4bee-ba34-fe4983be5931",
          //   gridFormButtons: "TradingAccountsGridBtns",
          //   docListApi: "/api/Accounts/ViewAccounts"
          // }
          // props.setNewTask(taskVars)
        }
      }
    }
    else if (buttonName === "updateTradingAccount") {
      let checkResult = checkForRequieredFields()
      if (checkResult === true) {
        let updateBody = getFieldValuesUpdateDoc()
        let res = await putRequest("/api/Accounts/UpdateAccountData/" + selectedDoc.id, JSON.stringify(updateBody))
        // console.log("UPDATE ACC", res)
        if (res.isSuccess === true) {
          props.callSuccessToast("Торговый счет обновлен!")
          props.closeTask(taskVariables.key)
          // let taskVars = {
          //   key: taskVariables.key,
          //   component: "mainForm",
          //   position: position,
          //   formDefId: "27799e9b-fdae-48ed-9d3b-f038b60e0918",
          //   buttons: "TradingAccountsSearchBtns",
          //   gridFormDefId: "633103b1-9c22-4bee-ba34-fe4983be5931",
          //   gridFormButtons: "TradingAccountsGridBtns",
          //   docListApi: "/api/Accounts/ViewAccounts"
          // }
          // props.setNewTask(taskVars)
        }
      }
    }
    else if (buttonName === "backToTradingAccountsSearchForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "27799e9b-fdae-48ed-9d3b-f038b60e0918",
        formType: "view",
        buttons: "TradingAccountsSearchBtns",
        gridFormDefId: "633103b1-9c22-4bee-ba34-fe4983be5931",
        gridFormButtons: "TradingAccountsGridBtns",
        docListApi: "/api/Accounts/ViewAccounts"
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "blockTradingAccount") {

      let res = await getRequest("/api/Accounts/DisableAccount?accountId=" + selectedDoc.id)
      if (res.isSuccess === true) {
        props.callSuccessToast("Счет заблокирован!")
      }
    }
    else if (buttonName === "unblockTradingAccount") {
      let res = await getRequest("/api/Accounts/EnableAccount?accountId=" + selectedDoc.id)
      if (res.isSuccess === true) {
        props.callSuccessToast("Счет разблокирован!")
      }
    }
    else if (buttonName === "showTradingAccountPositions") {
      let taskVars = {
        key: getUUID(),
        component: "mainForm",
        position: position,
        formDefId: "a160735b-1ed9-443a-a6c7-2f8cf73a940f",
        buttons: "TradingAccountPositionsSearchBtns",
        gridFormDefId: selectedDoc.abase === "1" ? "0f2d137c-44a8-4035-859a-322afc2120f2" : "3a815a43-7221-42d7-a4fd-622a6e5e5984",
        gridFormButtons: "TradingAccountPositionsGridBtns",
        docListApi: "/api/Accounts/ViewInstruments?accountId=" + selectedDoc.id,
        account: selectedDoc,
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "backToTradingAccountEditForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "05e8a787-8894-4b7f-8efd-65b0b1955603",
        formType: "view",
        buttons: "TradingAccountEditBtns",
        selectedDoc: taskVariables.account,
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null
      }
      props.setNewTask(taskVars)
    }

    // CURRENCY ACCOUNTS

    else if (buttonName === "showCurrencyAccountViewForm") {
      let taskVars = {
        key: getUUID(),
        component: "mainForm",
        position: position,
        formDefId: "05e8a787-8894-4b7f-8efd-65b0b1955603",
        buttons: "CurrencyAccountViewBtns",
        formType: "view",
        selectedDoc: item,
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "showCurrencyAccountEditForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "05e8a787-8894-4b7f-8efd-65b0b1955603",
        buttons: "CurrencyAccountEditBtns",
        formType: "edit",
        selectedDoc: item,
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "createCurrencyAccount") {
      let taskVars = {
        key: getUUID(),
        component: "mainForm",
        position: position,
        formDefId: "de7b713e-5acb-4fc0-9998-408f489b0c44",
        formType: "edit",
        buttons: "CurrencyAccountCreateBtns",
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null,
      }
      props.setNewTask(taskVars)
      // console.log(taskVars)
    }
    else if (buttonName === "saveCurrencyAccount") {
      let checkResult = checkForRequieredFields()
      if (checkResult === true) {
        let saveBody = getFieldValuesSaveDoc()
        let res = await postRequest("/api/Accounts/AddNewAccount", JSON.stringify(saveBody))
        if (res.isSuccess === true) {
          props.callSuccessToast("Денежный счет создан!")
          props.closeTask(taskVariables.key)
          // let taskVars = {
          //   key: taskVariables.key,
          //   component: "mainForm",
          //   position: position,
          //   formDefId: "27799e9b-fdae-48ed-9d3b-f038b60e0918",
          //   buttons: "CurrencyAccountsSearchBtns",
          //   gridFormDefId: "633103b1-9c22-4bee-ba34-fe4983be5931",
          //   gridFormButtons: "CurrencyAccountsGridBtns",
          //   docListApi: "/api/Accounts/ViewCurrencyAccounts"
          // }
          // props.setNewTask(taskVars)
        }
      }
    }
    else if (buttonName === "updateCurrencyAccount") {
      let checkResult = checkForRequieredFields()
      if (checkResult === true) {
        let updateBody = getFieldValuesUpdateDoc()
        let res = await putRequest("/api/Accounts/UpdateAccountData/" + selectedDoc.id, JSON.stringify(updateBody))
        if (res.isSuccess === true) {
          props.callSuccessToast("Денежный счет обновлен")
          // let taskVars = {
          //   key: taskVariables.key,
          //   component: "mainForm",
          //   position: position,
          //   formDefId: "27799e9b-fdae-48ed-9d3b-f038b60e0918",
          //   buttons: "CurrencyAccountsSearchBtns",
          //   gridFormDefId: "633103b1-9c22-4bee-ba34-fe4983be5931",
          //   gridFormButtons: "CurrencyAccountsGridBtns",
          //   docListApi: "/api/Accounts/ViewCurrencyAccounts"
          // }
          // props.setNewTask(taskVars)
        }
      }
    }
    else if (buttonName === "backToCurrencyAccountsSearchForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "27799e9b-fdae-48ed-9d3b-f038b60e0918",
        buttons: "CurrencyAccountsSearchBtns",
        gridFormDefId: "633103b1-9c22-4bee-ba34-fe4983be5931",
        gridFormButtons: "CurrencyAccountsGridBtns",
        docListApi: "/api/Accounts/ViewCurrencyAccounts"
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "blockCurrencyAccount") {
      let res = await getRequest("/api/Accounts/DisableAccount?accountId=" + selectedDoc.id)
      if (res.isSuccess === true) {
        props.callSuccessToast("Счет заблокирован!")
      }
    }
    else if (buttonName === "unblockCurrencyAccount") {
      let res = await getRequest("/api/Accounts/EnableAccount?accountId=" + selectedDoc.id)
      if (res.isSuccess === true) {
        props.callSuccessToast("Счет разблокирован!")
      }
    }
    else if (buttonName === "showCurrencyAccountPositions") {
      let taskVars = {
        key: getUUID(),
        component: "mainForm",
        position: position,
        formDefId: "a160735b-1ed9-443a-a6c7-2f8cf73a940f",
        buttons: "CurrencyAccountPositionsSearchBtns",
        gridFormDefId: "0f2d137c-44a8-4035-859a-322afc2120f2", // "3a815a43-7221-42d7-a4fd-622a6e5e5984"
        gridFormButtons: "CurrencyAccountPositionsGridBtns",
        docListApi: "/api/Accounts/ViewCurrencies?accountId=" + selectedDoc.id,
        account: selectedDoc
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "backToCurrencyAccountEditForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "05e8a787-8894-4b7f-8efd-65b0b1955603",
        buttons: "CurrencyAccountEditBtns",
        selectedDoc: taskVariables.account,
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null
      }
      props.setNewTask(taskVars)
    }

    // MARKET SECTORS CURRENCIES ACCOUNTS
    else if (buttonName === "showMSCurrAccountViewForm") {
      let taskVars = {
        key: getUUID(),
        component: "mainForm",
        position: position,
        formDefId: "8947b8c7-8ae5-4ea5-a30a-6e77e0604beb",
        formType: "view",
        buttons: "CurrAccountViewBtns",
        selectedDoc: item,
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "backToMSCurrAccountsSearchForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "0eb33e42-27e5-420f-aed1-e88f862e2fbf",
        buttons: "CurrAccountsSearchBtns",
        gridFormDefId: "71c28d30-67dc-42d9-865f-5a724f1d3c92",
        gridFormButtons: "MSCurrAccountsGridBtns",
        docListApi: "/api/AccountPositions/ViewCurrencies"
      }
      console.log("MSCURR", taskVars)
      props.setNewTask(taskVars)
    }

    // MARKETS Management
    else if (buttonName === "showManageSectorsSearchForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        // type: buttonName,
        taskType: buttonName,
        position: position,
        formDefId: "eadfc587-fef1-4a84-a5a2-fd8bc86a966f",
        buttons: "ManageSectorsSearchFormBtns",
        selectedDoc: null,
        gridFormDefId: "073c4354-0b8b-4d7d-ae99-b0bb8a026270",
        gridFormButtons: "ManageSectorsGridBtns",
        docListApi: "/api/ManageMarkets/MarketSectors?marketId=" + item.id,
        marketId: item.id,
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "backToManageMarketsSearchForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "6002de36-198a-449e-b4bf-e623dd5962d6",
        buttons: "ManageMarketsSearchFormBtns",
        gridFormDefId: "60308605-426a-4755-a5b7-82f1c2c6ffa5",
        gridFormButtons: "ManageMarketsGridBtns",
        docListApi: "/api/ManageMarkets/Markets"
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "showManageInstrumentGroupsSearchForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "5ac6cda7-32c6-4291-8528-dcb254cb7cae",
        buttons: "ManageInstrumentGroupsSearchFormBtns",
        selectedDoc: null,
        gridFormDefId: "a3a3fe23-a29d-47a0-9752-77f98d42af4e",
        gridFormButtons: "ManageInstrumentGroupsGridBtns",
        docListApi: "/api/ManageMarkets/InstrumentGroups?marketSectorId=" + item.id,
        marketId: taskVariables.marketId,
        marketSectorId: item.id
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "backToManageSectorsSearchForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "eadfc587-fef1-4a84-a5a2-fd8bc86a966f",
        buttons: "ManageSectorsSearchFormBtns",
        selectedDoc: null,
        gridFormDefId: "073c4354-0b8b-4d7d-ae99-b0bb8a026270",
        gridFormButtons: "ManageSectorsGridBtns",
        docListApi: "/api/ManageMarkets/MarketSectors?marketId=" + taskVariables.marketId,
        marketId: taskVariables.marketId,
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "showManageFinanceInstrumentsSearchForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "984daae7-328c-453e-b118-720bc0292bf5",
        buttons: "ManageFinanceInstrumentsSearchFormBtns",
        selectedDoc: null,
        gridFormDefId: "4fc0d52d-c2c3-41d8-80f3-5b3c900a6de6",
        gridFormButtons: "ManageInstrumentGroupsGridBtns",
        docListApi: "/api/ManageMarkets/FinanceInstruments?instrumentGroupId=" + item.id,
        marketId: taskVariables.marketId,
        marketSectorId: taskVariables.marketSectorId,
        instrumentGroupId: item.id
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "backToManageInstrumentGroupsSearchForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "5ac6cda7-32c6-4291-8528-dcb254cb7cae",
        buttons: "ManageInstrumentGroupsSearchFormBtns",
        selectedDoc: null,
        gridFormDefId: "a3a3fe23-a29d-47a0-9752-77f98d42af4e",
        gridFormButtons: "ManageInstrumentGroupsGridBtns",
        docListApi: "/api/ManageMarkets/InstrumentGroups?marketSectorId=" + taskVariables.marketSectorId,
        marketId: taskVariables.marketId,
        marketSectorId: taskVariables.marketSectorId,
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "createManageMarket") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        type: buttonName,
        taskType: buttonName,
        position: position,
        formDefId: "80771ebb-027a-4f7d-9405-85397b6d8b61",
        buttons: "ManageMarketsCreateBtns",
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null,
      }
      props.setNewTask(taskVars)
      // console.log(taskVars)
    }

    // MARKETS
    else if (buttonName === "updateMarket") {
      let checkResult = checkForRequieredFields()
      if (checkResult === true) {
        let updateBody = getFieldValuesUpdateDoc()
        // console.log("updateBody", updateBody)
        let res = await putRequest("/api/Markets?id=" + selectedDoc.id, JSON.stringify(updateBody))
        if (res.isSuccess === true) {
          props.callSuccessToast("Данные рынка обновлены!")
          updateBody.id = selectedDoc.id
          setSelectedDoc(updateBody)
        }
      }
    }
    else if (buttonName === "createMarket") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "80771ebb-027a-4f7d-9405-85397b6d8b61",
        buttons: "MarketsCreateBtns",
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null,
      }
      props.setNewTask(taskVars)
      // console.log(taskVars)
    }
    else if (buttonName === "saveMarket") {
      let saveBody = getFieldValuesSaveDoc()
      let res = await postRequest("/api/Markets", JSON.stringify(saveBody))
      if (res.isSuccess === true) {
        props.callSuccessToast("Рынок создан!")
        buttonClick("backToMarketsSearchForm")
      }
    }
    else if (buttonName === "deleteMarket") {
      swal({
        text: "Вы действительно хотите удалить этот рынок?",
        icon: "warning",
        buttons: { ok: "Да", cancel: "Отмена" },
      })
        .then(async (click) => {
          if (click === "ok") {
            let res = await deleteRequest("/api/Markets?id=" + selectedDoc.id)
            if (res.isSuccess === true) {
              props.callSuccessToast("Рынок удален!")
              buttonClick("backToMarketsSearchForm")
            }
          }
        })
    }
    // market sector
    else if (buttonName === "showMarketEditForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        type: buttonName,
        taskType: buttonName,
        position: position,
        formDefId: "105950bf-815d-4cb3-9037-3c63cb3b44eb",
        buttons: "MarketsEditFormBtns",
        selectedDoc: item,
        gridFormDefId: "b3342794-e451-43af-b3b0-4c2927fc2adf",
        gridFormButtons: "MarketSectorsGridBtns",
        docListApi: "/api/ManageMarkets/MarketSectors?marketId=" + item.id
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "backToMarketEditForm") {
      buttonClick("showMarketEditForm", taskVariables.market)
    }
    else if (buttonName === "createMarketSector") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "98f779f2-7f4b-4eb9-a9ae-4f9b78b3f2c4",
        buttons: "MarketSectorsCreateBtns",
        selectedDoc: { marketId: selectedDoc.id },
        market: selectedDoc,
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null,
      }
      props.setNewTask(taskVars)
      // console.log(taskVars)
    }
    else if (buttonName === "saveMarketSector") {
      let saveBody = getFieldValuesSaveDoc()
      let res = await postRequest("/api/MarketSectors", JSON.stringify(saveBody))
      if (res.isSuccess === true) {
        props.callSuccessToast("Сектор создан!")
        buttonClick("showMarketEditForm", taskVariables.market)
      }
    }
    else if (buttonName === "updateMarketSector") {
      let checkResult = checkForRequieredFields()
      if (checkResult === true) {
        let updateBody = getFieldValuesUpdateDoc()
        // console.log("updateBody", updateBody)
        let res = await putRequest("/api/MarketSectors?id=" + selectedDoc.id, JSON.stringify(updateBody))
        if (res.isSuccess === true) {
          props.callSuccessToast("Данные сектора обновлены!")
          updateBody.id = selectedDoc.id
          setSelectedDoc(updateBody)
        }
      }
    }
    else if (buttonName === "showMarketSectorEditForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        type: buttonName,
        taskType: buttonName,
        position: position,
        formDefId: "3e487a0e-69e1-4241-be95-f07d2d72b90b",
        buttons: "MarketSectorEditFormBtns",
        selectedDoc: item,
        market: selectedDoc,
        gridFormDefId: "3a6e90a5-6242-47c0-8eaa-3ccf5f656900",
        gridFormButtons: "MarketSectorGroupsGridBtns",
        docListApi: "/api/ManageMarkets/InstrumentGroups?marketSectorId=" + item.id
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "backFromSectorToMarketEditForm") {
      buttonClick("showMarketEditForm", taskVariables.market)
    }
    else if (buttonName === "deleteMarketSector") {
      swal({
        text: "Вы действительно хотите удалить этот сектор?",
        icon: "warning",
        buttons: { ok: "Да", cancel: "Отмена" },
      })
        .then(async (click) => {
          if (click === "ok") {
            let res = await deleteRequest("/api/MarketSectors?id=" + selectedDoc.id)
            if (res.isSuccess === true) {
              props.callSuccessToast("Сектор удален!")
              buttonClick("showMarketEditForm", taskVariables.market)
            }
          }
        })
    }
    else if (buttonName === "backToMarketsSearchForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "6002de36-198a-449e-b4bf-e623dd5962d6",
        buttons: "MarketsSearchFormBtns",
        gridFormDefId: "60308605-426a-4755-a5b7-82f1c2c6ffa5",
        gridFormButtons: "MarketsGridBtns",
        docListApi: "/api/Markets"
      }
      props.setNewTask(taskVars)
    }
    // market sector group
    else if (buttonName === "createMarketSectorGroup") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "7957c9b7-0640-49cf-861c-a1202025614b",
        buttons: "MarketSectorsGroupCreateBtns",
        selectedDoc: {
          marketId: selectedDoc.marketId,
          marketSectorId: selectedDoc.id
        },
        market: taskVariables.market,
        sector: selectedDoc,
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null,
      }
      props.setNewTask(taskVars)
      // console.log(taskVars)
    }
    else if (buttonName === "saveMarketSectorGroup") {
      let saveBody = getFieldValuesSaveDoc()
      let res = await postRequest("/api/InstrumentGroups", JSON.stringify(saveBody))
      if (res.isSuccess === true) {
        props.callSuccessToast("Группа создана!")
        let taskVars = {
          key: taskVariables.key,
          component: "mainForm",
          type: "showMarketSectorEditForm",
          position: position,
          formDefId: "3e487a0e-69e1-4241-be95-f07d2d72b90b",
          buttons: "MarketSectorEditFormBtns",
          selectedDoc: taskVariables.sector,
          market: taskVariables.market,
          gridFormDefId: "3a6e90a5-6242-47c0-8eaa-3ccf5f656900",
          gridFormButtons: "MarketSectorGroupsGridBtns",
          docListApi: "/api/ManageMarkets/InstrumentGroups?marketSectorId=" + taskVariables.sector.id
        }
        props.setNewTask(taskVars)
        // buttonClick("showMarketSectorEditForm", taskVariables.sector)
      }
    }
    else if (buttonName === "backToMarketSectorSearchForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        type: "showMarketSectorEditForm",
        position: position,
        formDefId: "3e487a0e-69e1-4241-be95-f07d2d72b90b",
        buttons: "MarketSectorEditFormBtns",
        selectedDoc: taskVariables.sector,
        market: taskVariables.market,
        gridFormDefId: "3a6e90a5-6242-47c0-8eaa-3ccf5f656900",
        gridFormButtons: "MarketSectorGroupsGridBtns",
        docListApi: "/api/ManageMarkets/InstrumentGroups?marketSectorId=" + taskVariables.sector.id
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "showMarketSectorGroupEditForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        type: buttonName,
        taskType: buttonName,
        position: position,
        formDefId: "3a6e90a5-6242-47c0-8eaa-3ccf5f656900",
        buttons: "MarketSectorGroupEditFormBtns",
        selectedDoc: item,
        market: taskVariables.market,
        sector: selectedDoc,
        gridFormDefId: "c22f3b9d-15aa-41dc-b0ca-20d870cf73f1",
        gridFormButtons: "MarketSectorGroupsInstrumentsGridBtns",
        docListApi: "/api/ManageMarkets/FinanceInstruments?instrumentGroupId=" + item.id
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "deleteMarketSectorGroup") {
      swal({
        text: "Вы действительно хотите удалить эту группу?",
        icon: "warning",
        buttons: { ok: "Да", cancel: "Отмена" },
      })
        .then(async (click) => {
          if (click === "ok") {
            let res = await deleteRequest("/api/InstrumentGroups?id=" + selectedDoc.id)
            if (res.isSuccess === true) {
              props.callSuccessToast("Группа удалена!")
              let taskVars = {
                key: taskVariables.key,
                component: "mainForm",
                type: "showMarketSectorGroupEditForm",
                position: position,
                formDefId: "3e487a0e-69e1-4241-be95-f07d2d72b90b",
                buttons: "MarketSectorEditFormBtns",
                selectedDoc: taskVariables.sector,
                market: taskVariables.market,
                gridFormDefId: "3a6e90a5-6242-47c0-8eaa-3ccf5f656900",
                gridFormButtons: "MarketSectorGroupsGridBtns",
                docListApi: "/api/ManageMarkets/InstrumentGroups?marketSectorId=" + taskVariables.sector.id
              }
              props.setNewTask(taskVars)
            }
          }
        })
    }
    else if (buttonName === "updateMarketSectorGroup") {
      let checkResult = checkForRequieredFields()
      if (checkResult === true) {
        let updateBody = getFieldValuesUpdateDoc()
        // console.log("updateBody", updateBody)
        let res = await putRequest("/api/InstrumentGroups?id=" + selectedDoc.id, JSON.stringify(updateBody))
        if (res.isSuccess === true) {
          props.callSuccessToast("Данные группы обновлены!")
          updateBody.id = selectedDoc.id
          setSelectedDoc(updateBody)
        }
      }
    }
    // market sector group instrument
    else if (buttonName === "createMarketSectorGroupInstrument") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "3a23b2c5-31f0-4776-a25a-b59bb98bcd00",
        buttons: "MarketSectorGroupInstrumentsCreateBtns",
        selectedDoc: {
          marketId: selectedDoc.marketId,
          marketSectorId: selectedDoc.marketSectorId,
          instrumentGroupId: selectedDoc.id
        },
        market: taskVariables.market,
        sector: taskVariables.sector,
        group: taskVariables.selectedDoc,
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null,
      }
      props.setNewTask(taskVars)
      // console.log(taskVars)
    }
    else if (buttonName === "saveMarketSectorGroupInstrument") {
      let saveBody = getFieldValuesSaveDoc()
      let res = await postRequest("/api/FinanceInstruments", JSON.stringify(saveBody))
      if (res.isSuccess === true) {
        props.callSuccessToast("Инструмент создан!")
        buttonClick("backToMarketSectorGroupSearchForm")
      }
    }
    else if (buttonName === "showMarketSectorGroupInstrumentEditForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "3e8e62c3-9fa2-40f9-9f0e-784fde1f669f",
        buttons: "MarketSectorGroupInstrumentEditFormBtns",
        selectedDoc: item,
        market: taskVariables.market,
        sector: taskVariables.sector,
        group: taskVariables.selectedDoc,
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null,
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "updateMarketSectorGroupInstrument") {
      let checkResult = checkForRequieredFields()
      if (checkResult === true) {
        let updateBody = getFieldValuesUpdateDoc()
        // console.log("updateBody", updateBody)
        let res = await putRequest("/api/FinanceInstruments?id=" + selectedDoc.id, JSON.stringify(updateBody))
        if (res.isSuccess === true) {
          props.callSuccessToast("Данные инструмента обновлены!")
          buttonClick("backToMarketSectorGroupSearchForm")
          updateBody.id = selectedDoc.id
          setSelectedDoc(updateBody)
        }
      }
    }
    else if (buttonName === "deleteMarketSectorGroupInstrument") {
      swal({
        text: "Вы действительно хотите удалить этот инструмент?",
        icon: "warning",
        buttons: { ok: "Да", cancel: "Отмена" },
      })
        .then(async (click) => {
          if (click === "ok") {
            let res = await deleteRequest("/api/FinanceInstruments?id=" + selectedDoc.id)
            if (res.isSuccess === true) {
              props.callSuccessToast("Инструмент удален!")
              buttonClick("backToMarketSectorGroupSearchForm")
            }
          }
        })
    }
    else if (buttonName === "backToMarketSectorGroupSearchForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        type: "showMarketSectorGroupEditForm",
        position: position,
        formDefId: "3a6e90a5-6242-47c0-8eaa-3ccf5f656900",
        buttons: "MarketSectorGroupEditFormBtns",
        selectedDoc: taskVariables.group,
        market: taskVariables.market,
        sector: taskVariables.sector,
        gridFormDefId: "c22f3b9d-15aa-41dc-b0ca-20d870cf73f1",
        gridFormButtons: "MarketSectorGroupsInstrumentsGridBtns",
        docListApi: "/api/ManageMarkets/FinanceInstruments?instrumentGroupId=" + taskVariables.group.id
      }
      props.setNewTask(taskVars)
    }


    // SECTORS
    else if (buttonName === "createSector") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "98f779f2-7f4b-4eb9-a9ae-4f9b78b3f2c4",
        buttons: "SectorCreateBtns",
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null,
      }
      props.setNewTask(taskVars)
      // console.log(taskVars)
    }
    else if (buttonName === "saveSector") {
      let saveBody = getFieldValuesSaveDoc()
      let res = await postRequest("/api/MarketSectors", JSON.stringify(saveBody))
      if (res.isSuccess === true) {
        props.callSuccessToast("Сектор создан!")
        buttonClick("backToSectorsSearchForm")
      }
    }
    else if (buttonName === "updateSector") {
      let checkResult = checkForRequieredFields()
      if (checkResult === true) {
        let updateBody = getFieldValuesUpdateDoc()
        // console.log("updateBody", updateBody)
        let res = await putRequest("/api/MarketSectors?id=" + selectedDoc.id, JSON.stringify(updateBody))
        if (res.isSuccess === true) {
          props.callSuccessToast("Данные сектора обновлены!")
          updateBody.id = selectedDoc.id
          setSelectedDoc(updateBody)
        }
      }
    }
    else if (buttonName === "deleteSector") {
      swal({
        text: "Вы действительно хотите удалить этот сектор?",
        icon: "warning",
        buttons: { ok: "Да", cancel: "Отмена" },
      })
        .then(async (click) => {
          if (click === "ok") {
            let res = await deleteRequest("/api/MarketSectors?id=" + selectedDoc.id)
            if (res.isSuccess === true) {
              props.callSuccessToast("Сектор удален!")
              buttonClick("backToSectorsSearchForm")
            }
          }
        })
    }
    else if (buttonName === "showSectorEditForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        type: buttonName,
        taskType: buttonName,
        position: position,
        formDefId: "3e487a0e-69e1-4241-be95-f07d2d72b90b",
        buttons: "SectorsEditFormBtns",
        selectedDoc: item,
        gridFormDefId: "3a6e90a5-6242-47c0-8eaa-3ccf5f656900",
        gridFormButtons: "SectorGroupsGridBtns",
        docListApi: "/api/ManageMarkets/InstrumentGroups?marketSectorId=" + item.id
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "backToSectorsSearchForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "88efb94f-71ce-40bc-b15c-6373259f0c9f",
        buttons: "SectorsSearchFormBtns",
        selectedDoc: null,
        gridFormDefId: "b3342794-e451-43af-b3b0-4c2927fc2adf",
        gridFormButtons: "SectorsGridBtns",
        docListApi: "/api/MarketSectors"
      }
      props.setNewTask(taskVars)
    }
    // sector groups
    else if (buttonName === "createSectorGroup") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "7957c9b7-0640-49cf-861c-a1202025614b",
        buttons: "SectorGroupCreateBtns",
        selectedDoc: {
          marketId: selectedDoc.marketId,
          marketSectorId: selectedDoc.id
        },
        sector: selectedDoc,
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null,
      }
      props.setNewTask(taskVars)
      // console.log(taskVars)
    }
    else if (buttonName === "saveSectorGroup") {
      let saveBody = getFieldValuesSaveDoc()
      let res = await postRequest("/api/InstrumentGroups", JSON.stringify(saveBody))
      if (res.isSuccess === true) {
        props.callSuccessToast("Группа создана!")
        buttonClick("backToSectorEditForm")
      }
    }
    else if (buttonName === "backToSectorEditForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        type: "showSectorEditForm",
        position: position,
        formDefId: "3e487a0e-69e1-4241-be95-f07d2d72b90b",
        buttons: "SectorsEditFormBtns",
        selectedDoc: taskVariables.sector,
        gridFormDefId: "3a6e90a5-6242-47c0-8eaa-3ccf5f656900",
        gridFormButtons: "SectorGroupsGridBtns",
        docListApi: "/api/ManageMarkets/InstrumentGroups?marketSectorId=" + taskVariables.sector.id
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "showSectorGroupEditForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        type: buttonName,
        taskType: buttonName,
        position: position,
        formDefId: "3a6e90a5-6242-47c0-8eaa-3ccf5f656900",
        buttons: "SectorGroupEditFormBtns",
        selectedDoc: item,
        sector: selectedDoc,
        gridFormDefId: "c22f3b9d-15aa-41dc-b0ca-20d870cf73f1",
        gridFormButtons: "SectorGroupInstrumentsGridBtns",
        docListApi: "/api/ManageMarkets/FinanceInstruments?instrumentGroupId=" + item.id
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "deleteSectorGroup") {
      swal({
        text: "Вы действительно хотите удалить эту группу?",
        icon: "warning",
        buttons: { ok: "Да", cancel: "Отмена" },
      })
        .then(async (click) => {
          if (click === "ok") {
            let res = await deleteRequest("/api/InstrumentGroups?id=" + selectedDoc.id)
            if (res.isSuccess === true) {
              props.callSuccessToast("Группа удалена!")
              buttonClick("backToSectorEditForm")
            }
          }
        })
    }
    else if (buttonName === "updateSectorGroup") {
      let checkResult = checkForRequieredFields()
      if (checkResult === true) {
        let updateBody = getFieldValuesUpdateDoc()
        // console.log("updateBody", updateBody)
        let res = await putRequest("/api/InstrumentGroups?id=" + selectedDoc.id, JSON.stringify(updateBody))
        if (res.isSuccess === true) {
          props.callSuccessToast("Данные группы обновлены!")
          updateBody.id = selectedDoc.id
          setSelectedDoc(updateBody)
        }
      }
    }
    // sector group instruments
    else if (buttonName === "showSectorGroupInstrumentEditForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "3e8e62c3-9fa2-40f9-9f0e-784fde1f669f",
        buttons: "SectorGroupInstrumentEditFormBtns",
        selectedDoc: item,
        sector: taskVariables.sector,
        group: taskVariables.selectedDoc,
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null,
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "createSectorGroupInstrument") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "3a23b2c5-31f0-4776-a25a-b59bb98bcd00",
        buttons: "SectorGroupInstrumentCreateBtns",
        selectedDoc: {
          marketId: selectedDoc.marketId,
          marketSectorId: selectedDoc.marketSectorId,
          instrumentGroupId: selectedDoc.id
        },
        sector: taskVariables.sector,
        group: taskVariables.selectedDoc,
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null,
      }
      props.setNewTask(taskVars)
      // console.log(taskVars)
    }
    else if (buttonName === "saveSectorGroupInstrument") {
      let saveBody = getFieldValuesSaveDoc()
      let res = await postRequest("/api/FinanceInstruments", JSON.stringify(saveBody))
      if (res.isSuccess === true) {
        props.callSuccessToast("Инструмент создан!")
        buttonClick("backToSectorGroupEditForm")
      }
    }
    else if (buttonName === "updateSectorGroupInstrument") {
      let checkResult = checkForRequieredFields()
      if (checkResult === true) {
        let updateBody = getFieldValuesUpdateDoc()
        // console.log("updateBody", updateBody)
        let res = await putRequest("/api/FinanceInstruments?id=" + selectedDoc.id, JSON.stringify(updateBody))
        if (res.isSuccess === true) {
          props.callSuccessToast("Данные инструмента обновлены!")
          buttonClick("backToSectorGroupEditForm")
        }
      }
    }
    else if (buttonName === "deleteSectorGroupInstrument") {
      swal({
        text: "Вы действительно хотите удалить этот инструмент?",
        icon: "warning",
        buttons: { ok: "Да", cancel: "Отмена" },
      })
        .then(async (click) => {
          if (click === "ok") {
            let res = await deleteRequest("/api/FinanceInstruments?id=" + selectedDoc.id)
            if (res.isSuccess === true) {
              props.callSuccessToast("Инструмент удален!")
              buttonClick("backToSectorGroupEditForm")
            }
          }
        })
    }
    else if (buttonName === "backToSectorGroupEditForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        type: "showMarketSectorGroupEditForm",
        position: position,
        formDefId: "3a6e90a5-6242-47c0-8eaa-3ccf5f656900",
        buttons: "SectorGroupEditFormBtns",
        selectedDoc: taskVariables.group,
        sector: taskVariables.sector,
        gridFormDefId: "c22f3b9d-15aa-41dc-b0ca-20d870cf73f1",
        gridFormButtons: "SectorGroupInstrumentsGridBtns",
        docListApi: "/api/ManageMarkets/FinanceInstruments?instrumentGroupId=" + taskVariables.group.id
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "openTradesBySector") {
      handleCloseMenu()
      let res = await getRequest("/api/TradingScheduleManagement/SetStatus2?marketSectorId=" + item.id + "&statusId=1&token=999225")
      if (res.isSuccess === true) {
        props.callSuccessToast("Торги открыты!")
      }
    }
    else if (buttonName === "closeTradesBySector") {
      handleCloseMenu()
      let res = await getRequest("/api/TradingScheduleManagement/SetStatus2?marketSectorId=" + item.id + "&statusId=3&token=999225")
      if (res.isSuccess === true) {
        props.callSuccessToast("Торги закрыты!")
      }
    }

    // GROUPS
    else if (buttonName === "createGroup") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "7957c9b7-0640-49cf-861c-a1202025614b",
        buttons: "GroupsCreateBtns",
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null,
      }
      props.setNewTask(taskVars)
      // console.log(taskVars)
    }
    else if (buttonName === "saveGroup") {
      let saveBody = getFieldValuesSaveDoc()
      let res = await postRequest("/api/InstrumentGroups", JSON.stringify(saveBody))
      if (res.isSuccess === true) {
        props.callSuccessToast("Группа создана!")
        buttonClick("backToGroupsSearchForm")
      }
    }
    else if (buttonName === "updateGroup") {
      let checkResult = checkForRequieredFields()
      if (checkResult === true) {
        let updateBody = getFieldValuesUpdateDoc()
        // console.log("updateBody", updateBody)
        let res = await putRequest("/api/InstrumentGroups?id=" + selectedDoc.id, JSON.stringify(updateBody))
        if (res.isSuccess === true) {
          props.callSuccessToast("Данные группы обновлены!")
          updateBody.id = selectedDoc.id
          setSelectedDoc(updateBody)
        }
      }
    }
    else if (buttonName === "deleteInstrumentGroup") {
      swal({
        text: "Вы действительно хотите удалить эту группу?",
        icon: "warning",
        buttons: { ok: "Да", cancel: "Отмена" },
      })
        .then(async (click) => {
          if (click === "ok") {
            let res = await deleteRequest("/api/InstrumentGroups?id=" + selectedDoc.id)
            if (res.isSuccess === true) {
              props.callSuccessToast("Группа удалена!")
              buttonClick("backToInstrumentGroupsSearchForm")
            }
          }
        })
    }
    else if (buttonName === "showGroupEditForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        type: buttonName,
        taskType: buttonName,
        position: position,
        formDefId: "3a6e90a5-6242-47c0-8eaa-3ccf5f656900",
        buttons: "GroupEditFormBtns",
        selectedDoc: item,
        gridFormDefId: "c22f3b9d-15aa-41dc-b0ca-20d870cf73f1",
        gridFormButtons: "GroupInstrumentsGridBtns",
        docListApi: "/api/ManageMarkets/FinanceInstruments?instrumentGroupId=" + item.id,
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "backToGroupsSearchForm") {
      let taskVars = {
        key: taskVariables.key,
        position: position,
        component: "mainForm",
        formDefId: "2dc278a8-43cb-44d9-88f2-4b34a9973f47",
        buttons: "GroupsSearchFormBtns",
        selectedDoc: null,
        gridFormDefId: "3a6e90a5-6242-47c0-8eaa-3ccf5f656900",
        gridFormButtons: "GroupsGridBtns",
        docListApi: "/api/InstrumentGroups"
      }
      props.setNewTask(taskVars)
    }
    // group instrument
    else if (buttonName === "createGroupInstrument") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "3a23b2c5-31f0-4776-a25a-b59bb98bcd00",
        buttons: "GroupInstrumentCreateBtns",
        selectedDoc: {
          marketId: selectedDoc.marketId,
          marketSectorId: selectedDoc.marketSectorId,
          instrumentGroupId: selectedDoc.id
        },
        group: selectedDoc,
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null,
      }
      props.setNewTask(taskVars)
      // console.log(taskVars)
    }
    else if (buttonName === "backToGroupEditForm") {
      let taskVars = {
        key: taskVariables.key,
        position: position,
        component: "mainForm",
        type: "showGroupEditForm",
        formDefId: "3a6e90a5-6242-47c0-8eaa-3ccf5f656900",
        buttons: "GroupEditFormBtns",
        selectedDoc: taskVariables.group,
        gridFormDefId: "c22f3b9d-15aa-41dc-b0ca-20d870cf73f1",
        gridFormButtons: "GroupInstrumentsGridBtns",
        docListApi: "/api/ManageMarkets/FinanceInstruments?instrumentGroupId=" + taskVariables.group.id,
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "saveGroupInstrument") {
      let saveBody = getFieldValuesSaveDoc()
      let res = await postRequest("/api/FinanceInstruments", JSON.stringify(saveBody))
      if (res.isSuccess === true) {
        props.callSuccessToast("Инструмент создан!")
        buttonClick("backToGroupEditForm")
      }
    }
    else if (buttonName === "showGroupInstrumentEditForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "3e8e62c3-9fa2-40f9-9f0e-784fde1f669f",
        buttons: "GroupInstrumentsEditFormBtns",
        selectedDoc: item,
        group: selectedDoc,
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null,
      }
      props.setNewTask(taskVars)
    }

    else if (buttonName === "updateGroupInstrument") {
      let checkResult = checkForRequieredFields()
      if (checkResult === true) {
        let updateBody = getFieldValuesUpdateDoc()
        console.log("updateBody", updateBody)
        let res = await putRequest("/api/FinanceInstruments?id=" + selectedDoc.id, JSON.stringify(updateBody))
        if (res.isSuccess === true) {
          props.callSuccessToast("Инструмент обновлен!")
          buttonClick("backToGroupEditForm")
        }
      }
    }
    else if (buttonName === "deleteGroupInstrument") {
      swal({
        text: "Вы действительно хотите удалить этот инструмент?",
        icon: "warning",
        buttons: { ok: "Да", cancel: "Отмена" },
      })
        .then(async (click) => {
          if (click === "ok") {
            let res = await deleteRequest("/api/FinanceInstruments?id=" + selectedDoc.id)
            if (res.isSuccess === true) {
              props.callSuccessToast("Инструмент удален!")
              buttonClick("backToGroupEditForm")
            }
          }
        })
    }

    // INSTRUMENTS
    else if (buttonName === "showInstrumentsEditForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "3e8e62c3-9fa2-40f9-9f0e-784fde1f669f",
        buttons: "InstrumentsEditFormBtns",
        selectedDoc: item,
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null,
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "backToInstrumentsSearchForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "98de9783-fb31-4ed5-bd92-9f2b6e36740e",
        buttons: "InstrumentsSearchFormBtns",
        gridFormDefId: "c22f3b9d-15aa-41dc-b0ca-20d870cf73f1",
        gridFormButtons: "InstrumentsGridBtns",
        docListApi: "/api/FinanceInstruments"
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "createInstrument") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "3a23b2c5-31f0-4776-a25a-b59bb98bcd00",
        buttons: "InstrumentsCreateBtns",
        // selectedDoc: {marketSectorId: props.taskVariables.marketSectorId},
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null,
      }
      props.setNewTask(taskVars)
      // console.log(taskVars)
    }
    else if (buttonName === "saveInstrument") {
      let saveBody = getFieldValuesSaveDoc()
      let res = await postRequest("/api/FinanceInstruments", JSON.stringify(saveBody))
      if (res.isSuccess === true) {
        props.callSuccessToast("Инструмент создан!")
        buttonClick("backToInstrumentsSearchForm")
      }
    }
    else if (buttonName === "updateInstrument") {
      let checkResult = checkForRequieredFields()
      if (checkResult === true) {
        let updateBody = getFieldValuesUpdateDoc()
        console.log("updateBody", updateBody)
        let res = await putRequest("/api/FinanceInstruments?id=" + selectedDoc.id, JSON.stringify(updateBody))
        if (res.isSuccess === true) {
          props.callSuccessToast("Инструмент обновлен!")
          buttonClick("backToInstrumentsSearchForm")
        }
      }
    }
    else if (buttonName === "deleteInstrument") {
      swal({
        text: "Вы действительно хотите удалить этот инструмент?",
        icon: "warning",
        buttons: { ok: "Да", cancel: "Отмена" },
      })
        .then(async (click) => {
          if (click === "ok") {
            let res = await deleteRequest("/api/FinanceInstruments?id=" + selectedDoc.id)
            if (res.isSuccess === true) {
              props.callSuccessToast("Инструмент удален!")
              buttonClick("backToInstrumentsSearchForm")
            }
          }
        })
    }
    else if (buttonName === "openTrades") {
      await fetch(
        kseRESTApi + "/api/TradingScheduleManagement/SetStatusInstrument?instrumentId=" + selectedDoc.id + "&statusId=1",
        {
          "headers": { "content-type": "application/json", "Authorization": "Bearer " + token }
        }
      )
        .then(response => response.json())
        .then(function (res) {
          console.log("RES", res)
          if (res.isSuccess === true) {
            props.callSuccessToast("Торги открыты!", 1500)
          }
        })
    }
    else if (buttonName === "closeTrades") {
      await fetch(
        kseRESTApi + "/api/TradingScheduleManagement/SetStatusInstrument?instrumentId=" + selectedDoc.id + "&statusId=3",
        {
          "headers": { "content-type": "application/json", "Authorization": "Bearer " + token }
        }
      )
        .then(response => response.json())
        .then(function (res) {
          console.log("RES", res)
          if (res.isSuccess === true) {
            props.callSuccessToast("Торги закрыты!", 1500)
          }
        })
    }

    // positions
    else if (buttonName === "showAccountPositionsCurrenciesBySectorId") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "5de0c890-c2d5-4ae3-a02c-d87bb0948119",
        buttons: "AccountPositionsCurrenciesSearchFormBtns",
        selectedDoc: null,
        gridFormDefId: "a506a42a-92f3-4942-8ac4-4d56ee11aa8d",
        gridFormButtons: "AccountPositionsCurrenciesGridBtns",
        docListApi: "/api/AccountPositions/ViewCurrencies?marketSectorId=" + item.id
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "showAccountPositionsTradingBySectorId") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "5de0c890-c2d5-4ae3-a02c-d87bb0948119",
        buttons: "AccountPositionsCurrenciesSearchFormBtns",
        selectedDoc: null,
        gridFormDefId: "a506a42a-92f3-4942-8ac4-4d56ee11aa8d",
        gridFormButtons: "AccountPositionsCurrenciesGridBtns",
        docListApi: "/api/AccountPositions/ViewPositions?marketSectorId=" + item.id
      }
      props.setNewTask(taskVars)
    }

    // CURRENCY RATES
    else if (buttonName === "createCurrencyRates") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "ce821864-8633-4b1a-ad62-2018851f170e",
        buttons: "CurrencyRatesCreateBtns",
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null,
      }
      props.setNewTask(taskVars)
      // console.log(taskVars)
    }
    else if (buttonName === "saveCurrencyRate") {
      let saveBody = getFieldValuesSaveDoc()
      let res = await postRequest("/api/CurrencyRates/Create", JSON.stringify(saveBody),)
      if (res.isSuccess === true) {
        props.callSuccessToast("Курс валюты сохранен!")
        let taskVars = {
          key: taskVariables.key,
          component: "mainForm",
          position: position,
          formDefId: "72d475d6-997b-40f7-b3b6-d5fe86e50cbe",
          buttons: "CurrencyRatesSearchForm",
          gridFormDefId: "0bd3477d-7a5a-439d-9af1-c777d5fbbb79",
          gridFormButtons: "CurrencyRatesGridBtns",
          docListApi: "/api/CurrencyRates/ViewRates"
        }
        props.setNewTask(taskVars)
      }
    }
    else if (buttonName === "showCurrencyRatesEditForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "598bff20-4a0d-4f63-a8e5-eebe64ef3a5b",
        buttons: "CurrencyRatesEditFormBtns",
        selectedDoc: item,
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null
      }
      props.setNewTask(taskVars)
      // console.log(taskVars)
    }
    else if (buttonName === "updateCurrencyRates") {
      let checkResult = checkForRequieredFields()
      if (checkResult === true) {
        let updateBody = getFieldValuesUpdateDoc()
        updateBody.rateForDate = moment(updateBody.rateForDate).format("YYYY-MM-DD")
        console.log("updateBody", updateBody)
        let res = await postRequest("/api/CurrencyRates/Create", JSON.stringify(updateBody))
        if (res.isSuccess === true) {
          props.callSuccessToast("Курс валюты обновлен!")
          buttonClick("backToCurrencyRatesSearchForm")
        }
      }
    }
    else if (buttonName === "editDownloadSchedule") {
      let schedule = await props.getDocREST("/api/CurrencyRates/ViewScheduledDownload")
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "7fde6cb9-4079-43ed-af60-e144487df0a6",
        buttons: "CurrencyRatesDownloadScheduleEditBtns",
        selectedDoc: schedule.data[0],
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null
      }
      props.setNewTask(taskVars)
      console.log("schedule", schedule)
    }
    else if (buttonName === "updateCurrencyRatesDownloadSchedule") {
      let res = await props.getDocREST("/api/CurrencyRates/ScheduleDownload?hh=" + fieldValue.hh + "&mm=" + fieldValue.mm)
      console.log("RES", res)
      if (res.isSuccess === true) {
        props.callSuccessToast("Расписание обновлено!")
        buttonClick("backToCurrencyRatesSearchForm")
      }
    }
    else if (buttonName === "backToCurrencyRatesSearchForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "72d475d6-997b-40f7-b3b6-d5fe86e50cbe",
        buttons: "CurrencyRatesSearchForm",
        gridFormDefId: "0bd3477d-7a5a-439d-9af1-c777d5fbbb79",
        gridFormButtons: "CurrencyRatesGridBtns",
        docListApi: "/api/CurrencyRates/ViewRates"
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "selectPreviewORDAndTRDDate") {
      let schedule = await props.getDocREST("/api/CurrencyRates/ViewScheduledDownload")
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "7fde6cb9-4079-43ed-af60-e144487df0a6",
        buttons: "CurrencyRatesDownloadScheduleEditBtns",
        date: fieldValue["orderDate"]
      }
      props.setNewTask(taskVars)
      console.log("schedule", schedule)
    }

    // ORGANIZATIONS
    else if (buttonName === "createOrganization") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "4e4b81aa-c8c0-497b-874a-661ed960c8d4",
        buttons: "OrganizationsCreateBtns",
        selectedDoc: { genAccount3001: true, genAccount3101: true, genAccount3301: true },
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null,
      }
      props.setNewTask(taskVars)
      // console.log(taskVars)
    }
    else if (buttonName === "saveOrganization") {
      let saveBody = getFieldValuesSaveDoc()
      let res = await postRequest("/api/Organizations", JSON.stringify(saveBody),)
      if (res.isSuccess === true) {
        props.callSuccessToast("Организация создана!")
        buttonClick("backToOrganizationsSearchForm")
      }
    }
    else if (buttonName === "backToOrganizationsSearchForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "754ab3f5-4eed-48dd-a25d-9409edbabc0d",
        buttons: "OrganizationsSearchFormBtns",
        gridFormDefId: "3b187d03-fc87-4e9f-91c8-009c119c8dc9",
        gridFormButtons: "OrganizationsGridBtns",
        docListApi: "/api/Organizations"
      }
      props.setNewTask(taskVars)
      console.log(taskVars)
    }
    else if (buttonName === "showOrganizationEditForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "2ceae15e-d185-4349-a506-65a0d72c0e6d",
        buttons: "OrganizationsEditFormBtns",
        selectedDoc: item,
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "updateOrganization") {
      let updateBody = getFieldValuesUpdateDoc()
      // console.log("BODY", updateBody)
      let res = await putRequest("/api/Organizations?id=" + selectedDoc.id, JSON.stringify(updateBody))
      if (res.isSuccess === true) {
        props.callSuccessToast("Организация обновлена!")
        buttonClick("backToOrganizationsSearchForm")
      }
    }
    else if (buttonName === "showOrganizationUsers") {
      let taskVars = {
        key: taskVariables.key,
        component: "users",
        position: position,
        formDefId: "a0ea7b6a-c26a-4636-85fe-5b64c8b78cb2",
        buttons: "OrganizationUsersSearchFormBtns",
        selectedDoc: null,
        organization: selectedDoc,
        gridFormDefId: "e5e74841-3d37-4835-8d15-9691d7902283",
        gridFormButtons: "OrganizationUsersGridBtns",
        docListApi: "/api/users/GetUsersByOrgId?orgId=" + selectedDoc.id
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "deleteOrganization") {
      swal({
        text: "Вы действительно хотите удалить этоу организацию?",
        icon: "warning",
        buttons: { ok: "Да", cancel: "Отмена" },
      })
        .then(async (click) => {
          if (click === "ok") {
            let res = await deleteRequest("/api/Organizations?id=" + selectedDoc.id)
            if (res.isSuccess === true) {
              props.callSuccessToast("Организация удалена!")
              buttonClick("backToOrganizationsSearchForm")
            }
          }
        })
    }

    // REPO
    else if (buttonName === "buyRepo") {
      setAnchorElRepoObligations(null)
      console.log("BUY", item.id)
      let res = await getRequest("/api/Trading/MakeRepoBuy?id=" + item.id)
      if (res.isSuccess === true) {
        props.callSuccessToast("Покупка выполнена успешно!")
        buttonClick("backToRepoObligationsSearchForm")
      }
    }
    else if (buttonName === "sellRepo") {
      let res = await getRequest("/api/Trading/MakeRepoSell?id=" + item.id)
      if (res.isSuccess === true) {
        props.callSuccessToast("Продажа выполнена успешно!")
        buttonClick("backToRepoObligationsSearchForm")
      }
    }
    else if (buttonName === "showRepoObligationsEditForm") {
      let btns = "RepoObligationViewBtns"
      if (item.buyOrgId === userProfile.organization.id && item.isBought === false) {
        btns = "RepoObligationViewBuyBtns"
        if (item.isBought === false && item.isSold === false) {
          btns = "RepoObligationViewBuyProlongationBtns"
        }
      }
      if (item.sellOrgId === userProfile.organization.id && item.isSold === false) {
        btns = "RepoObligationViewSellBtns"
        if (item.isBought === false && item.isSold === false) {
          btns = "RepoObligationViewSellProlongationBtns"
        }
      }

      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "c2e241f4-379b-41e6-a104-451160300133",
        buttons: btns,
        selectedDoc: item,
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "makeBuyRepo") {
      buttonClick("buyRepo", selectedDoc)
    }
    else if (buttonName === "makeSellRepo") {
      buttonClick("sellRepo", selectedDoc)
    }
    else if (buttonName === "backToRepoObligationsSearchForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "8c2c4cd8-5a95-48a6-acb5-14772f29552b",
        buttons: "RepoObligationsSearchBtns",
        gridFormDefId: "b85ad455-fb35-46ed-a334-9756121e5c44",
        gridFormButtons: "RepoObligationsGridBtns",
        docListApi: "/api/Trading/GetRepoObligations"
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "prolongateRepo") {
      handleCloseMenu()
      props.setShowRepoProlongation(true)
      if (isSearchForm === true) {
        props.setSelectedRepo(item)
      }
      else {
        props.setSelectedRepo(selectedDoc)
      }
    }
    else if (buttonName === "cancelRepo") {
      swal({
        text: "Вы точно хотите отменить РЕПО?",
        icon: "warning",
        buttons: { yes: "Да", cancel: "Отмена" }
      })
        .then(async (click) => {
          if (click === "yes") {
            let res = await getRequest("/api/Trading/DeclineRepo?repoId=" + item.id)
            if (res.isSuccess === true) {
              props.callSuccessToast(res.message)
              buttonClick("backToRepoObligationsSearchForm")
            }
          }
        })

    }
    else if (buttonName === "approveCancelRepo") {
      swal({
        text: "Вы точно хотите отменить РЕПО?",
        icon: "warning",
        buttons: { yes: "Да", cancel: "Отмена" }
      })
        .then(async (click) => {
          if (click === "yes") {
            let res = await getRequest("/api/Trading/DeclineRepo?repoId=" + item.id)
            if (res.isSuccess === true) {
              props.callSuccessToast(res.message)
              buttonClick("backToRepoObligationsSearchForm")
            }
          }
        })
    }
    // GENERAL
    else if (buttonName === "close") {
      props.closeTask(taskVariables.key)
      console.log("button close: ", taskVariables.key)
      props.setSavedDocs([])
    }
    else {
      console.log("UNKNOWN Button ", buttonName)
    }
  }
  // Key press handlers for some fields
  function onKeyPressText(event) {
    let code = event.charCode
    if (code === 13) {
      for (let i = 0; i < buttons.length; i++) {
        if (buttons[i].name === "findDocument") {
          // console.log("CODE", code)
          handleTextChange(event)
          buttonClick("findDocument", null)
        }
      }
    }
  }
  function onKeyPressDateTime(event) {
    let code = event.charCode
    if (code === 13) {
      for (let i = 0; i < buttons.length; i++) {
        if (buttons[i].name === "findDocument") {
          // console.log("CODE", code)
          handleDateTimeChange(event)
          buttonClick("findDocument", null)
        }
      }
    }
  }
  function onKeyPressInt(event) {
    let code = event.charCode
    if (code === 13) {
      for (let i = 0; i < buttons.length; i++) {
        if (buttons[i].name === "findDocument") {
          // console.log("CODE", code)
          let stringNum = event.target.value.toString().replace(/ /g, '')
          let int = parseInt(stringNum)
          fieldValue[event.target.name] = int
          setFieldValue(fieldValue)
          filterDocList(0, size - 1, initialDocList, fieldValue)
        }
      }
    }
  }
  function onKeyPressFloat(event) {
    let code = event.charCode
    if (code === 13) {
      for (let i = 0; i < buttons.length; i++) {
        if (buttons[i].name === "findDocument") {
          let stringNum = event.target.value.toString().replace(/ /g, '')
          let float = parseFloat(stringNum)
          fieldValue[event.target.name] = float
          setFieldValue(fieldValue)
          filterDocList(0, size - 1, initialDocList, fieldValue)
        }
      }
    }
  }
  // Create grid form components
  function getGridFormItems(docListItem, contentItem) {
    let value = docListItem[contentItem.name]
    let name = contentItem.name
    let type = contentItem.type
    if (type === "Enum") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="center" style={{ color: "black", fontSize: 12 }}>-</td>)
      }
      else {
        for (let l = 0; l < gridFormEnumData[name].length; l++) {
          if (gridFormEnumData[name][l].id === parseInt(value)) {
            return gridFormEnumData[name][l].label
          }
        }
      }
    }
    else if (type === "Bool") {
      // console.log("ITEM", name, value, type)
      return (
        <Checkbox
          style={{ maxWidth: 20, height: 15, color: value === false ? "grey" : "green" }}
          checked={(value === false || value === null || value === undefined) ? false : true}
        />
      )
    }
    else if (type === "DateTime") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="center" style={{ color: "black", fontSize: 12 }}>-</td>)
      }
      else {
        let d = moment(value).format("DD.MM.YYYY HH:MM")
        return d === "Invalid date" ? value : d
      }
    }
    else if (type === "Date") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="center" style={{ color: "black", fontSize: 12 }}>-</td>)
      }
      else {
        // console.log("ITEM", docListItem, value)
        let Date = value.substring(0, 10)
        return Date
      }
    }

    else {
      if (value === null || value === "" || value === undefined) {
        return (<td align="center" style={{ color: "black", fontSize: 12 }}>-</td>)
      }
      else {
        return (<td>{value}</td>)
      }
    }
  }
  function checkToShowSection(section) {
    let showSection = false
    for (let i = 0; i < section.contents.length; i++) {
      if (section.contents[i].show === true) {
        showSection = true
        break
      }
    }
    return showSection
  }
  function checkSectionOnLastChild(i) {
    let lastSection = true
    if (i === gridForm.sections.length - 1) { // The last section
      return true
    }
    else {
      let nextS = i + 1
      // console.log("IT", i, gridForm.sections[nextS])
      for (let s = nextS; s < gridForm.sections.length; s++) {
        for (let c = 0; c < gridForm.sections[s].contents.length; c++) { // Check next section
          if (gridForm.sections[s].contents[c].show === true) {
            lastSection = false
            break
          }
        }
      }
    }
    return lastSection
  }
  function getForm() {
    return (
      <Grid container direction="row" justify="center" spacing={0}>
        <Grid item xs={12}>
          <Table class="detail-table-style" width="100%">
            <Grid container direction="row" justify="center" alignItems="center">
              {Form.sections.map(section => {
                return sectionBuilder(section)
              })}
            </Grid>
          </Table>
        </Grid>
      </Grid>
    )
  }
  // Create sections with labels and call bodyBuilder function
  function sectionBuilder(section) {
    if (isSearchForm === false) {
      return (
        <Grid container direction="row" justify="center" spacing={0} style={{ marginTop: 5 }}>
          <Grid item xs={12}>
            <Accordion
              expanded={accordionState[section.name]}
              onChange={() => handleExpandAccordion(section.name)}
              style={{ background: accordionState[section.name] === false ? "#F6F6F6" : "white" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>{section.label}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {bodyBuilder(section)}
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      )
    }
    else {
      return (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                class="sectionBuilderStyle"
              >
                {section.label}
              </TableCell>
            </TableRow>
          </TableHead>
          {bodyBuilder(section)}
        </Table>
      )
    }

    // <Table size="small">
    //   <TableHead>
    //     <TableRow>
    //       <TableCell
    //         class="sectionBuilderStyle"
    //       >
    //         {section.label}
    //       </TableCell>
    //     </TableRow>
    //   </TableHead>
    //   {bodyBuilder(section)}
    // </Table>
  }
  // Create body of each section and call contentBuilder function
  function bodyBuilder(section) {
    return (
      <Table size="small">
        <TableBody>
          {section.contents.map(contentItem => {
            if (contentItem.show === true) {
              return (
                contentItem.type === "CheckBoxList" ?
                  <table width="100%" align="center">
                    <tr>
                      <th align="center">{contentItem.label}</th>
                    </tr>
                    <tr>
                      <td width="100%">
                        <Grid item>{getCheckBoxList(checkBoxList[contentItem.name], contentItem.name)}</Grid>
                      </td>
                    </tr>
                  </table>
                  :
                  <Table size="small">
                    <TableRow>
                      <TableCell
                        width="40%"
                        class="bodyBuilderStyle">
                        {contentItem.label}
                      </TableCell>
                      <TableCell
                        width="60%"
                        align="left"
                      // class="bodyBuilderStyle">
                      >
                        {contentBuilder(contentItem)}
                      </TableCell>
                    </TableRow>
                  </Table>
              )
            }
          })}
        </TableBody>
      </Table>
    )
  }
  // Creating components by it's type
  function contentBuilder(contentItem) {
    if (contentItem.type === "Text") {
      return (
        <TextField
          name={contentItem.name}
          value={fieldValue[contentItem.name]}
          onChange={handleTextChange}
          onKeyPress={onKeyPressText}
          placeholder={contentItem.placeholder}
          style={{ width: "100%" }}
          disabled={(formType === "view" || contentItem.edit === false) ? true : false}
        />
      )
    }
    else if (contentItem.type === "Enum") {
      let selectedOption = {
        "value": "",
        "label": "Пусто",
        "name": contentItem.name
      }
      if (fieldValue[contentItem.name] !== undefined) {
        for (let i = 0; i < enumOptions[contentItem.name].length; i++) {
          if (parseInt(fieldValue[contentItem.name]) === enumOptions[contentItem.name][i].value) {
            selectedOption = enumOptions[contentItem.name][i]
            break
          }
        }
      }
      if (selectedOptions.length !== 0) {
        for (let i = 0; i < selectedOptions.length; i++) {
          if (contentItem.name === selectedOptions[i].name) {
            selectedOption = selectedOptions[i]
          }
        }
      }
      return (
        <Select
          name={contentItem.name}
          value={selectedOption}
          onChange={handleSelectChange}
          options={enumOptions[contentItem.name]}
          isDisabled={(formType === "view" || contentItem.edit === false) ? true : false}
          menuPortalTarget={document.body}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        />
      )
    }
    else if (contentItem.type === "Bool") {
      return (
        <Checkbox
          style={{ maxWidth: 20, height: 15, color: (formType === "view" || contentItem.edit === false) ? "grey" : "green" }}
          name={contentItem.name}
          onChange={handleCheckboxChange}
          disabled={(formType === "view" || contentItem.edit === false) ? true : false}
          checked={(fieldValue[contentItem.name] === false || fieldValue[contentItem.name] === null || fieldValue[contentItem.name] === undefined) ? false : true}
        />
      )
    }

    else if (contentItem.type === "Int") {
      return (
        <TextField
          name={contentItem.name}
          value={(fieldValue[contentItem.name])}
          onChange={handleIntChange}
          onKeyPress={onKeyPressInt}
          placeholder={contentItem.placeholder}
          disabled={(formType === "view" || contentItem.edit === false) ? true : false}
          style={{ width: "100%" }}
          InputProps={{ inputComponent: IntegerFormat }}
        />
      )
    }
    else if (contentItem.type === "Float") {
      return (
        <TextField
          onKeyPress={onKeyPressFloat}
          name={contentItem.name}
          value={(fieldValue[contentItem.name])}
          onChange={handleFloatChange}
          // onBlur={handleFloatChange}
          // defaultValue={fieldValue[contentItem.name]}
          placeholder={contentItem.placeholder}
          style={{ width: "100%" }}
          disabled={(formType === "view" || contentItem.edit === false) ? true : false}
          InputProps={{ inputComponent: FloatFormat }}
        />
      )
    }
    else if (contentItem.type === "DateTime" || contentItem.type === "Date") {
      return (
        <TextField
          onKeyPress={onKeyPressDateTime}
          type="date"
          name={contentItem.name}
          onBlur={handleDateTimeChange}
          style={{ width: "100%" }}
          // defaultValue={moment(fieldValue[contentItem.name]).format('YYYY-MM-DD')}
          defaultValue={(fieldValue[contentItem.name] !== undefined && fieldValue[contentItem.name] !== null) ? moment(fieldValue[contentItem.name]).format('YYYY-MM-DD') : ""}
          disabled={(formType === "view" || contentItem.edit === false) ? true : false}
          InputLabelProps={{
            shrink: true,
          }}
        />
        // <TextField
        //   onKeyPress={onKeyPressDateTime}
        //   type="date"
        //   name={contentItem.name}
        //   onBlur={handleDateTimeChange}
        //   style={{ width: "100%" }}
        //   defaultValue={(fieldValue[contentItem.name] !== undefined) ? moment(fieldValue[contentItem.name]).format('YYYY-MM-DD') : ""}
        //   disabled={(formType === "view" || contentItem.edit === false) ? true : false}
        //   InputLabelProps={{
        //     shrink: true,
        //   }}
        // />
      )
    }
  }
  function getButtons() {
    let btns = []
    for (let i = 0; i < buttons.length; i++) {
      // let includeSearch = dataField.includes(searchField)
      if (buttons[i].name.includes("find") === true) {
        btns.push(
          <BootstrapTooltip title={buttons[i].label}>
            <button
              className="mainFormSearchButton"
              onClick={() => buttonClick(buttons[i].name, null)}
            ><BsSearch style={{ marginTop: 2 }} />
            </button>
          </BootstrapTooltip>
        )
      }
      else if (buttons[i].name.includes("create") === true) {
        btns.push(
          <BootstrapTooltip title={buttons[i].label}>
            <button
              className="mainFormCreateButton"
              onClick={() => buttonClick(buttons[i].name, null)}
            ><IoMdAdd size="16" style={{ marginTop: 2 }} />
            </button>
          </BootstrapTooltip>
        )
      }
      else if (buttons[i].name.includes("update") === true || buttons[i].name.includes("save") === true) {
        btns.push(
          <BootstrapTooltip title={buttons[i].label}>
            <button
              className="mainFormUpdateButton"
              onClick={() => buttonClick(buttons[i].name, null)}
            ><FaRegSave size="16" style={{ marginTop: 2 }} />
            </button>
          </BootstrapTooltip>
        )
      }
      else if (buttons[i].name.includes("delete") === true) {
        btns.push(
          <BootstrapTooltip title={buttons[i].label}>
            <button
              className="mainFormDeleteButton"
              onClick={() => buttonClick(buttons[i].name, null)}
            ><MdOutlineDeleteOutline size="16" style={{ marginTop: 2 }} />
            </button>
          </BootstrapTooltip>
        )
      }
      else if (buttons[i].name.includes("back") === true) {
        btns.push(
          <BootstrapTooltip title={buttons[i].label}>
            <button
              className="mainFormBackButton"
              onClick={() => buttonClick(buttons[i].name, null)}
            ><IoMdArrowBack size="16" style={{ marginTop: 2 }} />
            </button>
          </BootstrapTooltip>
        )
      }


      else {
        btns.push(
          <button
            className="mainFormButton"
            style={{
              border: `1px solid ${buttons[i].backgroundColor}`,
              background: buttons[i].backgroundColor,
              color: buttons[i].fontColor
            }}
            onClick={() => buttonClick(buttons[i].name, null)}
          >{buttons[i].label}
          </button>
        )
      }

    }
    return btns
  }
  function getEnumLabel(name, id) {
    for (let d = 0; d < enumData[name].length; d++) {
      if (enumData[name][d].id === id) {
        return enumData[name][d].label
      }
    }
  }
  function dynamicSort(property, sortOrder, type) {
    if (type === "DateTime" || type === "Bool") {
      sortOrder = sortOrder * -1
    }
    if (type === "DateTime") {
      return function (a, b) {
        if (a[property] !== null && b[property] !== null) {
          let dateA = new Date(a[property].substring(0, 19))
          let timeInSecA = dateA.getTime() / 1000
          // console.log("timeInSecA", timeInSecA)
          let dateB = new Date(b[property].substring(0, 19))
          let timeInSecB = dateB.getTime() / 1000
          // console.log("timeInSecB", timeInSecB)
          var result = (timeInSecA < timeInSecB) ? -1 : (timeInSecA > timeInSecB) ? 1 : 0
          return result * sortOrder
        }
        else {
          if (a[property] === null) {
            return -1 * sortOrder
          }
          return 1 * sortOrder
        }
      }
    }
    else if (type === "Int" || type === "Text" || type === "Float" || type === "Bool") {
      return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
        return result * sortOrder
      }
    }
    else if (type === "Enum") {
      return function (a, b) {
        if (a[property] === null) {
          return 1 * sortOrder
        }
        else {
          let labelA = getEnumLabel(property, a[property])
          // console.log("A", property, a[property], labelA)
          let labelB = getEnumLabel(property, b[property])
          // console.log("labelB", labelB, property, b)
          var result = (labelA < labelB) ? -1 : (labelA > labelB) ? 1 : 0
          return result * sortOrder
        }

      }
    }
  }
  function sortDataByColumn(name, type) {
    let sortOrder = 1
    if (sortedColumn === name) {
      sortOrder = sortedColumnOrder * -1
    }
    setSortedColumnOrder(sortOrder)
    setSortedColumn(name)
    let sortedDocList = filteredDocList.sort(dynamicSort(name, sortOrder, type))
    setPage(1)
    let fetchFrom = 0
    let fetchTo = size - 1
    setFilteredDocList(sortedDocList)
    fetchBySize(fetchFrom, fetchTo, sortedDocList)
  }
  function getModalStyle() {
    const top = 10;
    const left = 15;
    return {
      width: isSearchForm === true ? "1200px" : "900px",
      height: "600px",
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      zIndex: componentIndex
    };
  }
  function formClick() {
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 1)
    props.setComponentIndex(currIndex + 1)
    // console.log("F CLICK ", currIndex + 1)
  }

  function getBackground(item, index) {
    if (selectedItem.id === item.id) {
      return "#CECECE"
    }
    else {
      if (index % 2 === 0) {
        return "white"
      }
      else {
        // return "#F3F3F3"
        return "#def3f7" // blue
      }
    }
    // if (selectedItem.id === item.id) {
    //   if (item.bgColor) {
    //     if (item.bgColor === "white") {
    //       return "#C7EEFF"
    //     }
    //     else {
    //       return invert(item.bgColor)
    //     }
    //   }
    //   else {
    //     return "#C7EEFF"
    //   }
    // }
    // else {
    //   if (item.bgColor) {
    //     return item.bgColor
    //   }
    //   else {
    //     return "white"
    //   }
    // }
  }
  function getColor(item) {
    if (selectedItem.id === item.id) {
      if (item.textColor) {
        if (item.textColor === "black") {
          return "black"
        }
        else {
          return "white"
        }
      }
      else {
        return "black"
      }
    }
    else {
      if (item.textColor) {
        return item.textColor
      }
      else {
        return "black"
      }
    }
  }
  function showSearchForm() {
    if (isSearchForm === true) {
      if (taskType === "showMarketEditForm" || taskType === "showSectorEditForm") {
        return false
      }
      else {
        return true
      }
    }
  }
  function positionHandler(e, data) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 2 // Take 2% of width
    let maxDeviationX = (dimension.w / 100) * 90 // Take 90% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    // console.log("minX", minDeviationX, "maxX", maxDeviationX, "maxY", maxDeviationY)
    if (data.y < maxDeviationY) {
      // console.log("SET MIN Y", data.x)
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      // console.log("SET MIN X", data.x, minDeviationX)
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      // console.log("SET MAX X", data.x)
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
    // if (updProfile !== false) {
    //   let settings = {
    //     id: props.settings.id,
    //     type: props.settings.type,
    //     columns: props.settings.columns,
    //     position: { x: x, y: y },
    //     size: { h: size.h, w: size.w }
    //   }
    //   props.updateUserSettingsByType(settings)
    // }
  }
  function getWidth() {
    let w = isSearchForm === true ? "1400px" : "700px"
    if (gridForm !== null) {
      let l = 0
      for (let s = 0; s < gridForm.sections.length; s++) {
        for (let c = 0; c < gridForm.sections[s].contents.length; c++) {
          // console.log("LAB", gridForm.sections[s].contents[c].label, gridForm.sections[s].contents[c].label.length)
          if (gridForm.sections[s].contents[c].show === true) {
            l += gridForm.sections[s].contents[c].label.length * 24
          }
        }
      }
      if (l >= 1390) {
        l = 1400
      }
      else if (l < 650) {
        l = 650
      }
      // console.log("LLL", l, w)
      w = l
    }
    return w
  }
  if (updateState !== null) {
    try {
      return (
        <Draggable
          handle="p"
          onStart={() => formClick()}
          onStop={positionHandler}
          position={position}
        >
          {/* <div style={getModalStyle()} className={classes.resizeForm} onClick={() => formClick()}> */}
          <div
            className={classes.resizeForm}
            style={{ height: "600px", width: getWidth(), top: "15%", left: "2%", transform: "translate(-15%, -2%)", zIndex: componentIndex }}
            onClick={() => formClick()}
          >
            <p className="main-table-label-p-style-new">
              <table className="dragble-div-header-table-styl-new">
                <tr>
                  <td width="99%" className="dragble-div-header-td-style-new">{Form.label}</td>
                  <td>
                    <IoMdClose size={22} className="form-close-icons-style" style={{ marginRight: "5px" }} onClick={() => buttonClick("close")} />
                  </td>
                </tr>
              </table>
            </p>
            <div className="dragble-div-body-style-new">
              {/* Create grid table with data from doclist */}
              {/* {isSearchForm !== null && isSearchForm === true */}
              {showSearchForm() === true
                ?
                <Drawer anchor={"bottom"} open={openSearch} onClose={() => setOpenSearch(false)}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={0}
                  >
                    <Grid item xs={10}>
                      <table style={{ minHeight: 550 }} width="100%">
                        <table width="100%">
                          <tr>
                            <td align="center">
                              <table width="60%" style={{ paddingBottom: 70 }}>
                                <tr>
                                  <td width="60%" align="center"> {getForm()}</td>
                                </tr>
                                <tr>
                                  <td align="center">
                                    {buttons.map((button, index) => (
                                      <button
                                        className="mainFormButton"
                                        style={{
                                          border: `1px solid ${button.backgroundColor}`,
                                          background: button.backgroundColor,
                                          color: button.fontColor
                                        }}
                                        onClick={() => buttonClick(button.name, null)}
                                      >{button.label}
                                      </button>
                                      // <Button
                                      //   name={button.name}
                                      //   variant="outlined"
                                      //   onClick={() => buttonClick(button.name, null)}
                                      //   style={{
                                      //     margin: 3,
                                      //     color: button.fontColor,
                                      //     backgroundColor: button.backgroundColor,
                                      //     height: 30,
                                      //     fontSize: 12
                                      //   }}
                                      // >{button.label}
                                      // </Button>
                                    ))}
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </table>
                    </Grid>
                  </Grid>
                </Drawer>
                :
                getForm()
              }
              <Grid container direction="row" justify="center" spacing={0} style={{ paddingTop: 6 }}>
                {getButtons()}
                {/* {buttons.map((button, index) => (
                  <button
                    className="mainFormButton"
                    style={{
                      border: `1px solid ${button.backgroundColor}`,
                      background: button.backgroundColor,
                      color: button.fontColor
                    }}
                    onClick={() => buttonClick(button.name, null)}
                  >{button.label}
                  </button>
                ))} */}
              </Grid>
              {docList !== null && gridForm !== null &&
                <Grid container direction="row" justify="center" style={{ paddingTop: 10 }}>
                  <Grid item sm={"auto"}>
                    <Paper>
                      <div style={{ height: "400px", width: "100%", minWidth: "600px", overflow: "auto" }}>
                        <table className="grid-table-style" id={mainFormGridId}>
                          <thead className="grid-table-thead-style" style={{ position: "sticky", top: 0, zIndex: 9999 }}>
                            {/* 1st Row Sections Labels */}
                            <tr>
                              <td colSpan="1" className="grid-table-td-head-first-child"></td>
                              {gridForm.sections.map((section, index) => {
                                let showSection = checkToShowSection(section)
                                if (showSection === true) {
                                  let lastSection = checkSectionOnLastChild(index)
                                  return (
                                    <td
                                      class={lastSection === true ? "grid-table-td-head-last-child" : "grid-table-thead-style"}
                                      colSpan={section.contents.length}
                                    >{section.label}</td>
                                  )
                                }
                              })}
                            </tr>
                            {/* 2nd Row Sections Contents labels */}
                            <tr>
                              <td
                                class="grid-table-td-head-style-2row"
                                rowSpan="2"
                                style={{ minWidth: "85px", width: "85px" }}
                              >
                                Действие
                              </td>
                              {gridForm.sections.map(section =>
                                section.contents.map(contentItem => {
                                  if (contentItem.show === true) {
                                    return (
                                      <td
                                        class="grid-table-td-head-style-2row"
                                        rowSpan="2"
                                        onClick={() => sortDataByColumn(contentItem.name, contentItem.type)}
                                      >
                                        {contentItem.label}
                                      </td>
                                    )
                                  }
                                })
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(docList).length !== 0 &&
                              docList.map((docListItem, index) => (
                                <tr
                                  // style={{ "height": 30 }}
                                  style={{ background: docListItem.bgColor, color: docListItem.textColor }}
                                  onContextMenu={(ev) => handleDocListItemContextMenu(ev)}
                                  onMouseDown={() => setSelectedItem(docListItem)}
                                >
                                  <td
                                    class="grid-table-td-style"
                                    style={{ "maxWidth": 34 }}
                                  >
                                    {gridFormButtons !== "null" &&
                                      gridFormButtons.map(button =>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"
                                          className="gridFormEditButton" onClick={() => buttonClick(button.name, docListItem)} >
                                          <path style={{ fill: '#337e86' }} d="M180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h279v60H180v600h600v-279h60v279q0 24-18 42t-42 18H180Zm202-219-42-43 398-398H519v-60h321v321h-60v-218L382-339Z" />
                                        </svg>
                                        // <BiEdit size="20" className="gridFormEditButton" onClick={() => buttonClick(button.name, docListItem)} />
                                      )}
                                    {/* {gridFormButtons !== "null" &&
                                      gridFormButtons.map(button =>
                                        <button
                                          className="approveSmallButton"
                                          onClick={() => buttonClick(button.name, docListItem)}
                                        >
                                          {button.label}
                                        </button>
                                      )} */}
                                  </td>
                                  {
                                    gridForm.sections.map(section => (
                                      section.contents.map(contentItem => {
                                        if (contentItem.show === true) {
                                          return (
                                            <td align="left" style={{ color: "black", fontSize: 12, "text-align": "center", "border-bottom": "1px solid grey" }}>
                                              {getGridFormItems(docListItem, contentItem)}
                                            </td>
                                          )
                                        }
                                      })
                                    ))
                                  }
                                </tr>
                              )
                              )}
                          </tbody>
                        </table>
                      </div>
                      <table width="100%">
                        <tfoot height="44px" >
                          <tr>
                            <td width="50%" align="left">
                              <tr>
                                <td style={{ paddingLeft: "15px" }}>
                                  <div style={{ color: "#868686" }}>Количество строк:</div>
                                </td>
                                <td style={{ paddingLeft: "3px" }}>
                                  <FormControl>
                                    <GridSelect
                                      onChange={handleChangeRowsPerPage}
                                      style={{ height: 21, color: "#666666", marginLeft: "3px" }}
                                      value={size}
                                    >
                                      <MenuItem value={10}>10</MenuItem>
                                      <MenuItem value={20}>20</MenuItem>
                                      <MenuItem value={50}>50</MenuItem>
                                      <MenuItem value={100}>100</MenuItem>
                                      <MenuItem value={200}>200</MenuItem>
                                      <MenuItem value={500}>500</MenuItem>
                                    </GridSelect>
                                  </FormControl>
                                </td>
                              </tr>
                            </td>
                            <td width="50%" align="right">
                              <tr>
                                <td>
                                  <Tooltip title="На предыдущюю">
                                    <ArrowBackIosIcon style={{ fontSize: "small", color: "black", cursor: "pointer" }} onClick={() => KeyboardArrowLeftClick(page)} />
                                  </Tooltip>
                                </td>
                                <td>
                                  <div
                                    style={{
                                      cursor: "pointer",
                                      fontSize: 14,
                                      verticalAlign: "middle",
                                      textAlign: "center",
                                      height: "22px",
                                      width: "22px",
                                      color: page === 1 ? "white" : "black",
                                      background: page === 1 ? "#4FA4AD" : "white"
                                    }}
                                    onClick={() => GoToPage(1)}
                                  >
                                    <div style={{ marginTop: "2px", marginRight: "1px" }}>1</div>
                                  </div>
                                </td>
                                {page > 4 ?
                                  <React.Fragment>
                                    <td>
                                      <div
                                        style={{
                                          fontSize: 14,
                                          verticalAlign: "middle",
                                          textAlign: "center",
                                          height: "22px",
                                          width: "22px",
                                          color: "black",
                                          background: "white"
                                        }}
                                      >
                                        ...
                                      </div>
                                    </td>
                                    {getPages(page - 1, page + 1)}
                                  </React.Fragment>
                                  :
                                  getPages(2, 5)
                                }

                                {Math.floor(filteredDocList.length / size + 1) - page > 2 && filteredDocList.length / size > 5 &&
                                  <td>
                                    <div
                                      style={{
                                        fontSize: 14,
                                        verticalAlign: "middle",
                                        textAlign: "center",
                                        height: "22px",
                                        width: "22px",
                                        color: "black",
                                        background: "white"
                                      }}
                                    >
                                      ...
                                    </div>
                                  </td>
                                }
                                {filteredDocList.length / size > 1 && getPage(Math.floor(filteredDocList.length / size + 1))}
                                <td>
                                  <Tooltip title="На предыдущюю">
                                    <ArrowForwardIosIcon style={{ fontSize: "small", color: "black", cursor: "pointer", marginLeft: "2px", marginRight: "40px" }} onClick={() => KeyboardArrowRightClick(page)} />
                                  </Tooltip>
                                </td>
                              </tr>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                      {/* MarketSectors */}
                      <Menu
                        anchorEl={anchorElMarketSectors}
                        open={openMenuMarketSectors}
                        onClose={handleCloseMenu}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                        style={{ paddingLeft: "100px" }}
                      >
                        <MenuItem onClick={() => buttonClick("openTradesBySector", selectedItem)} style={{ color: "black" }}>
                          <ListItemIcon style={{ color: "black" }}>
                            <LockOpenIcon fontSize="small" />
                          </ListItemIcon>
                          Открыть торги
                        </MenuItem>
                        <MenuItem onClick={() => buttonClick("closeTradesBySector", selectedItem)} style={{ color: "black" }}>
                          <ListItemIcon style={{ color: "black" }}>
                            <LockIcon fontSize="small" />
                          </ListItemIcon>
                          Закрыть торги
                        </MenuItem>
                        <MenuItem onClick={() => buttonClick("showAccountPositionsTradingBySectorId", selectedItem)} style={{ color: "black" }}>
                          <ListItemIcon style={{ color: "black" }}>
                            <AccountBalanceWalletIcon fontSize="small" />
                          </ListItemIcon>
                          Торговые счета
                        </MenuItem>
                        <MenuItem onClick={() => buttonClick("showAccountPositionsCurrenciesBySectorId", selectedItem)} style={{ color: "black" }}>
                          <ListItemIcon style={{ color: "black" }}>
                            <LocalAtmIcon fontSize="small" />
                          </ListItemIcon>
                          Денежные счета
                        </MenuItem>
                      </Menu>
                      {/* RepoObligations */}
                      <Menu
                        anchorEl={anchorElRepoObligations}
                        open={openMenuRepoObligations}
                        onClose={handleCloseMenu}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                        style={{ paddingLeft: "100px" }}
                      >
                        {selectedItem.buyOrgId === userProfile.organization.id && selectedItem.isBought === false &&
                          <MenuItem onClick={() => buttonClick("buyRepo", selectedItem)} style={{ color: "black" }}>
                            <ListItemIcon style={{ color: "black" }}>
                              <AccountBalanceWalletIcon fontSize="small" />
                            </ListItemIcon>
                            Выполнить покупку
                          </MenuItem>
                        }
                        {selectedItem.sellOrgId === userProfile.organization.id && selectedItem.isSold === false &&
                          <MenuItem onClick={() => buttonClick("sellRepo", selectedItem)} style={{ color: "black" }}>
                            <ListItemIcon style={{ color: "black" }}>
                              <LocalAtmIcon fontSize="small" />
                            </ListItemIcon>
                            Выполнить продажу
                          </MenuItem>
                        }
                        {selectedItem.isBought === false && selectedItem.isSold === false &&
                          <MenuItem onClick={() => buttonClick("prolongateRepo", selectedItem)} style={{ color: "black" }}>
                            <ListItemIcon style={{ color: "black" }}>
                              <ReceiptLongIcon fontSize="small" />
                            </ListItemIcon>
                            Продлить
                          </MenuItem>
                        }
                        {(selectedItem.isBought === false || selectedItem.isSold === false) && selectedItem.closeInitiated === false &&
                          <MenuItem onClick={() => buttonClick("cancelRepo", selectedItem)} style={{ color: "black" }}>
                            <ListItemIcon style={{ color: "black" }}>
                              <CancelIcon fontSize="small" />
                            </ListItemIcon>
                            Отменить
                          </MenuItem>
                        }
                        {selectedItem.closeInitiated === true && userProfile.organization.id !== selectedItem.closeInitiatedOrgId &&
                          <MenuItem onClick={() => buttonClick("approveCancelRepo", selectedItem)} style={{ color: "black" }}>
                            <ListItemIcon style={{ color: "black" }}>
                              <CancelIcon fontSize="small" />
                            </ListItemIcon>
                            Подтвердить отмену
                          </MenuItem>
                        }

                      </Menu>
                    </Paper>
                  </Grid>
                </Grid>
              }
              <br />
              {showAttachedDocuments === true &&
                <Paper>
                  <Grid item>
                    <Grid container direction="row">
                      <div className={classes.importFile}>
                        <input
                          accept="image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          className={classes.inputFile}
                          id={buttonFiles}
                          multiple
                          type="file"
                          onChange={handleAttachFile}
                        />
                        <label htmlFor={buttonFiles}>
                          <Button
                            component="span"
                            variant="outlined"
                            style={{
                              margin: 3,
                              color: "white",
                              backgroundColor: "#BB7100",
                              borderColor: "#161C87",
                              height: 32,
                              fontSize: 12
                            }}
                            endIcon={<AttachFileIcon />}
                          >Прикрепить файлы
                          </Button>
                        </label>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} justify="flex-start" alignItems="flex-start" className={classes.root}>
                    <Grid item xs={"auto"}>
                      <Grid container direction="column" spacing={1}>
                        <div style={{ paddingLeft: 10 }}>Прикрепленные документы</div>
                        {attachedDocsList()}
                        {attachedImgsList()}
                      </Grid>
                    </Grid>
                  </Grid>
                  {showSavedDocuments === true &&
                    <Grid container spacing={2} justify="flex-start" alignItems="flex-start" className={classes.root}>
                      <Grid item xs={"auto"}>
                        <Grid container direction="column" spacing={1}>
                          <div style={{ paddingLeft: 10 }}>Сохраненные документы</div>
                          {savedDocsList()}
                          {savedImgsList()}
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                </Paper>
              }
              <Snackbar
                open={showSnackBar}
                onClose={() => handleCloseSnackBar()}
                autoHideDuration={1200}
                message={snackBarMessage}
              />
            </div>
          </div>
        </Draggable>
      )
    }
    catch (er) {
      console.log("ERROR", er)
      return <LinearProgress />
    }
  }
  else {
    return <div>Loading...</div>
  }
};