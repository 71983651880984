import React, { useState, useEffect, useLayoutEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, IconButton } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
// import ReactTooltip from 'react-tooltip';
// import Typography from '@material-ui/core/Typography';
// import NumberFormat from 'react-number-format';
// import PropTypes from 'prop-types';
// import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
// import ButtonGroup from '@mui/material/ButtonGroup';
// import Button from '@mui/material/Button';
import Collapse from "@material-ui/core/Collapse";
// import CssBaseline from '@mui/material/CssBaseline';
import Divider from "@material-ui/core/Divider";
// import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
// PIN components
import Modal from '@material-ui/core/Modal';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';



// BackDrop
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// Icons
import ReplayIcon from '@mui/icons-material/Replay';
import logo from "./Images/logoWhite.png";
import menuIcon from "./Images/MenuIcon.png";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';

// import { IoReloadOutline } from 'react-icons/io';
import { AiOutlineReload } from 'react-icons/ai';
import { MdAccountTree } from 'react-icons/md';
import { BsFillPeopleFill } from 'react-icons/bs';
import { FaFileDownload } from 'react-icons/fa';
import { RiFileEditFill } from 'react-icons/ri';
// import { RiAccountPinBoxLine } from 'react-icons/ri';
import { BsBookHalf } from 'react-icons/bs';
import { IoNewspaper } from 'react-icons/io5';
import { GoReport } from 'react-icons/go';
import { MdEmail } from 'react-icons/md';

import { RiBankFill } from 'react-icons/ri';
import { MdTimer } from 'react-icons/md';
import TimerIcon from '@mui/icons-material/Timer';

//Иконки строки управления
import BuyIcon from '@material-ui/icons/FormatBold'; //Продажа
import SellIcon from '@material-ui/icons/MonetizationOn'; //Покупка
import OnlineTime from "./OnlineTime"; //Онлайн время
import ExitToAppIcon from '@material-ui/icons/ExitToApp'; //Выход из системы
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'; // Фиксинг
import CardTravelIcon from '@mui/icons-material/CardTravel';

// CUSTOM COMPONENTS
import ComponentManager from "./ComponentManager.jsx";
import Instruments from "./Instruments.jsx";
import InstrumentFilters from "./InstrumentFilters.jsx";
import InstrumentProperties from "./InstrumentProperties.jsx";
import Charts from "./Charts.jsx";
import Quotations from "./Quotations.jsx";
import Fixing from "./Fixing.jsx";
import Bids from "./Bids.jsx";
import BidProperties from "./BidProperties.jsx";
import Deals from "./Deals.jsx";

import AuctionList from "./AuctionList.jsx";
import AuctionOpenTrades from "./AuctionOpenTrades.jsx";
import AuctionBidForm from "./AuctionBidForm.jsx";
import AuctionDetails from "./AuctionDetails.jsx";
import AuctionDetailsCDMinfin from "./AuctionDetailsCDMinfin.jsx";

import OrderSell from "./OrderSell.jsx";
import OrderBuy from "./OrderBuy.jsx";
import RepoOrder from "./RepoOrder.jsx";
import AcceptRepoOrder from "./AcceptRepoOrder.jsx";
import RepoProlongation from "./RepoProlongation.jsx";
import CreateDirectOrder from "./CreateDirectOrder.jsx";
import ConfigurationFile from "../configuration/ConfigurationFile.json";
import TradingScheduleManagement from "./TradingScheduleManagement.jsx";
import TradingScheduleManagementCreateEditForm from "./TradingScheduleManagementCreateEditForm.jsx";
import DirectOrders from "./DirectOrders.jsx";
import DirectOrderDetails from "./DirectOrderDetails.jsx";
import UsersMain from "./UsersMain.jsx";
import OpenFixingTrades from "./OpenFixingTrades.jsx";
import CreateFixingOrder from "./CreateFixingOrder.jsx";
import AccountsCurrenciesPositions from "./AccountsCurrenciesPositions.jsx";
import AccountsCurrenciesDepositing from "./AccountsCurrenciesDepositing.jsx";
import AccountsInstrumentsDepositing from "./AccountsInstrumentsDepositing.jsx";
import AccountsInstrumentPositions from "./AccountsInstrumentPositions.jsx";
import FormManagement from "./FormManagement.jsx";
import ImportantMessage from "./ImportantMessage.jsx";
import Mails from "./Mails.jsx";
import Users from "./Users.jsx";
import Profile from "./Profile.jsx";
import Resize from "./Resize.jsx";

// Library
import WebFont from 'webfontloader';
import { ToastContainer, toast } from 'react-toastify'; // https://fkhadra.github.io/react-toastify/introduction/
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import swal from 'sweetalert'; // https://sweetalert.js.org/guides/
import hotkeys from 'hotkeys-js'; // https://github.com/jaywcjlove/hotkeys
import * as rutoken from "rutoken";
var fetch = require('node-fetch');
var plugin = null;
var rutokenHandle, certHandle, certData, cmsData;


//СТИЛИ
const useStyles = makeStyles((theme) => ({
  toolbar: {
    // textAlign: "center",
    background: "white",
    height: "76px"
  },
  appBarTextButton: {
    fontFamily: 'Arimo',
    color: "#8B8B8B",
    backgroundColor: '#EDEDED',
    "&:hover": {
      color: "#4A99A1",
    }
  },
  appBarIconButton: {
    color: "#8B8B8B",
    "&:hover": {
      color: "#4A99A1",
    }
  },
  appBarDisabledIconButton: {
    color: "#D3D3D3",
    // "&:hover": {
    //   color: "#4A99A1",
    // }
  },
  drButton: {
    border: "1px solid #F1F1F1",
    borderRadius: "0%",
    // color: "#4A99A1",
    height: "25px",
    width: "58px",
    fontFamily: "Helvetica",
    cursor: "pointer",
  },
  // Группы кнопок
  buttonGroup: {
    height: "28px",
    // width: "250px",
    marginTop: "6px",
    background: "#F3F3F3",

  },
  buttonInGroup: {
    padding: "0px"
  },

  // Строка управления
  lineMainMenu: {
    cursor: "pointer",
    color: "#354A55",
    fontSize: "23px",
  },
  lineMenuGrey: {
    cursor: "pointer",
    color: "#78909c",
    fontSize: "23px",
  },
  lineMenuGreen: {
    cursor: "pointer",
    color: "#238205",
    fontSize: "23px",
  },
  lineMenuDark: {
    color: "black",
    cursor: "pointer",
    fontSize: "23px",
  },

  lineMenuDisabled: {
    color: "#B2B3B4",
    cursor: "not-allowed",
    fontSize: "23px",
  },
  // Иконка Выход
  exit: {
    fontSize: '23px',
  },
  exitButton: {
    cursor: "pointer",
    position: "absolute",
    top: 5,
    right: 10,
    // zIndex: 99999,
    color: "#8D8D8D",
    "&:hover": {
      color: "black",
    }
  },

  // Стиль иконок выподающего меню
  listItemIcon: {
    // border: "1px inset #78909c",
    cursor: "pointer",
    color: "#0C0A10",
    height: "23px",
    width: "23px",
    borderBottom: "1px inset #78909c",
    borderTop: "1px inset #78909c"
  },
  // Стиль текста выподающего меню
  listItemText: {
    cursor: "pointer",
    paddingLeft: 5,
    fontFamily: "Courier",
    fontSize: 16,
    color: "#0C0A10",
    fontWeight: "bold"
  },
  menuIconStyle: {
    position: "absolute",
    top: 7,
    right: 10
  },
  kseTextSmall: {
    margin: "0px",
    fontFamily: "Helvetica",
    color: "white",
    textAlign: "left",
    fontSize: 10,
  },
  rutokenModal: {
    position: 'absolute',
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #AFAFAF',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))
function getRutokenModalStyle() {
  const top = 35;
  const left = 45;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
rutoken.ready
  // Проверка установки расширение 'Адаптера Рутокен Плагина' в Google Chrome
  .then(function () {
    if (window.chrome || typeof InstallTrigger !== 'undefined') {
      return rutoken.isExtensionInstalled()
    } else {
      return Promise.resolve(true)
    }
  })
  // Проверка установки Рутокен Плагина
  .then(function (result) {
    if (result) {
      return rutoken.isPluginInstalled()
    } else {
      // alert("Не удаётся найти расширение 'Адаптер Рутокен Плагина'");
    }
  })
  // Загрузка плагина
  .then(function (result) {
    if (result) {
      return rutoken.loadPlugin()
    } else {
      // alert("Не удаётся найти Плагин");
    }
  })
  //Можно начинать работать с плагином
  .then(function (result) {
    if (!result) {
      return // alert("Не удаётся загрузить Плагин")
    } else {
      plugin = result
      return Promise.resolve()
    }
  })
// ToolTip стили (подсказки)
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white
  },
  tooltip: {
    backgroundColor: "white",
    border: "1px solid #7A7A7A",
    fontFamily: 'Helvetica',
    color: "#7A7A7A",
    fontSize: 13,
    textAlign: "center",
    boxShadow: "4px 4px 4px 3px #C3C3C3"
  }
}))
const useStylesMenu = makeStyles(theme => ({
  list: {
    background: "#337E86",
    width: "300px",
    height: "100vh"
  },
  menuIcon: {
    color: "C6EBEF",
    marginTop: "6px",
    "&:hover": {
      color: "white",
    }
  },
  listItemText: {
    fontSize: 14,
    paddingLeft: "10px",
  },
  level1: {
    paddingLeft: theme.spacing(3),
    color: "#C6EBEF",
    "&:hover": {
      color: "white",
      // fontWeight: "bold"
    }
  },
  level2: {
    paddingLeft: theme.spacing(7),
    color: "#C6EBEF",
    "&:hover": {
      color: "white",
      // fontWeight: "bold"
    }
  },
  menuButtonIconBack: {
    background: "#4FA4AD",
    borderRadius: "50%",
    height: "35px",
    width: "35px"
  }
}))

function getHW() {
  const { innerWidth: width, innerHeight: height } = window;
  // console.log("H H", height, "W", width)
  return { h: height, w: width }
}

//Home(props) - получаем переменные от родителя App.js 
export default function Home(props) {
  const classes = useStyles()
  const classesMenu = useStylesMenu()
  const [menuKey, setMenuKey] = useState(null)
  const [menuItemStates, setMenuItemStates] = useState({ subMenuMarketsStructure: false, subMenuParticipants: false, subMenuExchangeFiles: false })
  const [openMainMenu, setOpenMainMenu] = useState(false)

  const [tasks, setTasks] = useState([])
  // const [task, setTask] = useState(null)
  const [taskVariables, setTaskVariables] = useState({})
  const [componentIndex, setComponentIndex] = useState(1)
  const [appBarComponentIndex, setAppBarComponentIndex] = useState(1)
  const [footerComponentIndex, setFooterComponentIndex] = useState(1)
  const [savedDocs, setSavedDocs] = useState([])
  const [updateRepoOrderList, setUpdateRepoOrderList] = useState(null)

  // GENERAL VARS
  const [wsEndpoint] = useState(props.wsEndpoint) //Local
  const [kseRESTApi] = useState(props.kseRESTApi) //Local KFB main REST
  const [token] = useState(props.token)
  // const [socket, setSocket] = useState(null)
  const [socket, setSocket] = useState(null)
  const [session_id, setSession_id] = useState(null)
  const [userProfile, setUserProfile] = useState(props.userProfile)

  // TABLES
  // Instruments
  const [instrumentTables, setInstrumentTables] = useState([])
  const [updateFinanceInstrumentsHead, setUpdateFinanceInstrumentsHead] = useState(null)
  const [updateFinanceInstrumentsBody, setUpdateFinanceInstrumentsBody] = useState(null)
  const [updateFinanceInstrumentsZIndex, setUpdateFinanceInstrumentsZIndex] = useState(null)
  const [charts, setCharts] = useState([])
  const [selectedInstrument, setSelectedInstrument] = useState({ id: null })
  const [selectedAccountId, setSelectedAccountId] = useState(null)
  // Disable  
  const [buyDisabled, setBuyDisabled] = useState(false)
  const [sellDisabled, setSellDisabled] = useState(false)
  const [fixingDisabled, setFixingDisabled] = useState(false)
  const [directDisabled, setDirectDisabled] = useState(false)
  const [repoDisabled, setRepoDisabled] = useState(false)

  const [directOrderDetails, setDirectOrderDetails] = useState([])
  // Instrument related forms
  const [quotations, setQuotations] = useState([])
  const [updateQuotations, setUpdateQuotations] = useState(null)
  const [showInstrumentFilters, setShowInstrumentFilters] = useState(false)
  const [instrumentFilters, setInstrumentFilters] = useState({})
  const [instrumentProperties, setInstrumentProperties] = useState([])

  // Bids
  const [bidsTables, setBidsTables] = useState([])
  const [updateBidsHead, setUpdateBidsHead] = useState(null)
  const [updateBidsBody, setUpdateBidsBody] = useState(null)
  const [bidsProperties, setBidsProperties] = useState([])
  const [updateBidsZIndex, setUpdateBidsZIndex] = useState(null)
  // Deals
  const [dealsTables, setDealsTables] = useState([])
  const [updateDealsHead, setUpdateDealsHead] = useState(null)
  const [updateDealsBody, setUpdateDealsBody] = useState(null)
  const [updateDealsZIndex, setUpdateDealsZIndex] = useState(null)
  // Fixing
  const [fixingTables, setFixingTables] = useState([])
  const [updateFinanceInstrumentsFixingHead, setUpdateFinanceInstrumentsFixingHead] = useState(null)
  const [updateFinanceInstrumentsFixingBody, setUpdateFinanceInstrumentsFixingBody] = useState(null)
  const [updateFinanceInstrumentsFixingZIndex, setUpdateFinanceInstrumentsFixingZIndex] = useState(null)
  // Users
  const [usersMainTables, setUsersMainTables] = useState([])
  const [updateMainUsers, setUpdateMainUsers] = useState(null)
  const [activeUsers, setActiveUsers] = useState([])
  const [updateMainUsersZIndex, setUpdateMainUsersZIndex] = useState(null)
  // Schedule
  const [tradingScheduleTables, setTradingScheduleTables] = useState([])
  const [selectedSchedule, setSelectedSchedule] = useState({})
  const [showScheduleCreateEditForm, setShowScheduleCreateEditForm] = useState(false)
  const [updateTradingSchedule, setUpdateTradingSchedule] = useState(false)
  const [updateTradingScheduleZIndex, setUpdateTradingScheduleZIndex] = useState(null)
  // Auction
  const [auctionTables, setAuctionTables] = useState([])
  const [updateAuction, setUpdateAuction] = useState(null)
  const [updateAuctionZIndex, setUpdateAuctionZIndex] = useState(null)
  const [showAuctionOpenTrades, setShowAuctionOpenTrades] = useState(false)
  const [showAuctionBidForm, setShowAuctionBidForm] = useState(false)
  const [auctionDetails, setAuctionDetails] = useState([])
  const [updateAuctionDetails, setUpdateAuctionDetails] = useState(null)
  const [auctionDetailsCDMinfin, setAuctionDetailsCDMinfin] = useState([])
  const [updateAuctionDetailsCD, setUpdateAuctionDetailsCD] = useState(null)
  // const [selectedAuction, setSelectedAuction] = useState({id: null})
  const [selectedLot, setSelectedLot] = useState({ id: null })

  // 
  const [formManagement, setFormManagement] = useState([])
  const [notAcceptedDirecrOrders, setNotAcceptedDirecrOrders] = useState(null)
  const [unreadMails, setUnreadMails] = useState(null)
  const [incomingPos, setIncomingPos] = useState(null)

  const [updateState, setUpdateState] = useState(false)
  // Forms
  const [formIndex, setFormIndex] = useState("")
  const [showOrderSell, setShowOrderSell] = useState(false)
  const [orderSellFields, setOrderSellFields] = useState(null)
  const [showOrderBuy, setShowOrderBuy] = useState(false)
  const [orderBuyFields, setOrderBuyFields] = useState(null)
  const [showCreateDirectOrder, setShowCreateDirectOrder] = useState(false)

  const [selectedBid, setSelectedBid] = useState({ id: null })
  const [showRepoOrder, setShowRepoOrder] = useState(false)
  const [showAcceptRepoOrder, setShowAcceptRepoOrder] = useState(false)
  const [showRepoProlongation, setShowRepoProlongation] = useState(false)
  const [selectedRepo, setSelectedRepo] = useState({ id: null })

  const [showDirectOrders, setShowDirectOrders] = useState(false)
  const [showOpenFixingTrades, setShowOpenFixingTrades] = useState(false)
  const [showCreateFixingOrder, setShowCreateFixingOrder] = useState(false)

  // AccountsPositions
  const [showAccountsCurrenciesPositions, setShowAccountsCurrenciesPositions] = useState(false)
  const [showAccountsCurrenciesDepositing, setShowAccountsCurrenciesDepositing] = useState(false)
  const [updateAccountsCurrenciesPositions, setUpdateAccountsCurrenciesPositions] = useState(null)
  const [updateAccountsCurrenciesPositionsZIndex, setUpdateAccountsCurrenciesPositionsZIndex] = useState(null)


  const [showAccountsInstrumentsPositions, setShowAccountsInstrumentsPositions] = useState(false)
  const [showAccountsInstrumentsDepositing, setShowAccountsInstrumentsDepositing] = useState(false)
  const [updateAccountsInstrumentPositions, setUpdateAccountsInstrumentPositions] = useState(null)
  const [updateAccountsInstrumentsPositionsZIndex, setUpdateAccountsInstrumentsPositionsZIndex] = useState(null)

  // const [showComponentManager, setShowComponentManager] = useState(false)

  const [showMails, setShowMails] = useState(false)
  const [selectedOrganization, setSelectedOrganization] = useState(null)

  // SubMenus
  //Exchange Files
  const [anchorElExchangeFiles, setAnchorElExchangeFiles] = useState(null)
  const openMenuExchangeFiles = Boolean(anchorElExchangeFiles)
  const openSubMenuExchangeFiles = (event) => { setAnchorElExchangeFiles(event.currentTarget) }
  // Market Sectors
  const [anchorElParticipants, setAnchorElParticipants] = useState(null)
  const openMenuParticipants = Boolean(anchorElParticipants)
  const openSubMenuParticipants = (event) => { setAnchorElParticipants(event.currentTarget) }
  // Markets Structure
  const [anchorElMarketsStructure, setAnchorElMarketsStructure] = useState(null)
  const openMenuMarketsStructure = Boolean(anchorElMarketsStructure)
  const openSubMenuMarketsStructure = (event) => { setAnchorElMarketsStructure(event.currentTarget) }

  const [showImportantMessage, setShowImportantMessage] = useState(false)
  const [importantMessage, setImportantMessage] = useState("")
  const [importantMessageTime, setImportantMessageTime] = useState(null)
  // BackDrop
  const [openBackDrop, setOpenBackDrop] = useState(false)
  const [backDropMessage, setBackDropMessage] = useState("")
  // const [height, setHeight] = useState(300)
  // const [width, setWidth] = useState(300)

  // ProfileMenu
  const [anchorElProfile, setAnchorElProfile] = useState(null)
  const openMenuProfile = Boolean(anchorElProfile)
  const [showProfile, setShowProfile] = useState(false)

  // Rutoken
  const [rutokenModalStyle] = useState(getRutokenModalStyle)
  const [showModalEnterPin, setShowModalEnterPin] = useState(false)
  const [rutokenPin, setRutokenPin] = useState("")
  const [showPin, setShowPin] = useState(false)
  const [wrongPin, setWrongPin] = useState(false)
  const [esUserFullName, setEsUserFullName] = useState(null)
  const [esInn, setEsInn] = useState(null)
  const [esExpiredDate, setEsExpiredDate] = useState(null)

  // const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  const Item = styled(Grid)(({ theme }) => ({
    // backgroundColor: '#EDEDED',
    whiteSpace: "nowrap",
    textOverflow: "elipsis",
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'center',
    cursor: "pointer"
  }));


  const handleCloseSubMenu = () => {
    setAnchorElExchangeFiles(null)
    setAnchorElParticipants(null)
    setAnchorElMarketsStructure(null)
  }
  const handleOpenMenuProfile = (event) => {
    event.preventDefault()
    setAnchorElProfile(event.currentTarget)
  }
  const handleCloseMenuProfile = () => {
    setAnchorElProfile(null)
  }

  // Main socket connection and data receiving
  useEffect(async () => {
    // exitSystemClick()
    // menuButtonClick("showMarketsSearchForm", "button", null)
    // menuButtonClick("showReportsSelectingForm", "button", null)
    // menuButtonClick("showMainRefSearchForm", "button", null)
    // menuButtonClick("showReportsSelectingForm", "button", null)


    // menuButtonClick("showPackagesExchanceWithCDPos", "button", null)
    // menuButtonClick("showPackagesExchanceWithCD", "button", null)

    // menuButtonClick("showCurrencyAccountsSearchForm", "button", null)
    // setShowOrderBuy(true)
    // setShowMails(true)
    // setShowAccountsInstrumentsPositions(true)
    // setShowAccountsCurrenciesPositions(true)
    // setShowCreateFixingOrderForm()
    // setShowOrderSell(true)
    // setShowCreateDirectOrder(true)
    // if(socket === null){
    setSocket(new WebSocket(wsEndpoint))
    console.log("CONNECTING...", wsEndpoint)
    // }
    fetchNotAcceptedDirecrOrders()
    fetchIncomingPos()
    fetchUnreadMails()
    let restore = false
    if (props.userProfile.rutokenRequired) {
      if (props.userProfile.rutokenRequired === true) {
        setShowModalEnterPin(true)
      }
      else {
        restore = true
      }
    }
    else {
      restore = true
    }
    if (restore === true) {
      restoreTables()
    }
    // setShowModalEnterPin(true)

    WebFont.load({
      google: {
        families: ['Droid Sans', 'Chilanka', 'Montserrat', 'Open Sans', 'Roboto', 'Noto Serif']
      }
    })
    window.addEventListener("beforeunload", updateProfile)
    return () => {
      window.removeEventListener("beforeunload", updateProfile)
    }
  }, [])
  useLayoutEffect(() => {
    document.body.style.backgroundColor = "#DEDEDE"
  });
  const updateProfile = (e) => {
    // e.preventDefault()
    // e.returnValue = ""
    // console.log("RELOAD")
    let appProfile = props.getProfile()
    updateUserSettings(appProfile.settings)
  }

  async function restoreTables() {
    // exitSystemClick()
    if (props.userProfile.settings !== null) {
      if (props.userProfile.settings.instruments) {
        let is = props.userProfile.settings.instruments
        setInstrumentTables(is)
      }
      if (props.userProfile.settings.fixing) {
        let fx = props.userProfile.settings.fixing
        setFixingTables(fx)
      }
      if (props.userProfile.settings.bids) {
        let bs = props.userProfile.settings.bids
        setBidsTables(bs)
      }
      if (props.userProfile.settings.deals) {
        let ds = props.userProfile.settings.deals
        setDealsTables(ds)
      }
      if (props.userProfile.settings.auction) {
        let auc = props.userProfile.settings.auction
        setAuctionTables(auc)
      }
      if (props.userProfile.settings.instrumentFilters) {
        let iFilt = props.userProfile.settings.instrumentFilters
        setInstrumentFilters(iFilt)
      }
      if (props.userProfile.settings.usersMain) {
        let usMain = props.userProfile.settings.usersMain
        setUsersMainTables(usMain)
      }
      if (props.userProfile.settings.tradingSchedule) {
        let trSchedule = props.userProfile.settings.tradingSchedule
        setTradingScheduleTables(trSchedule)
      }

    }
    else {
      let form = await fetchForm("69a31ec9-fef1-43f8-ac92-4e490670a509")
      let initInstrumentsSettings = [{
        type: "instruments",
        id: getUUID(),
        columns: []
      }]
      for (let c = 0; c < form.sections[0].contents.length; c++) {
        initInstrumentsSettings[0].columns.push({
          // type: form.sections[0].contents[c].type,
          name: form.sections[0].contents[c].name,
          label: form.sections[0].contents[c].label,
          // enumDef: form.sections[0].contents[c].enumDef,
          // edit: form.sections[0].contents[c].edit,
          show: true,
          // required: form.sections[0].contents[c].required
        })
      }
      // console.log("INIT SETTINGS", initInstrumentsSettings)
      setInstrumentTables(initInstrumentsSettings)
      let body = {
        instruments: initInstrumentsSettings
      }
      updateUserSettings(body)
      setShowInstrumentFilters(true)
      swAllert("Уважаемый пользователь. Добро пожаловать в торговую систему Кыргызской фондовой биржы. Для начала работы вы можете задать фильтры для финансовых инструментов!", "success")
    }
  }

  // RuToken functions
  // Sign with rutoken
  function sign(data) {
    var textToSign = data;
    if (textToSign.length == 0) {
      console.log("Не хватает текста для подписи");
      return;
    }
    if (plugin !== null) {
      // Перебор подключенных Рутокенов
      plugin.enumerateDevices()
        .then(function (devices) {
          if (devices.length > 0) {
            return Promise.resolve(devices[0]);
          } else {
            swAllert("Рутокен не обнаружен", "warning");
          }
        })
        // Проверка залогиненности
        .then(function (firstDevice) {
          rutokenHandle = firstDevice;
          return plugin.getDeviceInfo(rutokenHandle, plugin.TOKEN_INFO_IS_LOGGED_IN);
        })
        // Логин на первый токен в списке устройств PIN-кодом по умолчанию
        .then(function (isLoggedIn) {
          // console.log("PIN", rutokenPin)
          if (isLoggedIn) {
            return Promise.resolve();
          } else {
            return plugin.login(rutokenHandle, rutokenPin);
          }
        })
        // Перебор пользовательских сертификатов на токене
        .then(function () {
          return plugin.enumerateCertificates(rutokenHandle, plugin.CERT_CATEGORY_UNSPEC);
        })
        .then(function (certs) {
          if (certs.length > 0) {
            certHandle = certs[0];
            return plugin.parseCertificate(rutokenHandle, certs[0]);
          } else {
            alert("Сертификат на Рутокен не обнаружен");
          }
        })
        // Подписание данных из текстового поля на первом найденом сертификате
        .then(function (certs) {
          certData = certs
          console.log("RUTDATA", certData)
          // var r = plugin.getCertificateInfo(0, certs).then(function (res){console.log(res)})
          // console.log("res", r)
          if (certHandle.length > 0) {
            var options = {};

            let inn = ""
            let fullName = ""
            for (let i = 0; i < certData.subject.length; i++) {
              if (certData.subject[i].rdn === "serialNumber") {
                inn = certData.subject[i].value
              }
              if (certData.subject[i].rdn === "commonName") {
                fullName = certData.subject[i].value
              }
            }
            console.log("ESDATA", inn, fullName)
            setEsInn(inn)
            setEsUserFullName(fullName)
            setEsExpiredDate(certData.validNotAfter.substring(0, certData.validNotAfter.length - 1))
            restoreTables()
            handleCloseModalEnterPin()
            return plugin.sign(rutokenHandle, certHandle, textToSign, plugin.DATA_FORMAT_PLAIN, options);
          } else {
            alert("Сертификат на Рутокен не обнаружен")
          }
        })
        // Закрытие сессии
        .then(function () {
          plugin.logout(rutokenHandle)
        }, handleError)
    }
    else { callErrorToast("Вставьте рутокен, либо установите расширение для вашего браузера", "warning") }
  }
  // catch errors while signing docs
  function handleError(reason) {
    if (isNaN(reason.message)) {
      console.log(reason);
    } else {
      var errorCodes = plugin.errorCodes;
      switch (parseInt(reason.message)) {
        case errorCodes.PIN_INCORRECT:
          // alert("Неверный PIN");
          setWrongPin(true)
          break;
        default:
          console.log("Неизвестная ошибка");
      }
    }
  }

  const handleCloseModalEnterPin = () => {
    setShowModalEnterPin(false)
  }
  function handlePinChange(event) {
    console.log("PIN EV", event.target.value)
    setRutokenPin(event.target.value)
    setWrongPin(false)
    // console.log("PIN", event.target.value)
  }
  function handleClickShowPin() {
    setShowPin(!showPin)
  }
  function onKeyPressModalEnterPin(event) {
    console.log("KP EV", event.target.value)
    let code = event.charCode
    if (code === 13) {
      buttonClick("SignButton")
    }
  }
  function buttonClick(buttonName) {
    // console.log("Button", buttonName)
    if (buttonName === "SignButton") {
      console.log("button Sign: ")
      if (rutokenPin === "") {
        setWrongPin(true)
      }
      else {
        sign("WriteESDatatoLocalState")
      }
    }
    else {
      console.log("button: ", buttonName)
    }
  }

  if (socket !== null) {
    socket.onmessage = async function (message) {
      var incomingJson = JSON.parse(message.data)
      // console.log("Socket message", incomingJson)
      if (incomingJson.messageType === "session_id") {
        console.log("CONNECTED TO: ", wsEndpoint)
        console.log("NEW SESSION: ", incomingJson.session_id)
        setSession_id(incomingJson.session_id)
        socket.send(JSON.stringify({
          commandType: "setUserData",
          userId: userProfile.userId,
          userName: userProfile.firstName,
          session_id: incomingJson.session_id,
          userRole: userProfile.role.name,
          organization: userProfile.organization !== null ? userProfile.organization.id : null,
          token: "Bearer " + token
        }))
      }
      else if (incomingJson.messageType === "userDataInserted") {
        console.log("userDataInserted", incomingJson.session_id)
      }
      else if (incomingJson.messageType === "toast") {
        console.log("TOAST", incomingJson)
        if (incomingJson.toastType === "success") {
          callSuccessToast(incomingJson.toastText)
        }
        else if (incomingJson.toastType === "error") {
          callErrorToast(incomingJson.toastText)
        }
      }
      else if (incomingJson.messageType === "setSavedDocs") {
        console.log("SDOCS", incomingJson)
        setSavedDocs(incomingJson.savedDocs)
        setUpdateState(getUUID())
      }
      else if (incomingJson.messageType === "updateFinanceInstruments") {
        if (instrumentTables.length > 0) {
          setUpdateFinanceInstrumentsBody(getUUID())
        }
      }
      else if (incomingJson.messageType === "updateFinanceInstrumentsFixing") {
        if (fixingTables.length > 0) {
          setUpdateFinanceInstrumentsFixingBody(getUUID())
        }
      }
      else if (incomingJson.messageType === "updateActiveBids") {
        console.log("Update BIDS SOCKET")
        if (bidsTables.length > 0) {
          setUpdateBidsBody(getUUID())
        }
      }
      else if (incomingJson.messageType === "updateDeals") {
        if (dealsTables.length > 0) {
          setUpdateDealsBody(getUUID())
        }
      }
      else if (incomingJson.messageType === "updateQuotation") {
        // console.log("UPDATE QUOTATIONS", incomingJson)
        if (quotations.length > 0) {
          setUpdateQuotations(getUUID())
        }
      }
      else if (incomingJson.messageType === "updateMainUsers") {
        if (usersMainTables.length > 0) {
          // console.log("UPDATE updateMainUsers", incomingJson)
          setUpdateMainUsers(getUUID())
          setActiveUsers(incomingJson.activeUsers)
        }
      }

      else if (incomingJson.messageType === "updateAuctionList") {
        if (auctionTables.length > 0) {
          setUpdateAuction(getUUID())
        }
      }
      else if (incomingJson.messageType === "showImportantMessage") {
        // console.log("IMPORTANT MESSAGE", incomingJson)
        setImportantMessage(incomingJson.text)
        setShowImportantMessage(true)
        setImportantMessageTime(new Date())
      }
      else if (incomingJson.messageType === "setClearingState") {
        console.log("setClearingState MESSAGE", incomingJson)
        if (incomingJson.status === "started") {
          setOpenBackDrop(true)
          setBackDropMessage(incomingJson.text)
        }
        else {
          setOpenBackDrop(false)
          setBackDropMessage("")
          callSuccessToast("Клиринг завершен!")
        }
      }
    }
    socket.onclose = function (er) {
      console.log("CONNECTION CLOSED ", wsEndpoint)
      setSocket(new WebSocket(wsEndpoint))
    }
  }
  // hotkeys('ctrl+b, enter, f5', function (event, handler) {
  //   event.preventDefault()
  //   console.log("KEY", handler.key, event.charCode)
  //   // if (handler.key === "f5") {
  //   //   console.log("KEY", handler.key, event.charCode)
  // })
  function swAllert(text, icon) {
    return (
      swal({
        text: text,
        icon: icon,
        buttons: { ok: "Ок" },
      })
    )
  }
  function openProfile() {
    if (showProfile === false) {
      setShowProfile(true)
    }
    else {
      setShowProfile(false)
    }
    handleCloseMenuProfile()
  }
  //Выход из ситемы
  async function exitSystemClick() {
    handleCloseMenuProfile()
    let appProfile = await props.getProfile()
    // console.log("PROFILE TO UPDATE", appProfile)
    if (appProfile.settings !== null) {
      let success = await updateUserSettings(appProfile.settings)
      if (success === true) {
        localStorage.removeItem("token")
        window.location.reload()
      }
    }
    else{
      localStorage.removeItem("token")
      window.location.reload()
    }
  }
  function callSuccessToast(text, time) {
    let timeToCLose = 4000
    if (time) {
      timeToCLose = time
    }
    toast(text, {
      position: "top-right",
      autoClose: timeToCLose,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }
  function callErrorToast(text) {
    toast.error(text, {
      position: "top-right",
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }
  function menuButtonClick(name, type, event) {
    if (type !== "subMenu") {
      setOpenMainMenu(false)
    }
    handleCloseSubMenu()
    console.log("BUTTON", name)
    if (name === "showMainRefSearchForm") {
      let newTasks = [...tasks]
      newTasks.push({
        key: getUUID(),
        taskType: name,
        position: { x: 0, y: 0 },
        component: "mainForm",
        formDefId: "2aeadc9c-99f6-48fc-a2b3-a47c0670b109",
        buttons: "MainRefSearchBtns",
        gridFormDefId: "5fe6c5f6-ca17-4415-9d7d-57aed52cfad1",
        gridFormButtons: "MainRefGridBtns",
        docListApi: "/api/Directory/Gets"
      })
      setTasks(newTasks)
    }
    else if (name === "showUserSearchForm") {
      let newTasks = [...tasks]
      newTasks.push({
        key: getUUID(),
        taskType: name,
        component: "users",
        position: { x: 0, y: 0 },
        formDefId: "a0ea7b6a-c26a-4636-85fe-5b64c8b78cb2",
        buttons: "UserSearchBtns",
        gridFormDefId: "e5e74841-3d37-4835-8d15-9691d7902283",
        gridFormButtons: "UserGridBtns",
        docListApi: "/api/Users/GetUsers"
      })
      setTasks(newTasks)
    }
    else if (name === "showRepoObligationsSearchForm") {
      let newTasks = [...tasks]
      newTasks.push({
        key: getUUID(),
        taskType: name,
        position: { x: 0, y: 0 },
        component: "mainForm",
        formDefId: "8c2c4cd8-5a95-48a6-acb5-14772f29552b",
        buttons: "RepoObligationsSearchBtns",
        gridFormDefId: "b85ad455-fb35-46ed-a334-9756121e5c44",
        gridFormButtons: "RepoObligationsGridBtns",
        docListApi: "/api/Trading/GetRepoObligations"
      })
      setTasks(newTasks)
    }

    else if (name === "showReportsSelectingForm") {
      let newTasks = [...tasks]
      newTasks.push({
        key: getUUID(),
        taskType: name,
        position: { x: 0, y: 0 },
        component: "reports",
        formDefId: "34a48bee-bd0e-4755-8367-ecc91987dec6",
        buttons: "ReportSelectBtns",
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null
      })
      setTasks(newTasks)
    }
    else if (name === "showContractsSearchForm") {
      let newTasks = [...tasks]
      newTasks.push({
        key: getUUID(),
        taskType: name,
        position: { x: 0, y: 0 },
        component: "mainForm",
        formDefId: "d8746abe-738f-4c36-865c-4aa18ec11a25",
        buttons: "ContractsSearchBtns",
        gridFormDefId: "97ecfa3d-5ac1-44a2-81be-f42d05d7b517",
        gridFormButtons: "ContractsGridBtns",
        docListApi: "/api/Contracts"
      })
      setTasks(newTasks)
    }
    else if (name === "insertForm") {
      socket.send(JSON.stringify({ commandType: "insertForm", session_id: session_id, token: token }));
    }
    else if (name === "openSubMenuExchangeFiles") {
      console.log("OPEN", name)
      openSubMenuExchangeFiles(event)
    }
    else if (name === "openSubMenuMarketsStructure") {
      // console.log("OPEN", name)
      openSubMenuMarketsStructure(event)
    }

    else if (name === "openSubMenuParticipants") {
      console.log("OPEN", name)
      openSubMenuParticipants(event)
    }
    else if (name === "showInstrumentsExchangeHistorySearchForm") {
      let newTasks = [...tasks]
      newTasks.push({
        key: getUUID(),
        taskType: name,
        position: { x: 0, y: 0 },
        component: "mainForm",
        formDefId: "933d3792-7b16-4e12-abc7-629683e1731f",
        buttons: "InstrumentsExchangeWithCDSearchBtns",
        gridFormDefId: "a396daa8-9f72-400d-ba42-8d990826c652",
        gridFormButtons: "ContractsGridBtns",
        docListApi: "/api/ImportedInstruments"
      })
      setTasks(newTasks)
    }
    else if (name === "showPreviewORDAndTRDDateSelectForm") {
      let newTasks = [...tasks]
      newTasks.push({
        key: getUUID(),
        taskType: name,
        position: { x: 0, y: 0 },
        component: "mainForm",
        formDefId: "e70ce801-100f-4cfc-a557-f1315e12d977",
        buttons: "PreviewORDAndTRDDateSelectBtns",
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null
      })
      setTasks(newTasks)
    }
    else if (name === "showPackagesExchanceWithCD") {
      let newTasks = [...tasks]
      newTasks.push({
        key: getUUID(),
        taskType: name,
        position: { x: 0, y: 0 },
        component: "exchangeORDAndTRD",
        formDefId: "a3792487-869a-488d-973e-68363dae210f",
        buttons: "ExchangeWithCDPackagesSearchBtns",
        gridFormDefId: "0352f8db-c643-4f96-8d6d-74a83428b19a",
        gridFormButtons: "ExchangeWithCDPackagesGridBtns"
      })
      setTasks(newTasks)
    }
    else if (name === "showPackagesExchanceWithCDPos") {
      let newTasks = [...tasks]
      newTasks.push({
        key: getUUID(),
        taskType: name,
        position: { x: 0, y: 0 },
        component: "exchangeORDAndTRD",
        formDefId: "b42832cf-11d2-4016-b0e1-74d90862f706",
        buttons: "ExchangeWithCDPosSearchBtns",
        gridFormDefId: "688d95bd-acdc-4b60-9494-d54e9ab5c7a7",
        gridFormButtons: "ExchangeWithCDPosGridBtns"
      })
      setTasks(newTasks)
    }

    else if (name === "showTradingAccountsSearchForm") {
      let newTasks = [...tasks]
      newTasks.push({
        key: getUUID(),
        taskType: name,
        position: { x: 0, y: 0 },
        component: "mainForm",
        formDefId: "27799e9b-fdae-48ed-9d3b-f038b60e0918",
        buttons: "TradingAccountsSearchBtns",
        gridFormDefId: "633103b1-9c22-4bee-ba34-fe4983be5931",
        gridFormButtons: "TradingAccountsGridBtns",
        docListApi: "/api/Accounts/ViewAccounts"
      })
      setTasks(newTasks)
    }
    else if (name === "showCurrencyAccountsSearchForm") {
      let newTasks = [...tasks]
      newTasks.push({
        key: getUUID(),
        taskType: name,
        position: { x: 0, y: 0 },
        component: "mainForm",
        formDefId: "876908fd-d89f-44e8-9aa6-adf8ab7ff7f5",
        buttons: "CurrencyAccountsSearchBtns",
        gridFormDefId: "42025e99-7903-4e84-a0f1-a8b866af3405",
        gridFormButtons: "CurrencyAccountsGridBtns",
        docListApi: "/api/Accounts/ViewCurrencyAccounts"
      })
      setTasks(newTasks)
    }
    // else if (name === "showMSCurrenciesAccountsSearchForm") {
    //   let newTasks = [...tasks]
    //   newTasks.push({
    //     key: getUUID(),
    //     taskType: name,
    //     position: { x: 0, y: 0 },
    //     component: "mainForm",
    //     formDefId: "0eb33e42-27e5-420f-aed1-e88f862e2fbf",
    //     buttons: "CurrAccountsSearchBtns",
    //     gridFormDefId: "71c28d30-67dc-42d9-865f-5a724f1d3c92",
    //     gridFormButtons: "CurrencyAccountsGridBtns",
    //     docListApi: "/api/AccountPositions/ViewCurrencies"
    //   })
    //   setTasks(newTasks)
    // }
    else if (name === "showAccountsInstrumentsPositions") {
      setShowAccountsInstrumentsPositions(true)
    }
    else if (name === "showAccountsCurrenciesPositions") {
      openCurrencyPositions()
    }

    else if (name === "showCurrencyRatessSearchForm") {
      let newTasks = [...tasks]
      newTasks.push({
        key: getUUID(),
        taskType: name,
        position: { x: 0, y: 0 },
        component: "mainForm",
        formDefId: "72d475d6-997b-40f7-b3b6-d5fe86e50cbe",
        buttons: "CurrencyRatesSearchForm",
        gridFormDefId: "0bd3477d-7a5a-439d-9af1-c777d5fbbb79",
        gridFormButtons: "CurrencyRatesGridBtns",
        docListApi: "/api/CurrencyRates/ViewRates"
      })
      setTasks(newTasks)
    }

    else if (name === "showManageMarketsSearchForm") {
      let newTasks = [...tasks]
      newTasks.push({
        key: getUUID(),
        taskType: name,
        position: { x: 0, y: 0 },
        component: "mainForm",
        formDefId: "6002de36-198a-449e-b4bf-e623dd5962d6",
        buttons: "ManageMarketsSearchFormBtns",
        gridFormDefId: "60308605-426a-4755-a5b7-82f1c2c6ffa5",
        gridFormButtons: "ManageMarketsGridBtns",
        docListApi: "/api/ManageMarkets/Markets"
      })
      setTasks(newTasks)
    }
    else if (name === "showMarketsSearchForm") {
      let newTasks = [...tasks]
      newTasks.push({
        key: getUUID(),
        taskType: name,
        position: { x: 0, y: 0 },
        component: "mainForm",
        formDefId: "6002de36-198a-449e-b4bf-e623dd5962d6",
        buttons: "MarketsSearchFormBtns",
        gridFormDefId: "60308605-426a-4755-a5b7-82f1c2c6ffa5",
        gridFormButtons: "MarketsGridBtns",
        docListApi: "/api/Markets"
      })
      setTasks(newTasks)
    }
    else if (name === "showSectorsSearchForm") {
      let newTasks = [...tasks]
      newTasks.push({
        key: getUUID(),
        taskType: name,
        position: { x: 0, y: 0 },
        component: "mainForm",
        formDefId: "88efb94f-71ce-40bc-b15c-6373259f0c9f",
        buttons: "SectorsSearchFormBtns",
        selectedDoc: null,
        gridFormDefId: "b3342794-e451-43af-b3b0-4c2927fc2adf",
        gridFormButtons: "SectorsGridBtns",
        docListApi: "/api/MarketSectors"
      })
      setTasks(newTasks)
    }
    else if (name === "showGroupsSearchForm") {
      let newTasks = [...tasks]
      newTasks.push({
        key: getUUID(),
        taskType: name,
        position: { x: 0, y: 0 },
        component: "mainForm",
        formDefId: "2dc278a8-43cb-44d9-88f2-4b34a9973f47",
        buttons: "GroupsSearchFormBtns",
        selectedDoc: null,
        gridFormDefId: "3a6e90a5-6242-47c0-8eaa-3ccf5f656900",
        gridFormButtons: "GroupsGridBtns",
        docListApi: "/api/InstrumentGroups"
      })
      setTasks(newTasks)
    }

    else if (name === "showInstrumentsSearchForm") {
      let newTasks = [...tasks]
      newTasks.push({
        key: getUUID(),
        taskType: name,
        position: { x: 0, y: 0 },
        component: "mainForm",
        formDefId: "98de9783-fb31-4ed5-bd92-9f2b6e36740e",
        buttons: "InstrumentsSearchFormBtns",
        gridFormDefId: "c22f3b9d-15aa-41dc-b0ca-20d870cf73f1",
        gridFormButtons: "InstrumentsGridBtns",
        docListApi: "/api/FinanceInstruments"
      })
      setTasks(newTasks)
    }
    else if (name === "showOrganizationsSearchForm") {
      let newTasks = [...tasks]
      newTasks.push({
        key: getUUID(),
        taskType: name,
        position: { x: 0, y: 0 },
        component: "mainForm",
        formDefId: "754ab3f5-4eed-48dd-a25d-9409edbabc0d",
        buttons: "OrganizationsSearchFormBtns",
        gridFormDefId: "3b187d03-fc87-4e9f-91c8-009c119c8dc9",
        gridFormButtons: "OrganizationsGridBtns",
        docListApi: "/api/Organizations"
      })
      setTasks(newTasks)
    }
    else if (name === "showAuctionConfirmerForm") {
      openAuctionForm()
    }
    else {
      console.log("UNKNOUN TASK")
      // setTask(name)
    }
  }
  function openNewTask(newTask) {
    let newTasks = [...tasks]
    newTasks.push(newTask)
    setTasks(newTasks)
  }
  function setNewTask(inTask) {
    let newTasks = []
    for (let i = 0; i < tasks.length; i++) {
      if (tasks[i].key !== inTask.key) {
        newTasks.push(tasks[i])
      }
    }
    inTask.key = getUUID()
    newTasks.push(inTask)
    setTasks(newTasks)
    console.log("NEW TASK: ", inTask)
  }
  function closeTask(key) {
    let newTasks = []
    for (let i = 0; i < tasks.length; i++) {
      if (tasks[i].key !== key) {
        newTasks.push(tasks[i])
      }
      else {
        console.log("Task to close", key)
      }
    }
    setTasks(newTasks)
  }
  // function setNewTask(inTask, inTaskVariables){
  //   setTask(inTask)
  //   setTaskVariables(inTaskVariables)
  //   console.log("NEW TASK: ", inTask, inTaskVariables)
  //   setShowComponentManager(true)
  // }
  function fetchSavedFiles(directory) {
    socket.send(JSON.stringify({
      commandType: "getFilesFromDir",
      userId: userProfile.userId,
      session_id: session_id,
      directory: directory
    }))
    setUpdateState(getUUID())
  }
  function saveFilesToDir(directory, files) {
    socket.send(JSON.stringify({
      commandType: "saveFilesToDir",
      userId: userProfile.userId,
      session_id: session_id,
      directory: directory,
      blobs: files
    }))
    setUpdateState(getUUID())
  }
  // random UUID generator
  function getUUID() {
    return uuidv4()
  }
  // Collect enumData using form
  async function getEnumDataByForm(Form) {
    // console.log("COLLECT EN D", Form.sections)
    let enumData = {}
    for (var section = 0; section < Form.sections.length; section++) {
      for (var item = 0; item < Form.sections[section].contents.length; item++) {
        let type = Form.sections[section].contents[item].type
        if (type === "Enum" || type === "TransferList" || type === "CheckBoxList") {
          let enumName = Form.sections[section].contents[item].name
          let enumDef = Form.sections[section].contents[item].enumDef
          // let apiName = ConfigurationFile.enumConfig[enumDef].apiName
          let enumValues = await getEnumValues(enumDef)
          enumData[enumName] = enumValues
        }
      }
    }
    return enumData
  }
  // Collect enumData using list
  async function getEnumDataByList(list) {
    let enumData = {}
    for (let i = 0; i < list.length; i++) {
      let enumName = list[i].enumName
      let enumDef = list[i].enumDef
      console.log("ENUM ITEM", enumName, enumDef)
      let enumValues = await getEnumValues(enumDef)
      enumData[enumName] = enumValues
    }
    return enumData
  }
  // Request Enum Data from API
  async function getEnumValues(enumDef) {
    // console.log("ENUM ITEM", apiName, enumName, enumDef)
    var newEnumValues = await fetch(
      kseRESTApi + ConfigurationFile.enumConfig[enumDef].apiName,
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token }
      }
    )
      .then(response => response.json())
      .then(function (res) {
        // console.log("EN RESP", res)
        let eData = res.data
        let newEnumData = []
        let dataToCollect = ConfigurationFile.enumConfig[enumDef].data
        for (let key = 0; key < eData.length; key++) {
          let newItem = {}
          for (let item in dataToCollect) {
            if (item === "id") {
              newItem[item] = eData[key][ConfigurationFile.enumConfig[enumDef].data[item]]
            }
            else {
              let fullLetter = null
              for (let n = 0; n < ConfigurationFile.enumConfig[enumDef].data[item].length; n++) {
                let itemToAppend = ConfigurationFile.enumConfig[enumDef].data[item][n]
                if (eData[key][itemToAppend] !== null) {
                  if (itemToAppend === "-" || itemToAppend === " ") {
                    fullLetter += itemToAppend
                  }
                  else {
                    let newLetter = eData[key][itemToAppend]
                    // console.log("NEW LETTER", newLetter)
                    if (fullLetter === null) {
                      fullLetter = newLetter
                    }
                    else {
                      fullLetter = fullLetter + eData[key][itemToAppend]
                    }
                  }
                }
              }
              newItem[item] = fullLetter
            }
          }
          newEnumData.push(newItem)
        }
        // console.log("EEENUM DATA: ", newEnumData)
        return newEnumData
      })
      .catch(function (error) {
        return console.log("Collecting enum data error: ", error,)
      })
    // console.log("newEnumValues", newEnumValues)
    return newEnumValues
  }
  async function createEnumOptions(enums) {
    console.log("ENUMS", enums)
    let newEnumOptions = {}
    for (let key in enums) {
      let options = [{
        "value": null,
        "label": "Пусто",
        "name": key
      }]
      // newEnumOptions[key] = options
      for (let d = 0; d < enums[key].length; d++) {
        options.push({
          "value": enums[key][d].id,
          "label": enums[key][d].label,
          "name": key
        })
      }
      newEnumOptions[key] = options
    }
    console.log("newEnumOptions", newEnumOptions)
    return newEnumOptions
  }
  async function fetchForm(defid) {
    // console.log("GET FORM", defid)
    var form = await fetch(
      kseRESTApi + "/api/Metadata/GetByDefId?defid=" + defid,
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token }
      }
    )
      .then(response => response.json())
      .then(function (res) {
        let f = JSON.parse(res.data.data)
        // console.log("INIT FFF", res, JSON.parse(res.data.data))
        return JSON.parse(res.data.data)
      })
      .catch(function (error) {
        return console.log("Collecting Form error: ", error)
      })
    return form
  }
  async function fetchDocList(docListApi) {
    console.log("API", kseRESTApi + docListApi)
    let docList = await fetch(kseRESTApi + docListApi,
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token }
      }
    )
      .then(response => response.json())
      .then(function (res) {
        // console.log("GET DOCL", res)
        let data = []
        if (res.isSuccess === true || res.data) {
          data = res.data
        }
        else {
          callErrorToast(res.errors[0])
        }
        return data
      })
      .catch(function (error) {
        console.log("Collecting docList error: ", error)
        return []
      })
    return docList
  }
  async function getDocREST(api) {
    // console.log("API", kseRESTApi + api)
    let doc = await fetch(kseRESTApi + api,
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token }
      }
    )
      .then(response => response.json())
      .then(function (res) {
        return res
      })
      .catch(function (error) {
        console.log("Collecting doc error: ", error)
        return {}
      })
    return doc
  }
  async function fetchNotAcceptedDirecrOrders() {
    let dirOrders = await getDocREST("/api/Trading/GetNotAcceptedDirectRequests")
    // console.log("D ORDERS", dirOrders)
    if (dirOrders.data > 0) {
      setNotAcceptedDirecrOrders(dirOrders.data)
    }
  }
  async function fetchIncomingPos() {
    let incPos = await getDocREST("/api/DepositoryExchanges/PosCountByStatus")
    if (incPos.data > 0) {
      setIncomingPos(incPos.data)
    }
  }
  async function fetchUnreadMails() {
    let unreadMess = await getDocREST("/api/Messages/UnreadCount")
    if (unreadMess.data > 0) {
      setUnreadMails(unreadMess.data)
    }
  }

  // Custom ToolTip
  function BootstrapTooltip(props) {
    const clsToolTip = useStylesBootstrap();
    return <Tooltip arrow classes={clsToolTip} {...props} />;
  }

  function handleOpenMenuClick(name) {
    setMenuItemStates({ ...menuItemStates, [name]: !menuItemStates[name] })
    setMenuKey(getUUID())
  }

  const menuList = () => (
    <List className={classesMenu.list} key={menuKey}>
      <table style={{ paddingLeft: "25px" }}>
        <tr>
          <td>
            <img src={logo} alt="Logo" height={42} style={{ paddingTop: "5px" }} />
          </td>
          <td>
            <table style={{ paddingLeft: "5px" }}>
              <tr>
                <td className={classes.kseTextSmall}>KYRGYZ</td>
              </tr>
              <tr>
                <td className={classes.kseTextSmall}>STOCK</td>
              </tr>
              <tr>
                <td className={classes.kseTextSmall}>EXCHANGE</td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <Divider />
      {userProfile.role.name === "Administrator" &&
        <>
          {userProfile.userId === "badbea93-f213-4986-a94a-8a87e865884a" &&
            <ListItem
              button
              className={classesMenu.level1}
              onClick={(e) => menuButtonClick("insertForm", "button", null)}
            >
              <div className={classesMenu.menuButtonIconBack} align="center">
                <AiOutlineReload size={"75%"} className={classesMenu.menuIcon} />
                {/* <div><AiOutlineReload size={"25px"} className={classesMenu.menuIcon} style={{marginTop: "5px"}}/></div> */}
              </div>
              <div className={classesMenu.listItemText} style={{ "fontSize": 18 }}>Обновить формы</div>
            </ListItem>
          }
          <ListItem
            button
            className={classesMenu.level1}
            onClick={() => handleOpenMenuClick("subMenuMarketsStructure")}
            style={{ marginTop: "15px" }}
          >
            <div className={classesMenu.menuButtonIconBack} align="center">
              <MdAccountTree size={"68%"} className={classesMenu.menuIcon} />
            </div>
            <div className={classesMenu.listItemText} style={{ "fontSize": 18 }}>Структура рынков</div>
            {(menuItemStates.subMenuMarketsStructure === true) ? <ExpandLess style={{ paddingLeft: "5px" }} /> : <ExpandMore style={{ paddingLeft: "5px" }} />}
          </ListItem>
          <Collapse in={menuItemStates.subMenuMarketsStructure} timeout="auto" unmountOnExit>
            <ListItem button
              className={classesMenu.level2}
              onClick={() => menuButtonClick("showMarketsSearchForm", "button", null)}
            >
              <div className={classesMenu.listItemText}>Рынки</div>
            </ListItem>
            <ListItem button
              className={classesMenu.level2}
              onClick={() => menuButtonClick("showSectorsSearchForm", "button", null)}
            >
              <div className={classesMenu.listItemText}>Секторы</div>
            </ListItem>
            <ListItem button
              className={classesMenu.level2}
              onClick={() => menuButtonClick("showGroupsSearchForm", "button", null)}
            >
              <div className={classesMenu.listItemText}>Группы</div>
            </ListItem>
            <ListItem button
              className={classesMenu.level2}
              onClick={() => menuButtonClick("showInstrumentsSearchForm", "button", null)}
            >
              <div className={classesMenu.listItemText}>Инструменты</div>
            </ListItem>
            <ListItem button
              className={classesMenu.level2}
              onClick={() => menuButtonClick("showCurrencyRatessSearchForm", "button", null)}
            >
              <div className={classesMenu.listItemText}>Валюта</div>
            </ListItem>
          </Collapse>
        </>
      }
      {(userProfile.role.name === "Administrator" || userProfile.role.name === "BROKER" || userProfile.role.name === "TRADER") &&
        <>
          <ListItem
            button
            className={classesMenu.level1}
            onClick={() => handleOpenMenuClick("subMenuParticipants")}
          // style={{paddingTop: "15px"}}
          >
            <div className={classesMenu.menuButtonIconBack} align="center">
              <BsFillPeopleFill size={"64%"} className={classesMenu.menuIcon} />
            </div>
            <div className={classesMenu.listItemText} style={{ "fontSize": 18 }}>Участники</div>
            {(menuItemStates.subMenuParticipants === true) ? <ExpandLess style={{ paddingLeft: "5px" }} /> : <ExpandMore style={{ paddingLeft: "5px" }} />}
          </ListItem>
          <Collapse in={menuItemStates.subMenuParticipants} timeout="auto" unmountOnExit>
            {userProfile.role.name === "Administrator" &&
              <ListItem
                button
                className={classesMenu.level2}
                onClick={() => menuButtonClick("showUserSearchForm", "button", null)}
              >
                <div className={classesMenu.listItemText}>Пользователи</div>
              </ListItem>
            }
            {userProfile.role.name === "Administrator" &&
              <ListItem
                button
                className={classesMenu.level2}
                onClick={() => menuButtonClick("showOrganizationsSearchForm", "button", null)}
              >
                <div className={classesMenu.listItemText}>Фирмы</div>
              </ListItem>
            }
            <ListItem
              button
              className={classesMenu.level2}
              onClick={() => menuButtonClick("showTradingAccountsSearchForm", "button", null)}
            >
              <div className={classesMenu.listItemText}>Торговые счета</div>
            </ListItem>
            <ListItem
              button
              className={classesMenu.level2}
              onClick={() => menuButtonClick("showCurrencyAccountsSearchForm", "button", null)}
            >
              <div className={classesMenu.listItemText}>Денежные счета</div>
            </ListItem>
          </Collapse>
        </>
      }
      {userProfile.role.name === "Administrator" &&
        <>
          <ListItem
            button
            className={classesMenu.level1}
            onClick={() => handleOpenMenuClick("subMenuExchangeFiles")}
          >
            <div className={classesMenu.menuButtonIconBack} align="center">
              <FaFileDownload size={"64%"} className={classesMenu.menuIcon} />
            </div>
            <div className={classesMenu.listItemText} style={{ "fontSize": 18 }}>Обмен данными</div>
            {(menuItemStates.subMenuExchangeFiles === true) ? <ExpandLess style={{ paddingLeft: "5px" }} /> : <ExpandMore style={{ paddingLeft: "5px" }} />}
          </ListItem>
          <Collapse in={menuItemStates.subMenuExchangeFiles} timeout="auto" unmountOnExit>
            <ListItem button
              className={classesMenu.level2}
              onClick={() => menuButtonClick("showPackagesExchanceWithCDPos", "button", null)}
            >
              <div className={classesMenu.listItemText}>Входящие позиции POS/POSDS</div>
            </ListItem>
            <ListItem button
              className={classesMenu.level2}
              // style={{marginTop: "5px"}}
              onClick={() => menuButtonClick("showPackagesExchanceWithCD", "button", null)}
            >
              <div className={classesMenu.listItemText}>Список пакетов ORD/TRD</div>
            </ListItem>
          </Collapse>
        </>
      }
      {(userProfile.role.name === "Administrator" || userProfile.role.name === "BROKER" || userProfile.role.name === "TRADER") &&
        <ListItem
          button
          className={classesMenu.level1}
          onClick={() => menuButtonClick("showRepoObligationsSearchForm", "button", null)}
        >
          <div className={classesMenu.menuButtonIconBack} align="center">
            <RiFileEditFill size={"64%"} className={classesMenu.menuIcon} />
          </div>
          <div className={classesMenu.listItemText} style={{ "fontSize": 18 }}>РЕПО обязательства</div>
        </ListItem>
      }
      {userProfile.role.name === "Administrator" &&
        <>
          <ListItem
            button
            className={classesMenu.level1}
            onClick={() => menuButtonClick("showMainRefSearchForm", "button", null)}
          >
            <div className={classesMenu.menuButtonIconBack} align="center">
              <BsBookHalf size={"64%"} className={classesMenu.menuIcon} />
            </div>
            <div className={classesMenu.listItemText} style={{ "fontSize": 18 }}>Справочники</div>
          </ListItem>
          <ListItem
            button
            className={classesMenu.level1}
            onClick={() => menuButtonClick("showContractsSearchForm", "button", null)}
          >
            <div className={classesMenu.menuButtonIconBack} align="center">
              <IoNewspaper size={"64%"} className={classesMenu.menuIcon} />
            </div>
            <div className={classesMenu.listItemText} style={{ "fontSize": 18 }}>Договора</div>
          </ListItem>
          <ListItem
            button
            className={classesMenu.level1}
            onClick={() => menuButtonClick("showReportsSelectingForm", "button", null)}
          // style={{paddingTop: "15px"}}
          >
            <div className={classesMenu.menuButtonIconBack} align="center">
              <GoReport size={"64%"} className={classesMenu.menuIcon} style={{ marginTop: "7px" }} />
            </div>
            <div className={classesMenu.listItemText} style={{ "fontSize": 18 }}>Отчеты</div>
          </ListItem>
        </>
      }
      {userProfile.role.name === "CD" &&
        <>
          <ListItem
            button
            className={classesMenu.level1}
            onClick={() => menuButtonClick("showAuctionConfirmerForm", "button", null)}
          >
            <div className={classesMenu.menuButtonIconBack} align="center">
              <MdTimer size={"64%"} className={classesMenu.menuIcon} />
            </div>
            <div className={classesMenu.listItemText} style={{ "fontSize": 18 }}>Аукцион ГЦБ</div>
          </ListItem>
        </>
      }

    </List>
  )
  function setSelectedFinanceInstrument(instr) {
    console.log("SELINSTR", instr)
    setSelectedInstrument(instr)
    if (instr !== null) {
      if (instr.blocked === true) { // Инструмент заблокирован
        setBuyDisabled(true)
        setSellDisabled(true)
        setFixingDisabled(true)
        setDirectDisabled(true)
        setRepoDisabled(true)
      }
      else {
        if (instr.tradeStatusId === null || instr.tradeStatusId === 3) { // Торги по инструменту закрыты
          setBuyDisabled(true)
          setSellDisabled(true)
          setFixingDisabled(true)
          setDirectDisabled(true)
          setRepoDisabled(true)
        }
        else {
          setDirectDisabled(true)
          setRepoDisabled(true)
          if (instr.bidAllowToDirect === true) {
            setDirectDisabled(false)
          }
          if (instr.bidAllowToRepo === true) {
            setRepoDisabled(false)
          }
          if (instr.bidAllowToMarket === true) {
            setBuyDisabled(false)
            setSellDisabled(false)
            setFixingDisabled(false)
          }
          // setBuyDisabled(false)
          // setSellDisabled(false)
          // setFixingDisabled(false)
          // setDirectDisabled(false)
          // setRepoDisabled(false)
        }
      }
    }
    else {
      setBuyDisabled(false)
      setSellDisabled(false)
      setFixingDisabled(false)
      setDirectDisabled(false)
      setRepoDisabled(false)
    }
  }
  function showOrderSellForm() {
    if (buyDisabled === false) {
      setShowOrderSell(!showOrderSell)
    }
  }
  function showOrderBuyForm() {
    if (sellDisabled === false) {
      setShowOrderBuy(!showOrderBuy)
      // setFormIndex("orderBuy")
      // setUpdateState(getUUID())
    }
  }
  function setShowCreateDirectOrderForm() {
    if (directDisabled === false) {
      setShowCreateDirectOrder(!showCreateDirectOrder)
      // setFormIndex("orderBuy")
      // setUpdateState(getUUID())
    }
  }
  function setShowCreateRepoOrderForm() {
    if (repoDisabled === false) {
      setShowRepoOrder(!showRepoOrder)
    }
  }
  function setShowCreateFixingOrderForm() {
    if (fixingDisabled === false) {
      setShowCreateFixingOrder(!showCreateFixingOrder)
      // setFormIndex("orderBuy")
      // setUpdateState(getUUID())
    }
  }
  function openCurrencyPositions() {
    setShowAccountsCurrenciesPositions(true)
    if (showAccountsCurrenciesPositions === true) {
      setUpdateAccountsCurrenciesPositionsZIndex(getUUID())
    }
  }
  function openInstrumentsPositions() {
    setShowAccountsInstrumentsPositions(true)
    if (showAccountsInstrumentsPositions === true) {
      setUpdateAccountsInstrumentsPositionsZIndex(getUUID())
    }
  }
  // Columns mangement functions
  function showFormManagement(settings) {
    // console.log("F MGMNT", settings)
    let enableToOpen = null
    for (let f = 0; f < formManagement.length; f++) {
      if (formManagement[f].id === settings.id) {
        enableToOpen = false
        break
      }
      else { enableToOpen = true }
    }
    if (enableToOpen === true || formManagement.length === 0) {
      let newFormMgmnt = [...formManagement]
      newFormMgmnt.push(settings)
      setFormManagement(newFormMgmnt)
    }
  }
  function closeFormManagement(id) {
    let newFormManagement = []
    for (let f = 0; f < formManagement.length; f++) {
      if (formManagement[f].id !== id) {
        newFormManagement.push(formManagement[f])
      }
    }
    setFormManagement(newFormManagement)
  }
  // Instruments
  async function openNewInstrumentsForm() {
    if (instrumentTables.length === 0) {
      let cols = await prepareTableColumns("c64984ab-55b3-4e61-aaf2-91b63992ea0e")
      let newInstrumentsForm = {
        id: getUUID(),
        type: "instruments",
        columns: cols,
        position: { x: 0, y: 0 }
      }
      let newInstrumentSetting = [...instrumentTables]
      newInstrumentSetting.push(newInstrumentsForm)
      setInstrumentTables(newInstrumentSetting)
      console.log("NEW", newInstrumentsForm)
      let appProfile = await props.getProfile()
      appProfile.settings.instruments = newInstrumentSetting
      props.setProfile(appProfile)
    }
    else {
      setUpdateFinanceInstrumentsZIndex(getUUID())
      // closeInstrumentsTable(instrumentTables[0])
    }
  }
  async function closeInstrumentsTable(table) {
    console.log("CL INSTR", table)
    let newInstrumentSetting = []
    for (let f = 0; f < instrumentTables.length; f++) {
      if (instrumentTables[f].id !== table.id) {
        newInstrumentSetting.push(instrumentTables[f])
      }
    }
    setInstrumentTables(newInstrumentSetting)
    setSelectedInstrument({ id: null })
    setBuyDisabled(false)
    setSellDisabled(false)
    setFixingDisabled(false)
    setDirectDisabled(false)
    setRepoDisabled(false)
    setUpdateFinanceInstrumentsHead(null)
    setUpdateFinanceInstrumentsBody(null)
    let appProfile = await props.getProfile()
    appProfile.settings.instruments = newInstrumentSetting
    props.setProfile(appProfile)
  }
  function getInstrumentsSettingById(id) {
    for (let i = 0; i < instrumentTables.length; i++) {
      if (instrumentTables[i].id === id) {
        return instrumentTables[i]
      }
    }
  }
  // Finance Instrument Quotations
  function openQuotationForInstrument(instrument) {
    // handleCloseMenu()
    let newQ = [...quotations]
    instrument.uuid = getUUID()
    newQ.push(instrument)
    setQuotations(newQ)
  }
  function closeQuotation(id) {
    let newQuotations = []
    for (let i = 0; i < quotations.length; i++) {
      if (quotations[i].uuid !== id) {
        newQuotations.push(quotations[i])
      }
    }
    setQuotations(newQuotations)
    // setUpdateQuotations(null)
  }
  function openChartForInstrument(instrument) {
    let newC = [...charts]
    instrument.uuid = getUUID()
    newC.push(instrument)
    setCharts(newC)
  }
  function closeChart(id) {
    let newCharts = []
    for (let i = 0; i < charts.length; i++) {
      if (charts[i].uuid !== id) {
        newCharts.push(charts[i])
      }
    }
    setCharts(newCharts)
  }
  function openInstrumentProperties(instrument, type) {
    let newP = [...instrumentProperties]
    instrument.uuid = getUUID()
    newP.push({ instrument, type })
    setInstrumentProperties(newP)
  }
  function closeInstrumentProperties(id) {
    // console.log("CLOSE PROP", id)
    let newP = []
    for (let i = 0; i < instrumentProperties.length; i++) {
      if (instrumentProperties[i].instrument.uuid !== id) {
        newP.push(instrumentProperties[i])
      }
    }
    setInstrumentProperties(newP)
  }

  // directOrderDetails
  function openDirectOrderDetail(detail) {
    console.log("openDODetail", detail)
    let newOD = [...directOrderDetails]
    detail.uuid = getUUID()
    newOD.push(detail)
    setDirectOrderDetails(newOD)
  }
  function closeDirectOrderDetail(id) {
    let newOD = []
    for (let i = 0; i < directOrderDetails.length; i++) {
      if (directOrderDetails[i].uuid !== id) {
        newOD.push(directOrderDetails[i])
      }
    }
    setDirectOrderDetails(newOD)
  }
  // Fixing trades
  async function openFixingForm() {
    if (fixingTables.length === 0) {
      let cols = await prepareTableColumns("99fe7586-f875-427f-9fe8-47dc665da747")
      let newFixingForm = {
        id: getUUID(),
        type: "fixing",
        columns: cols,
        position: { x: 0, y: 0 }
      }
      console.log("newFixingSetting", newFixingForm)
      let newFixingSetting = [...fixingTables]
      newFixingSetting.push(newFixingForm)
      setFixingTables(newFixingSetting)
      let appProfile = await props.getProfile()
      appProfile.settings.fixing = newFixingSetting
      props.setProfile(appProfile)
    }
    else {
      setUpdateFinanceInstrumentsFixingZIndex(getUUID())
      // closeFixingTable(fixingTables[0])
    }
  }
  async function closeFixingTable(table) {
    // console.log("CL INSTR", table)
    let newFixingSetting = []
    for (let f = 0; f < fixingTables.length; f++) {
      if (fixingTables[f].id !== table.id) {
        newFixingSetting.push(fixingTables[f])
      }
    }
    setFixingTables(newFixingSetting)
    let appProfile = await props.getProfile()
    appProfile.settings.fixing = newFixingSetting
    props.setProfile(appProfile)
    setUpdateFinanceInstrumentsFixingHead(null)
    setUpdateFinanceInstrumentsFixingBody(null)
  }
  function getFixingSettingById(id) {
    for (let i = 0; i < fixingTables.length; i++) {
      if (fixingTables[i].id === id) {
        return fixingTables[i]
      }
    }
  }
  // Active Bids
  async function openNewBidsForm() {
    if (bidsTables.length === 0) {
      let cols = await prepareTableColumns("c2dcc09a-df12-4fe0-8284-f193cba09e48")
      let newBidsForm = {
        id: getUUID(),
        type: "bids",
        columns: cols,
        position: { x: 0, y: 0 }
      }
      let newBidsSetting = [...bidsTables]
      newBidsSetting.push(newBidsForm)
      setBidsTables(newBidsSetting)
      let appProfile = await props.getProfile()
      appProfile.settings.bids = newBidsSetting
      props.setProfile(appProfile)
      setUpdateBidsHead(null)
      setUpdateBidsBody(null)
    }
    else {
      // closeBidsTable(bidsTables[0])
      setUpdateBidsZIndex(getUUID())
    }
  }
  async function closeBidsTable(table) {
    // console.log("CL INSTR", table)
    let newBidsSetting = []
    for (let f = 0; f < bidsTables.length; f++) {
      if (bidsTables[f].id !== table.id) {
        newBidsSetting.push(bidsTables[f])
      }
    }
    setBidsTables(newBidsSetting)
    let appProfile = await props.getProfile()
    appProfile.settings.bids = newBidsSetting
    props.setProfile(appProfile)
    setUpdateBidsHead(null)
    setUpdateBidsBody(null)
  }
  function getBidsSettingById(id) {
    for (let i = 0; i < bidsTables.length; i++) {
      if (bidsTables[i].id === id) {
        return bidsTables[i]
      }
    }
  }
  function openBidProperties(bid) {
    let newBP = [...bidsProperties]
    bid.uuid = getUUID()
    newBP.push(bid)
    setBidsProperties(newBP)
  }
  function closeBidProperties(id) {
    let newBP = []
    for (let i = 0; i < bidsProperties.length; i++) {
      if (bidsProperties[i].uuid !== id) {
        newBP.push(bidsProperties[i])
      }
    }
    setBidsProperties(newBP)
  }
  // Deals
  async function openNewDealsForm() {
    if (dealsTables.length === 0) {
      let cols = await prepareTableColumns("326b5e30-294a-4e2c-bfae-739af1203794")
      let newDealsForm = {
        id: getUUID(),
        type: "deals",
        columns: cols,
        position: { x: 0, y: 0 }
      }
      let newDealsSetting = [...dealsTables]
      newDealsSetting.push(newDealsForm)
      setDealsTables(newDealsSetting)
      let appProfile = await props.getProfile()
      appProfile.settings.deals = newDealsSetting
      props.setProfile(appProfile)
    }
    else {
      // closeDealsTable(dealsTables[0])
      setUpdateDealsZIndex(getUUID())
    }
  }
  async function closeDealsTable(table) {
    console.log("CL DEALS", table)
    let newDealsSetting = []
    for (let f = 0; f < dealsTables.length; f++) {
      if (dealsTables[f].id !== table.id) {
        newDealsSetting.push(dealsTables[f])
      }
    }
    setDealsTables(newDealsSetting)
    let appProfile = await props.getProfile()
    appProfile.settings.deals = newDealsSetting
    props.setProfile(appProfile)
    setUpdateDealsHead(null)
    setUpdateDealsBody(null)
  }
  function getDealsSettingById(id) {
    for (let i = 0; i < dealsTables.length; i++) {
      if (dealsTables[i].id === id) {
        return dealsTables[i]
      }
    }
  }
  // Deals
  async function openAuctionForm() {
    if (auctionTables.length === 0) {
      let cols = await prepareTableColumns("6bd9bcdb-7ddf-4295-a0cf-0412fc9c22ab")
      let newAuctionForm = {
        id: getUUID(),
        type: "auction",
        columns: cols,
        position: { x: 0, y: 0 }
      }
      let newAuctionSetting = [...auctionTables]
      newAuctionSetting.push(newAuctionForm)
      setAuctionTables(newAuctionSetting)
      let appProfile = await props.getProfile()
      appProfile.settings.auction = newAuctionSetting
      props.setProfile(appProfile)
    }
    else {
      setUpdateAuctionZIndex(getUUID())
      // closeAuctionTable(auctionTables[0])
    }
  }
  async function closeAuctionTable(table) {
    // console.log("CL INSTR", table)
    let newAuctionSetting = []
    for (let f = 0; f < auctionTables.length; f++) {
      if (auctionTables[f].id !== table.id) {
        newAuctionSetting.push(auctionTables[f])
      }
    }
    setAuctionTables(newAuctionSetting)
    let appProfile = await props.getProfile()
    appProfile.settings.auction = newAuctionSetting
    props.setProfile(appProfile)
  }
  function getAuctionSettingById(id) {
    for (let i = 0; i < auctionTables.length; i++) {
      if (auctionTables[i].id === id) {
        return auctionTables[i]
      }
    }
  }

  // tradingSchedule
  async function openNewTradingScheduleForm() {
    if (tradingScheduleTables.length === 0) {
      let cols = await prepareTableColumns("91136086-8468-4294-abf4-aad629378098")
      let newTradingScheduleTablesForm = {
        id: getUUID(),
        type: "tradingSchedule",
        columns: cols,
        position: { x: 0, y: 0 }
      }
      let newTradingSchedule = [...tradingScheduleTables]
      newTradingSchedule.push(newTradingScheduleTablesForm)
      setTradingScheduleTables(newTradingSchedule)
      let appProfile = await props.getProfile()
      appProfile.settings.tradingSchedule = newTradingSchedule
      props.setProfile(appProfile)
    }
    else {
      setUpdateTradingScheduleZIndex(getUUID())
      // closeTradingScheduleTable(tradingScheduleTables[0])
    }
  }
  async function closeTradingScheduleTable(table) {
    // console.log("CL INSTR", table)
    let newTradingSchedule = []
    for (let f = 0; f < tradingScheduleTables.length; f++) {
      if (tradingScheduleTables[f].id !== table.id) {
        newTradingSchedule.push(tradingScheduleTables[f])
      }
    }
    setTradingScheduleTables(newTradingSchedule)
    let appProfile = await props.getProfile()
    appProfile.settings.tradingSchedule = newTradingSchedule
    props.setProfile(appProfile)
    setUpdateTradingSchedule(null)
  }
  function getTradingScheduleSettingById(id) {
    for (let i = 0; i < tradingScheduleTables.length; i++) {
      if (tradingScheduleTables[i].id === id) {
        return tradingScheduleTables[i]
      }
    }
  }
  // UsersMain
  async function openNewUsersMainForm() {
    // console.log("OPEN USERS")
    if (usersMainTables.length === 0) {

      let cols = await prepareTableColumns("b644d334-a43a-483f-b8df-44eac690dd8f")
      let newUsersMainForm = {
        id: getUUID(),
        type: "usersMain",
        columns: cols,
        position: { x: 0, y: 0 }
      }
      let newUsersMainSetting = [...usersMainTables]
      newUsersMainSetting.push(newUsersMainForm)
      setUsersMainTables(newUsersMainSetting)

      let appProfile = await props.getProfile()
      appProfile.settings.usersMain = newUsersMainSetting
      props.setProfile(appProfile)
    }
    else {
      // closeUsersMainTable(usersMainTables[0])
      setUpdateMainUsersZIndex(getUUID())
    }
  }
  async function closeUsersMainTable(table) {
    // console.log("CL INSTR", table)
    let newUsersMainSetting = []
    for (let f = 0; f < usersMainTables.length; f++) {
      if (usersMainTables[f].id !== table.id) {
        newUsersMainSetting.push(usersMainTables[f])
      }
    }
    setUsersMainTables(newUsersMainSetting)
    let appProfile = await props.getProfile()
    appProfile.settings.usersMain = newUsersMainSetting
    props.setProfile(appProfile)
    setUpdateMainUsers(null)
  }
  function getUsersMainSettingById(id) {
    for (let i = 0; i < usersMainTables.length; i++) {
      if (usersMainTables[i].id === id) {
        return usersMainTables[i]
      }
    }
  }

  function openAuctionDetails(auction) {
    let newA = [...auctionDetails]
    auction.uuid = getUUID()
    newA.push(auction)
    setAuctionDetails(newA)
  }
  function closeAuctionDetails(id) {
    console.log("CLOSE AUC DET", id)
    let newA = []
    for (let i = 0; i < auctionDetails.length; i++) {
      if (auctionDetails[i].uuid !== id) {
        newA.push(auctionDetails[i])
      }
    }
    setAuctionDetails(newA)
    setUpdateAuctionDetails(null)
  }
  function openAuctionDetailsCDMinfin(auction) {
    let newACD = [...auctionDetailsCDMinfin]
    auction.uuid = getUUID()
    newACD.push(auction)
    setAuctionDetailsCDMinfin(newACD)
  }
  function closeAuctionDetailsCDMinfin(id) {
    // console.log("CLOSE AUC DET", id)
    let newACD = []
    for (let i = 0; i < auctionDetailsCDMinfin.length; i++) {
      if (auctionDetailsCDMinfin[i].uuid !== id) {
        newACD.push(auctionDetailsCDMinfin[i])
      }
    }
    setAuctionDetailsCDMinfin(newACD)
    setUpdateAuctionDetailsCD(null)
  }


  // Tables management functions
  async function prepareTableColumns(id) {
    var form = await fetch(
      kseRESTApi + "/api/Metadata/GetByDefId?defid=" + id,
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token }
      }
    )
      .then(response => response.json())
      .then(function (res) {
        let f = JSON.parse(res.data.data)
        return f
      })
    // console.log("FORM", form)
    let cols = []
    for (let s = 0; s < form.sections.length; s++) {
      for (let c = 0; c < form.sections[s].contents.length; c++) {
        cols.push({
          name: form.sections[s].contents[c].name,
          label: form.sections[s].contents[c].label,
          show: form.sections[s].contents[c].show
        })
      }
    }
    // console.log("COLS", cols)
    return cols
  }
  async function updateUserSettingsByType(settings) {
    let newUserSettings = []
    if (settings.type === "instruments") {
      for (let f = 0; f < instrumentTables.length; f++) {
        if (instrumentTables[f].id === settings.id) {
          newUserSettings.push(settings)
        }
        else {
          newUserSettings.push(instrumentTables[f])
        }
      }

      // setUpdateFinanceInstrumentsHead(getUUID())
      setInstrumentTables(newUserSettings)
    }
    else if (settings.type === "fixing") {
      console.log("UPDATE SET BY TYPE", settings)
      for (let f = 0; f < fixingTables.length; f++) {
        if (fixingTables[f].id === settings.id) {
          newUserSettings.push(settings)
        }
        else {
          newUserSettings.push(fixingTables[f])
        }
      }
      // setUpdateFinanceInstrumentsFixing(getUUID())
      setFixingTables(newUserSettings)
    }
    else if (settings.type === "bids") {
      for (let f = 0; f < bidsTables.length; f++) {
        if (bidsTables[f].id === settings.id) {
          newUserSettings.push(settings)
        }
        else {
          newUserSettings.push(bidsTables[f])
        }
      }
      setBidsTables(newUserSettings)
    }
    else if (settings.type === "deals") {
      for (let f = 0; f < dealsTables.length; f++) {
        if (dealsTables[f].id === settings.id) {
          newUserSettings.push(settings)
        }
        else {
          newUserSettings.push(dealsTables[f])
        }
      }
      // console.log("UPD SET DEALS", newUserSettings)
      setDealsTables(newUserSettings)
    }
    else if (settings.type === "auction") {
      for (let f = 0; f < auctionTables.length; f++) {
        if (auctionTables[f].id === settings.id) {
          newUserSettings.push(settings)
        }
        else {
          newUserSettings.push(auctionTables[f])
        }
      }
      // console.log("UPD SET auctionTables", newUserSettings)
      // setUpdateAuction(getUUID())
      setAuctionTables(newUserSettings)
    }
    else if (settings.type === "instrumentFilters") {
      setUpdateFinanceInstrumentsBody(getUUID())
      setInstrumentFilters(settings.instrumentFilters)
    }
    else if (settings.type === "usersMain") {
      for (let f = 0; f < usersMainTables.length; f++) {
        if (usersMainTables[f].id === settings.id) {
          newUserSettings.push(settings)
        }
        else {
          newUserSettings.push(usersMainTables[f])
        }
      }
      // setUpdateMainUsers(getUUID())
      setUsersMainTables(newUserSettings)
    }
    else if (settings.type === "tradingSchedule") {
      for (let f = 0; f < tradingScheduleTables.length; f++) {
        if (tradingScheduleTables[f].id === settings.id) {
          newUserSettings.push(settings)
        }
        else {
          newUserSettings.push(tradingScheduleTables[f])
        }
      }
      // setUpdateTradingSchedule(getUUID())
      setTradingScheduleTables(newUserSettings)
    }
    let appProfile = props.getProfile()
    appProfile.settings[settings.type] = newUserSettings
    props.setProfile(appProfile)
    console.log("PROFILE UPD", appProfile, settings.type, newUserSettings)
  }
  async function updateUserSettings(settings) {
    console.log("BODY", settings)
    let res = await fetch(
      kseRESTApi + "/api/users/UpdateUserSettings",
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
        "method": "POST",
        "body": JSON.stringify(settings)
      }
    )
      .then(response => response.json())
      .then(function (res) {
        if (res.isSuccess === true) {
          // props.fetchUserProfile(token)
          // callSuccessToast("Профиль обновлен!", 700)
          return true
        }
        else {
          callErrorToast(res.errors[0])
        }
      })
    return res
  }
  function filterForm(form, settings) {
    let newForm = form
    let newContents = []
    for (let i = 0; i < settings.columns.length; i++) {
      for (let c = 0; c < form.sections[0].contents.length; c++) {
        if (settings.columns[i].name === form.sections[0].contents[c].name) {
          newContents.push({
            type: form.sections[0].contents[c].type,
            name: form.sections[0].contents[c].name,
            label: form.sections[0].contents[c].label,
            enumDef: form.sections[0].contents[c].enumDef,
            edit: form.sections[0].contents[c].edit,
            show: settings.columns[i].show,
            required: form.sections[0].contents[c].required
          })
        }
      }
    }
    newForm.sections[0].contents = newContents
    return newForm
  }
  function getActiveUsers() {
    return activeUsers
  }
  function getInstrumentFilters() {
    return instrumentFilters
  }
  function getComponentIndex() {
    return componentIndex
  }
  // currencyBeautifier(6.090)
  function currencyBeautifier(currency) {
    // return currency
    try {
      let cur = parseFloat(currency).toFixed(4).toString()
      let parts = cur.split(".")
      let numberPart = parts[0]
      let thousands = /\B(?=(\d{3})+(?!\d))/g
      let decimalPart = parts[1]

      let newCur = numberPart.replace(thousands, " ")

      if (decimalPart !== "0000") {
        let spDecPart = decimalPart.split('')
        let newDecPart = ""
        let numberStarted = false
        for (let i = 3; i > -1; i--) {
          if (spDecPart[i] === "0") {
            if (numberStarted === true) {
              newDecPart = spDecPart[i] + newDecPart
            }
          }
          else {
            numberStarted = true
            newDecPart = spDecPart[i] + newDecPart
          }
        }
        newCur = newCur + (newDecPart ? "." + newDecPart : "")
      }
      return newCur
    }
    catch (er) {
      return currency
    }
  }
  function intBeautifier(int) {
    try {
      let strInt = int.toString()
      const thousands = /\B(?=(\d{3})+(?!\d))/g
      let newInt = strInt.replace(thousands, " ")
      return newInt
    }
    catch (er) {
      return int
    }
  }
  function appBarClick() {
    // console.log("CLICK")
    let currIndex = getComponentIndex()
    setAppBarComponentIndex(currIndex + 2)
    setComponentIndex(currIndex + 2)
  }
  function footerClick() {
    // console.log("CLICK")
    let currIndex = getComponentIndex()
    setFooterComponentIndex(currIndex + 2)
    setComponentIndex(currIndex + 2)
  }

  return (
    <div id="home">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <AppBar position="fixed" style={{ zIndex: appBarComponentIndex }} onClick={() => appBarClick()}>
                <div className={classes.toolbar}>
                  <table width="100%" height="38px" style={{ background: "#EDEDED" }}>
                    <tr height="32px">
                      <td width="35px">
                        <BootstrapTooltip title="Меню">
                          <div
                            style={{ cursor: "pointer", background: "#337E86", position: "absolute", left: 1, top: 39 }}
                            onClick={() => setOpenMainMenu(true)}
                          >
                            <div width="36px" height="36px" style={{ maxWidth: "36px", maxHeight: "36px" }}>
                              <img src={menuIcon} alt="Logo" height="35px" />
                            </div>
                          </div>
                        </BootstrapTooltip>

                        <Drawer open={openMainMenu} onClose={() => setOpenMainMenu(false)} style={{ zIndex: 99999999 }}>
                          {menuList()}
                        </Drawer>
                      </td>
                      <td>
                        <Stack
                          direction="row"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={2}
                        >
                          {userProfile.role.name !== "CD" && userProfile.role.name !== "MINFIN" &&
                            <Item className={classes.appBarTextButton} onClick={() => openNewInstrumentsForm()}>Инструменты</Item>
                          }
                          {userProfile.role.name !== "CD" && userProfile.role.name !== "MINFIN" &&
                            <Item className={classes.appBarTextButton} onClick={() => openNewBidsForm()}>Заявки</Item>
                          }
                          {userProfile.role.name !== "CD" && userProfile.role.name !== "MINFIN" &&
                            <Item className={classes.appBarTextButton} onClick={() => openNewDealsForm()}>Сделки</Item>
                          }
                          {userProfile.role.name !== "CD" && userProfile.role.name !== "MINFIN" &&
                            <Item className={classes.appBarTextButton} onClick={() => openFixingForm()}>Фиксинг</Item>
                          }
                          {userProfile.role.name === "Administrator" &&
                            <Item className={classes.appBarTextButton} onClick={() => openNewUsersMainForm()}>Пользователи</Item>
                          }
                          {userProfile.role.name === "Administrator" &&
                            <Item className={classes.appBarTextButton} onClick={() => openNewTradingScheduleForm()}>Расписание торгов</Item>
                          }
                          <Item className={classes.appBarTextButton} onClick={() => openAuctionForm()}>Аукцион ГЦБ</Item>
                        </Stack>
                      </td>
                    </tr>
                  </table>
                  <table width="100%">
                    <tr>
                      <td width="90px" />
                      {/* Открытие торгов */}
                      {userProfile.role.name === "Administrator" &&
                        <td>
                          <Stack
                            direction="row"
                            divider={<Divider orientation="vertical" flexItem />}
                            spacing={1}
                          >
                            <Item className={classes.appBarIconButton} onClick={() => setShowOpenFixingTrades(!showCreateFixingOrder)}>
                              <BootstrapTooltip title="Открытие пред. ФИКСИНГА">
                                <ManageHistoryIcon style={{ marginTop: 3 }} />
                              </BootstrapTooltip>
                            </Item>
                            <Item className={classes.appBarIconButton} onClick={() => setShowAuctionOpenTrades(!showAuctionOpenTrades)}>
                              <BootstrapTooltip title="Открытие АУКЦИОНА">
                                <TimerIcon style={{ marginTop: 3 }} />
                              </BootstrapTooltip>
                            </Item>
                          </Stack>
                        </td>
                      }
                      {/* Основные Заявки */}
                      {userProfile.role.name !== "CD" && userProfile.role.name !== "MINFIN" &&
                        <td>
                          <Stack
                            direction="row"
                            divider={<Divider orientation="vertical" flexItem />}
                            spacing={1}
                          >
                            <Item className={fixingDisabled === true ? classes.appBarDisabledIconButton : classes.appBarIconButton} onClick={() => setShowCreateFixingOrderForm()}>
                              <BootstrapTooltip title="Ввод ФИКСИНГ заявки">
                                <PublishedWithChangesIcon style={{ marginTop: 3 }} />
                              </BootstrapTooltip>
                            </Item>
                            <Item className={buyDisabled === true ? classes.appBarDisabledIconButton : classes.appBarIconButton} onClick={() => showOrderBuyForm()}>
                              <BootstrapTooltip title="Ввод заявки на ПОКУПКУ методом открытых торгов">
                                <BuyIcon style={{ marginTop: 3 }} />
                              </BootstrapTooltip>
                            </Item>
                            <Item className={sellDisabled === true ? classes.appBarDisabledIconButton : classes.appBarIconButton} onClick={() => showOrderSellForm()}>
                              <BootstrapTooltip title="Ввод заявки на ПРОДАЖУ методом открытых торгов">
                                <SellIcon style={{ marginTop: 3 }} />
                              </BootstrapTooltip>
                            </Item>
                          </Stack>
                        </td>
                      }
                      {/* Direct Repo */}
                      {userProfile.role.name !== "CD" && userProfile.role.name !== "MINFIN" &&
                        <td style={{ whiteSpace: "nowrap", textOverflow: "elipsis" }}>
                          <BootstrapTooltip title="Ввод прямой заявки">
                            <button
                              className={classes.drButton}
                              onClick={() => setShowCreateDirectOrderForm()}
                              disabled={directDisabled}
                              style={{ marginLeft: 8, color: directDisabled === true ? "#D3D3D3" : "#4A99A1" }}
                            >
                              Direct
                            </button>
                          </BootstrapTooltip>
                          <BootstrapTooltip title="Ввод заявки методом РЕПО">
                            <button
                              className={classes.drButton}
                              style={{ marginLeft: 8, color: repoDisabled === true ? "#D3D3D3" : "#4A99A1" }}
                              onClick={() => setShowCreateRepoOrderForm()}
                              disabled={repoDisabled}
                            >
                              Repo
                            </button>
                          </BootstrapTooltip>
                        </td>
                      }
                      {/* Позиции */}
                      {userProfile.role.name !== "CD" && userProfile.role.name !== "MINFIN" &&
                        <td align="right">
                          <table>
                            <tr>
                              {/* Позиции по инструментам */}
                              <td style={{ height: "25px" }}>
                                <div style={{ verticalAlign: "middle", height: "25px" }}>
                                  <CardTravelIcon
                                    fontSize="medium"
                                    style={{ color: "#8D8D8D", cursor: "pointer", color: "black" }}
                                    onClick={() => openInstrumentsPositions()}
                                  />
                                </div>
                              </td>
                              <td style={{ height: "25px", whiteSpace: "nowrap", textOverflow: "elipsis" }}>
                                <div
                                  className="bottom-nav-text-style"
                                  style={{ color: "black" }}
                                  onClick={() => openInstrumentsPositions()}
                                >
                                  Позиции по инструментам
                                </div>
                              </td>
                              {/* Позиции по денежным средствам */}
                              <td style={{ height: "25px", paddingLeft: 20 }}>
                                <div style={{ verticalAlign: "middle", height: "25px" }}>
                                  <LocalAtmIcon
                                    fontSize="20px"
                                    style={{ color: "#8D8D8D", cursor: "pointer", color: "black" }}
                                    onClick={() => openCurrencyPositions()}
                                  />
                                </div>
                              </td>
                              <td style={{ height: "25px", whiteSpace: "nowrap", textOverflow: "elipsis" }}>
                                <div
                                  className="bottom-nav-text-style"
                                  style={{ color: "black" }}
                                  onClick={() => openCurrencyPositions()}>
                                  Позиции по денежным средствам
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                      }
                    </tr>
                  </table>
                  {/* <BootstrapTooltip title="Выход из системы"> */}
                  <div className={classes.exitButton} onClick={(ev) => handleOpenMenuProfile(ev)}>
                    <table>
                      <tr>
                        <td>
                          <div style={{ verticalAlign: "middle", height: "25px" }}>
                            {userProfile.userName}
                          </div>
                        </td>
                        <td>
                          <div style={{ verticalAlign: "middle", height: "25px" }}>
                            <AccountCircleIcon style={{ fontSize: "23px" }} />
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <Menu
                    anchorEl={anchorElProfile}
                    open={openMenuProfile}
                    onClose={handleCloseMenuProfile}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    style={{ paddingLeft: "100px" }}
                  >
                    <MenuItem onClick={() => openProfile()} style={{ color: "black" }}>
                      <ListItemIcon style={{ color: "black" }}>
                        <AccountCircleIcon fontSize="small" />
                      </ListItemIcon>
                      Профиль
                    </MenuItem>
                    <MenuItem onClick={() => exitSystemClick()} style={{ color: "black" }}>
                      <ListItemIcon style={{ color: "black" }}>
                        <ExitToAppIcon fontSize="small" />
                      </ListItemIcon>
                      Выход
                    </MenuItem>
                  </Menu>

                  {/* </BootstrapTooltip> */}
                </div>
              </AppBar>
            </Grid>
          </Grid>
        </Grid>


        {/* <div> */}
        <Grid item xs={12}>
          {/* Инструменты*/}
          {instrumentTables.map((settings, index) => (
            <Instruments
              index={index}
              key={settings.id}
              settings={settings}
              kseRESTApi={kseRESTApi}
              token={token}
              selectedInstrument={selectedInstrument}
              updateFinanceInstrumentsHead={updateFinanceInstrumentsHead}
              updateFinanceInstrumentsBody={updateFinanceInstrumentsBody}
              updateFinanceInstrumentsZIndex={updateFinanceInstrumentsZIndex}
              userProfile={userProfile}
              // instrumentFilters={instrumentFilters}
              getInstrumentFilters={getInstrumentFilters}
              // FUNCTIONS
              updateUserSettingsByType={updateUserSettingsByType}
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              setShowInstrumentFilters={setShowInstrumentFilters}
              getInstrumentsSettingById={getInstrumentsSettingById}
              openQuotationForInstrument={openQuotationForInstrument}
              openChartForInstrument={openChartForInstrument}
              openInstrumentProperties={openInstrumentProperties}
              filterForm={filterForm}
              closeInstrumentsTable={closeInstrumentsTable}
              showFormManagement={showFormManagement}
              // setShowPropertiesForInstrument={setShowPropertiesForInstrument}
              // setShowPropertiesForInstrumentTaskType={setShowPropertiesForInstrumentTaskType}
              setSelectedInstrument={setSelectedFinanceInstrument}
              getEnumDataByForm={getEnumDataByForm}
              getEnumDataByList={getEnumDataByList}
              createEnumOptions={createEnumOptions}
              fetchForm={fetchForm}
              fetchDocList={fetchDocList}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              currencyBeautifier={currencyBeautifier}
              intBeautifier={intBeautifier}
              getHW={getHW}
            />
          ))}
          {instrumentProperties.map((property, index) => (
            <InstrumentProperties
              index={index}
              key={property.instrument.uuid}
              instrument={property.instrument}
              type={property.type}
              kseRESTApi={kseRESTApi}
              userProfile={userProfile}
              token={token}
              componentIndex={componentIndex}
              // FUNCTIONS
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              getEnumDataByList={getEnumDataByList}
              createEnumOptions={createEnumOptions}
              closeInstrumentProperties={closeInstrumentProperties}
              setUpdateFinanceInstrumentsBody={setUpdateFinanceInstrumentsBody}
              currencyBeautifier={currencyBeautifier}
              intBeautifier={intBeautifier}
              getHW={getHW}
            />
          ))}
          {quotations.map((instr, index) =>
            <Quotations
              // VARS
              key={instr.uuid}
              instrument={instr}
              style={{ paddingTop: "50px" }}
              kseRESTApi={kseRESTApi}
              userProfile={userProfile}
              token={token}
              updateQuotations={updateQuotations}
              componentIndex={componentIndex}
              // FUNCTIONS
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              setSelectedInstrument={setSelectedFinanceInstrument}
              setShowOrderBuy={setShowOrderBuy}
              setOrderBuyFields={setOrderBuyFields}
              setShowOrderSell={setShowOrderSell}
              setOrderSellFields={setOrderSellFields}
              closeQuotation={closeQuotation}
              fetchDocList={fetchDocList}
              currencyBeautifier={currencyBeautifier}
              intBeautifier={intBeautifier}
              getHW={getHW}
            />
          )}
          {charts.map((instr, index) =>
            <Charts
              // VARS
              key={instr.uuid}
              instrument={instr}
              style={{ paddingTop: "50px" }}
              kseRESTApi={kseRESTApi}
              userProfile={userProfile}
              token={token}
              componentIndex={componentIndex}
              // FUNCTIONS
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              closeChart={closeChart}
              currencyBeautifier={currencyBeautifier}
              intBeautifier={intBeautifier}
              getHW={getHW}
            />
          )}
          {/* Инструменты фиксинг*/}
          {fixingTables.map((settings, index) =>
            <Fixing
              index={index}
              key={settings.id}
              settings={settings}
              kseRESTApi={kseRESTApi}
              token={token}
              userProfile={userProfile}
              updateFinanceInstrumentsFixingHead={updateFinanceInstrumentsFixingHead}
              updateFinanceInstrumentsFixingBody={updateFinanceInstrumentsFixingBody}
              updateFinanceInstrumentsFixingZIndex={updateFinanceInstrumentsFixingZIndex}
              // FUNCTIONS
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              updateUserSettingsByType={updateUserSettingsByType}
              getFixingSettingById={getFixingSettingById}
              fetchForm={fetchForm}
              filterForm={filterForm}
              closeFixingTable={closeFixingTable}
              showFormManagement={showFormManagement}
              getEnumDataByForm={getEnumDataByForm}
              fetchDocList={fetchDocList}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              currencyBeautifier={currencyBeautifier}
              intBeautifier={intBeautifier}
              getHW={getHW}
            />
          )}
          {/* Заявки */}
          {bidsTables.map((settings, index) =>
            <Bids
              // VARS
              index={index}
              key={settings.id}
              settings={settings}
              kseRESTApi={kseRESTApi}
              token={token}
              updateBidsHead={updateBidsHead}
              updateBidsBody={updateBidsBody}
              updateBidsZIndex={updateBidsZIndex}
              userProfile={userProfile}
              // FUNCTIONS
              openDirectOrderDetail={openDirectOrderDetail}
              setShowOrderBuy={setShowOrderBuy}
              setOrderBuyFields={setOrderBuyFields}
              setShowOrderSell={setShowOrderSell}
              setOrderSellFields={setOrderSellFields}
              openBidProperties={openBidProperties}
              updateUserSettingsByType={updateUserSettingsByType}
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              setShowAcceptRepoOrder={setShowAcceptRepoOrder}
              setSelectedBid={setSelectedBid}
              getBidsSettingById={getBidsSettingById}
              filterForm={filterForm}
              closeBidsTable={closeBidsTable}
              showFormManagement={showFormManagement}
              fetchForm={fetchForm}
              getEnumDataByForm={getEnumDataByForm}
              getEnumDataByList={getEnumDataByList}
              fetchDocList={fetchDocList}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              currencyBeautifier={currencyBeautifier}
              intBeautifier={intBeautifier}
              setUpdateAccountsInstrumentPositions={setUpdateAccountsInstrumentPositions}
              setUpdateAccountsCurrenciesPositions={setUpdateAccountsCurrenciesPositions}
              getHW={getHW}
            />
          )}
          {bidsProperties.map((bid, index) =>
            <BidProperties
              // VARS
              key={bid.uuid}
              bid={bid}
              kseRESTApi={kseRESTApi}
              token={token}
              userProfile={userProfile}
              // FUNCTIONS
              closeBidProperties={closeBidProperties}
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              filterForm={filterForm}
              getEnumDataByList={getEnumDataByList}
              fetchDocList={fetchDocList}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              createEnumOptions={createEnumOptions}
              currencyBeautifier={currencyBeautifier}
              intBeautifier={intBeautifier}
              getHW={getHW}
            />
          )}

          {/* Сделки */}
          {dealsTables.map((settings, index) =>
            <Deals
              // VARS
              index={index}
              key={settings.id}
              settings={settings}
              kseRESTApi={kseRESTApi}
              token={token}
              updateDealsHead={updateDealsHead}
              updateDealsBody={updateDealsBody}
              updateDealsZIndex={updateDealsZIndex}
              // FUNCTIONS
              updateUserSettingsByType={updateUserSettingsByType}
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              getDealsSettingById={getDealsSettingById}
              filterForm={filterForm}
              closeDealsTable={closeDealsTable}
              showFormManagement={showFormManagement}
              getEnumDataByForm={getEnumDataByForm}
              fetchForm={fetchForm}
              fetchDocList={fetchDocList}
              currencyBeautifier={currencyBeautifier}
              intBeautifier={intBeautifier}
              getHW={getHW}
            />
          )}
          {/* Аукцион */}

          {showAuctionOpenTrades === true &&
            <AuctionOpenTrades
              // VARS
              kseRESTApi={kseRESTApi}
              userProfile={userProfile}
              token={token}
              selectedInstrument={selectedInstrument}
              componentIndex={componentIndex}
              // FUNCTIONS
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              setShowAuctionOpenTrades={setShowAuctionOpenTrades}
              getEnumDataByList={getEnumDataByList}
              createEnumOptions={createEnumOptions}
              fetchDocList={fetchDocList}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              setUpdateAuction={setUpdateAuction}
              currencyBeautifier={currencyBeautifier}
              intBeautifier={intBeautifier}
              getHW={getHW}
            />
          }
          {auctionTables.map((settings, index) =>
            <AuctionList
              // VARS
              index={index}
              settings={settings}
              kseRESTApi={kseRESTApi}
              userProfile={userProfile}
              token={token}
              updateAuctionZIndex={updateAuctionZIndex}
              // FUNCTIONS
              openAuctionDetails={openAuctionDetails}
              openAuctionDetailsCDMinfin={openAuctionDetailsCDMinfin}
              updateUserSettingsByType={updateUserSettingsByType}
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              updateAuction={updateAuction}
              getAuctionSettingById={getAuctionSettingById}
              filterForm={filterForm}
              closeAuctionTable={closeAuctionTable}
              showFormManagement={showFormManagement}
              getEnumDataByForm={getEnumDataByForm}
              fetchForm={fetchForm}
              fetchDocList={fetchDocList}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              currencyBeautifier={currencyBeautifier}
              intBeautifier={intBeautifier}
              getHW={getHW}
            />
          )}
          {auctionDetails.map((auc, index) =>
            <AuctionDetails
              // VARS
              key={auc.uuid}
              auction={auc}
              style={{ paddingTop: "50px" }}
              kseRESTApi={kseRESTApi}
              userProfile={userProfile}
              token={token}
              componentIndex={componentIndex}
              updateAuctionDetails={updateAuctionDetails}
              // FUNCTIONS
              setSelectedLot={setSelectedLot}
              setShowAuctionBidForm={setShowAuctionBidForm}
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              closeAuctionDetails={closeAuctionDetails}
              getEnumDataByForm={getEnumDataByForm}
              getEnumDataByList={getEnumDataByList}
              fetchDocList={fetchDocList}
              fetchForm={fetchForm}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              setUpdateAuction={setUpdateAuction}
              currencyBeautifier={currencyBeautifier}
              intBeautifier={intBeautifier}
              getHW={getHW}
            />
          )}
          {auctionDetailsCDMinfin.map((auc, index) =>
            <AuctionDetailsCDMinfin
              // VARS
              key={auc.uuid}
              auction={auc}
              style={{ paddingTop: "50px" }}
              kseRESTApi={kseRESTApi}
              userProfile={userProfile}
              token={token}
              componentIndex={componentIndex}
              updateAuctionDetailsCD={updateAuctionDetailsCD}
              // FUNCTIONS
              setSelectedLot={setSelectedLot}
              setShowAuctionBidForm={setShowAuctionBidForm}
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              closeAuctionDetailsCDMinfin={closeAuctionDetailsCDMinfin}
              getEnumDataByForm={getEnumDataByForm}
              getEnumDataByList={getEnumDataByList}
              fetchDocList={fetchDocList}
              fetchForm={fetchForm}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              setUpdateAuction={setUpdateAuction}
              currencyBeautifier={currencyBeautifier}
              intBeautifier={intBeautifier}
              getHW={getHW}
            />
          )}
          {showAuctionBidForm === true &&
            <AuctionBidForm
              // VARS
              kseRESTApi={kseRESTApi}
              userProfile={userProfile}
              token={token}
              formIndex={formIndex}
              selectedLot={selectedLot}
              componentIndex={componentIndex}
              // FUNCTIONS
              setUpdateAuctionDetails={setUpdateAuctionDetails}
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              setFormIndex={setFormIndex}
              setShowAuctionBidForm={setShowAuctionBidForm}
              getEnumDataByList={getEnumDataByList}
              createEnumOptions={createEnumOptions}
              fetchDocList={fetchDocList}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              currencyBeautifier={currencyBeautifier}
              intBeautifier={intBeautifier}
              getHW={getHW}
            />
          }

          {/* Расписание */}
          {tradingScheduleTables.map((tradingScheduleTableSettings, index) =>
            <TradingScheduleManagement
              // VARS
              index={index}
              kseRESTApi={kseRESTApi}
              token={token}
              userProfile={userProfile}
              key={tradingScheduleTableSettings.id}
              settings={tradingScheduleTableSettings}
              updateTradingSchedule={updateTradingSchedule}
              updateTradingScheduleZIndex={updateTradingScheduleZIndex}
              // FUNCTIONS
              getTradingScheduleSettingById={getTradingScheduleSettingById}
              setShowScheduleCreateEditForm={setShowScheduleCreateEditForm}
              setSelectedSchedule={setSelectedSchedule}
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              updateUserSettingsByType={updateUserSettingsByType}
              setNewTask={setNewTask}
              closeTradingScheduleTable={closeTradingScheduleTable}
              filterForm={filterForm}
              showFormManagement={showFormManagement}
              getEnumDataByList={getEnumDataByList}
              fetchForm={fetchForm}
              getEnumDataByForm={getEnumDataByForm}
              fetchDocList={fetchDocList}
              createEnumOptions={createEnumOptions}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              getHW={getHW}
            />
          )}
          {showScheduleCreateEditForm === true &&
            <TradingScheduleManagementCreateEditForm
              // VARS
              kseRESTApi={kseRESTApi}
              token={token}
              selectedSchedule={selectedSchedule}
              // FUNCTIONS
              setUpdateTradingSchedule={setUpdateTradingSchedule}
              setShowScheduleCreateEditForm={setShowScheduleCreateEditForm}
              setSelectedSchedule={setSelectedSchedule}
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              getEnumDataByList={getEnumDataByList}
              createEnumOptions={createEnumOptions}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              getHW={getHW}
            />
          }
          {/* Пользователи */}
          {usersMainTables.map((usersMainTableSettings, index) =>
            <UsersMain
              // VARS
              index={index}
              kseRESTApi={kseRESTApi}
              token={token}
              updateMainUsers={updateMainUsers}
              updateMainUsersZIndex={updateMainUsersZIndex}
              key={usersMainTableSettings.id}
              settings={usersMainTableSettings}
              // FUNCTIONS
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              updateUserSettingsByType={updateUserSettingsByType}
              setNewTask={setNewTask}
              openNewTask={openNewTask}
              closeUsersMainTable={closeUsersMainTable}
              getUsersMainSettingById={getUsersMainSettingById}
              filterForm={filterForm}
              showFormManagement={showFormManagement}
              setShowMails={setShowMails}
              setSelectedOrganization={setSelectedOrganization}
              getActiveUsers={getActiveUsers}
              getEnumDataByList={getEnumDataByList}
              fetchForm={fetchForm}
              getEnumDataByForm={getEnumDataByForm}
              fetchDocList={fetchDocList}
              createEnumOptions={createEnumOptions}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              getHW={getHW}
            />
          )}
          {showOrderSell === true &&
            <OrderSell
              // VARS
              kseRESTApi={kseRESTApi}
              userProfile={userProfile}
              token={token}
              formIndex={formIndex}
              selectedInstrument={selectedInstrument}
              selectedAccountId={selectedAccountId}
              componentIndex={componentIndex}
              // FUNCTIONS
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              setFormIndex={setFormIndex}
              setShowOrderSell={setShowOrderSell}
              orderSellFields={orderSellFields}
              getEnumDataByList={getEnumDataByList}
              createEnumOptions={createEnumOptions}
              fetchDocList={fetchDocList}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              currencyBeautifier={currencyBeautifier}
              setUpdateBidsBody={setUpdateBidsBody}
              setUpdateDealsBody={setUpdateDealsBody}
              setUpdateAccountsInstrumentPositions={setUpdateAccountsInstrumentPositions}
              setUpdateAccountsCurrenciesPositions={setUpdateAccountsCurrenciesPositions}
              getHW={getHW}
            />
          }
          {showOrderBuy === true &&
            <OrderBuy
              // VARS
              kseRESTApi={kseRESTApi}
              userProfile={userProfile}
              token={token}
              formIndex={formIndex}
              selectedInstrument={selectedInstrument}
              selectedAccountId={selectedAccountId}
              orderBuyFields={orderBuyFields}
              componentIndex={componentIndex}
              // FUNCTIONS
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              setFormIndex={setFormIndex}
              setShowOrderBuy={setShowOrderBuy}
              getEnumDataByList={getEnumDataByList}
              createEnumOptions={createEnumOptions}
              fetchDocList={fetchDocList}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              currencyBeautifier={currencyBeautifier}
              setUpdateBidsBody={setUpdateBidsBody}
              setUpdateDealsBody={setUpdateDealsBody}
              setUpdateAccountsInstrumentPositions={setUpdateAccountsInstrumentPositions}
              setUpdateAccountsCurrenciesPositions={setUpdateAccountsCurrenciesPositions}
              getHW={getHW}
            // getRequest={getRequest}
            />
          }
          {showCreateDirectOrder === true &&
            <CreateDirectOrder
              // VARS
              kseRESTApi={kseRESTApi}
              userProfile={userProfile}
              token={token}
              selectedInstrument={selectedInstrument}
              componentIndex={componentIndex}
              // FUNCTIONS
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              setShowCreateDirectOrder={setShowCreateDirectOrder}
              getEnumDataByList={getEnumDataByList}
              createEnumOptions={createEnumOptions}
              fetchDocList={fetchDocList}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              setUpdateBidsBody={setUpdateBidsBody}
              getHW={getHW}
            />
          }
          {showRepoOrder === true &&
            <RepoOrder
              // VARS
              kseRESTApi={kseRESTApi}
              userProfile={userProfile}
              token={token}
              formIndex={formIndex}
              selectedInstrument={selectedInstrument}
              componentIndex={componentIndex}
              // FUNCTIONS
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              setFormIndex={setFormIndex}
              setShowRepoOrder={setShowRepoOrder}
              getEnumDataByList={getEnumDataByList}
              createEnumOptions={createEnumOptions}
              fetchDocList={fetchDocList}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              setUpdateBidsBody={setUpdateBidsBody}
              getHW={getHW}
            />
          }
          {showAcceptRepoOrder === true &&
            <AcceptRepoOrder
              // VARS
              kseRESTApi={kseRESTApi}
              userProfile={userProfile}
              token={token}
              formIndex={formIndex}
              selectedBid={selectedBid}
              componentIndex={componentIndex}
              // FUNCTIONS
              setSelectedBid={setSelectedBid}
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              setFormIndex={setFormIndex}
              setShowAcceptRepoOrder={setShowAcceptRepoOrder}
              getEnumDataByList={getEnumDataByList}
              createEnumOptions={createEnumOptions}
              fetchDocList={fetchDocList}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              setUpdateRepoOrderList={setUpdateRepoOrderList}
              setUpdateBidsHead={setUpdateBidsHead}
              setUpdateBidsBody={setUpdateBidsBody}
              getHW={getHW}
            />
          }
          {showRepoProlongation === true &&
            <RepoProlongation
              // VARS
              kseRESTApi={kseRESTApi}
              userProfile={userProfile}
              token={token}
              formIndex={formIndex}
              selectedRepo={selectedRepo}
              componentIndex={componentIndex}
              // FUNCTIONS
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              setFormIndex={setFormIndex}
              setShowRepoProlongation={setShowRepoProlongation}
              getEnumDataByList={getEnumDataByList}
              createEnumOptions={createEnumOptions}
              fetchDocList={fetchDocList}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              getHW={getHW}
            />
          }

          {showDirectOrders === true &&
            <DirectOrders
              // VARS
              kseRESTApi={kseRESTApi}
              userProfile={userProfile}
              token={token}
              selectedInstrument={selectedInstrument}
              componentIndex={componentIndex}
              // FUNCTIONS
              openDirectOrderDetail={openDirectOrderDetail}
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              setShowDirectOrders={setShowDirectOrders}
              getEnumDataByList={getEnumDataByList}
              createEnumOptions={createEnumOptions}
              fetchDocList={fetchDocList}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              getHW={getHW}
            />
          }
          {directOrderDetails.map((orderDetail, index) =>
            <DirectOrderDetails
              // VARS
              key={orderDetail.uuid}
              orderDetail={orderDetail}
              style={{ paddingTop: "50px" }}
              kseRESTApi={kseRESTApi}
              userProfile={userProfile}
              token={token}
              componentIndex={componentIndex}
              // FUNCTIONS
              getEnumDataByList={getEnumDataByList}
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              closeDirectOrderDetail={closeDirectOrderDetail}
              createEnumOptions={createEnumOptions}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              setUpdateBidsBody={setUpdateBidsBody}
              setUpdateDealsBody={setUpdateDealsBody}
              setUpdateAccountsInstrumentPositions={setUpdateAccountsInstrumentPositions}
              getHW={getHW}
            />
          )}

          {showOpenFixingTrades === true &&
            <OpenFixingTrades
              // VARS
              kseRESTApi={kseRESTApi}
              userProfile={userProfile}
              token={token}
              selectedInstrument={selectedInstrument}
              componentIndex={componentIndex}
              // FUNCTIONS
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              setShowOpenFixingTrades={setShowOpenFixingTrades}
              getEnumDataByList={getEnumDataByList}
              createEnumOptions={createEnumOptions}
              fetchDocList={fetchDocList}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              getHW={getHW}
            />
          }

          {showCreateFixingOrder === true &&
            <CreateFixingOrder
              // VARS
              kseRESTApi={kseRESTApi}
              userProfile={userProfile}
              token={token}
              selectedInstrument={selectedInstrument}
              componentIndex={componentIndex}
              // FUNCTIONS
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              setShowCreateFixingOrder={setShowCreateFixingOrder}
              getEnumDataByList={getEnumDataByList}
              createEnumOptions={createEnumOptions}
              fetchDocList={fetchDocList}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              setUpdateBidsBody={setUpdateBidsBody}
              getHW={getHW}
            />
          }
          {showAccountsCurrenciesPositions === true &&
            <AccountsCurrenciesPositions
              // VARS
              kseRESTApi={kseRESTApi}
              userProfile={userProfile}
              token={token}
              componentIndex={componentIndex}
              updateAccountsCurrenciesPositions={updateAccountsCurrenciesPositions}
              updateAccountsCurrenciesPositionsZIndex={updateAccountsCurrenciesPositionsZIndex}
              // FUNCTIONS
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              fetchForm={fetchForm}
              setShowAccountsCurrenciesPositions={setShowAccountsCurrenciesPositions}
              setShowAccountsCurrenciesDepositing={setShowAccountsCurrenciesDepositing}
              getEnumDataByForm={getEnumDataByForm}
              getEnumDataByList={getEnumDataByList}
              createEnumOptions={createEnumOptions}
              fetchDocList={fetchDocList}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              currencyBeautifier={currencyBeautifier}
              intBeautifier={intBeautifier}
              getHW={getHW}
            />
          }
          {showAccountsCurrenciesDepositing === true &&
            <AccountsCurrenciesDepositing
              // VARS
              kseRESTApi={kseRESTApi}
              userProfile={userProfile}
              token={token}
              componentIndex={componentIndex}

              // FUNCTIONS
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              // fetchForm={fetchForm}
              setShowAccountsCurrenciesDepositing={setShowAccountsCurrenciesDepositing}
              setUpdateAccountsCurrenciesPositions={setUpdateAccountsCurrenciesPositions}
              getEnumDataByList={getEnumDataByList}
              createEnumOptions={createEnumOptions}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              getHW={getHW}
            />
          }


          {showAccountsInstrumentsPositions === true &&
            <AccountsInstrumentPositions
              // VARS
              kseRESTApi={kseRESTApi}
              userProfile={userProfile}
              token={token}
              componentIndex={componentIndex}
              updateAccountsInstrumentPositions={updateAccountsInstrumentPositions}
              updateAccountsInstrumentsPositionsZIndex={updateAccountsInstrumentsPositionsZIndex}
              // FUNCTIONS
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              fetchForm={fetchForm}
              setShowAccountsInstrumentsPositions={setShowAccountsInstrumentsPositions}
              setShowAccountsInstrumentsDepositing={setShowAccountsInstrumentsDepositing}
              getEnumDataByForm={getEnumDataByForm}
              getEnumDataByList={getEnumDataByList}
              createEnumOptions={createEnumOptions}
              fetchDocList={fetchDocList}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              currencyBeautifier={currencyBeautifier}
              intBeautifier={intBeautifier}
              setSelectedInstrument={setSelectedFinanceInstrument}
              setSelectedAccountId={setSelectedAccountId}
              getHW={getHW}
            />
          }
          {showAccountsInstrumentsDepositing === true &&
            <AccountsInstrumentsDepositing
              // VARS
              kseRESTApi={kseRESTApi}
              userProfile={userProfile}
              token={token}
              componentIndex={componentIndex}
              // FUNCTIONS
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              // fetchForm={fetchForm}
              setShowAccountsInstrumentsDepositing={setShowAccountsInstrumentsDepositing}
              setUpdateAccountsInstrumentPositions={setUpdateAccountsInstrumentPositions}
              getEnumDataByList={getEnumDataByList}
              createEnumOptions={createEnumOptions}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              getHW={getHW}
            />
          }
          {formManagement.map(settings =>
            <FormManagement
              // VARS
              kseRESTApi={kseRESTApi}
              userProfile={userProfile}
              token={token}
              settings={settings}
              componentIndex={componentIndex}
              // FUNCTIONS
              setUpdateFinanceInstrumentsHead={setUpdateFinanceInstrumentsHead}
              setUpdateBidsHead={setUpdateBidsHead}
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              updateUserSettingsByType={updateUserSettingsByType}
              closeFormManagement={closeFormManagement}
              fetchForm={fetchForm}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              getHW={getHW}
            />
          )}
          {showInstrumentFilters === true &&
            <InstrumentFilters
              kseRESTApi={kseRESTApi}
              userProfile={userProfile}
              token={token}
              setUpdateFinanceInstrumentsBody={setUpdateFinanceInstrumentsBody}
              instrumentFilters={instrumentFilters}
              componentIndex={componentIndex}
              // FUNCTIONS
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              setShowInstrumentFilters={setShowInstrumentFilters}
              updateUserSettingsByType={updateUserSettingsByType}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              getEnumDataByList={getEnumDataByList}
              createEnumOptions={createEnumOptions}
              getHW={getHW}
            />
          }
          {showImportantMessage === true &&
            <ImportantMessage
              // VARS
              kseRESTApi={kseRESTApi}
              message={importantMessage}
              time={importantMessageTime}
              componentIndex={componentIndex}
              // FUNCTIONS
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              setShowImportantMessage={setShowImportantMessage}
              getHW={getHW}
            />
          }
          {tasks.map(task => (
            <ComponentManager
              // VARS
              id={task.key}
              key={task.key}
              taskType={task.taskType}
              taskVariables={task}
              kseRESTApi={kseRESTApi}
              token={token}
              userProfile={userProfile}
              savedDocs={savedDocs}
              componentIndex={componentIndex}
              // FUNCTIONS
              setShowRepoProlongation={setShowRepoProlongation}
              setNewTask={setNewTask}
              closeTask={closeTask}
              setSelectedRepo={setSelectedRepo}
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              getDocREST={getDocREST}
              getEnumDataByForm={getEnumDataByForm}
              getEnumDataByList={getEnumDataByList}
              createEnumOptions={createEnumOptions}
              fetchForm={fetchForm}
              fetchDocList={fetchDocList}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              fetchSavedFiles={fetchSavedFiles}
              saveFilesToDir={saveFilesToDir}
              setSavedDocs={setSavedDocs}
              updateRepoOrderList={updateRepoOrderList}
              getHW={getHW}
              setUpdateAccountsCurrenciesPositions={setUpdateAccountsCurrenciesPositions}
              setUpdateAccountsInstrumentPositions={setUpdateAccountsInstrumentPositions}
            />
          ))}
          {showMails === true &&
            <Mails
              // VARS
              taskVariables={taskVariables}
              kseRESTApi={kseRESTApi}
              token={token}
              userProfile={userProfile}
              setShowMails={setShowMails}
              componentIndex={componentIndex}
              selectedOrganization={selectedOrganization}
              // FUNCTIONS
              setSelectedOrganization={setSelectedOrganization}
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              getDocREST={getDocREST}
              getEnumDataByList={getEnumDataByList}
              getEnumDataByForm={getEnumDataByForm}
              createEnumOptions={createEnumOptions}
              fetchForm={fetchForm}
              fetchDocList={fetchDocList}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              getHW={getHW}
            />
          }
          {showProfile === true &&
            <Profile
              // VARS
              kseRESTApi={kseRESTApi}
              userProfile={userProfile}
              token={token}
              formIndex={formIndex}
              componentIndex={componentIndex}
              // FUNCTIONS
              getComponentIndex={getComponentIndex}
              setComponentIndex={setComponentIndex}
              setFormIndex={setFormIndex}
              setShowProfile={setShowProfile}
              callSuccessToast={callSuccessToast}
              callErrorToast={callErrorToast}
              getHW={getHW}
            />
          }
          {/* <Resize/> */}
          {/* Общяя блокировка функционала на время клиринга */}
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openBackDrop}>
            <table align="center">
              <tr align="center">{backDropMessage}</tr>
              <tr align="center"><CircularProgress color="inherit" /></tr>
            </table>
          </Backdrop>
          {/* Окно авторизации рутокен */}
          <Modal
            open={showModalEnterPin}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div style={rutokenModalStyle} className={classes.rutokenModal}>
              <h3 id="simple-modal-title">Введите ПИН код Рутокена</h3>
              <FormControl variant="outlined" style={{ width: "100%" }} error={wrongPin}>
                <InputLabel htmlFor="outlined-adornment-password">PIN</InputLabel>
                <OutlinedInput
                  type={showPin ? 'text' : 'password'}
                  defaultValue={rutokenPin}
                  onChange={handlePinChange}
                  onKeyPress={onKeyPressModalEnterPin}
                  autoFocus
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPin}
                        edge="end"
                      >
                        {showPin ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={25}
                />
              </FormControl>
              {wrongPin === true && <p color="red">Не верный PIN</p>}
              <Button
                variant="contained"
                onClick={() => buttonClick("SignButton")}
                style={{
                  margin: 3,
                  height: 32,
                  fontSize: 12,
                  color: "white",
                  backgroundColor: "#2862F4"
                }}
              >Ок
              </Button>
              <Button
                variant="contained"
                onClick={() => exitSystemClick()}
                style={{
                  margin: 3,
                  height: 32,
                  fontSize: 12,
                  color: "white",
                  backgroundColor: "#2862F4"
                }}
              >Выход
              </Button>
            </div>
          </Modal>

          {/* Вызов toast */}
          <ToastContainer />

        </Grid>
        <div
          style={{ position: "fixed", bottom: 0, right: 0, borderTop: "1px solid #8D8D8D", zIndex: footerComponentIndex, background: "white", width: "100%" }}
          onMouseDown={() => footerClick()}
        >
          <table width="100%" style={{ height: "27px" }}>
            <tr>
              <td width="70%" align="left" style={{ whiteSpace: "nowrap", textOverflow: "elipsis" }}><OnlineTime /></td>
              <td width="30%" align="right" style={{ padding: 0 }}>
                <table>
                  <tr>
                    <td height="22px" style={{ padding: 0 }}>
                      <div style={{ verticalAlign: "middle" }}>
                        <MdEmail
                          fontSize="22px"
                          style={{ color: unreadMails !== null ? "black" : "#8D8D8D", cursor: "pointer" }}
                          onClick={() => setShowMails(true)}
                        />
                      </div>
                    </td>
                    <td style={{ height: "25px" }}>
                      <div
                        className="bottom-nav-text-style"
                        style={{ color: unreadMails !== null ? "black" : "#8D8D8D" }}
                        onClick={() => setShowMails(true)}>
                        Сообщения
                      </div>
                    </td>
                    <td style={{ height: "25px" }}>
                      {unreadMails !== null &&
                        <div style={{ verticalAlign: "middle", height: "25px", cursor: "pointer" }} onClick={() => setShowMails(true)}>
                          <div style={{ fontSize: 12, verticalAlign: "middle", textAlign: "center", height: "20px", width: "20px", color: "white", background: "#4FA4AD", borderRadius: "50%" }}>
                            <div style={{ border: "1px solid #4FA4AD", borderRadius: "50%", marginTop: "2px", marginRight: "1px" }}>{unreadMails}</div>
                          </div>
                        </div>
                      }
                    </td>
                    {userProfile.role.name === "Administrator" &&
                      <React.Fragment>
                        <td style={{ height: "25px", padding: 0, paddingLeft: 20 }}>
                          <div style={{ verticalAlign: "middle", height: "25px" }}>
                            <RiBankFill
                              fontSize="20px"
                              style={{ color: incomingPos !== null ? "black" : "#8D8D8D", cursor: "pointer" }}
                              onClick={() => menuButtonClick("showPackagesExchanceWithCDPos")}
                            />
                          </div>
                        </td>
                        <td style={{ height: "25px" }}>
                          <div
                            className="bottom-nav-text-style"
                            style={{ color: incomingPos !== null ? "black" : "#8D8D8D" }}
                            onClick={() => menuButtonClick("showPackagesExchanceWithCDPos")}>
                            Обмен данными с ЦД
                          </div>
                        </td>
                        <td style={{ height: "25px", paddingRight: 10 }}>
                          {incomingPos !== null &&
                            <div style={{ verticalAlign: "middle", height: "25px", cursor: "pointer" }} onClick={() => menuButtonClick("showPackagesExchanceWithCDPos")}>
                              <div style={{ fontSize: 12, verticalAlign: "middle", textAlign: "center", height: "20px", width: "20px", color: "white", background: "#4FA4AD", borderRadius: "50%" }}>
                                <div style={{ border: "1px solid #4FA4AD", borderRadius: "50%", marginTop: "2px", marginRight: "1px" }}>{incomingPos}</div>
                              </div>
                            </div>
                          }
                        </td>
                      </React.Fragment>
                    }
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
      </Grid>
    </div>
  )
}