import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
// Icons
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// CUSTOM COMPONENTS
import ConfigurationFile from "../configuration/ConfigurationFile.json";
// Library
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import Draggable from 'react-draggable';
import { red } from "@material-ui/core/colors";
import { padding } from "@mui/system";
import swal from 'sweetalert'; // https://sweetalert.js.org/guides/
var moment = require('moment');
var fetch = require('node-fetch');

const useStyles = makeStyles((theme) => ({
  // resizeForm: {
  //   borderRadius: "7px",
  //   border: "1px solid #2B4D80",
  //   display: "flex",
  //   backgroundColor: "#F5F5F5",
  //   boxShadow: theme.shadows[1],
  //   "flex-flow": "column",
  //   width: "50%",
  //   resize: "both",
  //   overflow: "hidden",
  //   minWidth: "150px",
  //   minHeight: "150px",
  //   position: 'absolute',
  // },
  resizeForm: {
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    border: "1px solid #2B4D80",
    display: "flex",
    backgroundColor: "white",
    boxShadow: theme.shadows[1],
    "flex-flow": "column",
    width: "50%",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "150px",
    position: 'absolute',
  }
}))
function FloatFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      decimalSeparator={"."}
      thousandSeparator={" "}
      isNumericString
    />
  )
}
FloatFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
function IntegerFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={" "}
      isNumericString
    />
  );
}
IntegerFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

//Home(props) - получаем переменные от родителя App.js 
export default (props) => {
  const classes = useStyles()
  const [token] = useState(props.token)
  const [kseRESTApi] = useState(props.kseRESTApi) //Local KFB main REST
  const [userProfile, setUserProfile] = useState(props.userProfile)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [enumData, setEnumData] = useState({})
  const [enumOptions, setEnumOptions] = useState({})
  const [selectedOptions, setSelectedOptions] = useState({})
  const [tableKey, setTableKey] = useState(null)
  const [repo] = useState(props.selectedRepo)
  const [position, setPosition] = useState({ x: 0, y: 0 })

  // FIELDS
  const [fieldValue, setFieldValue] = useState({
    sellerOrganizationId: repo.sellOrgId,
    buyerOrganizationId: repo.buyOrgId,
    sellerProlongationDate: repo.newCloseDate !== null ? repo.newCloseDate : repo.closeDate, //repo.newCloseDate !== null,
    buyerProlongationDate: repo.newCloseDate !== null ? repo.newCloseDate : repo.closeDate,
    sellerPrice: repo.newClosePrice !== null ? repo.newClosePrice : repo.closePrice,
    buyerPrice: repo.newClosePrice !== null ? repo.newClosePrice : repo.closePrice
  })
  useEffect(async () => {
    console.log("REPO PROL PROPS", props)
    let enumDataToCollect = [
      { enumName: "sellerOrganizationId", enumDef: "5b78d9dd-821d-4c6a-a00a-3af85224fbc4" },
      { enumName: "buyerOrganizationId", enumDef: "5b78d9dd-821d-4c6a-a00a-3af85224fbc4" }
    ]
    let enums = await props.getEnumDataByList(enumDataToCollect)
    // console.log("BUY ENUMS", enums)
    setEnumData(enums)
    let eOpts = await props.createEnumOptions(enums)
    setEnumOptions(eOpts)
    let sOpts = { ...selectedOptions }
    let so = "sellerOrganizationId"
    for (let d = 0; d < enums[so].length; d++) {
      if (enums[so][d].id === repo.sellOrgId) {
        sOpts[so] = { "value": enums[so][d].id, "label": enums[so][d].label, "name": so }
      }
    }
    let bo = "buyerOrganizationId"
    for (let d = 0; d < enums[bo].length; d++) {
      if (enums[bo][d].id === repo.buyOrgId) {
        sOpts[bo] = { "value": enums[bo][d].id, "label": enums[bo][d].label, "name": bo }
      }
    }
    setSelectedOptions(sOpts)
    props.setComponentIndex(props.getComponentIndex() + 1)
  }, [])
  // random UUID generator
  function getUUID() {
    return uuidv4()
  }
  // INPUT HANDLERS
  // SELECT
  function handleSelectChange(option) {
    setSelectedOptions({ ...selectedOptions, [option.name]: option })
    setFieldValue({ ...fieldValue, [option.name]: option.value })
    console.log("OPT", option, fieldValue)
  }
  // INT
  const handleIntChange = (event) => {
    // console.log("EVENT", event.target.name, event.target.value)
    if (event.target.value !== "") {
      let stringNum = event.target.value.toString().replace(/ /g, '')
      let int = parseInt(stringNum)
      setFieldValue({ ...fieldValue, [event.target.name]: int })
    }
    else {
      setFieldValue({ ...fieldValue, [event.target.name]: event.target.value })
    }
  }
  // Float input handler
  const handleFloatChange = (event) => {
    // console.log("FLOAT CHANGE", event.target.name, event.target.value)
    if (event.target.value !== "") {
      let val = event.target.value.replace(/ /g, '')
      let newFields = fieldValue
      newFields[event.target.name] = parseFloat(val)
      setFieldValue(newFields)
    }
    else {
      setFieldValue({ ...fieldValue, [event.target.name]: null })
    }
    // setFieldValue({ ...fieldValue, [event.target.name]: parseFloat(event.target.value) })
  }
  function handleDateTimeChange(event) {
    let date = moment(event.target.value).format()
    setFieldValue({ ...fieldValue, [event.target.name]: date })
    console.log("DATE CHANGE", date)
  }
  async function extendRepo() {
    let body = {
      repoId: repo.id,
      newCloseDate: userProfile.organization.id !== repo.sellOrgId ? fieldValue.buyerProlongationDate : fieldValue.sellerProlongationDate,
      newClosePrice: userProfile.organization.id !== repo.sellOrgId ? fieldValue.buyerPrice : fieldValue.sellerPrice,
    }
    console.log("BODY", body)
    console.log("fieldValue", fieldValue)
    await fetch(
      kseRESTApi + "/api/Trading/ExtendRepo",
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
        "method": "POST",
        "body": JSON.stringify(body)
      }
    )
      .then(response => response.json())
      .then(function (res) {
        console.log("RES EXTEND", res)
        if (res.isSuccess === true) {
          props.callSuccessToast(res.message, 1100)
          props.setShowRepoProlongation(false)
        }
        else {
          props.callErrorToast(res.errors[0])
        }
      })
  }
  function getModalStyle() {
    const top = 15;
    const left = 25;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      zIndex: componentIndex
    };
  }
  function formClick() {
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 1)
    props.setComponentIndex(currIndex + 1)
  }
  function positionHandler(e, data) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 10 // Take 10% of width
    let maxDeviationX = (dimension.w / 100) * 80 // Take 80% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    // console.log("minX", minDeviationX, "maxX", maxDeviationX, "maxY", maxDeviationY)
    if (data.y < maxDeviationY) {
      // console.log("SET MIN Y", data.x)
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      // console.log("SET MIN X", data.x, minDeviationX)
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      // console.log("SET MAX X", data.x)
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
  }
  return (
    // <Draggable
    //   handle="p"
    //   onStart={() => formClick()}
    //   onMouseDown={() => formClick()}
    // // onClick={()=> formClick()}
    // >
    //   <div style={getModalStyle()} className={classes.resizeForm}>
    <Draggable
      handle="p"
      onStart={() => formClick()}
      onStop={positionHandler}
      position={position}
    >
      <div
        className={classes.resizeForm}
        style={{ top: "15%", left: "10%", transform: "translate(-15%, -10%)", zIndex: componentIndex }}
        onClick={() => formClick()}
      >
        <p className="main-table-label-p-style-new">
          <table className="dragble-div-header-table-style-new">
            <tr>
              <td width="99%" className="dragble-div-header-td-style-new">Продление РЕПО</td>
              <td onClick={() => props.setShowRepoProlongation(false)}><IconButton size="small"><CloseIcon /></IconButton></td>
            </tr>
          </table>
        </p>
        <div className="dragble-div-body-style">
          <table align="center" width="100%" key={tableKey}>
            <tr>
              <td width="50%">
                <table width="100%">
                  <tr className="dragble-div-body-td-text-style">Покупатель</tr>
                  <tr>
                    <Select
                      name={"buyerOrganizationId"}
                      placeholder={"Выбрать..."}
                      value={selectedOptions.buyerOrganizationId}
                      onChange={handleSelectChange}
                      options={enumOptions.buyerOrganizationId}
                      isDisabled={true}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                  </tr>
                  <tr className="properties-td-text-style">Дата</tr>
                  <tr>
                    <td>
                      <TextField
                        variant="outlined"
                        type="date"
                        size="small"
                        name="buyerProlongationDate"
                        onChange={handleDateTimeChange}
                        defaultValue={(fieldValue.buyerProlongationDate !== null) ? moment(fieldValue.buyerProlongationDate).format('YYYY-MM-DD') : ""}
                        style={{ width: "100%" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={userProfile.organization.id !== repo.buyOrgId ? true : false}
                      />
                    </td>
                  </tr>
                  <tr className="properties-td-text-style">Цена</tr>
                  <tr>
                    <TextField
                      variant="outlined"
                      placeholder="0.0"
                      size="small"
                      name="buyerPrice"
                      value={fieldValue.buyerPrice}
                      style={{ width: "100%" }}
                      InputProps={{ inputComponent: FloatFormat }}
                      disabled={userProfile.organization.id !== repo.buyOrgId ? true : false}
                    />
                    {/* <input
                      type='number'
                      step="0.0001"
                      defaultValue={fieldValue.buyerPrice}
                      onBlur={handleFloatChange}
                      name="buyerPrice"
                      disabled={userProfile.organization.id !== repo.buyOrgId ? true : false}
                    /> */}
                  </tr>
                </table>
              </td>

              <td width="50%">
                <table width="100%">
                  <tr className="dragble-div-body-td-text-style">Продавец</tr>
                  <tr>
                    <Select
                      name={"sellerOrganizationId"}
                      placeholder={"Выбрать..."}
                      value={selectedOptions.sellerOrganizationId}
                      onChange={handleSelectChange}
                      options={enumOptions.sellerOrganizationId}
                      isDisabled={true}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                  </tr>
                  <tr className="properties-td-text-style">Дата</tr>
                  <tr>
                    <td>
                      <TextField
                        variant="outlined"
                        type="date"
                        size="small"
                        name="sellerProlongationDate"
                        onChange={handleDateTimeChange}
                        defaultValue={(fieldValue.sellerProlongationDate !== null) ? moment(fieldValue.sellerProlongationDate).format('YYYY-MM-DD') : ""}
                        style={{ width: "100%" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={userProfile.organization.id !== repo.sellOrgId ? true : false}
                      />
                    </td>
                  </tr>
                  <tr className="properties-td-text-style">Цена</tr>
                  <tr>
                    <TextField
                      variant="outlined"
                      placeholder="0.0"
                      size="small"
                      name="sellerPrice"
                      value={fieldValue.sellerPrice}
                      style={{ width: "100%" }}
                      InputProps={{ inputComponent: FloatFormat }}
                      disabled={userProfile.organization.id !== repo.sellOrgId ? true : false}
                    />
                    {/* <input
                      type='number'
                      step="0.0001"
                      defaultValue={fieldValue.sellerPrice}
                      onBlur={handleFloatChange}
                      name="sellerPrice"
                      disabled={userProfile.organization.id !== repo.sellOrgId ? true : false}
                    /> */}
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table width="100%">
            <tr>
              <td width="100%" align="center">
                {/* <button
                  className="cancelButton"
                  onClick={() => props.setShowRepoProlongation(false)}
                  style={{ marginRight: 20 }}
                >
                  Отмена
                </button> */}
                <button
                  className="approveButton"
                  style={{ marginRight: 20 }}
                  onClick={() => extendRepo()}
                >
                  Подтвердить
                </button>
              </td>
            </tr>
          </table>
          {/* <table align="center" width="100%">
            <tr>
              <td>
                <Button
                  variant="contained"
                  onClick={() => extendRepo()}
                  style={{
                    margin: 3,
                    height: 30,
                    fontSize: 12,
                    color: "white",
                    backgroundColor: "#2862F4"
                  }}
                >Подтвердить
                </Button>
                <Button
                  variant="contained"
                  onClick={() => props.setShowRepoProlongation(false)}
                  style={{
                    margin: 3,
                    height: 30,
                    fontSize: 12,
                    color: "white",
                    backgroundColor: "#E73639"
                  }}
                >Отмена
                </Button>
              </td>
            </tr>
          </table> */}
        </div>
      </div>
    </Draggable>
  )
}