
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// Icons
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// Library
import { ResponsiveLine } from '@nivo/line' // https://nivo.rocks/line/
import Draggable from 'react-draggable';

const useStyles = makeStyles((theme) => ({
  resizeForm: {
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    display: "flex",
    "flex-flow": "column",
    width: 700,
    height: 350,
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "150px",
    background: "white",
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #AFAFAF',
    boxShadow: theme.shadows[1],
    // padding: theme.spacing(0, 2, 1),
  },
  label: {
    cursor: "move",
    textAlignLast: "center",
    fontWeight: "bold",
    marginBottom: 0,
    marginTop: 0,
  },
  labelTable: {
    marginTop: 1,
    marginBottom: 2,
    textAlign: "center",
    fontWeight: "bold",
  }
  // modal: {
  //   position: 'absolute',
  //   width: 700,
  //   height: 350,
  //   backgroundColor: theme.palette.background.paper,
  //   border: '1px solid #AFAFAF',
  //   boxShadow: theme.shadows[1],
  //   padding: theme.spacing(0, 2, 1),
  // }
}))

export default (props) => {
  const classes = useStyles()
  const [kseRESTApi] = useState(props.kseRESTApi)
  const [token] = useState(props.token)
  const [userProfile] = useState(props.userProfile)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [instrument] = useState(props.instrument)
  const [data, setData] = useState(null)
  const [position, setPosition] = useState({ x: 0, y: 0 })

  useEffect(() => {
    console.log("CHARTS PROPS", props)
    fetchChartData()
    props.setComponentIndex(props.getComponentIndex() + 1)
  }, [])

  function fetchChartData() {
    fetch(kseRESTApi + "/api/Charts/BidLineData?instrumentCode=" + props.instrument.code,
      {
        "headers": { "content-type": "application/json" }
      }
    )
      .then(response => response.json())
      .then(function (res) {
        setData(res.data)
      })
  }
  function getModalStyle() {
    const top = 15;
    const left = 25;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      zIndex: componentIndex
    };
  }
  function formClick() {
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 1)
    props.setComponentIndex(currIndex + 1)
  }
  function positionHandler(e, data) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 30 // Take 30% of width
    let maxDeviationX = (dimension.w / 100) * 60 // Take 60% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    // console.log("minX", minDeviationX, "maxX", maxDeviationX, "maxY", maxDeviationY)
    if (data.y < maxDeviationY) {
      // console.log("SET MIN Y", data.x)
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      // console.log("SET MIN X", data.x, minDeviationX)
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      // console.log("SET MAX X", data.x)
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
  }
  if (data !== null) {
    return (
      // <Draggable 
      //   handle="p"
      //   onStart={()=> formClick()}
      //   onMouseDown={()=> formClick()}
      //   // onClick={()=> formClick()}
      // >
      //   <div style={getModalStyle()} className={classes.resizeForm}>
      <Draggable
        handle="p"
        onStart={() => formClick()}
        onStop={positionHandler}
        position={position}
      >
        <div
          className={classes.resizeForm}
          style={{ top: "15%", left: "30%", transform: "translate(-15%, -30%)", zIndex: componentIndex }}
          onClick={() => formClick()}
        >
          <p className={classes.label}>
            <table className={classes.labelTable}>
              <tr>
                <td width="99%" className="dragble-div-header-td-style">{instrument.code}</td>
                <td onClick={() => props.closeChart(instrument.uuid)}><IconButton size="small"><CloseIcon /></IconButton></td>
              </tr>
            </table>
          </p>
          {/* <div className="dragble-div-body-style"> */}
          <ResponsiveLine
            data={data}
            margin={{ top: 6, right: 70, bottom: 100, left: 60 }}
            xScale={{ type: 'point' }}
            yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
            yFormat=" >-.2f"
            curve="monotoneX"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: 'bottom',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Дата',
              legendOffset: 36,
              legendPosition: 'middle'
            }}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Цена',
              legendOffset: -40,
              legendPosition: 'middle'
            }}
            colors={{ scheme: 'dark2' }}
            lineWidth={3}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            areaBlendMode="screen"
            useMesh={true}
            legends={[
              {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                      itemOpacity: 1
                    }
                  }
                ]
              }
            ]}
          />
          {/* </div> */}

        </div>
      </Draggable>
    )
  }
  else {
    return (
      <div>Loading...</div>
    )
  }
}