import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
// import TextareaAutosize from '@mui/material/TextareaAutosize';
import Checkbox from '@material-ui/core/Checkbox';
// Icons
import { IoMdClose } from 'react-icons/io';
// import IconButton from '@material-ui/core/IconButton';
// import AddIcon from '@mui/icons-material/AddCircleOutline';
// import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
// import CloseIcon from '@material-ui/icons/Close';
// import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// Radio Group
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
// CUSTOM COMPONENTS
// import ConfigurationFile from "../configuration/ConfigurationFile.json";
import Buttons from "../configuration/Buttons.json";
// Library
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import Draggable from 'react-draggable';
// import swal from 'sweetalert'; // https://sweetalert.js.org/guides/
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'; // https://www.npmjs.com/package/react-tabs
import 'react-tabs/style/react-tabs.css';
var fetch = require('node-fetch');
var moment = require('moment');
const useStyles = makeStyles((theme) => ({
  resizeForm: {
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    border: "1px solid #2B4D80",
    display: "flex",
    // backgroundColor: "#F5F5F5",
    backgroundColor: "white",
    boxShadow: theme.shadows[1],
    "flex-flow": "column",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "150px",
    position: 'absolute',
  }
}))
function FloatFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}

      decimalSeparator={"."}
      thousandSeparator={" "}
      isNumericString
    />
  )
}
FloatFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
function IntegerFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={" "}
      isNumericString
    />
  );
}
IntegerFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

//Home(props) - получаем переменные от родителя App.js 
export default (props) => {
  const classes = useStyles()
  const [token] = useState(props.token)
  const [kseRESTApi] = useState(props.kseRESTApi) // Local KFB main REST
  const [userProfile, setUserProfile] = useState(props.userProfile)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [enumData, setEnumData] = useState({})
  const [enumOptions, setEnumOptions] = useState({})
  const [selectedOptions, setSelectedOptions] = useState({})
  const [fieldValue, setFieldValue] = useState({ couponMethod: null })
  const [updateState, setUpdateState] = useState(null)
  const [instrument] = useState(props.instrument)
  const [formType, setFormType] = useState("view")
  const [buttons, setButtons] = useState([])
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [selectedTab, setSelectedTab] = useState(0)
  const [tableKey, setTableKey] = useState(1)

  useEffect(async () => {
    console.log("INSTR PROPERTIES PROPS", props)
    let enumDataToCollect = [
      { enumName: "marketId", enumDef: "ab14dc6d-0139-4dd4-ab65-172cacb636f8" },
      { enumName: "marketSectorId", enumDef: "df59dde0-68fd-4a8b-84e1-aa33531d2fe6" },
      { enumName: "instrumentGroupId", enumDef: "3e66280f-9295-46af-8855-cfce4d98faf1" },
      { enumName: "instrumentTypeId", enumDef: "80780935-f08c-4101-931a-24261880269a" },
      { enumName: "currencyForTrade", enumDef: "3bac793e-bc73-44f4-a18f-8c1a55efe919" },
      { enumName: "currencyForClearing", enumDef: "3bac793e-bc73-44f4-a18f-8c1a55efe919" },
      { enumName: "couponMethod", enumDef: "f0163330-70ec-41e9-8cee-90c21002e336" },
      { enumName: "emitentId", enumDef: "5088d5ee-0dfd-482e-8143-89dc6ca5617a" },
      { enumName: "registratorId", enumDef: "7b5ab690-f475-4165-8618-daa413c3695c" },
      { enumName: "industryId", enumDef: "63b092dc-164a-403c-9e09-149742be0252" },
    ]
    let enums = await props.getEnumDataByList(enumDataToCollect)
    console.log("INSTR PROPER ENUMS", enums)
    setEnumData(enums)
    let eOpts = await props.createEnumOptions(enums)
    setEnumOptions(eOpts)
    if (props.type === "edit") {
      setFormType("edit")
      // setSelectedDoc(instrument)
      setFieldValue(instrument)
      console.log("SDOC", instrument)
      setSelectValuesFromDoc(instrument, eOpts)
      setButtons(Buttons[userProfile.role.name]["instrumentPropertiesEditBtns"])
      // console.log("in prop BTNS", Buttons[userProfile.role.name]["instrumentPropertiesEditBtns"])
    }
    else if (props.type === "create") {
      setFormType("create")
      setButtons(Buttons[userProfile.role.name]["instrumentPropertiesCreateBtns"])
    }
    else if (props.type === "view") {
      setFormType("view")
      // setSelectedDoc(instrument)
      setFieldValue(instrument)
      console.log("SDOC", instrument)
      setSelectValuesFromDoc(instrument, eOpts)
      setButtons(Buttons[userProfile.role.name]["instrumentPropertiesViewBtns"])
    }
    props.setComponentIndex(props.getComponentIndex() + 1)
    setUpdateState(getUUID())
  }, [])
  function setSelectValuesFromDoc(doc, opts) {
    let selOpts = {}
    for (let key in doc) {
      if (opts[key]) {
        for (let i = 0; i < opts[key].length; i++) {
          if (opts[key][i].value === doc[key]) {
            selOpts[key] = opts[key][i]
          }
        }
      }
    }
    setSelectedOptions(selOpts)
  }
  // random UUID generator
  function getUUID() {
    return uuidv4()
  }
  // INPUT HANDLERS
  function handleTextChange(event) {
    // console.log("EVENT", event.target.name, event.target.value)
    // fieldValue[event.target.name] = event.target.value
    // setFieldValue(fieldValue)
    setFieldValue({ ...fieldValue, [event.target.name]: event.target.value })
    // console.log("FIELDVALUE", fieldValue)
  }
  // SELECT
  function handleSelectChange(option) {
    if (option.name === "marketId") {
      let newFields = { ...fieldValue }
      newFields.marketId = option.value
      newFields.marketSectorId = null
      newFields.instrumentGroupId = null
      setFieldValue(newFields)

      let newSelOptions = { ...selectedOptions }
      newSelOptions.marketSectorId = {
        label: "Пусто",
        name: "marketSectorId",
        value: null
      }
      newSelOptions.instrumentGroupId = {
        label: "Пусто",
        name: "instrumentGroupId",
        value: null
      }
      newSelOptions.marketId = option
      setSelectedOptions(newSelOptions)

      let newEnumOptions = { ...enumOptions }
      newEnumOptions.marketSectorId = filterMarketSectors(option.value, enumData)
      newEnumOptions.instrumentGroupId = []
      setEnumOptions(newEnumOptions)
    }
    else if (option.name === "marketSectorId") {
      let newFields = { ...fieldValue }
      newFields.marketSectorId = option.value
      newFields.instrumentGroupId = null
      setFieldValue(newFields)

      let newSelOptions = { ...selectedOptions }
      newSelOptions.instrumentGroupId = {
        label: "Пусто",
        name: "instrumentGroupId",
        value: null
      }
      newSelOptions.marketSectorId = option
      setSelectedOptions(newSelOptions)

      let newEnumOptions = { ...enumOptions }
      newEnumOptions.instrumentGroupId = filterSectorGroups(option.value, enumData)
      setEnumOptions(newEnumOptions)
    }
    else {
      setSelectedOptions({ ...selectedOptions, [option.name]: option })
      setFieldValue({ ...fieldValue, [option.name]: option.value })
      console.log("OPT", option, fieldValue)
    }
  }
  // INT
  const handleIntChange = (event) => {
    // console.log("EVENT", event.target.name, event.target.value, fieldValue)
    if (event.target.value !== "") {
      let stringNum = event.target.value.toString().replace(/ /g, '')
      let int = parseInt(stringNum)
      setFieldValue({ ...fieldValue, [event.target.name]: int })
    }
    else {
      setFieldValue({ ...fieldValue, [event.target.name]: null })
    }
  }

  // Float input handler
  const handleFloatChange = (event) => {
    // console.log("FLOAT CHANGE", event.target.name, parseFloat(event.target.value))
    // setFieldValue({ ...fieldValue, [event.target.name]: parseFloat(event.target.value) })
    // // console.log("FLOAT CHANGE", event.target.name, event.target.value, float)

    if (event.target.value !== "") {
      let stringNum = event.target.value.toString().replace(/ /g, '')
      let float = parseFloat(stringNum)
      setFieldValue({ ...fieldValue, [event.target.name]: float })
      // console.log("FLOAT CHANGE", event.target.name, float)
    }
    else {
      setFieldValue({ ...fieldValue, [event.target.name]: event.target.value })
    }
  }
  const handleCheckboxChange = (event) => {
    console.log("CHBX", event.target.name, event.target.checked)
    setFieldValue({ ...fieldValue, [event.target.name]: event.target.checked })
  }
  function handleDateTimeChange(event) {
    let date = moment(event.target.value).format("YYYY-MM-DD")
    setFieldValue({ ...fieldValue, [event.target.name]: date })
  }
  function handleRadionGroupChange(event) {
    console.log("R CH", event.target.name, event.target.value)
    setFieldValue({ ...fieldValue, [event.target.name]: parseInt(event.target.value) })
    setUpdateState(getUUID())
  }
  function returnRadioGroup(name) {
    // console.log("RAD GR ENUM", enumData[name])
    return (
      <FormControl component="fieldset">
        <RadioGroup
          name={name}
          value={fieldValue[name]}
          onChange={handleRadionGroupChange}
        >
          {enumData[name].map(item => (
            <FormControlLabel
              style={{ marginTop: -10 }}
              value={item.id}
              control={<Radio style={{ color: fieldValue[name] === item.id ? "#337E86" : null }} />}
              label={item.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    )

  }
  function filterMarketSectors(marketId, enums) {
    let newSectors = [{
      "value": null,
      "label": "Пусто",
      "name": "marketSectorId"
    }]
    for (let i = 0; i < enums["marketSectorId"].length; i++) {
      if (enums["marketSectorId"][i].market === marketId) {
        newSectors.push({
          "value": enums["marketSectorId"][i].id,
          "label": enums["marketSectorId"][i].label,
          "name": "marketSectorId"
        })
      }
    }
    return newSectors
  }
  function filterSectorGroups(marketSectorId, enums) {
    let newGroups = [{
      "value": null,
      "label": "Пусто",
      "name": "instrumentGroupId"
    }]
    for (let i = 0; i < enums["instrumentGroupId"].length; i++) {
      if (enums["instrumentGroupId"][i].sector === marketSectorId) {
        newGroups.push({
          "value": enums["instrumentGroupId"][i].id,
          "label": enums["instrumentGroupId"][i].label,
          "name": "instrumentGroupId"
        })
      }
    }
    return newGroups
  }
  async function buttonClick(buttonName) {
    console.log("buttonName", buttonName)
    if (buttonName === "updateFinanceInstrument") {
      console.log("FIELDS", fieldValue)
      await fetch(
        kseRESTApi + "/api/FinanceInstruments?id=" + fieldValue.id,
        {
          "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
          "method": "PUT",
          "body": JSON.stringify(fieldValue)
        }
      )
        .then(response => response.json())
        .then(function (res) {
          // console.log("RES", res)
          if (res.isSuccess === true) {
            props.callSuccessToast("Данные обновлены!")
            props.closeInstrumentProperties(instrument.uuid)
            props.setUpdateFinanceInstrumentsBody(getUUID())
          }
          else {
            props.callErrorToast(res.errors[0])
          }
        })
    }
    else if (buttonName === "saveFinanceInstrument") {
      console.log("FVAL", fieldValue)
      await fetch(
        kseRESTApi + "/api/FinanceInstruments",
        {
          "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
          "method": "POST",
          "body": JSON.stringify(fieldValue)
        }
      )
        .then(response => response.json())
        .then(function (res) {
          // console.log("RES", res, instrument)
          if (res.isSuccess === true) {
            props.callSuccessToast("Инструмент создан!")
            props.setUpdateFinanceInstrumentsBody(getUUID())
            props.closeInstrumentProperties(instrument.uuid)
          }
          else {
            props.callErrorToast(res.errors[0])
          }
        })
    }
    else if (buttonName === "close") {
      props.closeInstrumentProperties(instrument.uuid)
    }
  }
  function getModalStyle() {
    const top = 15;
    const left = 25;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      zIndex: componentIndex
    }
  }
  function formClick() {
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 1)
    props.setComponentIndex(currIndex + 1)
  }
  function positionHandler(e, data) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 30 // Take 30% of width
    let maxDeviationX = (dimension.w / 100) * 60 // Take 60% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    // console.log("minX", minDeviationX, "maxX", maxDeviationX, "maxY", maxDeviationY)
    if (data.y < maxDeviationY) {
      // console.log("SET MIN Y", data.x)
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      // console.log("SET MIN X", data.x, minDeviationX)
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      // console.log("SET MAX X", data.x)
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
  }
  function selectTab(id) {
    // console.log("S TAB", id)
    setSelectedTab(id)
    setTableKey(getUUID())
  }

  if (updateState !== null) {
    return (
      <Draggable
        handle="p"
        onStart={() => formClick()}
        onStop={positionHandler}
        position={position}
      >
        <div
          className={classes.resizeForm}
          style={{ top: "15%", left: "30%", transform: "translate(-15%, -30%)", zIndex: componentIndex }}
          onClick={() => formClick()}
          key={tableKey}
        >
          <p className="main-table-label-p-style-new">
            <table className="dragble-div-header-table-style-new">
              <tr>
                <td width="99%" className="dragble-div-header-td-style-new">Свойства инструмента</td>
                <td>
                  <IoMdClose size={22} className="form-close-icons-style" style={{ marginRight: "5px" }} onClick={() => props.closeInstrumentProperties(instrument.uuid)} />
                </td>
              </tr>
            </table>
          </p>
          <div className="dragble-div-body-style" >
            <Tabs selectedIndex={selectedTab} onSelect={(index) => selectTab(index)}>
              <TabList>
                <Tab style={{ fontSize: 14, fontWeight: selectedTab === 0 ? "bold" : "normal", color: selectedTab === 0 ? "#418991" : "black" }}>Основные</Tab>
                <Tab style={{ fontSize: 14, fontWeight: selectedTab === 1 ? "bold" : "normal", color: selectedTab === 1 ? "#418991" : "black" }}>Дополн.</Tab>
                <Tab style={{ fontSize: 14, fontWeight: selectedTab === 2 ? "bold" : "normal", color: selectedTab === 2 ? "#418991" : "black" }}>Цена</Tab>
                <Tab style={{ fontSize: 14, fontWeight: selectedTab === 3 ? "bold" : "normal", color: selectedTab === 3 ? "#418991" : "black" }}>Позиции</Tab>
                <Tab style={{ fontSize: 14, fontWeight: selectedTab === 4 ? "bold" : "normal", color: selectedTab === 4 ? "#418991" : "black" }}>Заявки</Tab>
                <Tab style={{ fontSize: 14, fontWeight: selectedTab === 5 ? "bold" : "normal", color: selectedTab === 5 ? "#418991" : "black" }}>Лимиты</Tab>
                <Tab style={{ fontSize: 14, fontWeight: selectedTab === 6 ? "bold" : "normal", color: selectedTab === 6 ? "#418991" : "black" }}>Валюты</Tab>
                <Tab style={{ fontSize: 14, fontWeight: selectedTab === 7 ? "bold" : "normal", color: selectedTab === 7 ? "#418991" : "black" }}>Swift</Tab>
              </TabList>
              {/* Основные */}
              <TabPanel style={{ paddingLeft: 25, width: "590px", height: "550px", paddingRihgt: 25 }}>
                <table align="left" >
                  <tr>
                    <td width="70px" className="properties-td-text-style">Рынок</td>
                    <td width="460px" align="left">
                      <Select
                        name={"marketId"}
                        placeholder={"Выбрать..."}
                        value={selectedOptions["marketId"]}
                        onChange={handleSelectChange}
                        options={enumOptions["marketId"]}
                        isDisabled={formType === "view" ? true : false}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="properties-td-text-style">Сектор</td>
                    <td>
                      <Select
                        name={"marketSectorId"}
                        placeholder={"Выбрать..."}
                        value={selectedOptions["marketSectorId"]}
                        onChange={handleSelectChange}
                        options={enumOptions["marketSectorId"]}
                        isDisabled={formType === "view" ? true : false}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="properties-td-text-style">Группа</td>
                    <td>
                      <Select
                        name={"instrumentGroupId"}
                        placeholder={"Выбрать..."}
                        value={selectedOptions["instrumentGroupId"]}
                        onChange={handleSelectChange}
                        options={enumOptions["instrumentGroupId"]}
                        isDisabled={formType === "view" ? true : false}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="properties-td-text-style">Тип</td>
                    <td>
                      <Select
                        name={"instrumentTypeId"}
                        placeholder={"Выбрать..."}
                        value={selectedOptions["instrumentTypeId"]}
                        onChange={handleSelectChange}
                        options={enumOptions["instrumentTypeId"]}
                        isDisabled={formType === "view" ? true : false}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      />
                    </td>
                  </tr>
                </table>
                <table>
                  <tr>
                    <td className="properties-td-text-style">Наименование</td>
                    <td className="properties-td-text-style">Внешний код</td>
                    <td className="properties-td-text-style"></td>
                  </tr>
                  <tr>
                    <td width="180px">
                      <TextField
                        variant="outlined"
                        size="small"
                        name="code"
                        onChange={handleTextChange}
                        defaultValue={(fieldValue.code !== undefined) ? fieldValue.code : ""}
                        // style={{ width: "140px" }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                    <td width="180px">
                      <TextField
                        variant="outlined"
                        size="small"
                        name="isin"
                        onChange={handleTextChange}
                        defaultValue={(fieldValue.isin !== undefined) ? fieldValue.isin : ""}
                        // style={{ width: "140px" }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                    <td>
                      <Checkbox
                        style={{ color: "#337E86" }}
                        name="blocked"
                        onChange={handleCheckboxChange}
                        checked={fieldValue["blocked"] ? (fieldValue["blocked"] === false ? false : true) : false}
                        disabled={formType === "view" ? true : false}
                      />Заблокирован
                    </td>
                  </tr>
                </table>
                <table width="100%">
                  <tr>
                    <td className="properties-td-text-style">Отрасль промышленности</td>
                  </tr>
                  <tr>
                    <td width="100%">
                      <Select
                        name={"industryId"}
                        placeholder={"Выбрать..."}
                        value={selectedOptions["industryId"]}
                        onChange={handleSelectChange}
                        options={enumOptions["industryId"]}
                        isDisabled={formType === "view" ? true : false}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="properties-td-text-style">Полное наименование на русском языке</td>
                  </tr>
                  <tr>
                    <td>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="fullNameRus"
                        onChange={handleTextChange}
                        defaultValue={(fieldValue.fullNameRus !== undefined) ? fieldValue.fullNameRus : ""}
                        style={{ width: "100%" }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="properties-td-text-style">Полное наименование на английском языке</td>
                  </tr>
                  <tr>
                    <td>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="fullNameEng"
                        onChange={handleTextChange}
                        defaultValue={(fieldValue.fullNameEng !== undefined) ? fieldValue.fullNameEng : ""}
                        style={{ width: "100%" }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                  </tr>
                </table>
                <table>
                  <tr>
                    <td className="properties-td-text-style">
                      <Checkbox
                        style={{ color: "#337E86" }}
                        name="hasMarketMakers"
                        onChange={handleCheckboxChange}
                        checked={fieldValue["hasMarketMakers"] ? (fieldValue["hasMarketMakers"] === false ? false : true) : false}
                        disabled={formType === "view" ? true : false}
                      />Есть маркет-мейкеры
                    </td>
                  </tr>
                </table>
                <table>
                  <tr>
                    <td className="properties-td-text-style" width="255px">Мин. объем для маркет-мейкеров</td>
                    <td className="properties-td-text-style" width="180px">
                      <TextField
                        variant="outlined"
                        size="small"
                        // style={{ width: "120px" }}
                        style={{ width: "100%" }}
                        name="minMarketMakerVolume"
                        placeholder="0.0"
                        onBlur={handleFloatChange}
                        defaultValue={fieldValue.minMarketMakerVolume}
                        InputProps={{ inputComponent: FloatFormat }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                  </tr>
                </table>
              </TabPanel>
              {/* Дополн. */}
              <TabPanel style={{ paddingLeft: 20, width: "590px", height: "550px" }}>
                <table align="center" width="100%">
                  <tr>
                    <td className="properties-td-text-style">Эмитент</td>
                  </tr>
                  <tr>
                    <td width="430px">
                      <Select
                        name={"emitentId"}
                        placeholder={"Выбрать..."}
                        value={selectedOptions["emitentId"]}
                        onChange={handleSelectChange}
                        options={enumOptions["emitentId"]}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        isDisabled={formType === "view" ? true : false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="properties-td-text-style">Регистратор</td>
                  </tr>
                  <tr>
                    <td width="430px">
                      <Select
                        name={"registratorId"}
                        placeholder={"Выбрать..."}
                        value={selectedOptions["registratorId"]}
                        onChange={handleSelectChange}
                        options={enumOptions["registratorId"]}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        isDisabled={formType === "view" ? true : false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="properties-td-text-style">Объем эмиссии</td>
                  </tr>
                  <tr>
                    <td width="430px">
                      <TextField
                        variant="outlined"
                        size="small"
                        // style={{ width: "120px" }}
                        style={{ width: "100%" }}
                        name="emissionVolume"
                        placeholder="0.0"
                        onBlur={handleFloatChange}
                        defaultValue={fieldValue.emissionVolume}
                        InputProps={{ inputComponent: FloatFormat }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: "14px" }}>
                      <Checkbox
                        style={{ color: "#337E86" }}
                        name="hasIndex"
                        onChange={handleCheckboxChange}
                        checked={fieldValue["hasIndex"] ? (fieldValue["hasIndex"] === false ? false : true) : false}
                        disabled={formType === "view" ? true : false}
                      />Индекс
                    </td>
                    <td></td>
                  </tr>
                </table>

                <table align="center" width="100%">
                  <tr>
                    <td style={{ fontSize: "14px" }}>
                      <Checkbox
                        style={{ color: "#337E86" }}
                        name="hasProfit"
                        onChange={handleCheckboxChange}
                        checked={fieldValue["hasProfit"] ? (fieldValue["hasProfit"] === false ? false : true) : false}
                        disabled={formType === "view" ? true : false}
                      />Есть доходность
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className="properties-td-text-style">Дата открытия</td>
                    <td className="properties-td-text-style">Дата закрытия</td>
                  </tr>
                  <tr>
                    <td>
                      <TextField
                        variant="outlined"
                        size="small"
                        type="date"
                        name="openDate"
                        onBlur={handleDateTimeChange}
                        style={{ width: "150px" }}
                        // defaultValue={(fieldValue["openDate"]) ? parseDate(fieldValue["openDate"]): ""}
                        defaultValue={(fieldValue["openDate"] !== undefined) ? moment(fieldValue["openDate"]).format('YYYY-MM-DD') : ""}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                    <td>
                      <TextField
                        variant="outlined"
                        size="small"
                        type="date"
                        name="closeDate"
                        onBlur={handleDateTimeChange}
                        style={{ width: "150px" }}
                        // defaultValue={(fieldValue["closeDate"]) ? parseDate(fieldValue["closeDate"]): ""}
                        defaultValue={(fieldValue["closeDate"] !== undefined) ? moment(fieldValue["closeDate"]).format('YYYY-MM-DD') : ""}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="properties-td-text-style">Дата прекращения обращения</td>
                    <td className="properties-td-text-style">Номер текущей сессии</td>
                  </tr>
                  <tr>
                    <td>
                      <TextField
                        variant="outlined"
                        size="small"
                        type="date"
                        name="expiryDate"
                        onBlur={handleDateTimeChange}
                        style={{ width: "150px" }}
                        defaultValue={(fieldValue["expiryDate"] !== undefined) ? moment(fieldValue["expiryDate"]).format('YYYY-MM-DD') : ""}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                    <td>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="sessionNo"
                        placeholder="0"
                        value={fieldValue.sessionNo}
                        onChange={handleIntChange}
                        style={{ width: "150px" }}
                        InputProps={{ inputComponent: IntegerFormat }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="properties-td-text-style" style={{ width: "250px" }}>Срок репо для автоматического репо, дней</td>
                    <td>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="repoPeriodDays"
                        placeholder="0"
                        value={fieldValue.repoPeriodDays}
                        onChange={handleIntChange}
                        style={{ width: "150px" }}
                        InputProps={{ inputComponent: IntegerFormat }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Checkbox
                        style={{ color: "#337E86" }}
                        name="frankfurt"
                        onChange={handleCheckboxChange}
                        checked={fieldValue["frankfurt"] ? (fieldValue["frankfurt"] === false ? false : true) : false}
                        disabled={formType === "view" ? true : false}
                      />
                      Режим ожидания
                    </td>
                    <td>
                      <table width="100%">
                        <tr>
                          <td width="160px">
                            <TextField
                              variant="outlined"
                              size="small"
                              name="frankfurtActiveMinutes"
                              placeholder="0"
                              value={fieldValue.frankfurtActiveMinutes}
                              onChange={handleIntChange}
                              style={{ width: "150px", marginLeft: -2 }}
                              InputProps={{ inputComponent: IntegerFormat }}
                              disabled={formType === "view" ? true : false}
                            />
                          </td>
                          <td>- мин. ожидание</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  {/* <tr>
                    <td colSpan={2}>
                      <table width="100%">
                        <tr>
                          <td width="160px">
                            <TextField
                              variant="outlined"
                              size="small"
                              name="frankfurtActiveMinutes"
                              placeholder="0"
                              value={fieldValue.frankfurtActiveMinutes}
                              onChange={handleIntChange}
                              style={{ width: "150px" }}
                              InputProps={{ inputComponent: IntegerFormat }}
                              disabled={formType === "view" ? true : false}
                            />
                          </td>
                          <td>- мин. ожидание</td>
                        </tr>
                      </table>


                    </td>
                  </tr> */}
                </table>
              </TabPanel>
              {/* Цена */}
              <TabPanel style={{ paddingLeft: 15, width: "590px", height: "520px" }}>
                <table align="center" width="100%">
                  <tr>
                    <td className="properties-td-text-style">Номинал</td>
                    <td>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="nominal"
                        placeholder="0.0"
                        value={fieldValue.nominal}
                        onChange={handleFloatChange}
                        style={{ width: "80px" }}
                        InputProps={{ inputComponent: FloatFormat }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                    <td className="properties-td-text-style">Оценка</td>
                    <td>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="repoNominal"
                        placeholder="0.0"
                        value={fieldValue.repoNominal}
                        onChange={handleFloatChange}
                        style={{ width: "80px" }}
                        InputProps={{ inputComponent: FloatFormat }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="properties-td-text-style">Цена открытия</td>
                    <td>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="openSessionPrice"
                        placeholder="0.0"
                        value={fieldValue.openSessionPrice}
                        onChange={handleFloatChange}
                        style={{ width: "80px" }}
                        InputProps={{ inputComponent: FloatFormat }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                    <td className="properties-td-text-style">Средняя цена утренних торгов</td>
                    <td>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="avgStartPrice"
                        placeholder="0.0"
                        value={fieldValue.avgStartPrice}
                        onChange={handleFloatChange}
                        style={{ width: "80px" }}
                        InputProps={{ inputComponent: FloatFormat }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                  </tr>
                </table>
                <FormControl component="fieldset" key={updateState + "priceForOpenSession"}>
                  <FormLabel component="legend">Цена открытия определяется...</FormLabel>
                  <RadioGroup
                    name="priceForOpenSession"
                    value={fieldValue["priceForOpenSession"]}
                    onChange={handleRadionGroupChange}
                  >
                    <FormControlLabel
                      disabled={formType === "view" ? true : false}
                      style={{ marginTop: -8 }}
                      value={1}
                      control={<Radio style={{ color: fieldValue.priceForOpenSession === 1 ? "#337E86" : null }} />}
                      label="Как цена закрытия предыдущих торгов"
                    />
                    <FormControlLabel
                      disabled={formType === "view" ? true : false}
                      style={{ marginTop: -20 }}
                      value={2}
                      control={<Radio style={{ color: fieldValue.priceForOpenSession === 2 ? "#337E86" : null }} />}
                      label="Как средневзвешенная цена предыдущих торгов"
                    />
                    <FormControlLabel
                      disabled={formType === "view" ? true : false}
                      style={{ marginTop: -20 }}
                      value={3}
                      control={<Radio style={{ color: fieldValue.priceForOpenSession === 3 ? "#337E86" : null }} />}
                      label="Как цена отсечения предторгового периода"
                    />
                    <FormControlLabel
                      disabled={formType === "view" ? true : false}
                      style={{ marginTop: -20 }}
                      value={4}
                      control={<Radio style={{ color: fieldValue.priceForOpenSession === 4 ? "#337E86" : null }} />}
                      label="Как цена первой сделки"
                    />
                    <FormControlLabel
                      disabled={formType === "view" ? true : false}
                      style={{ marginTop: -20 }}
                      value={5}
                      control={<Radio style={{ color: fieldValue.priceForOpenSession === 5 ? "#337E86" : null }} />}
                      label="Подтвержденная маклером"
                    />

                  </RadioGroup>
                </FormControl>
                <FormControl component="fieldset" key={updateState + "priceForOpenSessionMorning"}>
                  <FormLabel component="legend">Цена открытия утренней сессии определяется...</FormLabel>
                  <RadioGroup
                    name="priceForOpenSessionMorning"
                    value={fieldValue["priceForOpenSessionMorning"]}
                    onChange={handleRadionGroupChange}
                  >
                    <FormControlLabel
                      disabled={formType === "view" ? true : false}
                      style={{ marginTop: -8 }}
                      value={1}
                      control={<Radio style={{ color: fieldValue.priceForOpenSessionMorning === 1 ? "#337E86" : null }} />}
                      label="Как средняя цена утренней сессии"
                    />
                    <FormControlLabel
                      disabled={formType === "view" ? true : false}
                      style={{ marginTop: -20 }}
                      value={2}
                      control={<Radio style={{ color: fieldValue.priceForOpenSessionMorning === 2 ? "#337E86" : null }} />}
                      label="Как средняя цена последней сессии"
                    />
                  </RadioGroup>
                </FormControl>
              </TabPanel>
              {/* Позиции */}
              <TabPanel style={{ paddingLeft: 15, width: "590px", height: "520px" }}>
                <table align="center" width="100%">
                  <tr>
                    <td className="properties-td-text-style">Стандартное кол-во базового актива</td>
                    <td>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="standardAmount"
                        placeholder="0"
                        value={fieldValue.standardAmount}
                        onChange={handleIntChange}
                        style={{ width: "100px" }}
                        InputProps={{ inputComponent: IntegerFormat }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="properties-td-text-style">Кратное кол-во базовых активо - лот</td>
                    <td>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="standardLot"
                        placeholder="0"
                        value={fieldValue.standardLot}
                        onChange={handleIntChange}
                        style={{ width: "100px" }}
                        InputProps={{ inputComponent: IntegerFormat }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="properties-td-text-style">Минимальное кол-во инструмента в заявке</td>
                    <td>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="minBidAmount"
                        placeholder="0"
                        value={fieldValue.minBidAmount}
                        onChange={handleIntChange}
                        style={{ width: "100px" }}
                        InputProps={{ inputComponent: IntegerFormat }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="properties-td-text-style">
                      <Checkbox
                        style={{ color: "#337E86" }}
                        name="checkMaxBidAmount"
                        onChange={handleCheckboxChange}
                        checked={fieldValue["checkMaxBidAmount"] ? (fieldValue["checkMaxBidAmount"] === false ? false : true) : false}
                        disabled={formType === "view" ? true : false}
                      />Максимальное кол-во инструмента в заявке
                    </td>
                    <td>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="maxBidAmount"
                        placeholder="0"
                        value={fieldValue.maxBidAmount}
                        onChange={handleIntChange}
                        style={{ width: "100px" }}
                        InputProps={{ inputComponent: IntegerFormat }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="properties-td-text-style">Ставка деп. маржи по спред. позиции</td>
                    <td>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="marginForSpreadPosition"
                        placeholder="0.0"
                        value={fieldValue.marginForSpreadPosition}
                        onChange={handleFloatChange}
                        style={{ width: "100px" }}
                        InputProps={{ inputComponent: FloatFormat }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="properties-td-text-style">Ставка деп. маржи по изол. позиции</td>
                    <td>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="marginForIsolatedPosition"
                        placeholder="0.0"
                        value={fieldValue.marginForIsolatedPosition}
                        onChange={handleFloatChange}
                        style={{ width: "100px" }}
                        InputProps={{ inputComponent: FloatFormat }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                  </tr>
                </table>
              </TabPanel>
              {/* Заявки */}
              <TabPanel style={{ paddingLeft: 15, width: "590px", height: "520px" }}>
                <table align="center" width="100%" size="small">
                  <div style={{ border: "1px solid grey", marginRight: 8 }}>
                    <tr>
                      <th>Разрешены заявки</th>
                    </tr>
                    <tr>
                      <td className="properties-td-text-style">
                        <Checkbox
                          size="small"
                          style={{ color: "#337E86" }}
                          name="bidAllowToLimited"
                          onChange={handleCheckboxChange}
                          checked={fieldValue["bidAllowToLimited"] ? (fieldValue["bidAllowToLimited"] === false ? false : true) : false}
                          disabled={formType === "view" ? true : false}
                        />Лимитированные
                      </td>
                      <td className="properties-td-text-style">
                        <Checkbox
                          size="small"
                          style={{ color: "#337E86" }}
                          name="bidAllowToDirect"
                          onChange={handleCheckboxChange}
                          checked={fieldValue["bidAllowToDirect"] ? (fieldValue["bidAllowToDirect"] === false ? false : true) : false}
                          disabled={formType === "view" ? true : false}
                        />Прямые
                      </td>
                    </tr>
                    <tr>
                      <td className="properties-td-text-style" style={{ marginTop: -10 }}>
                        <Checkbox
                          size="small"
                          style={{ color: "#337E86" }}
                          name="bidAllowToMarket"
                          onChange={handleCheckboxChange}
                          checked={fieldValue["bidAllowToMarket"] ? (fieldValue["bidAllowToMarket"] === false ? false : true) : false}
                          disabled={formType === "view" ? true : false}
                        />Рыночные
                      </td>
                      <td className="properties-td-text-style">
                        <Checkbox
                          size="small"
                          style={{ color: "#337E86" }}
                          name="bidAllowToDirectConfirmAdmin"
                          onChange={handleCheckboxChange}
                          checked={fieldValue["bidAllowToDirectConfirmAdmin"] ? (fieldValue["bidAllowToDirectConfirmAdmin"] === false ? false : true) : false}
                          disabled={formType === "view" ? true : false}
                        />Прямые сделки подтверждает маклер
                      </td>
                    </tr>
                    <tr>
                      <td className="properties-td-text-style">
                        <Checkbox
                          size="small"
                          style={{ color: "#337E86" }}
                          name="bidAllowToRepo"
                          onChange={handleCheckboxChange}
                          checked={fieldValue["bidAllowToRepo"] ? (fieldValue["bidAllowToRepo"] === false ? false : true) : false}
                          disabled={formType === "view" ? true : false}
                        />Репо
                      </td>
                      <td className="properties-td-text-style">
                        <Checkbox
                          size="small"
                          style={{ color: "#337E86" }}
                          name="bidAllowToDirectQuotation"
                          onChange={handleCheckboxChange}
                          checked={fieldValue["bidAllowToDirectQuotation"] ? (fieldValue["bidAllowToDirectQuotation"] === false ? false : true) : false}
                          disabled={formType === "view" ? true : false}
                        />Прямые котировки
                      </td>
                    </tr>
                  </div>
                </table>
                <table align="center" width="100%">
                  <tr>
                    <td className="properties-td-text-style">
                      <Checkbox
                        size="small"
                        style={{ color: "#337E86" }}
                        name="allowToHiddenAmount"
                        onChange={handleCheckboxChange}
                        checked={fieldValue["allowToHiddenAmount"] ? (fieldValue["allowToHiddenAmount"] === false ? false : true) : false}
                        disabled={formType === "view" ? true : false}
                      />Скрытое кол-во разрешено
                    </td>
                  </tr>
                  <tr>
                    <td className="properties-td-text-style">
                      <Checkbox
                        size="small"
                        style={{ color: "#337E86" }}
                        name="disabledTillDealDone"
                        onChange={handleCheckboxChange}
                        checked={fieldValue["disabledTillDealDone"] ? (fieldValue["disabledTillDealDone"] === false ? false : true) : false}
                        disabled={formType === "view" ? true : false}
                      />Приобретенное кол-во недоступно до расчетов по сделке
                    </td>
                  </tr>
                  <tr>
                    <td className="properties-td-text-style">
                      <Checkbox
                        size="small"
                        style={{ color: "#337E86" }}
                        name="setBidExpirationDate"
                        onChange={handleCheckboxChange}
                        checked={fieldValue["setBidExpirationDate"] ? (fieldValue["setBidExpirationDate"] === false ? false : true) : false}
                        disabled={formType === "view" ? true : false}
                      />Заявки сохраняются до указанной в них даты
                    </td>
                  </tr>
                </table>
                <table align="center" width="100%">
                  <tr>
                    <td className="properties-td-text-style">
                      <Checkbox
                        size="small"
                        style={{ color: "#337E86" }}
                        name="amountEqualsToVolume"
                        onChange={handleCheckboxChange}
                        checked={fieldValue["amountEqualsToVolume"] ? (fieldValue["amountEqualsToVolume"] === false ? false : true) : false}
                        disabled={formType === "view" ? true : false}
                      />Количество равно объему(размещение)
                    </td>
                  </tr>
                </table>
              </TabPanel>
              {/* Лимиты */}
              <TabPanel style={{ paddingLeft: 15, width: "590px", height: "520px" }}>
                <table align="center" width="100%">
                  <tr>
                    <td className="properties-td-text-style">
                      <Checkbox
                        size="small"
                        style={{ color: "#337E86" }}
                        name="checkAvailableMoney"
                        onChange={handleCheckboxChange}
                        checked={fieldValue["checkAvailableMoney"] ? (fieldValue["checkAvailableMoney"] === false ? false : true) : false}
                        disabled={formType === "view" ? true : false}
                      />Проверка лимитов по деньгам
                    </td>
                  </tr>
                  <tr>
                    <td className="properties-td-text-style">
                      <Checkbox
                        size="small"
                        style={{ color: "#337E86" }}
                        name="checkAvailableInstruments"
                        onChange={handleCheckboxChange}
                        checked={fieldValue["checkAvailableInstruments"] ? (fieldValue["checkAvailableInstruments"] === false ? false : true) : false}
                        disabled={formType === "view" ? true : false}
                      />Проверка лимитов по инстр.
                    </td>
                  </tr>
                  <tr>
                    <td className="properties-td-text-style">
                      <Checkbox
                        size="small"
                        style={{ color: "#337E86" }}
                        name="allowSingleAccount"
                        onChange={handleCheckboxChange}
                        checked={fieldValue["allowSingleAccount"] ? (fieldValue["allowSingleAccount"] === false ? false : true) : false}
                        disabled={formType === "view" ? true : false}
                      />Разрешены сделки с одним счетом
                    </td>
                  </tr>
                </table>
                <table align="center" width="100%">
                  <tr>
                    <td className="properties-td-text-style">Отображаемая точность цены (до 4 знаков)</td>
                    <td>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="digitsAfterDecimalPoint"
                        placeholder="0"
                        value={fieldValue.digitsAfterDecimalPoint}
                        onChange={handleIntChange}
                        style={{ width: "100px" }}
                        InputProps={{ inputComponent: IntegerFormat }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="properties-td-text-style">Минимальный шаг изменения цены</td>
                    <td>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="minStepPrice"
                        placeholder="0.0"
                        value={fieldValue.minStepPrice}
                        onChange={handleFloatChange}
                        style={{ width: "100px" }}
                        InputProps={{ inputComponent: FloatFormat }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="properties-td-text-style">Предельное отклонение от цены открытия</td>
                    <td>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="permissibleDeviationOpenPrice"
                        placeholder="0.0"
                        value={fieldValue.permissibleDeviationOpenPrice}
                        onChange={handleFloatChange}
                        style={{ width: "100px" }}
                        InputProps={{ inputComponent: FloatFormat }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="properties-td-text-style">Предельное отклонение от цены последней сделки</td>
                    <td>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="permissibleDeviationLastDealPrice"
                        placeholder="0.0"
                        value={fieldValue.permissibleDeviationLastDealPrice}
                        onChange={handleFloatChange}
                        style={{ width: "100px" }}
                        InputProps={{ inputComponent: FloatFormat }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="properties-td-text-style">Лимит на долю рынка в процентах</td>
                    <td>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="shareLimitInPercent"
                        placeholder="0"
                        value={fieldValue.shareLimitInPercent}
                        onChange={handleIntChange}
                        style={{ width: "100px" }}
                        InputProps={{ inputComponent: IntegerFormat }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="properties-td-text-style">Макс. ОП вне лимита на долю рынка</td>
                    <td>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="maxShareLimitInPercent"
                        placeholder="0"
                        value={fieldValue.maxShareLimitInPercent}
                        onChange={handleIntChange}
                        style={{ width: "100px" }}
                        InputProps={{ inputComponent: IntegerFormat }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="properties-td-text-style">Предупреждение при отклонении от цены открытия более чем на</td>
                    <td>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="warningOnDeviationInPercent"
                        placeholder="0.0"
                        value={fieldValue.warningOnDeviationInPercent}
                        onChange={handleFloatChange}
                        style={{ width: "100px" }}
                        InputProps={{ inputComponent: FloatFormat }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                  </tr>
                </table>
              </TabPanel>
              {/* Валюты */}
              <TabPanel style={{ padding: "0px 15px 0px 15px" }}>
                <table align="center" width="95%">
                  <tr>
                    <td width="25%" className="properties-td-text-style">Валюта в которой ведутся торги</td>
                    <td width="35%">
                      <Select
                        name={"currencyForTrade"}
                        placeholder={"Выбрать..."}
                        value={selectedOptions["currencyForTrade"]}
                        onChange={handleSelectChange}
                        options={enumOptions["currencyForTrade"]}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        isDisabled={formType === "view" ? true : false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td width="25%" className="properties-td-text-style">Валюта в которой производятся расчеты</td>
                    <td width="35%">
                      <Select
                        name={"currencyForClearing"}
                        placeholder={"Выбрать..."}
                        value={selectedOptions["currencyForClearing"]}
                        onChange={handleSelectChange}
                        options={enumOptions["currencyForClearing"]}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        isDisabled={formType === "view" ? true : false}
                      />
                    </td>
                  </tr>
                </table>
                <FormControl component="fieldset" key={updateState + "fieldset"}>
                  <FormLabel component="legend">Способ пересчета</FormLabel>
                  <RadioGroup
                    name="clearingMethod"
                    value={fieldValue["clearingMethod"]}
                    onChange={handleRadionGroupChange}
                  >
                    <FormControlLabel
                      disabled={formType === "view" ? true : false}
                      style={{ marginTop: -8 }}
                      value={1}
                      control={<Radio style={{ color: fieldValue.clearingMethod === 1 ? "#337E86" : null }} />}
                      label="Без пересчета"
                    />
                    <FormControlLabel
                      disabled={formType === "view" ? true : false}
                      style={{ marginTop: -20 }}
                      value={2}
                      control={<Radio style={{ color: fieldValue.clearingMethod === 2 ? "#337E86" : null }} />}
                      label="По установленному курсу"
                    />
                    <FormControlLabel
                      disabled={formType === "view" ? true : false}
                      style={{ marginTop: -20 }}
                      value={3}
                      control={<Radio style={{ color: fieldValue.clearingMethod === 3 ? "#337E86" : null }} />}
                      label="По курсу биржи"
                    />
                  </RadioGroup>
                </FormControl>
                <table>
                  <tr>
                    <td className="properties-td-text-style">
                      <Checkbox
                        // size="small"
                        style={{ color: "#337E86" }}
                        name="isCoupon"
                        onChange={handleCheckboxChange}
                        checked={fieldValue["isCoupon"] ? (fieldValue["isCoupon"] === false ? false : true) : false}
                        disabled={formType === "view" ? true : false}
                      />Купонная бумага
                    </td>
                    <td className="properties-td-text-style" align="right" style={{ paddingLeft: "30px" }}>Курс пересчета</td>
                    <td>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="couponExchangeRate"
                        placeholder="0.0"
                        value={fieldValue.couponExchangeRate}
                        onChange={handleFloatChange}
                        style={{ width: "150px" }}
                        InputProps={{ inputComponent: FloatFormat }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                  </tr>
                </table>
                {/* {fieldValue.isCoupon === true && */}
                <table width="100%">
                  <tr>
                    <td width="80%">
                      <fieldset>
                        <legend>Купонная бумага</legend>
                        <table align="center" width="100%">
                          <tr>
                            <td style={{ verticalAlign: "top" }}>
                              <table align="center" width="100%">
                                <tr className="properties-td-text-style">
                                  <td>Дней в интервале</td>
                                </tr>
                                <tr>
                                  <td>
                                    <TextField
                                      variant="outlined"
                                      size="small"
                                      name="couponIntervalDays"
                                      placeholder="0"
                                      value={fieldValue.couponIntervalDays}
                                      onChange={handleIntChange}
                                      style={{ width: "150px" }}
                                      InputProps={{ inputComponent: IntegerFormat }}
                                      disabled={formType === "view" ? true : false}
                                    />
                                  </td>
                                </tr>
                                <tr className="properties-td-text-style">
                                  <td>Дней с посл. выплаты</td>
                                </tr>
                                <tr>
                                  <td>
                                    <TextField
                                      variant="outlined"
                                      size="small"
                                      name="couponLastPaymentDays"
                                      placeholder="0"
                                      value={fieldValue.couponLastPaymentDays}
                                      onChange={handleIntChange}
                                      style={{ width: "150px" }}
                                      InputProps={{ inputComponent: IntegerFormat }}
                                      disabled={formType === "view" ? true : false}
                                    />
                                  </td>
                                </tr>
                                <tr className="properties-td-text-style">
                                  <td>Накопл. интерес (НИ)</td>
                                </tr>
                                <tr>
                                  <td>
                                    <TextField
                                      variant="outlined"
                                      size="small"
                                      name="couponAccumulatedInterest"
                                      placeholder="0.0"
                                      value={fieldValue.couponAccumulatedInterest}
                                      onChange={handleFloatChange}
                                      style={{ width: "150px" }}
                                      InputProps={{ inputComponent: FloatFormat }}
                                      disabled={formType === "view" ? true : false}
                                    />
                                  </td>
                                </tr>
                                <tr className="properties-td-text-style">
                                  <td>Курс на дату начала обращения</td>
                                </tr>
                                <tr>
                                  <td>
                                    <TextField
                                      variant="outlined"
                                      size="small"
                                      name="couponRateBegin"
                                      placeholder="0.0"
                                      value={fieldValue.couponRateBegin}
                                      onChange={handleFloatChange}
                                      style={{ width: "150px" }}
                                      InputProps={{ inputComponent: FloatFormat }}
                                      disabled={formType === "view" ? true : false}
                                    />
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td style={{ verticalAlign: "top" }}>
                              <table align="center" width="100%">
                                <tr className="properties-td-text-style">
                                  <td>Купонная годовая ставка %</td>
                                </tr>
                                <tr className="properties-td-text-style">
                                  {/* <td className="properties-td-text-style" align="right" style={{ whiteSpace: "nowrap" }}>Купонная годовая ставка %</td> */}
                                  <td>
                                    <TextField
                                      variant="outlined"
                                      size="small"
                                      name="couponAnnualRate"
                                      placeholder="0.0"
                                      value={fieldValue.couponAnnualRate}
                                      onChange={handleFloatChange}
                                      style={{ width: "150px" }}
                                      InputProps={{ inputComponent: FloatFormat }}
                                      disabled={formType === "view" ? true : false}
                                    />
                                  </td>
                                </tr>
                                <tr className="properties-td-text-style">
                                  <td>Кол-во выплат в году</td>
                                </tr>
                                <tr className="properties-td-text-style">
                                  {/* <td className="properties-td-text-style" align="right" style={{ whiteSpace: "nowrap" }}>Кол-во выплат в году</td> */}
                                  <td>
                                    <TextField
                                      variant="outlined"
                                      size="small"
                                      name="couponAnnualPaymentCount"
                                      placeholder="0"
                                      value={fieldValue.couponAnnualPaymentCount}
                                      onChange={handleIntChange}
                                      style={{ width: "150px" }}
                                      InputProps={{ inputComponent: IntegerFormat }}
                                      disabled={formType === "view" ? true : false}
                                    />
                                  </td>
                                </tr>
                                <tr className="properties-td-text-style">
                                  <td>Дата посл. выплаты</td>
                                </tr>
                                <tr className="properties-td-text-style">
                                  {/* <td className="properties-td-text-style" align="right" style={{ whiteSpace: "nowrap" }}>Дата посл. выплаты</td> */}
                                  <td>
                                    <TextField
                                      variant="outlined"
                                      size="small"
                                      type="date"
                                      name="couponLastPayDate"
                                      onBlur={handleDateTimeChange}
                                      defaultValue={(fieldValue["couponLastPayDate"] !== undefined) ? moment(fieldValue["couponLastPayDate"]).format('YYYY-MM-DD') : ""}
                                      style={{ width: "150px" }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      disabled={formType === "view" ? true : false}
                                    />
                                  </td>
                                </tr>
                                <tr className="properties-td-text-style">
                                  <td>Дата след. выплаты</td>
                                </tr>
                                <tr className="properties-td-text-style">
                                  {/* <td className="properties-td-text-style" align="right" style={{ whiteSpace: "nowrap" }}>Дата след. выплаты</td> */}
                                  <td>
                                    <TextField
                                      variant="outlined"
                                      size="small"
                                      type="date"
                                      name="couponNextPayDate"
                                      onBlur={handleDateTimeChange}
                                      defaultValue={(fieldValue["couponNextPayDate"] !== undefined) ? moment(fieldValue["couponNextPayDate"]).format('YYYY-MM-DD') : ""}
                                      style={{ width: "150px" }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      disabled={formType === "view" ? true : false}
                                    />
                                  </td>
                                </tr>
                                <tr className="properties-td-text-style">
                                  <td>Дата с которой не начис. НИ</td>
                                </tr>
                                <tr className="properties-td-text-style">
                                  {/* <td className="properties-td-text-style" align="right" style={{ whiteSpace: "nowrap" }}>Дата с которой не начис. НИ</td> */}
                                  <td>
                                    <TextField
                                      variant="outlined"
                                      size="small"
                                      type="date"
                                      name="couponAiExcludingDate"
                                      onBlur={handleDateTimeChange}
                                      defaultValue={(fieldValue["couponAiExcludingDate"] !== undefined) ? moment(fieldValue["couponAiExcludingDate"]).format('YYYY-MM-DD') : ""}
                                      style={{ width: "150px" }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      disabled={formType === "view" ? true : false}
                                    />
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </fieldset>
                    </td>
                    <td width="20%" style={{ verticalAlign: "top" }}>
                      <fieldset>
                        <legend>Метод</legend>
                        {returnRadioGroup("couponMethod")}
                      </fieldset>
                    </td>
                  </tr>
                </table>
                {/* } */}
              </TabPanel>
              {/* Swift */}
              <TabPanel style={{ paddingLeft: 15, width: "590px", height: "520px" }}>
                <table align="center" width="100%">
                  <tr>
                    <td className="properties-td-text-style">Коррекция количества в SWIFT-сообщении</td>
                    <td>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="swiftAmountCorrection"
                        placeholder="0.0"
                        value={fieldValue.swiftAmountCorrection}
                        onChange={handleFloatChange}
                        style={{ width: "100px" }}
                        InputProps={{ inputComponent: FloatFormat }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="properties-td-text-style">Коррекция цены в SWIFT-сообщении</td>
                    <td>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="swiftPriceCorrection"
                        placeholder="0.0"
                        value={fieldValue.swiftPriceCorrection}
                        onChange={handleFloatChange}
                        style={{ width: "100px" }}
                        InputProps={{ inputComponent: FloatFormat }}
                        disabled={formType === "view" ? true : false}
                      />
                    </td>
                  </tr>
                </table>
              </TabPanel>
            </Tabs>
            <table width="100%">
              <tr>
                <td width="100%" align="center">
                  {buttons.map(button => (
                    <button
                      className={button.className}
                      onClick={() => buttonClick(button.name)}
                      style={{ marginRight: 20 }}
                    >
                      {button.label}
                    </button>
                  ))}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </Draggable>
    )
  }
  else {
    return <div>Loading...</div>
  }
}