import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CheckboxTree from 'react-checkbox-tree'; // https://www.npmjs.com/package/react-checkbox-tree
import Checkbox from '@material-ui/core/Checkbox';
// Icons
import { IoMdClose } from 'react-icons/io';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { MdExpandLess } from 'react-icons/md';
import { MdExpandMore } from 'react-icons/md';
import { GiPapers } from 'react-icons/gi';
import { AiFillFolderOpen } from 'react-icons/ai';
import { AiFillFolder } from 'react-icons/ai';

// Library
import "../styles/generalStyles.css"
import { v4 as uuidv4 } from 'uuid';
import Draggable from 'react-draggable';
import swal from 'sweetalert'; // https://sweetalert.js.org/guides/
import { CompressOutlined } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  resizeForm: {
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    border: "1px solid #2B4D80",
    display: "flex",
    backgroundColor: "#F5F5F5",
    boxShadow: theme.shadows[1],
    "flex-flow": "column",
    width: "35%",
    height: "600px",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "150px",
    position: 'absolute',
  },
  rctIconExpandClose: {
    content: "\f054"
  },
  rctIconExpandOpen: {
    content: "\f078"
  }
}))

//Home(props) - получаем переменные от родителя App.js 
export default (props) => {
  const classes = useStyles()
  const [token] = useState(props.token)
  const [kseRESTApi] = useState(props.kseRESTApi) //Local KFB main REST
  const [userProfile, setUserProfile] = useState(props.userProfile)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [instrumentFilters, setInstrumentFilters] = useState(props.instrumentFilters)
  const [checked, setChecked] = useState([])
  const [expanded, setExpanded] = useState(["markets"])
  const [nodes, setNodes] = useState([])
  const [treeKey, setTreeKey] = useState(null)

  const [fieldValue, setFieldValue] = useState({})
  const [position, setPosition] = useState({ x: 0, y: 0 })

  useEffect(async () => {
    console.log("INST FILTERS PROPS", props)
    let enumDataToCollect = [
      { enumName: "markets", enumDef: "ab14dc6d-0139-4dd4-ab65-172cacb636f8" },
      { enumName: "sectors", enumDef: "df59dde0-68fd-4a8b-84e1-aa33531d2fe6" },
      { enumName: "groups", enumDef: "3e66280f-9295-46af-8855-cfce4d98faf1" },
      { enumName: "instruments", enumDef: "3e819d7e-25d0-4a04-a3ff-092fd348a375" },
    ]
    let enums = await props.getEnumDataByList(enumDataToCollect)
    createTree(enums)
    props.setComponentIndex(props.getComponentIndex() + 1)
  }, [])
  function createTree(enums) {
    // console.log("EN FILTERS", enums)
    let newNode = [{
      value: 'markets',
      label: 'Рынки',
      children: []
    }]
    // Push children to their levels
    for (let m = 0; m < enums.markets.length; m++) {
      let newMarket = {
        value: "market#" + enums.markets[m].id,
        label: enums.markets[m].label,
        children: []
      }
      newNode[0].children.push(newMarket)
      for (let s = 0; s < enums.sectors.length; s++) {
        if (enums.sectors[s].market === enums.markets[m].id) {
          let newSector = {
            value: "sector#" + enums.sectors[s].id,
            label: enums.sectors[s].label,
            children: []
          }
          newMarket.children.push(newSector)
          for (let g = 0; g < enums.groups.length; g++) {
            if (enums.groups[g].sector === enums.sectors[s].id) {
              let newGroup = {
                value: "group#" + enums.groups[g].id,
                label: enums.groups[g].label,
                children: []
              }
              newSector.children.push(newGroup)
              for (let i = 0; i < enums.instruments.length; i++) {
                if (enums.instruments[i].group === enums.groups[g].id) {
                  let newInstrument = {
                    value: "instrument#" + enums.instruments[i].id,
                    label: enums.instruments[i].label,
                  }
                  newGroup.children.push(newInstrument)
                }
              }
            }
          }
        }
      }
    }
    // Clear empty levels
    for (let markets = 0; markets < newNode[0].children.length; markets++) {
      if (newNode[0].children[markets].children.length === 0) { // Check for empty markets
        newNode[0].children[markets] = {
          value: newNode[0].children[markets].value,
          label: newNode[0].children[markets].label
        }
      }
      else {
        for (let sectors = 0; sectors < newNode[0].children[markets].children.length; sectors++) { // Check for empty sectors
          if (newNode[0].children[markets].children[sectors].children.length === 0) {
            newNode[0].children[markets].children[sectors] = {
              value: newNode[0].children[markets].children[sectors].value,
              label: newNode[0].children[markets].children[sectors].label
            }
          }
          else {
            for (let groups = 0; groups < newNode[0].children[markets].children[sectors].children.length; groups++) { // Check for empty groups
              if (newNode[0].children[markets].children[sectors].children[groups].children.length === 0) {
                newNode[0].children[markets].children[sectors].children[groups] = {
                  value: newNode[0].children[markets].children[sectors].children[groups].value,
                  label: newNode[0].children[markets].children[sectors].children[groups].label
                }
              }
            }
          }
        }
      }

    }
    setNodes(newNode)
    // console.log("NEW NODE", newNode)
    if (Object.keys(instrumentFilters).length > 0) {
      let newChecked = []
      for (let m = 0; m < instrumentFilters.markets.length; m++) {
        newChecked.push("market#" + instrumentFilters.markets[m])
      }
      for (let s = 0; s < instrumentFilters.marketSectors.length; s++) {
        newChecked.push("sector#" + instrumentFilters.marketSectors[s])
      }
      for (let g = 0; g < instrumentFilters.instrumentGroups.length; g++) {
        newChecked.push("group#" + instrumentFilters.instrumentGroups[g])
      }
      for (let i = 0; i < instrumentFilters.financeInstruments.length; i++) {
        newChecked.push("instrument#" + instrumentFilters.financeInstruments[i])
      }
      // console.log("CHECKED", newChecked)
      setChecked(newChecked)
    }
  }
  // random UUID generator
  function getUUID() {
    return uuidv4()
  }
  function saveFilters() {
    console.log("CHECKED", checked)
    let filters = {
      markets: [],
      marketSectors: [],
      instrumentGroups: [],
      financeInstruments: []
    }
    for (let c = 0; c < checked.length; c++) {
      let checkedItem = checked[c].split("#")
      // console.log("checkedItem", checkedItem)
      if (checkedItem[0] === "market") {
        filters.markets.push(parseInt(checkedItem[1]))
      }
      if (checkedItem[0] === "sector") {
        filters.marketSectors.push(parseInt(checkedItem[1]))
      }
      if (checkedItem[0] === "group") {
        filters.instrumentGroups.push(parseInt(checkedItem[1]))
      }
      if (checkedItem[0] === "instrument") {
        filters.financeInstruments.push(parseInt(checkedItem[1]))
      }
    }
    // console.log("filters", filters)
    let settings = {
      type: "instrumentFilters",
      instrumentFilters: filters
    }
    props.updateUserSettingsByType(settings)
    props.setShowInstrumentFilters(false)
  }
  function getModalStyle() {
    const top = 15;
    const left = 25;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      zIndex: componentIndex
    };
  }
  function formClick() {
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 1)
    props.setComponentIndex(currIndex + 1)
  }
  function positionHandler(e, data) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 12 // Take 12% of width
    let maxDeviationX = (dimension.w / 100) * 80 // Take 80% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    // console.log("minX", minDeviationX, "maxX", maxDeviationX, "maxY", maxDeviationY)
    if (data.y < maxDeviationY) {
      // console.log("SET MIN Y", data.x)
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      // console.log("SET MIN X", data.x, minDeviationX)
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      // console.log("SET MAX X", data.x)
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
  }
  return (
    // <Draggable
    //   handle="p"
    //   onStart={() => formClick()}
    //   onMouseDown={() => formClick()}
    // >
    // <div style={getModalStyle()} className={classes.resizeForm}>
    <Draggable
      handle="p"
      onStart={() => formClick()}
      onStop={positionHandler}
      position={position}
    >
      <div
        className={classes.resizeForm}
        style={{ top: "15%", left: "12%", transform: "translate(-15%, -12%)", zIndex: componentIndex }}
        onClick={() => formClick()}
      >
        <p className="main-table-label-p-style-new">
          <table className="dragble-div-header-table-style-new">
            <tr>
              <td width="99%" className="dragble-div-header-td-style-new">Фильтрация инструментов</td>
              {/* <td onClick={()=> props.setShowInstrumentFilters(false)}><IconButton size="small"><CloseIcon/></IconButton></td> */}
              <td>
                <IoMdClose size={22} className="form-close-icons-style" style={{ marginRight: "5px" }} onClick={() => props.setShowInstrumentFilters(false)} />
              </td>
            </tr>
          </table>
        </p>
        <table align="center" width="100%">
          <tr>
            <td>
              <div style={{ height: "505px", overflow: "auto" }}>
                <CheckboxTree
                  key={treeKey}
                  nodes={nodes}
                  checked={checked}
                  expanded={expanded}
                  // optimisticToggle={true}
                  onCheck={(checked) => setChecked(checked)}
                  onExpand={(expanded) => setExpanded(expanded)}
                  // expandOnClick={true}
                  nativeCheckboxes={true}
                  // onlyLeafCheckboxes={true}
                  // showExpandAll={true}
                  icons={{
                    // check: <span className="rct-icon rct-icon-check" />,
                    // uncheck: <span className="rct-icon rct-icon-uncheck" />,
                    // halfCheck: <span className="rct-icon rct-icon-half-check" />,
                    expandClose: <span className="rct-icon rct-icon-expand-close" />,
                    expandClose: <MdExpandMore className="rct-icon rct-icon-expand-close" />,
                    expandOpen: <MdExpandLess className="rct-icon rct-icon-expand-open" />,
                    // expandAll: <span className="rct-icon rct-icon-expand-all" />,
                    // collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                    parentClose: <AiFillFolder className="rct-icon rct-icon-parent-close" />,
                    parentOpen: <AiFillFolderOpen className="rct-icon rct-icon-parent-open" />,
                    leaf: <GiPapers className="rct-icon rct-icon-leaf" />,
                  }}
                />
              </div>
            </td>
          </tr>
        </table>
        <table align="center" width="100%">
          <tr>
            <td width="100%" align="center">
              <button
                className="cancelButton"
                style={{ marginRight: 20 }}
                onClick={() => props.setShowInstrumentFilters(false)}
              >
                Отмена
              </button>
              <button
                className="approveButton"
                onClick={() => saveFilters()}
                style={{ marginRight: 20 }}
              >
                Сохранить
              </button>
            </td>
            {/* <td>
              <Button
                variant="contained"
                onClick={() => saveFilters()}
                style={{
                  margin: 3,
                  height: 30,
                  fontSize: 12,
                  color: "white",
                  backgroundColor: "#2862F4"
                }}
              >Сохранить
              </Button>
              <Button
                variant="contained"
                onClick={() => props.setShowInstrumentFilters(false)}
                style={{
                  margin: 3,
                  height: 30,
                  fontSize: 12,
                  color: "white",
                  backgroundColor: "#E73639"
                }}
              >Отмена
              </Button>
            </td> */}
          </tr>
        </table>

      </div>
    </Draggable>
  )
}