import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
// import { Grid } from '@material-ui/core';
// import LinearProgress from '@material-ui/core/LinearProgress';
import Checkbox from '@material-ui/core/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
// Icons
import CancelIcon from '@mui/icons-material/Cancel';
// import ChartIcon from '@mui/icons-material/InsertChartOutlined';
import { IoIosSettings } from 'react-icons/io';
import { IoMdClose } from 'react-icons/io';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { MdExpandMore } from 'react-icons/md';
import { MdExpandLess } from 'react-icons/md';
import SettingsIcon from '@mui/icons-material/Settings';
import BuyIcon from '@material-ui/icons/FormatBold'; //Продажа
import SellIcon from '@material-ui/icons/MonetizationOn'; //Покупка
// import { BsArrowDown } from 'react-icons/bs';

//Style
import "../styles/generalStyles.css"
// Libraries
import Draggable from 'react-draggable'; // https://www.npmjs.com/package/react-draggable
import swal from 'sweetalert'; // https://sweetalert.js.org/guides/
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";
const invert = require('invert-color'); // https://www.npmjs.com/package/invert-color
var generator = require('generate-password');

const useStyles = makeStyles(() => ({
  resizeForm: {
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    border: "1px solid #2B4D80",
    display: "flex",
    backgroundColor: "white",
    "flex-flow": "column",
    // height: "320px",
    // width: "73%",
    // width: "90%",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "30px",
    background: "white",
    position: 'absolute',
  },
  menuIconStyle: {
    position: "absolute",
    top: 7,
    right: 10
  },
  tdBody: {
    cursor: "pointer",
    fontSize: 13,
    fontWeight: "light",
    height: "30px",
    minWidth: "1px",
    maxWidth: "150px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "elipsis '[..]'",
    overflow: "hidden",
    fontFamily: "Roboto",
    borderBottom: "1px solid #D9D9D9",
    padding: "3px 3px 3px 8px",
  }
}))

export default (props) => {
  const classes = useStyles()
  const [kseRESTApi] = useState(props.kseRESTApi)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [token] = useState(props.token)
  const [userProfile] = useState(props.userProfile)
  const [form, setForm] = useState(null)
  const [docList, setDocList] = useState([])
  const [enumData, setEnumData] = useState({})

  const [updateState, setUpdateState] = useState(null)
  const [sortedColumn, setSortedColumn] = useState("null")
  const [sortedColumnOrder, setSortedColumnOrder] = useState(1)

  const [selectedBid, setSelectedBid] = useState({ bidId: null, organizationId: null, status: null })
  // const [tableKey, setTableKey] = useState(getUUID())
  const [tableKeyHead, setTableKeyHead] = useState(getUUID())
  const [tableKeyBody, setTableKeyBody] = useState(getUUID())
  const [anchorEl, setAnchorEl] = useState(null)
  const openMenu = Boolean(anchorEl)
  const [expanded, setExpanded] = useState(true)

  const [resizeDivBidsId] = useState(getUUID())
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [size, setSize] = useState({ h: 320, w: 700 })
  const [prevHeight, setPrevHeight] = useState(40)

  // Set data from props to state of component
  useEffect(async () => {
    console.log("BIDS PROPS", props)
    let gForm = await props.fetchForm("c2dcc09a-df12-4fe0-8284-f193cba09e48")
    let filteredForm = props.filterForm(gForm, props.settings)
    // console.log("FILTERED", filteredForm)
    setForm(filteredForm)
    let enumDataToCollect = [
      { enumName: "financeInstrumentId", enumDef: "3e819d7e-25d0-4a04-a3ff-092fd348a375" },
    ]
    let enumsFromList = await props.getEnumDataByList(enumDataToCollect)
    let enumsFromForm = await props.getEnumDataByForm(gForm)
    let enums = { ...enumsFromForm }
    for (let key in enumsFromList) {
      enums[key] = enumsFromList[key]
    }
    // console.log("EN BIDS", enums)
    setEnumData(enums)
    updateDocList()
    if (props.settings.position) {
      positionHandler(null, props.settings.position, false)
      // setPosition(props.settings.position)
    }
    if (props.settings.size !== undefined) {
      setSize(props.settings.size)
      if(props.settings.size.h < 60){
        setExpanded(false)
        setPrevHeight(600)
      }
    }
    props.setComponentIndex(props.getComponentIndex() + 1)
    setUpdateState(getUUID())
  }, [])
  useEffect(async () => {
    if (props.updateBidsHead !== null) {
      // console.log("HVAR", props.updateFinanceInstrumentsHead)
      let f = form
      if (f === null) {
        f = await props.fetchForm("c2dcc09a-df12-4fe0-8284-f193cba09e48")
      }
      let filteredForm = props.filterForm(f, props.getBidsSettingById(props.settings.id))
      setForm(filteredForm)
      setTableKeyHead(getUUID())
    }
  }, [props.updateBidsHead])
  useEffect(async () => {
    if (props.updateBidsBody !== null) {
      console.log("UPD BIDS", props.updateBidsBody)
      updateDocList()
    }
  }, [props.updateBidsBody])
  useEffect(async () => {
    if (props.updateBidsZIndex !== null) {
      props.setComponentIndex(props.getComponentIndex() + 1)
      setComponentIndex(props.getComponentIndex() + 1)
    }
  }, [props.updateBidsZIndex])
  async function updateDocList() {
    let docL = await props.fetchDocList("/api/Trading/GetActiveBids")
    if (sortedColumn !== "null") {
      setDocList(docL.sort(dynamicSort(sortedColumn, sortedColumnOrder, getContentTypeByName(sortedColumn))))
    }
    else {
      setDocList(docL.sort(dynamicSort("createdAt", 1, "DateTime")))
    }
    console.log("DOCL BIDS", docL)
    setTableKeyBody(getUUID())
  }
  function getContentTypeByName(name) {
    for (let s = 0; s < form.sections.length; s++) {
      for (let c = 0; c < form.sections[s].contents.length; c++) {
        if (name === form.sections[s].contents[c].name) {
          return form.sections[s].contents[c].type
        }
      }
    }
  }
  async function handleContextMenu(event) {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
    // console.log("DBL BID", event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }
  // random UUID generator
  function getUUID() {
    return uuidv4()
  }
  // random numbers generator
  function keyGen(length) {
    var password = generator.generate({
      length: length,
      numbers: true
    })
    return password
  }
  // Create grid form components
  function getGridFormItems(docListItem, contentItem) {
    let value = docListItem[contentItem.name]
    let name = contentItem.name
    let type = contentItem.type
    if (type === "Enum") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="left" className={classes.tdBody} style={{ color: getColor(docListItem) }}>-</td>)
      }
      else {
        return (
          <td
            align="left"
            className={classes.tdBody}
            style={{ color: getColor(docListItem) }}
          >
            {getEnumLabel(name, value)}
          </td>
        )
      }
    }
    else if (type === "Bool") {
      return (
        <td
          align="center"
          className={classes.tdBody}
        >
          <Checkbox
            size="small"
            style={{ maxWidth: 20, height: 15, color: "#337E86" }}
            checked={(value === false || value === null || value === undefined) ? false : true}
          />
        </td>
      )
    }
    else if (type === "DateTime") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="right" className={classes.tdBody} style={{ color: getColor(docListItem) }}>-</td>)
      }
      else {
        let d = moment(value).format("DD.MM.YYYY HH:MM")
        // return d === "Invalid date" ? value : d
        return (
          <td
            align="right"
            className={classes.tdBody}
            style={{ color: getColor(docListItem) }}
          >
            {d === "Invalid date" ? value : d}
          </td>
        )
      }
    }
    else if (type === "Currency") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="right" className={classes.tdBody} style={{ color: getColor(docListItem) }}>-</td>)
      }
      else {
        let newCur = props.currencyBeautifier(value)
        // console.log("CUR", newCur)
        // return newCur
        return (
          <td
            align="right"
            className={classes.tdBody}
            style={{ color: getColor(docListItem) }}
          >
            {newCur}
          </td>
        )
      }
    }
    else if (type === "Int") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="right" style={{ color: getColor(docListItem) }}>-</td>)
      }
      else {
        let newInt = props.intBeautifier(value)
        return (
          <td
            align="right"
            className={classes.tdBody}
            style={{ color: getColor(docListItem) }}
          >
            {newInt}
          </td>
        )
      }
    }
    else {
      if (value === null || value === "" || value === undefined) {
        return (<td align="left" className={classes.tdBody} style={{ color: getColor(docListItem) }}>-</td>)
      }
      else {
        return (<td align="left" className={classes.tdBody} style={{ color: getColor(docListItem) }}>{value}</td>)
      }
    }
  }
  function getEnumLabel(name, id) {
    for (let d = 0; d < enumData[name].length; d++) {
      if (enumData[name][d].id === id) {
        return enumData[name][d].label
      }
    }
  }
  function dynamicSort(property, sortOrder, type) {
    if (type === "DateTime" || type === "Bool") {
      sortOrder = sortOrder * -1
    }
    if (type === "DateTime") {
      return function (a, b) {
        if (a[property] !== null && b[property] !== null) {
          let dateA = new Date(a[property].substring(0, 19))
          let timeInSecA = dateA.getTime() / 1000
          // console.log("timeInSecA", timeInSecA)
          let dateB = new Date(b[property].substring(0, 19))
          let timeInSecB = dateB.getTime() / 1000
          // console.log("timeInSecB", timeInSecB)
          var result = (timeInSecA < timeInSecB) ? -1 : (timeInSecA > timeInSecB) ? 1 : 0
          return result * sortOrder
        }
        else {
          if (a[property] === null) {
            return -1 * sortOrder
          }
          return 1 * sortOrder
        }
      }
    }
    else if (type === "Int" || type === "Text" || type === "Float" || type === "Bool") {
      return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
        return result * sortOrder
      }
    }
    else if (type === "Enum") {
      return function (a, b) {
        if (a[property] === null) {
          return 1 * sortOrder
        }
        else {
          let labelA = getEnumLabel(property, a[property])
          // console.log("A", property, a[property], labelA)
          let labelB = getEnumLabel(property, b[property])
          // console.log("labelB", labelB, property, b)
          var result = (labelA < labelB) ? -1 : (labelA > labelB) ? 1 : 0
          return result * sortOrder
        }

      }
    }
  }
  function sortDataByColumn(name, type) {
    // let contentItem = getContentItem(name)
    // let type = contentItem.type
    console.log("SORT", name, type)
    let sortOrder = 1
    if (sortedColumn === name) {
      sortOrder = sortedColumnOrder * -1
    }
    setSortedColumnOrder(sortOrder)
    setSortedColumn(name)
    let sortedDocList = docList.sort(dynamicSort(name, sortOrder, type))

    setDocList(sortedDocList)
    // fetchBySize(fetchForm, fetchTo, sortedDocList)
  }

  function showBuyForm() {
    handleCloseMenu()
    let instrumentId = null
    for (let i = 0; i < enumData.financeInstrumentId.length; i++) {
      if (enumData.financeInstrumentId[i].code === selectedBid.instrumentCode) {
        instrumentId = enumData.financeInstrumentId[i].id
      }
    }
    props.setOrderBuyFields(
      {
        financeInstrumentId: instrumentId,
        priceForInstrument: selectedBid.price,
        amountOfInstrument: selectedBid.amount,
      }
    )
    props.setShowOrderBuy(true)
  }
  function showSellForm() {
    handleCloseMenu()
    let instrumentId = null
    for (let i = 0; i < enumData.financeInstrumentId.length; i++) {
      if (enumData.financeInstrumentId[i].code === selectedBid.instrumentCode) {
        instrumentId = enumData.financeInstrumentId[i].id
      }
    }
    props.setOrderSellFields(
      {
        financeInstrumentId: instrumentId,
        priceForInstrument: selectedBid.price,
        amountOfInstrument: selectedBid.amount,
      }
    )
    props.setShowOrderSell(true)
  }
  function closeBid() {
    console.log("SEL bid", selectedBid)
    setAnchorEl(null)
    swal({
      text: "Вы точно хотите снять заявку?",
      icon: "warning",
      buttons: { yes: "Да", cancel: "Отмена" }
    })
      .then(async (click) => {
        if (click === "yes") {
          await fetch(kseRESTApi + "/api/Trading/CancelOrder?bidId=" + selectedBid.bidId,
            {
              "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
            }
          )
            .then(response => response.json())
            .then(function (res) {
              console.log("Cancel bid", res)
              if (res.isSuccess === true) {
                props.callSuccessToast("Заявка снята!")
                handleCloseMenu()
                updateDocList()
                props.setUpdateAccountsInstrumentPositions(getUUID())
                props.setUpdateAccountsCurrenciesPositions(getUUID())
              }
              else {
                props.callErrorToast(res.errors[0])
              }
            }
            )
        }
      })
  }
  function acceptBid() {
    setAnchorEl(null)
    props.setShowAcceptRepoOrder(true)
    props.setSelectedBid(selectedBid)
    // await fetch(kseRESTApi + "/api/Trading/?bidId=" + selectedBid.bidId,
    //   {
    //     "headers": { "content-type": "application/json", "Authorization": "Bearer " + token},
    //   }
    // )
    // .then(response => response.json())
    // .then(function(res){
    //     console.log("Cancel bid", res)
    //     if(res.isSuccess === true){
    //       props.callSuccessToast("Заявка снята!")
    //       handleCloseMenu()
    //       updateDocList()
    //     }
    //     else{
    //       props.callErrorToast(res.errors[0])
    //     }
    //   }
    // )
  }
  function acceptDirectBid() {
    setAnchorEl(null)
    // props.setShowAcceptRepoOrder(true)
    // props.setSelectedBid(selectedBid)
    props.openDirectOrderDetail(selectedBid)
  }

  function showBidProperties() {
    handleCloseMenu()
    props.openBidProperties(selectedBid)
  }
  function getBackground(bid, index) {
    if (selectedBid.bidId === bid.bidId) {
      return "#CECECE"
    }
    else {
      if (index % 2 === 0) {
        // return "white"
        return "#FBFBFB"
      }
      else {
        // return "#F3F3F3"
        // return "#def3f7" // blue
        // return "#F6F4F3" // beige
        return "#f7f7f7"
      }
    }
  }
  function getColor(bid) {
    if (selectedBid.bidId === bid.id) {
      if (bid.textColor === "black") {
        return "black"
      }
      else {
        return bid.textColor
      }
    }
    else{
      if (bid.textColor === "black") {
        return "#444"
      }
      else {
        return bid.textColor
      }
    }
  }
  function selectBid(item) {
    console.log("SEL BID", item)
    if(selectedBid.bidId === item.bidId){
      setSelectedBid({ bidId: null })
      props.setSelectedBid({ bidId: null })
    }
    else{
      setSelectedBid(item)
      props.setSelectedBid(item)
    }
  }

  function formClick() {
    // console.log("CLICK")
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 2)
    props.setComponentIndex(currIndex + 2)
  }

  // function positionHandler(e, data) {
  //   let settings = {
  //     id: props.settings.id,
  //     type: props.settings.type,
  //     columns: props.settings.columns,
  //     position: { x: data.x, y: data.y },
  //     size: { h: size.h, w: size.w }
  //   }
  //   if (position.x !== data.x || position.y !== data.y) {
  //     setPosition({ x: data.x, y: data.y })
  //     props.updateUserSettingsByType(settings)
  //     // console.log("X", data.x, "Y", data.y)
  //   }
  // }
  // Describe allowed positions for different screen sizes
  function positionHandler(e, data, updProfile) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 2 // Take 2% of width
    let maxDeviationX = (dimension.w / 100) * 90 // Take 90% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    if (data.y < maxDeviationY) {
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
    if (updProfile !== false) {
      let settings = {
        id: props.settings.id,
        type: props.settings.type,
        columns: props.settings.columns,
        position: { x: x, y: y },
        size: { h: size.h, w: size.w }
      }
      props.updateUserSettingsByType(settings)
    }
  }
  function sizeHandler() {
    var clientHeight = document.getElementById(resizeDivBidsId).clientHeight;
    var clientWidth = document.getElementById(resizeDivBidsId).clientWidth;
    let diffH = Math.abs(clientHeight - size.h)
    let diffW = Math.abs(clientWidth - size.w)
    // console.log("diffH", diffH, "diffW", diffW)
    if (diffH > 2 || diffW > 2) {
      // console.log("SIZE CHANGED", "H", clientHeight, "HS", size.h, "W", clientWidth, "WS", size.w)
      let settings = {
        id: props.settings.id,
        type: props.settings.type,
        columns: props.settings.columns,
        position: { x: position.x, y: position.y },
        size: { h: clientHeight, w: clientWidth }
      }
      props.updateUserSettingsByType(settings)
      setSize({ h: clientHeight, w: clientWidth })
      if (expanded === false) {
        setExpanded(true)
        setPrevHeight(size.h)
      }
    }
  }
  function expand() {
    // console.log("PREV H", prevHeight)
    setSize({ h: prevHeight, w: size.w })
    setExpanded(!expanded)
    setPrevHeight(size.h)
    let settings = {
      id: props.settings.id,
      type: props.settings.type,
      columns: props.settings.columns,
      position: { x: position.x, y: position.y },
      size: { h: prevHeight, w: size.w }
    }
    props.updateUserSettingsByType(settings)
  }

  if (updateState !== null && form !== null) {
    try {
      return (
        <Draggable
          handle="p"
          onStart={() => formClick()}
          onStop={positionHandler}
          // defaultPosition={position}
          position={position}
          onMouseDown={() => formClick()}
        // onClick={()=> formClick()}
        >
          {/* <div style={getModalStyle()} className={classes.resizeForm}> */}
          <div
            id={resizeDivBidsId}
            onClick={() => sizeHandler()}
            className={classes.resizeForm}
            style={{ height: size.h, width: size.w, top: "15%", left: "2%", transform: "translate(-15%, -2%)", zIndex: componentIndex }}
          >
            <div className="main-table-label-div-style-new">
              <p className="main-table-label-p-style-new">
                <table className="main-table-label-div-style-new">
                  <tr>
                    <td width={"99%"} style={{ paddingLeft: 15 }}>
                      Заявки
                    </td>
                    {expanded == false
                      ?
                      <td>
                        <MdExpandLess size={22} className="form-expand-icons-style" onClick={() => expand()} />
                      </td>
                      :
                      <td>
                        <MdExpandMore size={22} className="form-expand-icons-style" onClick={() => expand()} />
                      </td>
                    }
                    <td>
                      <IoIosSettings size={22} className="form-settings-icons-style" onClick={() => props.showFormManagement(props.settings)} />
                    </td>
                    <td>
                      <IoMdClose size={22} className="form-close-icons-style" onClick={() => props.closeBidsTable(props.settings)} />
                    </td>
                  </tr>
                </table>
              </p>
            </div>
            {expanded === true &&
              <div style={{ height: "100%", width: "100%", overflow: "scroll" }}>
                <table className="main-table-style-new" width="100%" id={tableKeyHead} key={tableKeyHead}>
                  <thead>
                    <tr>
                    <th
                        class="main-table-header-th-style-new"
                        style={{
                          borderRight: "1px solid #D9D9D9",
                          fontWeight: "bold",
                          color: "#6D6D6D",
                          background: "#F6F4F3",
                          textAlign: "center",
                          fontSize: "13px"
                        }}
                      >
                        №
                      </th>
                      {form.sections.map(section =>
                        section.contents.map(contentItem => {
                          if (contentItem.show === true) {
                            return (
                              <th class="main-table-header-th-style-new">
                                {contentItem.label}
                              </th>
                            )
                          }
                        })
                      )}
                    </tr>
                  </thead>
                  <tbody key={tableKeyBody}>
                    {Object.keys(docList).length !== 0 &&
                      docList.map((docListItem, index) => (
                        <tr
                          style={{ background: getBackground(docListItem, index), color: docListItem.textColor }}
                          // style={{background: getBackground(docListItem), color: getColor(docListItem)}}
                          onContextMenu={(ev) => handleContextMenu(ev)}
                          // onMouseDown={() => selectBid(docListItem)}
                          onMouseDown={() => setSelectedBid(docListItem)}
                        >
                         <td
                            align="center"
                            class="main-table-number-style"
                            style={{ borderRight: "1px solid rgb(217, 217, 217)" }}
                          >
                            {index + 1}
                          </td>
                          {form.sections.map(section => (
                            section.contents.map(contentItem => {
                              if (contentItem.show === true) {
                                return getGridFormItems(docListItem, contentItem)
                              }
                            })
                          ))}
                        </tr>
                      )
                      )}
                  </tbody>
                </table>
                <Menu
                  id="menu-instruments"
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    'aria-labelledby': tableKeyHead,
                  }}
                >
                  {selectedBid.direction === "S" && selectedBid.status !== "DIRECT" && selectedBid.status !== "REPO" && // Продается => купить
                    <MenuItem onClick={() => showBuyForm()} style={{ color: "black" }}>
                      Купить
                      <ListItemIcon style={{ color: "black" }}>
                        <BuyIcon fontSize="small" className={classes.menuIconStyle} />
                      </ListItemIcon>
                    </MenuItem>
                  }
                  {selectedBid.direction === "B" && selectedBid.status !== "DIRECT" && selectedBid.status !== "REPO" && // Покупается => продать
                    <MenuItem onClick={() => showSellForm()} style={{ color: "black" }}>
                      Продать
                      <ListItemIcon style={{ color: "black" }}>
                        <SellIcon fontSize="small" className={classes.menuIconStyle} />
                      </ListItemIcon>
                    </MenuItem>
                  }
                  {selectedBid.status !== "REPO" && selectedBid.status !== "DIRECT" &&
                    <MenuItem onClick={() => closeBid()} style={{ color: "black" }}>
                      Снять заявку
                      <ListItemIcon style={{ color: "black" }}>
                        <CancelIcon fontSize="small" className={classes.menuIconStyle} />
                      </ListItemIcon>
                    </MenuItem>
                  }
                  <MenuItem onClick={() => showBidProperties()} style={{ color: "black" }}>
                    Свойства
                    <ListItemIcon style={{ color: "black" }}>
                      <SettingsIcon fontSize="small" className={classes.menuIconStyle} />
                    </ListItemIcon>
                  </MenuItem>
                  {selectedBid.status === "REPO" && selectedBid.organizationId !== userProfile.organization.id && // Принять РЕПО обязательства
                    <MenuItem onClick={() => acceptBid()} style={{ color: "black" }}>
                      Удовлетворить
                      <ListItemIcon style={{ color: "black" }}>
                        <DoneAllIcon fontSize="small" className={classes.menuIconStyle} />
                      </ListItemIcon>
                    </MenuItem>
                  }
                  {selectedBid.status === "DIRECT" && selectedBid.recipientId === userProfile.organization.id && // Принять отклонить прямую заявку
                    <MenuItem onClick={() => acceptDirectBid()} style={{ color: "black" }}>
                      Удовлетворить
                      <ListItemIcon style={{ color: "black" }}>
                        <DoneAllIcon fontSize="small" className={classes.menuIconStyle} />
                      </ListItemIcon>
                    </MenuItem>
                  }
                </Menu>
              </div>
            }
            <CssBaseline />
          </div>
        </Draggable>
      )
    }
    catch (er) {
      console.log("ERROR", er)
      return <div>Loading...</div>
    }
  }
  else return <div>Loading...</div>
}



