import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
// import Checkbox from '@material-ui/core/Checkbox';
// import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import Select from 'react-select'; // https://react-select.com/home
import Button from '@material-ui/core/Button';
// Icons
// import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { IoMdClose } from 'react-icons/io';
// Library
import 'react-toastify/dist/ReactToastify.css';
import Draggable from 'react-draggable';
import swal from 'sweetalert'; // https://sweetalert.js.org/guides/
import { v4 as uuidv4 } from 'uuid';
var fetch = require('node-fetch');

const useStyles = makeStyles((theme) => ({
  resizeForm: {
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    border: "1px solid #2B4D80",
    display: "flex",
    backgroundColor: "white",
    boxShadow: theme.shadows[1],
    "flex-flow": "column",
    width: "35%",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "150px",
    position: 'absolute',
  }
}))
function FloatFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      decimalSeparator={"."}
      thousandSeparator={" "}
      isNumericString
    />
  )
}
FloatFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
function IntegerFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={" "}
      isNumericString
    />
  );
}
IntegerFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
export default (props) => {
  const classes = useStyles()
  const [kseRESTApi] = useState(props.kseRESTApi) //Local KFB main REST
  const [token] = useState(props.token);
  const [userProfile] = useState(props.userProfile)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [enumData, setEnumData] = useState({})
  const [enumOptions, setEnumOptions] = useState({})
  const [selectedOptions, setSelectedOptions] = useState({})
  const [fieldValue, setFieldValue] = useState({ accountId: null })
  const [position, setPosition] = useState({ x: 0, y: 0 })


  useEffect(async () => {
    console.log("DIRECT ORDERS PROPS", props)
    let enumDataToCollect = [
      { enumName: "financeInstrumentId", enumDef: "3e819d7e-25d0-4a04-a3ff-092fd348a375" },
      { enumName: "accountId", enumDef: "c324d86f-3a3b-43b2-9514-d983b2982794" },
      { enumName: "recipientId", enumDef: "5b78d9dd-821d-4c6a-a00a-3af85224fbc4" },
      { enumName: "senderId", enumDef: "5b78d9dd-821d-4c6a-a00a-3af85224fbc4" },
    ]
    let enums = await props.getEnumDataByList(enumDataToCollect)
    setEnumData(enums)
    setEnumOptions(await props.createEnumOptions(enums))
    // console.log("DORs ENUMS", await props.createEnumOptions(enums))
    props.setComponentIndex(props.getComponentIndex() + 1)
    setFieldValue(props.orderDetail)
    let opts = await props.createEnumOptions(enums)
    showEnumOption(props.orderDetail, opts)
  }, [])
  // random UUID generator
  function getUUID() {
    return uuidv4()
  }
  function showEnumOption(orderDetail, opts) {
    let newSelOpts = {}
    let list = {
      financeInstrumentId: orderDetail.financeInstrumentId,
      recipientId: orderDetail.recipientId,
      senderId: orderDetail.senderId
    }
    for (let key in list) {
      for (let d = 0; d < opts[key].length; d++) {
        if (opts[key][d].value === list[key]) {
          newSelOpts[key] = opts[key][d]
        }
      }
    }
    setSelectedOptions(newSelOpts)
    console.log("List", list, "Opts", opts)
  }
  function swAllert(text, icon) {
    return (
      swal({
        text: text,
        icon: icon,
        buttons: { ok: "Ок" },
      })
    )
  }
  function acceptDirectOrder() {
    if (fieldValue.accountId !== null && fieldValue.accountId) {
      swal({
        text: "Подтвердить данную завку?",
        icon: "warning",
        buttons: { yes: "Да", cancel: "Отмена" },
      })
        .then(async (click) => {
          if (click === "yes") {
            let body = {
              "requestId": fieldValue.bidId,
              "accountId": fieldValue.accountId,
              "accepted": true
            }
            console.log("BODY", body)
            await fetch(
              kseRESTApi + "/api/Trading/AcceptDirectRequestBid",
              {
                "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
                "method": "POST",
                "body": JSON.stringify(body)
              }
            )
              .then(response => response.json())
              .then(async function (res) {
                console.log("ACCEPT", res)
                if (res.isSuccess === true) {
                  props.callSuccessToast("Заявка подтверждена")
                  props.closeDirectOrderDetail(props.orderDetail.uuid)
                  props.setUpdateBidsBody(getUUID())
                  props.setUpdateDealsBody(getUUID())
                  props.setUpdateAccountsInstrumentPositions(getUUID())
                  // Close form
                }
                else {
                  props.callErrorToast(res.errors[0])
                }
              })
          }
        })
    }
    else {
      swAllert("Укажите счет", "warning")
    }
  }
  function rejectDirectOrder() {
    swal({
      text: "Отклонить данную завку?",
      icon: "warning",
      buttons: { yes: "Да", cancel: "Отмена" },
    })
      .then(async (click) => {
        if (click === "yes") {
          let body = {
            "requestId": fieldValue.bidId,
            "accountId": null,
            "accepted": false
          }
          console.log("BODY", body)
          await fetch(
            kseRESTApi + "/api/Trading/AcceptDirectRequestBid",
            {
              "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
              "method": "POST",
              "body": JSON.stringify(body)
            }
          )
            .then(response => response.json())
            .then(async function (res) {
              console.log("ACCEPT", res)
              if (res.isSuccess === true) {
                props.callSuccessToast("Заявка отклонена")
                props.closeDirectOrderDetail(props.orderDetail.uuid)
                props.setUpdateBidsBody(getUUID())
                // Close form
              }
              else {
                props.callErrorToast(res.errors[0])
              }
            })
        }
      })
  }

  function handleSelectChange(option) {
    setSelectedOptions({ ...selectedOptions, [option.name]: option })
    setFieldValue({ ...fieldValue, [option.name]: option.value })
    // console.log("OPT", option, fieldValue)
  }
  function getModalStyle() {
    const top = 15;
    const left = 25;
    return {
      top: `${top}%`,
      left: `${left}%`,
      height: "400px",
      transform: `translate(-${top}%, -${left}%)`,
      zIndex: componentIndex
    };
  }
  function formClick() {
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 1)
    props.setComponentIndex(currIndex + 1)
  }
  function positionHandler(e, data) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 20 // Take 20% of width
    let maxDeviationX = (dimension.w / 100) * 70 // Take 70% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    // console.log("minX", minDeviationX, "maxX", maxDeviationX, "maxY", maxDeviationY)
    if (data.y < maxDeviationY) {
      // console.log("SET MIN Y", data.x)
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      // console.log("SET MIN X", data.x, minDeviationX)
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      // console.log("SET MAX X", data.x)
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
  }
  return (
    // <Draggable
    //   handle="p"
    //   onStart={() => formClick()}
    //   onMouseDown={() => formClick()}
    // >
    //   <div style={getModalStyle()} className={classes.resizeForm}>
    <Draggable
      handle="p"
      onStart={() => formClick()}
      onStop={positionHandler}
      position={position}
    >
      <div
        className={classes.resizeForm}
        style={{ top: "15%", left: "20%", transform: "translate(-15%, -20%)", zIndex: componentIndex }}
        onClick={() => formClick()}
      >
        <p className="main-table-label-p-style-new">
          <table className="dragble-div-header-table-style-new">
            <tr>
              <td width="99%" className="dragble-div-header-td-style-new">Прямая сделка</td>
              <td>
                <IoMdClose size={22} className="form-close-icons-style" style={{ marginRight: "5px" }} onClick={() => props.closeDirectOrderDetail(props.orderDetail.uuid)} />
              </td>
              {/* <td onClick={()=> props.closeDirectOrderDetail(props.orderDetail.uuid)}><IconButton size="small"><CloseIcon/></IconButton></td> */}
            </tr>
          </table>
        </p>
        <div className="dragble-div-body-style">
          <table align="center" width="100%">
            <div className="box no-cursor">
              <table align="center" width="100%">
                <tr>
                  <td width="30%" style={{ fontSize: "15px", fontFamily: "Courier New", fontWeight: "bold" }}>Инструмент</td>
                  <td width="70%" height="10%">
                    <Select
                      name={"financeInstrumentId"}
                      placeholder={"Выбрать..."}
                      value={selectedOptions["financeInstrumentId"]}
                      options={enumOptions["financeInstrumentId"]}
                      isDisabled={true}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                  </td>
                </tr>
                <tr>
                  <td width="30%" style={{ fontSize: "15px", fontFamily: "Courier New", fontWeight: "bold" }}>Цена</td>
                  <td width="70%" height="10%">
                    <TextField
                      variant="outlined"
                      placeholder="0.0"
                      size="small"
                      name="price"
                      value={fieldValue.price}
                      // onBlur={handleFloatChange}
                      style={{ width: "100%" }}
                      InputProps={{ inputComponent: FloatFormat }}
                      disabled
                    />
                    {/* <input
                        type='number'
                        style={{width: "97%"}}
                        variant="outlined"
                        value={fieldValue.price}
                        disabled={true}
                      />  */}
                  </td>
                </tr>
                <tr>
                  <td width="30%" style={{ fontSize: "15px", fontFamily: "Courier New", fontWeight: "bold" }}>Количество</td>
                  <td width="70%" height="10%">
                    <TextField
                      variant="outlined"
                      placeholder="0.0"
                      size="small"
                      name="amount"
                      value={fieldValue.amount}
                      // onBlur={handleFloatChange}
                      style={{ width: "100%" }}
                      InputProps={{ inputComponent: FloatFormat }}
                      disabled
                    />
                    {/* <input
                        type='number'
                        style={{width: "97%"}}
                        variant="outlined"
                        value={fieldValue.amount}
                        disabled={true}
                      />  */}
                  </td>
                </tr>
                <tr>
                  <td width="30%" style={{ fontSize: "15px", fontFamily: "Courier New", fontWeight: "bold" }}>B/S</td>
                  <td width="70%" height="10%">
                    <TextField
                      variant="outlined"
                      placeholder="0.0"
                      size="small"
                      // name="price"
                      value={fieldValue.bidDirection === 0 ? "Покупка" : "Продажа"}
                      // onBlur={handleFloatChange}
                      style={{ width: "100%" }}
                      InputProps={{ inputComponent: FloatFormat }}
                      disabled
                    />
                    {/* <input
                        type='number'
                        style={{width: "97%"}}
                        variant="outlined"
                        value={fieldValue.bidDirection === 0 ? "Покупка" : "Продажа"}
                        disabled={true}
                      />  */}
                  </td>
                </tr>
                <tr>
                  <td width="30%" style={{ fontSize: "15px", fontFamily: "Courier New", fontWeight: "bold" }}>Отправитель</td>
                  <td width="70%" height="10%">
                    <Select
                      name={"senderId"}
                      placeholder={"Выбрать..."}
                      value={selectedOptions["senderId"]}
                      options={enumOptions["senderId"]}
                      isDisabled={true}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                  </td>
                </tr>
                <tr>
                  <td width="30%" style={{ fontSize: "15px", fontFamily: "Courier New", fontWeight: "bold" }}>Получатель</td>
                  <td width="70%" height="10%">
                    <Select
                      name={"recipientId"}
                      placeholder={"Выбрать..."}
                      value={selectedOptions["recipientId"]}
                      options={enumOptions["recipientId"]}
                      isDisabled={true}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                  </td>
                </tr>
                {fieldValue.recipientId === userProfile.organization.id && fieldValue.accepted === false && fieldValue.rejected === false &&
                  <tr>
                    <td width="30%" style={{ fontSize: "15px", fontFamily: "Courier New", fontWeight: "bold" }}>Счет</td>
                    <td width="70%" height="10%">
                      <Select
                        name={"accountId"}
                        placeholder={"Выбрать..."}
                        value={selectedOptions["accountId"]}
                        onChange={handleSelectChange}
                        options={enumOptions["accountId"]}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      />
                    </td>
                  </tr>
                }
              </table>
              {fieldValue.recipientId === userProfile.organization.id && fieldValue.accepted === false && fieldValue.rejected === false &&
                <table width="100%">
                  <tr>
                    <td width="100%" align="center">
                      <button
                        className="cancelButton"
                        onClick={() => rejectDirectOrder(false)}
                        style={{ marginRight: 20 }}
                      >
                        Отклонить
                      </button>
                      <button
                        className="approveButton"
                        style={{ marginRight: 20 }}
                        onClick={() => acceptDirectOrder(true)}
                      >
                        Подтвердить
                      </button>
                    </td>
                  </tr>
                </table>
                // <table align="center" width="100%">
                //   <tr>
                //     <td align="right">
                //       <Button
                //         variant="contained"
                //         onClick={() => acceptDirectOrder()}
                //         style={{
                //           margin: 3,
                //           height: 30,
                //           fontSize: 12,
                //           color: "white",
                //           backgroundColor: "#2862F4"
                //         }}
                //       >Подтвердить
                //       </Button>
                //     </td>
                //     <td align="left">
                //       <Button
                //         variant="contained"
                //         onClick={() => rejectDirectOrder()}
                //         style={{
                //           margin: 3,
                //           height: 30,
                //           fontSize: 12,
                //           color: "white",
                //           backgroundColor: "#E73639"
                //         }}
                //       >Отклонить
                //       </Button>
                //     </td>
                //   </tr>
                // </table>
              }
            </div>
          </table>
        </div>
      </div>
    </Draggable>
  )
}