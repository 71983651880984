import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
// import Tab from '@mui/material/Tab';
// Time
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import Checkbox from '@material-ui/core/Checkbox';
// Icons
import { IoMdClose } from 'react-icons/io';
// import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
// import CloseIcon from '@material-ui/icons/Close';
// import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// CUSTOM COMPONENTS
// import ConfigurationFile from "../configuration/ConfigurationFile.json";
// Library
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import Draggable from 'react-draggable';
// import { red } from "@material-ui/core/colors";
// import { padding } from "@mui/system";
import swal from 'sweetalert'; // https://sweetalert.js.org/guides/
var moment = require('moment');
var fetch = require('node-fetch');

const useStyles = makeStyles((theme) => ({
  resizeForm: {
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    border: "1px solid #2B4D80",
    display: "flex",
    // backgroundColor: "#F5F5F5",
    backgroundColor: "white",
    boxShadow: theme.shadows[1],
    "flex-flow": "column",
    width: "530px",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "150px",
    position: 'absolute',
  }
  // modal: {
  //   position: 'absolute',
  //   width: 550,
  //   backgroundColor:"#eceff1",
  //   borderRadius: "7px",
  //   border: '1px solid #78909c',
  //   boxShadow: theme.shadows[1],
  //   padding: 3,
  //   resize: "both",
  //   overflow: "auto",
  //   minWidth: "150px",
  //   minHeight: "150px",
  // }
}))
function FloatFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      decimalSeparator={"."}
      thousandSeparator={" "}
      isNumericString
    />
  )
}
FloatFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
function IntegerFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={" "}
      isNumericString
    />
  );
}
IntegerFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

//Home(props) - получаем переменные от родителя App.js 
export default (props) => {
  const classes = useStyles()
  const [token] = useState(props.token)
  const [kseRESTApi] = useState(props.kseRESTApi) //Local KFB main REST
  const [userProfile, setUserProfile] = useState(props.userProfile)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [enumData, setEnumData] = useState({})
  const [curPos, setCurPos] = useState([])

  const [enumOptions, setEnumOptions] = useState({})
  const [selectedOptions, setSelectedOptions] = useState({})
  const [bidType, setBidType] = useState("limited")
  const [tableKey, setTableKey] = useState(null)
  // FIELDS
  const [lots, setLots] = useState([])
  const [fieldValue, setFieldValue] = useState({
    tradingAccount: null,
    organizationId: userProfile.organization.id,
    financeInstrumentId: props.selectedInstrument.id !== null ? props.selectedInstrument.id : null,
    amountOfLots: 0,
    amountOfLotsStep: 1,
    priceForInstrument: null,
    priceForInstrumentStep: 1,
    amountOfInstrument: null,
    amountOfInstrumentStep: 1,
    priceMoreOrEqual: null,
    priceMoreOrEqualStep: 1,
    // setOrderByPrice: null,
    // setOrderByPriceStep: 1,
    fullRequired: false,
    atOnePrice: false,
    withdrawBalance: false,
    newPrice: 0,
    expiredAt: null
  })
  const [atOnePriceDisabled, setAtOnePriceDisabled] = useState(false)
  const [withdrawBalanceDisabled, setWithdrawBalanceDisabled] = useState(true)
  const [position, setPosition] = useState({ x: 0, y: 0 })

  useEffect(async () => {
    console.log("ORDER BUY PROPS", props)
    let enumDataToCollect = [
      { enumName: "financeInstrumentId", enumDef: "3e819d7e-25d0-4a04-a3ff-092fd348a375" },
      { enumName: "tradingAccount", enumDef: "c324d86f-3a3b-43b2-9514-d983b2982794" },
      { enumName: "organizationId", enumDef: "5b78d9dd-821d-4c6a-a00a-3af85224fbc4" },
    ]
    let enums = await props.getEnumDataByList(enumDataToCollect)
    // console.log("BUY ENUMS", enums)
    setEnumData(enums)
    let eOpts = await props.createEnumOptions(enums)
    setEnumOptions(eOpts)
    let sOpts = { ...selectedOptions }
    let n = "organizationId"
    for (let d = 0; d < enums[n].length; d++) {
      if (enums[n][d].id === fieldValue.organizationId) {
        sOpts[n] = { "value": enums[n][d].id, "label": enums[n][d].label, "name": n }
      }
    }
    if (props.selectedInstrument.id !== null) {
      let newFields = { ...fieldValue }
      let f = "financeInstrumentId"
      for (let d = 0; d < enums[f].length; d++) {
        if (enums[f][d].id === props.selectedInstrument.id) {
          sOpts[f] = { "value": enums[f][d].id, "label": enums[f][d].label, "name": f }
          newFields.priceForInstrument = await getBestPrice(props.selectedInstrument.id)
        }
      }
      setFieldValue(newFields)
    }

    if (props.orderBuyFields !== null) {
      let newFields = fieldValue
      for (let key in props.orderBuyFields) {
        if (key === "financeInstrumentId") {
          for (let d = 0; d < enums[key].length; d++) {
            if (enums[key][d].id === props.orderBuyFields.financeInstrumentId) {
              sOpts[key] = { "value": enums[key][d].id, "label": enums[key][d].label, "name": key }
            }
          }
        }
        newFields[key] = props.orderBuyFields[key]
      }
      setFieldValue(newFields)
      setTableKey(getUUID())
    }
    setSelectedOptions(sOpts)

    let currenciesPos = await props.fetchDocList("/api/Accounts/ViewCurrencies")
    console.log("CUR POS", currenciesPos)
    setCurPos(currenciesPos)

    props.setComponentIndex(props.getComponentIndex() + 1)
  }, [])
  async function getBestPrice(instrId) {
    let bestPrice = await fetch(
      kseRESTApi + "/api/Trading/GetBestPrice?financeInstrumentId=" + instrId + "&bidDirection=0",
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
        "method": "GET"
      }
    )
      .then(response => response.json())
      .then(function (res) {

        if (res.isSuccess === true) {
          // console.log("BEST PRICE", res)
          return res.data
        }
        else {
          props.callErrorToast(res.errors[0])
          return null
        }
      })
      .catch(function (error) {
        return null
      })
    // console.log("BEST PR", bestPrice)
    return bestPrice
  }
  function swAllert(text, icon) {
    return (
      swal({
        text: text,
        icon: icon,
        buttons: { ok: "Ок" },
      })
    )
  }
  // random UUID generator
  function getUUID() {
    return uuidv4()
  }
  // INPUT HANDLERS
  // SELECT
  async function handleSelectChange(option) {
    if (option.name === "financeInstrumentId") {
      let newFileds = { ...fieldValue }
      newFileds.financeInstrumentId = option.value
      let bPrice = await getBestPrice(option.value)
      newFileds.priceForInstrument = bPrice === null ? "" : bPrice
      setFieldValue(newFileds)
      // console.log("BEST PRICE", bPrice, newFileds)
    }
    else {
      setFieldValue({ ...fieldValue, [option.name]: option.value })
    }
    setSelectedOptions({ ...selectedOptions, [option.name]: option })
    console.log("OPT", option, fieldValue)
    setTableKey(getUUID())
  }
  // INT
  const handleIntChange = (event) => {
    // console.log("EVENT", event.target.name, event.target.value)
    if (event.target.value !== "") {
      let stringNum = event.target.value.toString().replace(/ /g, '')
      let int = parseInt(stringNum)
      setFieldValue({ ...fieldValue, [event.target.name]: int })
    }
    else {
      setFieldValue({ ...fieldValue, [event.target.name]: null })
    }
  }
  const handleFloatChange = (event) => {
    // console.log("FLOAT CHANGE", event.target.name, event.target.value)
    if (event.target.value !== "") {
      let val = event.target.value.replace(/ /g, '')
      let newFields = fieldValue
      newFields[event.target.name] = parseFloat(val)
      setFieldValue(newFields)
    }
    else {
      setFieldValue({ ...fieldValue, [event.target.name]: null })
    }
  }
  function handleDateTimeChange(time, name) {
    // console.log("TIME", time, name)
    setFieldValue({ ...fieldValue, [name]: time })
  }
  function handleCheckboxChange(event) {
    let name = event.target.name
    let value = event.target.checked
    if (name === "fullRequired") {
      let newFields = fieldValue
      newFields[name] = value
      if (value === true) {
        newFields["atOnePrice"] = false
        newFields["withdrawBalance"] = false
        setAtOnePriceDisabled(true)
        setWithdrawBalanceDisabled(true)
      }
      else {
        setAtOnePriceDisabled(false)
      }
      setFieldValue(newFields)
      // console.log("NEW FIELDS", newFields)
    }
    if (name === "atOnePrice") {
      let newFields = fieldValue
      newFields[name] = value
      if (value === true) {
        setWithdrawBalanceDisabled(false)
      }
      else {
        newFields["withdrawBalance"] = false
        setWithdrawBalanceDisabled(true)
      }
      setFieldValue(newFields)
      // console.log("NEW FIELDS", newFields)
    }
    else {
      setFieldValue({ ...fieldValue, [name]: event.target.checked })
    }
  }
  function addLots() {
    // console.log("LOTS AMOUNT", fieldValue["amountOfLots"], fieldValue["amountOfLotsStep"])
    let newAmount = fieldValue["amountOfLots"] + fieldValue["amountOfLotsStep"]
    // console.log("NEW AMOUNT", newAmount)
    setFieldValue({ ...fieldValue, ["amountOfLots"]: newAmount })
    let newLots = lots
    newLots.push({
      "price": fieldValue["priceForInstrument"],
      "amount": fieldValue["amountOfLotsStep"],
      "profit": 0,
      "volume": 0
    })
    // var removedItem = fruits.splice(pos, 1); // так можно удалить элемент
    // var last = fruits.pop(); // удалим Апельсин (из конца)
    // var first = fruits.shift(); // удалим Яблоко (из начала)
    // var newLength = fruits.unshift('Клубника') // добавляет в начало
  }
  function deleteLot(i) {
    // console.log("DELETE LOT", i, lots[i])
    let newLots = lots.slice()
    newLots.splice(i, 1) // delete element
    setLots(newLots)
    setFieldValue({ ...fieldValue, ["amountOfLots"]: newLots.length })
  }
  function add(name, step) {
    let newVal = fieldValue[name] + fieldValue[step]
    setFieldValue({ ...fieldValue, [name]: newVal })
    // console.log("NEW VAL", newVal)
    setTableKey(getUUID())
  }
  function reduce(name, step) {
    let newVal = fieldValue[name] - fieldValue[step]
    setFieldValue({ ...fieldValue, [name]: newVal })
    setTableKey(getUUID())
    // console.log("NEW Val", newVal)
  }
  async function sendOrder() {
    if (fieldValue.tradingAccount !== null) {
      if (fieldValue.financeInstrument !== null) {
        let expire = fieldValue.expiredAt !== null ? moment(fieldValue.expiredAt).format() : null
        let body = {
          "organizationId": fieldValue.organizationId,
          "financeInstrumentId": fieldValue.financeInstrumentId,
          "accountId": fieldValue.tradingAccount,
          "bidDirection": 0, // 0 Buy 1 Sell
          "bidType": bidType === "market" ? 0 : 1,
          "expiredAt": expire,
          "fullRequired": fieldValue.fullRequired,
          "atOnePrice": fieldValue.atOnePrice,
          "withdrawBalance": fieldValue.withdrawBalance,
          "lots": [{
            "price": fieldValue.priceForInstrument,
            "amount": fieldValue.amountOfInstrument,
            // "profit": 0,
            // "volume": 0
          }]//lots
        }
        // console.log("BODY", body)
        if (bidType === "market") {
          sendBid(body)
        }
        else {
          await fetch(
            kseRESTApi + "/api/Trading/CheckSpread?instrumentId=" + fieldValue.financeInstrumentId + "&price=" + fieldValue.priceForInstrument,
            {
              "headers": { "content-type": "application/json", "Authorization": "Bearer " + token }
            }
          )
            .then(response => response.json())
            .then(function (res) {
              // console.log("RES CHECK", res)
              if (res.isSuccess === true) {
                sendBid(body)
              }
              else {
                swal({
                  text: res.errors[0] + "." + "\nOK для подтверждения заявки, Отмена для редактирования!",
                  icon: "warning",
                  buttons: { ok: "Ок", cancel: "Отмена" }
                })
                  .then((click) => {
                    if (click === "ok") {
                      sendBid(body)
                    }
                  })
              }
            })
        }
      } else { swAllert("Введите инструмент!", "warning") }
    } else { swAllert("Введите торговый счёт!", "warning") }
  }
  async function sendBid(body) {
    await fetch(
      kseRESTApi + "/api/Trading/CreateBid",
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
        "method": "POST",
        "body": JSON.stringify(body)
      }
    )
      .then(response => response.json())
      .then(function (res) {
        console.log("RES CREATE", res)
        if (res.isSuccess === true) {
          props.callSuccessToast("Заявка создана", 1100)
          props.setShowOrderBuy(false)
          props.setUpdateBidsBody(getUUID())
          props.setUpdateDealsBody(getUUID())
          props.setUpdateAccountsInstrumentPositions(getUUID())
          props.setUpdateAccountsCurrenciesPositions(getUUID())
        }
        else {
          props.callErrorToast(res.errors[0])
        }
      })
  }
  function getModalStyle() {
    const top = 15;
    const left = 25;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      zIndex: componentIndex
    };
  }
  function formClick() {
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 1)
    props.setComponentIndex(currIndex + 1)
  }
  function getCurQuantity() {
    let q = 0
    if (fieldValue.tradingAccount !== null) {
      let splitedTradingAcc = selectedOptions.tradingAccount.label.split("-")
      let tradingAccType = splitedTradingAcc[1]
      // Logic: 3001 = 405, 3101 = 400, 3301 = 400
      for (let i = 0; i < curPos.length; i++) {
        if (curPos[i].organizationId === userProfile.organization.id) {
          let splitedCurPosAcc = curPos[i].accountNo.split("-")
          let curPosAccType = splitedCurPosAcc[1]
          if (tradingAccType === "3001") {
            if (curPosAccType === "405") {
              q = curPos[i].quantity
            }
          }
          else {
            if (curPosAccType === "400") {
              q = curPos[i].quantity
            }
          }
        }
      }
    }
    return q
  }
  function positionHandler(e, data) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 30 // Take 30% of width
    let maxDeviationX = (dimension.w / 100) * 60 // Take 60% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    // console.log("minX", minDeviationX, "maxX", maxDeviationX, "maxY", maxDeviationY)
    if (data.y < maxDeviationY) {
      // console.log("SET MIN Y", data.x)
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      // console.log("SET MIN X", data.x, minDeviationX)
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      // console.log("SET MAX X", data.x)
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
  }
  return (
    // <Draggable
    //   handle="p"
    //   onStart={() => formClick()}
    //   onMouseDown={() => formClick()}
    // >
    //   <div style={getModalStyle()} className={classes.resizeForm}>
    <Draggable
      handle="p"
      onStart={() => formClick()}
      onStop={positionHandler}
      position={position}
    >
      <div
        className={classes.resizeForm}
        style={{ top: "15%", left: "30%", transform: "translate(-15%, -30%)", zIndex: componentIndex }}
        onClick={() => formClick()}
        key={tableKey}
      >
        <p className="main-table-label-p-style-new">
          <table className="dragble-div-header-table-style-new">
            <tr>
              <td width="99%" className="dragble-div-header-td-style-new">Ввод заявки на ПОКУПКУ методом открытых торгов</td>
              <td>
                <IoMdClose size={22} className="form-close-icons-style" style={{ marginRight: "5px" }} onClick={() => props.setShowOrderBuy(false)} />
              </td>
            </tr>
          </table>
        </p>
        <div className="dragble-div-body-style-new">
          <table align="center" width="100%" >
            <tr>
              <td width="50%">
                <Select
                  label="LABEl"
                  name={"tradingAccount"}
                  placeholder={"Торговый счёт..."}
                  value={selectedOptions["tradingAccount"]}
                  onChange={handleSelectChange}
                  options={enumOptions["tradingAccount"]}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
              </td>
              <td width="50%" height="10%">
                <Select
                  name={"organizationId"}
                  placeholder={"Код клиента..."}
                  value={selectedOptions["organizationId"]}
                  onChange={handleSelectChange}
                  options={enumOptions["organizationId"]}
                  isDisabled={true}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
              </td>
            </tr>
          </table>
          <table width="100%">
            <tr>
              <td>
                <Select
                  name={"financeInstrumentId"}
                  placeholder={"Найти инструмент..."}
                  value={selectedOptions["financeInstrumentId"]}
                  onChange={handleSelectChange}
                  options={enumOptions["financeInstrumentId"]}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
              </td>
            </tr>
          </table>
          <table width="100%">
            <tr>
              <td align="center" className="dragble-div-body-td-text-style">
                ДОСТУПНЫЕ СРЕДСТВА: {<span style={{ color: (fieldValue.priceForInstrument * fieldValue.amountOfInstrument) <= getCurQuantity() ? "#418991" : "red" }}>{props.currencyBeautifier(getCurQuantity())} сом</span>}
              </td>
            </tr>
          </table>
          {/* Тип заявки */}
          <table align="center" width="100%">
            <tr>
              <td align="center">
                <Button
                  style={{
                    margin: 3,
                    height: 30,
                    fontSize: 12,
                    width: "47%",
                    color: bidType === "limited" ? "white" : "#595959",
                    borderColor: bidType === "limited" ? "#153236" : "#42A5AF",
                    borderRadius: "0%",
                    backgroundColor: "#42A5AF",
                    fontWeight: "bold",
                    fontFamily: "Helvetica"
                  }}
                  variant="outlined"
                  onClick={() => setBidType("limited")}
                >
                  Лимитированная
                </Button>
                <Button
                  style={{
                    margin: 3,
                    height: 30,
                    fontSize: 12,
                    width: "47%",
                    color: bidType === "market" ? "white" : "#595959",
                    borderColor: bidType === "market" ? "#153236" : "#42A5AF",
                    borderRadius: "0%",
                    backgroundColor: "#42A5AF",
                    fontWeight: "bold",
                    fontFamily: "Helvetica"
                  }}
                  variant="outlined"
                  onClick={() => setBidType("market")}
                >Рыночная
                </Button>
              </td>
            </tr>
          </table>
          {/* ЛИМИТИРОВАННАЯ */}
          {bidType === "limited" &&
            <div overflow="auto">
              <table align="center" width="100%">
                <tr>
                  <td className="dragble-div-body-td-text-style" style={{ width: 165 }}>Цена за инструмент</td>
                  <td style={{ width: 170 }}>
                    <div style={{ verticalAlign: "middle", height: "25px", cursor: "pointer", display: "flex", flexDirection: "row" }}>
                      <div>
                        <TextField
                          name="priceForInstrument"
                          placeholder="0.0"
                          defaultValue={fieldValue.priceForInstrument}
                          onBlur={handleFloatChange}
                          style={{ width: "150px" }}
                          InputProps={{ inputComponent: FloatFormat }}
                        />
                      </div>
                      <div style={{ paddingLeft: "5px" }}>
                        <AddIcon fontSize="medium" onClick={() => add("priceForInstrument", "priceForInstrumentStep")} />
                      </div>
                      <div style={{ paddingLeft: "10px" }}>
                        <RemoveIcon fontSize="medium" onClick={() => reduce("priceForInstrument", "priceForInstrumentStep")} />
                      </div>
                    </div>

                  </td>
                  <td className="dragble-div-body-td-text-style" style={{ paddingLeft: "15px" }}>Сумма: {parseFloat((fieldValue.priceForInstrument * fieldValue.amountOfInstrument).toFixed(2))}</td>
                </tr>
                <tr>
                  <td></td>
                  <td className="dragble-div-body-td-simple-text-style">шаг цены: {fieldValue["priceForInstrumentStep"]}</td>
                </tr>
                {/* Количество */}
                <tr>
                  <td className="dragble-div-body-td-text-style">Количество</td>
                  <td>
                    <TextField
                      name="amountOfInstrument"
                      placeholder="0"
                      value={fieldValue.amountOfInstrument}
                      onChange={handleIntChange}
                      style={{ width: "150px" }}
                      InputProps={{ inputComponent: IntegerFormat }}
                    />
                    <AddIcon fontSize="medium" className="add-icon-style" onClick={() => add("amountOfInstrument", "amountOfInstrumentStep")} />
                    <RemoveIcon fontSize="medium" className="reduce-icon-style" onClick={() => reduce("amountOfInstrument", "amountOfInstrumentStep")} />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td className="dragble-div-body-td-simple-text-style">шаг: {fieldValue["amountOfInstrumentStep"]}</td>
                </tr>
                {/* Время снятия */}
                <tr>
                  <td className="dragble-div-body-td-text-style">Время снятия</td>
                  <td>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        ampm={false}
                        openTo="hours"
                        views={['hours', 'minutes']}
                        inputFormat="HH:mm"
                        mask="__:__"
                        value={fieldValue.expiredAt}
                        onChange={(newValue) => {
                          setFieldValue({ ...fieldValue, ["expiredAt"]: newValue.$d })
                        }}
                        renderInput={(params) => <TextField {...params} size="small" style={{ width: "160px" }} />}
                      />
                    </LocalizationProvider>
                  </td>
                </tr>
              </table>
            </div>
          }
          {/* РЫНОЧНАЯ */}
          {bidType === "market" &&
            <div overflow="auto">
              <table align="center" width="100%">
                {/* Количество */}
                <tr>
                  <td className="dragble-div-body-td-text-style">Количество</td>
                  <td>
                    <TextField
                      name="amountOfInstrument"
                      placeholder="0"
                      value={fieldValue.amountOfInstrument}
                      onChange={handleIntChange}
                      style={{ width: "150px" }}
                      InputProps={{ inputComponent: IntegerFormat }}
                    />
                    <AddIcon fontSize="small" className="add-icon-style" onClick={() => add("amountOfInstrument", "amountOfInstrumentStep")} />
                    <RemoveIcon fontSize="small" className="reduce-icon-style" onClick={() => reduce("amountOfInstrument", "amountOfInstrumentStep")} />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td className="dragble-div-body-td-simple-text-style">шаг: {fieldValue["amountOfInstrumentStep"]}</td>
                </tr>
              </table>
              <table align="center" width="100%">
                <tr>
                  <td>
                    <Checkbox
                      size="small"
                      style={{ color: "#337E86" }}
                      name="fullRequired"
                      onChange={handleCheckboxChange}
                      checked={(fieldValue["fullRequired"] === false || fieldValue["fullRequired"] === null || fieldValue["fullRequired"] === undefined) ? false : true}
                    />
                  </td>
                  <td className="dragble-div-body-td-text-style" >Только весь объём</td>
                  <td>
                    <Checkbox
                      size="small"
                      style={{ color: atOnePriceDisabled === true ? "grey" : "#337E86" }}
                      name="atOnePrice"
                      onChange={handleCheckboxChange}
                      checked={(fieldValue["atOnePrice"] === false || fieldValue["atOnePrice"] === null || fieldValue["atOnePrice"] === undefined) ? false : true}
                      disabled={atOnePriceDisabled === true ? true : false}
                    />
                  </td>
                  <td className="dragble-div-body-td-text-style">По одной цене</td>

                  <td>
                    <Checkbox
                      size="small"
                      style={{ color: withdrawBalanceDisabled === true ? "grey" : "#337E86" }}
                      name="withdrawBalance"
                      onChange={handleCheckboxChange}
                      checked={(fieldValue["withdrawBalance"] === false || fieldValue["withdrawBalance"] === null || fieldValue["withdrawBalance"] === undefined) ? false : true}
                      disabled={withdrawBalanceDisabled === true ? true : false}
                    />
                  </td>
                  <td className="dragble-div-body-td-text-style">Снять остаток</td>

                </tr>
              </table>
            </div>
          }
          <table width="100%">
            <tr>
              <td width="100%" align="center">
                <button
                  className="cancelButton"
                  style={{ marginRight: 20 }}
                  onClick={() => props.setShowOrderBuy(false)}
                >
                  Отмена
                </button>
                <button
                  className="approveButton"
                  onClick={() => sendOrder()}
                  style={{ marginRight: 20 }}
                >
                  Отправить заявку
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </Draggable>
  )
}