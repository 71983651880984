import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
var moment = require('moment');

const useStyles = makeStyles((theme) => ({
  timeText: {
    fontFamily: 'Helvetica',
    fontSize: 15,
    fontWeight: "bold",
    // variant: 'body2',
    // paddingLeft: theme.spacing(1),
    color: "#676767",
    position: "related",
    paddingLeft: "20px",
    // paddingTop: "3px"
    // height: "19px"
    // padding: 0
  }
}));

export default function OnlineTime() {
  const classes = useStyles();
  // const [date, setDate] = useState("");
  // const [time, setTime] = useState("");
  const [dateTime, setDateTime] = useState("");
  setInterval(async function () {
    // setDate(moment(new Date()).format('MM.DD.YYYY'))
    // setTime(moment(new Date()).format('HH:mm:ss'))
    setDateTime(moment(new Date()).format('MM.DD.YYYY HH:mm:ss'))
  }, 1000)

  return (
    // <Grid
    //   container
    //   direction="column"
    //   // justifyContent="space-between"
    //   alignItems="center"
    //   style={{paddingTop: 2}}
    // >
    //  <Grid itemxs={12}>
    //   <div className={classes.timeText}>
    //     {date}
    //   </div>
    //  </Grid>
    //  <Grid itemxs={12}>
    //   <div className={classes.timeText}>
    //     {time}
    //   </div>
    //  </Grid>
    // </Grid>
    // <div className={classes.timeText}>
    //   <table>
    //   <tr>
    //     <td>{date}</td>
    //   </tr>
    //   <tr>
    //     <td>{time}</td>
    //   </tr></table>
    // </div>
    <div className={classes.timeText}>
      {dateTime}
    </div>
  )
}