import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
// Icons
import TextField from '@material-ui/core/TextField';
import { IoMdClose } from 'react-icons/io';
// Form components
import Draggable from 'react-draggable';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

//Style
import "../styles/generalStyles.css"
import { v4 as uuidv4 } from 'uuid';
var fetch = require('node-fetch');

function IntegerFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={" "}
      isNumericString
    />
  );
}
IntegerFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

const useStyles = makeStyles((theme) => ({
  resizeForm: {
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    border: "1px solid #2B4D80",
    display: "flex",
    backgroundColor: "white",
    boxShadow: theme.shadows[1],
    "flex-flow": "column",
    width: "450px",
    height: "250px",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "150px",
    position: 'absolute',
  },
}))

export default (props) => {
  const classes = useStyles()
  const [kseRESTApi] = useState(props.kseRESTApi)
  const [token] = useState(props.token)
  // const [userProfile] = useState(props.userProfile)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [enumData, setEnumData] = useState({})
  const [enumOptions, setEnumOptions] = useState({})
  const [selectedOptions, setSelectedOptions] = useState({})
  const [fieldValue, setFieldValue] = useState({
    accountId: null,
    quantity: null
  })
  const [position, setPosition] = useState({ x: 0, y: 0 })
  // Set data from props to state of component
  useEffect(async () => {
    console.log("CUR DEPOS PROPS", props)
    // let gForm = await props.fetchForm("79f4ab13-8221-422b-ba6a-2dec0bdfec14")
    // setForm(gForm)
    // let enums = await props.getEnumDataByForm(gForm)
    // setEnumData(enums)
    let enumDataToCollect = [
      { enumName: "financeInstrumentId", enumDef: "3e819d7e-25d0-4a04-a3ff-092fd348a375" },
      { enumName: "accountId", enumDef: "72318ba5-dce0-4845-95a8-ad18debceae0" },
    ]
    let enums = await props.getEnumDataByList(enumDataToCollect)
    // console.log("BUY ENUMS", enums)
    setEnumData(enums)
    console.log("CUR POS EN", enums)
    let eOpts = await props.createEnumOptions(enums)
    setEnumOptions(eOpts)
    props.setComponentIndex(props.getComponentIndex() + 1)
  }, [])
  // random UUID generator
  function getUUID() {
    return uuidv4()
  }

  // INPUT HANDLERS
  // SELECT
  function handleSelectChange(option) {
    setSelectedOptions({ ...selectedOptions, [option.name]: option })
    setFieldValue({ ...fieldValue, [option.name]: option.value })
    console.log("OPT", option, fieldValue)
  }
  // INT
  const handleIntChange = (event) => {
    // console.log("EVENT", event.target.name, event.target.value)
    if (event.target.value !== "") {
      let stringNum = event.target.value.toString().replace(/ /g, '')
      let int = parseInt(stringNum)
      setFieldValue({ ...fieldValue, [event.target.name]: int })
    }
    else {
      setFieldValue({ ...fieldValue, [event.target.name]: event.target.value })
    }
  }

  function getModalStyle() {
    const top = 25;
    const left = 30;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      zIndex: componentIndex
    };
  }
  function formClick() {
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 1)
    props.setComponentIndex(currIndex + 1)
  }
  async function replenish() {
    let body = {
      "accountId": fieldValue.accountId,
      // "organizationId": selectedItem.organizationId,
      "financeInstrumentId": fieldValue.financeInstrumentId,
      "quantity": fieldValue.quantity
    }
    await fetch(
      kseRESTApi + "/api/Accounts/ReplenishInstruments",
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
        "method": "POST",
        "body": JSON.stringify(body)
      }
    )
      .then(response => response.json())
      .then(async function (res) {
        // console.log("RES REPL", res)
        if (res.isSuccess === true) {
          props.callSuccessToast("Депонирование завершено!")
          props.setShowAccountsInstrumentsDepositing(false)
          props.setUpdateAccountsInstrumentPositions(getUUID())
        }
        else {
          props.callErrorToast(res.errors[0])
        }
      })
  }
  function positionHandler(e, data) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 30 // Take 30% of width
    let maxDeviationX = (dimension.w / 100) * 60 // Take 60% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    // console.log("minX", minDeviationX, "maxX", maxDeviationX, "maxY", maxDeviationY)
    if (data.y < maxDeviationY) {
      // console.log("SET MIN Y", data.x)
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      // console.log("SET MIN X", data.x, minDeviationX)
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      // console.log("SET MAX X", data.x)
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
  }
  return (
    // <Draggable
    //   handle="p"
    //   onStart={() => formClick()}
    //   onMouseDown={() => formClick()}
    // >
    //   <div style={getModalStyle()} className={classes.resizeForm}>
    <Draggable
      handle="p"
      onStart={() => formClick()}
      onStop={positionHandler}
      position={position}
    >
      <div
        className={classes.resizeForm}
        style={{ top: "15%", left: "30%", transform: "translate(-15%, -30%)", zIndex: componentIndex }}
        onClick={() => formClick()}
      >
        <p className="main-table-label-p-style-new">
          <table className="dragble-div-header-table-stylenew">
            <tr>
              <td width="99%" className="dragble-div-header-td-style-new">Депонирование ЦБ</td>
              <td>
                <IoMdClose size={22} className="form-close-icons-style" style={{ marginRight: "5px" }} onClick={() => props.setShowAccountsInstrumentsDepositing(false)} />
              </td>
            </tr>
          </table>
        </p>
        <div className="dragble-div-body-style-new">
          <table width="100%">
            <tr>
              <td width="100%">
                <Select
                  name={"financeInstrumentId"}
                  placeholder={"Найти инструмент..."}
                  value={selectedOptions["financeInstrumentId"]}
                  onChange={handleSelectChange}
                  options={enumOptions["financeInstrumentId"]}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
              </td>
            </tr>
          </table>
          <table width="100%">
            <tr>
              <td width="100%">
                <Select
                  name={"accountId"}
                  placeholder={"Найти счет..."}
                  value={selectedOptions["accountId"]}
                  onChange={handleSelectChange}
                  options={enumOptions["accountId"]}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
              </td>
            </tr>
          </table>
          <table width="100%">
            <tr>
              <td className="dragble-div-body-td-text-style-new" width="30%">Количество</td>
              <td width="70%">
                <TextField
                  name="quantity"
                  placeholder="0"
                  value={fieldValue.quantity}
                  onChange={handleIntChange}
                  style={{ width: "150px" }}
                  InputProps={{ inputComponent: IntegerFormat }}
                />
              </td>
            </tr>
          </table>
          <table width="100%" style={{ paddingTop: 30 }}>
            <tr>
              <td width="50%" align="center">
                <button
                  className="cancelButton"
                  style={{ marginRight: 20 }}
                  onClick={() => props.setShowAccountsCurrenciesDepositing(false)}
                >
                  Отмена
                </button>
              </td>
              <td width="50%" align="right">
                <button
                  className="approveButton"
                  onClick={() => replenish()}
                  style={{ marginRight: 20 }}
                >
                  Депонировать
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </Draggable >
  )
}

