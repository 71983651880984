import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
// Radio Group
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

// Icons
import { IoMdClose } from 'react-icons/io';
// Library
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import Draggable from 'react-draggable';
import swal from 'sweetalert'; // https://sweetalert.js.org/guides/
var moment = require('moment');
var fetch = require('node-fetch');

const useStyles = makeStyles((theme) => ({
  // resizeForm: {
  //   borderRadius: "7px",
  //   border: "1px solid #2B4D80",
  //   display: "flex",
  //   backgroundColor: "#F5F5F5",
  //   boxShadow: theme.shadows[1],
  //   "flex-flow": "column",
  //   width: "60%",
  //   resize: "both",
  //   overflow: "hidden",
  //   minWidth: "150px",
  //   minHeight: "150px",
  //   position: 'absolute',
  // },
  resizeForm: {
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    border: "1px solid #2B4D80",
    display: "flex",
    backgroundColor: "white",
    boxShadow: theme.shadows[1],
    "flex-flow": "column",
    width: "60%",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "150px",
    position: 'absolute',
  }
}))
function FloatFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      decimalSeparator={"."}
      thousandSeparator={" "}
      isNumericString
    />
  )
}
FloatFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
function IntegerFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={" "}
      isNumericString
    />
  );
}
IntegerFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

//Home(props) - получаем переменные от родителя App.js 
export default (props) => {
  const classes = useStyles()
  const [token] = useState(props.token)
  const [kseRESTApi] = useState(props.kseRESTApi) //Local KFB main REST
  const [userProfile, setUserProfile] = useState(props.userProfile)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [enumData, setEnumData] = useState({})
  const [enumOptions, setEnumOptions] = useState({})
  const [selectedOptions, setSelectedOptions] = useState({})
  const [bidType, setBidType] = useState("limited")
  const [tableKey, setTableKey] = useState(null)
  // FIELDS
  const [fieldValue, setFieldValue] = useState({
    accountId: null,
    organizationId: userProfile.organization.id,
    recipientOrganizationId: null,
    financeInstrumentId: props.selectedInstrument.id !== null ? props.selectedInstrument.id : null,
    currency: 1,
    bidDirection: 0,
    bidDirectionClose: 1,
    openPrice: null,
    amount: null,
    closeDate: null,
    closePrice: null,
    profit: 0
  })
  const [updateState, setUpdateState] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  useEffect(async () => {
    console.log("REPO ORDER PROPS", props)
    let enumDataToCollect = [
      { enumName: "financeInstrumentId", enumDef: "3e819d7e-25d0-4a04-a3ff-092fd348a375" },
      { enumName: "accountId", enumDef: "c324d86f-3a3b-43b2-9514-d983b2982794" },
      { enumName: "organizationId", enumDef: "5b78d9dd-821d-4c6a-a00a-3af85224fbc4" },
      { enumName: "recipientOrganizationId", enumDef: "5b78d9dd-821d-4c6a-a00a-3af85224fbc4" },
    ]
    let enums = await props.getEnumDataByList(enumDataToCollect)
    // console.log("BUY ENUMS", enums)
    setEnumData(enums)
    let eOpts = await props.createEnumOptions(enums)
    setEnumOptions(eOpts)
    let sOpts = { ...selectedOptions }
    if (props.selectedInstrument.id !== null) {
      let f = "financeInstrumentId"
      for (let d = 0; d < enums[f].length; d++) {
        if (enums[f][d].id === props.selectedInstrument.id) {
          sOpts[f] = { "value": enums[f][d].id, "label": enums[f][d].label, "name": f }
        }
      }
    }
    setSelectedOptions(sOpts)
    props.setComponentIndex(props.getComponentIndex() + 1)
  }, [])
  function swAllert(text, icon) {
    return (
      swal({
        text: text,
        icon: icon,
        buttons: { ok: "Ок" },
      })
    )
  }
  // random UUID generator
  function getUUID() {
    return uuidv4()
  }
  // INPUT HANDLERS
  // SELECT
  function handleSelectChange(option) {
    setSelectedOptions({ ...selectedOptions, [option.name]: option })
    setFieldValue({ ...fieldValue, [option.name]: option.value })
    console.log("OPT", option, fieldValue)
  }
  // INT
  const handleIntChange = (event) => {
    // console.log("EVENT", event.target.name, event.target.value)
    if (event.target.value !== "") {
      let stringNum = event.target.value.toString().replace(/ /g, '')
      let int = parseInt(stringNum)
      setFieldValue({ ...fieldValue, [event.target.name]: int })
    }
    else {
      setFieldValue({ ...fieldValue, [event.target.name]: null })
    }
  }
  function handleDateTimeChange(event) {
    let name = event.target.name
    let value = event.target.value
    let date = moment(value).format()
    // console.log("DATE CHANGE", date)
    if (name === "closeDate") {
      let newFields = fieldValue
      newFields[name] = date
      newFields.profit = calculateProfit(date)
      setFieldValue(newFields)
    }
    else {
      setFieldValue({ ...fieldValue, [name]: date })
    }
    setTableKey(getUUID())
  }
  function calculateProfit(closeDate) {
    let year = moment(new Date()).format("YYYY")
    let daysInYear = ((year % 4 === 0 && year % 100 > 0) || year % 400 == 0) ? 366 : 365
    // console.log("daysInYear", daysInYear)

    let openCloseDateDifference = dateDifference(new Date(), closeDate)
    // console.log("DIFF", openCloseDateDifference)
    // console.log("calculateProfit", fieldValue)
    let p = ((fieldValue.closePrice / fieldValue.openPrice) * (daysInYear * 100) - (daysInYear * 100)) / openCloseDateDifference
    // console.log("PR", p)
    return p
  }
  function dateDifference(open, close) {
    let d1 = moment(open).format("MM/DD/YYYY")
    let d2 = moment(close).format("MM/DD/YYYY")
    var date1 = new Date(d1) // "06/30/2019"
    var date2 = new Date(d2) // "07/30/2019"
    // To calculate the time difference of two dates
    var differenceInTime = date2.getTime() - date1.getTime()
    // To calculate the no. of days between two dates
    var differenceInDays = differenceInTime / (1000 * 3600 * 24)
    return differenceInDays
  }
  function handleRadionGroupChange(event) {
    // console.log("R CH", event.target.name, event.target.value)
    let name = event.target.name
    let value = parseInt(event.target.value)
    if (name === "bidDirection") {

      let newFields = { ...fieldValue }
      newFields["bidDirection"] = value
      newFields["bidDirectionClose"] = value === 1 ? 0 : 1
      setFieldValue(newFields)
    }
    else {
      setFieldValue({ ...fieldValue, [name]: value })
    }
    setUpdateState(getUUID())
  }

  // Float input handler
  const handleFloatChange = (event) => {
    if (event.target.value !== "") {
      let stringNum = event.target.value.toString().replace(/ /g, '')
      let float = parseFloat(stringNum)
      let newFields = fieldValue
      newFields[event.target.name] = float
      // console.log("FLOAT CHANGE", event.target.name, float)
      if (fieldValue.closeDate !== null) {
        newFields.profit = calculateProfit(fieldValue.closeDate)
      }
      setFieldValue(newFields)
      setTableKey(getUUID())
      // console.log("FLOAT CHANGE", event.target.name, event.target.value, fieldValue)
    }
    else {
      setFieldValue({ ...fieldValue, [event.target.name]: event.target.value })
    }    
  }
  async function sendRepoOrder() {
    if (fieldValue.accountId !== null) {
      if (fieldValue.financeInstrument !== null) {
        if (fieldValue.closeDate !== null) {
          let body = {
            "organizationId": fieldValue.organizationId,
            "recipientOrganizationId": fieldValue.recipientOrganizationId,
            "financeInstrumentId": fieldValue.financeInstrumentId,
            "accountId": fieldValue.accountId,
            "bidDirection": fieldValue.bidDirection, // 0 Buy 1 Sell
            "openPrice": fieldValue.openPrice,
            "amount": fieldValue.amount,
            "closeDate": fieldValue.closeDate,
            "closePrice": fieldValue.closePrice
          }
          console.log("BODY", body)
          await fetch(
            kseRESTApi + "/api/Trading/CreateRepoRequest",
            {
              "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
              "method": "POST",
              "body": JSON.stringify(body)
            }
          )
            .then(response => response.json())
            .then(function (res) {
              console.log("RES CREATE", res)
              if (res.isSuccess === true) {
                props.callSuccessToast("Заявка создана", 1100)
                props.setShowRepoOrder(false)
                props.setUpdateBidsBody(getUUID())
              }
              else {
                props.callErrorToast(res.errors[0])
              }
            })
        } else { props.callErrorToast("Введите дату закрытия!", "warning") }
      } else { props.callErrorToast("Введите инструмент!", "warning") }
    } else { props.callErrorToast("Введите торговый счёт!", "warning") }
  }
  function getModalStyle() {
    const top = 15;
    const left = 25;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      zIndex: componentIndex
    };
  }
  function formClick() {
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 1)
    props.setComponentIndex(currIndex + 1)
  }
  function positionHandler(e, data) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 10 // Take 10% of width
    let maxDeviationX = (dimension.w / 100) * 80 // Take 80% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    // console.log("minX", minDeviationX, "maxX", maxDeviationX, "maxY", maxDeviationY)
    if (data.y < maxDeviationY) {
      // console.log("SET MIN Y", data.x)
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      // console.log("SET MIN X", data.x, minDeviationX)
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      // console.log("SET MAX X", data.x)
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
  }
  return (
    // <Draggable
    //   handle="p"
    //   onStart={() => formClick()}
    //   onMouseDown={() => formClick()}
    // >
    //   <div style={getModalStyle()} className={classes.resizeForm}>
    <Draggable
      handle="p"
      onStart={() => formClick()}
      onStop={positionHandler}
      position={position}
    >
      <div
        className={classes.resizeForm}
        style={{ top: "15%", left: "10%", transform: "translate(-15%, -10%)", zIndex: componentIndex }}
        onClick={() => formClick()}
      >
        <p className="main-table-label-p-style-new">
          <table className="dragble-div-header-table-style-new">
            <tr>
              <td width="99%" className="dragble-div-header-td-style-new">Ввод REPO заявки</td>
              <td>
                <IoMdClose size={22} className="form-close-icons-style" style={{ marginRight: "5px" }} onClick={() => props.setShowRepoOrder(false)} />
              </td>
            </tr>
          </table>
        </p>
        <div className="dragble-div-body-style">
          <th>Параметры открытия:</th>
          <table align="center" width="100%" key={tableKey}>
            <tr>
              <td width="15%" className="dragble-div-body-td-text-style">Торговый счёт</td>
              <td width="35%">
                <Select
                  name={"accountId"}
                  placeholder={"Выбрать..."}
                  value={selectedOptions["accountId"]}
                  onChange={handleSelectChange}
                  options={enumOptions["accountId"]}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
              </td>
              <td width="15%" className="dragble-div-body-td-text-style">Контрагент</td>
              <td width="35%" height="10%">
                <Select
                  name={"recipientOrganizationId"}
                  placeholder={"Выбрать..."}
                  value={selectedOptions["recipientOrganizationId"]}
                  onChange={handleSelectChange}
                  options={enumOptions["recipientOrganizationId"]}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
              </td>
            </tr>
          </table>
          <table width="100%">
            <tr>
              <td>
                <Select
                  name={"financeInstrumentId"}
                  placeholder={"Найти инструмент..."}
                  value={selectedOptions["financeInstrumentId"]}
                  onChange={handleSelectChange}
                  options={enumOptions["financeInstrumentId"]}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
              </td>
            </tr>
          </table>
          <div overflow="auto">
            <table>
              {/* Открытие */}
              <tr>
                <td width="70%">
                  <table align="center" width="100%" style={{ border: "1px solid grey" }}>
                    <tr>
                      <td className="dragble-div-body-td-text-style" style={{ border: "1px solid grey" }}>
                        <FormControl component="fieldset" key={updateState + "bidDirection"}>
                          <FormLabel component="legend">Направл.</FormLabel>
                          <RadioGroup
                            name="bidDirection"
                            value={fieldValue["bidDirection"]}
                            onChange={handleRadionGroupChange}
                          >
                            <FormControlLabel style={{ marginTop: -8 }} value={0} control={<Radio />} label="Пок." />
                            <FormControlLabel style={{ marginTop: -20 }} value={1} control={<Radio />} label="Прод." />
                          </RadioGroup>
                        </FormControl>
                      </td>
                      <td>
                        <tr className="properties-td-text-style">Цена откр.</tr>
                        <tr>
                          <TextField
                            variant="outlined"
                            placeholder="0.0"
                            size="small"
                            name="openPrice"
                            value={fieldValue.openPrice}
                            onChange={handleFloatChange}
                            style={{ width: "100%" }}
                            InputProps={{ inputComponent: FloatFormat }}
                          />
                          {/* <input
                            type='number'
                            step="0.0001"
                            style={{ width: "100%" }}
                            defaultValue={fieldValue.openPrice}
                            onBlur={handleFloatChange}
                            name="openPrice"
                          /> */}
                        </tr>
                      </td>
                      <td>
                        <tr className="properties-td-text-style">Количество</tr>
                        <tr>
                          <TextField
                            variant="outlined"
                            placeholder="0.0"
                            size="small"
                            name="amount"
                            // defaultValue={fieldValue.amount}
                            // onBlur={handleFloatChange}
                            value={fieldValue.amount}
                            onChange={handleFloatChange}
                            // value={fieldValue[contentItem.name]}
                            style={{ width: "100%" }}
                            InputProps={{ inputComponent: FloatFormat }}
                          />
                          {/* <input
                            type='number'
                            step="1"
                            style={{ width: "100%" }}
                            defaultValue={fieldValue.amount}
                            onBlur={handleFloatChange}
                            name="amount"
                          /> */}
                        </tr>
                      </td>
                      <td>
                        <tr className="properties-td-text-style">Объем</tr>
                        <tr>
                          <TextField
                            variant="outlined"
                            size="small"
                            value={(parseFloat(fieldValue.amount) * parseFloat(fieldValue.openPrice)).toFixed(4)}
                            style={{ width: "100%" }}
                            InputProps={{ inputComponent: FloatFormat }}
                            disabled
                          />
                          {/* <input
                            type='number'
                            style={{ width: "100%" }}
                            value={(fieldValue.amount * fieldValue.openPrice).toFixed(4)}
                            disabled
                          /> */}
                        </tr>
                      </td>
                    </tr>
                  </table>
                </td>
                <td width="30%">
                  <table align="center" width="100%" style={{ border: "1px solid grey" }}>
                    <tr>
                      <td className="dragble-div-body-td-text-style">
                        <FormControl component="fieldset" key={updateState + "priceForOpenSession"}>
                          <FormLabel component="legend">Валюты.</FormLabel>
                          <RadioGroup
                            name="currency"
                            value={fieldValue["currency"]}
                            onChange={handleRadionGroupChange}
                          >
                            <FormControlLabel disabled style={{ marginTop: -8 }} value={1} control={<Radio />} label="Сомы" />
                            <FormControlLabel disabled style={{ marginTop: -20 }} value={2} control={<Radio />} label="Валюта торгов" />
                          </RadioGroup>
                        </FormControl>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            {/* Закрытие */}
            <table align="center" width="100%" style={{ border: "1px solid grey" }}>
              <tr>
                <td className="dragble-div-body-td-text-style" style={{ border: "1px solid grey" }}>
                  <FormControl component="fieldset" key={updateState + "bidDirectionClose"}>
                    <FormLabel component="legend">Направл.</FormLabel>
                    <RadioGroup
                      name="bidDirectionClose"
                      value={fieldValue["bidDirectionClose"]}
                      onChange={handleRadionGroupChange}
                    >
                      <FormControlLabel disabled style={{ marginTop: -8 }} value={0} control={<Radio />} label="Пок." />
                      <FormControlLabel disabled style={{ marginTop: -20 }} value={1} control={<Radio />} label="Прод." />
                    </RadioGroup>
                  </FormControl>
                </td>
                <td>
                  <tr className="properties-td-text-style">Цена закр.</tr>
                  <tr>
                    <TextField
                      variant="outlined"
                      placeholder="0.0"
                      size="small"
                      name="closePrice"
                      // defaultValue={fieldValue.closePrice}
                      // onBlur={handleFloatChange}
                      value={fieldValue.closePrice}
                      onChange={handleFloatChange}
                      style={{ width: "100%" }}
                      InputProps={{ inputComponent: FloatFormat }}
                    />
                    {/* <input
                      type='number'
                      step="0.0001"
                      style={{ width: "100%" }}
                      defaultValue={fieldValue.closePrice}
                      onBlur={handleFloatChange}
                      name="closePrice"
                    /> */}
                  </tr>
                </td>
                <td>
                  <tr className="properties-td-text-style">Доходность репо</tr>
                  <tr>
                    <TextField
                      variant="outlined"
                      placeholder="0.0000"
                      size="small"
                      value={(fieldValue.profit).toFixed(4)}
                      style={{ width: "100%" }}
                      disabled
                    />
                    {/* <input
                      type='number'
                      // step="0.0001"
                      style={{ width: "100%" }}
                      value={(fieldValue.profit).toFixed(4)}
                      // onBlur={handleFloatChange}
                      name="profit"
                      disabled
                    /> */}
                  </tr>
                </td>
                <td>
                  <tr className="properties-td-text-style">Объем закр.</tr>
                  <tr>
                    <TextField
                      variant="outlined"
                      placeholder="0.0000"
                      size="small"
                      value={Math.abs(parseFloat(fieldValue.closePrice) * parseFloat(fieldValue.amount)).toFixed(4)}
                      style={{ width: "100%" }}
                      disabled
                    />
                    {/* <input
                      type='number'
                      // step="0.0001"
                      style={{ width: "100%" }}
                      value={Math.abs(fieldValue.closePrice * fieldValue.amount).toFixed(4)}
                      // onBlur={handleFloatChange}
                      name="closeAmount"
                      disabled
                    /> */}
                  </tr>
                </td>
                <td>
                  <tr className="properties-td-text-style">Дата закр.</tr>
                  <tr>
                    <td>
                      <TextField
                        variant="outlined"
                        size="small"
                        type="date"
                        name="closeDate"
                        onChange={handleDateTimeChange}
                        defaultValue={(fieldValue["closeDate"] !== undefined) ? moment(fieldValue["closeDate"]).format('YYYY-MM-DD') : ""}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </td>
                  </tr>
                </td>
              </tr>
            </table>
          </div>
          <table width="100%">
            <tr>
              <td width="100%" align="center">
                <button
                  className="cancelButton"
                  style={{ marginRight: 20 }}
                  onClick={() => props.setShowRepoOrder(false)}
                >
                  Отмена
                </button>
                <button
                  className="approveButton"
                  onClick={() => sendRepoOrder()}
                  style={{ marginRight: 20 }}
                >
                  Отправить заявку
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </Draggable>
  )
}