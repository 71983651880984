import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';

// import Checkbox from '@material-ui/core/Checkbox';
// Icons
// import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';
// import { FaLongArrowAltUp } from 'react-icons/fa';
// import { FaLongArrowAltDown } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';

// Library
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import Draggable from 'react-draggable';
// import swal from 'sweetalert'; // https://sweetalert.js.org/guides/
var moment = require('moment');
var fetch = require('node-fetch');

const useStyles = makeStyles((theme) => ({
  // resizeForm: {
  //   borderRadius: "7px",
  //   border: "1px solid #2B4D80",
  //   display: "flex",
  //   backgroundColor: "#F5F5F5",
  //   boxShadow: theme.shadows[1],
  //   "flex-flow": "column",
  //   width: "40%",
  //   resize: "both",
  //   overflow: "hidden",
  //   minWidth: "150px",
  //   minHeight: "150px",
  //   position: 'absolute',
  // },
  resizeForm: {
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    border: "1px solid #2B4D80",
    display: "flex",
    backgroundColor: "white",
    "flex-flow": "column",
    width: "40%",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "30px",
    background: "white",
    position: 'absolute',
  },
}))

//Home(props) - получаем переменные от родителя App.js 
export default (props) => {
  const classes = useStyles()
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [position, setPosition] = useState({ x: 0, y: 0 })

  // FIELDS
  const [fieldValue, setFieldValue] = useState({})

  useEffect(async () => {
    console.log("IMP MESS PROPS", props)
    props.setComponentIndex(props.getComponentIndex() + 1)
  }, [])
  // random UUID generator
  function getUUID() {
    return uuidv4()
  }
  function formClick() {
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 1)
    props.setComponentIndex(currIndex + 1)
  }
  function positionHandler(e, data) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 25 // Take 25% of width
    let maxDeviationX = (dimension.w / 100) * 60 // Take 60% of width
    let maxDeviationY = -(dimension.h / 100) * 20 // Take 20% of height 
    // console.log("minX", minDeviationX, "maxX", maxDeviationX, "maxY", maxDeviationY)
    if (data.y < maxDeviationY) {
      // console.log("SET MIN Y", data.x)
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      // console.log("SET MIN X", data.x, minDeviationX)
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      // console.log("SET MAX X", data.x)
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
  }
  return (
    // <Draggable
    //   handle="p"
    //   onStart={() => formClick()}
    //   onMouseDown={() => formClick()}
    // // onClick={()=> formClick()}
    // >
    <Draggable
      handle="p"
      onStart={() => formClick()}
      onStop={positionHandler}
      position={position}
    >
      <div
        className={classes.resizeForm}
        style={{ top: "20%", left: "25%", transform: "translate(-20%, -25%)", zIndex: componentIndex }}
        onClick={() => formClick()}
      >
        <p className="main-table-label-p-style-new">
          <table className="dragble-div-header-table-style-new">
            <tr>
              <td width="99%" className="dragble-div-header-td-style-new">Сообщение</td>
              <td>
                <IoMdClose size={22} className="form-close-icons-style" style={{ marginRight: "5px" }} onClick={() => props.setShowImportantMessage(false)} />
              </td>
              {/* <td onClick={()=> props.setShowImportantMessage(false)}><IconButton size="small"><CloseIcon/></IconButton></td> */}
            </tr>
          </table>
        </p>
        <div className="dragble-div-body-style">
          <div>
            <table align="left" width="100%">
              <tr>
                <td width="5%" style={{ background: "white", fontWeight: "bold", textAlign: "right" }}>
                  Время:
                </td>
                <td width="20%" style={{ border: "1px solid grey", background: "white" }}>
                  {moment(props.time).format('HH:mm:ss')}
                </td>
                <td width="30%" style={{ background: "white", fontWeight: "bold", textAlign: "right" }}>
                  Отправитель:
                </td>
                <td width="20%" style={{ border: "1px solid grey", background: "white" }}>
                  Система
                </td>
              </tr>
            </table>
            <table align="center" width="100%">
              <tr>
                <td width="100%" style={{ border: "1px solid grey", background: "white" }}>
                  {props.message}
                </td>
              </tr>
            </table>
            <table width="100%">
              <tr align="right">
                <td>
                  <button
                    className="approveButton"
                    onClick={() => props.setShowImportantMessage(false)}
                    style={{ marginRight: 20, width: "50px" }}
                  >
                    ОК
                  </button>
                  {/* <Button
                    variant="contained"
                    onClick = {()=> props.setShowImportantMessage(false)}
                    style={{
                      margin: 3,
                      height: 30,
                      fontSize: 12,
                      color: "white",
                      backgroundColor: "#2862F4"
                    }}
                  >ОК
                  </Button> */}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </Draggable>
  )
}