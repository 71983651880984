import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
// Time
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// Icons
import { IoMdClose } from 'react-icons/io';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// CUSTOM COMPONENTS
import ConfigurationFile from "../configuration/ConfigurationFile.json";
// Library
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import Draggable from 'react-draggable';
import swal from 'sweetalert'; // https://sweetalert.js.org/guides/
var fetch = require('node-fetch');
var moment = require('moment');

const useStyles = makeStyles((theme) => ({
  resizeForm: {
    borderRadius: "3px",
    border: "1px solid #2B4D80",
    display: "flex",
    backgroundColor: "white",
    boxShadow: theme.shadows[1],
    "flex-flow": "column",
    width: "530px",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "150px",
    position: 'absolute',
  }
}))

function FloatFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      decimalSeparator={"."}
      thousandSeparator={" "}
      isNumericString
    />
  )
}
FloatFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}
function IntegerFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={" "}
      isNumericString
    />
  );
}
IntegerFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

//Home(props) - получаем переменные от родителя App.js 
export default function OrderSell(props) {
  const classes = useStyles()
  const [kseRESTApi] = useState(props.kseRESTApi) //Local KFB main REST
  const [token] = useState(props.token)
  const [userProfile] = useState(props.userProfile)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [enumData, setEnumData] = useState({})
  const [instrPos, setInstrPos] = useState([])
  const [enumOptions, setEnumOptions] = useState({})
  const [selectedOptions, setSelectedOptions] = useState({})
  const [bidType, setBidType] = useState("limited")
  const [tableKey, setTableKey] = useState(null)
  // FIELDS
  // const [lots, setLots] = useState([])
  const [fieldValue, setFieldValue] = useState({
    tradingAccount: props.selectedAccountId !== null ? props.selectedAccountId : null,
    organizationId: userProfile.organization.id,
    financeInstrumentId: props.selectedInstrument.id !== null ? props.selectedInstrument.id : null,
    amountOfLots: 0,
    amountOfLotsStep: 1,
    priceForInstrument: null,
    priceForInstrumentStep: 1,
    amountOfInstrument: null,
    amountOfInstrumentStep: 1,
    expiredAt: null,
    fullRequired: false,
    atOnePrice: false,
    withdrawBalance: false,
  })
  const [atOnePriceDisabled, setAtOnePriceDisabled] = useState(false)
  const [withdrawBalanceDisabled, setWithdrawBalanceDisabled] = useState(true)
  const [position, setPosition] = useState({ x: 0, y: 0 })

  useEffect(async () => {
    console.log("ORDER SELL PROPS", props)
    // EnumData
    let enumDataToCollect = [
      { enumName: "financeInstrumentId", enumDef: "3e819d7e-25d0-4a04-a3ff-092fd348a375" },
      { enumName: "tradingAccount", enumDef: "c324d86f-3a3b-43b2-9514-d983b2982794" },
      { enumName: "organizationId", enumDef: "5b78d9dd-821d-4c6a-a00a-3af85224fbc4" },
    ]
    let enums = await props.getEnumDataByList(enumDataToCollect)
    setEnumData(enums)
    console.log("OS ENUMS", enums)
    //
    /// instrumentPositions
    let instrumentPos = await props.fetchDocList("/api/Accounts/ViewInstruments")
    console.log("INSTR POS", instrumentPos)

    ///
    //** enumOptions
    let eOpts = await props.createEnumOptions(enums)
    console.log("E OPTS", eOpts)
    //**
    ///-- selectedOptions
    let sOpts = { ...selectedOptions }
    let n = "organizationId" // Show user organization
    for (let d = 0; d < enums[n].length; d++) {
      if (enums[n][d].id === fieldValue.organizationId) {
        sOpts[n] = { "value": enums[n][d].id, "label": enums[n][d].label, "name": n }
      }
    }

    if (props.selectedInstrument.id !== null) { // show selected instrument if user has it on account
      let newFields = { ...fieldValue }
      let f = "financeInstrumentId"
      for (let d = 0; d < enums[f].length; d++) {
        if (enums[f][d].id === props.selectedInstrument.id) {
          for (let p = 0; p < instrumentPos.length; p++) { // check whether user have instrument in account positions
            if (instrumentPos[p].financeInstrumentId === enums[f][d].id) {
              sOpts[f] = { "value": enums[f][d].id, "label": enums[f][d].label, "name": f }
            }
          }
        }
      }
      let t = "tradingAccount" // Show user account that has selected
      let account = null
      if (props.selectedAccountId !== null) {
        for (let d = 0; d < enums[t].length; d++) {
          if (enums[t][d].id === props.selectedAccountId) {
            sOpts[t] = { "value": enums[t][d].id, "label": enums[t][d].label, "name": t }
          }
        }
      }
      else {
        for (let q = 0; q < enums[t].length; q++) {
          for (let w = 0; w < instrumentPos.length; w++) {
            if (enums[t][q].id === instrumentPos[w].accountId && instrumentPos[w].financeInstrumentId === props.selectedInstrument.id) {
              sOpts[t] = { "value": enums[t][q].id, "label": enums[t][q].label, "name": t }
              account = instrumentPos[w].accountId
              newFields[t] = account
            }
          }
        }
      }

      newFields.priceForInstrument = await getBestPrice(props.selectedInstrument.id)
      setFieldValue(newFields)
      // filter instruments by account positions
      let filteredInstruments = [{ "value": null, "label": "Пусто", "name": "financeInstrumentId" }]
      for (let e = 0; e < eOpts.financeInstrumentId.length; e++) {
        for (let p = 0; p < instrumentPos.length; p++) { // check whether user have instrument in account positions
          if (instrumentPos[p].financeInstrumentId === eOpts.financeInstrumentId[e].value && instrumentPos[p].accountId === account) {
            filteredInstruments.push({ "value": eOpts.financeInstrumentId[e].value, "label": eOpts.financeInstrumentId[e].label, "name": "financeInstrumentId" })
          }
        }
      }
      eOpts.financeInstrumentId = filteredInstruments
    }
    if (props.selectedInstrument.id === null) {
      // if instrument wasn't selected leave instruments field emty
      let filteredInstruments = [{ "value": null, "label": "Пусто", "name": "financeInstrumentId" }]
      eOpts.financeInstrumentId = filteredInstruments
    }
    if (props.orderSellFields !== null) { // show instrument from bids
      let newFields = fieldValue
      for (let key in props.orderSellFields) {
        if (key === "financeInstrumentId") {
          for (let d = 0; d < enums[key].length; d++) {
            if (enums[key][d].id === props.orderSellFields.financeInstrumentId) {
              sOpts[key] = { "value": enums[key][d].id, "label": enums[key][d].label, "name": key }
            }
          }
        }
        newFields[key] = props.orderSellFields[key]
      }
      setFieldValue(newFields)
    }
    ///--

    // Update state
    setEnumOptions(eOpts)
    setSelectedOptions(sOpts)
    setInstrPos(instrumentPos)
    setTableKey(getUUID())
    props.setComponentIndex(props.getComponentIndex() + 1)
  }, [])
  async function getBestPrice(instrId) {
    let bestPrice = await fetch(
      kseRESTApi + "/api/Trading/GetBestPrice?financeInstrumentId=" + instrId + "&bidDirection=1",
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
        "method": "GET"
      }
    )
      .then(response => response.json())
      .then(function (res) {
        if (res.isSuccess === true) {
          console.log("BEST RES", res)
          return res.data
        }
        else {
          props.callErrorToast(res.errors[0])
          return null
        }
      })
      .catch(function (error) {
        return null
      })
    // console.log("BEST PR RES", bestPrice)
    return bestPrice
  }
  function swAllert(text, icon) {
    return (
      swal({
        text: text,
        icon: icon,
        buttons: { ok: "Ок" },
      })
    )
  }
  // random UUID generator
  function getUUID() {
    return uuidv4()
  }
  // INPUT HANDLERS
  // SELECT
  async function handleSelectChange(option) {
    if (option.name === "tradingAccount") {
      // enum options
      let newEnOpts = { ...enumOptions }
      let newFinanceInstrumentIdSelOpts = [{ "value": null, "label": "Пусто", "name": "financeInstrumentId" }]
      let account = option.value
      for (let e = 0; e < enumData.financeInstrumentId.length; e++) {
        for (let p = 0; p < instrPos.length; p++) { // check whether user have instrument in account positions
          if (instrPos[p].financeInstrumentId === enumData.financeInstrumentId[e].id && instrPos[p].accountId === account) {
            newFinanceInstrumentIdSelOpts.push({ "value": enumData.financeInstrumentId[e].id, "label": enumData.financeInstrumentId[e].label, "name": "financeInstrumentId" })
          }
        }
      }
      newEnOpts.financeInstrumentId = newFinanceInstrumentIdSelOpts
      setEnumOptions(newEnOpts) // change list of instruments


      // selected options
      let newSelOpts = { ...selectedOptions }
      newSelOpts.tradingAccount = option
      newSelOpts.financeInstrumentId = newFinanceInstrumentIdSelOpts[0]
      // Do not remove selected instrument if user has it on account
      for (let i = 0; i < newFinanceInstrumentIdSelOpts.length; i++) {
        if (newFinanceInstrumentIdSelOpts[i].value === fieldValue.financeInstrumentId) {
          newSelOpts.financeInstrumentId = newFinanceInstrumentIdSelOpts[i]
        }
      }
      setSelectedOptions(newSelOpts) // set selected value for instrument & account
      // fieldvalue
      let newFileds = { ...fieldValue }
      newFileds.tradingAccount = option.value
      newFileds.financeInstrumentId = null
      console.log("NEW SEL OPTS", newFinanceInstrumentIdSelOpts, fieldValue)
      // Do not remove selected instrument if user has it on account
      for (let i = 0; i < newFinanceInstrumentIdSelOpts.length; i++) {
        if (newFinanceInstrumentIdSelOpts[i].value === fieldValue.financeInstrumentId) {
          newFileds.financeInstrumentId = fieldValue.financeInstrumentId
        }
      }
      setFieldValue(newFileds) // set field value for instrument & account
      // console.log("newFinanceInstrumentIdSelOpts", newFinanceInstrumentIdSelOpts)
    }
    else {
      if (option.name === "financeInstrumentId") {
        let newFileds = { ...fieldValue }
        newFileds.financeInstrumentId = option.value
        let bPrice = await getBestPrice(option.value)
        newFileds.priceForInstrument = bPrice === null ? "" : bPrice
        setFieldValue(newFileds)
      }
      else {
        setFieldValue({ ...fieldValue, [option.name]: option.value })
      }
      setSelectedOptions({ ...selectedOptions, [option.name]: option })
    }
    // console.log("OPT", option)
    setTableKey(getUUID())
  }
  // INT
  const handleIntChange = (event) => {
    // console.log("EVENT", event.target.name, event.target.value)
    if (event.target.value !== "") {
      let stringNum = event.target.value.toString().replace(/ /g, '')
      let int = parseInt(stringNum)
      setFieldValue({ ...fieldValue, [event.target.name]: int })
    }
    else {
      setFieldValue({ ...fieldValue, [event.target.name]: null })
    }
  }
  const handleFloatChange = (event) => {
    // console.log("FLOAT CHANGE", event.target.name, event.target.value)
    if (event.target.value !== "") {
      let val = event.target.value.replace(/ /g, '')
      let newFields = fieldValue
      newFields[event.target.name] = parseFloat(val)
      setFieldValue(newFields)
      // console.log("FLOAT NEW VAL", event.target.name, val, fieldValue)
    }
    else {
      setFieldValue({ ...fieldValue, [event.target.name]: null })
    }
  }
  function handleDateTimeChange(time, name) {
    // console.log("TIME", time, name)
    setFieldValue({ ...fieldValue, [name]: time })
  }
  function handleCheckboxChange(event) {
    let name = event.target.name
    let value = event.target.checked
    if (name === "fullRequired") {
      let newFields = fieldValue
      newFields[name] = value
      if (value === true) {
        newFields["atOnePrice"] = false
        newFields["withdrawBalance"] = false
        setAtOnePriceDisabled(true)
        setWithdrawBalanceDisabled(true)
      }
      else {
        setAtOnePriceDisabled(false)
      }
      setFieldValue(newFields)
      // console.log("NEW FIELDS", newFields)
    }
    if (name === "atOnePrice") {
      let newFields = fieldValue
      newFields[name] = value
      if (value === true) {
        setWithdrawBalanceDisabled(false)
      }
      else {
        newFields["withdrawBalance"] = false
        setWithdrawBalanceDisabled(true)
      }
      setFieldValue(newFields)
      // console.log("NEW FIELDS", newFields)
    }
    else {
      setFieldValue({ ...fieldValue, [name]: event.target.checked })
    }
  }
  // function addLot(){
  //   // console.log("LOTS AMOUNT", fieldValue["amountOfLots"], fieldValue["amountOfLotsStep"])
  //   let newAmount = fieldValue["amountOfLots"] + fieldValue["amountOfLotsStep"]
  //   // console.log("NEW AMOUNT", newAmount)
  //   setFieldValue({ ...fieldValue, ["amountOfLots"]: newAmount})
  //   let newLots = lots
  //   newLots.push({
  //     "price": fieldValue["priceForInstrument"],
  //     "amount": fieldValue["amountOfLotsStep"],
  //     "profit": fieldValue["amountOfInstrument"],
  //     "volume": 0
  //   })
  //   // var removedItem = fruits.splice(pos, 1); // так можно удалить элемент
  //   // var last = fruits.pop(); // удалим Апельсин (из конца)
  //   // var first = fruits.shift(); // удалим Яблоко (из начала)
  //   // var newLength = fruits.unshift('Клубника') // добавляет в начало
  // }
  // function deleteLot(i){
  //   let newLots = lots.slice()
  //   newLots.splice(i, 1) // delete element
  //   setLots(newLots)
  //   setFieldValue({ ...fieldValue, ["amountOfLots"]: newLots.length})
  // }
  function add(name, step) {
    let newVal = fieldValue[name] + fieldValue[step]
    setFieldValue({ ...fieldValue, [name]: newVal })
    // console.log("NEW VAL", newVal)
    setTableKey(getUUID())
  }
  function reduce(name, step) {
    let newVal = fieldValue[name] - fieldValue[step]
    setFieldValue({ ...fieldValue, [name]: newVal })
    setTableKey(getUUID())
    // console.log("NEW Val", newVal)
  }
  async function sendOrder() {
    console.log("FVAL", fieldValue)
    if (fieldValue.tradingAccount !== null) {
      if (fieldValue.financeInstrumentId !== null) {
        let expire = fieldValue.expiredAt !== null ? moment(fieldValue.expiredAt).format() : null
        let body = {
          "organizationId": fieldValue.organizationId,
          "financeInstrumentId": fieldValue.financeInstrumentId,
          "accountId": fieldValue.tradingAccount,
          "bidDirection": 1, // 0 Buy 1 Sell
          "bidType": bidType === "market" ? 0 : 1,
          "expiredAt": expire,
          "fullRequired": fieldValue.fullRequired,
          "atOnePrice": fieldValue.atOnePrice,
          "withdrawBalance": fieldValue.withdrawBalance,
          "lots": [{
            "price": fieldValue.priceForInstrument,
            "amount": fieldValue.amountOfInstrument,
            // "profit": 0,
            // "volume": 0
          }]//lots
        }
        console.log("BODY", body)
        if (bidType === "market") {
          sendBid(body)
        }
        else {
          await fetch(
            kseRESTApi + "/api/Trading/CheckSpread?instrumentId=" + fieldValue.financeInstrumentId + "&price=" + fieldValue.priceForInstrument,
            {
              "headers": { "content-type": "application/json", "Authorization": "Bearer " + token }
            }
          )
            .then(response => response.json())
            .then(function (res) {
              console.log("RES CHECK", res)
              if (res.isSuccess === true) {
                sendBid(body)
              }
              else {
                swal({
                  text: res.errors[0] + "." + "\nOK для подтверждения заявки, Отмена для редактирования!",
                  icon: "warning",
                  buttons: { ok: "Ок", cancel: "Отмена" }
                })
                  .then((click) => {
                    if (click === "ok") {
                      sendBid(body)
                    }
                  })
              }
            })
        }
      } else { props.callErrorToast("Введите инструмент!", "warning") }
    } else { props.callErrorToast("Введите торговый счёт!", "warning") }
  }
  async function sendBid(body) {
    await fetch(
      kseRESTApi + "/api/Trading/CreateBid",
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
        "method": "POST",
        "body": JSON.stringify(body)
      }
    )
      .then(response => response.json())
      .then(function (res) {
        console.log("RES", res)
        if (res.isSuccess === true) {
          props.callSuccessToast("Заявка создана", 1100)
          props.setShowOrderSell(false)
          props.setUpdateBidsBody(getUUID())
          props.setUpdateDealsBody(getUUID())
          props.setUpdateAccountsInstrumentPositions(getUUID())
          props.setUpdateAccountsCurrenciesPositions(getUUID())
        }
        else {
          props.callErrorToast(res.errors[0])
        }
      })
  }
  function getModalStyle() {
    const top = 15;
    const left = 25;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      zIndex: componentIndex
    };
  }
  function formClick() {
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 1)
    props.setComponentIndex(currIndex + 1)
  }
  function getInstrQuantity() {
    for (let i = 0; i < instrPos.length; i++) {
      if (instrPos[i].financeInstrumentId === fieldValue.financeInstrumentId && instrPos[i].accountId === fieldValue.tradingAccount) {
        return instrPos[i].quantity
      }
    }
  }
  function positionHandler(e, data) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 30 // Take 30% of width
    let maxDeviationX = (dimension.w / 100) * 60 // Take 60% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    // console.log("minX", minDeviationX, "maxX", maxDeviationX, "maxY", maxDeviationY)
    if (data.y < maxDeviationY) {
      // console.log("SET MIN Y", data.x)
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      // console.log("SET MIN X", data.x, minDeviationX)
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      // console.log("SET MAX X", data.x)
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
  }
  return (
    // <Draggable
    //   handle="p"
    //   onStart={() => formClick()}
    //   onMouseDown={() => formClick()}
    // >
    //   <div style={getModalStyle()} className={classes.resizeForm}>
    <Draggable
      handle="p"
      onStart={() => formClick()}
      onStop={positionHandler}
      position={position}
    >
      <div
        className={classes.resizeForm}
        style={{ top: "15%", left: "30%", transform: "translate(-15%, -30%)", zIndex: componentIndex }}
        onClick={() => formClick()}
        key={tableKey}
      >
        <p className="main-table-label-p-style-new">
          <table className="dragble-div-header-table-style-new">
            <tr>
              <td width="99%" className="dragble-div-header-td-style-new">Ввод заявки на ПРОДАЖУ методом открытых торгов</td>
              <td>
                <IoMdClose size={22} className="form-close-icons-style" style={{ marginRight: "5px" }} onClick={() => props.setShowOrderSell(false)} />
              </td>
            </tr>
          </table>
        </p>
        <div className="dragble-div-body-style-new">
          {/* Торговый счёт */}
          <table align="center" width="100%">
            <tr>
              <td width="50%">
                <Select
                  name={"tradingAccount"}
                  placeholder={"Выбрать..."}
                  value={selectedOptions["tradingAccount"]}
                  onChange={handleSelectChange}
                  options={enumOptions["tradingAccount"]}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
              </td>
              <td width="50%">
                <Select
                  name={"organizationId"}
                  placeholder={"Выбрать..."}
                  value={selectedOptions["organizationId"]}
                  onChange={handleSelectChange}
                  options={enumOptions["organizationId"]}
                  isDisabled={true}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
              </td>
            </tr>

          </table>
          {/* Инструмент */}
          <table width="100%">
            <tr>
              <td>
                <Select
                  name={"financeInstrumentId"}
                  placeholder={"Найти инструмент..."}
                  value={selectedOptions["financeInstrumentId"]}
                  onChange={handleSelectChange}
                  options={enumOptions["financeInstrumentId"]}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
              </td>
            </tr>
          </table>
          {fieldValue.tradingAccount !== null && fieldValue.financeInstrumentId !== null &&
            <table width="100%">
              <tr>
                <td align="center" className="dragble-div-body-td-text-style">
                  Доступные инструменты: {<span style={{ color: fieldValue.amountOfInstrument <= getInstrQuantity() ? "green" : "red" }}>{props.currencyBeautifier(getInstrQuantity())} шт</span>}
                </td>
              </tr>
            </table>
          }

          {/* Количество лотов */}
          {/* <table align="center" width="100%">
            <tr>
              <td align="center" style={{fontSize: "14px"}}>Количество лотов</td>
              <td align="center" style={{height: 30}}>
                <TextField
                  size="small"
                  variant="outlined"
                  value={fieldValue.amountOfLots}
                  onChange={handleIntChange}
                  name="amountOfLots"
                  disabled={true}
                  InputProps={{inputComponent: IntegerFormat}}
                />
                <AddIcon fontSize="large" style={{paddingTop: 3}} onClick={()=> addLot()}/>
              </td>
              <td style={{fontSize: "14px"}}>Максимум</td>
            </tr>
          </table> */}
          {/* Тип заявки */}
          <table align="center" width="100%">
            <tr>
              <td align="center">
                <Button
                  style={{
                    margin: 3,
                    height: 30,
                    fontSize: 12,
                    width: "47%",
                    color: bidType === "limited" ? "white" : "#595959",
                    borderColor: bidType === "limited" ? "#153236" : "#42A5AF",
                    borderRadius: "0%",
                    backgroundColor: "#42A5AF",
                    fontWeight: "bold",
                    fontFamily: "Helvetica"
                  }}
                  variant="outlined"
                  onClick={() => setBidType("limited")}
                >
                  Лимитированная
                </Button>
                <Button
                  style={{
                    margin: 3,
                    height: 30,
                    fontSize: 12,
                    width: "47%",
                    color: bidType === "market" ? "white" : "#595959",
                    borderColor: bidType === "market" ? "#153236" : "#42A5AF",
                    borderRadius: "0%",
                    backgroundColor: "#42A5AF",
                    fontWeight: "bold",
                    fontFamily: "Helvetica"
                  }}
                  variant="outlined"
                  onClick={() => setBidType("market")}
                >Рыночная
                </Button>
              </td>
            </tr>
            {/* <tr>
              <td align="center">
                <Button
                  style={{
                    margin: 3,
                    height: 30,
                    fontSize: 12,
                    width: "47%",
                    color: bidType === "limited" ? "white" : "black",
                    borderColor: bidType === "limited" ? "black" : "#029DAB",
                    backgroundColor: "#029DAB"
                  }}
                  variant="outlined"                
                  onClick = {() => setBidType("limited")}
                >
                  Лимитированная
                </Button>  
                <Button
                  style={{
                    margin: 3,
                    height: 30,
                    fontSize: 12,
                    width: "47%",
                    color: bidType === "market" ? "white" : "black",
                    borderColor: bidType === "market" ? "black" : "#029DAB",
                    backgroundColor: "#029DAB"
                  }}
                  variant="outlined"                   
                  onClick = {() => setBidType("market")}                
                  >
                    Рыночная
                </Button>                          
              </td>
            </tr> */}
          </table>
          {/* ЛИМИТИРОВАННАЯ */}
          {bidType === "limited" &&
            <div overflow="auto">
              <table align="center" width="100%">
                <tr>
                  <td className="dragble-div-body-td-text-style" style={{ width: 165 }}>Цена за инструмент</td>
                  <td style={{ width: 170 }}>
                    <div style={{ verticalAlign: "middle", height: "25px", cursor: "pointer", display: "flex", flexDirection: "row" }}>
                      <div>
                        {/* <TextField
                          name="priceForInstrument"
                          placeholder="0"
                          value={fieldValue.priceForInstrument}
                          onChange={handleIntChange}
                          style={{ width: "150px" }}
                          InputProps={{ inputComponent: IntegerFormat }}
                        /> */}
                        <TextField
                          name="priceForInstrument"
                          placeholder="0.0"
                          defaultValue={fieldValue.priceForInstrument}
                          onBlur={handleFloatChange}
                          style={{ width: "150px" }}
                          InputProps={{ inputComponent: FloatFormat }}
                        />
                      </div>
                      <div style={{ paddingLeft: "5px" }}>
                        <AddIcon fontSize="medium" onClick={() => add("priceForInstrument", "priceForInstrumentStep")} />
                      </div>
                      <div style={{ paddingLeft: "10px" }}>
                        <RemoveIcon fontSize="medium" onClick={() => reduce("priceForInstrument", "priceForInstrumentStep")} />
                      </div>
                    </div>

                  </td>
                  <td className="dragble-div-body-td-text-style" style={{ paddingLeft: "15px" }}>Сумма:  {parseFloat((fieldValue.priceForInstrument * fieldValue.amountOfInstrument).toFixed(2))}</td>
                </tr>
                <tr>
                  <td></td>
                  <td className="dragble-div-body-td-simple-text-style">шаг цены: {fieldValue["priceForInstrumentStep"]}</td>
                </tr>
                {/* Количество */}
                <tr>
                  <td className="dragble-div-body-td-text-style">Количество</td>
                  <td>
                    <TextField
                      name="amountOfInstrument"
                      placeholder="0"
                      value={fieldValue.amountOfInstrument}
                      onChange={handleIntChange}
                      style={{ width: "150px" }}
                      InputProps={{ inputComponent: IntegerFormat }}
                    />
                    <AddIcon fontSize="medium" className="add-icon-style" onClick={() => add("amountOfInstrument", "amountOfInstrumentStep")} />
                    <RemoveIcon fontSize="medium" className="reduce-icon-style" onClick={() => reduce("amountOfInstrument", "amountOfInstrumentStep")} />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td className="dragble-div-body-td-simple-text-style">шаг: {fieldValue["amountOfInstrumentStep"]}</td>
                </tr>
                {/* Время снятия */}
                <tr>
                  <td className="dragble-div-body-td-text-style">Время снятия</td>
                  <td>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        ampm={false}
                        openTo="hours"
                        views={['hours', 'minutes']}
                        inputFormat="HH:mm"
                        mask="__:__"
                        value={fieldValue.expiredAt}
                        onChange={(newValue) => {
                          setFieldValue({ ...fieldValue, ["expiredAt"]: newValue.$d })
                        }}
                        renderInput={(params) => <TextField {...params} size="small" style={{ width: "160px" }} />}
                      />
                    </LocalizationProvider>
                  </td>
                </tr>
              </table>
            </div>
          }
          {/* РЫНОЧНАЯ */}
          {bidType === "market" &&
            <div overflow="auto">
              <table align="center" width="100%">
                {/* Количество */}
                <tr>
                  <td className="dragble-div-body-td-text-style">Количество</td>
                  <td>
                    <TextField
                      name="amountOfInstrument"
                      placeholder="0"
                      value={fieldValue.amountOfInstrument}
                      onChange={handleIntChange}
                      style={{ width: "150px" }}
                      InputProps={{ inputComponent: IntegerFormat }}
                    />
                    <AddIcon fontSize="small" className="add-icon-style" onClick={() => add("amountOfInstrument", "amountOfInstrumentStep")} />
                    <RemoveIcon fontSize="small" className="reduce-icon-style" onClick={() => reduce("amountOfInstrument", "amountOfInstrumentStep")} />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td className="dragble-div-body-td-simple-text-style">шаг: {fieldValue["amountOfInstrumentStep"]}</td>
                </tr>
              </table>
              <table align="center" width="100%">
                <tr>
                  <td>
                    <Checkbox
                      size="small"
                      style={{ color: "#337E86" }}
                      name="fullRequired"
                      onChange={handleCheckboxChange}
                      checked={(fieldValue["fullRequired"] === false || fieldValue["fullRequired"] === null || fieldValue["fullRequired"] === undefined) ? false : true}
                    />
                  </td>
                  <td className="dragble-div-body-td-text-style" >Только весь объём</td>
                  <td>
                    <Checkbox
                      size="small"
                      style={{ color: atOnePriceDisabled === true ? "grey" : "#337E86" }}
                      name="atOnePrice"
                      onChange={handleCheckboxChange}
                      checked={(fieldValue["atOnePrice"] === false || fieldValue["atOnePrice"] === null || fieldValue["atOnePrice"] === undefined) ? false : true}
                      disabled={atOnePriceDisabled === true ? true : false}
                    />
                  </td>
                  <td className="dragble-div-body-td-text-style">По одной цене</td>

                  <td>
                    <Checkbox
                      size="small"
                      style={{ color: withdrawBalanceDisabled === true ? "grey" : "#337E86" }}
                      name="withdrawBalance"
                      onChange={handleCheckboxChange}
                      checked={(fieldValue["withdrawBalance"] === false || fieldValue["withdrawBalance"] === null || fieldValue["withdrawBalance"] === undefined) ? false : true}
                      disabled={withdrawBalanceDisabled === true ? true : false}
                    />
                  </td>
                  <td className="dragble-div-body-td-text-style">Снять остаток</td>

                </tr>
              </table>
            </div>
          }

          <table width="100%">
            <tr>
              <td width="100%" align="center">
                <button
                  className="cancelButton"
                  style={{ marginRight: 20 }}
                  onClick={() => props.setShowOrderSell(false)}
                >
                  Отмена
                </button>
                <button
                  className="approveButton"
                  onClick={() => sendOrder()}
                  style={{ marginRight: 20 }}
                >
                  Отправить заявку
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </Draggable>
  )
}