import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import TextField from '@material-ui/core/TextField';

// Icons
import { IoMdClose } from 'react-icons/io';
import { MdOutlineContentCopy } from 'react-icons/md';
import { RxReload } from 'react-icons/rx';

// Library
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import Draggable from 'react-draggable';
import swal from 'sweetalert'; // https://sweetalert.js.org/guides/
var fetch = require('node-fetch');

const useStyles = makeStyles((theme) => ({
  resizeForm: {
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    border: "1px solid #2B4D80",
    display: "flex",
    // backgroundColor: "#F5F5F5",
    backgroundColor: "white",
    boxShadow: theme.shadows[1],
    "flex-flow": "column",
    width: "600px",
    height: "450px",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "150px",
    position: 'absolute',
  }
}))
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white
  },
  tooltip: {
    backgroundColor: "white",
    border: "1px solid #7A7A7A",
    fontFamily: 'Helvetica',
    color: "#7A7A7A",
    fontSize: 13,
    textAlign: "center",
    boxShadow: "4px 4px 4px 3px #C3C3C3"
  }
}))

//Home(props) - получаем переменные от родителя App.js 
export default (props) => {
  const classes = useStyles()
  const [token] = useState(props.token)
  const [kseRESTApi] = useState(props.kseRESTApi) //Local KFB main REST
  const [userProfile, setUserProfile] = useState(props.userProfile)
  // const [brokerToken, setBrokerToken] = useState(props.userProfile.brokerToken === null ? "" : props.userProfile.brokerToken)
  const [brokerToken, setBrokerToken] = useState(props.userProfile.brokerToken)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  useEffect(async () => {
    console.log("PROFILE PROPS", props)
    props.setComponentIndex(props.getComponentIndex() + 1)
  }, [])
  function swAllert(text, icon) {
    return (
      swal({
        text: text,
        icon: icon,
        buttons: { ok: "Ок" },
      })
    )
  }
  // random UUID generator
  function getUUID() {
    return uuidv4()
  }
  async function generateToken() {
    await fetch(
      kseRESTApi + "/api/users/GenerateBrokerToken",
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token }
      }
    )
      .then(response => response.json())
      .then(function (res) {
        console.log("RES", res)
        if (res.isSuccess === true) {
          setBrokerToken(res.message)
        }
        else {
          props.callErrorToast(res.errors[0])
        }
      })
  }
  function getModalStyle() {
    const top = 15;
    const left = 25;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      zIndex: componentIndex
    };
  }
  function formClick() {
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 1)
    props.setComponentIndex(currIndex + 1)
  }
  function BootstrapTooltip(props) {
    const clsToolTip = useStylesBootstrap();
    return <Tooltip arrow classes={clsToolTip} {...props} />;
  }
  function positionHandler(e, data) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 60 // Take 40% of width
    let maxDeviationX = (dimension.w / 100) * 30 // Take 50% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    // console.log("minX", minDeviationX, "maxX", maxDeviationX, "maxY", maxDeviationY)
    if (data.y < maxDeviationY) {
      // console.log("SET MIN Y", data.x)
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      // console.log("SET MIN X", data.x, minDeviationX)
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      // console.log("SET MAX X", data.x)
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
  }
  return (
    // <Draggable
    //   handle="p"
    //   onStart={() => formClick()}
    //   onMouseDown={() => formClick()}
    // >
    //   <div style={getModalStyle()} className={classes.resizeForm}>
    <Draggable
      handle="p"
      onStart={() => formClick()}
      onStop={positionHandler}
      position={position}
    >
      <div
        className={classes.resizeForm}
        style={{ top: "15%", left: "60%", transform: "translate(-15%, -60%)", zIndex: componentIndex }}
        onClick={() => formClick()}
      >
        <p className="main-table-label-p-style-new">
          <table className="dragble-div-header-table-style-new">
            <tr>
              <td width="99%" className="dragble-div-header-td-style-new">Профиль пользователя</td>
              <td>
                <IoMdClose size={22} className="form-close-icons-style" style={{ marginRight: "5px" }} onClick={() => props.setShowProfile(false)} />
              </td>
            </tr>
          </table>
        </p>
        <div className="dragble-div-body-style-new">
          <table width="100%" style={{ paddingLeft: 10 }}>
            <tr>
              <td width="30%">
                Фамилия
              </td>
              <td width="50%" >
                <TextField
                  variant="outlined"
                  size="small"
                  value={userProfile.firstName}
                  style={{ width: "100%" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </td>
            </tr>
            <tr>
              <td width="30%">
                Имя
              </td>
              <td width="50%">
                <TextField
                  variant="outlined"
                  size="small"
                  value={userProfile.lastName}
                  style={{ width: "100%" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </td>
            </tr>
            <tr>
              <td width="30%">
                Отчество
              </td>
              <td width="50%">
                <TextField
                  variant="outlined"
                  size="small"
                  value={userProfile.middleName}
                  style={{ width: "100%" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </td>
            </tr>
            <tr>
              <td width="30%">
                Организация
              </td>
              <td width="50%">
                <TextField
                  variant="outlined"
                  size="small"
                  value={userProfile.organization.name}
                  style={{ width: "100%" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </td>
            </tr>
            <tr>
              <td width="30%">
                Email
              </td>
              <td width="50%">
                <TextField
                  variant="outlined"
                  size="small"
                  value={userProfile.email}
                  style={{ width: "100%" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </td>
            </tr>
            <tr>
              <td width="30%">
                Телефон
              </td>
              <td width="50%">
                <TextField
                  variant="outlined"
                  size="small"
                  value={userProfile.phone}
                  style={{ width: "100%" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </td>
            </tr>
            <tr>
              <td width="30%">
                Роль
              </td>
              <td width="50%">
                <TextField
                  variant="outlined"
                  size="small"
                  value={userProfile.role.name}
                  style={{ width: "100%" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </td>
            </tr>
            <tr>
              <td width="30%">
                Токен брокера
              </td>
              <td width="50%">
                <TextField
                  variant="outlined"
                  size="small"
                  value={brokerToken}
                  style={{ width: "100%" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </td>
              <td width="5%" style={{ cursor: "pointer", paddingLeft: 10 }}>
                <Tooltip title="Копировать" classes={useStylesBootstrap()}>
                  <div>
                    <CopyToClipboard text={brokerToken}
                      onCopy={() => props.callSuccessToast("Токен скопирован!", 1000)}
                    >
                      <MdOutlineContentCopy style={{ cursor: "pointer" }} />
                    </CopyToClipboard>
                  </div>
                </Tooltip>
              </td>

              <td width="5%">
                <Tooltip title="Сгенерировать" classes={useStylesBootstrap()}>
                  <div style={{ cursor: "pointer" }}>
                    <RxReload style={{ cursor: "pointer" }} onClick={() => generateToken()} />
                  </div>
                </Tooltip>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </Draggable>
  )
}