import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
// Icons
// import IconButton from '@material-ui/core/IconButton';
// import AddTaskIcon from '@mui/icons-material/AddTask';
// import CloseIcon from '@material-ui/icons/Close';
import { IoMdClose } from 'react-icons/io';
import BuyIcon from '@material-ui/icons/FormatBold'; //Продажа
import SellIcon from '@material-ui/icons/MonetizationOn'; //Покупка
// Library
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import Draggable from 'react-draggable';
import swal from 'sweetalert'; // https://sweetalert.js.org/guides/
const invert = require('invert-color'); // https://www.npmjs.com/package/invert-color

const useStyles = makeStyles((theme) => ({
  resizeForm: {
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    border: "1px solid #2B4D80",
    display: "flex",
    backgroundColor: "white",
    "flex-flow": "column",
    width: "25%",
    height: "200px",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "30px",
    background: "white",
    position: 'absolute',
  },
  menuIconStyle: {
    position: "absolute",
    top: 7,
    right: 10
  }
}))

export default (props) => {
  const classes = useStyles()
  // const [kseRESTApi] = useState(props.kseRESTApi) //Local KFB main REST
  // const [token] = useState(props.token);
  // const [userProfile] = useState(props.userProfile)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [tableKey, setTableKey] = useState(getUUID())

  const [instrument] = useState(props.instrument)
  const [docList, setDocList] = useState([])
  const [selectedQuotation, setSelectedQuotation] = useState({id: null})
  const [title, setTitle] = useState("")

  const [anchorEl, setAnchorEl] = useState(null)
  const openMenu = Boolean(anchorEl)
  const [position, setPosition] = useState({ x: 0, y: 0 })

  useEffect(async ()=>{
    console.log("QUOTATIONS PROPS", props)
    let docL = await props.fetchDocList("/api/Trading/GetInstrumentQuotationWithTitle?instrumentCode=" + instrument.code)
    console.log("QUOT LIST", docL)
    setDocList(docL.list)
    setTitle(docL.title)
    props.setComponentIndex(props.getComponentIndex() + 1)
  },[])
  useEffect(async ()=>{
    if(props.updateQuotations !== null){
      // console.log("UPDATING", instrument.code)
      let docL = await props.fetchDocList("/api/Trading/GetInstrumentQuotationWithTitle?instrumentCode=" + instrument.code)
      console.log("QUOT LIST", docL)
      setDocList(docL.list)
      setTitle(docL.title)
      setTableKey(getUUID())
    }
  },[props.updateQuotations])
  // random UUID generator
  function getUUID(){
    return uuidv4()
  }

  async function handleQuotationContextMenuClick(event, quotation){
    event.preventDefault()
    setAnchorEl(event.currentTarget)
    setSelectedQuotation(quotation)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }
  function openBuyForm(){
    props.setSelectedInstrument(instrument)
    props.setShowOrderBuy(true)
    props.setOrderBuyFields({
      priceForInstrument: selectedQuotation.price,
      amountOfInstrument: selectedQuotation.sell
    })
    handleCloseMenu()
  }
  function openSellForm(){
    props.setSelectedInstrument(instrument)
    props.setShowOrderSell(true)
    props.setOrderSellFields({
      priceForInstrument: selectedQuotation.price,
      amountOfInstrument: selectedQuotation.buy
    })
    handleCloseMenu()
  }
  function getBackground(quot){
    if(selectedQuotation.id === quot.id){
      if(quot.bgColor === "white"){
        return "#C7EEFF"
      }
      else{
        return invert(quot.bgColor)
      }
    }
    else{
      return quot.bgColor
    }
  }
  function getModalStyle() {
    const top = 15;
    const left = 25;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      zIndex: componentIndex
    }
  }
  function formClick(){
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 1)
    props.setComponentIndex(currIndex + 1)
  }
  function positionHandler(e, data) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 30 // Take 30% of width
    let maxDeviationX = (dimension.w / 100) * 60 // Take 60% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    // console.log("minX", minDeviationX, "maxX", maxDeviationX, "maxY", maxDeviationY)
    if (data.y < maxDeviationY) {
      // console.log("SET MIN Y", data.x)
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      // console.log("SET MIN X", data.x, minDeviationX)
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      // console.log("SET MAX X", data.x)
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
  }
  return (
    // <Draggable 
    //   handle="p"
    //   onStart={()=> formClick()}
    //   onMouseDown={()=> formClick()}
    // >
    //   <div style={getModalStyle()} className={classes.resizeForm}>
    <Draggable
      handle="p"
      onStart={() => formClick()}
      onStop={positionHandler}
      position={position}
    >
      <div
        className={classes.resizeForm}
        style={{ top: "15%", left: "30%", transform: "translate(-15%, -30%)", zIndex: componentIndex }}
        onClick={() => formClick()}
      >
        <p className="main-table-label-p-style-new">
          <table className="dragble-div-header-table-style-new">
            <tr> 
              <td width="99%" className="dragble-div-header-td-style-new">{title}</td>
              {/* <td onClick={()=> props.closeQuotation(instrument.uuid)}><IconButton size="small"><CloseIcon/></IconButton></td> */}
              <td>
                <IoMdClose size={22} className="form-close-icons-style" style={{ marginRight: "5px" }} onClick={() => props.closeQuotation(instrument.uuid)} />
              </td>
            </tr>
          </table>
        </p>
        <div>
          <table width="100%" style={{"border-collapse": "collapse"}} key={tableKey}>
            <tr>
              <td align="center" width="20%" style={{fontSize: "14px", border: "1px solid grey"}}>Продажа (S)</td>
              <td align="center" width="20%" style={{fontSize: "14px", border: "1px solid grey"}}>Цена</td>
              <td align="center" width="20%" style={{fontSize: "14px", border: "1px solid grey"}}>Покупка (B)</td>
              <td align="center" width="20%" style={{fontSize: "14px", border: "1px solid grey"}}>Остатки</td>
            </tr>
            {docList.map(quotation => {
              return(
                <tr 
                  style={{background: getBackground(quotation)}}
                  onMouseDown={()=> setSelectedQuotation(quotation)}
                  onContextMenu={(ev)=> handleQuotationContextMenuClick(ev, quotation)}
                >
                  <td 
                    align="center" 
                    width="20%" 
                    style={{color: quotation.textColor, fontSize: "14px", border: "1px solid grey"}}
                  >{quotation.sell}
                  </td>
                  <td
                    align="center"
                    width="20%"
                    style={{color: quotation.textColor, fontSize: "14px", border: "1px solid grey"}}
                    >{quotation.price}
                  </td>
                  <td 
                    align="center" 
                    width="20%" 
                    style={{color: quotation.textColor, fontSize: "14px", border: "1px solid grey"}}
                  >{quotation.buy}
                  </td>
                  <td 
                    align="center"
                    width="20%"
                    style={{color: quotation.textColor, fontSize: "14px", border: "1px solid grey"}}
                  >{quotation.balanceAmount}
                  </td>
                </tr>
              )
            })}
          </table>
          <Menu
            id="menu-quotations"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleCloseMenu}
            MenuListProps={{
              'aria-labelledby': tableKey,
            }}
          >
            {selectedQuotation.sell !== null &&
              <MenuItem onClick={()=> openBuyForm()} style={{color: "black"}}>
                Купить
                <ListItemIcon style={{color: "black"}}>
                  <BuyIcon fontSize="small" className={classes.menuIconStyle}/>
                </ListItemIcon>
              </MenuItem>
            }
            {selectedQuotation.buy !== null &&
              <MenuItem onClick={()=> openSellForm()} style={{color: "black"}}>
                Продать
                <ListItemIcon style={{color: "black"}}>
                  <SellIcon fontSize="small" className={classes.menuIconStyle}/>
                </ListItemIcon>
              </MenuItem> 
            }              
          </Menu>
        </div>
      </div>
    </Draggable>
  )
}