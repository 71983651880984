import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Markup } from 'interweave';
import Table from "@material-ui/core/Table"; // Material ui table for usual form
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
// Form components
import TextField from '@material-ui/core/TextField';
import Select from 'react-select'; // https://react-select.com/home
import Checkbox from '@material-ui/core/Checkbox';
// import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'; // https://www.npmjs.com/package/react-tabs
// Icons
import { IoMdClose } from 'react-icons/io';
// import CloseIcon from '@material-ui/icons/Close';

// Custom components
// import "../../components/GridForm/GridFormCSS.css"
import Buttons from "../configuration/Buttons.json";

//Style
import "../styles/generalStyles.css"
// Libraries
import Draggable from 'react-draggable';
import swal from 'sweetalert'; // https://sweetalert.js.org/guides/
var moment = require('moment');

var fetch = require('node-fetch');
var generator = require('generate-password');

function FloatFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        })
      }}
      decimalSeparator={"."}
      thousandSeparator={" "}
      isNumericString
    />
  );
}

FloatFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

function IntegerFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator={" "}
      isNumericString
    />
  );
}

IntegerFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}
const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: 200,
    overflow: 'auto',
  },
  resizeForm: {
    position: 'absolute',
    width: "90%",
    height: "85%",
    backgroundColor: "#F5F5F5",
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    border: '1px solid #2B4D80',
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "150px",
    // maxHeight: "700px",
    background: "white",
    position: 'absolute',
  },
  reportStyle: {
    borderBottom: "1px solid grey",
    fontWeight: "bold",
    cursor: "pointer",
    fontSize: "16",
    color: "#418991",
    "&:hover": {
      color: "#418991",
      background: "#D5E6E8"
    }
  },
  arrow: {
    color: theme.palette.common.white
  },
  tooltip: {
    backgroundColor: "white",
    border: "1px solid #7A7A7A",
    fontFamily: 'Helvetica',
    color: "#7A7A7A",
    fontSize: 13,
    textAlign: "center",
    boxShadow: "4px 4px 4px 3px #C3C3C3"
  }
}))
function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}
function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}
export default (props) => {
  const classes = useStyles()
  const [kseRESTApi] = useState(props.kseRESTApi)
  const [taskVariables] = useState(props.taskVariables)
  const [userProfile] = useState(props.userProfile)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [Form, setForm] = useState(null)
  const [buttons, setButtons] = useState([])
  const [formType] = useState("edit")
  const [selectedDoc, setSelectedDoc] = useState(null)

  const [enumData, setEnumData] = useState({})
  const [enumOptions, setEnumOptions] = useState({})
  const [selectedOptions, setSelectedOptions] = useState([])

  const [fieldValue, setFieldValue] = useState({})
  const [reportVariables, setReportVariables] = useState("")
  const [reports, setReports] = useState([])
  const [updateState, setUpdateState] = useState(false)

  // TRANSFER LIST
  var initialTrList = { market_sectors_id: [], instruments_id: [], dep_id: [], markets_id: [], currency_id: [], service_type_id: [] }
  const [transferList, setTransferList] = useState(initialTrList)
  const [checked, setChecked] = useState(initialTrList)
  const [selectedTransferList, setSelectedTransferList] = useState(initialTrList)
  const [transferListSearchFileds, setTransferListSearchFileds] = useState({})
  const [selectedTransferListSearchFileds, setSelectedTransferListSearchFileds] = useState({})
  const [autofocusFieldTrList, setAutofocusFieldTrList] = useState(null)
  const [autofocusFieldSelTrList, setAutofocusFieldSelTrList] = useState(null)

  const [tableKey, setTableKey] = useState("1")

  const [position, setPosition] = useState(props.taskVariables.position)
  function leftChecked(name) {
    return intersection(checked[name], transferList[name])
  }
  function rightChecked(name) {
    return intersection(checked[name], selectedTransferList[name])
  }

  // Set data from props to state of component
  useEffect(async () => {
    console.log("REPORT PROPS", props.taskVariables)
    let f = await props.fetchForm(taskVariables.formDefId)
    setForm(f)
    setButtons(Buttons[userProfile.role.name][taskVariables.buttons])
    // console.log("BUTTONS", Buttons[userProfile.role.name][taskVariables.buttons])
    let enums = await props.getEnumDataByForm(f)
    // let enums = {}
    let enumDataToCollect = [
      { enumName: "instruments", enumDef: "3e819d7e-25d0-4a04-a3ff-092fd348a375" },
      { enumName: "sectors", enumDef: "df59dde0-68fd-4a8b-84e1-aa33531d2fe6" },
      { enumName: "markets", enumDef: "ab14dc6d-0139-4dd4-ab65-172cacb636f8" },
      { enumName: "reports", enumDef: "58b19f13-64ba-49c1-bdbe-1b2c88697414" },
    ]
    let enums2 = await props.getEnumDataByList(enumDataToCollect)
    for (let i = 0; i < enumDataToCollect.length; i++) {
      enums[enumDataToCollect[i].enumName] = enums2[enumDataToCollect[i].enumName]
    }
    setReports(enums.reports.sort(dynamicSort("id", 1, "Int")))
    setEnumData(enums)
    console.log("REP EN", enums)
    createTransferListOptions(f, enums)

    setEnumOptions(await props.createEnumOptions(enums))
    setUpdateState(getUUID())
    props.setComponentIndex(props.getComponentIndex() + 1)
  }, [])
  function createTransferListOptions(form, enums) {
    let newTransferList = {}
    let newSelectedTransferList = {}
    for (let s = 0; s < form.sections.length; s++) {
      for (let c = 0; c < form.sections[s].contents.length; c++) {
        if (form.sections[s].contents[c].type === "TransferList") {
          let newTrItems = []
          let newSelTrItems = []
          let cName = form.sections[s].contents[c].name
          for (let d = 0; d < enums[cName].length; d++) {
            newTrItems.push(enums[cName][d].id)
          }
          newTransferList[cName] = newTrItems
          newSelectedTransferList[cName] = newSelTrItems
        }
      }
    }
    setTransferList(newTransferList)
    setSelectedTransferList(newSelectedTransferList)
  }
  function dynamicSort(property, sortOrder, type) {
    if (type === "DateTime" || type === "Bool") {
      sortOrder = sortOrder * -1
    }
    if (type === "DateTime") {
      return function (a, b) {
        if (a[property] !== null && b[property] !== null) {
          let dateA = new Date(a[property].substring(0, 19))
          let timeInSecA = dateA.getTime() / 1000
          // console.log("timeInSecA", timeInSecA)
          let dateB = new Date(b[property].substring(0, 19))
          let timeInSecB = dateB.getTime() / 1000
          // console.log("timeInSecB", timeInSecB)
          var result = (timeInSecA < timeInSecB) ? -1 : (timeInSecA > timeInSecB) ? 1 : 0
          return result * sortOrder
        }
        else {
          if (a[property] === null) {
            return -1 * sortOrder
          }
          return 1 * sortOrder
        }
      }
    }
    else if (type === "Int" || type === "Text" || type === "Float" || type === "Bool") {
      return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
        return result * sortOrder
      }
    }
    else if (type === "Enum") {
      return function (a, b) {
        if (a[property] === null) {
          return 1 * sortOrder
        }
        else {
          let labelA = getEnumLabel(property, a[property])
          // console.log("A", property, a[property], labelA)
          let labelB = getEnumLabel(property, b[property])
          // console.log("labelB", labelB, property, b)
          var result = (labelA < labelB) ? -1 : (labelA > labelB) ? 1 : 0
          return result * sortOrder
        }

      }
    }
  }
  function getEnumLabel(name, id) {
    for (let d = 0; d < enumData[name].length; d++) {
      if (enumData[name][d].id === id) {
        return enumData[name][d].label
      }
    }
  }
  const handleCheckboxChange = (event) => {
    console.log("CHBX", event.target.name, event.target.checked);
    setFieldValue({ ...fieldValue, [event.target.name]: event.target.checked })
  }
  // Integer input handler
  const handleIntChange = (event) => {
    // console.log("EVENT", event.target.name, event.target.value)
    if (event.target.value !== "") {
      let stringNum = event.target.value.toString().replace(/ /g, '')
      let int = parseInt(stringNum)
      setFieldValue({ ...fieldValue, [event.target.name]: int })
    }
    else {
      setFieldValue({ ...fieldValue, [event.target.name]: null })
    }
  }
  // Float input handler
  const handleFloatChange = (event) => {
    // console.log("FLOAT CHANGE", event.target.name, event.target.value)
    if (event.target.value !== "") {
      let val = event.target.value.replace(/ /g, '')
      let newFields = fieldValue
      newFields[event.target.name] = parseFloat(val)
      setFieldValue(newFields)
    }
    else {
      setFieldValue({ ...fieldValue, [event.target.name]: null })
    }
    // setFieldValue({ ...fieldValue, [event.target.name]: parseFloat(event.target.value) })
  }
  // Text input handler
  function handleTextChange(event) {
    // console.log("EVENT", event.target.name, event.target.value);
    fieldValue[event.target.name] = event.target.value;
    setFieldValue(fieldValue);
    // console.log("FIELDVALUE", fieldValue)
  }

  function handleSelectChange(option) {
    setFieldValue({ ...fieldValue, [option.name]: option.value })
    // fieldValue[option.name] = option.value
    console.log("OPTs", option.name, option.value);
    var updateSelectedOptions = selectedOptions.slice();
    let noSuchOption = true;
    for (let i = 0; i < updateSelectedOptions.length; i++) {
      if (option.name === updateSelectedOptions[i].name) {
        updateSelectedOptions[i] = option;
        noSuchOption = false;
        setSelectedOptions(updateSelectedOptions);
        break
      } else {
        noSuchOption = true
      }
    }
    if (noSuchOption === true) {
      updateSelectedOptions.push(option);
      setSelectedOptions(updateSelectedOptions)
    }
  }
  function handleSearchTransferListChange(event) {
    let name = event.target.name
    let value = event.target.value
    setAutofocusFieldTrList(name)
    setTransferListSearchFileds({ ...transferListSearchFileds, [name]: value })
    let filteredTrListOpts = []
    for (let e = 0; e < enumData.length; e++) {
      if (enumData[e].name === name) {
        for (let d = 0; d < enumData[e].data.length; d++) {
          let label = enumData[e].data[d].label.toLowerCase()
          let searchField = value.toLowerCase()
          let includeSearch = label.includes(searchField)
          if (includeSearch === true) {
            filteredTrListOpts.push(enumData[e].data[d].id)
          }
        }
      }
    }
    // console.log("N TR L", newTrListOpts)
    let newTrListOpts = []
    if (selectedTransferList[name].length > 0) {
      for (let i = 0; i < filteredTrListOpts.length; i++) {
        let include = true
        for (let s = 0; s < selectedTransferList[name].length; s++) {
          if (filteredTrListOpts[i] === selectedTransferList[name][s]) {
            include = false
            break
          }
        }
        if (include === true) {
          newTrListOpts.push(filteredTrListOpts[i])
        }
      }
    }
    else {
      newTrListOpts = filteredTrListOpts
    }
    setTransferList({ ...transferList, [name]: newTrListOpts })
  }

  function handleDateTimeChange(event) {
    let date = moment(event.target.value).format()
    setFieldValue({ ...fieldValue, [event.target.name]: date })
    console.log("DATE CHANGE", date)
  }
  // Transfer List functions
  const handleToggle = (value, name) => () => {
    const currentIndex = checked[name].indexOf(value)
    const newChecked = [...checked[name]]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked({ ...checked, [name]: newChecked })
  }
  const handleAllRight = (name) => {
    setSelectedTransferList({ ...selectedTransferList, [name]: selectedTransferList[name].concat(transferList[name]) })
    setTransferList({ ...transferList, [name]: [] })
  }
  function handleCheckedRight(name) {
    // console.log("CHECKED RIGHT", name)
    setSelectedTransferList({ ...selectedTransferList, [name]: selectedTransferList[name].concat(leftChecked(name)) })
    setTransferList({ ...transferList, [name]: not(transferList[name], leftChecked(name)) })
    setChecked({ ...checked, [name]: not(checked[name], leftChecked(name)) })
  }
  function handleCheckedLeft(name) {
    setTransferList({ ...transferList, [name]: transferList[name].concat(rightChecked(name)) })
    setSelectedTransferList({ ...selectedTransferList, [name]: not(selectedTransferList[name], rightChecked(name)) })
    setChecked({ ...checked, [name]: not(checked[name], rightChecked(name)) })
  }
  const handleAllLeft = (name) => {
    setTransferList({ ...transferList, [name]: transferList[name].concat(selectedTransferList[name]) })
    setSelectedTransferList({ ...selectedTransferList, [name]: [] })
  }
  function getTransferList(items, name) {
    // console.log("G TR L", items, name)
    return (
      <Paper className={classes.paper}>
        <List dense component="div" role="list">
          {items.map((value) => {
            const labelId = `transfer-list-item-${value}-label`;
            return (
              <ListItem size="small" key={value} role="listitem" button onClick={handleToggle(value, name)}>
                <Checkbox
                  size="small"
                  checked={checked[name].indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
                {/* <ListItemText id={labelId} primary={value} /> */}
                <ListItemText id={labelId} primary={getTranserListItemLabel(name, value)} />
              </ListItem>
            )
          })}
          <ListItem />
        </List>
      </Paper>
    )
  }
  function getTranserListItemLabel(name, value) {
    // console.log("G TRL LABEL", name, value)
    for (let d = 0; d < enumData[name].length; d++) {
      if (enumData[name][d].id === value) {
        return enumData[name][d].label
      }
    }
  }
  function handleSearchTransferListChange(event) {
    let name = event.target.name
    let value = event.target.value
    setAutofocusFieldTrList(name)
    setTransferListSearchFileds({ ...transferListSearchFileds, [name]: value })
    let filteredTrListOpts = []
    for (let d = 0; d < enumData[name].length; d++) {
      let label = enumData[name][d].label.toLowerCase()
      let searchField = value.toLowerCase()
      let includeSearch = label.includes(searchField)
      if (includeSearch === true) {
        filteredTrListOpts.push(enumData[name][d].id)
      }
    }
    // console.log("N TR L", newTrListOpts)
    let newTrListOpts = []
    if (selectedTransferList[name].length > 0) {
      for (let i = 0; i < filteredTrListOpts.length; i++) {
        let include = true
        for (let s = 0; s < selectedTransferList[name].length; s++) {
          if (filteredTrListOpts[i] === selectedTransferList[name][s]) {
            include = false
            break
          }
        }
        if (include === true) {
          newTrListOpts.push(filteredTrListOpts[i])
        }
      }
    }
    else {
      newTrListOpts = filteredTrListOpts
    }
    setTransferList({ ...transferList, [name]: newTrListOpts })
  }
  function handleSearchSelectedTransferListChange(event) {
    let name = event.target.name
    let value = event.target.value
    setAutofocusFieldSelTrList(name)
    setSelectedTransferListSearchFileds({ ...selectedTransferListSearchFileds, [name]: value })
    let filteredTrListOpts = []
    for (let d = 0; d < enumData[name].length; d++) {
      let label = enumData[name][d].label.toLowerCase()
      let searchField = value.toLowerCase()
      let includeSearch = label.includes(searchField)
      if (includeSearch === true) {
        filteredTrListOpts.push(enumData[name][d].id)
      }
    }
    let newSelTrListOpts = []
    if (transferList[name].length > 0) {
      for (let i = 0; i < filteredTrListOpts.length; i++) {
        let include = true
        for (let s = 0; s < transferList[name].length; s++) {
          if (filteredTrListOpts[i] === transferList[name][s]) {
            include = false
            break
          }
        }
        if (include === true) {
          newSelTrListOpts.push(filteredTrListOpts[i])
        }
      }
    }
    else {
      newSelTrListOpts = filteredTrListOpts
    }
    setSelectedTransferList({ ...selectedTransferList, [name]: newSelTrListOpts })
  }

  // random UUID generator
  function getUUID() {
    const uuid = require("uuid");
    return uuid.v1()
  }
  // random numbers generator
  function keyGen(length) {
    var password = generator.generate({
      length: length,
      numbers: true
    });
    return password
  }

  function swAllert(text, icon) {
    return (
      swal({
        text: text,
        icon: icon,
        buttons: { ok: "Ок" },
      })
    )
  }

  //Проверка наличие полей для обязательного заполнения в форме
  function checkForRequieredFields() {
    let checkedSuccessfuly = null;
    for (let s = 0; s < Form.sections.length; s++) {
      for (let c = 0; c < Form.sections[s].contents.length; c++) {
        let fieldName = Form.sections[s].contents[c].name;
        if (Form.sections[s].contents[c].required === true) {
          if (fieldValue[fieldName] === undefined || fieldValue[fieldName] === null ||
            fieldValue[fieldName] === "NaN.NaN.NaN" || fieldValue[fieldName] === "") {
            checkedSuccessfuly = false;
            swAllert("Внимание заполните поле \"" + Form.sections[s].contents[c].label + "\"", "warning");
            return checkedSuccessfuly
          } else {
            checkedSuccessfuly = true
          }
        } else {
          checkedSuccessfuly = true
        }
      }
    }
    return checkedSuccessfuly
  }

  async function repserverlogin() {
    await fetch(
      "/jasperserver/rest_v2/login?j_username=jasperadmin&j_password=jasperadmin",
      {
        mode: 'no-cors',
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'text/html; charset=utf-8'
        }
      }
    )
      .then(response => {
        // response.text().then(string => {
        console.log("JASPERT AUTH", response)
        // })
      })
      .catch(error => console.error(error));
  }
  async function reportToHtml(reportName, reportVars) {
    await repserverlogin()
    let url = "/jasperserver/rest_v2/reports/reports/Kyrgyz_stock_exchange/" + reportName + ".html" + reportVars
    await fetch(
      url,
      {
        mode: 'no-cors',
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'text/html; charset=utf-8'
        }
      }
    )
      .then(response => response.text())
      .then(txt => {
        setSelectedDoc(txt)
      })
      .catch(error => console.error(error));
  }
  async function reportToExcel(reportName) {
    await repserverlogin();
    let url = "/jasperserver/rest_v2/reports/reports/Kyrgyz_stock_exchange/" + reportName + ".xlsx" + reportVariables
    await fetch(
      url,
      {
        mode: 'no-cors',
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'text/html; charset=utf-8'
        }
      }
    )
      .then(response => response.blob())
      .then(blob => {
        // console.log("RESP", blob)
        var url = window.URL.createObjectURL(blob)
        var a = document.createElement('a')
        a.href = url
        a.download = `${reportName}.xlsx`
        document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click()
        a.remove()  //afterwards we remove the element again
      })
      .catch(error => console.error(error));
  }
  async function reportToPDF(reportName) {
    await repserverlogin()
    let url = "/jasperserver/rest_v2/reports/reports/Kyrgyz_stock_exchange/" + reportName + ".pdf" + reportVariables
    await fetch(
      url,
      {
        mode: 'no-cors',
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'text/html; charset=utf-8'
        }
      }
    )
      .then(response => response.blob())
      .then(blob => {
        console.log("RESP", blob)
        var url = window.URL.createObjectURL(blob)
        var a = document.createElement('a')
        a.href = url
        a.download = `${reportName}.pdf`
        document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click()
        a.remove()  //afterwards we remove the element again
      })
      .catch(error => console.error(error));
  }
  async function reportToWord(reportName) {
    await repserverlogin()
    let url = "/jasperserver/rest_v2/reports/reports/Kyrgyz_stock_exchange/" + reportName + ".docx" + reportVariables
    await fetch(
      url,
      {
        mode: 'no-cors',
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'text/html; charset=utf-8'
        }
      }
    )
      .then(response => response.blob())
      .then(blob => {
        var url = window.URL.createObjectURL(blob)
        var a = document.createElement('a')
        a.href = url
        a.download = `${reportName}.docx`
        document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click()
        a.remove()  //afterwards we remove the element again
      })
      .catch(error => console.error(error))
  }

  function getForm() {
    return (
      <Grid container direction="row" justify="center">
        <Grid item xs={11}>
          <Table class="detail-table-style" width="100%">
            <Grid container direction="row" justify="center" alignItems="center">
              {Form.sections.map(section => {
                return sectionBuilder(section)
              })}
            </Grid>
            <Grid direction="row" align="center">
              {buttons.map((button, index) => (
                <Button
                  name={button.name}
                  variant="outlined"
                  onClick={() => buttonClick(button.name, null)}
                  style={{
                    margin: 3,
                    color: button.fontColor,
                    backgroundColor: button.backgroundColor,
                    height: 30,
                    fontSize: 12
                  }}
                >{button.label}
                </Button>
              )
              )}
            </Grid>
          </Table>
        </Grid>
      </Grid>
    )
  }
  // Create sections with labels and call bodyBuilder function
  function sectionBuilder(section) {
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              class="sectionBuilderStyle"
            >
              {section.label}
            </TableCell>
          </TableRow>
        </TableHead>
        {bodyBuilder(section)}
      </Table>
    )
  }
  // Create body of each section and call contentBuilder function
  function bodyBuilder(section) {
    return (
      <Table size="small">
        <TableBody>
          {section.contents.map(contentItem => (
            contentItem.show === true &&
              contentItem.type === "TransferList" ?
              <table size="small" align="center">
                <tr>
                  <th colSpan="3">{contentItem.label}</th>
                </tr>
                <tr>
                  <td width="43%">поиск <input
                    autoFocus={autofocusFieldTrList === contentItem.name ? true : false}
                    name={contentItem.name}
                    onChange={handleSearchTransferListChange}
                    value={transferListSearchFileds[contentItem.name]}
                  />
                  </td>
                  <td width="4%"></td>
                  <td width="43%">поиск <input
                    autoFocus={autofocusFieldSelTrList === contentItem.name ? true : false}
                    name={contentItem.name}
                    onChange={handleSearchSelectedTransferListChange}
                    value={selectedTransferListSearchFileds[contentItem.name]}
                  /></td>
                </tr>
                <tr>
                  <td width="43%">
                    <Grid item>{getTransferList(transferList[contentItem.name], contentItem.name)}</Grid>
                  </td>
                  <td width="4%">
                    <Grid item>
                      <Grid container direction="column">
                        <Button
                          variant="outlined"
                          size="small"
                          className={classes.button}
                          onClick={() => handleAllRight(contentItem.name)}
                          disabled={transferList[contentItem.name].length === 0}
                        >
                          ≫
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          className={classes.button}
                          onClick={() => handleCheckedRight(contentItem.name)}
                          disabled={leftChecked(contentItem.name).length === 0}
                        >
                          &gt;
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          className={classes.button}
                          onClick={() => handleCheckedLeft(contentItem.name)}
                          disabled={rightChecked(contentItem.name).length === 0}
                        >
                          &lt;
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          className={classes.button}
                          onClick={() => handleAllLeft(contentItem.name)}
                          disabled={selectedTransferList[contentItem.name].length === 0}
                        >
                          ≪
                        </Button>
                      </Grid>
                    </Grid>
                  </td>
                  <td width="43%">
                    <Grid item>{getTransferList(selectedTransferList[contentItem.name], contentItem.name)}</Grid>
                  </td>
                </tr>
              </table>
              :
              <Table size="small">
                <TableRow>
                  <TableCell
                    width="40%"
                    class="bodyBuilderStyle">
                    {contentItem.label}
                  </TableCell>
                  <TableCell
                    width="60%"
                    align="left"
                  // class="bodyBuilderStyle">
                  >
                    {contentBuilder(contentItem)}
                  </TableCell>
                </TableRow>
              </Table>
          ))}
        </TableBody>
      </Table>
    )
  }
  // Creating components by it's type
  function contentBuilder(contentItem) {
    if (contentItem.type === "Text") {
      return (
        <TextField
          onBlur={handleTextChange}
          name={contentItem.name}
          placeholder={contentItem.placeholder}
          style={{ width: "100%" }}
          disabled={(formType === "view" || contentItem.edit === false) ? true : false}
          defaultValue={(fieldValue[contentItem.name]) ? fieldValue[contentItem.name] : ""}
        />
      )
    }
    else if (contentItem.type === "Enum") {
      let selectedOption = {
        "value": "",
        "label": "Пусто",
        "name": contentItem.name
      }
      if (fieldValue[contentItem.name]) {
        for (let i = 0; i < enumOptions[contentItem.name].length; i++) {
          if (fieldValue[contentItem.name] === enumOptions[contentItem.name][i].value) {
            selectedOption = enumOptions[contentItem.name][i]
            break
          }
        }
      }
      return (
        <Select
          name={contentItem.name}
          value={selectedOption}
          onChange={handleSelectChange}
          options={enumOptions[contentItem.name]}
          isDisabled={(formType === "view" || contentItem.edit === false) ? true : false}
          menuPortalTarget={document.body}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        />
      )
    }
    else if (contentItem.type === "Bool") {
      return (
        <Checkbox
          style={{ maxWidth: 20, height: 15, color: (formType === "view" || contentItem.edit === false) ? "grey" : "green" }}
          name={contentItem.name}
          onChange={handleCheckboxChange}
          disabled={(formType === "view" || contentItem.edit === false) ? true : false}
          checked={(fieldValue[contentItem.name] === false || fieldValue[contentItem.name] === null || fieldValue[contentItem.name] === undefined) ? false : true}
        />
      )
    }
    else if (contentItem.type === "Int") {
      return (
        <TextField
          disabled={(formType === "view" || contentItem.edit === false) ? true : false}
          style={{ width: "100%" }}
          defaultValue={(fieldValue[contentItem.name] !== undefined) ? fieldValue[contentItem.name] : ""}
          // value = {(fieldValue[contentItem.name] !== undefined) ? fieldValue[contentItem.name]: ""}
          onBlur={handleIntChange}
          name={contentItem.name}
          InputProps={{ inputComponent: IntegerFormat }}
        />
      )
    }
    else if (contentItem.type === "Float") {
      // console.log("FLOAT VAL", fieldValue[contentItem.name])
      return (
        <TextField
          name={contentItem.name}
          onBlur={handleFloatChange}
          defaultValue={fieldValue[contentItem.name]}
          style={{ width: "100%" }}
          disabled={(formType === "view" || contentItem.edit === false) ? true : false}
          InputProps={{ inputComponent: FloatFormat }}
        />
      )
    }
    else if (contentItem.type === "DateTime") {
      return (
        <TextField
          type="date"
          name={contentItem.name}
          onBlur={handleDateTimeChange}
          style={{ width: "100%" }}
          defaultValue={(fieldValue[contentItem.name] !== undefined) ? moment(fieldValue[contentItem.name]).format('YYYY-MM-DD') : ""}
          disabled={(formType === "view" || contentItem.edit === false) ? true : false}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )
    }
  }
  // // Creating components by it's type
  // function contentBuilder(contentItem) {
  //   if (contentItem.type === "Text") {
  //     return (
  //       <TextField
  //         onBlur={handleTextChange}
  //         name={contentItem.name}
  //         style={{ width: "100%" }}
  //         disabled={(formType === "view" || contentItem.edit === false) ? true : false}
  //         defaultValue={(fieldValue[contentItem.name]) ? fieldValue[contentItem.name] : ""}
  //       />
  //     )
  //   }
  //   else if (contentItem.type === "Enum") {
  //     let selectedOption = {
  //       "value": "",
  //       "label": "Пусто",
  //       "name": contentItem.name
  //     };
  //     if (fieldValue[contentItem.name] !== undefined) {
  //       for (let i = 0; i < enumOptions[contentItem.name].length; i++) {
  //         if (parseInt(fieldValue[contentItem.name]) === enumOptions[contentItem.name][i].value) {
  //           selectedOption = enumOptions[contentItem.name][i];
  //           break
  //         }
  //       }
  //     }
  //     if (selectedOptions.length !== 0) {
  //       for (let i = 0; i < selectedOptions.length; i++) {
  //         if (contentItem.name === selectedOptions[i].name) {
  //           selectedOption = selectedOptions[i]
  //         }
  //       }
  //     }
  //     return (
  //       <Select
  //         name={contentItem.name}
  //         value={selectedOption}
  //         onChange={handleSelectChange}
  //         options={enumOptions[contentItem.name]}
  //         // isDisabled ={(formType === "view" || contentItem.edit === false) ? true : false}
  //         menuPortalTarget={document.body}
  //         styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
  //       />
  //     )
  //   } else if (contentItem.type === "Bool") {
  //     return (
  //       <Checkbox
  //         style={{
  //           maxWidth: 20,
  //           height: 15,
  //           color: (formType === "view" || contentItem.edit === false) ? "grey" : "green"
  //         }}
  //         name={contentItem.name}
  //         onChange={handleCheckboxChange}
  //         disabled={(formType === "view" || contentItem.edit === false) ? true : false}
  //         checked={(fieldValue[contentItem.name] === false || fieldValue[contentItem.name] === null || fieldValue[contentItem.name] === undefined) ? false : true}
  //       />
  //     )
  //   } else if (contentItem.type === "Int") {
  //     return (
  //       <TextField
  //         disabled={(formType === "view" || contentItem.edit === false) ? true : false}
  //         style={{ width: "100%" }}
  //         defaultValue={(fieldValue[contentItem.name] !== undefined) ? fieldValue[contentItem.name] : ""}
  //         // value = {(fieldValue[contentItem.name] !== undefined) ? fieldValue[contentItem.name]: ""}
  //         onBlur={handleIntChange}
  //         name={contentItem.name}
  //         InputProps={{ inputComponent: IntegerFormat }}
  //       />
  //     )
  //   } else if (contentItem.type === "Float") {
  //     // console.log("FLOAT VAL", fieldValue[contentItem.name])
  //     return (
  //       <TextField
  //         name={contentItem.name}
  //         onBlur={handleFloatChange}
  //         defaultValue={fieldValue[contentItem.name]}
  //         style={{ width: "100%" }}
  //         disabled={(formType === "view" || contentItem.edit === false) ? true : false}
  //         InputProps={{ inputComponent: FloatFormat }}
  //       />
  //     )
  //   } else if (contentItem.type === "DateTime") {
  //     return (
  //       <TextField
  //         type="date"
  //         name={contentItem.name}
  //         onBlur={handleDateTimeChange}
  //         style={{ width: "100%" }}
  //         defaultValue={(fieldValue[contentItem.name] !== undefined) ? moment(fieldValue[contentItem.name]).format('YYYY-MM-DD') : ""}
  //         disabled={(formType === "view" || contentItem.edit === false) ? true : false}
  //         InputLabelProps={{
  //           shrink: true,
  //         }}
  //       />
  //     )
  //   }
  // }

  async function buttonClick(buttonName, item) {
    if (buttonName === "selectReport") {
      //console.log("Selected Report",  fieldValue['report_name'])
      let formDefId = null
      let reportName = null
      let name = "report_name"
      for (let d = 0; d < enumData[name].length; d++) {
        if (enumData[name][d].id === fieldValue['report_name']) {
          formDefId = enumData[name][d].formDefId
          reportName = enumData[name][d].reportName
          console.log("REP", enumData[name][d])
        }
      }
      let taskVars = {
        key: taskVariables.key,
        component: "reports",
        position: position,
        formDefId: formDefId,
        buttons: "ReportCreateBtns",
        reportName: reportName,
        selectedDoc: null
      }
      props.setNewTask(taskVars)
      console.log("button", buttonName, taskVars)
    }
    else if (buttonName === "createReport") {
      repserverlogin()
      let reportVars = getFieldValuesCreateReport()
      await reportToHtml(taskVariables.reportName, reportVars)
      setForm(await props.fetchForm("fcf8855f-24ff-4b2f-a5b8-7eb0d622ced5"))
      setButtons(Buttons[userProfile.role.name]["ReportViewBtns"])
      setReportVariables(reportVars)
      console.log("button", buttonName, taskVariables.reportName, reportVars)
    }
    else if (buttonName === "backToReportSelectForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "reports",
        position: position,
        taskType: "showReportsSelectingForm",
        formDefId: "34a48bee-bd0e-4755-8367-ecc91987dec6",
        buttons: "ReportSelectBtns",
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null
      }
      props.setNewTask(taskVars)
      console.log("button backToReportSelectForm: ", taskVars)
    }

    else if (buttonName === "reportToPDF") {
      await reportToPDF(taskVariables.reportName);
      console.log("button", buttonName)
    }
    else if (buttonName === "reportToExcel") {
      await reportToExcel(taskVariables.reportName);
      console.log("button", buttonName)
    }
    else if (buttonName === "reportToWord") {
      await reportToWord(taskVariables.reportName);
      console.log("button", buttonName)
    }
    else if (buttonName === "close") {
      props.closeTask(taskVariables.key)
      // props.setNewTask(null, {})
      console.log("button close: ")
    }
    else {
      console.log("UNKNOWN Button ", buttonName)
    }
  }
  function getFieldValuesCreateReport() {
    let reportVars = {}
    let strReportVars = ""
    for (let s = 0; s < Form.sections.length; s++) {
      for (let c = 0; c < Form.sections[s].contents.length; c++) {
        let fieldName = Form.sections[s].contents[c].name
        let type = Form.sections[s].contents[c].type
        if (type === "DateTime") {
          reportVars[fieldName] = moment(fieldValue[fieldName]).format('YYYY-MM-DD')
        }
        else if (type === "TransferList") {
          // console.log("TTTTTT", selectedTransferList[fieldName])
          let strVals = ""
          if (selectedTransferList[fieldName].length > 0) {
            for (let i = 0; i < selectedTransferList[fieldName].length; i++) {
              let value = selectedTransferList[fieldName][i]
              if (i === selectedTransferList[fieldName].length - 1) {
                strVals += value
              }
              else {
                strVals += value + ","
              }
            }
          }
          reportVars[fieldName] = strVals
          console.log("TR LIST", strVals)
        }
        else if (type === "FullDateTime") {
          let val = new Date(fieldValue[fieldName])
          let newDate = new Date(fieldValue[fieldName]) // "2020-01-26"
          let dd = String(newDate.getDate()).padStart(2, '0')
          let mm = String(newDate.getMonth() + 1).padStart(2, '0') //January is 0!
          let yyyy = newDate.getFullYear()
          var hours = val.getHours() < 10 ? "0" + val.getHours() : val.getHours()
          var minutes = val.getMinutes() < 10 ? "0" + val.getMinutes() : val.getMinutes()
          var seconds = val.getSeconds() < 10 ? "0" + val.getSeconds() : val.getSeconds()
          var convertedDate = dd + "." + mm + "." + yyyy + "T" + hours + ":" + minutes + ":" + seconds
          reportVars[fieldName] = convertedDate
        }
        else {
          reportVars[fieldName] = fieldValue[fieldName]
        }
      }
    }
    if (Object.keys(reportVars).length > 0) {
      strReportVars = "?"
      for (let key in reportVars) {
        let newVar = key + "=" + reportVars[key] + "&"
        strReportVars = strReportVars + newVar
      }
      strReportVars = strReportVars.substring(0, strReportVars.length - 1)
    }
    return strReportVars
  }
  function getModalStyle() {
    const top = 10;
    const left = 5;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      zIndex: componentIndex
    }
  }
  function formClick() {
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 1)
    props.setComponentIndex(currIndex + 1)
  }

  if (updateState !== null && Object.keys(enumData).length > 0) {
    try {
      return (
        <Draggable
          handle="p"
          onStart={() => formClick()}
          onMouseDown={() => formClick()}
        >
          <div style={getModalStyle()} className={classes.resizeForm}>
            <p className="main-table-label-p-style-new">
              <table className="dragble-div-header-table-styl-new">
                <tr>
                  <td width="99%" className="dragble-div-header-td-style-new">{Form.label}</td>
                  <td>
                    <IoMdClose size={22} className="form-close-icons-style" style={{ marginRight: "5px" }} onClick={() => buttonClick("close")} />
                  </td>
                </tr>
              </table>
            </p>
            <div className="dragble-div-body-style-new">
              <Grid>
                {Form !== null && getForm()}
                <br></br>
                {selectedDoc !== null &&
                  <Markup id="reportContainer" content={selectedDoc} />
                }
              </Grid>
            </div>
          </div>
        </Draggable>
      )
    } catch (er) {
      console.log("ERROR", er)
      return <LinearProgress />
    }
  }
  else return <div>Loading...</div>
};

