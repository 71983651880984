import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

// Radio Group
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

// Icons
import { IoMdClose } from 'react-icons/io';

// Form components
import TextField from '@material-ui/core/TextField';
import Select from 'react-select'; // https://react-select.com/home
import Checkbox from '@material-ui/core/Checkbox';
// Time
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

//Style
import "../styles/generalStyles.css"

// Libraries
import swal from 'sweetalert'; // https://sweetalert.js.org/guides/
import Draggable from 'react-draggable';
import { v4 as uuidv4 } from 'uuid';
// var moment = require('moment');
var fetch = require('node-fetch');
// var generator = require('generate-password');

function IntegerFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={" "}
      isNumericString
    />
  );
}
IntegerFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
const useStyles = makeStyles((theme) => ({
  resizeForm: {
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    border: "1px solid #2B4D80",
    display: "flex",
    // backgroundColor: "#F5F5F5",
    "flex-flow": "column",
    width: "38%",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "30px",
    background: "white",
    position: 'absolute',
  }
}))
export default (props) => {
  const classes = useStyles()
  const [kseRESTApi] = useState(props.kseRESTApi)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [token] = useState(props.token)
  const [userProfile] = useState(props.userProfile)
  const [enumData, setEnumData] = useState({})
  const [enumOptions, setEnumOptions] = useState({})
  const [selectedOptions, setSelectedOptions] = useState({})
  const [fieldValue, setFieldValue] = useState({
    marketId: null,
    marketSectorId: null,
    tradeStatusId: null,
    objectType: null
  })
  const [showMarket, setShowMarket] = useState(true)
  const [showSector, setShowSector] = useState(false)
  const [time, setTime] = useState(null)
  const [updateState, setUpdateState] = useState(null)
  const [tableKey, setTableKey] = useState(getUUID())
  const [formType, setFormType] = useState("edit")
  const [position, setPosition] = useState({ x: 0, y: 0 })


  // Set data from props to state of component
  useEffect(async () => {
    console.log("SCH MNGR EDIT PROPS", props)
    let enumDataToCollect = [
      { enumName: "marketId", enumDef: "ab14dc6d-0139-4dd4-ab65-172cacb636f8" },
      { enumName: "marketSectorId", enumDef: "df59dde0-68fd-4a8b-84e1-aa33531d2fe6" },
      { enumName: "tradeStatusId", enumDef: "e67a3f49-48e0-4d93-9b05-07ca0391d021" },
    ]
    let enums = await props.getEnumDataByList(enumDataToCollect)
    setEnumData(enums)
    // console.log("SC MNGR ENUM", enums)
    let eOpts = await props.createEnumOptions(enums)
    setEnumOptions(eOpts)
    if (props.selectedSchedule !== null) {
      setFieldValue(props.selectedSchedule)
      let newSelOptions = {}
      for (let key in eOpts) {
        for (let i = 0; i < eOpts[key].length; i++) {
          if (props.selectedSchedule[key] === eOpts[key][i].value) {
            newSelOptions[key] = eOpts[key][i]
          }
        }
      }
      setSelectedOptions(newSelOptions)
      let newTime = new Date("10 10 2022 " + props.selectedSchedule.hh + ":" + props.selectedSchedule.mm + ":00")
      setTime(newTime)
      setFormType("edit")
    }
    else {
      setFormType("create")
    }
    props.setComponentIndex(props.getComponentIndex() + 1)
    setUpdateState(getUUID())
  }, [])

  // random UUID generator
  function getUUID() {
    return uuidv4()
  }
  // INPUT HANDLERS
  // SELECT
  function handleSelectChange(option) {
    setSelectedOptions({ ...selectedOptions, [option.name]: option })
    setFieldValue({ ...fieldValue, [option.name]: option.value })
    console.log("OPT", option)
  }
  const handleCheckboxChange = (event) => {
    // console.log("CHBX", event.target.name, event.target.checked)
    setFieldValue({ ...fieldValue, [event.target.name]: event.target.checked })
  }

  async function saveScheduleTask() {
    let fullDate = new Date(time)
    var hours = fullDate.getHours()
    var minutes = fullDate.getMinutes()
    let body = {
      "marketId": fieldValue["marketId"],
      "marketSectorId": fieldValue["marketSectorId"],
      "tradeStatusId": fieldValue["tradeStatusId"],
      "hh": hours,
      "mm": minutes
    }
    console.log("BODY", body)
    await fetch(
      kseRESTApi + "/api/TradingScheduleManagement/" + (formType === "create" ? "CreateNew" : "Update?id=" + fieldValue.id),
      {
        "headers": { "content-type": "application/json" },
        "method": (formType === "create" ? "POST" : "PUT"),
        "body": JSON.stringify(body)
      }
    )
      .then(response => response.json())
      .then(async function (res) {
        console.log("CREATE/UPDATE", res)
        if (res.isSuccess === true) {
          props.callSuccessToast(formType === "create" ? "Задача создана!" : "Задача обновлена!")
          // setTableKey(getUUID())
          props.setShowScheduleCreateEditForm(false)
          props.setUpdateTradingSchedule(getUUID())
        }
        else {
          props.callErrorToast(res.errors[0])
        }
      })
  }
  function getModalStyle() {
    const top = 10
    const left = 15
    return {
      // height: expanded === true ? "320px" : "30px",
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      zIndex: componentIndex + 5
    }
  }
  function formClick() {
    // console.log("CLICK")
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 1)
    props.setComponentIndex(currIndex + 1)
  }
  function returnRadioGroup(name) {
    // console.log("RAD GR ENUM", enumData[name])
    return (
      <FormControl component="fieldset">
        <RadioGroup
          name={name}
          value={fieldValue[name]}
          onChange={handleRadionGroupChange}
        >
          {enumData[name].map(item => (
            <FormControlLabel
              style={{ marginTop: -10 }}
              value={item.id}
              control={<Radio />}
              label={item.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    )

  }
  function handleRadionGroupChange(event) {
    // console.log("R CH", event.target.name, event.target.value)
    let name = event.target.name
    let value = event.target.value

    if (name === "objectType") {
      let newFields = { ...fieldValue }
      let newSelOptions = { ...selectedOptions }
      if (value === "1") {
        setShowMarket(true)
        setShowSector(false)
        newFields.marketSectorId = null
        newSelOptions.marketSectorId = {
          name: "marketSectorId",
          label: "Пусто",
          value: null
        }
      }
      else if (value === "2") {
        setShowMarket(false)
        setShowSector(true)
        newFields.marketId = null
        newSelOptions.marketId = {
          name: "marketId",
          label: "Пусто",
          value: null
        }
      }
      else {
        setShowMarket(true)
        setShowSector(true)
      }
      newFields.objectType = parseInt(value)
      setFieldValue(newFields)
      setSelectedOptions(newSelOptions)
    }
    else {
      setFieldValue({ ...fieldValue, [name]: parseInt(value) })
    }
    // setTableKey(getUUID())
    // setUpdateState(getUUID())
  }
  function positionHandler(e, data) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 30 // Take 30% of width
    let maxDeviationX = (dimension.w / 100) * 60 // Take 60% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    // console.log("minX", minDeviationX, "maxX", maxDeviationX, "maxY", maxDeviationY)
    if (data.y < maxDeviationY) {
      // console.log("SET MIN Y", data.x)
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      // console.log("SET MIN X", data.x, minDeviationX)
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      // console.log("SET MAX X", data.x)
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
  }
  if (updateState !== null) {
    try {
      return (
        // <Draggable
        //   handle="p"
        //   onStart={() => formClick()}
        //   onMouseDown={() => formClick()}
        // >
        //   <div style={getModalStyle()} className={classes.resizeForm}>
        <Draggable
          handle="p"
          onStart={() => formClick()}
          onStop={positionHandler}
          position={position}
        >
          <div
            className={classes.resizeForm}
            style={{ top: "15%", left: "30%", transform: "translate(-15%, -30%)", zIndex: componentIndex }}
            onClick={() => formClick()}
          >
            <p className="main-table-label-p-style-new">
              <table className="dragble-div-header-table-style-new">
                <tr>
                  <td width="99%" className="dragble-div-header-td-style-new">Создание задачи</td>
                  <td>
                    <IoMdClose size={22} className="form-close-icons-style" style={{ marginRight: "5px" }} onClick={() => props.setShowScheduleCreateEditForm(false)} />
                  </td>
                </tr>
              </table>
            </p>
            <div className="dragble-div-body-style">
              <div style={{padding: "0px 3px 5px 5px"}}>
              <table key={tableKey} width="100%">
                <tr>
                  <td width="50%">
                    <table>
                      <tr>
                        <fieldset>
                          <legend>Тип объекта</legend>
                          <FormControl component="fieldset" style={{ margin: -10 }}>
                            <RadioGroup
                              name={"objectType"}
                              value={fieldValue.objectType}
                              onChange={handleRadionGroupChange}
                            >
                              {/* <FormControlLabel
                                style={{ marginTop: -5 }}
                                value={1}
                                control={<Radio />}
                                label="Рынок"
                              /> */}
                              <FormControlLabel
                                style={{ marginTop: -5 }}
                                value={2}
                                control={<Radio />}
                                label="Сектор"
                              />
                              <FormControlLabel
                                style={{ marginTop: -15 }}
                                value={3}
                                control={<Radio />}
                                label="Общие"
                              />
                              <FormControlLabel
                                style={{ marginTop: -5 }}
                                value={4}
                                control={<Radio />}
                                label="Разрешить/запретить подтверждение"
                              />
                              <FormControlLabel
                                style={{ marginTop: -5 }}
                                value={5}
                                control={<Radio />}
                                label="Авто подтверждение репо"
                              />

                            </RadioGroup>
                          </FormControl>
                        </fieldset>
                      </tr>
                      <tr>
                        <td align="center" width="50%" className="dragble-div-body-td-text-style">Объект</td>
                      </tr>
                      {/* {showMarket === true &&
                        <tr>
                          <td width="50%">
                            <Select
                              name={"marketId"}
                              placeholder={"Рынок..."}
                              value={selectedOptions["marketId"]}
                              onChange={handleSelectChange}
                              options={enumOptions["marketId"]}
                              menuPortalTarget={document.body}
                              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                          </td>
                        </tr>
                      } */}
                      {showSector === true &&
                        <tr>
                          <td width="50%">
                            <Select
                              name={"marketSectorId"}
                              placeholder={"Сектор..."}
                              value={selectedOptions["marketSectorId"]}
                              onChange={handleSelectChange}
                              options={enumOptions["marketSectorId"]}
                              menuPortalTarget={document.body}
                              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                          </td>
                        </tr>
                      }
                      <tr>
                        <td align="center" width="20%" className="dragble-div-body-td-text-style">Время</td>
                      </tr>
                      <tr>
                        <td width="100%">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              ampm={false}
                              openTo="hours"
                              views={['hours', 'minutes']}
                              inputFormat="HH:mm"
                              mask="__:__"
                              value={time}
                              onChange={(newValue) => {
                                setTime(newValue.$d)
                              }}
                              renderInput={(params) => <TextField {...params} size="small" style={{ width: "160px" }} />}
                            />
                          </LocalizationProvider>
                        </td>
                      </tr>
                      <tr>
                        <td className="properties-td-text-style">
                          <Checkbox
                            style={{ color: "green" }}
                            name="notAllowed"
                            onChange={handleCheckboxChange}
                            checked={fieldValue["notAllowed"] ? (fieldValue["notAllowed"] === false ? false : true) : false}
                          />Запрещено
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td width="50%" style={{ verticalAlign: "top" }}>
                    <fieldset>
                      <legend>Задача</legend>
                      {returnRadioGroup("tradeStatusId")}
                    </fieldset>
                  </td>
                </tr>
              </table>
              <table align="center" width="100%">
                <tr>
                  <td width="100%" align="center">
                    <button
                      className="cancelButton"
                      style={{ marginRight: 20 }}
                      onClick={() => props.setShowScheduleCreateEditForm(false)}
                    >
                      Отмена
                    </button>
                    <button
                      className="approveButton"
                      onClick={() => saveScheduleTask()}
                      style={{ marginRight: 20 }}
                    >
                      Сохранить
                    </button>
                  </td>
                </tr>
              </table>
              </div>
            </div>
          </div>
        </Draggable>
      )
    }
    catch (er) {
      console.log("ERROR", er)
      return <LinearProgress />
    }
  }
  else return <LinearProgress />
}

