import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Markup } from 'interweave';
import Table from "@material-ui/core/Table"; // Material ui table for usual form
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import GridSelect from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
// Form components
import TextField from '@material-ui/core/TextField';
import Select from 'react-select'; // https://react-select.com/home
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'; // https://www.npmjs.com/package/react-tabs
// Icons
import { IoMdClose } from 'react-icons/io';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import { AiOutlineFullscreen } from 'react-icons/ai';
import { AiOutlineFullscreenExit } from 'react-icons/ai';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { AiOutlineUp } from 'react-icons/ai';
import { AiOutlineDown } from 'react-icons/ai';
import { BsSearch } from 'react-icons/bs';
// import CloseIcon from '@material-ui/icons/Close';

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import InboxIcon from '@mui/icons-material/MoveToInbox';

// Custom components
// import "../../components/GridForm/GridFormCSS.css"
import Buttons from "../configuration/Buttons.json";


//Style
import "../styles/generalStyles.css"
// Libraries
import Draggable from 'react-draggable';
import swal from 'sweetalert'; // https://sweetalert.js.org/guides/
// import { print } from "pdf-to-printer";
var moment = require('moment');

var fetch = require('node-fetch');
var generator = require('generate-password');

function FloatFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        })
      }}
      decimalSeparator={"."}
      thousandSeparator={" "}
      isNumericString
    />
  );
}

FloatFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

function IntegerFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator={" "}
      isNumericString
    />
  );
}

IntegerFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}
const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: 150,
    overflow: 'auto',
  },
  resizeForm: {
    position: 'absolute',
    // width: "95%",
    // height: "800px",
    // height: "85%",
    backgroundColor: "#F5F5F5",
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    border: '1px solid #2B4D80',
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "150px",
    // maxHeight: "700px",
    background: "white",
    position: 'absolute',
  },
  reportStyle: {
    width: 80,
    borderBottom: "1px solid grey",
    fontWeight: "bold",
    cursor: "pointer",
    fontSize: "16",
    color: "#418991",
    "&:hover": {
      color: "#418991",
      background: "#D5E6E8"
    }
  },
  arrow: {
    color: theme.palette.common.white
  },
  tooltip: {
    backgroundColor: "white",
    border: "1px solid #7A7A7A",
    fontFamily: 'Helvetica',
    color: "#7A7A7A",
    fontSize: 13,
    textAlign: "center",
    boxShadow: "4px 4px 4px 3px #C3C3C3"
  }
}))

const drawerWidth = 240;

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== 'open',
// })<AppBarProps>(({ theme, open }) => ({
//   transition: theme.transitions.create(['margin', 'width'], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     width: `calc(100% - ${drawerWidth}px)`,
//     marginLeft: `${drawerWidth}px`,
//     transition: theme.transitions.create(['margin', 'width'], {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
// }));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));
export default (props) => {
  const classes = useStyles()
  const [kseRESTApi] = useState(props.kseRESTApi)
  const [taskVariables] = useState(props.taskVariables)
  const [userProfile] = useState(props.userProfile)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [tabIndex, setTabIndex] = useState(0)
  const [Form, setForm] = useState(null)
  const [buttons, setButtons] = useState([])
  const [formType] = useState("edit")
  const [selectedDoc, setSelectedDoc] = useState(null)

  const [page, setPage] = useState(1)
  const [size, setSize] = useState(1)
  const [showSnackBar, setShowSnackBar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState("")
  const [showFull, setShowFull] = useState(false)

  const [enumData, setEnumData] = useState({})
  const [enumOptions, setEnumOptions] = useState({})
  const [selectedOptions, setSelectedOptions] = useState([])

  const [fieldValue, setFieldValue] = useState({})
  const [fieldStorage, setFieldStorage] = useState({})
  const [reportVariables, setReportVariables] = useState("")
  const [reportTabs, setReportTabs] = useState([])
  const [reports, setReports] = useState([])
  const [updateState, setUpdateState] = useState(false)

  // TRANSFER LIST
  var initialList = { market_sectors_id: [], instruments_id: [], dep_id: [], markets_id: [], currency_id: [], service_type_id: [], organization_id: [] }

  const [checkBoxList, setCheckBoxList] = useState({ market_sectors_id: [], instruments_id: [], dep_id: [], markets_id: [], currency_id: [], service_type_id: [], organization_id: [] })
  const [checkedCheckBoxListItems, setCheckedCheckBoxListItems] = useState({ market_sectors_id: [], instruments_id: [], dep_id: [], markets_id: [], currency_id: [], service_type_id: [], organization_id: [] })
  const [chechBoxSearchFields, setChechBoxSearchFields] = useState({})
  const [loading, setLoading] = useState(false)

  const [selectedReport, setSelectedReport] = useState({ id: null, label: "Отчеты", reportName: "report" })
  const [initialPages, setInitialPages] = useState({ report: [] })
  const [reportPages, setReportPages] = useState({ report: [] })
  const [reportsTableKey, setReportsTableKey] = useState(getUUID())
  const [searchField, setSearchField] = useState("")
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [formSize, setFormSize] = useState({ h: 800, w: 1366 })

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  // const [checkedListType, setCheckedListType] = useState("instruments_id")
  // const [position, setPosition] = useState(props.taskVariables.position)

  // Set data from props to state of component
  useEffect(async () => {
    // console.log("REPORT PROPS", props.taskVariables)
    setButtons(Buttons[userProfile.role.name]["ReportsBtns"])
    let enumDataToCollect = [
      { enumName: "reportTabs", enumDef: "2c7ff7f9-11f6-49a4-800f-325341f1e697" },
      { enumName: "reports", enumDef: "58b19f13-64ba-49c1-bdbe-1b2c88697414" }      
    ]
    let enums = await props.getEnumDataByList(enumDataToCollect)
    console.log("REP EN", enums)
    setReportTabs(enums.reportTabs.sort(dynamicSort("sortOrder", 1, "Int")))
    setReports(enums.reports.sort(dynamicSort("sortOrder", 1, "Int")))

    setEnumOptions(await props.createEnumOptions(enums))
    setEnumData(enums)
    let storage = {}
    for (let i = 0; i < enums.reports.length; i++) {
      storage[enums.reports[i].reportName] = {}
      storage[enums.reports[i].reportName].storageFields = {}
      storage[enums.reports[i].reportName].checkedCheckBoxListItems = { market_sectors_id: [], instruments_id: [], dep_id: [], markets_id: [], currency_id: [], service_type_id: [], organization_id: [] }
    }
    setFieldStorage(storage)
    props.setComponentIndex(props.getComponentIndex() + 1)
    // selectReport(
    //   {
    //     formDefId: "495c9f23-7c20-462e-8706-fbcca7ec67a7",
    //     id: 2,
    //     label: "Биржевой контракт",
    //     reportName: "Birjevoi_kontrakt"
    //   }
    // )
    //   {
    //   formDefId: "5576396d-3664-4c96-9780-ef299bf0e82e",
    //   id: 9,
    //   label: "Отчет о заключенных сделках, рынок, сектор, последняя сделка",
    //   reportName: "Otchet_o_zaklyuchennyh_sdelkah_rynok_sektor_poslednyaya_sdelka"
    // }
    setUpdateState(getUUID())
  }, [])
  function createCheckBoxListOptions(form, enums) {
    let newCheckBoxList = {}
    for (let s = 0; s < form.sections.length; s++) {
      for (let c = 0; c < form.sections[s].contents.length; c++) {
        if (form.sections[s].contents[c].type === "TransferList") {
          let newChBoxItems = [{ id: 0, label: "Все", name: "all" }]
          let cName = form.sections[s].contents[c].name
          for (let d = 0; d < enums[cName].length; d++) {
            newChBoxItems.push(enums[cName][d])
          }
          newCheckBoxList[cName] = newChBoxItems
        }
      }
    }
    setCheckBoxList(newCheckBoxList)
  }
  function dynamicSort(property, sortOrder, type) {
    if (type === "DateTime" || type === "Bool") {
      sortOrder = sortOrder * -1
    }
    if (type === "DateTime") {
      return function (a, b) {
        if (a[property] !== null && b[property] !== null) {
          let dateA = new Date(a[property].substring(0, 19))
          let timeInSecA = dateA.getTime() / 1000
          // console.log("timeInSecA", timeInSecA)
          let dateB = new Date(b[property].substring(0, 19))
          let timeInSecB = dateB.getTime() / 1000
          // console.log("timeInSecB", timeInSecB)
          var result = (timeInSecA < timeInSecB) ? -1 : (timeInSecA > timeInSecB) ? 1 : 0
          return result * sortOrder
        }
        else {
          if (a[property] === null) {
            return -1 * sortOrder
          }
          return 1 * sortOrder
        }
      }
    }
    else if (type === "Int" || type === "Text" || type === "Float" || type === "Bool") {
      return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
        return result * sortOrder
      }
    }
    else if (type === "Enum") {
      return function (a, b) {
        if (a[property] === null) {
          return 1 * sortOrder
        }
        else {
          let labelA = getEnumLabel(property, a[property])
          let labelB = getEnumLabel(property, b[property])
          var result = (labelA < labelB) ? -1 : (labelA > labelB) ? 1 : 0
          return result * sortOrder
        }

      }
    }
  }
  function getEnumLabel(name, id) {
    for (let d = 0; d < enumData[name].length; d++) {
      if (enumData[name][d].id === id) {
        return enumData[name][d].label
      }
    }
  }
  const handleCheckboxChange = (event) => {
    // console.log("CHBX", event.target.name, event.target.checked);
    setFieldValue({ ...fieldValue, [event.target.name]: event.target.checked })
  }
  // Integer input handler
  const handleIntChange = (event) => {
    // console.log("EVENT", event.target.name, event.target.value)
    if (event.target.value !== "") {
      let stringNum = event.target.value.toString().replace(/ /g, '')
      let int = parseInt(stringNum)
      setFieldValue({ ...fieldValue, [event.target.name]: int })
    }
    else {
      setFieldValue({ ...fieldValue, [event.target.name]: null })
    }
  }
  // Float input handler
  const handleFloatChange = (event) => {
    // console.log("FLOAT CHANGE", event.target.name, event.target.value)
    if (event.target.value !== "") {
      let val = event.target.value.replace(/ /g, '')
      let newFields = fieldValue
      newFields[event.target.name] = parseFloat(val)
      setFieldValue(newFields)
    }
    else {
      setFieldValue({ ...fieldValue, [event.target.name]: null })
    }
  }
  // Text input handler
  function handleTextChange(event) {
    // console.log("EVENT", event.target.name, event.target.value);
    fieldValue[event.target.name] = event.target.value;
    setFieldValue(fieldValue);
    // console.log("FIELDVALUE", fieldValue)
  }

  function handleSelectChange(option) {
    setFieldValue({ ...fieldValue, [option.name]: option.value })
    // fieldValue[option.name] = option.value
    // console.log("OPTs", option.name, option.value);
    var updateSelectedOptions = selectedOptions.slice();
    let noSuchOption = true;
    for (let i = 0; i < updateSelectedOptions.length; i++) {
      if (option.name === updateSelectedOptions[i].name) {
        updateSelectedOptions[i] = option;
        noSuchOption = false;
        setSelectedOptions(updateSelectedOptions);
        break
      } else {
        noSuchOption = true
      }
    }
    if (noSuchOption === true) {
      updateSelectedOptions.push(option);
      setSelectedOptions(updateSelectedOptions)
    }
  }

  function handleDateTimeChange(event) {
    let date = moment(event.target.value).format()
    setFieldValue({ ...fieldValue, [event.target.name]: date })
    console.log("DATE CHANGE", date, fieldValue)
  }

  // random UUID generator
  function getUUID() {
    const uuid = require("uuid");
    return uuid.v1()
  }
  function swAllert(text, icon) {
    return (
      swal({
        text: text,
        icon: icon,
        buttons: { ok: "Ок" },
      })
    )
  }

  //Проверка наличие полей для обязательного заполнения в форме
  function checkForRequieredFields() {
    let checkedSuccessfuly = null;
    for (let s = 0; s < Form.sections.length; s++) {
      for (let c = 0; c < Form.sections[s].contents.length; c++) {
        let fieldName = Form.sections[s].contents[c].name;
        if (Form.sections[s].contents[c].required === true) {
          if (Form.sections[s].contents[c].type === "TransferList") {
            if (checkedCheckBoxListItems[fieldName].length === 0) {
              checkedSuccessfuly = false
              props.callErrorToast("Заполните поле \"" + Form.sections[s].contents[c].label + "\"", "warning");
              return checkedSuccessfuly
            }
          }
          else {
            if (fieldValue[fieldName] === undefined || fieldValue[fieldName] === null ||
              fieldValue[fieldName] === "NaN.NaN.NaN" || fieldValue[fieldName] === "") {
              checkedSuccessfuly = false
              props.callErrorToast("Заполните поле \"" + Form.sections[s].contents[c].label + "\"", "warning");
              return checkedSuccessfuly
            }
            else {
              checkedSuccessfuly = true
            }
          }
        }
        else {
          checkedSuccessfuly = true
        }
      }
    }
    return checkedSuccessfuly
  }

  async function repserverlogin() {
    await fetch(
      "/jasperserver/rest_v2/login?j_username=jasperadmin&j_password=jasperadmin",
      {
        mode: 'no-cors',
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'text/html; charset=utf-8'
        }
      }
    )
      .then(response => {
        // response.text().then(string => {
        // console.log("JASPERT AUTH", response)
        // })
      })
      .catch(error => console.error(error));
  }
  async function reportToHtml(reportName, reportVars) {
    await repserverlogin()
    let url = "/jasperserver/rest_v2/reports/reports/Kyrgyz_stock_exchange/" + reportName + ".html" + reportVars + "&userLocale=ru_RU"
    await fetch(
      url,
      {
        mode: 'no-cors',
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'text/html; charset=utf-8'
        }
      }
    )
      .then(response => response.text())
      .then(txt => {
        console.log("REP", txt)
        setLoading(false)
        let splitedHTMLpages = txt.split("JR_PAGE_ANCHOR_0_")
        let clearPages = []
        for (let i = 1; i < splitedHTMLpages.length; i++) {
          let p = splitedHTMLpages[i].substring(8, splitedHTMLpages[i].length - 12)
          clearPages.push(p)
        }
        fetchBySize(0, size - 1, clearPages)
        setInitialPages({ ...initialPages, [reportName]: clearPages })
        console.log("SIZE", size)
      })
      .catch(error => console.error(error));
  }
  async function reportToExcel(reportName) {
    await repserverlogin();
    let url = "/jasperserver/rest_v2/reports/reports/Kyrgyz_stock_exchange/" + reportName + ".xlsx" + reportVariables + "&userLocale=ru_RU"
    await fetch(
      url,
      {
        mode: 'no-cors',
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'text/html; charset=utf-8'
        }
      }
    )
      .then(response => response.blob())
      .then(blob => {
        // console.log("RESP", blob)
        var url = window.URL.createObjectURL(blob)
        var a = document.createElement('a')
        a.href = url
        a.download = `${reportName}.xlsx`
        document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click()
        a.remove()  //afterwards we remove the element again
      })
      .catch(error => console.error(error));
  }
  async function reportToPDF(reportName) {
    await repserverlogin()
    let url = "/jasperserver/rest_v2/reports/reports/Kyrgyz_stock_exchange/" + reportName + ".pdf" + reportVariables + "&userLocale=ru_RU"
    await fetch(
      url,
      {
        mode: 'no-cors',
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'text/html; charset=utf-8'
        }
      }
    )
      .then(response => response.blob())
      .then(blob => {
        console.log("RESP", blob)
        var url = window.URL.createObjectURL(blob)
        var a = document.createElement('a')
        a.href = url
        a.download = `${reportName}.pdf`
        document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click()
        a.remove()  //afterwards we remove the element again
      })
      .catch(error => console.error(error));
  }
  async function reportToWord(reportName) {
    await repserverlogin()
    let url = "/jasperserver/rest_v2/reports/reports/Kyrgyz_stock_exchange/" + reportName + ".docx" + reportVariables + "&userLocale=ru_RU"
    await fetch(
      url,
      {
        mode: 'no-cors',
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'text/html; charset=utf-8'
        }
      }
    )
      .then(response => response.blob())
      .then(blob => {
        var url = window.URL.createObjectURL(blob)
        var a = document.createElement('a')
        a.href = url
        a.download = `${reportName}.docx`
        document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click()
        a.remove()  //afterwards we remove the element again
      })
      .catch(error => console.error(error))
  }

  function getForm() {
    return (
      <Grid container direction="row">
        <Grid item xs={12}>
          <Table class="detail-table-style" width="100%">
            <Grid container direction="row" alignItems="center">
              {Form.sections.map(section => {
                return sectionBuilder(section)
              })}
            </Grid>
          </Table>
        </Grid>
      </Grid>
    )
  }
  // Create sections with labels and call bodyBuilder function
  function sectionBuilder(section) {
    return (
      <Table size="small">
        {bodyBuilder(section)}
      </Table>
    )
  }
  // Create body of each section and call contentBuilder function
  function bodyBuilder(section) {
    return (
      <Table size="small">
        <TableBody>
          {section.contents.map(contentItem => (
            contentItem.show === true &&
              contentItem.type === "TransferList" ?
              <table size="small" align="center" width="100%">
                <tr align="center">{contentItem.label}</tr>
                <tr align="center">
                  <input
                    type="search"
                    style={{ width: "90%" }}
                    name={contentItem.name}
                    value={chechBoxSearchFields[contentItem.name]}
                    onChange={checkBoxListSearchChange}
                  />
                </tr>
                <tr>
                  <td align="center"><Grid item>{getCheckBoxList(contentItem.name)}</Grid></td>
                </tr>
              </table>
              :
              <Table size="small">
                <TableRow>
                  <TableCell
                    width="20%"
                    class="bodyBuilderStyle"
                    style={{ paddingLeft: 25 }}
                  >
                    {contentItem.label}
                  </TableCell>
                  <TableCell
                    width="60%"
                    align="left"
                  // class="bodyBuilderStyle">
                  >
                    {contentBuilder(contentItem)}
                  </TableCell>
                </TableRow>
              </Table>
          ))}
        </TableBody>
      </Table>
    )
  }
  // Creating components by it's type
  function contentBuilder(contentItem) {
    if (contentItem.type === "Text") {
      return (
        <TextField
          onBlur={handleTextChange}
          name={contentItem.name}
          placeholder={contentItem.placeholder}
          style={{ width: "100%" }}
          disabled={(formType === "view" || contentItem.edit === false) ? true : false}
          defaultValue={(fieldValue[contentItem.name]) ? fieldValue[contentItem.name] : ""}
        />
      )
    }
    else if (contentItem.type === "Enum") {
      let selectedOption = {
        "value": "",
        "label": "Пусто",
        "name": contentItem.name
      }
      if (fieldValue[contentItem.name]) {
        for (let i = 0; i < enumOptions[contentItem.name].length; i++) {
          if (fieldValue[contentItem.name] === enumOptions[contentItem.name][i].value) {
            selectedOption = enumOptions[contentItem.name][i]
            break
          }
        }
      }
      return (
        <Select
          name={contentItem.name}
          value={selectedOption}
          onChange={handleSelectChange}
          options={enumOptions[contentItem.name]}
          isDisabled={(formType === "view" || contentItem.edit === false) ? true : false}
          menuPortalTarget={document.body}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        />
      )
    }
    else if (contentItem.type === "Bool") {
      return (
        <Checkbox
          style={{ maxWidth: 20, height: 15, color: (formType === "view" || contentItem.edit === false) ? "grey" : "green" }}
          name={contentItem.name}
          onChange={handleCheckboxChange}
          disabled={(formType === "view" || contentItem.edit === false) ? true : false}
          checked={(fieldValue[contentItem.name] === false || fieldValue[contentItem.name] === null || fieldValue[contentItem.name] === undefined) ? false : true}
        />
      )
    }
    else if (contentItem.type === "Int") {
      return (
        <TextField
          disabled={(formType === "view" || contentItem.edit === false) ? true : false}
          style={{ width: "100%" }}
          defaultValue={(fieldValue[contentItem.name] !== undefined) ? fieldValue[contentItem.name] : ""}
          // value = {(fieldValue[contentItem.name] !== undefined) ? fieldValue[contentItem.name]: ""}
          onBlur={handleIntChange}
          name={contentItem.name}
          InputProps={{ inputComponent: IntegerFormat }}
        />
      )
    }
    else if (contentItem.type === "Float") {
      // console.log("FLOAT VAL", fieldValue[contentItem.name])
      return (
        <TextField
          name={contentItem.name}
          onBlur={handleFloatChange}
          defaultValue={fieldValue[contentItem.name]}
          style={{ width: "100%" }}
          disabled={(formType === "view" || contentItem.edit === false) ? true : false}
          InputProps={{ inputComponent: FloatFormat }}
        />
      )
    }
    else if (contentItem.type === "DateTime") {
      return (
        <TextField
          size="small"
          type="date"
          name={contentItem.name}
          onBlur={handleDateTimeChange}
          style={{ width: "80%" }}
          defaultValue={(fieldValue[contentItem.name] !== undefined) ? moment(fieldValue[contentItem.name]).format('YYYY-MM-DD') : ""}
          disabled={(formType === "view" || contentItem.edit === false) ? true : false}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )
    }
  }

  // CheckBoxList 
  function getCheckBoxList(enumName) {
    // console.log("CH L", enumName, checkBoxList[enumName])
    return (
      <Paper className={classes.paper} id={chechBoxSearchFields[enumName]}>
        <List size="small" dense component="div" role="list">
          {checkBoxList[enumName].map((item) => {
            const labelId = `checkbox-list-item-${item.id}-label`
            return (
              <ListItem
                size="small"
                key={item.id}
                role="listitem"
                button
                onClick={() => checkBoxListItemClick(enumName, item.id)}
                style={{ background: checkedCheckBoxListItems[enumName].indexOf(item.id) !== -1 ? "#D5E6E8" : "", padding: 0, paddingLeft: 6}}
              >
                <ListItemText id={labelId} primary={item.label} />
              </ListItem>
            )
          })}
        </List>
      </Paper>
    )
  }
  function checkBoxListItemClick(name, value) {
    let currentIndex = checkedCheckBoxListItems[name].indexOf(value)
    let newChecked = [...checkedCheckBoxListItems[name]]
    console.log(name, value, newChecked)
    if (currentIndex === -1) { // Select 1 item button
      newChecked.push(value)
    }
    else {
      newChecked.splice(currentIndex, 1)
    }

    if (value === 0) { // Select all button
      for (let i = 0; i < checkBoxList[name].length; i++) {
        if (currentIndex === -1) {
          newChecked.push(checkBoxList[name][i].id)
        }
        else {
          newChecked = []
        }
      }
    }
    setCheckedCheckBoxListItems({ ...checkedCheckBoxListItems, [name]: newChecked })
  }
  function checkBoxListSearchChange(event) {
    let name = event.target.name
    let value = event.target.value
    setChechBoxSearchFields({ ...chechBoxSearchFields, [name]: value })
    let filteredCheckBoxList = { ...checkBoxList }
    filteredCheckBoxList[name] = [{ id: 0, label: "Все", name: "all" }]
    for (let i = 0; i < enumData[name].length; i++) {
      let searchField = value.toLowerCase()
      let enumField = enumData[name][i].label.toLowerCase()
      if (enumField.includes(searchField) === true) {
        filteredCheckBoxList[name].push(enumData[name][i])
      }
    }
    setCheckBoxList(filteredCheckBoxList)
  }

  async function buttonClick(buttonName, item) {
    if (buttonName === "createReport") {
      if (selectedReport.id !== null) {
        let checkResult = checkForRequieredFields()
        if (checkResult === true) {
          setLoading(true)
          setSelectedDoc(null)
          setReportsTableKey(getUUID())
          repserverlogin()
          setPage(1)
          setSize(1)
          let reportVars = getFieldValuesCreateReport()
          reportToHtml(selectedReport.reportName, reportVars)
          setReportVariables(reportVars)
          console.log("button", buttonName, selectedReport.reportName + reportVars)
        }
      }

    }
    else if (buttonName === "reportToPDF") {
      // console.log("VARS", reportVariables)
      if (reportVariables !== "") {
        await reportToPDF(selectedReport.reportName);
        console.log("button", buttonName)
      }
    }
    else if (buttonName === "reportToExcel") {
      if (reportVariables !== "") {
        await reportToExcel(selectedReport.reportName);
        console.log("button", buttonName)
      }
    }
    else if (buttonName === "reportToWord") {
      if (reportVariables !== "") {
        await reportToWord(selectedReport.reportName);
        console.log("button", buttonName)
      }
    }
    else if (buttonName === "printReport") {
      let rep = initialPages[selectedReport.reportName][0]
      console.log("REP", rep)
      let re = /width: 842px/g
      let n = rep.replace(re, 'width: 103%')
      console.log("REP2", n)

      let mywindow = window.open('', 'PRINT', 'top=100, left=10')
      mywindow.document.write(`<html><head><title>${'Экспорт в PDF'}</title>`)
      mywindow.document.write('</head><body >')
      mywindow.document.write(n)
      mywindow.document.write('</body></html>')
      mywindow.document.close() // necessary for IE >= 10
      mywindow.print()
    }
    else if (buttonName === "close") {
      props.closeTask(taskVariables.key)
      // props.setNewTask(null, {})
      console.log("button close: ")
    }
    else {
      console.log("UNKNOWN Button ", buttonName)
    }
  }
  function getFieldValuesCreateReport() {
    let reportVars = {}
    let strReportVars = ""
    let allBoolChecked = true
    for (let s = 0; s < Form.sections.length; s++) {
      for (let c = 0; c < Form.sections[s].contents.length; c++) {
        let fieldName = Form.sections[s].contents[c].name
        let type = Form.sections[s].contents[c].type
        if (type === "DateTime") {
          reportVars[fieldName] = moment(fieldValue[fieldName]).format('YYYY-MM-DD')
        }
        else if (type === "TransferList") {
          let strVals = ""
          if (checkedCheckBoxListItems[fieldName].length > 0) {
            for (let i = 0; i < checkedCheckBoxListItems[fieldName].length; i++) {
              let value = checkedCheckBoxListItems[fieldName][i]
              if (value !== 0) {
                if (i === checkedCheckBoxListItems[fieldName].length - 1) {
                  strVals += value
                }
                else {
                  strVals += value + ","
                }
              }

            }
          }
          if (checkedCheckBoxListItems[fieldName].length === 0) {
            for (let i = 0; i < checkBoxList[fieldName].length; i++) {
              let value = checkBoxList[fieldName][i].id
              if (value !== 0) {
                if (i === checkBoxList[fieldName].length - 1) {
                  strVals += value
                }
                else {
                  strVals += value + ","
                }
              }
            }
          }
          reportVars[fieldName] = strVals
          // console.log("TR LIST", strVals)
        }
        else if (type === "Bool") {
          if (fieldValue[fieldName] === true) {
            reportVars[fieldName] = "true"
          }
          else {
            reportVars[fieldName] = "false"
            allBoolChecked = false
          }
        }
        else {
          reportVars[fieldName] = fieldValue[fieldName]
        }
      }
    }
    if (Object.keys(reportVars).length > 0) {
      for (let key in reportVars) {
        if (allBoolChecked === true) {
          if (reportVars[key] === "true") {
            reportVars[key] = "true,false"
          }
        }
      }
      strReportVars = "?"
      for (let key in reportVars) {
        let newVar = key + "=" + reportVars[key] + "&"
        strReportVars = strReportVars + newVar
      }
      strReportVars = strReportVars.substring(0, strReportVars.length - 1)
    }
    return strReportVars
  }
  function getModalStyle() {
    const top = 9;
    const left = 1;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      zIndex: componentIndex
    }
  }
  function formClick() {
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 1)
    props.setComponentIndex(currIndex + 1)
  }
  // Custom ToolTip
  function BootstrapTooltip(props) {
    const clsToolTip = useStyles();
    return <Tooltip arrow classes={clsToolTip} {...props} />;
  }


  function getHeight() {
    // console.log(reportsTableKey)
    try {
      let h = document.getElementById(reportsTableKey).clientHeight;
      // console.log("H", h, h -120)
      return h - 120
    }
    catch (er) {
      return "700px"
    }

  }

  // PAGINATION
  function GoToPage(p) {
    setPage(p)
    setShowFull(false)
    if (p === 1) {
      fetchBySize(0, size - 1, initialPages[selectedReport.reportName])
    }
    else {
      let fetchFrom = p * size - size
      let fetchTo = p * size - 1
      fetchBySize(fetchFrom, fetchTo, initialPages[selectedReport.reportName])
    }
  }
  function handlePageChange(event) {
    if (event.target.value === "") {
      setPage("")
    }
    else {
      GoToPage(parseInt(event.target.value))
    }
    // console.log("NEW PAGE", event.target.value)
  }
  function handleSearchChange(event) {
    let value = event.target.value
    setSearchField(value)
    let filteredPages = []
    for (let i = 0; i < initialPages[selectedReport.reportName].length; i++) {
      let searchField = value.toLowerCase()
      let pageField = initialPages[selectedReport.reportName][i].toLowerCase()
      if (pageField.includes(searchField) === true) {
        filteredPages.push(initialPages[selectedReport.reportName][i])
      }
    }
    console.log("SEARCH", event.target.value, filteredPages.length)
    setReportPages({ ...reportPages, [selectedReport.reportName]: filteredPages })
  }
  function getPages(from, to) {
    let pArr = []
    for (let i = from; i <= to; i++) {
      if (i < Math.floor(initialPages[selectedReport.reportName].length / size)) {
        pArr.push(getPage(i))
      }
    }
    return pArr
  }
  function getPage(num) {
    return (
      <tr align="center">
        <div
          style={{
            cursor: "pointer",
            fontSize: 14,
            verticalAlign: "middle",
            textAlign: "center",
            height: "22px",
            width: "22px",
            color: page === num ? "white" : "black",
            background: page === num ? "#4FA4AD" : "white"
          }}
          onClick={() => GoToPage(num)}
        >
          <div style={{}}>{num}</div>
        </div>
      </tr>
    )
  }
  function fetchBySize(fetchFrom, fetchTo, Data) {
    // console.log("fetchFrom", "fetchTo")
    let newPages = []
    for (let i = fetchFrom; i <= fetchTo; i++) {
      if (Data[i] !== undefined) {
        newPages.push(Data[i])
      }
    }
    // setFilteredDocList(Data)
    // setReportPages(newPages)
    setReportPages({ ...reportPages, [selectedReport.reportName]: newPages })
  }
  function KeyboardArrowLeftClick(page) {
    if (page !== 1) {
      var prevPage = page - 1
      setPage(prevPage)
      let fetchFrom = ((prevPage - 1) * size) //10
      let fetchTo = (size * prevPage) - 1
      fetchBySize(fetchFrom, fetchTo, initialPages[selectedReport.reportName])
    }
    else {
      setSnackBarMessage("Вы на первой странице!")
      setShowSnackBar(true)
    }
  }
  function KeyboardArrowRightClick(page) {
    // console.log("RIGHT", initialPages.length, size)
    if (initialPages[selectedReport.reportName].length <= size * page) {
      // console.log("NO DATA")
      setSnackBarMessage("Больше нет данных!")
      setShowSnackBar(true)
    }
    else {
      setPage(page + 1)
      let fetchFrom = (size * page)
      let fetchTo = ((page + 1) * size) - 1
      fetchBySize(fetchFrom, fetchTo, initialPages[selectedReport.reportName])
    }
  }
  function handleChangeRowsPerPage(event) {
    setSize(event.target.value)
    setPage(1)
    fetchBySize(0, event.target.value - 1, initialPages[selectedReport.reportName])
  }
  function fullClick() {
    // console.log("SHOW FULL", initialPages[selectedReport.reportName])
    setShowFull(true)
    // setReportPages(initialPages[selectedReport.reportName])
    setReportPages({ ...reportPages, [selectedReport.reportName]: initialPages[selectedReport.reportName] })
  }
  function exitFullClick() {
    // console.log("EXIT FULL")
    setShowFull(false)
    GoToPage(page)
  }

  async function selectReport(report) {
    // console.log("SEL REP", report)

    setSelectedReport(report)
    // console.log("STORAGE", fieldStorage)

    let storage = {}
    let storageFields = {}
    for (let key in fieldValue) {
      storageFields[key] = fieldValue[key]
    }
    storage.storageFields = storageFields
    let storageCheckItems = {}
    for (let key in checkedCheckBoxListItems) {
      storageCheckItems[key] = checkedCheckBoxListItems[key]
    }
    storage.checkedCheckBoxListItems = checkedCheckBoxListItems

    // let newFieldStorage = { ...fieldStorage }
    // newFieldStorage[report.reportName] = storage
    // console.log("ST ITEM", storage)


    let newFields = {}
    let chList = {}

    if (fieldStorage[report.reportName] !== undefined) {
      newFields = fieldStorage[report.reportName].storageFields
      chList = { ...fieldStorage[report.reportName].checkedCheckBoxListItems }
      // console.log("FSVAL", fieldStorage[report.reportName])
    }

    //🤷‍♀️
    // else{
    //   chList = initialList
    // }

    // }
    if (!newFields["date"]) {
      newFields["date"] = moment(new Date()).format('YYYY-MM-DD')
    }
    if (!newFields["end_date"]) {
      newFields["end_date"] = moment(new Date()).format('YYYY-MM-DD')
    }

    // { "date": moment(new Date()).format('YYYY-MM-DD'), "end_date": moment(new Date()).format('YYYY-MM-DD') }


    let f = await props.fetchForm(report.formDefId)
    // console.log("FORM", f)
    let enumDataToCollect = []
    for (let s = 0; s < f.sections.length; s++) {
      for (let c = 0; c < f.sections[s].contents.length; c++) {
        if (f.sections[s].contents[c].type === "TransferList") {
          // console.log("TR LIST", f.sections[s].contents[c].name)
          let addEnum = true
          for (let key in enumData) {
            if (key === f.sections[s].contents[c].name) {
              // console.log("ENUM EXIST", f.sections[s].contents[c].name)
              addEnum = false
            }
          }
          if (addEnum === true) {
            enumDataToCollect.push({ enumName: f.sections[s].contents[c].name, enumDef: f.sections[s].contents[c].enumDef })
            // console.log("ADD ENUM", f.sections[s].contents[c].name)
          }
        }
        else if (f.sections[s].contents[c].type === "Bool") {
          newFields[f.sections[s].contents[c].name] = true
        }
      }
    }
    // console.log("COLLECT", enumDataToCollect)
    let enums = await props.getEnumDataByList(enumDataToCollect)
    let newEnumData = { ...enumData }

    for (let key in enums) {
      newEnumData[key] = enums[key]
    }
    setEnumData(newEnumData)
    // console.log("ENUMS", enums)
    createCheckBoxListOptions(f, newEnumData)
    setCheckedCheckBoxListItems(chList)
    setReportVariables("")
    setFieldValue(newFields)
    setOpen(true)
    setForm(f)
    // console.log("INIT P", initialPages)
    setInitialPages({ ...initialPages, [report.reportName]: initialPages[report.reportName] === undefined ? [] : initialPages[report.reportName] })
    setReportPages({ ...reportPages, [report.reportName]: reportPages[report.reportName] === undefined ? [] : reportPages[report.reportName] })
    setReportsTableKey(getUUID())
    setFieldStorage({ ...fieldStorage, [report.reportName]: storage })
  }

  async function clearForm(index) {
    // setForm(null)
    // setCheckedCheckBoxListItems(initialList)
    // setInitialPages([])
    // setReportPages([])
    // setReportVariables("")
    // setSearchField("")
    // setFieldValue({})
    // setReportsTableKey(getUUID())
    setTabIndex(index)
    // setSelectedReport({ id: null })
    console.log("NEW TAB", index + 1)
  }
  function positionHandler(e, data) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 2 // Take 2% of width
    let maxDeviationX = (dimension.w / 100) * 90 // Take 90% of width
    let maxDeviationY = -(dimension.h / 100) * 10 // Take 15% of height 
    // console.log("minX", minDeviationX, "maxX", maxDeviationX, "maxY", maxDeviationY)
    if (data.y < maxDeviationY) {
      // console.log("SET MIN Y", data.x)
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      // console.log("SET MIN X", data.x, minDeviationX)
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      // console.log("SET MAX X", data.x)
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
  }
  function sizeHandler() {
    formClick()
    var clientHeight = document.getElementById(reportsTableKey).clientHeight;
    var clientWidth = document.getElementById(reportsTableKey).clientWidth;
    let diffH = Math.abs(clientHeight - formSize.h)
    let diffW = Math.abs(clientWidth - formSize.w)
    // console.log("diffH", diffH, "diffW", diffW)
    if (diffH > 2 || diffW > 2) {
      // console.log("SIZE CHANGED", "H", clientHeight, "HS", formSize.h, "W", clientWidth, "WS", formSize.w)
      // let settings = {
      //   id: props.settings.id,
      //   type: props.settings.type,
      //   columns: props.settings.columns,
      //   position: { x: position.x, y: position.y },
      //   size: { h: clientHeight, w: clientWidth }
      // }
      // props.updateUserSettingsByType(settings)
      setFormSize({ h: clientHeight, w: clientWidth })
      // if(expanded === false){
      //   setExpanded(true)
      //   setPrevHeight(size.h)
      // }
    }
  }
  function getWidth() {
    if (open === true) {
      return "23%"
    }
    else {
      return "1%"
    }
  }

  if (updateState !== null && Object.keys(enumData).length > 0) {
    // && Object.keys(enumData).length > 0
    try {
      return (
        <Draggable
          handle="p"
          onStart={() => formClick()}
          onStop={positionHandler}
          position={position}
        // onMouseDown={() => formClick()}
        >
          <div
            id={reportsTableKey}
            className={classes.resizeForm}
            // style={getModalStyle()}
            onClick={() => sizeHandler()}
            style={{ height: formSize.h, width: formSize.w, top: "10%", left: "2%", transform: "translate(-10%, -2%)", zIndex: componentIndex }}
            key={reportsTableKey}
          // onMouseDown={() => setReportsTableKey(getUUID())}
          >
            <p className="main-table-label-p-style-new">
              <table className="dragble-div-header-table-style-new">
                <tr>
                  <td width="99%" className="dragble-div-header-td-style-new">{selectedReport.label}</td>
                  <td>
                    <IoMdClose size={22} className="form-close-icons-style" style={{ marginRight: "5px" }} onClick={() => buttonClick("close")} />
                  </td>
                </tr>
              </table>
            </p>
            <Grid>
              <Tabs selectedIndex={tabIndex} onSelect={(index) => clearForm(index)}>
                <TabList>
                  {reportTabs.map(tab => (
                    <Tab style={{ fontSize: 14 }}>{tab.name}</Tab>
                  ))}
                </TabList>
                {reportTabs.map(tab => (
                  <TabPanel>
                    <table align="left" style={{ borderCollapse: "collapse" }}>
                      <tr>
                        {reports.map(report => (
                          report.tab === tab.id &&
                          <td
                            align="center"
                            class={classes.reportStyle}
                            style={{ background: selectedReport.id === report.id ? "#D5E6E8" : "" }}
                            onMouseDown={() => selectReport(report)}
                          >
                            <BootstrapTooltip title={report.label}><div>{report.shortLabel}</div></BootstrapTooltip>
                          </td>
                        ))}
                      </tr>
                    </table>

                  </TabPanel>
                ))}
              </Tabs>

            </Grid>
            <table width="100%" style={{ borderCollapse: "collapse" }}>
              <tr>
                {selectedReport.id !== null && Form !== null &&
                  <td style={{ verticalAlign: "top" }} width={getWidth()} >
                    <Box sx={{ display: 'flex' }}>
                      <Drawer
                        sx={{
                          flexShrink: 0,
                          '& .MuiDrawer-paper': {
                            width: "27%",
                            boxSizing: 'border-box',
                            marginTop: "108px"
                          },
                        }}
                        variant="persistent"
                        anchor="left"
                        open={open}
                        onClose={handleDrawerClose}
                      >
                        <Divider />
                        <div style={{ height: "710px", overflow: "auto" }}>
                          {getForm()}
                        </div>
                      </Drawer>
                    </Box>
                  </td>
                }
                <td style={{ verticalAlign: "top" }} width="60%">
                  <table width="100%" style={{ borderCollapse: "collapse" }}>
                    <tr verticalAlign="center">
                      <td width="60%" align="center">
                        {open === true && selectedReport.id !== null &&
                          // <BootstrapTooltip title={"Скрыть параметры"} >
                          <IconButton onClick={handleDrawerClose} style={{ marginLeft: 40 }}>
                            <ArrowBackIosIcon />
                          </IconButton>
                          // </BootstrapTooltip>

                        }
                        {open === false &&
                          <IconButton>
                            <ArrowForwardIosIcon onClick={handleDrawerOpen} />
                          </IconButton>
                        }
                        {selectedReport.id !== null &&
                          buttons.map(button => (
                            <button
                              className="mainFormButton"
                              style={{
                                border: `1px solid ${button.backgroundColor}`,
                                background: button.backgroundColor,
                                color: button.fontColor
                              }}
                              onClick={() => buttonClick(button.name, null)}
                            >{button.label}
                            </button>
                          ))
                        }
                      </td>
                      <td width="30%" align="right">
                        <TextField
                          type="search"
                          variant="outlined"
                          size="small"
                          InputProps={{
                            startAdornment: <InputAdornment position="start"><BsSearch /></InputAdornment>,
                          }}
                          value={searchField}
                          onChange={handleSearchChange}
                        />
                      </td>
                    </tr>
                  </table>
                  <table width="100%">
                    <tr align="center" colspan="2">
                      <div style={{ height: getHeight(), overflow: "auto" }}>
                        {reportPages[selectedReport.reportName].map(p => (
                          <Markup content={p} />
                        ))}
                        {/* <Markup content={reportPages[selectedReport.reportName]} /> */}
                        {loading === true &&
                          <CircularProgress style={{ marginTop: 250 }} />
                        }
                        {/* </td> */}
                      </div>
                    </tr>
                  </table>
                </td>
                <td align="center" style={{ border: "1px solid grey", verticalAlign: "top" }} width="1%">
                  {showFull === false &&
                    <BootstrapTooltip title="Все страницы" >
                      <td ><AiOutlineFullscreen size={25} onMouseDown={() => fullClick()} style={{ cursor: "pointer" }} /></td>
                    </BootstrapTooltip>
                  }

                  {showFull === true &&
                    <BootstrapTooltip title="По страницам">
                      <div><AiOutlineFullscreenExit size={25} onMouseDown={() => exitFullClick()} style={{ cursor: "pointer", paddingTop: 2 }} /></div>
                    </BootstrapTooltip>
                  }
                  <table width="100%">
                    <tr>
                      <FormControl>
                        <GridSelect
                          onChange={handleChangeRowsPerPage}
                          style={{ height: 21, color: "#666666" }}
                          value={size}
                        >
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20].map(i => (
                            <MenuItem value={i}>{i}</MenuItem>
                          ))}
                        </GridSelect>
                      </FormControl>
                    </tr>
                    <tr align="center">
                      <input
                        style={{ width: "30px" }}
                        value={page}
                        onChange={handlePageChange}
                      />
                    </tr>

                    <BootstrapTooltip title="На предыдущую">
                      <tr align="center">
                        <AiOutlineUp size={25} style={{ color: "black", cursor: "pointer" }} onMouseDown={() => KeyboardArrowLeftClick(page)} />
                      </tr>
                    </BootstrapTooltip>
                    <tr align="center">
                      <div
                        style={{
                          cursor: "pointer",
                          fontSize: 14,
                          verticalAlign: "middle",
                          textAlign: "center",
                          height: "22px",
                          width: "22px",
                          color: page === 1 ? "white" : "black",
                          background: page === 1 ? "#4FA4AD" : "white"
                        }}
                        onClick={() => GoToPage(1)}
                      >
                        <div style={{ marginTop: "2px", marginRight: "1px" }}>1</div>
                      </div>
                    </tr>
                    {page > 8 ?
                      <>
                        <tr align="center">
                          <div
                            style={{
                              fontSize: 14,
                              verticalAlign: "middle",
                              textAlign: "center",
                              height: "22px",
                              width: "22px",
                              color: "black",
                              background: "white"
                            }}
                          >
                            ...
                          </div>
                        </tr>
                        {getPages(page - 3, page + 3)}
                      </>
                      :
                      getPages(2, 9)
                    }

                    {Math.floor(initialPages[selectedReport.reportName].length / size) - page > 2 && initialPages[selectedReport.reportName].length / size > 9 &&
                      <tr align="center">
                        <div
                          style={{
                            fontSize: 14,
                            verticalAlign: "middle",
                            textAlign: "center",
                            height: "22px",
                            width: "22px",
                            color: "black",
                            background: "white"
                          }}
                        >
                          ...
                        </div>
                      </tr>
                    }
                    {initialPages[selectedReport.reportName].length / size > 1 && getPage(Math.floor(initialPages[selectedReport.reportName].length / size))}
                    <BootstrapTooltip title="На следующую">
                      <tr align="center">
                        <AiOutlineDown size={25} style={{ color: "black", cursor: "pointer", paddingTop: 6 }} onMouseDown={() => KeyboardArrowRightClick(page)} />
                      </tr>
                    </BootstrapTooltip>

                  </table>
                </td>
              </tr>
            </table>
          </div>
        </Draggable>
      )
    } catch (er) {
      // console.log("ERROR", er)
      return <LinearProgress />
    }
  }
  else return <div>Loading...</div>
};

