import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
// Time
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

// import 'date-fns';
// import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';

// Icons
// import { IoMdClose } from 'react-icons/io';
import IconButton from '@material-ui/core/IconButton';
// import AddIcon from '@mui/icons-material/AddCircleOutline';
// import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
// CUSTOM COMPONENTS
// import ConfigurationFile from "../configuration/ConfigurationFile.json";
// Library
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import Draggable from 'react-draggable';
import swal from 'sweetalert'; // https://sweetalert.js.org/guides/
var moment = require('moment');
var fetch = require('node-fetch');

const useStyles = makeStyles((theme) => ({
  resizeForm: {
    borderRadius: "3px",
    border: "1px solid #2B4D80",
    display: "flex",
    backgroundColor: "white",
    boxShadow: theme.shadows[1],
    "flex-flow": "column",
    width: "40%",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "150px",
    position: 'absolute',
  }
  // modal: {
  //   position: 'absolute',
  //   width: 550,
  //   // height: 200,
  //   backgroundColor:"#eceff1", // theme.palette.background.paper,
  //   borderRadius: "7px",
  //   border: '1px solid #78909c',
  //   boxShadow: theme.shadows[1],
  //   padding: 4
  //   // theme.spacing(1, 1, 1),
  // }
}))
function FloatFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      decimalSeparator={"."}
      thousandSeparator={" "}
      isNumericString
    />
  )
}
FloatFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}
function IntegerFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={" "}
      isNumericString
    />
  );
}
IntegerFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default (props) => {
  const classes = useStyles()
  const [token] = useState(props.token)
  const [kseRESTApi] = useState(props.kseRESTApi) //Local KFB main REST
  const [userProfile, setUserProfile] = useState(props.userProfile)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [enumData, setEnumData] = useState({})
  const [enumOptions, setEnumOptions] = useState({})
  const [selectedOptions, setSelectedOptions] = useState({})

  const [tableKey, setTableKey] = useState(null)
  // FIELDS
  const [fieldValue, setFieldValue] = useState({
    financeInstrumentId: props.selectedInstrument.id !== null ? props.selectedInstrument.id : null,
    auctionTypeId: null,
    start: new Date("2022-12-31T09:30:00"),
    finish: new Date("2022-12-31T12:00:00"),
    startHH: null,
    startMM: null,
    finishHH: null,
    finishMM: null,
    calculateDate: null,
    closeDate: null,
    totalVolume: null,
    nonCompetitivePercent: 30
  })
  // const [time, setTime] = useState(null)
  const [position, setPosition] = useState({ x: 0, y: 0 })

  useEffect(async () => {
    console.log("OPEN AUCT TRADES PROPS", props)
    let enumDataToCollect = [
      { enumName: "financeInstrumentId", enumDef: "3e819d7e-25d0-4a04-a3ff-092fd348a375" },
      { enumName: "auctionTypeId", enumDef: "0e6becb1-c6f7-4cac-a69f-38ee986e111a" }
    ]
    let enums = await props.getEnumDataByList(enumDataToCollect)
    console.log("OPEN AUCT ENUMS", enums)
    setEnumData(enums)
    let eOpts = await props.createEnumOptions(enums)
    setEnumOptions(eOpts)
    let sOpts = { ...selectedOptions }
    if (props.selectedInstrument.id !== null) {
      let newFields = { ...fieldValue }
      let f = "financeInstrumentId"
      for (let d = 0; d < enums[f].length; d++) {
        if (enums[f][d].id === props.selectedInstrument.id) {
          sOpts[f] = { "value": enums[f][d].id, "label": enums[f][d].label, "name": f }
          newFields.calculateDate = enums[f][d].couponAiExcludingDate
          newFields.closeDate = enums[f][d].couponLastPayDate
        }
      }
      setFieldValue(newFields)
    }
    setSelectedOptions(sOpts)
    props.setComponentIndex(props.getComponentIndex() + 1)
  }, [])
  function swAllert(text, icon) {
    return (
      swal({
        text: text,
        icon: icon,
        buttons: { ok: "Ок" },
      })
    )
  }
  // random UUID generator
  function getUUID() {
    return uuidv4()
  }
  // INPUT HANDLERS
  // SELECT
  function handleSelectChange(option) {
    setSelectedOptions({ ...selectedOptions, [option.name]: option })
    console.log("OPT", option, fieldValue)
    setFieldValue({ ...fieldValue, [option.name]: option.value })
    if (option.name === "financeInstrumentId") {
      let newFields = { ...fieldValue }
      let f = "financeInstrumentId"
      for (let d = 0; d < enumData[f].length; d++) {
        if (enumData[f][d].id === option.value) {
          newFields.calculateDate = enumData[f][d].couponAiExcludingDate
          newFields.closeDate = enumData[f][d].couponLastPayDate
          newFields.financeInstrumentId = option.value
        }
      }
      setFieldValue(newFields)
    }
  }
  // INT
  const handleIntChange = (event) => {
    // console.log("EVENT", event.target.name, event.target.value)
    if (event.target.value !== "") {
      let stringNum = event.target.value.toString().replace(/ /g, '')
      let int = parseInt(stringNum)
      setFieldValue({ ...fieldValue, [event.target.name]: int })
    }
    else {
      setFieldValue({ ...fieldValue, [event.target.name]: null })
    }
  }
  function handleDateTimeChange(event) {
    let date = moment(event.target.value).format()
    setFieldValue({ ...fieldValue, [event.target.name]: date })
    console.log("DATE CHANGE", fieldValue)
  }
  function handleTimeChange(event) {
    console.log("TIME CHANGE", event.target.value)
  }
  async function openAuctionTrades() {
    // console.log("FFF", fieldValue)
    if (fieldValue.financeInstrumentId !== null) {
      if (fieldValue.calculateDate !== null) {
        if (fieldValue.closeDate !== null) {
          if (fieldValue.totalVolume !== null) {
            if (fieldValue.nonCompetitivePercent !== null) {
              let body = {
                "auctionTypeId": fieldValue.auctionTypeId,
                "startHH": parseInt(moment(fieldValue.start).format("HH")),
                "startMM": parseInt(moment(fieldValue.start).format("mm")),
                "finishHH": parseInt(moment(fieldValue.finish).format("HH")),
                "finishMM": parseInt(moment(fieldValue.finish).format("mm")),
                "financeInstrumentId": fieldValue.financeInstrumentId,
                "calculateDate": fieldValue.calculateDate,
                "closeDate": fieldValue.closeDate,
                "totalVolume": fieldValue.totalVolume,
                "nonCompetitivePercent": fieldValue.nonCompetitivePercent
              }
              console.log("BODY", body)
              await fetch(
                kseRESTApi + "/api/Auction/CreateNew",
                {
                  "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
                  "method": "POST",
                  "body": JSON.stringify(body)
                }
              )
                .then(response => response.json())
                .then(function (res) {
                  console.log("RES", res)
                  if (res.isSuccess === true) {
                    props.callSuccessToast("Аукцион открыт!")
                    props.setShowAuctionOpenTrades(false)
                    props.setUpdateAuction(getUUID())
                  }
                  else {
                    props.callErrorToast(res.errors[0])
                  }
                })
            } else { swAllert("Введите % не конкурентных!", "warning") }
          } else { swAllert("Введите объем эмиссии!", "warning") }
        } else { swAllert("Введите дату погашения!", "warning") }
      } else { swAllert("Введите дату расчёта!", "warning") }
    } else { swAllert("Введите инструмент!", "warning") }
  }
  function getModalStyle() {
    const top = 15;
    const left = 25;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      zIndex: componentIndex
    }
  }
  function formClick() {
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 1)
    props.setComponentIndex(currIndex + 1)
  }
  function positionHandler(e, data) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 30 // Take 30% of width
    let maxDeviationX = (dimension.w / 100) * 60 // Take 60% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    // console.log("minX", minDeviationX, "maxX", maxDeviationX, "maxY", maxDeviationY)
    if (data.y < maxDeviationY) {
      // console.log("SET MIN Y", data.x)
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      // console.log("SET MIN X", data.x, minDeviationX)
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      // console.log("SET MAX X", data.x)
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
  }
  return (
    // <Draggable
    //   handle="p"
    //   onStart={() => formClick()}
    //   onMouseDown={() => formClick()}
    // >
    //   <div style={getModalStyle()} className={classes.resizeForm} key={tableKey}>
    <Draggable
      key={tableKey}
      handle="p"
      onStart={() => formClick()}
      onStop={positionHandler}
      position={position}
    >
      <div
        className={classes.resizeForm}
        style={{ top: "15%", left: "30%", transform: "translate(-15%, -30%)", zIndex: componentIndex }}
        onClick={() => formClick()}
      >
        <p className="main-table-label-p-style-new">
          <table className="dragble-div-header-table-style-new">
            <tr>
              <td width="99%" sclassName="dragble-div-header-td-style-new">Создание аукциона</td>
              <td style={{ lineHeight: "2px" }} onClick={() => props.setShowAuctionOpenTrades(false)}><IconButton size="small"><CloseIcon /></IconButton></td>
            </tr>
          </table>
        </p>
        <div className="dragble-div-body-style-new">
          <div>
            <table width="100%">
              <tr>
                <td width="20%" className="dragble-div-body-td-text-style">Инструмент</td>
                <td>
                  <Select
                    width="80%"
                    name={"financeInstrumentId"}
                    placeholder={"Найти инструмент..."}
                    value={selectedOptions["financeInstrumentId"]}
                    onChange={handleSelectChange}
                    options={enumOptions["financeInstrumentId"]}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  />
                </td>
              </tr>
              <tr>
                <td width="20%" className="dragble-div-body-td-text-style">Тип</td>
                <td>
                  <Select
                    width="80%"
                    name={"auctionTypeId"}
                    placeholder={"Найти тип..."}
                    value={selectedOptions["auctionTypeId"]}
                    onChange={handleSelectChange}
                    options={enumOptions["auctionTypeId"]}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  />
                </td>
              </tr>
            </table>
            <table align="center" width="100%">
              <tr>
                <td width="40%" className="dragble-div-body-td-text-style">Начало</td>
                <td width="60%">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      ampm={false}
                      openTo="hours"
                      views={['hours', 'minutes']}
                      inputFormat="HH:mm"
                      mask="__:__"
                      value={fieldValue.start}
                      onChange={(newValue) => {
                        setFieldValue({ ...fieldValue, ["start"]: newValue.$d })
                      }}
                      renderInput={(params) => <TextField {...params} size="small" style={{ width: "160px" }} />}
                    />
                  </LocalizationProvider>
                </td>
              </tr>
              <tr>
                <td width="40%" className="dragble-div-body-td-text-style">Конец</td>
                <td width="60%">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      ampm={false}
                      openTo="hours"
                      views={['hours', 'minutes']}
                      inputFormat="HH:mm"
                      mask="__:__"
                      value={fieldValue.finish}
                      onChange={(newValue) => {
                        setFieldValue({ ...fieldValue, ["finish"]: newValue.$d })
                      }}
                      renderInput={(params) => <TextField {...params} size="small" style={{ width: "160px" }} />}
                    />
                  </LocalizationProvider>
                </td>
              </tr>
              {/* Дата расчёта */}
              <tr>
                <td className="dragble-div-body-td-text-style">Дата расчёта</td>
                <td style={{ fontSize: "14px" }}>
                  <TextField
                    size="small"
                    type="date"
                    name="calculateDate"
                    onChange={handleDateTimeChange}
                    value={moment(fieldValue.calculateDate).format('YYYY-MM-DD')}
                    style={{ width: "160px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </td>
              </tr>
              {/* Дата погашения */}
              <tr>
                <td className="dragble-div-body-td-text-style">Дата погашения</td>
                <td style={{ fontSize: "14px" }}>
                  <TextField
                    size="small"
                    type="date"
                    name="closeDate"
                    onChange={handleDateTimeChange}
                    value={moment(fieldValue.closeDate).format('YYYY-MM-DD')}
                    style={{ width: "160px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </td>
              </tr>
              {/* Объем эмиссии */}
              <tr>
                <td className="dragble-div-body-td-text-style">Объем эмиссии</td>
                <td>
                  <TextField
                    size="small"
                    // variant="standart"
                    name="totalVolume"
                    placeholder="0"
                    value={fieldValue.totalVolume}
                    onChange={handleIntChange}
                    style={{ width: "160px" }}
                    InputProps={{ inputComponent: IntegerFormat }}
                  />
                </td>
              </tr>
              {/* Из них не конкурентных (%) */}
              <tr>
                <td className="dragble-div-body-td-text-style">Из них не конкурентных (%)</td>
                <td>
                  <TextField
                    size="small"
                    // variant="standart"
                    name="nonCompetitivePercent"
                    placeholder="0"
                    value={fieldValue.nonCompetitivePercent}
                    onChange={handleIntChange}
                    style={{ width: "160px" }}
                    InputProps={{ inputComponent: IntegerFormat }}
                  />
                </td>
              </tr>
            </table>
            <table align="center" width="100%">
              <tr>
                <td align="center">
                  <button
                    className="cancelButton"
                    style={{ marginRight: 20 }}
                    onClick={() => props.setShowAuctionOpenTrades(false)}
                  >
                    Отмена
                  </button>
                  <button
                    className="approveButton"
                    onClick={() => openAuctionTrades()}
                    style={{ marginRight: 20 }}
                  >
                    Открыть аукцион
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </Draggable>
  )
}