import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Checkbox from '@material-ui/core/Checkbox';
// Icons
import { IoMdClose } from 'react-icons/io';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { FaLongArrowAltUp } from 'react-icons/fa';
import { FaLongArrowAltDown } from 'react-icons/fa';


// CUSTOM COMPONENTS
import ConfigurationFile from "../configuration/ConfigurationFile.json";
// Library
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import Draggable from 'react-draggable';

const useStyles = makeStyles((theme) => ({
  resizeForm: {
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    border: "1px solid #2B4D80",
    display: "flex",
    backgroundColor: "#F5F5F5",
    boxShadow: theme.shadows[1],
    "flex-flow": "column",
    width: "40%",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "150px",
    position: 'absolute',
  },
}))

//Home(props) - получаем переменные от родителя App.js 
export default (props) => {
  const classes = useStyles()
  const [token] = useState(props.token)
  const [kseRESTApi] = useState(props.kseRESTApi) //Local KFB main REST
  const [userProfile, setUserProfile] = useState(props.userProfile)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [columns, setColumns] = useState([])
  const [selectedColumn, setSelectedColumn] = useState(null)
  const [fieldValue, setFieldValue] = useState({})
  const [position, setPosition] = useState({ x: 0, y: 0 })

  useEffect(async () => {
    console.log("FORM MGMNT PROPS", props)
    setColumns(props.settings.columns)
    props.setComponentIndex(props.getComponentIndex() + 1)
  }, [])
  // random UUID generator
  function getUUID() {
    return uuidv4()
  }
  // INPUT HANDLERS
  function handleCheckboxChange(event) {
    // setFieldValue({ ...fieldValue, [event.target.name]: event.target.checked })
    console.log("EV", event.target.name, event.target.checked)
    let newColumns = [...columns]
    for (let c = 0; c < columns.length; c++) {
      if (event.target.name === columns[c].name) {
        newColumns[c] = {
          name: columns[c].name,
          label: columns[c].label,
          show: event.target.checked
        }
      }
    }
    setColumns(newColumns)
  }
  function columnUp() {
    let newColumns = [...columns]
    for (let c = 0; c < columns.length; c++) {
      if (selectedColumn === columns[c].name) {
        if (c > 0) {
          let prevColumn = columns[c - 1]
          let currColumn = columns[c]
          newColumns[c - 1] = currColumn
          newColumns[c] = prevColumn
          break
        }
      }
    }
    setColumns(newColumns)
  }
  function columnDown() {
    let newColumns = [...columns]
    for (let c = 0; c < columns.length; c++) {
      if (selectedColumn === columns[c].name) {
        if (c < columns.length - 1) {
          let nextColumn = columns[c + 1]
          let currColumn = columns[c]
          newColumns[c + 1] = currColumn
          newColumns[c] = nextColumn
        }
      }
    }
    setColumns(newColumns)
  }
  async function updateUserSettings() {
    let newSettings = {
      id: props.settings.id,
      type: props.settings.type,
      columns: columns
    }
    // console.log("N SET", newSettings)
    props.updateUserSettingsByType(newSettings)
    props.closeFormManagement(props.settings.id)
    if (props.settings.type === "instruments") {
      props.setUpdateFinanceInstrumentsHead(getUUID())
    }
    else if (props.settings.type === "bids") {
      props.setUpdateBidsHead(getUUID())
    }
  }
  function getModalStyle() {
    const top = 10;
    const left = 25;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      zIndex: componentIndex
    };
  }
  function formClick() {
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 1)
    props.setComponentIndex(currIndex + 1)
  }
  function positionHandler(e, data) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 20 // Take 20% of width
    let maxDeviationX = (dimension.w / 100) * 70 // Take 70% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    // console.log("minX", minDeviationX, "maxX", maxDeviationX, "maxY", maxDeviationY)
    if (data.y < maxDeviationY) {
      // console.log("SET MIN Y", data.x)
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      // console.log("SET MIN X", data.x, minDeviationX)
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      // console.log("SET MAX X", data.x)
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
  }
  return (
    // <Draggable
    //   handle="p"
    //   onStart={() => formClick()}
    //   onMouseDown={() => formClick()}
    // >
    //   <div style={getModalStyle()} className={classes.resizeForm}>
    <Draggable
      handle="p"
      onStart={() => formClick()}
      onStop={positionHandler}
      position={position}
    >
      <div
        className={classes.resizeForm}
        style={{ top: "15%", left: "20%", transform: "translate(-15%, -20%)", zIndex: componentIndex }}
        onClick={() => formClick()}
      >
        <p className="main-table-label-p-style-new">
          <table className="dragble-div-header-table-style-new">
            <tr>
              <td width="99%" className="dragble-div-header-td-style-new">Настройка столбцов</td>
              <td>
                <IoMdClose size={22} className="form-close-icons-style" style={{ marginRight: "5px" }} onClick={() => props.closeFormManagement(props.settings.id)} />
              </td>
            </tr>
          </table>
        </p>
        <div className="dragble-div-body-style">
          <div>
            <table align="center" width="100%">
              <tr>
                <td width="30%" align="center">
                  <Button
                    variant="contained"
                    onClick={() => columnUp()}
                    style={{
                      color: "#418991",
                      margin: 7,
                      fontSize: 25,
                      width: "70%"
                    }}
                  >
                    <FaLongArrowAltUp />
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => columnDown()}
                    style={{
                      color: "#418991",
                      margin: 7,
                      fontSize: 25,
                      width: "70%"
                    }}
                  >
                    <FaLongArrowAltDown />
                  </Button>
                </td>
                <td width="70%" style={{ border: "1px solid grey" }}>
                  <div style={{ height: "300px", overflow: "auto" }}>
                    {columns.map(column =>
                      <div
                        onClick={() => setSelectedColumn(column.name)}
                        style={{ background: selectedColumn === column.name ? "#C6C6C6" : null, paddingBottom: 0, paddingLeft: 10 }}
                      >
                        <Checkbox
                          // size="small"
                          name={column.name}
                          checked={column.show}
                          style={{ maxWidth: 20, height: 15, color: column.show === false ? "grey" : "#418991", paddingRight: 20 }}
                          onChange={handleCheckboxChange}
                        />
                        {column.label}
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            </table>
            <table align="center" width="100%">
              <tr>
                <td width="100%" align="center">
                  <button
                    className="cancelButton"
                    style={{ marginRight: 20 }}
                    onClick={() => props.closeFormManagement(props.settings.id)}
                  >
                    Отмена
                  </button>
                  <button
                    className="approveButton"
                    onClick={() => updateUserSettings()}
                    style={{ marginRight: 20 }}
                  >
                    Сохранить
                  </button>
                </td>
                {/* <td>
                  <Button
                    variant="contained"                      
                    onClick = {()=> updateUserSettings()}
                    style={{
                      margin: 3,
                      height: 30,
                      fontSize: 12,
                      color: "white",
                      backgroundColor: "#2862F4"
                    }}
                  >Сохранить
                  </Button>
                  <Button
                      variant="contained"                         
                      onClick = {()=> props.setShowOrderBuy(false)}
                      style={{
                        margin: 3,
                        height: 30,
                        fontSize: 12,
                        color: "white",
                        backgroundColor: "#E73639"
                      }}
                    >Отмена
                  </Button>
                </td> */}
              </tr>
            </table>
          </div>
        </div>
      </div>
    </Draggable>
  )
}