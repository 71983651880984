import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
// Radio Group
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

// Icons
// import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';
// import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { IoMdClose } from 'react-icons/io';
// CUSTOM COMPONENTS
// import ConfigurationFile from "../configuration/ConfigurationFile.json";
// Library
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import Draggable from 'react-draggable';
import swal from 'sweetalert'; // https://sweetalert.js.org/guides/
var moment = require('moment');
var fetch = require('node-fetch');

const useStyles = makeStyles((theme) => ({
  resizeForm: {
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    border: "1px solid #2B4D80",
    display: "flex",
    backgroundColor: "white",
    boxShadow: theme.shadows[1],
    "flex-flow": "column",
    width: "80%",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "150px",
    position: 'absolute',
  }
}))
function FloatFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      decimalSeparator={"."}
      thousandSeparator={" "}
      isNumericString
    />
  )
}
FloatFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
function IntegerFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={" "}
      isNumericString
    />
  );
}
IntegerFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

//Home(props) - получаем переменные от родителя App.js 
export default (props) => {
  const classes = useStyles()
  const [token] = useState(props.token)
  const [kseRESTApi] = useState(props.kseRESTApi) //Local KFB main REST
  const [userProfile, setUserProfile] = useState(props.userProfile)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [enumData, setEnumData] = useState({})
  const [enumOptions, setEnumOptions] = useState({})
  const [selectedOptions, setSelectedOptions] = useState({})
  const [bidType, setBidType] = useState("limited")
  const [tableKey, setTableKey] = useState(null)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  // FIELDS
  const [selectedBid] = useState(props.selectedBid)
  // const [fieldValue, setFieldValue] = useState({})
  const [fieldValue, setFieldValue] = useState({
    organizationId: userProfile.organization.id,
    recipientOrganizationId: null,
    instrumentCode: props.selectedBid.instrumentCode,
    currency: 1,
    bidDirection: 0,
    bidDirectionClose: 1,
    openPrice: null,
    confirmOpenPrice: null,
    amount: null,
    confirmAmount: null,
    closeDate: null,
    confirmCloseDate: null,
    closePrice: null,
    confirmClosePrice: null,
    profit: 0
  })
  const [updateState, setUpdateState] = useState(false)
  useEffect(async () => {
    console.log("ACCEPT REPO ORDER PROPS", props)
    // console.log("REPO", props.selectedBid)
    let enumDataToCollect = [
      { enumName: "financeInstrumentId", enumDef: "3e819d7e-25d0-4a04-a3ff-092fd348a375" },
      { enumName: "confirmAccountId", enumDef: "c324d86f-3a3b-43b2-9514-d983b2982794" },
      { enumName: "organizationId", enumDef: "5b78d9dd-821d-4c6a-a00a-3af85224fbc4" },
      { enumName: "recipientOrganizationId", enumDef: "5b78d9dd-821d-4c6a-a00a-3af85224fbc4" },
    ]
    let enums = await props.getEnumDataByList(enumDataToCollect)
    // console.log("ACC REPO ENUMS", enums)
    setEnumData(enums)
    let eOpts = await props.createEnumOptions(enums)

    setEnumOptions(eOpts)
    let sOpts = { ...selectedOptions }
    let n = "organizationId"
    for (let d = 0; d < enums[n].length; d++) {
      if (enums[n][d].id === selectedBid.organizationId) {
        sOpts[n] = { "value": enums[n][d].id, "label": enums[n][d].label, "name": n }
      }
    }
    setSelectedOptions(sOpts)
    let newFields = { ...selectedBid }
    newFields.bidDirection = selectedBid.direction === "B" ? 0 : 1
    newFields.bidDirectionClose = selectedBid.direction === "B" ? 1 : 0
    newFields.currency = 1
    newFields.confirmAccountId = null
    newFields.amount = selectedBid.amount
    newFields.closeDate = selectedBid.closeDate
    console.log("S R ORDER", newFields)
    setFieldValue(newFields)
    props.setComponentIndex(props.getComponentIndex() + 1)
    setTableKey(getUUID())
  }, [])
  function swAllert(text, icon) {
    return (
      swal({
        text: text,
        icon: icon,
        buttons: { ok: "Ок" },
      })
    )
  }
  // random UUID generator
  function getUUID() {
    return uuidv4()
  }
  // INPUT HANDLERS
  // SELECT
  function handleSelectChange(option) {
    setSelectedOptions({ ...selectedOptions, [option.name]: option })
    setFieldValue({ ...fieldValue, [option.name]: option.value })
    console.log("OPT", option, fieldValue)
  }
  // INT
  // const handleIntChange = (event) => {
  //   console.log("EVENT", event.target.name, event.target.value)
  //   if(event.target.value !== ""){
  //     let stringNum = event.target.value.toString().replace(/ /g,'')
  //     let int = parseInt(stringNum)
  //     setFieldValue({ ...fieldValue, [event.target.name]: int })
  //   }
  //   else{
  //     setFieldValue({ ...fieldValue, [event.target.name]: event.target.value })
  //   }
  // }
  function handleDateTimeChange(event) {
    let name = event.target.name
    let value = event.target.value
    let date = moment(value).format()
    setFieldValue({ ...fieldValue, [name]: date })
    setTableKey(getUUID())
  }
  function calculateProfit(closeDate) {
    let year = moment(new Date()).format("YYYY")
    let daysInYear = ((year % 4 === 0 && year % 100 > 0) || year % 400 == 0) ? 366 : 365
    // console.log("daysInYear", daysInYear)

    let openCloseDateDifference = dateDifference(new Date(), closeDate)
    // console.log("DIFF", openCloseDateDifference)

    let p = ((fieldValue.closePrice / fieldValue.openPrice) * (daysInYear * 100) - (daysInYear * 100)) / openCloseDateDifference
    // console.log("PROFIT", p)
    return p
  }
  function dateDifference(open, close) {
    let d1 = moment(open).format("MM/DD/YYYY")
    let d2 = moment(close).format("MM/DD/YYYY")
    var date1 = new Date(d1) // "06/30/2019"
    var date2 = new Date(d2) // "07/30/2019"
    // To calculate the time difference of two dates
    var differenceInTime = date2.getTime() - date1.getTime()
    // To calculate the no. of days between two dates
    var differenceInDays = differenceInTime / (1000 * 3600 * 24)
    return differenceInDays
  }
  function handleRadionGroupChange(event) {
    // console.log("R CH", event.target.name, event.target.value)
    let name = event.target.name
    let value = parseInt(event.target.value)
    if (name === "bidDirection") {

      let newFields = { ...fieldValue }
      newFields["bidDirection"] = value
      newFields["bidDirectionClose"] = value === 1 ? 0 : 1
      setFieldValue(newFields)
    }
    else {
      setFieldValue({ ...fieldValue, [name]: value })
    }
    setUpdateState(getUUID())
  }

  // Float input handler
  const handleFloatChange = (event) => {
    let newFields = fieldValue
    if (event.target.value !== "") {
      let val = event.target.value.replace(/ /g, '')
      newFields[event.target.name] = parseFloat(val)
      if (fieldValue.closeDate !== null) {
        newFields.profit = calculateProfit(fieldValue.closeDate)
      }
      setFieldValue(newFields)
    }
    else {
      setFieldValue({ ...fieldValue, [event.target.name]: null })
    }
    // setTableKey(getUUID())
    
    // newFields[event.target.name] = parseFloat(event.target.value)
    // if (fieldValue.closeDate !== null) {
    //   newFields.profit = calculateProfit(fieldValue.closeDate)
    // }
    // setFieldValue(newFields)
    // setTableKey(getUUID())
  }
  async function acceptRepoOrder(accepted) {
    console.log("ACC", fieldValue)
    if (fieldValue.confirmAccountId !== null) {
      if (fieldValue.confirmOpenPrice === fieldValue.openPrice) {
        if (fieldValue.confirmAmount === fieldValue.amount) {
          if (fieldValue.confirmClosePrice === fieldValue.closePrice) {
            if (moment(fieldValue.confirmCloseDate).format("YYYY-MM-DD") === moment(fieldValue.closeDate).format("YYYY-MM-DD")) {
              console.log("CHECKED")
              let body = {
                "requestId": selectedBid.repoId,
                "accountId": fieldValue.confirmAccountId,
                "closeDate": fieldValue.confirmCloseDate,
                "closePrice": fieldValue.confirmClosePrice,
                "accepted": accepted
              }
              console.log("BODY", body)
              await fetch(
                kseRESTApi + "/api/Trading/AcceptRepoRequest",
                {
                  "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
                  "method": "POST",
                  "body": JSON.stringify(body)
                }
              )
                .then(response => response.json())
                .then(function (res) {
                  console.log("RES CREATE", res)
                  if (res.isSuccess === true) {
                    props.callSuccessToast("РЕПО удовлетворено!", 1100)
                    props.setShowAcceptRepoOrder(false)
                    props.setUpdateRepoOrderList(getUUID())
                    props.setUpdateBidsBody(getUUID())
                  }
                  else {
                    props.callErrorToast(res.errors[0])
                  }
                })
            } else { props.callErrorToast("Дата подтверждения не совпадает с датой во входящем предложении!", "warning") }
          } else { props.callErrorToast("Цена закрытия не совпадает с ценой закрытия во входящем предложении!", "warning") }
        } else { props.callErrorToast("Количество не совпадает с количеством во входящем предложении!", "warning") }
      } else { props.callErrorToast("Цена не совпадает с ценой открытия во входящем предложении!", "warning") }
    } else { props.callErrorToast("Введите торговый счёт!", "warning") }
  }
  function getModalStyle() {
    const top = 15;
    const left = 5;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      zIndex: componentIndex
    };
  }
  function formClick() {
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 1)
    props.setComponentIndex(currIndex + 1)
  }
  function positionHandler(e, data) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 10 // Take 10% of width
    let maxDeviationX = (dimension.w / 100) * 80 // Take 80% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    // console.log("minX", minDeviationX, "maxX", maxDeviationX, "maxY", maxDeviationY)
    if (data.y < maxDeviationY) {
      // console.log("SET MIN Y", data.x)
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      // console.log("SET MIN X", data.x)
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      // console.log("SET MAX X", data.x)
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
  }

  return (
    // <Draggable
    //   handle="p"
    //   onStart={() => formClick()}
    //   onStop={positionHandler}
    //   position={position}
    // // onMouseDown={() => formClick()}
    // >
    //   <div style={getModalStyle()} className={classes.resizeForm} onClick={() => formClick()}>
    <Draggable
      key={tableKey}
      handle="p"
      onStart={() => formClick()}
      onStop={positionHandler}
      position={position}
    >
      <div
        className={classes.resizeForm}
        style={{ top: "15%", left: "10%", transform: "translate(-15%, -10%)", zIndex: componentIndex }}
        onClick={() => formClick()}
      >
        <p className="main-table-label-p-style-new">
          <table className="dragble-div-header-table-style-new">
            <tr>
              <td width="99%" className="dragble-div-header-td-style-new">REPO заявка</td>
              <td>
                <IoMdClose size={22} className="form-close-icons-style" style={{ marginRight: "5px" }} onClick={() => props.setShowAcceptRepoOrder(false)} />
              </td>
            </tr>
          </table>
        </p>
        <div className="dragble-div-body-style">
          <table align="center" width="100%" key={tableKey}>
            <tr>
              <td width="40%" style={{ fontWeight: "bold", fontSize: 22, paddingLeft: 5 }}>
                {props.selectedBid.instrumentCode}
              </td>
              <td width="15%" className="dragble-div-body-td-text-style">Контрагент</td>
              <td width="35%" height="10%">
                <Select
                  name={"organizationId"}
                  placeholder={"Выбрать..."}
                  value={selectedOptions["organizationId"]}
                  onChange={handleSelectChange}
                  options={enumOptions["organizationId"]}
                  isDisabled
                />
              </td>
            </tr>
          </table>
          <th>Входящее предложение:</th>
          <div overflow="auto">
            <table>
              <tr>
                <td width="80%">
                  <table width="100%" style={{ border: "1px solid grey" }}>
                    <tr>
                      <td rowSpan={2} className="dragble-div-body-td-text-style" style={{ border: "1px solid grey" }}>
                        <FormControl component="fieldset" key={updateState + "bidDirection"}>
                          <FormLabel component="legend">Направл.</FormLabel>
                          <RadioGroup
                            name="bidDirection"
                            value={fieldValue["bidDirection"]}
                            onChange={handleRadionGroupChange}
                          >
                            <FormControlLabel disabled style={{ marginTop: -8 }} value={0} control={<Radio />} label="Пок." />
                            <FormControlLabel disabled style={{ marginTop: -20 }} value={1} control={<Radio />} label="Прод." />
                          </RadioGroup>
                        </FormControl>
                      </td>
                      <td>
                        <tr className="properties-td-text-style">Цена откр.</tr>
                        <tr>
                          <TextField
                            variant="outlined"
                            placeholder="0.0"
                            size="small"
                            value={fieldValue.openPrice}
                            style={{ width: "100%" }}
                            InputProps={{ inputComponent: FloatFormat }}
                            disabled
                          />
                        </tr>
                      </td>
                      <td>
                        <tr className="properties-td-text-style">Количество</tr>
                        <tr>
                          <TextField
                            variant="outlined"
                            placeholder="0.0"
                            size="small"
                            value={fieldValue.amount}
                            style={{ width: "100%" }}
                            InputProps={{ inputComponent: FloatFormat }}
                            disabled
                          />
                        </tr>
                      </td>
                      <td>
                        <tr className="properties-td-text-style">Цена закр.</tr>
                        <tr>
                          <TextField
                            variant="outlined"
                            placeholder="0.0"
                            size="small"
                            value={fieldValue.closePrice}
                            style={{ width: "100%" }}
                            InputProps={{ inputComponent: FloatFormat }}
                            disabled
                          />
                        </tr>
                      </td>
                      <td>
                        <tr className="properties-td-text-style">Дата закр.</tr>
                        <tr>
                          <td>
                            <TextField
                              type="date"
                              name="closeDate"
                              onChange={handleDateTimeChange}
                              // style={{width: "80%"}}
                              value={(fieldValue["closeDate"] !== null) ? moment(fieldValue["closeDate"]).format('YYYY-MM-DD') : ""}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              disabled
                            />
                          </td>
                        </tr>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <tr className="properties-td-text-style">Объем откр.</tr>
                        <tr>
                          <TextField
                            variant="outlined"
                            placeholder="0.0"
                            size="small"
                            value={fieldValue.openSum}
                            style={{ width: "100%" }}
                            InputProps={{ inputComponent: FloatFormat }}
                            disabled
                          />
                        </tr>
                      </td>
                      <td>
                        <tr className="properties-td-text-style">Объем закр.</tr>
                        <tr>
                          <TextField
                            variant="outlined"
                            placeholder="0.0"
                            size="small"
                            value={fieldValue.closeSum}
                            style={{ width: "100%" }}
                            InputProps={{ inputComponent: FloatFormat }}
                            disabled
                          />
                        </tr>
                      </td>
                      <td></td>
                      <td>
                        <tr className="properties-td-text-style">Доходность репо</tr>
                        <tr>
                          <TextField
                            variant="outlined"
                            placeholder="0.0"
                            size="small"
                            name="closeSum"
                            value={fieldValue.profit.toFixed(4)}
                            style={{ width: "100%" }}
                            InputProps={{ inputComponent: FloatFormat }}
                            disabled
                          />
                        </tr>
                      </td>
                    </tr>
                  </table>
                </td>
                <td width="20%">
                  <table align="center" width="100%" style={{ border: "1px solid grey" }}>
                    <tr>
                      <td className="dragble-div-body-td-text-style">
                        <FormControl component="fieldset" key={updateState + "priceForOpenSession"}>
                          <FormLabel component="legend">Валюты.</FormLabel>
                          <RadioGroup
                            name="currency"
                            value={fieldValue["currency"]}
                            onChange={handleRadionGroupChange}
                          >
                            <FormControlLabel disabled style={{ marginTop: -8 }} value={1} control={<Radio />} label="Сомы" />
                            <FormControlLabel disabled style={{ marginTop: -20 }} value={2} control={<Radio />} label="Валюта торгов" />
                          </RadioGroup>
                        </FormControl>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>
          <th>Подтверждение:</th>
          <div overflow="auto">
            <table align="center" width="100%" key={tableKey}>
              <tr>
                <td width="15%" className="dragble-div-body-td-text-style">Торговый счёт</td>
                <td width="35%">
                  <Select
                    name={"confirmAccountId"}
                    placeholder={"Выбрать..."}
                    value={selectedOptions["confirmAccountId"]}
                    onChange={handleSelectChange}
                    options={enumOptions["confirmAccountId"]}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  />
                </td>
                <td width="50%"></td>
              </tr>
            </table>
            <table>
              <tr>
                <td width="80%">
                  <table width="100%" style={{ border: "1px solid grey" }}>
                    <tr>
                      <td rowSpan={2} className="dragble-div-body-td-text-style" style={{ border: "1px solid grey" }}>
                        <FormControl component="fieldset" key={updateState + "bidDirectionClose"}>
                          <FormLabel component="legend">Направл.</FormLabel>
                          <RadioGroup
                            name="bidDirectionClose"
                            value={fieldValue["bidDirectionClose"]}
                            onChange={handleRadionGroupChange}
                          >
                            <FormControlLabel disabled style={{ marginTop: -8 }} value={0} control={<Radio />} label="Пок." />
                            <FormControlLabel disabled style={{ marginTop: -20 }} value={1} control={<Radio />} label="Прод." />
                          </RadioGroup>
                        </FormControl>
                      </td>
                      <td>
                        <tr className="properties-td-text-style">Цена</tr>
                        <tr>
                          <TextField
                            variant="outlined"
                            placeholder="0.0"
                            size="small"
                            name="confirmOpenPrice"
                            value={fieldValue.confirmOpenPrice}
                            onChange={handleFloatChange}
                            style={{ width: "100%" }}
                            InputProps={{ inputComponent: FloatFormat }}
                          />
                        </tr>
                      </td>
                      <td>
                        <tr className="properties-td-text-style">Количество</tr>
                        <tr>
                          <TextField
                            variant="outlined"
                            placeholder="0.0"
                            size="small"
                            name="confirmAmount"
                            value={fieldValue.confirmAmount}
                            onChange={handleFloatChange}
                            style={{ width: "100%" }}
                            InputProps={{ inputComponent: FloatFormat }}
                          />
                        </tr>
                      </td>
                      <td>
                        <tr className="properties-td-text-style">Цена закр.</tr>
                        <tr>
                          <TextField
                            variant="outlined"
                            placeholder="0.0"
                            size="small"
                            name="confirmClosePrice"
                            value={fieldValue.confirmClosePrice}
                            onChange={handleFloatChange}
                            style={{ width: "100%" }}
                            InputProps={{ inputComponent: FloatFormat }}
                          />
                        </tr>
                      </td>
                      <td>
                        <tr className="properties-td-text-style">Дата закр.</tr>
                        <tr>
                          <td>
                            <TextField
                              type="date"
                              variant="outlined"
                              size="small"
                              name="confirmCloseDate"
                              onBlur={handleDateTimeChange}
                              // style={{ width: "80%" }}
                              // defaultValue={moment(fieldValue[contentItem.name]).format('YYYY-MM-DD')}
                              defaultValue={(fieldValue.confirmCloseDate !== undefined && fieldValue.confirmCloseDate !== null) ? moment(fieldValue.confirmCloseDate).format('YYYY-MM-DD') : ""}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            {/* <TextField
                              type="date"
                              name="confirmCloseDate"
                              onChange={handleDateTimeChange}
                              // style={{width: "80%"}}
                              defaultValue={(fieldValue["confirmCloseDate"] !== null) ? moment(fieldValue["confirmCloseDate"]).format('YYYY-MM-DD') : null}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            /> */}
                          </td>
                        </tr>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <tr className="properties-td-text-style">Объем откр.</tr>
                        <tr>
                          <TextField
                            variant="outlined"
                            placeholder="0.0"
                            size="small"
                            value={fieldValue.openSum}
                            style={{ width: "100%" }}
                            InputProps={{ inputComponent: FloatFormat }}
                            disabled
                          />
                        </tr>
                      </td>
                      <td>
                        <tr className="properties-td-text-style">Объем закр.</tr>
                        <tr>
                          <TextField
                            variant="outlined"
                            placeholder="0.0"
                            size="small"
                            value={fieldValue.closeSum}
                            style={{ width: "100%" }}
                            InputProps={{ inputComponent: FloatFormat }}
                            disabled
                          />
                        </tr>
                      </td>
                      <td></td>
                      <td>
                        <tr className="properties-td-text-style">Доходность репо</tr>
                        <tr>
                          <TextField
                            variant="outlined"
                            placeholder="0.0"
                            size="small"
                            value={(fieldValue.profit).toFixed(4)}
                            style={{ width: "100%" }}
                            InputProps={{ inputComponent: FloatFormat }}
                            disabled
                          />
                        </tr>
                      </td>
                    </tr>
                  </table>
                </td>
                <td width="20%"></td>
              </tr>
            </table>
          </div>
          <table width="100%">
            <tr>
              <td width="100%" align="center">
                <button
                  className="cancelButton"
                  onClick={() => acceptRepoOrder(false)}
                  style={{ marginRight: 20 }}
                >
                  Отклонить
                </button>
                <button
                  className="approveButton"
                  style={{ marginRight: 20 }}
                  onClick={() => acceptRepoOrder(true)}
                >
                  Удовлетворить
                </button>
              </td>
            </tr>
          </table>
          {/* <table align="center" width="100%">
            <tr>
              <td>
                <Button
                  variant="contained"
                  onClick={() => acceptRepoOrder(true)}
                  style={{
                    margin: 3,
                    height: 30,
                    fontSize: 12,
                    color: "white",
                    backgroundColor: "#2862F4"
                  }}
                >Удовлетворить
                </Button>
                <Button
                  variant="contained"
                  onClick={() => acceptRepoOrder(false)}
                  style={{
                    margin: 3,
                    height: 30,
                    fontSize: 12,
                    color: "white",
                    backgroundColor: "#FF5F43"
                  }}
                >Отклонить
                </Button>
                <Button
                  variant="contained"
                  onClick={() => props.setShoRepoOrder(false)}
                  style={{
                    margin: 3,
                    height: 30,
                    fontSize: 12,
                    color: "white",
                    backgroundColor: "#E73639"
                  }}
                >Отмена
                </Button>
              </td>
            </tr>
          </table> */}
        </div>
      </div>
    </Draggable>
  )
}