import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from '@material-ui/core';
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import Card from '@material-ui/core/Card';
import background from "./Images/LoginPageBackground.webp";
import logo from "./Images/logo.png";

// Libs
var fetch = require('node-fetch');

const useStyles = makeStyles((theme) => ({
  typographySty: {
    border: "1px solid #212121",
    borderRadius: "10px",
    background: "#1E00A5"
  },
  card: {
    margin: "4px",
    borderRadius: "4px",
    border: "1px solid #424242",
    background: "#F8FCFE"
  },
  // loginText: {
  //   // padding:"1px", 
  //   margin: "12px",
  //   border: "solid 1px #1E00A5",
  //   fontFamily: "Garamond",
  //   color: "#170564",
  //   textAlign: "center"
  // },
  loginText:{
    paddingLeft: "15%",
    fontFamily: "Helvetica",
    fontWeight: "bold", 
  },
  // kseText: {
  //   // margin: "12px", 
  //   fontFamily: "Helvetica",
  //   color: "#F7F6FB",
  //   textAlign: "left",
  //   fontSize: 40,
  //   letterSpacing: "0.2em",
  //   textShadow: "#0E0D11 30px 20px",
  //   width: "40%"
  // },
  kseText:{
    margin: "0px", 
    fontFamily: "Helvetica",
    color: "#4A99A1",
    textAlign: "center",
    fontSize: 60,
  },
  textField: {
    margin: "20px",
    width: "30ch",
    border: "solid 1px #e0e0e0",
    background: "#FFFAFA",
    borderRadius: "10px",
  },
  formControl: {
    borderRadius: "10px",
    border: "solid 1px #e0e0e0",
    background: "#FFFAFA",
    width: "30ch"
  },
  // btnSave: {
  //   color: "white",
  //   border: "solid 1px #455a64",
  //   backgroundColor: "#5124E1",
  //   marginTop: "30px",
  //   fontFamily: "Garamond",
  //   fontSize: 14,
  //   width: "250px",
  //   textShadow: "Black 1px 1px"
  // },
  btnSave:{
    margin: "15px",
    color: "white",
    border: "solid 1px #4A99A1",
    backgroundColor: "#4A99A1",      
    marginTop: "30px",
    marginBottom: "20px",
    fontFamily: "Garamond",
    fontSize: 14,
    width: "50%",
    // textShadow: "Black 1px 1px"
  },
  btnCancel: {
    color: "DarkGreen",
    border: "solid 1px #455a64",
    backgroundColor: "white",
    marginTop: "10px",
    marginBottom: "20px",
    fontFamily: "Garamond",
    fontSize: 14,
    width: "50%",
    // textShadow: "Black 1px 1px"
  }
}));

export default function Authentication(props) {
  const classes = useStyles();
  const [kseRESTApi] = useState(props.kseRESTApi); //KFB main REST
  const [userProfile] = useState(props.userProfile);
  const [token] = useState(props.token);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    console.log("UPDATE PWD", props)
  }, [])


  function handlePasswordChange(event) {
    // console.log("PASS", event.target.value)
    setPassword(event.target.value)
    setError(false)
  }
  function handleConfirmPasswordChange(event) {
    // console.log("PASS", event.target.value)
    setConfirmPassword(event.target.value)
    setError(false)
  }
  function handleClickShowPassword() {
    // console.log("SH PASS", !showPassword)
    setShowPassword(!showPassword)
  }
  function handleClickShowConfirmPassword() {
    // console.log("SH PASS", !showPassword)
    setShowConfirmPassword(!showConfirmPassword)
  }
  function onKeyPressPassword(event) {
    let code = event.charCode
    if (code === 13) {
      // console.log("CODE", code)
      handlePasswordChange(event)
      UpdatePasswordButtonClick()
    }
  }
  function onKeyPressConfirmPassword(event) {
    let code = event.charCode
    if (code === 13) {
      // console.log("CODE", code)
      handleConfirmPasswordChange(event)
      UpdatePasswordButtonClick()
    }
  }
  async function UpdatePasswordButtonClick() {
    if (password === confirmPassword) {
      let body = {
        "password": password,
        "confirmPassword": confirmPassword,
        "userName": userProfile.userName
      }
      await fetch(kseRESTApi + "/api/users/ResetPassword",
        {
          "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
          "method": "POST",
          "body": JSON.stringify(body)
        }
      )
        .then(response => response.json())
        .then(async function (res) {
          // console.log("RES", res)
          if (res.isSuccess === true) {
            await fetch(kseRESTApi + "/api/users/SetRequiredAction?value=" + "" + "&userName=" + userProfile.userName,
              {
                "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
              }
            )
              .then(response => response.json())
              .then(async function (res) {
                // console.log("RES", res)
                if (res.isSuccess === true) {
                  await props.fetchUserProfile(token)
                  setPassword("")
                  setConfirmPassword("")
                }
                else {
                  setError(true)
                }
              })
              .catch(function (er) {
                console.log("ERR", er)
              })
          }
          else {
            setError(true)
          }
        })
        .catch(function (er) {
          console.log("ERR", er)
        })
    }
    else {
      setError(true)
    }
  }
  async function BackToLoginPage() {
    localStorage.removeItem("token")
    props.setAuthenticated(false)
    props.setUserProfile(null)
    props.setToken(null)
    setPassword("")
    setConfirmPassword("")
  }
  return (
    <Grid 
      container
      direction="row"
      justifyContent="space-around"
      alignItems="center"
    >
      <Grid item xs={6}>
        <div style={{height: "100vh"}}>
          <table width="100%">
            <tr>
              <td align="left" width="50%">
                <img src={logo} alt="Logo" height={80} style={{paddingTop: "100px", paddingLeft: "20%"}}/>
              </td>
            </tr>
            <tr>
              <td align="left" width="50%">
                <div className={classes.loginText}>
                  <h2>Для входа в систему необходимо создать новый пароль</h2>
                </div>
              </td>
            </tr>
            <tr>
              <td align="center" width="50%">
                <div align="center">
                  {error === true && <p style={{color: "red"}}>Пароли не совпадают!</p>}
                  {/* password */}
                  <div style={{paddingTop:"30px"}}>
                    <TextField
                      style={{width: "50%"}}
                      error={error}
                      size="small"
                      label="Пароль"
                      variant="standard"      
                      value={password}
                      onChange={handlePasswordChange}
                      onKeyPress={onKeyPressPassword}
                      name="password"
                      type="password"
                    />
                  </div>
                  {/* confirmPassword */}
                  <div style={{paddingTop:"30px"}}>
                    <TextField
                      style={{width: "50%"}}
                      error={error}
                      size="small"
                      label="Подтверждение пароля"
                      variant="standard"       
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      onKeyPress={onKeyPressConfirmPassword}
                      name="confirmPassword"
                      type="password"
                    />
                  </div>
                </div>
                <div align="center" >
                  <Button
                    variant="contained" 
                    onClick={()=> UpdatePasswordButtonClick()}
                    className={classes.btnSave}
                  >
                    <b>Сохранить</b>
                  </Button>
                </div>
                <div align="center" >
                  <Button
                    variant="contained" 
                    onClick={()=> BackToLoginPage()}
                    className={classes.btnCancel}
                  >
                    <b>Отмена</b>
                  </Button>
                </div>
              </td>              
            </tr>
          </table>
          </div>
      </Grid>
      <Grid item xs={6}>
        <div style={{background: "#ECECEC", height: "100vh"}}>
          <Grid 
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <img src={logo} alt="Logo" height={350} style={{paddingTop: "100px"}}/>
            </Grid>
            <Grid item xs={12}>
              <table>
                <tr>
                  <td className={classes.kseText}>KYRGYZ</td>
                </tr>
                <tr>
                  <td className={classes.kseText}>STOCK</td>
                </tr>
                <tr>
                  <td className={classes.kseText}>EXCHANGE</td>
                </tr>
              </table> 
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
    // <div style={{ backgroundImage: `url(${background})`, height: "100vh" }}>
    //   <table width="100%">
    //     <tr>
    //       <td align="left">
    //         <div style={{ paddingTop: "40px", paddingLeft: "50px", flexDirection: "row" }}>
    //           <div>
    //             <img src={logo} alt="Logo" height={120} />
    //           </div>
    //           <div className={classes.kseText}>
    //             <h2>КЫРГЫЗСКАЯ</h2>
    //           </div>
    //           <div className={classes.kseText}>
    //             <h2>ФОНДОВАЯ</h2>
    //           </div>
    //           <div className={classes.kseText}>
    //             <h2>БИРЖА</h2>
    //           </div>
    //         </div>
    //       </td>
    //       <td align="right">
    //         <div style={{ paddingTop: "150px", paddingRight: "30px", width: "400px" }}>
    //           <Typography className={classes.typographySty}>
    //             <Card className={classes.card}>
    //               <div className={classes.loginText}>
    //                 <h2>Для входа в систему необходимо создать новый пароль!</h2>
    //               </div>

    //               <form align="center">
    //                 {error === true && <p style={{ color: "red" }}>Пароли не совпадают!</p>}
    //                 {/* Пароль */}
    //                 <div align="center">
    //                   <FormControl
    //                     size="small"
    //                     variant="outlined"
    //                     className={classes.formControl}
    //                   >
    //                     <InputLabel htmlFor="outlined-adornment-password" variant='outlined'> Пароль </InputLabel>
    //                     <OutlinedInput
    //                       error={error}
    //                       labelWidth={60}
    //                       id="password"
    //                       name="password"
    //                       type={showPassword ? "text" : "password"}
    //                       value={password}
    //                       onChange={handlePasswordChange}
    //                       onKeyPress={onKeyPressPassword}
    //                       endAdornment={
    //                         <InputAdornment position="end">
    //                           <IconButton
    //                             aria-label="toggle password visibility"
    //                             onClick={handleClickShowPassword}
    //                             edge="end"
    //                             labelPlacement="start"
    //                           >
    //                             {showPassword ? <Visibility /> : <VisibilityOff />}
    //                           </IconButton>
    //                         </InputAdornment>
    //                       }
    //                     />
    //                   </FormControl>
    //                 </div>
    //                 {/* Подтверждение пароля */}
    //                 <div align="center">
    //                   <FormControl
    //                     size="small"
    //                     variant="outlined"
    //                     className={classes.formControl}
    //                     style={{ marginTop: 5 }}
    //                   >
    //                     <InputLabel htmlFor="outlined-adornment-confirmPassword" variant='outlined'>Подтверждение пароля</InputLabel>
    //                     <OutlinedInput
    //                       error={error}
    //                       labelWidth={60}
    //                       id="confirmPassword"
    //                       name="confirmPassword"
    //                       type={showConfirmPassword ? "text" : "password"}
    //                       value={confirmPassword}
    //                       onChange={handleConfirmPasswordChange}
    //                       onKeyPress={onKeyPressConfirmPassword}
    //                       endAdornment={
    //                         <InputAdornment position="end">
    //                           <IconButton
    //                             aria-label="toggle confirmPassword visibility"
    //                             onClick={handleClickShowConfirmPassword}
    //                             edge="end"
    //                             labelPlacement="start"
    //                           >
    //                             {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
    //                           </IconButton>
    //                         </InputAdornment>
    //                       }
    //                     />
    //                   </FormControl>
    //                 </div>
    //               </form>
    //               <div align="center">
    //                 <Button
    //                   variant="contained"
    //                   onClick={() => UpdatePasswordButtonClick()}
    //                   className={classes.btnSave}
    //                 >
    //                   <b>Сохранить</b>
    //                 </Button>
    //                 <Button
    //                   variant="contained"
    //                   onClick={() => BackToLoginPage()}
    //                   className={classes.btnCancel}
    //                 >
    //                   <b>Отмена</b>
    //                 </Button>
    //               </div>
    //             </Card>
    //           </Typography>
    //         </div>
    //       </td>
    //     </tr>
    //   </table>
    // </div>
  )
}
