import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
// Icons
import { IoMdClose } from 'react-icons/io';
import { MdExpandMore } from 'react-icons/md';
import { MdExpandLess } from 'react-icons/md';
import { FcApproval } from 'react-icons/fc';
import { FcCancel } from 'react-icons/fc';
// import { IoIosSettings } from 'react-icons/io';
// import FileOpenIcon from '@mui/icons-material/FileOpen';
// Form components
import Checkbox from '@material-ui/core/Checkbox';
// Tab components
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
//Style
import "../styles/generalStyles.css"
// Libraries
import swal from 'sweetalert'; // https://sweetalert.js.org/guides/
import Draggable from 'react-draggable'; // https://www.npmjs.com/package/react-draggable
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";
var fetch = require('node-fetch');

function IntegerFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={" "}
      isNumericString
    />
  );
}
IntegerFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
function FloatFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      decimalSeparator={"."}
      thousandSeparator={" "}
      isNumericString
    />
  )
}
FloatFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

const useStyles = makeStyles(() => ({
  resizeForm: {
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    border: "1px solid #2B4D80",
    display: "flex",
    backgroundColor: "white",
    "flex-flow": "column",
    width: "80%",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "30px",
    background: "white",
    position: 'absolute',
  },
  menuIconStyle: {
    position: "absolute",
    top: 7,
    right: 10
  },
  // lotsLabel: {
  //   fontWeight: "bold",
  //   background: "#C8B8E7"
  // },
  lotsLabel: {
    fontWeight: "bold",
    color: "white",
    background: "#418991",
    whiteSpace: "nowrap"
  },
  lot: {
    color: "white",
    background: "#3E54DA",
    fontWeight: "bold",
  },
  total: {
    fontWeight: "bold",
    fontSize: 12
  },
  tdButton: {
    cursor: "pointer",
    borderBottom: "1px solid grey",
    "&:hover": {
      background: "#C0C0C0",
      cursor: "pointer",
      borderBottom: "1px solid grey",
    }
  }
}))
// ToolTip стили (подсказки)
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white
  },
  tooltip: {
    backgroundColor: "white",
    border: "1px solid #7A7A7A",
    fontFamily: 'Helvetica',
    color: "#7A7A7A",
    fontSize: 13,
    textAlign: "center",
    boxShadow: "4px 4px 4px 3px #C3C3C3"
  }
}))

export default (props) => {
  const classes = useStyles()
  const [kseRESTApi] = useState(props.kseRESTApi)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [token] = useState(props.token)
  const [userProfile] = useState(props.userProfile)
  const [lotsForm, setLotsForm] = useState(null)
  const [requestsForm, setRequestsForm] = useState(null)
  const [tableKey, setTableKey] = useState(getUUID())
  const [auction, setAuction] = useState(props.auction)
  const [docListConfirmer, setDocListConfirmer] = useState([])
  const [docListLots, setDocListLots] = useState([])
  const [docListRequests, setDocListRequests] = useState([])
  const [stats, setStats] = useState(null)
  const [enumData, setEnumData] = useState({})
  const [selectedLot, setSelectedLot] = useState({ id: null })
  const [selectedRequest, setSelectedRequest] = useState({ ordPrefferedProfit: null })
  const [selectedItemConfirmer, setSelectedItemConfirmer] = useState({ organizationId: null })

  const [updateState, setUpdateState] = useState(null)
  const [expanded, setExpanded] = useState(true)
  const [anchorEl, setAnchorEl] = useState(null)
  const openMenu = Boolean(anchorEl)

  const [fieldValue, setFieldValue] = useState({
    competitiveVolume: null,
    nonCompetitiveVolume: null,
    nonCompetitivePrefferedProfit: null,
  })
  const [checkedRequests, setCheckedRequests] = useState({})
  const [deletedRequests, setDeletededRequests] = useState({})
  const [position, setPosition] = useState({ x: 0, y: 0 })

  // Set data from props to state of component
  useEffect(async () => {
    console.log("Auction Details PROPS CD/MINFIN", props)
    let lotsForm = await props.fetchForm("d7714268-24d4-4c5d-9b58-e4f54b1b675d")
    setLotsForm(lotsForm)
    let requestsForm = null
    if (auction.auctionStatusId === 7) {
      requestsForm = await props.fetchForm("8e2abe3e-805e-4c6b-82b4-8fe8e0d261ae")
      setRequestsForm(requestsForm)
    }
    else {
      requestsForm = await props.fetchForm("0bf21973-30f7-4bb5-80b5-7465d61a946d")
      setRequestsForm(requestsForm)
    }


    let lotsEnums = await props.getEnumDataByForm(lotsForm)
    let reqEnums = await props.getEnumDataByForm(requestsForm)
    let enumDataToCollect = [
      { enumName: "auctionStatuses", enumDef: "ecb0c7a9-83f1-4535-9b45-a381dbc93966" },
      { enumName: "organizationId", enumDef: "5b78d9dd-821d-4c6a-a00a-3af85224fbc4" }
    ]
    let enums = await props.getEnumDataByList(enumDataToCollect)
    for (let key in lotsEnums) {
      reqEnums[key] = lotsEnums[key]
    }
    for (let key in enums) {
      reqEnums[key] = enums[key]
    }
    console.log("EN AD", reqEnums)
    setEnumData(reqEnums)
    updateDocList()
    props.setComponentIndex(props.getComponentIndex() + 1)
    setUpdateState(getUUID())
  }, [])
  useEffect(() => {
    if (props.updateAuctionDetails !== null) {
      // console.log("HVAR", props.updateAuctionDetails)
      updateDocList()
    }
  }, [props.updateAuctionDetails])
  async function updateDocList() {
    // let docL = await props.fetchDocList("/api/Auction/Details/" + auction.id)
    // let docLC = await props.fetchDocList("/api/Auction/DetailsConfirmerForm/" + auction.id)
    if (auction.auctionStatusId === 3 || auction.auctionStatusId === 4) {
      await fetch(
        kseRESTApi + "/api/Auction/DetailsConfirmer/" + auction.id,
        {
          "headers": { "content-type": "application/json", "Authorization": "Bearer " + token }
        }
      )
        .then(response => response.json())
        .then(function (res) {
          console.log("DOC L CD MIN", res)
          setDocListConfirmer(res.data)
          // setAuction(res.data)
          // setDocListLots(res.data.lots)
          // setSelectedLot(res.data.lots[0])
          // setDocListRequests(res.data.requests)
          setTableKey(getUUID())
        })
        .catch(function (error) {
          return console.log("Collecting data error: ", error)
        })
    }
    else if (auction.auctionStatusId === 7 && userProfile.role.name === "CD") {
      await fetch(
        kseRESTApi + "/api/Auction/DetailsByRequests/" + auction.id,
        {
          "headers": { "content-type": "application/json", "Authorization": "Bearer " + token }
        }
      )
        .then(response => response.json())
        .then(function (res) {
          console.log("DOC L CD MIN", res)
          setDocListConfirmer(res.data)
          setAuction(res.data)
          setDocListLots(res.data.lots)
          setSelectedLot(res.data.lots[0])
          setDocListRequests(res.data.requests.sort(dynamicSort("ordPrice", -1, "Float")))
          setTableKey(getUUID())
        })
        .catch(function (error) {
          return console.log("Collecting data error: ", error)
        })
    }
    else {
      await fetch(
        kseRESTApi + "/api/Auction/DetailsByGrouped/" + auction.id,
        {
          "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
          "method": "GET"
        }
      )
        .then(response => response.json())
        .then(function (res) {
          console.log("DOC L CON ByGrouped", res.data)
          setDocListConfirmer(res.data)
          setAuction(res.data)
          setDocListLots(res.data.lots)
          setSelectedLot(res.data.lots[0])
          // setDocListRequests(res.data.requests)
          setDocListRequests(res.data.requests.sort(dynamicSort("ordPrice", -1, "Float")))
          setStats(res.data.stats)
          setTableKey(getUUID())
        })
        .catch(function (error) {
          return console.log("Collecting data error: ", error)
        })
    }
  }
  function dynamicSort(property, sortOrder, type) {
    // console.log("SORT", property)
    if (type === "DateTime" || type === "Bool") {
      sortOrder = sortOrder * -1
    }
    if (type === "DateTime") {
      return function (a, b) {
        if (a[property] !== null && b[property] !== null) {
          let dateA = new Date(a[property].substring(0, 19))
          let timeInSecA = dateA.getTime() / 1000
          // console.log("timeInSecA", timeInSecA)
          let dateB = new Date(b[property].substring(0, 19))
          let timeInSecB = dateB.getTime() / 1000
          // console.log("timeInSecB", timeInSecB)
          var result = (timeInSecA < timeInSecB) ? -1 : (timeInSecA > timeInSecB) ? 1 : 0
          return result * sortOrder
        }
        else {
          if (a[property] === null) {
            return -1 * sortOrder
          }
          return 1 * sortOrder
        }
      }
    }
    else if (type === "Int" || type === "Text" || type === "Float" || type === "Bool") {
      return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
        return result * sortOrder
      }
    }
    else if (type === "Enum") {
      return function (a, b) {
        if (a[property] === null) {
          return 1 * sortOrder
        }
        else {
          let labelA = getEnumLabel(property, a[property])
          // console.log("A", property, a[property], labelA)
          let labelB = getEnumLabel(property, b[property])
          // console.log("labelB", labelB, property, b)
          var result = (labelA < labelB) ? -1 : (labelA > labelB) ? 1 : 0
          return result * sortOrder
        }

      }
    }
  }
  // random UUID generator
  function getUUID() {
    return uuidv4()
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }
  async function handleContextMenuClick(event, docListLotsItem) {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
    setSelectedLot(docListLotsItem)
  }

  const handleIntChange = (event) => {
    // console.log("EVENT", event.target.name, event.target.value)
    if (event.target.value !== "") {
      let stringNum = event.target.value.toString().replace(/ /g, '')
      let int = parseInt(stringNum)
      setFieldValue({ ...fieldValue, [event.target.name]: int })
    }
    else {
      setFieldValue({ ...fieldValue, [event.target.name]: null })
    }
  }
  const handleFloatChange = (event) => {
    // let newFields = fieldValue
    // newFields[event.target.name] = parseFloat(event.target.value)
    // setFieldValue(newFields)
    // console.log("FLOAT CHANGE", event.target.name, event.target.value, fieldValue)
    // // setFieldValue({ ...fieldValue, [event.target.name]: event.target.value })
    if (event.target.value !== "") {
      let stringNum = event.target.value.toString().replace(/ /g, '')
      let float = parseFloat(stringNum)
      setFieldValue({ ...fieldValue, [event.target.name]: float })
      // console.log("FLOAT CHANGE", event.target.name, float)
    }
    else {
      setFieldValue({ ...fieldValue, [event.target.name]: event.target.value })
    }
  }

  // Create grid form components
  function getGridFormItems(docListItem, contentItem) {
    let value = docListItem[contentItem.name]
    let name = contentItem.name
    let type = contentItem.type
    if (type === "Enum") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="center" style={{ color: "black", fontSize: 12 }}>-</td>)
      }
      else {
        return getEnumLabel(name, value)
      }
    }
    else if (type === "Bool") {
      // console.log("ITEM", name, value, type)
      return (
        <Checkbox
          size="small"
          style={{ maxWidth: 20, height: 15, color: value === false ? "grey" : "green" }}
          checked={(value === false || value === null || value === undefined) ? false : true}
        />
      )
    }
    else if (type === "DateTime") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="center" style={{ color: "black", fontSize: 12 }}>-</td>)
      }
      else {
        let d = moment(value).format("DD.MM.YYYY HH:MM")
        return d === "Invalid date" ? value : d
      }
    }
    else if (type === "Currency") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="center" style={{ color: "black", fontSize: 12 }}>-</td>)
      }
      else {

        let newCur = props.currencyBeautifier(value)
        // console.log("CUR", newCur)
        return newCur
      }
    }
    else if (type === "Int") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="center" style={{ color: "black", fontSize: 12 }}>-</td>)
      }
      else {
        let newInt = props.intBeautifier(value)
        // console.log("CUR", newCur)
        return newInt
      }
    }
    else {
      if (value === null || value === "" || value === undefined) {
        return (<td align="center" style={{ color: "black", fontSize: 12 }}>-</td>)
      }
      else {
        return (<td>{value}</td>)
      }
    }
  }
  function getEnumLabel(name, id) {
    for (let d = 0; d < enumData[name].length; d++) {
      if (enumData[name][d].id === id) {
        return enumData[name][d].label
      }
    }
  }
  function formClick() {
    // console.log("CLICK")
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 1)
    props.setComponentIndex(currIndex + 1)
  }
  function getModalStyle() {
    const top = 12;
    const left = 7;
    return {
      height: expanded === true ? "670px" : "40px",
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      zIndex: componentIndex
    };
  }

  function getBackgroundLots(item) {
    if (selectedLot.id === item.id) {
      return "#CECECE"
    }
    else {
      return "white"
    }
  }
  function getBackgroundRequests(item) {
    if (selectedRequest.ordPrefferedProfit === item.ordPrefferedProfit) {
      return "#CECECE"
    }
    else {
      return item.bgColor
    }
  }
  function getBackgroundConfirmer(item) {
    if (selectedItemConfirmer.organizationId === item.organizationId) {
      return "#CECECE"
    }
    else {
      return "white"
    }
  }

  function countCompetitiveFields(name) {
    let counter = 0
    for (let i = 0; i < docListRequests.length; i++) {
      if (docListRequests[i].nonCompetitive === false) {
        // console.log("COUNT", docListRequests[i][name])
        counter = counter + docListRequests[i][name]
      }
    }
    return counter
  }
  function countNonCompetitiveFields(name) {
    let counter = 0
    for (let i = 0; i < docListRequests.length; i++) {
      if (docListRequests[i].nonCompetitive === true) {
        // console.log("COUNT", docListRequests[i][name])
        counter = counter + docListRequests[i][name]
      }
    }
    return counter
  }
  function getNonCompetitiveProfit() {
    let profit = 0
    for (let i = 0; i < docListRequests.length; i++) {
      if (docListRequests[i].nonCompetitive === true) {
        // console.log("COUNT", docListRequests[i][name])
        profit = docListRequests[i].ordPrefferedProfit
        break
      }
    }
    return profit
  }
  function countField(f, competitive) {
    let fields = []
    for (let s = 0; s < f.sections.length; s++) {
      for (let c = 0; c < f.sections[s].contents.length; c++) {
        if (f.sections[s].contents[c].show === true) {
          let name = f.sections[s].contents[c].name
          if (name === "ordAmount" || name === "ordSum" || name === "dealAmount" || name === "dealSum") {
            let result = ""
            if (competitive === true) {
              result = countCompetitiveFields(name)
            }
            else if (competitive === false) {
              result = countNonCompetitiveFields(name)
            }
            else if (competitive === "both") {
              result = countCompetitiveFields(name) + countNonCompetitiveFields(name)
            }
            fields.push(<td class={classes.total}>{props.currencyBeautifier(result)}</td>)
          }
          else { fields.push(<td />) }
        }

      }
    }
    return fields
  }
  async function changeStatus(accepted) {
    let body = {
      organizationId: selectedItemConfirmer.organizationId,
      accepted: accepted
    }
    await fetch(
      kseRESTApi + "/api/Auction/AcceptByConfirmer/" + props.auction.id,
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
        "method": "POST",
        "body": JSON.stringify(body)
      }
    )
      .then(response => response.json())
      .then(function (res) {
        console.log("RES ACCEPT", res)
        if (res.isSuccess === true) {
          props.callSuccessToast(accepted === true ? "Принято" : "Отклонено", 1100)
          updateDocList()
          props.setUpdateAuction(getUUID())
        }
        else {
          props.callErrorToast(res.errors[0])
        }
      })
  }
  function getStatus(item) {
    let status = <div></div>
    if (item.handled === true && item.accepted === true) {
      status = <div style={{ color: "green", fontWeight: "bold" }}>Подтвержден</div>
    }
    else if (item.handled === true && item.accepted === false) {
      status = <div style={{ color: "red", fontWeight: "bold" }}>Отклонен</div>
    }
    return status
  }
  async function preCalculate() {
    let body = {
      competitiveVolume: fieldValue.competitiveVolume,
      nonCompetitiveVolume: fieldValue.nonCompetitiveVolume,
      nonCompetitivePrefferedProfit: fieldValue.nonCompetitivePrefferedProfit,
      selectedCompetitiveProfit: selectedRequest.ordPrefferedProfit
    }
    await fetch(
      kseRESTApi + "/api/Auction/CalcByMinfin/" + auction.id,
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
        "method": "POST",
        "body": JSON.stringify(body)
      }
    )
      .then(response => response.json())
      .then(function (res) {
        console.log("RES CALC", res)
        if (res.isSuccess === true) {
          // setDocListConfirmer(res.data)
          // setAuction(res.data)
          // setDocListLots(res.data.lots)
          // setSelectedLot(res.data.lots[0])
          // setDocListRequests(res.data.requests.sort(dynamicSort("ordPrice", -1, "Float")))
          // // setDocListRequests(res.data.requests)
          // setTableKey(getUUID())
          updateDocList()
        }
        else {
          props.callErrorToast(res.errors[0])
        }
      })
  }

  async function approveAuction() {
    await fetch(
      kseRESTApi + "/api/Auction/AcceptByMinfin/" + auction.id,
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
        "method": "GET"
      }
    )
      .then(response => response.json())
      .then(function (res) {
        console.log("RES APPROVE AUCT", res)
        if (res.isSuccess === true) {
          props.callSuccessToast("Результаты аукциона успешно подтверждены", 1200)
          updateDocList()
          setTableKey(getUUID())
          props.setUpdateAuction(getUUID())
        }
        else {
          props.callErrorToast(res.errors[0])
        }
      })
  }
  async function declineAuctionResults() {
    swal({
      text: "Вы точно хотите отклонить результаты аукциона?",
      icon: "warning",
      buttons: { yes: "Да", cancel: "Отмена" }
    })
      .then(async (click) => {
        if (click === "yes") {
          await fetch(
            kseRESTApi + "/api/Auction/DeclineAuctionResults/" + auction.id,
            {
              "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
              "method": "GET"
            }
          )
            .then(response => response.json())
            .then(function (res) {
              console.log("RES APPROVE AUCT", res)
              if (res.isSuccess === true) {
                props.callSuccessToast("Результаты аукциона отклонены!", 1200)
                updateDocList()
                setTableKey(getUUID())
                props.setUpdateAuction(getUUID())
              }
              else {
                props.callErrorToast(res.errors[0])
              }
            })
        }
      })
  }

  async function closeAuction() {
    await fetch(
      kseRESTApi + "/api/Auction/AcceptByDepo/" + auction.id,
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
        "method": "GET"
      }
    )
      .then(response => response.json())
      .then(function (res) {
        console.log("RES APPROVE AUCT", res)
        if (res.isSuccess === true) {
          props.callSuccessToast("Аукцион закрыт!", 1200)
          updateDocList()
          setTableKey(getUUID())
          props.setUpdateAuction(getUUID())
        }
        else {
          props.callErrorToast(res.errors[0])
        }
      })
  }
  // async function downloadReport(format) {
  //   await fetch(
  //     kseRESTApi + "/api/Auction/" + auction.id + "/" + selectedLot.id + "/summary-report/" + format,
  //     {
  //       "headers": { "Authorization": "Bearer " + token }
  //     }
  //   )
  //     .then(response => response.blob())
  //     .then(blob => {
  //       // console.log("RESP", blob)
  //       var url = window.URL.createObjectURL(blob)
  //       var a = document.createElement('a')
  //       a.href = url
  //       a.download = `Сводная ведомость заявок.${format}`
  //       document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
  //       a.click()
  //       a.remove()  //afterwards we remove the element again         
  //     })
  //     .catch(error => console.error(error));
  // }
  async function downloadReport(format, repNum, repLabel) {
    await fetch(
      kseRESTApi + "/api/Auction/" + auction.id + "/" + selectedLot.id + "/summary-report-" + repNum + "/" + format,
      {
        "headers": { "Authorization": "Bearer " + token }
      }
    )
      .then(response => response.blob())
      .then(blob => {
        // console.log("RESP", blob)
        var url = window.URL.createObjectURL(blob)
        var a = document.createElement('a')
        a.href = url
        a.download = `${repLabel}.${format}`
        document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click()
        a.remove()  //afterwards we remove the element again         
      })
      .catch(error => console.error(error));
  }
  // Custom ToolTip
  function BootstrapTooltip(props) {
    const clsToolTip = useStylesBootstrap();
    return <Tooltip arrow classes={clsToolTip} {...props} />;
  }

  async function deleteLotRequests(request) {
    swal({
      text: "Вы точно хотите снять выбранные заявки?",
      icon: "warning",
      buttons: { yes: "Да", cancel: "Отмена" }
    })
      .then(async (click) => {
        if (click === "yes") {
          let newDelRerqs = { ...deletedRequests }
          for (let key in checkedRequests) {
            newDelRerqs[key] = true
          }
          setDeletededRequests(newDelRerqs)
          setCheckedRequests({})
        }
      })
  }
  async function returnByDepo() {
    swal({
      text: "Вы точно хотите вернуть данный аукцион на пересчет в Минфин?",
      icon: "warning",
      buttons: { yes: "Да", cancel: "Отмена" }
    })
      .then(async (click) => {
        if (click === "yes") {
          let body = []
          for (let key in deletedRequests) {
            body.push(key)
          }
          await fetch(
            kseRESTApi + "/api/Auction/ReturnByDepo/" + auction.id,
            {
              "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
              "method": "POST",
              "body": JSON.stringify(body)
            }
          )
            .then(response => response.json())
            .then(function (res) {
              console.log("RES DEL", res)
              if (res.isSuccess === true) {
                props.callSuccessToast("Аукцион возвращен на пересчет в Минфин!", 1100)
                updateDocList()
                props.setUpdateAuction(getUUID())
              }
              else {
                props.callErrorToast(res.errors[0])
              }
            })
          // props.callSuccessToast("Заявка снята!", 1100)
        }
      })
  }
  const handleDeleteRequestCheckboxChange = (event) => {
    // console.log("CHBX", event.target.name, event.target.checked, checkedRequests)
    setCheckedRequests({ ...checkedRequests, [event.target.name]: event.target.checked })
    setUpdateState(getUUID())
  }
  function chechToShowDeleteRequestButton() {
    let result = false
    if (Object.keys(checkedRequests).length === 0) {
      return result
    }
    else {
      for (let key in checkedRequests) {
        if (checkedRequests[key] === true) {
          result = true
        }
      }
    }
    // console.log("BUTT", result)
    if (result === true) {
      return (
        <button
          className="approveButton"
          onClick={() => deleteLotRequests()}
          style={{ marginLeft: 10 }}
        >
          Снять выбранные заявки
        </button>
      )
    }
  }
  function positionHandler(e, data) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 10 // Take 10% of width
    let maxDeviationX = (dimension.w / 100) * 80 // Take 80% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    // console.log("minX", minDeviationX, "maxX", maxDeviationX, "maxY", maxDeviationY)
    if (data.y < maxDeviationY) {
      // console.log("SET MIN Y", data.x)
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      // console.log("SET MIN X", data.x, minDeviationX)
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      // console.log("SET MAX X", data.x)
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
  }

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  if (updateState !== null && requestsForm !== null) {
    try {
      return (
        // <Draggable
        //   handle="p"
        //   onStart={() => formClick()}
        //   onMouseDown={() => formClick()}
        // >
        //   <div style={getModalStyle()} className={classes.resizeForm} key={tableKey}>
        <Draggable
          key={tableKey}
          handle="p"
          onStart={() => formClick()}
          onStop={positionHandler}
          position={position}
        >
          <div
            className={classes.resizeForm}
            style={{ top: "15%", left: "10%", transform: "translate(-15%, -10%)", zIndex: componentIndex }}
            onClick={() => formClick()}
          >
            <div className="main-table-label-div-style-new" onDoubleClick={() => setExpanded(!expanded)}>
              <p className="main-table-label-p-style-new">
                <table className="main-table-label-div-style-new">
                  <tr>
                    <td width={"99%"}>
                      Детали аукциона ГЦБ
                    </td>
                    {expanded == false
                      ?
                      <td>
                        <MdExpandLess size={22} className="form-expand-icons-style" onClick={() => setExpanded(!expanded)} />
                      </td>
                      :
                      <td>
                        <MdExpandMore size={22} className="form-expand-icons-style" onClick={() => setExpanded(!expanded)} />
                      </td>
                    }
                    <td>
                      <IoMdClose size={22} className="form-close-icons-style" style={{ marginRight: "5px" }} onClick={() => props.closeAuctionDetailsCDMinfin(props.auction.uuid)} />
                    </td>
                  </tr>
                </table>
              </p>
            </div>
            {expanded === true &&
              <div className="dragble-div-body-style">
                {(auction.auctionStatusId === 3 || auction.auctionStatusId === 4) && userProfile.role.name === "CD" && // Подтвекрждение наличия ДС у брокеров
                  <>
                    <div style={{ fontWeight: "bold", paddingLeft: 20 }}>Приказы на покупку</div>
                    <table className="main-table-style" width="100%" id={tableKey}>
                      <thead style={{ backgroundColor: "#cfd8dc" }}>
                        <tr>
                          <th class="main-table-header-th-style-new">Компания</th>
                          <th class="main-table-header-th-style-new">{"Заявок на сумму (сом)"}</th>
                          <th class="main-table-header-th-style-new">Принять</th>
                          <th class="main-table-header-th-style-new">Отклонить</th>
                          <th class="main-table-header-th-style-new">Статус</th>
                        </tr>
                      </thead>
                      <tbody>
                        {docListConfirmer.map(docListConfirmerItem => (
                          <tr
                            onMouseDown={() => setSelectedItemConfirmer(docListConfirmerItem)}
                            style={{ background: getBackgroundConfirmer(docListConfirmerItem) }}
                          >
                            <td align="left" className="main-table-tbody-td-style">
                              {getEnumLabel("organizationId", docListConfirmerItem.organizationId)}
                            </td>
                            <td align="left" className="main-table-tbody-td-style">
                              {props.currencyBeautifier(docListConfirmerItem.totalSum)}
                            </td>
                            <td align="center" className="main-table-tbody-td-style" class={classes.tdButton}>
                              {docListConfirmerItem.handled === false && <FcApproval size={22} onClick={() => changeStatus(true)} />}
                            </td>
                            <td align="center" className="main-table-tbody-td-style" class={classes.tdButton}>
                              {docListConfirmerItem.handled === false && <FcCancel size={22} onClick={() => changeStatus(false)} />}
                            </td>
                            <td align="center" className="main-table-tbody-td-style">
                              {getStatus(docListConfirmerItem)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div style={{ fontWeight: "bold" }}>Статус: {getEnumLabel("auctionStatuses", auction.auctionStatusId)}</div>
                  </>
                }
                {auction.auctionStatusId >= 5 && // Установка цены отсечения
                  <>
                    <div style={{ fontWeight: "bold", paddingLeft: 20 }}>Ведомость предварительных результатов аукциона</div>
                    <div style={{ paddingLeft: 20 }}>Дата размещения {moment(auction.date).format('DD.MM.YYYY')}</div>
                    <div style={{ paddingLeft: 20 }}>ГЦБ {getEnumLabel("financeInstrumentId", selectedLot.financeInstrumentId)}</div>
                    {/* <div style={{ fontWeight: "bold", paddingLeft: 20 }}>Лоты</div> */}
                
                    <br />

                    <table className="main-table-style" width="100%" id={tableKey}>
                      <thead style={{ backgroundColor: "#2CB405" }}>
                        <tr>
                          <th class="main-table-header-th-style-new"></th>
                          {/* <th class="main-table-header-th-style-new">Тип приказа</th> */}
                          {requestsForm.sections.map(section => section.contents.map(contentItem => {
                            if (contentItem.show === true) {
                              return (
                                <th
                                  class="main-table-header-th-style-new"
                                >
                                  {contentItem.label}
                                </th>
                              )
                            }
                          }))}
                          {auction.auctionStatusId === 6 && <th class="main-table-header-th-style-new">Т. О.</th>}
                        </tr>
                      </thead>
                      <tbody>
                        <tr className={classes.lotsLabel}><td>Конкурентные</td>
                          {requestsForm.sections.map(section => (
                            section.contents.map(contentItem => {
                              if (contentItem.show === true) {
                                return <td />
                              }
                            })
                          ))}
                        </tr>
                        {docListRequests.map(docListRequestsItem => (
                          docListRequestsItem.nonCompetitive === false && deletedRequests[docListRequestsItem.id] !== true &&
                          <tr
                            onMouseDown={() => setSelectedRequest(docListRequestsItem)}
                            style={{ 
                              background: getBackgroundRequests(docListRequestsItem), 
                              color: docListRequestsItem.textColor 
                              // color:getBackgroundRequests(docListRequestsItem)
                            }}
                          >
                            <td align="left" className="main-table-tbody-td-style">
                              {auction.auctionStatusId === 7 && userProfile.role.name === "CD" &&
                                <Checkbox
                                  style={{ color: "#337E86" }}
                                  name={docListRequestsItem.id}
                                  onChange={handleDeleteRequestCheckboxChange}
                                  checked={checkedRequests[docListRequestsItem.id] ? (checkedRequests[docListRequestsItem.id] === false ? false : true) : false}
                                />
                              }
                            </td>
                            {/* <td align="left" className="main-table-tbody-td-style">Конкурентный</td> */}
                            {requestsForm.sections.map(section => (
                              section.contents.map(contentItem => {
                                if (contentItem.show === true) {
                                  return (
                                    <td align="left" className="main-table-tbody-td-style">
                                      {getGridFormItems(docListRequestsItem, contentItem)}
                                    </td>
                                  )
                                }
                              })
                            ))}
                            {auction.auctionStatusId === 6 && <td align="center" className="main-table-tbody-td-style">
                              <Checkbox
                                size="small"
                                style={{ maxWidth: 20, height: 10, color: selectedRequest.ordPrefferedProfit === docListRequestsItem.ordPrefferedProfit ? "green" : "grey" }}
                                checked={selectedRequest.ordPrefferedProfit === docListRequestsItem.ordPrefferedProfit ? true : false}
                              />
                            </td>
                            }
                          </tr>
                        ))}
                        <tr>
                          <td class={classes.total}>Итого:</td>
                          {countField(requestsForm, true)}
                        </tr>
                        {/* <tr>
                          <td class={classes.total}>Итого:</td>
                          {auction.auctionStatusId === 7 && <td />}
                          <td />
                          <td class={classes.total}>{props.intBeautifier(countCompetitiveFields("ordAmount"))}</td>
                          <td /><td />
                          <td class={classes.total}>{props.currencyBeautifier(countCompetitiveFields("ordSum"))}</td>
                          <td />
                          <td class={classes.total}>{props.intBeautifier(countCompetitiveFields("dealAmount"))}</td>
                          <td />
                          <td class={classes.total}>{props.currencyBeautifier(countCompetitiveFields("dealSum"))}</td>
                          <td />
                        </tr> */}
                        {/* <tr style={{ background: "white" }}><br /><td /><td /><td /><td /><td /><td /><td /><td /><td /><td /><td /></tr> */}
                        {/* <tr style={{ background: "#337E86" }}><br /><td /><td /><td /><td /><td /><td /><td /><td /><td /><td /><td /></tr> */}

                        <tr className={classes.lotsLabel}><td>Не конкурентные</td>
                          {requestsForm.sections.map(section => (
                            section.contents.map(contentItem => {
                              if (contentItem.show === true) {
                                return <td />
                              }
                            })
                          ))}
                        </tr>
                        {docListRequests.map(docListRequestsItem => (
                          docListRequestsItem.nonCompetitive === true && deletedRequests[docListRequestsItem.id] !== true &&
                          <tr
                            // onMouseDown={() => setSelectedLot(docListRequestsItem)}
                            style={{ background: docListRequestsItem.bgColor, color: docListRequestsItem.textColor }}
                          >
                            <td align="left" className="main-table-tbody-td-style">
                              {auction.auctionStatusId === 7 && userProfile.role.name === "CD" &&
                                <Checkbox
                                  style={{ color: "#337E86" }}
                                  name={docListRequestsItem.id}
                                  onChange={handleDeleteRequestCheckboxChange}
                                  checked={checkedRequests[docListRequestsItem.id] ? (checkedRequests[docListRequestsItem.id] === false ? false : true) : false}
                                />
                              }
                            </td>
                            {/* <td align="left" className="main-table-tbody-td-style">Не конкурентный</td> */}
                            {requestsForm.sections.map(section => (
                              section.contents.map(contentItem => {
                                if (contentItem.show === true) {
                                  return (
                                    <td align="left" className="main-table-tbody-td-style">
                                      {getGridFormItems(docListRequestsItem, contentItem)}
                                    </td>
                                  )
                                }
                              })
                            ))}
                            <td align="left" className="main-table-tbody-td-style"></td>
                          </tr>
                        ))}
                        {chechToShowDeleteRequestButton()}
                        <tr>
                          <td class={classes.total}>Итого:</td>
                          {countField(requestsForm, false)}
                        </tr>
                        <tr style={{ background: "white" }}><br /></tr>
                        <tr>
                          <td class={classes.total}>Всего:</td>
                          {countField(requestsForm, "both")}
                        </tr>
                      </tbody>
                    </table>
                    <br />
                    {auction.auctionStatusId === 5 && userProfile.role.name === "MINFIN" &&
                      <fieldset>
                        <legend>Параметры удовлетворения от Минфин</legend>
                        <table className="main-table-style">
                          <tr>
                            <td>
                              Кол-во конкурентных ГЦБ:
                            </td>
                            <td style={{ paddingLeft: "10px" }}>
                              <TextField
                                variant="outlined"
                                size="small"
                                placeholder={props.intBeautifier(countCompetitiveFields("dealAmount"))}
                                name="competitiveVolume"
                                value={fieldValue.competitiveVolume}
                                onChange={handleIntChange}
                                InputProps={{ inputComponent: IntegerFormat }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Кол-во неконкурентных ГЦБ:
                            </td>
                            <td style={{ paddingLeft: "10px" }}>
                              <TextField
                                variant="outlined"
                                size="small"
                                placeholder={props.intBeautifier(countNonCompetitiveFields("dealAmount"))}
                                name="nonCompetitiveVolume"
                                value={fieldValue.nonCompetitiveVolume}
                                onChange={handleIntChange}
                                InputProps={{ inputComponent: IntegerFormat }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Доходность неконкурентных ГЦБ:
                            </td>
                            <td style={{ paddingLeft: "10px" }}>
                              <TextField
                                variant="outlined"
                                size="small"
                                placeholder={props.currencyBeautifier(getNonCompetitiveProfit())}
                                name="nonCompetitivePrefferedProfit"
                                value={fieldValue.nonCompetitivePrefferedProfit}
                                onChange={handleFloatChange}
                                InputProps={{ inputComponent: FloatFormat }}
                              />
                            </td>
                          </tr>
                        </table>
                      </fieldset>
                    }
                    {auction.auctionStatusId === 5 && userProfile.role.name === "MINFIN" &&
                      <button
                        className="approveButton"
                        onClick={() => preCalculate()}
                        style={{ width: "370px", marginLeft: 10 }}
                      >
                        Пересчитать предварительные результаты аукциона
                      </button>
                    }
                 
                    <br /><br />
                    {stats !== null &&

                      <table width="100%">
                        <tr>
                          <Box sx={{ width: '100%', typography: 'body1', marginTop: '20px' }}>
                            <TabContext value={value}>
                              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                  <Tab label="Общая информация" value="1" />
                                  <Tab label="Цена и доходность" value="2" />
                                  <Tab label="Сводные ведомости заявок" value="3" />
                                </TabList>
                              </Box>
                              <TabPanel value="1" >
                            <table style={{ width: '100%' }}>
                              <tr>
                                <td style={{ verticalAlign: 'top', width: '40%' }} >
                               
                                 <tr>
                                  <td style={{width:'100%'}}>
                                    <table>
                                      {/* {auction.auctionStatusId === 1 && <th class="main-table-header-th-style-new"></th>} */}
                                      {lotsForm.sections.map(section =>
                                        section.contents.map(contentItem => {
                                          if (contentItem.show === true) {
                                            return (
                                              <tr>
                                              <td
                                                // class="main-table-header-th-style-new"
                                                style={{ margin: '4px 0', fontSize: '14px', fontWeight: 'bold' }}
                                              >
                                                {contentItem.label} :
                                              </td>
                                              </tr>
                                            )
                                          }
                                        }) 
                                      )}
                                    </table>
                                    </td>
                                  
                    <td style={{width:'100%'}}>
                                    {docListLots.map((docListLotsItem, index) => (
                                      <table
                                        // onMouseDown={() => setSelectedLot(docListLotsItem)}
                                        style={{
                                          // background: getBackgroundLots(docListLotsItem) 
                                          background: '#fff'
                                        }}
                                        // onContextMenu={(ev) => handleContextMenuClick(ev, docListLotsItem)}
                                      >
                                        {/* {auction.auctionStatusId === 1 &&
                          <p
                            align="left"
                            className={classes.tdBody}
                            style={{ width: "90px" }}
                          >
                            <button className="approveSmallButton" style={{ width: "110px" }} onClick={() => openAuctionBidForm()}>подать заявку</button>
                          </p>
                        } */}

                                        {lotsForm.sections.map(section => (
                                          section.contents.map(contentItem => {
                                            if (contentItem.show === true) {
                                              return (
                                                <tr>
                                                <td
                                                  // className={classes.tdBody} 
                                                  style={{ color: '#337E86', margin: '4px 0', fontSize: '14px', paddingLeft: '8px', textAlign: 'right' ,whiteSpace:'nowrap'}}>
                                                  {getGridFormItems(docListLotsItem, contentItem)}
                                                </td>
                                                </tr>
                                              )
                                            }
                                          })
                                        ))}
                                      </table>
                                    ))}
                                    </td>
                                        </tr>
                                    {/* </div> */}
                            
                                  {/* </tbody>
                </table> */}
                                </td>
                                <td style={{ verticalAlign: 'top', width: '100%' }}>
                                  <table className="main-table-style" style={{ width: '80%' }}>
                                    {/* <thead style={{ backgroundColor: "#2CB405" }}>
                            <tr>
                              <th class="main-table-header-th-style-new"></th>
                              <th class="main-table-header-th-style-new">Конкур-е</th>
                              <th class="main-table-header-th-style-new">Неконкур-е</th>
                              <th class="main-table-header-th-style-new">Всего</th>
                            </tr>
                          </thead> */}
                                    <tbody>
                                      {/* <tr>
                              <td className="main-table-tbody-td-style" style={{ fontWeight: "bold" }}>Предложение, сом</td>
                              <td className="main-table-tbody-td-style">{props.currencyBeautifier(stats.stat1.bids.comp)}</td>
                              <td className="main-table-tbody-td-style">{props.currencyBeautifier(stats.stat1.bids.nocomp)}</td>
                              <td className="main-table-tbody-td-style">{props.currencyBeautifier(stats.stat1.bids.total)}</td>
                            </tr> */}
                                      <tr>
                                        <td style={{ margin: '4px 0', fontSize: '14px', fontWeight: "bold" }}>Обьем спроса (сом) :</td>
                                        <td style={{ color: '#337E86', fontSize: '14px', textAlign: 'right' }}>{props.currencyBeautifier(stats.stat1.asks.total)}</td>
                                      </tr>
                                      <tr>
                                        <td style={{ margin: '4px 0', fontSize: '14px', fontWeight: "bold" }}>Конкур-е :</td>
                                        <td style={{ color: '#337E86', fontSize: '14px', textAlign: 'right' }}>{props.currencyBeautifier(stats.stat1.asks.comp)}</td>
                                      </tr>
                                      <tr>
                                        <td style={{ margin: '4px 0', fontSize: '14px', fontWeight: "bold" }}>Неконкур-е :</td>
                                        <td style={{ color: '#337E86', fontSize: '14px', textAlign: 'right' }}>{props.currencyBeautifier(stats.stat1.asks.nocomp)}</td>
                                      </tr>
                                      <tr>
                                        <td colSpan={2}>
                                          <hr />
                                        </td>
                                      </tr>                                  
                                      <tr>
                                        <td style={{ margin: '4px 0', fontSize: '14px', fontWeight: "bold" }}>Обьем удовлетв. спрос (сом) :</td>
                                        <td style={{ color: '#337E86', fontSize: '14px', textAlign: 'right' }}>{props.currencyBeautifier(stats.stat1.satisfieds.total)}</td>
                                      </tr>
                                      <tr>
                                        <td style={{ margin: '4px 0', fontSize: '14px', fontWeight: "bold" }}>Конкур-е :</td>
                                        <td style={{ color: '#337E86', fontSize: '14px', textAlign: 'right' }}>{props.currencyBeautifier(stats.stat1.satisfieds.comp)}</td>
                                      </tr>
                                      <tr>
                                        <td style={{ margin: '4px 0', fontSize: '14px', fontWeight: "bold" }}>Неконкур-е :</td>
                                        <td style={{ color: '#337E86', fontSize: '14px', textAlign: 'right' }}>{props.currencyBeautifier(stats.stat1.satisfieds.nocomp)}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>

                              </tr>
                            </table>
                          </TabPanel>
                          <TabPanel value="2">
                            <table className="main-table-style">
                              {/* <thead style={{ backgroundColor: "#2CB405" }}>
                            <tr>
                              <th class="main-table-header-th-style-new"></th>
                              <th class="main-table-header-th-style-new">Максимальная цена</th>
                              <th class="main-table-header-th-style-new">Минимальная цена</th>
                              <th class="main-table-header-th-style-new">Средневзвешенная цена</th>
                            </tr>
                          </thead> */}
                              <tbody>
                                <tr>
                                  <td style={{ width: '10%' }}>
                                    <tr>
                                      <td style={{ margin: '4px 0', fontSize: '14px', fontWeight: "bold" }}>Минимальная цена :</td>
                                      <td style={{ width: '150px', color: '#337E86', fontSize: '14px', textAlign: 'right' }}>{props.currencyBeautifier(stats.stat2.prices.min)}</td>
                                    </tr>
                                    <tr>
                                      <td style={{ margin: '4px 0', fontSize: '14px', fontWeight: "bold" }}>Максимальная цена :</td>
                                      <td style={{ width: '150px', color: '#337E86', fontSize: '14px', textAlign: 'right' }}>{props.currencyBeautifier(stats.stat2.prices.max)}</td>
                                    </tr>
                                    <tr>
                                      <td style={{ margin: '4px 0', fontSize: '14px', fontWeight: "bold" }}>Средневзвешенная цена :</td>
                                      <td style={{ width: '150px', color: '#337E86', fontSize: '14px', textAlign: 'right' }}>{props.currencyBeautifier(stats.stat2.prices.avg)}</td>
                                    </tr>
                                  </td>
                                  <td style={{ width: '10%' }}>
                                    <tr>
                                      <td style={{ margin: '4px 0', fontSize: '14px', fontWeight: "bold" }}>Минимальная доходность к погашению, % :</td>
                                      <td style={{ width: '150px', color: '#337E86', fontSize: '14px', textAlign: 'right' }}>{props.currencyBeautifier(stats.stat2.profits.min)}</td>
                                    </tr>
                                    <tr>
                                      <td style={{ margin: '4px 0', fontSize: '14px', fontWeight: "bold" }}>Максимальная доходность к погашению, % :</td>
                                      <td style={{ width: '150px', color: '#337E86', fontSize: '14px', textAlign: 'right' }}>{props.currencyBeautifier(stats.stat2.profits.max)}</td>
                                    </tr>
                                    <tr>
                                      <td style={{ margin: '4px 0', fontSize: '14px', fontWeight: "bold" }}>Средневзвешенная доходность к погашению, % :</td>
                                      <td style={{ width: '150px', color: '#337E86', fontSize: '14px', textAlign: 'right' }}>{props.currencyBeautifier(stats.stat2.profits.avg)}</td>
                                    </tr>
                                  </td>
                                </tr>

                                <tr>
                                  {/* <td className="main-table-tbody-td-style" style={{ fontWeight: "bold" }}>Цены аукциона, сом</td> */}
                                  {/* <td className="main-table-tbody-td-style">{props.currencyBeautifier(stats.stat2.prices.max)}</td> */}
                                  {/* <td className="main-table-tbody-td-style">{props.currencyBeautifier(stats.stat2.prices.min)}</td> */}
                                  {/* <td className="main-table-tbody-td-style">{props.currencyBeautifier(stats.stat2.prices.avg)}</td> */}
                                </tr>
                                <tr>
                                  {/* <td className="main-table-tbody-td-style" style={{ fontWeight: "bold" }}>Доходность к погашению, %</td> */}
                                  {/* <td className="main-table-tbody-td-style">{props.currencyBeautifier(stats.stat2.profits.max)}</td> */}
                                  {/* <td className="main-table-tbody-td-style">{props.currencyBeautifier(stats.stat2.profits.min)}</td> */}
                                  {/* <td className="main-table-tbody-td-style">{props.currencyBeautifier(stats.stat2.profits.avg)}</td> */}
                                </tr>
                              </tbody>
                            </table>
                          </TabPanel>
                          <TabPanel value="3">
                            {userProfile.role.name === "MINFIN" && (auction.auctionStatusId === 5 || auction.auctionStatusId === 6 || auction.auctionStatusId === 7) &&
                              <table className="main-table-style">
                                {/* <tr>
                      <td colSpan="2" class="main-table-header-th-style-new" style={{ fontWeight: "bold", fontSize: "16px" }}>
                      Сводные ведомости заявок
                      </td>
                    </tr> */}
                                <tr>
                                  <td style={{ fontSize: '14px', fontWeight: 'bold', paddingRight: '20px' }}>Сводная ведомость заявок-1</td>
                                  <BootstrapTooltip title="Сводная ведомость заявок-1">

                                    <td className="main-table-tbody-td-style">

                                      <button
                                        className="approveButton"
                                        onMouseDown={() => downloadReport("docx", "1", "Сводная ведомость заявок-1")}
                                        style={{ width: "60px", background: "#1A58CB", height: "25px" }}
                                      >
                                        WORD
                                      </button>

                                    </td></BootstrapTooltip>
                                  <td className="main-table-tbody-td-style">
                                    <BootstrapTooltip title="Сводная ведомость заявок-1">
                                      <button
                                        className="approveButton"
                                        onMouseDown={() => downloadReport("pdf", "1", "Сводная ведомость заявок-1")}
                                        style={{ width: "50px", marginLeft: 10, background: "#EE8C31", height: "25px" }}
                                      >
                                        PDF
                                      </button>
                                    </BootstrapTooltip>
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ fontSize: '14px', fontWeight: 'bold', paddingRight: '20px' }}>Сводная ведомость заявок-2</td>
                                  <td className="main-table-tbody-td-style">
                                    <BootstrapTooltip title="Сводная ведомость заявок-2">
                                      <button
                                        className="approveButton"
                                        onMouseDown={() => downloadReport("docx", "2", "Сводная ведомость заявок-2")}
                                        style={{ width: "60px", background: "#1A58CB", height: "25px" }}
                                      >
                                        WORD
                                      </button>
                                    </BootstrapTooltip>
                                  </td>
                                  <td className="main-table-tbody-td-style">

                                    <BootstrapTooltip title="Сводная ведомость заявок-2">
                                      <button
                                        className="approveButton"
                                        onMouseDown={() => downloadReport("pdf", "2", "Сводная ведомость заявок-2")}
                                        style={{ width: "50px", marginLeft: 10, background: "#EE8C31", height: "25px" }}
                                      >
                                        PDF
                                      </button>
                                    </BootstrapTooltip>
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ fontSize: '14px', fontWeight: 'bold', paddingRight: '20px' }}>Сводная ведомость заявок - квалификация заявок</td>
                                  <td className="main-table-tbody-td-style">
                                    <BootstrapTooltip title="Сводная ведомость заявок - квалификация заявок">
                                      <button
                                        className="approveButton"
                                        onMouseDown={() => downloadReport("docx", "3", "Сводная ведомость заявок - квалификация заявок")}
                                        style={{ width: "60px", background: "#1A58CB", height: "25px" }}
                                      >
                                        WORD
                                      </button>
                                    </BootstrapTooltip>
                                  </td>
                                  <td className="main-table-tbody-td-style">
                                    <BootstrapTooltip title="Сводная ведомость заявок - квалификация заявок">
                                      <button
                                        className="approveButton"
                                        onMouseDown={() => downloadReport("pdf", "3", "Сводная ведомость заявок - квалификация заявок")}
                                        style={{ width: "50px", marginLeft: 10, background: "#EE8C31", height: "25px" }}
                                      >
                                        PDF
                                      </button>
                                    </BootstrapTooltip>
                                  </td>
                                </tr>
                              </table>

                              // <>
                              //   <button
                              //     className="approveButton"
                              //     onClick={() => downloadReport("docx")}
                              //     style={{ width: "270px", marginLeft: 10, background: "#1A58CB" }}
                              //   >
                              //     Загрузить сводную ведомость WORD
                              //   </button>
                              //   <button
                              //     className="approveButton"
                              //     onClick={() => downloadReport("pdf")}
                              //     style={{ width: "270px", marginLeft: 10, background: "#EE8C31" }}
                              //   >
                              //     Загрузить сводную ведомость PDF
                              //   </button>
                              // </>
                            }
                          </TabPanel>
                            </TabContext>
                          </Box>
                          <td style={{ verticalAlign: "top" }}>
                           
                          </td>
                          <td style={{ verticalAlign: "top" }}>
                            
                          </td>
                        </tr>
                      </table>
                    }

                    {auction.auctionStatusId === 5 && userProfile.role.name === "MINFIN" &&
                      <>
                        <button
                          className="approveButton"
                          onClick={() => approveAuction()}
                          style={{ width: "270px", marginLeft: 10 }}
                        >
                          Подтвердить результаты аукциона
                        </button>
                        <button
                          className="cancelButton"
                          onClick={() => declineAuctionResults()}
                          style={{ width: "270px", marginLeft: 10 }}
                        >
                          Отклонить результаты аукциона
                        </button>
                      </>
                    }
                    {auction.auctionStatusId === 7 && userProfile.role.name === "CD" &&
                      <>
                        <button
                          className="approveButton"
                          onClick={() => closeAuction()}
                          style={{ width: "270px", marginLeft: 10 }}
                        >
                          Закрытие аукциона
                        </button>
                        <button
                          className="approveButton"
                          onClick={() => returnByDepo()}
                          style={{ width: "270px", marginLeft: 10 }}
                        >
                          Возврат на пересчет в Минфин
                        </button>
                      </>
                    }
                  </>
                }

              </div>
            }
          </div>
        </Draggable >
      )
    }
    catch (er) {
      console.log("ERROR", er)
      return <div>Loading...</div>
    }
  }
  else return <div>Loading...</div>
}