import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
// import TextareaAutosize from '@mui/material/TextareaAutosize';
import TextField from '@material-ui/core/TextField';

import Checkbox from '@material-ui/core/Checkbox';
// Icons
// import IconButton from '@material-ui/core/IconButton';
// import AddIcon from '@mui/icons-material/AddCircleOutline';
// import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
// import CloseIcon from '@material-ui/icons/Close';
// import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { IoMdClose } from 'react-icons/io';
import { MdExpandMore } from 'react-icons/md';
import { MdExpandLess } from 'react-icons/md';
// CUSTOM COMPONENTS
// import ConfigurationFile from "../configuration/ConfigurationFile.json";
// Library
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import Draggable from 'react-draggable';
// import swal from 'sweetalert'; // https://sweetalert.js.org/guides/
// var moment = require('moment');
var fetch = require('node-fetch');

const useStyles = makeStyles((theme) => ({
  // resizeForm: {
  //   borderRadius: "7px",
  //   border: "1px solid #2B4D80",
  //   display: "flex",
  //   backgroundColor: "#F5F5F5",
  //   boxShadow: theme.shadows[1],
  //   "flex-flow": "column",
  //   width: "40%",
  //   resize: "both",
  //   overflow: "hidden",
  //   minWidth: "150px",
  //   minHeight: "150px",
  //   position: 'absolute',
  // },
  resizeForm: {
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    border: "1px solid #2B4D80",
    display: "flex",
    backgroundColor: "white",
    "flex-flow": "column",
    width: "600px",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "30px",
    background: "white",
    position: 'absolute',
  },
}))
function FloatFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      decimalSeparator={"."}
      thousandSeparator={" "}
      isNumericString
    />
  )
}
FloatFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
function IntegerFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={" "}
      isNumericString
    />
  );
}
IntegerFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

//Home(props) - получаем переменные от родителя App.js 
export default (props) => {
  const classes = useStyles()
  const [token] = useState(props.token)
  const [kseRESTApi] = useState(props.kseRESTApi) //Local KFB main REST
  const [userProfile, setUserProfile] = useState(props.userProfile)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [enumData, setEnumData] = useState({})
  const [enumOptions, setEnumOptions] = useState({})
  const [selectedOptions, setSelectedOptions] = useState({})
  const [tableKey, setTableKey] = useState(null)
  const [selectedLot] = useState(props.selectedLot)
  const [expanded, setExpanded] = useState(true)
  // FIELDS
  const [fieldValue, setFieldValue] = useState({
    accountId: null,
    organizationId: userProfile.organization.id,
    financeInstrumentId: selectedLot.financeInstrumentId,
    amountOfInstrument: null,
    amountOfInstrumentStep: 1,
    nonCompetitive: false,
    prefferedProfit: null,
    price: null
  })
  // const [orderBallance, setOrderBallance] = useState(null)
  const [position, setPosition] = useState({ x: 0, y: 0 })

  useEffect(async () => {
    console.log("Auction Bid Form PROPS", props)
    let enumDataToCollect = [
      { enumName: "financeInstrumentId", enumDef: "3e819d7e-25d0-4a04-a3ff-092fd348a375" },
      { enumName: "accountId", enumDef: "c324d86f-3a3b-43b2-9514-d983b2982794" },
      { enumName: "organizationId", enumDef: "5b78d9dd-821d-4c6a-a00a-3af85224fbc4" }
    ]
    let enums = await props.getEnumDataByList(enumDataToCollect)
    // console.log("ABF ENUMS", enums)
    setEnumData(enums)
    let eOpts = await props.createEnumOptions(enums)
    setEnumOptions(eOpts)
    let sOpts = { ...selectedOptions }
    let n = "organizationId"
    for (let d = 0; d < enums[n].length; d++) {
      if (enums[n][d].id === fieldValue.organizationId) {
        sOpts[n] = { "value": enums[n][d].id, "label": enums[n][d].label, "name": n }
      }
    }
    let f = "financeInstrumentId"
    for (let d = 0; d < enums[f].length; d++) {
      if (enums[f][d].id === fieldValue.financeInstrumentId) {
        sOpts[f] = { "value": enums[f][d].id, "label": enums[f][d].label, "name": f }
      }
    }
    setSelectedOptions(sOpts)
    // getOrderBallance(false)
    props.setComponentIndex(props.getComponentIndex() + 1)
  }, [])
  // random UUID generator
  function getUUID() {
    return uuidv4()
  }
  // INPUT HANDLERS
  // SELECT
  function handleSelectChange(option) {
    setSelectedOptions({ ...selectedOptions, [option.name]: option })
    setFieldValue({ ...fieldValue, [option.name]: option.value })
    console.log("OPT", option, fieldValue)
  }
  // INT
  const handleIntChange = (event) => {
    // console.log("EVENT", event.target.name, event.target.value)
    if (event.target.value !== "") {
      let stringNum = event.target.value.toString().replace(/ /g, '')
      let int = parseInt(stringNum)
      setFieldValue({ ...fieldValue, [event.target.name]: int })
    }
    else {
      setFieldValue({ ...fieldValue, [event.target.name]: null })
    }
  }
  function handleCheckboxChange(event) {
    let name = event.target.name
    let value = event.target.checked

    if (name === "nonCompetitive") {
      let newFields = { ...fieldValue }
      newFields[name] = value
      newFields.price = null
      newFields.prefferedProfit = null
      setFieldValue(newFields)
      // getOrderBallance(event.target.checked)
    }
    else {
      setFieldValue({ ...fieldValue, [name]: value })
    }
    // console.log("CHBX CHANGE", name, value, fieldValue)
    setTableKey(getUUID())
  }
  // Float input handler
  const handleFloatChange = async (event) => {
    // console.log("FLOAT CHANGE", event.target.name, event.target.value)
    if (event.target.name === "prefferedProfit") {
      let newFields = { ...fieldValue }
      newFields.prefferedProfit = parseFloat(event.target.value)
      if (event.target.value !== null && event.target.value !== "") {
        let body = {
          "auctionLotId": selectedLot.id,
          "prefferedProfit": parseFloat(event.target.value)
        }
        console.log("BODY", body)
        await fetch(
          kseRESTApi + "/api/Auction/CalcPrice",
          {
            "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
            "method": "POST",
            "body": JSON.stringify(body)
          }
        )
          .then(response => response.json())
          .then(function (res) {
            console.log("RES CALC", res)
            if (res.isSuccess === true) {
              newFields.price = res.data
            }
            else {
              props.callErrorToast(res.errors[0])
            }
          })
      }
      setFieldValue(newFields)
    }
    else {
      setFieldValue({ ...fieldValue, [event.target.name]: parseFloat(event.target.value) })
    }
  }
  // async function getOrderBallance(nonComp) {
  //   await fetch(
  //     kseRESTApi + `/api/Auction/GetOrderBalance/${selectedLot.id}/${nonComp === true ? "n" : "c"}`,
  //     {
  //       "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
  //     }
  //   )
  //     .then(response => response.json())
  //     .then(function (res) {
  //       console.log("RES BALLANCE", res)
  //       if (res.isSuccess === true) {
  //         setOrderBallance(res.data)
  //       }
  //       else {
  //         props.callErrorToast(res.errors[0])
  //       }
  //     })
  // }
  async function sendBid() {
    let body = {
      "auctionLotId": selectedLot.id,
      "accountId": fieldValue.accountId,
      "prefferedProfit": fieldValue.prefferedProfit,
      "amount": fieldValue.amount,
      "price": fieldValue.price,
      "nonCompetitive": fieldValue.nonCompetitive
    }
    console.log("BODY", body)
    await fetch(
      kseRESTApi + "/api/Auction/CreateLotRequest",
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
        "method": "POST",
        "body": JSON.stringify(body)
      }
    )
      .then(response => response.json())
      .then(function (res) {
        console.log("RES CREATE", res)
        if (res.isSuccess === true) {
          props.callSuccessToast("Заявка создана", 1100)
          props.setShowAuctionBidForm(false)
          props.setUpdateAuctionDetails(getUUID())
        }
        else {
          props.callErrorToast(res.errors[0])
        }
      })
  }
  function getModalStyle() {
    const top = 15;
    const left = 25;
    return {
      height: expanded === true ? "" : "40px",
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      zIndex: componentIndex + 1
    };
  }
  function formClick() {
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 1)
    props.setComponentIndex(currIndex + 1)
  }
  function positionHandler(e, data) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 30 // Take 30% of width
    let maxDeviationX = (dimension.w / 100) * 60 // Take 60% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    // console.log("minX", minDeviationX, "maxX", maxDeviationX, "maxY", maxDeviationY)
    if (data.y < maxDeviationY) {
      // console.log("SET MIN Y", data.x)
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      // console.log("SET MIN X", data.x, minDeviationX)
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      // console.log("SET MAX X", data.x)
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
  }
  return (
    // <Draggable
    //   handle="p"
    //   onStart={() => formClick()}
    //   onMouseDown={() => formClick()}
    // >
    //   <div style={getModalStyle()} className={classes.resizeForm} key={tableKey}>
    <Draggable
      handle="p"
      onStart={() => formClick()}
      onStop={positionHandler}
      position={position}
    >
      <div
        key={tableKey}
        className={classes.resizeForm}
        style={{ top: "15%", left: "30%", transform: "translate(-15%, -30%)", zIndex: componentIndex }}
        onClick={() => formClick()}
      >
        <p className="main-table-label-p-style-new">
          <table className="dragble-div-header-table-style-new">
            <tr>
              <td width="99%" className="dragble-div-header-td-style-new">Ввод заявки на участие в аукционе ГЦБ</td>
              {/* <td onClick={()=> props.setShowAuctionBidForm(false)}><IconButton size="small"><CloseIcon/></IconButton></td> */}
              {expanded == false
                ?
                <td>
                  <MdExpandLess size={22} className="form-expand-icons-style" onClick={() => setExpanded(!expanded)} />
                </td>
                :
                <td>
                  <MdExpandMore size={22} className="form-expand-icons-style" onClick={() => setExpanded(!expanded)} />
                </td>
              }
              <td>
                <IoMdClose size={22} className="form-close-icons-style" style={{ marginRight: "5px" }} onClick={() => props.setShowAuctionBidForm(false)} />
              </td>
            </tr>
          </table>
        </p>
        {expanded === true &&
          <div className="dragble-div-body-style">
            <table align="center" width="100%">
              <tr>
                <td width="15%" className="dragble-div-body-td-text-style">Торговый счёт</td>
                <td width="35%">
                  <Select
                    name={"accountId"}
                    placeholder={"Выбрать..."}
                    value={selectedOptions["accountId"]}
                    onChange={handleSelectChange}
                    options={enumOptions["accountId"]}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  />
                </td>
                <td width="15%" className="dragble-div-body-td-text-style">Код клиента</td>
                <td width="35%" height="10%">
                  <Select
                    name={"organizationId"}
                    placeholder={"Выбрать..."}
                    value={selectedOptions["organizationId"]}
                    onChange={handleSelectChange}
                    options={enumOptions["organizationId"]}
                    isDisabled={true}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  />
                </td>
              </tr>
            </table>
            <table width="100%">
              <tr>
                <td>
                  <Select
                    name={"financeInstrumentId"}
                    placeholder={"Найти инструмент..."}
                    value={selectedOptions["financeInstrumentId"]}
                    onChange={handleSelectChange}
                    options={enumOptions["financeInstrumentId"]}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    isDisabled={true}
                  />
                </td>
              </tr>
            </table>
            {/* <table align="center" width="100%">
              <tr>
                <td className="dragble-div-body-td-text-style" align="center">
                  Количество: {props.intBeautifier(fieldValue.nonCompetitive === true ? props.selectedLot.nonCompetitiveVolume : props.selectedLot.competitiveVolume)}
                </td>
              </tr>
            </table> */}
            <div overflow="auto">
              {/* Не конкурентная */}
              <table align="left" width="50%">
                <tr>
                  <td className="dragble-div-body-td-text-style">Не конкурентная
                    <Checkbox
                      size="small"
                      style={{ color: "#337E86" }}
                      name="nonCompetitive"
                      onChange={handleCheckboxChange}
                      checked={fieldValue.nonCompetitive === true ? true : false}
                    />
                  </td>
                </tr>
              </table>
              <table align="left" width="70%">
                {/* Количество */}
                <tr>
                  <td className="dragble-div-body-td-text-style">Количество</td>
                  <td>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="amount"
                      placeholder="0"
                      value={fieldValue.amount}
                      onChange={handleIntChange}
                      style={{ width: "150px" }}
                      InputProps={{ inputComponent: IntegerFormat }}
                    />
                    {/* <input
                      type='number'
                      style={{ width: 120 }}
                      value={fieldValue.amount}
                      onChange={handleIntChange}
                      name="amount"
                    /> */}
                  </td>
                </tr>
                {/* Прибыль */}
                <tr>
                  <td className="dragble-div-body-td-text-style">Желаемая доходность</td>
                  <td>
                    {/* <input
                      type='number'
                      step="0.01"
                      name="prefferedProfit"
                      style={{ width: 120 }}
                      defaultValue={fieldValue.prefferedProfit}
                      onBlur={handleFloatChange}
                      // value={fieldValue.prefferedProfit}
                      disabled={fieldValue.nonCompetitive === true ? true : false}
                    /> */}
                    <TextField
                      variant="outlined"
                      size="small"
                      name="prefferedProfit"
                      placeholder="0.0"
                      // value={fieldValue.prefferedProfit}
                      // onChange={handleFloatChange}
                      defaultValue={fieldValue.prefferedProfit}
                      onBlur={handleFloatChange}
                      style={{ width: "150px" }}
                      InputProps={{ inputComponent: IntegerFormat }}
                      disabled={fieldValue.nonCompetitive === true ? true : false}
                    />
                  </td>
                </tr>
                {/* Цена */}
                <tr>
                  <td className="dragble-div-body-td-text-style">Цена</td>
                  <td>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="price"
                      placeholder="0.0"
                      value={fieldValue.price}
                      onChange={handleFloatChange}
                      style={{ width: "150px" }}
                      InputProps={{ inputComponent: IntegerFormat }}
                      disabled={fieldValue.nonCompetitive === true ? true : false}
                    />
                    {/* <input
                      type='number'
                      step="0.01"
                      name="price"
                      style={{ width: 120 }}
                      defaultValue={fieldValue.price}
                      onBlur={handleFloatChange}
                      // value={fieldValue.price}
                      disabled={fieldValue.nonCompetitive === true ? true : false}
                    /> */}
                  </td>
                </tr>
              </table>
            </div>
            <table align="center" width="100%">
              <tr>
                <td width="100%" align="center">
                  <button
                    className="cancelButton"
                    style={{ marginRight: 20 }}
                    onClick={() => props.setShowAuctionBidForm(false)}
                  >
                    Отмена
                  </button>
                  <button
                    className="approveButton"
                    onClick={() => sendBid()}
                    style={{ marginRight: 20 }}
                  >
                    Отправить заявку
                  </button>
                </td>
              </tr>
            </table>
          </div>
        }
      </div>
    </Draggable>
  )
}