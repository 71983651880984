// Import material and react components
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// Table
import Table from "@material-ui/core/Table"; // Material ui table for usual form
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import IconButton from '@material-ui/core/IconButton';
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import GridSelect from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Drawer from "@mui/material/Drawer";
// Form components
import TextField from '@material-ui/core/TextField';
import Select from 'react-select'; // https://react-select.com/home
import Checkbox from '@material-ui/core/Checkbox';
// Accordion
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Icons
import { IoMdClose } from 'react-icons/io';
import CloseIcon from '@material-ui/icons/Close';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { BsArrowDown } from 'react-icons/bs';

// Custom components
import Buttons from "../configuration/Buttons.json";
import GridFormButtons from '../configuration/GridFormButtons.json';
// Style
import "../styles/generalStyles.css";
// Import libraries
import Draggable from 'react-draggable';
import swal from 'sweetalert'; // https://sweetalert.js.org/guides/
import { v4 as uuidv4 } from 'uuid';
var fetch = require('node-fetch');
// var generator = require('generate-password');
var moment = require('moment');

function FloatFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      decimalSeparator={"."}
      thousandSeparator={" "}
      isNumericString
    />
  );
}
FloatFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}
function IntegerFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator={" "}
      isNumericString
    />
  );
}
IntegerFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

const useStyles = makeStyles((theme) => ({
  resizeForm: {
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    border: "1px solid #2B4D80",
    display: "flex",
    "flex-flow": "column",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "150px",
    background: "white",
    position: 'absolute',
  },
  importFile: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  inputFile: {
    display: 'none',
  }
}))

export default (props) => {
  // This.state
  const classes = useStyles();
  const [kseRESTApi] = useState(props.kseRESTApi)
  const [token] = useState(props.token)
  const [taskVariables] = useState(props.taskVariables)
  const [userProfile] = useState(props.userProfile)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [Form, setForm] = useState(null)
  const [buttons, setButtons] = useState([])
  const [isSearchForm, setIsSearchForm] = useState(null)
  const [accordionExpanded, setAccordionExpanded] = useState(false)

  const [docList, setDocList] = useState(null)
  const [filteredDocList, setFilteredDocList] = useState(null)
  const [initialDocList, setInitialDocList] = useState(null)
  const [enumData, setEnumData] = useState({})
  const [enumOptions, setEnumOptions] = useState({})
  const [selectedOptions, setSelectedOptions] = useState([])
  const [gridForm, setGridForm] = useState(null)
  const [gridFormEnumData, setGridFormEnumData] = useState({})
  const [gridFormButtons, setGridFormButtons] = useState([])
  const [selectedItem, setSelectedItem] = useState({ id: null })
  const [fieldValue, setFieldValue] = useState({})
  const [formType, setFormType] = useState("edit")


  const [packageOrdTrd, setPackageOrdTrd] = useState(null)
  const [gridFormOrd, setGridFormOrd] = useState(null)
  const [enumDataOrd, setEnumDataOrd] = useState({})
  const [docListOrders, setDocListOrders] = useState([])
  const [ordError, setOrdError] = useState("")

  const [gridFormOrdDs, setGridFormOrdDs] = useState(null)
  const [enumDataOrdDs, setEnumDataOrdDs] = useState({})
  const [docListOrdersDs, setDocListOrdersDs] = useState([])
  const [ordDsError, setOrdDsError] = useState("")

  const [gridFormTrd, setGridFormTrd] = useState(null)
  const [enumDataTrd, setEnumDataTrd] = useState({})
  const [docListTrades, setDocListTrades] = useState([])
  const [trdError, setTrdError] = useState("")

  const [gridFormPos, setGridFormPos] = useState(null)
  const [enumDataPos, setEnumDataPos] = useState({})
  const [docListPos, setDocListPos] = useState([])

  const [gridFormPosDs, setGridFormPosDs] = useState(null)
  const [enumDataPosDs, setEnumDataPosDs] = useState({})
  const [docListPosDs, setDocListPosDs] = useState([])

  // PAGINATION
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [showSnackBar, setShowSnackBar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState("")

  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [tableKey, setTableKey] = useState(getUUID())
  // const [position, setPosition] = useState(props.taskVariables.position)

  const [openSearch, setOpenSearch] = useState(false)
  // const [accordionState, setAccordionState] = useState({})

  const [loadDataFromFilePosId, setLoadDataFromFilePosId] = useState(null)
  const [loadDataFromFilePosDsId, setLoadDataFromFilePosDsId] = useState(null)
  const [loadDataFromFileTRDId, setLoadDataFromFileTRDId] = useState(null)


  const [filePos, setFilePos] = useState(null)
  const [filePosDs, setFilePosDs] = useState(null)
  const [fileTrd, setFileTrd] = useState(null)


  useEffect(async () => {
    console.log("TRDs PROPS", props)
    if (props.taskType === "showPackagesExchanceWithCD") {
      let form = await props.fetchForm(taskVariables.formDefId)
      setForm(form)
      let enums = await props.getEnumDataByForm(form)
      setEnumData(enums)
      console.log("MAIN EN", enums)
      setEnumOptions(await props.createEnumOptions(enums))
      setButtons(Buttons[userProfile.role.name][taskVariables.buttons])
      console.log("BUTTONS", Buttons[userProfile.role.name][taskVariables.buttons])
      if (taskVariables.gridFormDefId !== null) {
        let gForm = await props.fetchForm(taskVariables.gridFormDefId)
        setGridForm(gForm)
        let gEnums = await props.getEnumDataByForm(gForm)
        setGridFormEnumData(gEnums)
        let docL = await getORDandTRDList()
        console.log("DOCL TRDS", docL)
        let sortedDocList = docL.sort(dynamicSort("id", 0, "Int"))
        setFilteredDocList(sortedDocList)
        setInitialDocList(sortedDocList)
        fetchBySize(0, 9, sortedDocList)
        setGridFormButtons(GridFormButtons[userProfile.role.name][taskVariables.gridFormButtons])
        setIsSearchForm(true)
        console.log("GF BTNS", GridFormButtons[userProfile.role.name][taskVariables.gridFormButtons])
      }

    }
    else if (props.taskType === "showExchangePackage") {
      console.log("showExchangePackage")
      let form = await props.fetchForm("4283097e-9e92-470e-9265-0bbefbb38fcc")
      setForm(form)
      setButtons(Buttons[userProfile.role.name]["ExchangeWithCDPackageViewBtns"])

      let formOrd = await props.fetchForm("013b6a0a-1a28-416c-9086-395e28d9bf0b")
      setEnumDataOrd(await props.getEnumDataByForm(formOrd))
      setGridFormOrd(formOrd)

      let formOrdDs = await props.fetchForm("10891ed2-9dea-48cc-9c07-23d804d34858")
      setEnumDataOrdDs(await props.getEnumDataByForm(formOrdDs))
      setGridFormOrdDs(formOrdDs)
      console.log("formOrdDs", formOrdDs)

      let formTrd = await props.fetchForm("4829aed1-ea56-4314-92f3-35e273406360")
      setEnumDataTrd(await props.getEnumDataByForm(formTrd))
      setGridFormTrd(formTrd)

      let docL = await props.fetchDocList("/api/DepositoryExchanges/Get?id=" + taskVariables.selectedDoc.id)
      console.log("DOCL ORD TRD", docL)
      setPackageOrdTrd(docL)
      setDocListOrders(docL.ord)
      setOrdError(docL.ordError)
      setDocListOrdersDs(docL.ordds)
      // setOrdDsError(docL.orddserror)
      setDocListTrades(docL.trd)
      setTrdError(docL.trdError)
    }
    else if (props.taskType === "showPackagesExchanceWithCDPos") {
      console.log("EXCH POS", props.taskType)
      let form = await props.fetchForm(taskVariables.formDefId)
      setForm(form)
      let enums = await props.getEnumDataByForm(form)
      setEnumData(enums)
      // console.log("MAIN EN", enums)
      setEnumOptions(await props.createEnumOptions(enums))
      setButtons(Buttons[userProfile.role.name][taskVariables.buttons])
      // console.log("BUTTONS", Buttons[userProfile.role.name][taskVariables.buttons])
      if (taskVariables.gridFormDefId !== null) {
        let gForm = await props.fetchForm(taskVariables.gridFormDefId)
        setGridForm(gForm)
        let gEnums = await props.getEnumDataByForm(gForm)
        setGridFormEnumData(gEnums)
        let docL = await getPosList()
        console.log("DOCL POS", docL)
        let sortedDocList = docL.sort(dynamicSort("id", 0, "Int"))
        setFilteredDocList(sortedDocList)
        setInitialDocList(sortedDocList)
        fetchBySize(0, 9, sortedDocList)
        setGridFormButtons(GridFormButtons[userProfile.role.name][taskVariables.gridFormButtons])
        setIsSearchForm(true)
        console.log("GF BTNS", GridFormButtons[userProfile.role.name][taskVariables.gridFormButtons])
      }
    }
    else if (props.taskType === "showPos") {
      let form = await props.fetchForm("c9f2f0f3-633a-4c0e-b926-069254a0de54")
      setForm(form)
      setButtons(Buttons[userProfile.role.name]["ExchangeWithCDPosViewBtns"])

      let formPos = await props.fetchForm("8cb7e631-441d-4f79-9ff7-3033784bc3d3")
      setEnumDataPos(await props.getEnumDataByForm(formPos))
      setGridFormPos(formPos)

      let formPosDs = await props.fetchForm("0f383e58-22a7-4be3-8066-3d0b8696fcb3")
      setEnumDataPosDs(await props.getEnumDataByForm(formPosDs))
      setGridFormPosDs(formPosDs)

      let docL = await props.fetchDocList("/api/DepositoryExchanges/GetPos?id=" + taskVariables.selectedDoc.id)
      console.log("DOCL POS", docL)
      setDocListPos(docL.pos)
      setDocListPosDs(docL.posds)
    }
    else if (props.taskType === "showLoadPackageFromFile") {
      setLoadDataFromFilePosId(getUUID())
      setLoadDataFromFilePosDsId(getUUID())
      setButtons(Buttons[userProfile.role.name]["LoadPackageFromFileBtns"])

      let formPos = await props.fetchForm("8cb7e631-441d-4f79-9ff7-3033784bc3d3")
      setEnumDataPos(await props.getEnumDataByForm(formPos))
      setGridFormPos(formPos)

      let formPosDs = await props.fetchForm("0f383e58-22a7-4be3-8066-3d0b8696fcb3")
      setEnumDataPosDs(await props.getEnumDataByForm(formPosDs))
      setGridFormPosDs(formPosDs)

      setDocListPos([])
      setDocListPosDs([])
    }
    else if (props.taskType === "showLoadTRDResult") {
      setLoadDataFromFileTRDId(getUUID())
      setButtons(Buttons[userProfile.role.name]["LoadPackageFromFileTRDBtns"])
      let formTRD = await props.fetchForm("7d7d8956-ad88-4da3-ac53-163418696c40")
      setGridFormTrd(formTRD)
      setDocListTrades([])
    }

    if (props.taskVariables.position !== undefined && props.taskVariables.position !== null) {
      // console.log("POSITION", props.taskVariables.position)
      setPosition(props.taskVariables.position)
    }
    props.setComponentIndex(props.getComponentIndex() + 1)
  }, [])
  async function getORDandTRDList() {
    var docL = await fetch(
      kseRESTApi + "/api/DepositoryExchanges/List",
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
        "method": "POST",
        "body": JSON.stringify({})
      }
    )
      .then(response => response.json())
      .then(function (res) {
        return res.data
      })
      .catch(function (error) {
        return console.log("Collecting enum data error: ", error)
      })
    return docL
  }
  async function getPosList() {
    var docL = await fetch(
      kseRESTApi + "/api/DepositoryExchanges/ListPos",
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
        "method": "POST",
        "body": JSON.stringify({})
      }
    )
      .then(response => response.json())
      .then(function (res) {
        return res.data
      })
      .catch(function (error) {
        return console.log("Collecting enum data error: ", error)
      })
    return docL
  }

  function dynamicSort(property, sortOrder, type) {
    if (type === "DateTime" || type === "Bool") {
      sortOrder = sortOrder * -1
    }
    if (type === "DateTime") {
      return function (a, b) {
        if (a[property] !== null && b[property] !== null) {
          let dateA = new Date(a[property].substring(0, 19))
          let timeInSecA = dateA.getTime() / 1000
          // console.log("timeInSecA", timeInSecA)
          let dateB = new Date(b[property].substring(0, 19))
          let timeInSecB = dateB.getTime() / 1000
          // console.log("timeInSecB", timeInSecB)
          var result = (timeInSecA < timeInSecB) ? -1 : (timeInSecA > timeInSecB) ? 1 : 0
          return result * sortOrder
        }
        else {
          if (a[property] === null) {
            return -1 * sortOrder
          }
          return 1 * sortOrder
        }
      }
    }
    else if (type === "Int" || type === "Text" || type === "Float" || type === "Bool") {
      return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
        return result * sortOrder
      }
    }
    else if (type === "Enum") {
      return function (a, b) {
        if (a[property] === null) {
          return 1 * sortOrder
        }
        else {
          let labelA = getEnumLabel(property, a[property])
          // console.log("A", property, a[property], labelA)
          let labelB = getEnumLabel(property, b[property])
          // console.log("labelB", labelB, property, b)
          var result = (labelA < labelB) ? -1 : (labelA > labelB) ? 1 : 0
          return result * sortOrder
        }
      }
    }
  }
  // random UUID generator
  function getUUID() {
    return uuidv4()
  }
  // // random numbers generator
  // function keyGen(length) {
  //   var password = generator.generate({
  //     length: length,
  //     numbers: true
  //   })
  //   return password
  // }
  // filter docList by filled parameters
  function filterDocList(fetchFrom, fetchTo, Data, fields) {
    var newDocList = []
    if (Object.keys(fields).length === 0) {
      newDocList = Data
    }
    else {
      for (let i = 0; i < Data.length; i++) {
        let match = false
        for (let key in fields) {
          if (fields[key] === null || fields[key] === "" || fields[key] === undefined) {
            match = true
          }
          else {
            if (Data[i][key] !== null) {
              let contentType = getContentType(key)
              if (contentType === "Text") {
                try {
                  let searchField = fields[key].toLowerCase()
                  let dataField = Data[i][key].toLowerCase()
                  let includeSearch = dataField.includes(searchField)
                  if (includeSearch === true) {
                    match = true
                  }
                  else {
                    match = false
                    break
                  }
                }
                catch (er) {
                  console.log("ERR", er)
                  match = true
                }
              }
              else if (contentType === "Int" || contentType === "Float") {
                if (fields[key] >= 0 || fields[key] < 0) {
                  let searchField = fields[key].toString()
                  let dataField = Data[i][key].toString()
                  let includeSearch = dataField.includes(searchField)
                  if (includeSearch === true) {
                    match = true
                  }
                  else {
                    match = false
                    break
                  }
                }
                else { match = true }
              }
              else if (contentType === "Enum") {

                if (fields[key] === Data[i][key]) {
                  // console.log("ENUMS", key, fields[key], Data[i][key])
                  match = true
                }
                else {
                  match = false
                  break
                }
              }
              else if (contentType === "DateTime") {
                let searchField = moment(fields[key]).format('YYYY-MM-DD')
                let dataField = moment(Data[i][key]).format('YYYY-MM-DD')
                // console.log("DATES", searchField, dataField, searchField.includes(dataField))
                if (searchField === "NaN-NaN-NaN") {
                  match = true
                }
                else {
                  if (searchField.includes(dataField) === true) {
                    match = true
                  }
                  else {
                    match = false
                    break
                  }
                }
              }
              else if (contentType === "Bool") {
                if (fields[key] === Data[i][key]) {
                  match = true
                }
                else {
                  match = false
                  break
                }
              }
            }
            else {
              match = false
              break
            }
          }
        }
        if (match === true) {
          newDocList.push(Data[i])
        }
      }
    }
    setFilteredDocList(newDocList)
    fetchBySize(fetchFrom, fetchTo, newDocList)
  }
  // get rows amount of filtered docList by size
  function fetchBySize(fetchFrom, fetchTo, Data) {
    // console.log("fetchFrom", "fetchTo")
    let newDocList = []
    for (let i = fetchFrom; i <= fetchTo; i++) {
      if (Data[i] !== undefined) {
        newDocList.push(Data[i])
      }
    }
    // setFilteredDocList(Data)
    setDocList(newDocList)
  }
  function handleExpandAccordion() {
    setAccordionExpanded(!accordionExpanded)
  }

  function getGridFormItems(docListItem, contentItem, enums) {
    let value = docListItem[contentItem.name]
    let name = contentItem.name
    let type = contentItem.type
    if (type === "Enum") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="center" style={{ color: "black", fontSize: 12 }}>-</td>)
      }
      else {
        return getEnumLabel(name, value, enums)
      }
    }
    else if (type === "DateTime") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="center" style={{ color: "black", fontSize: 12 }}>-</td>)
      }
      else {
        let d = moment(value).format("DD.MM.YYYY HH:MM")
        return d === "Invalid date" ? value : d
      }
    }
    else {
      if (value === null || value === "" || value === undefined) {
        return (<td align="center" style={{ color: "black", fontSize: 12 }}>-</td>)
      }
      else {
        return (<td>{value}</td>)
      }
    }
  }
  function getEnumLabel(name, id, enums) {
    if (enums[name]) {
      for (let d = 0; d < enums[name].length; d++) {
        if (enums[name][d].id === id) {
          return enums[name][d].label
        }
      }
    }
    else {
      return ""
    }
  }

  function getForm() {
    return (
      <Grid container direction="row" justify="center" spacing={0}>
        <Grid item xs={isSearchForm === true ? 12 : 8}>
          <Table class="detail-table-style" width="100%">
            <Grid container direction="row" justify="center" alignItems="center">
              {Form.sections.map(section => {
                return sectionBuilder(section)
              })}
            </Grid>
            {/* <Grid direction="row" align="center">
              {buttons.map((button, index) => (
                <Button
                  // class="detailFormBtns"
                  name={button.name}
                  variant="outlined"
                  onClick = {() => buttonClick(button.name, null)}
                  style={{
                    margin: 3,
                    color: button.fontColor,
                    backgroundColor: button.backgroundColor,
                    height: 30,
                    fontSize: 12
                  }}                  
                >{button.label}
                </Button>
              )
              )}
            </Grid> */}
          </Table>
        </Grid>
      </Grid>
    )
  }
  // Create sections with labels and call bodyBuilder function
  function sectionBuilder(section) {
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              class="sectionBuilderStyle">
              {section.label}
            </TableCell>
          </TableRow>
        </TableHead>
        {bodyBuilder(section)}
      </Table>
    )
  }
  // Create body of each section and call contentBuilder function
  function bodyBuilder(section) {
    return (
      <Table size="small">
        <TableBody>
          {section.contents.map(contentItem => (
            contentItem.show === true &&
            <TableRow>
              <TableCell
                class="bodyBuilderStyle"
                width="40%"
              >
                {contentItem.label}
              </TableCell>
              <TableCell
                align="left"
                class="bodyBuilderStyle"
                width="60%"
              >
                {contentBuilder(contentItem)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }
  // Creating components by it's type
  function contentBuilder(contentItem) {
    if (contentItem.type === "Text") {
      return (
        <TextField
          onKeyPress={onKeyPressText}
          onBlur={handleTextChange}
          name={contentItem.name}
          style={{ width: "100%" }}
          disabled={(formType === "view" || contentItem.edit === false) ? true : false}
          defaultValue={(fieldValue[contentItem.name]) ? fieldValue[contentItem.name] : ""}
        />
      )
    }
    else if (contentItem.type === "Enum") {
      let selectedOption = {
        "value": "",
        "label": "Пусто",
        "name": contentItem.name
      }
      if (fieldValue[contentItem.name] !== undefined) {
        for (let i = 0; i < enumOptions[contentItem.name].length; i++) {
          if (parseInt(fieldValue[contentItem.name]) === enumOptions[contentItem.name][i].value) {
            selectedOption = enumOptions[contentItem.name][i]
            break
          }
        }
      }
      if (selectedOptions.length !== 0) {
        for (let i = 0; i < selectedOptions.length; i++) {
          if (contentItem.name === selectedOptions[i].name) {
            selectedOption = selectedOptions[i]
          }
        }
      }
      return (
        <Select
          name={contentItem.name}
          value={selectedOption}
          onChange={handleSelectChange}
          options={enumOptions[contentItem.name]}
          isDisabled={(formType === "view" || contentItem.edit === false) ? true : false}
          menuPortalTarget={document.body}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        />
      )
    }
    else if (contentItem.type === "Bool") {
      return (
        <Checkbox
          style={{ maxWidth: 20, height: 15, color: (formType === "view" || contentItem.edit === false) ? "grey" : "green" }}
          name={contentItem.name}
          onChange={handleCheckboxChange}
          disabled={(formType === "view" || contentItem.edit === false) ? true : false}
          checked={(fieldValue[contentItem.name] === false || fieldValue[contentItem.name] === null || fieldValue[contentItem.name] === undefined) ? false : true}
        />
      )
    }

    else if (contentItem.type === "Int") {
      return (
        <TextField
          name={contentItem.name}
          value={(fieldValue[contentItem.name])}
          onChange={handleIntChange}
          onKeyPress={onKeyPressInt}
          disabled={(formType === "view" || contentItem.edit === false) ? true : false}
          style={{ width: "100%" }}
          InputProps={{ inputComponent: IntegerFormat }}
        />
      )
    }
    else if (contentItem.type === "Float") {
      // console.log("FLOAT VAL", fieldValue[contentItem.name])
      return (
        <TextField
          onKeyPress={onKeyPressFloat}
          name={contentItem.name}
          defaultValue={fieldValue[contentItem.name]}
          onBlur={handleFloatChange}
          // value={fieldValue[contentItem.name]}
          style={{ width: "100%" }}
          disabled={(formType === "view" || contentItem.edit === false) ? true : false}
          InputProps={{ inputComponent: FloatFormat }}
        />
      )
    }
    else if (contentItem.type === "DateTime") {
      return (
        <TextField
          onKeyPress={onKeyPressDateTime}
          type="date"
          name={contentItem.name}
          onBlur={handleDateTimeChange}
          style={{ width: "100%" }}
          defaultValue={(fieldValue[contentItem.name] !== undefined) ? moment(fieldValue[contentItem.name]).format('YYYY-MM-DD') : ""}
          disabled={(formType === "view" || contentItem.edit === false) ? true : false}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )
    }
  }
  const handleCheckboxChange = (event) => {
    console.log("CHBX", event.target.name, event.target.checked)
    setFieldValue({ ...fieldValue, [event.target.name]: event.target.checked })
  }
  // Integer input handler
  const handleIntChange = (event) => {
    // console.log("EVENT", event.target.name, event.target.value)
    if (event.target.value !== "") {
      let stringNum = event.target.value.toString().replace(/ /g, '')
      let int = parseInt(stringNum)
      setFieldValue({ ...fieldValue, [event.target.name]: int })
    }
    else {
      setFieldValue({ ...fieldValue, [event.target.name]: null })
    }
  }
  // Float input handler
  const handleFloatChange = (event) => {
    // console.log("FLOAT CHANGE", event.target.name, event.target.value, fieldValue)
    // setFieldValue({ ...fieldValue, [event.target.name]: parseFloat(event.target.value) })
    if (event.target.value !== "") {
      let stringNum = event.target.value.toString().replace(/ /g, '')
      let float = parseFloat(stringNum)
      setFieldValue({ ...fieldValue, [event.target.name]: float })
      // console.log("FLOAT CHANGE", event.target.name, float)
    }
    else {
      setFieldValue({ ...fieldValue, [event.target.name]: event.target.value })
    }
  }
  // Text input handler
  function handleTextChange(event) {
    // console.log("EVENT", event.target.name, event.target.value)
    fieldValue[event.target.name] = event.target.value
    setFieldValue(fieldValue)
    console.log("FIELDVALUE", fieldValue)
  }
  function handleSelectChange(option) {
    fieldValue[option.name] = option.value
    console.log("OPT", option.name, option.value)
    var updateSelectedOptions = selectedOptions.slice()
    let noSuchOption = true
    for (let i = 0; i < updateSelectedOptions.length; i++) {
      if (option.name === updateSelectedOptions[i].name) {
        updateSelectedOptions[i] = option
        noSuchOption = false
        setSelectedOptions(updateSelectedOptions)
        break
      }
      else {
        noSuchOption = true
      }
    }
    if (noSuchOption === true) {
      updateSelectedOptions.push(option)
      setSelectedOptions(updateSelectedOptions)
    }
  }
  function handleDateTimeChange(event) {
    let date = moment(event.target.value).format()
    setFieldValue({ ...fieldValue, [event.target.name]: date })
    console.log("DATE CHANGE", date)
  }
  function getContentType(key) {
    for (let s = 0; s < Form.sections.length; s++) {
      for (let c = 0; c < Form.sections[s].contents.length; c++) {
        if (key === Form.sections[s].contents[c].name) {
          return Form.sections[s].contents[c].type
        }
      }
    }
  }
  async function buttonClick(buttonName, item) {
    console.log("ITEM", item)
    if (buttonName === "findDocument") {
      // console.log("findDocument")
      if (openSearch === true) {
        filterDocList(0, size - 1, initialDocList, fieldValue)
      }
      setOpenSearch(!openSearch)
    }
    else if (buttonName === "showExchangePackage") {
      let taskVars = {
        taskType: "showExchangePackage",
        key: taskVariables.key,
        component: "exchangeORDAndTRD",
        position: position,
        formDefId: null,
        buttons: null,
        selectedDoc: item,
        gridFormDefId: null,
        gridFormButtons: null
      }
      props.setNewTask(taskVars)
      console.log("button showExchangePackage: ", taskVars)
    }
    else if (buttonName === "showLoadTRDResult") {
      let taskVars = {
        taskType: "showLoadTRDResult",
        key: taskVariables.key,
        component: "exchangeORDAndTRD",
        position: position,
        formDefId: null,
        buttons: null,
        selectedDoc: props.taskVariables.selectedDoc,
        gridFormDefId: null,
        gridFormButtons: null
      }
      props.setNewTask(taskVars)
      console.log("button showLoadTRDResult: ", taskVars)
    }
    else if (buttonName === "importTRDResult") {
      // console.log("TRD FILE", filePos)
      if (fileTrd !== null) {
        let formDataTrd = new FormData()
        formDataTrd.append("fileUpload", fileTrd, fileTrd.name)
        await fetch(
          kseRESTApi + "/api/DepositoryExchanges/UploadTrdResult?pkgId=" + props.taskVariables.selectedDoc.id,
          {
            "headers": { "Authorization": "Bearer " + token },
            "method": "POST",
            "body": formDataTrd,
            "redirect": 'follow',
            // "mode": 'no-cors'
          }
        )
          .then(response => response.json())
          .then(function (res) {
            console.log("RES IMPORT POS", res)
            if (res.isSuccess === true) {
              props.callSuccessToast("Результат клиринга обработан!")
              buttonClick("backToExchangePackageForm")
              props.setUpdateAccountsInstrumentPositions(getUUID())
              props.setUpdateAccountsCurrenciesPositions(getUUID())
            }
            else {
              props.callErrorToast(res.errors[0])
            }
          })
          .catch(function (error) {
            return console.log("Import pos error: ", error)
          })
      }
    }
    else if (buttonName === "backToExchangePackageForm") {
      let taskVars = {
        taskType: "showExchangePackage",
        key: taskVariables.key,
        component: "exchangeORDAndTRD",
        position: position,
        formDefId: null,
        buttons: null,
        selectedDoc: props.taskVariables.selectedDoc,
        gridFormDefId: null,
        gridFormButtons: null
      }
      props.setNewTask(taskVars)
      console.log("button showExchangePackage: ", taskVars)
    }


    else if (buttonName === "backToPackagesSearchForm") {
      let taskVars = {
        taskType: "showPackagesExchanceWithCD",
        key: taskVariables.key,
        component: "exchangeORDAndTRD",
        position: position,
        formDefId: "a3792487-869a-488d-973e-68363dae210f",
        buttons: "ExchangeWithCDPackagesSearchBtns",
        gridFormDefId: "0352f8db-c643-4f96-8d6d-74a83428b19a",
        gridFormButtons: "ExchangeWithCDPackagesGridBtns"
      }
      props.setNewTask(taskVars)
      console.log("button showPackagesExchanceWithCD: ", taskVars)
    }
    else if (buttonName === "createNewPackageGov") {
      await fetch(
        kseRESTApi + "/api/DepositoryExchanges/CreateNewPackage?marketId=7",
        {
          "headers": { "content-type": "application/json", "Authorization": "Bearer " + token }
        }
      )
        .then(response => response.json())
        .then(function (res) {
          console.log("RES CREATE PCKG", res)
          if (res.isSuccess === true) {
            props.callSuccessToast("Пакет ГЦБ создан!")
            buttonClick("backToPackagesSearchForm", null)
            console.log("button createNewPackage: ")
          }
          else {
            props.callErrorToast(res.errors[0])
          }
        })
        .catch(function (error) {
          return console.log("Creating package error: ", error)
        })
    }
    else if (buttonName === "createNewPackage") {
      await fetch(
        kseRESTApi + "/api/DepositoryExchanges/CreateNewPackage?marketId=3",
        {
          "headers": { "content-type": "application/json", "Authorization": "Bearer " + token }
        }
      )
        .then(response => response.json())
        .then(function (res) {
          console.log("RES CREATE PCKG", res)
          if (res.isSuccess === true) {
            props.callSuccessToast("Пакет КЦБ создан!")
            buttonClick("backToPackagesSearchForm", null)
            console.log("button createNewPackage: ")
          }
          else {
            props.callErrorToast(res.errors[0])
          }
        })
        .catch(function (error) {
          return console.log("Creating package error: ", error)
        })
    }
    else if (buttonName === "loadPackageFromFile") {
      let taskVars = {
        taskType: "showLoadPackageFromFile",
        key: taskVariables.key,
        component: "exchangeORDAndTRD",
        position: position,
        formDefId: null,
        buttons: null,
        gridFormDefId: null,
        gridFormButtons: null
      }
      props.setNewTask(taskVars)
      console.log("button showPackagesExchanceWithCD: ", taskVars)
    }
    else if (buttonName === "importPackageOffline") {
      console.log("POS FILE", filePos)
      if (filePos !== null) {
        let formDataPos = new FormData()
        formDataPos.append("fileUpload", filePos, filePos.name)
        await fetch(
          kseRESTApi + "/api/DepositoryExchanges/ImportPosOffline",
          {
            "headers": { "Authorization": "Bearer " + token },
            "method": "POST",
            "body": formDataPos,
            "redirect": 'follow',
            // "mode": 'no-cors'
          }
        )
          .then(response => response.json())
          .then(function (res) {
            console.log("RES IMPORT POS", res)
            if (res.isSuccess === true) {
              props.callSuccessToast("Позиции ЦБ импортированы!")
              buttonClick("backToPosSearchForm")
              props.setUpdateAccountsInstrumentPositions(getUUID())
            }
            else {
              props.callErrorToast(res.errors[0])
            }
          })
          .catch(function (error) {
            return console.log("Import pos error: ", error)
          })
      }
      if (filePosDs !== null) {
        let formDataPosDs = new FormData()
        formDataPosDs.append("fileUpload", filePosDs, filePosDs.name)
        await fetch(
          kseRESTApi + "/api/DepositoryExchanges/ImportPosDsOffline",
          {
            "headers": { "Authorization": "Bearer " + token },
            "method": "POST",
            "body": formDataPosDs,
            "redirect": 'follow',
            // "mode": 'no-cors'
          }
        )
          .then(response => response.json())
          .then(function (res) {
            console.log("RES IMPORT POS", res)
            if (res.isSuccess === true) {
              props.callSuccessToast("Позиции ДС импортированы!")
              buttonClick("backToPosSearchForm")
              props.setUpdateAccountsCurrenciesPositions(getUUID())
            }
            else {
              props.callErrorToast(res.errors[0])
            }
          })
          .catch(function (error) {
            return console.log("Import pos error: ", error)
          })
      }
    }
    else if (buttonName === "sendPackageToCD") {
      // props.callSuccessToast("Пакет отправлен!")
      // buttonClick("backToPackagesSearchForm")
      await fetch(
        kseRESTApi + "/api/DepositoryExchanges/Send?id=" + taskVariables.selectedDoc.id,
        {
          "headers": { "content-type": "application/json", "Authorization": "Bearer " + token }
        }
      )
        .then(response => response.json())
        .then(function (res) {
          console.log("RES CREATE PCKG", res)
          if (res.isSuccess === true) {
            props.callSuccessToast("Пакет отправлен!")
            buttonClick("backToPackagesSearchForm")
          }
          else {
            props.callErrorToast(res.errors[0])
          }
        })
        .catch(function (error) {
          return console.log("Sending package error: ", error)
        })
    }
    else if (buttonName === "sendPackageToCDOffline") {
      // props.callSuccessToast("Пакет отправлен!")
      // buttonClick("backToPackagesSearchForm")
      await fetch(
        kseRESTApi + "/api/DepositoryExchanges/SendOffline?id=" + taskVariables.selectedDoc.id,
        {
          "headers": { "content-type": "application/json", "Authorization": "Bearer " + token }
        }
      )
        .then(response => response.json())
        .then(function (res) {
          console.log("RES SEND PCKG", res)
          if (res.isSuccess === true) {
            props.callSuccessToast("Пакет отправлен Offline!")
            buttonClick("backToPackagesSearchForm")
            props.setUpdateAccountsCurrenciesPositions(getUUID())
            props.setUpdateAccountsInstrumentPositions(getUUID())
          }
          else {
            props.callErrorToast(res.errors[0])
          }
        })
        .catch(function (error) {
          return console.log("Sending package error: ", error)
        })
    }
    else if (buttonName === "deletePackage") {
      if (taskVariables.selectedDoc.status === 0) {
        swal({
          text: "Вы точно хотите удалить пакет?",
          icon: "warning",
          buttons: { yes: "Да", cancel: "Отмена" }
        })
          .then(async (click) => {
            if (click === "yes") {
              await fetch(
                kseRESTApi + "/api/DepositoryExchanges/Delete?id=" + taskVariables.selectedDoc.id,
                {
                  "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
                  "method": "DELETE"
                }
              )
                .then(response => response.json())
                .then(function (res) {
                  console.log("RES DELETE PCKG", res)
                  if (res.isSuccess === true) {
                    props.callSuccessToast("Пакет удален!")
                    buttonClick("backToPackagesSearchForm")
                  }
                  else {
                    props.callErrorToast(res.errors[0])
                  }
                })
                .catch(function (error) {
                  return console.log("Deleting package error: ", error)
                })
            }
          })

      }
      else {
        props.callErrorToast("Пакет не может быть удален!")
      }
    }
    else if (buttonName === "showPos") {
      let taskVars = {
        taskType: "showPos",
        key: taskVariables.key,
        component: "exchangeORDAndTRD",
        position: position,
        formDefId: null,
        buttons: null,
        selectedDoc: item,
        gridFormDefId: null,
        gridFormButtons: null
      }
      props.setNewTask(taskVars)
      console.log("button showPos: ", taskVars)
    }
    else if (buttonName === "backToPosSearchForm") {
      let taskVars = {
        taskType: "showPackagesExchanceWithCDPos",
        key: taskVariables.key,
        component: "exchangeORDAndTRD",
        position: position,
        formDefId: "b42832cf-11d2-4016-b0e1-74d90862f706",
        buttons: "ExchangeWithCDPosSearchBtns",
        gridFormDefId: "688d95bd-acdc-4b60-9494-d54e9ab5c7a7",
        gridFormButtons: "ExchangeWithCDPosGridBtns"
      }
      props.setNewTask(taskVars)
      console.log("button backToPosSearchForm: ", taskVars)
    }
    else if (buttonName === "importPos") {
      // if(taskVariables.selectedDoc.status === 0){
      swal({
        text: "Вы точно хотите импортировать пакет?",
        icon: "warning",
        buttons: { yes: "Да", cancel: "Отмена" }
      })
        .then(async (click) => {
          if (click === "yes") {
            await fetch(
              kseRESTApi + "/api/DepositoryExchanges/AcceptIncoming?id=" + taskVariables.selectedDoc.id,
              {
                "headers": { "content-type": "application/json", "Authorization": "Bearer " + token }
              }
            )
              .then(response => response.json())
              .then(function (res) {
                console.log("RES POS accept", res)
                if (res.isSuccess === true) {
                  props.callSuccessToast("Пакет импортирован!")
                  buttonClick("backToPosSearchForm")
                }
                else {
                  props.callErrorToast(res.errors[0])
                }
              })
              .catch(function (error) {
                return console.log("Accepting package error: ", error)
              })
          }
        })

      // }
      // else{
      //   props.callErrorToast("Пакет не может быть удален!")
      // }
    }
    else if (buttonName === "deletePos") {
      // if(taskVariables.selectedDoc.status === 0){
      swal({
        text: "Вы точно хотите удалить пакет?",
        icon: "warning",
        buttons: { yes: "Да", cancel: "Отмена" }
      })
        .then(async (click) => {
          if (click === "yes") {
            await fetch(
              kseRESTApi + "/api/DepositoryExchanges/DeletePos?id=" + taskVariables.selectedDoc.id,
              {
                "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
                "method": "DELETE"
              }
            )
              .then(response => response.json())
              .then(function (res) {
                console.log("RES DELETE POS", res)
                if (res.isSuccess === true) {
                  props.callSuccessToast("Пакет удален!")
                  buttonClick("backToPosSearchForm")
                }
                else {
                  props.callErrorToast(res.errors[0])
                }
              })
              .catch(function (error) {
                return console.log("error: ", error)
              })
          }
        })

      // }
      // else{
      //   props.callErrorToast("Пакет не может быть удален!")
      // }
    }
    else if (buttonName === "exportToTxt") {
      let ordDs = getOrdDsTxt()
      // Download ord
      let pomOrdDs = document.createElement('a')
      let filenameOrdDs = "OrdDs" + moment(new Date).format("DDMMYYYY") + ".txt"
      let bbOrdDs = new Blob([ordDs], { type: 'text/plain' })
      pomOrdDs.setAttribute('href', window.URL.createObjectURL(bbOrdDs))
      pomOrdDs.setAttribute('download', filenameOrdDs)
      pomOrdDs.dataset.downloadurl = ['text/plain', pomOrdDs.download, pomOrdDs.href].join(':')
      pomOrdDs.draggable = true
      pomOrdDs.classList.add('dragout')
      pomOrdDs.click()

      let ord = getOrdTxt()
      // Download ord
      let pomOrd = document.createElement('a')
      let filenameOrd = "Ord" + moment(new Date).format("DDMMYYYY") + ".txt"
      let bbOrd = new Blob([ord], { type: 'text/plain' })
      pomOrd.setAttribute('href', window.URL.createObjectURL(bbOrd))
      pomOrd.setAttribute('download', filenameOrd)
      pomOrd.dataset.downloadurl = ['text/plain', pomOrd.download, pomOrd.href].join(':')
      pomOrd.draggable = true
      pomOrd.classList.add('dragout')
      pomOrd.click()

      let trd = getTrdTxt()
      // Download trd
      let pomTrd = document.createElement('a')
      let filenameTrd = "Trd" + moment(new Date).format("DDMMYYYY") + ".txt"
      let bbTrd = new Blob([trd], { type: 'text/plain' })
      pomTrd.setAttribute('href', window.URL.createObjectURL(bbTrd))
      pomTrd.setAttribute('download', filenameTrd)
      pomTrd.dataset.downloadurl = ['text/plain', pomTrd.download, pomTrd.href].join(':')
      pomTrd.draggable = true
      pomTrd.classList.add('dragout')
      pomTrd.click()
    }

    // GENERAL
    else if (buttonName === "close") {
      props.closeTask(taskVariables.key)
      // props.setNewTask(null, {})
      console.log("button close: ", taskVariables)
    }
    else {
      console.log("UNKNOWN Button ", buttonName)
    }
  }
  function getOrdDsTxt() {
    let ordDs = ""
    for (let i = 0; i < docListOrdersDs.length; i++) {
      ordDs += docListOrdersDs[i].depositor_id_from_trades + "\t"
      ordDs += docListOrdersDs[i].quantity + "\t"
      ordDs += docListOrdersDs[i].currency_code + "\t"
      ordDs += docListOrdersDs[i].account_number
      if (i < docListOrdersDs.length - 1) { ordDs += "\n" }
    }
    return ordDs
  }
  function getOrdTxt() {
    let ord = ""
    for (let i = 0; i < docListOrders.length; i++) {
      ord += docListOrders[i].depositor_id_from_trades + "\t"
      ord += docListOrders[i].security_code + "\t"
      ord += docListOrders[i].quantity + "\t"
      ord += docListOrders[i].account_number
      if (i < docListOrders.length - 1) { ord += "\n" }
    }
    return ord
  }
  function getTrdTxt() {
    let trd = ""
    for (let i = 0; i < docListTrades.length; i++) {
      trd += docListTrades[i].id + "\t"
      trd += docListTrades[i].security_code + "\t"
      trd += docListTrades[i].seller_id + "\t"
      trd += docListTrades[i].seller_account + "\t"
      trd += docListTrades[i].buyer_id + "\t"
      trd += docListTrades[i].buyer_account + "\t"
      trd += docListTrades[i].quantity + "\t"
      trd += docListTrades[i].amount + "\t"
      trd += docListTrades[i].cliring
      if (i < docListTrades.length - 1) { trd += "\n" }
    }
    return trd
  }
  function handleAttachFilePos(e) {
    let selectedFiles = e.target.files
    if (selectedFiles && selectedFiles[0]) {
      let reader = new FileReader()
      reader.onload = async function (e) {
        const file = e.target.result
        const rows = file.split(/\r\n|\n/)
        console.log("rows", rows)
        let arr = []
        for (let i = 0; i < rows.length; i++) {
          if (rows[i] !== "") {
            let items = rows[i].split('\t')
            let newItem = {
              depositor_id_from_trades: items[0],
              security_code: items[1],
              quantity: items[2],
              account_number: items[3]
            }
            arr.push(newItem)

          }
        }
        console.log("ARR", arr)
        setDocListPos(arr)
      }
      reader.readAsText(selectedFiles[0])
      setFilePos(selectedFiles[0])
    }
    setTableKey(getUUID())
  }
  function handleAttachFilePosDs(e) {
    let selectedFiles = e.target.files
    if (selectedFiles && selectedFiles[0]) {
      // setFile(selectedFiles[0])
      let reader = new FileReader()
      reader.onload = async function (e) {
        const file = e.target.result
        const rows = file.split(/\r\n|\n/)
        console.log("rows", rows)
        let arr = []
        for (let i = 0; i < rows.length; i++) {
          if (rows[i] !== "") {
            let items = rows[i].split('\t')
            let newItem = {
              depositor_id_from_trades: items[0],
              quantity: items[1],
              currency_code: items[2],
              account_number: items[3]
            }
            arr.push(newItem)

          }
        }
        console.log("ARR", arr)
        setDocListPosDs(arr)
      }
      reader.readAsText(selectedFiles[0])
      setFilePosDs(selectedFiles[0])
    }
    setTableKey(getUUID())
  }
  function handleAttachFileTrd(e) {
    let selectedFiles = e.target.files
    if (selectedFiles && selectedFiles[0]) {
      // setFile(selectedFiles[0])
      let reader = new FileReader()
      reader.onload = async function (e) {
        const file = e.target.result
        const rows = file.split(/\r\n|\n/)
        console.log("rows", rows)
        let arr = []
        for (let i = 0; i < rows.length; i++) {
          if (rows[i] !== "") {
            let items = rows[i].split('\t')
            let newItem = {
              TRDPackageNumber: items[0],
              code: items[1],
              status: items[2]
            }
            arr.push(newItem)

          }
        }
        console.log("ARR", arr)
        setDocListTrades(arr)
      }
      reader.readAsText(selectedFiles[0], 'CP1251')
      setFileTrd(selectedFiles[0])
    }
    setTableKey(getUUID())
  }

  // Key press handlers for some fields
  function onKeyPressText(event) {
    let code = event.charCode
    if (code === 13) {
      for (let i = 0; i < buttons.length; i++) {
        if (buttons[i].name === "findDocument") {
          // console.log("CODE", code)
          handleTextChange(event)
          buttonClick("findDocument", null)
        }
      }
    }
  }
  function onKeyPressDateTime(event) {
    let code = event.charCode
    if (code === 13) {
      for (let i = 0; i < buttons.length; i++) {
        if (buttons[i].name === "findDocument") {
          // console.log("CODE", code)
          handleDateTimeChange(event)
          buttonClick("findDocument", null)
        }
      }
    }
  }
  function onKeyPressInt(event) {
    let code = event.charCode
    if (code === 13) {
      for (let i = 0; i < buttons.length; i++) {
        if (buttons[i].name === "findDocument") {
          // console.log("CODE", code)
          let stringNum = event.target.value.toString().replace(/ /g, '')
          let int = parseInt(stringNum)
          fieldValue[event.target.name] = int
          setFieldValue(fieldValue)
          filterDocList(0, size - 1, initialDocList, fieldValue)
        }
      }
    }
  }
  function onKeyPressFloat(event) {
    let code = event.charCode
    if (code === 13) {
      for (let i = 0; i < buttons.length; i++) {
        if (buttons[i].name === "findDocument") {
          let stringNum = event.target.value.toString().replace(/ /g, '')
          let float = parseFloat(stringNum)
          fieldValue[event.target.name] = float
          setFieldValue(fieldValue)
          filterDocList(0, size - 1, initialDocList, fieldValue)
        }
      }
    }
  }

  function checkToShowSection(section) {
    let showSection = false
    for (let i = 0; i < section.contents.length; i++) {
      if (section.contents[i].show === true) {
        showSection = true
        break
      }
    }
    return showSection
  }
  function checkSectionOnLastChild(i) {
    let lastSection = true
    if (i === gridForm.sections.length - 1) { // The last section
      return true
    }
    else {
      let nextS = i + 1
      // console.log("IT", i, gridForm.sections[nextS])
      for (let s = nextS; s < gridForm.sections.length; s++) {
        for (let c = 0; c < gridForm.sections[s].contents.length; c++) { // Check next section
          if (gridForm.sections[s].contents[c].show === true) {
            lastSection = false
            break
          }
        }
      }
    }
    return lastSection
  }
  // Pagination functions
  function KeyboardArrowFirstClick() {
    if (page !== 1) {
      setPage(1)
      fetchBySize(0, size - 1, filteredDocList)
    }
    else {
      setSnackBarMessage("Вы на первой странице!")
      setShowSnackBar(true)
    }
  }
  function KeyboardArrowLeftClick(page) {
    if (page !== 1) {
      var prevPage = page - 1
      setPage(prevPage)
      let fetchFrom = ((prevPage - 1) * size) //10
      let fetchTo = (size * prevPage) - 1
      fetchBySize(fetchFrom, fetchTo, filteredDocList)
    }
    else {
      setSnackBarMessage("Вы на первой странице!")
      setShowSnackBar(true)
    }
  }
  function KeyboardArrowRightClick(page) {
    if (filteredDocList.length <= size * page) {
      // console.log("NO DATA")
      setSnackBarMessage("Больше нет данных!")
      setShowSnackBar(true)
    }
    else {
      setPage(page + 1)
      let fetchFrom = (size * page)
      let fetchTo = ((page + 1) * size) - 1
      fetchBySize(fetchFrom, fetchTo, filteredDocList)
    }
  }
  function handleChangeRowsPerPage(event) {
    setSize(event.target.value)
    setPage(1)
    fetchBySize(0, event.target.value - 1, filteredDocList)
  }
  // function GoToPage() {
  //   let fetchFrom = (page * size - 1) - size
  //   let fetchTo = page * size - 1
  //   fetchBySize(fetchFrom, fetchTo, filteredDocList)
  // }
  function GoToPage(p) {
    setPage(p)
    if (p === 1) {
      fetchBySize(0, size - 1, filteredDocList)
    }
    else {
      let fetchFrom = p * size - size
      let fetchTo = p * size - 1
      fetchBySize(fetchFrom, fetchTo, filteredDocList)
    }
  }
  function getPages(from, to) {
    let pArr = []
    for (let i = from; i <= to; i++) {
      if (i < Math.floor(filteredDocList.length / size) + 1) {
        pArr.push(
          getPage(i)
        )
      }
    }
    return pArr
  }
  function getPage(num) {
    return (
      <td>
        <div
          style={{
            cursor: "pointer",
            fontSize: 14,
            verticalAlign: "middle",
            textAlign: "center",
            height: "22px",
            width: "22px",
            color: page === num ? "white" : "black",
            background: page === num ? "#4FA4AD" : "white"
          }}
          onClick={() => GoToPage(num)}
        >
          <div style={{ marginTop: "2px", marginRight: "1px" }}>{num}</div>
        </div>
      </td>
    )
  }
  function handlePageChange(event) {
    setPage(event.target.value)
  }
  function handleCloseSnackBar() {
    setShowSnackBar(false)
  }
  function getPageAmount() {
    let pagesFloat = (filteredDocList.length) / size
    let mathRoundOfPages = Math.round(pagesFloat)
    if (pagesFloat > mathRoundOfPages) {
      return mathRoundOfPages + 1
    }
    else {
      return mathRoundOfPages
    }
  }
  function getModalStyle() {
    const top = 15;
    const left = 10;
    return {
      width: isSearchForm === true ? "1200px" : "900px",
      height: "600px",
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      zIndex: componentIndex
    };
  }
  function formClick() {
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 1)
    props.setComponentIndex(currIndex + 1)
  }
  function positionHandler(e, data) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 2 // Take 2% of width
    let maxDeviationX = (dimension.w / 100) * 90 // Take 90% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    // console.log("minX", minDeviationX, "maxX", maxDeviationX, "maxY", maxDeviationY)
    if (data.y < maxDeviationY) {
      // console.log("SET MIN Y", data.x)
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      // console.log("SET MIN X", data.x, minDeviationX)
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      // console.log("SET MAX X", data.x)
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
  }
  function getHeaderLabel() {
    if (props.taskType === "showPackagesExchanceWithCD") {
      return "Список пакетов ORD/TRD"
    }
    if (props.taskType === "showPackagesExchanceWithCDPos") {
      return "Входящие позиции POS/POSDS"
    }
    if (props.taskType === "showExchangePackage" || props.taskType === "showPos") {
      return packageOrdTrd !== null ? "Пакет " + packageOrdTrd.marketName : "Детали пакета"
    }
    if (props.taskType === "showLoadPackageFromFile") {
      return "Загрузка из файлов"
    }

  }
  try {
    return (
      <Draggable
        handle="p"
        onStart={() => formClick()}
        onStop={positionHandler}
        position={position}
      >
        {/* <div style={getModalStyle()} className={classes.resizeForm}> */}
        <div
          key={tableKey}
          className={classes.resizeForm}
          style={{ height: "600px", width: "800px", top: "15%", left: "2%", transform: "translate(-15%, -2%)", zIndex: componentIndex }}
          onClick={() => formClick()}
        >
          <p className="main-table-label-p-style-new">
            <table className="dragble-div-header-table-styl-new">
              <tr>
                <td width="99%" className="dragble-div-header-td-style-new">{getHeaderLabel()}</td>
                <td>
                  <IoMdClose size={22} className="form-close-icons-style" style={{ marginRight: "5px" }} onClick={() => buttonClick("close")} />
                </td>
              </tr>
            </table>
          </p>
          <div className="dragble-div-body-style-new">
            {/* Create grid table with data from doclist */}
            {isSearchForm !== null && isSearchForm === true
              ?
              <Grid container direction="row" justify="center" spacing={0} style={{ paddingTop: 10 }}>
                <Grid item xs={8}>
                  <Drawer anchor={"bottom"} open={openSearch} onClose={() => setOpenSearch(false)}>
                    <table width="100%">
                      <tr>
                        <td align="center">
                          <table width="60%" style={{ paddingBottom: 70 }}>
                            <tr>
                              <td width="60%" align="center"> {getForm()}</td>
                            </tr>
                            <tr>
                              <td align="center">
                                {buttons.map((button, index) => (
                                  <button
                                    className="mainFormButton"
                                    style={{
                                      border: `1px solid ${button.backgroundColor}`,
                                      background: button.backgroundColor,
                                      color: button.fontColor
                                    }}
                                    onClick={() => buttonClick(button.name, null)}
                                  >{button.label}
                                  </button>
                                  // <Button
                                  //   name={button.name}
                                  //   variant="outlined"
                                  //   onClick={() => buttonClick(button.name, null)}
                                  //   style={{
                                  //     margin: 3,
                                  //     color: button.fontColor,
                                  //     backgroundColor: button.backgroundColor,
                                  //     height: 30,
                                  //     fontSize: 12
                                  //   }}
                                  // >{button.label}
                                  // </Button>
                                ))}
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </Drawer>
                </Grid>
              </Grid>
              :
              isSearchForm !== null && getForm()
            }
            <Grid container direction="row" justify="center" spacing={0}>
              {props.taskType === "showLoadPackageFromFile" &&
                <>
                  <div className={classes.importFile}>
                    <input
                      accept="text/plain"
                      className={classes.inputFile}
                      id={loadDataFromFilePosId}
                      multiple
                      type="file"
                      onChange={handleAttachFilePos}
                    />
                    <label htmlFor={loadDataFromFilePosId}>
                      <Button
                        component="span"
                        variant="outlined"
                        style={{
                          // margin: 3,
                          color: "white",
                          backgroundColor: "#BB7100",
                          // borderColor: "#161C87",
                          borderRadius: "0%",
                          height: 26,
                          fontSize: 12
                        }}
                      >Позиции ЦБ
                      </Button>
                    </label>
                  </div>
                  <div className={classes.importFile}>
                    <input
                      accept="text/plain"
                      className={classes.inputFile}
                      id={loadDataFromFilePosDsId}
                      multiple
                      type="file"
                      onChange={handleAttachFilePosDs}
                    />
                    <label htmlFor={loadDataFromFilePosDsId}>
                      <Button
                        component="span"
                        variant="outlined"
                        style={{
                          // margin: 3,
                          color: "white",
                          backgroundColor: "#BB7100",
                          // borderColor: "#161C87",
                          borderRadius: "0%",
                          height: 26,
                          fontSize: 12
                        }}
                      >Позиции ДС
                      </Button>
                    </label>
                  </div>
                </>
              }
              {props.taskType === "showLoadTRDResult" &&
                <>
                  <div className={classes.importFile}>
                    <input
                      accept="text/plain"
                      className={classes.inputFile}
                      id={loadDataFromFileTRDId}
                      multiple
                      type="file"
                      onChange={handleAttachFileTrd}
                    />
                    <label htmlFor={loadDataFromFileTRDId}>
                      <Button
                        component="span"
                        variant="outlined"
                        style={{
                          // margin: 3,
                          color: "white",
                          backgroundColor: "#BB7100",
                          // borderColor: "#161C87",
                          borderRadius: "0%",
                          height: 26,
                          fontSize: 12
                        }}
                      >Файл результатов TRD
                      </Button>
                    </label>
                  </div>
                </>
              }
              {buttons.map((button, index) => (
                <button
                  className="mainFormButton"
                  style={{
                    border: `1px solid ${button.backgroundColor}`,
                    background: button.backgroundColor,
                    color: button.fontColor
                  }}
                  onClick={() => buttonClick(button.name, null)}
                >{button.label}
                </button>
                // <Button
                //   name={button.name}
                //   variant="outlined"
                //   onClick={() => buttonClick(button.name, null)}
                //   style={{
                //     margin: 3,
                //     color: button.fontColor,
                //     backgroundColor: button.backgroundColor,
                //     height: 30,
                //     fontSize: 12
                //   }}
                // >{button.label}
                // </Button>
              ))}
            </Grid>
            {docList !== null && gridForm !== null &&
              <Grid container direction="row" justify="center" style={{ paddingTop: 10 }}>
                <Grid item sm={"auto"}>
                  <Paper>
                    <div style={{ height: "400px", width: "100%", minWidth: "600px", overflow: "auto" }}>
                      <table className="grid-table-style" width="100%">
                        <thead className="grid-table-thead-style" style={{ position: "sticky", top: 0, zIndex: 9999 }}>
                          {/* 1st Row Sections Labels */}
                          <tr>
                            <td colSpan="1" className="grid-table-td-head-first-child"></td>
                            {gridForm.sections.map((section, index) => {
                              let showSection = checkToShowSection(section)
                              if (showSection === true) {
                                let lastSection = checkSectionOnLastChild(index)
                                return (
                                  <td
                                    class={lastSection === true ? "grid-table-td-head-last-child" : "grid-table-thead-style"}
                                    colSpan={section.contents.length}
                                  >{section.label}</td>
                                )
                              }
                            })}
                          </tr>
                          {/* 2nd Row Sections Contents labels */}
                          <tr>
                            <td
                              class="grid-table-td-head-style-2row"
                              rowSpan="2"
                              style={{ minWidth: "85px", width: "85px" }}
                            >
                              Действие
                            </td>
                            {gridForm.sections.map(section =>
                              section.contents.map(contentItem => {
                                if (contentItem.show === true) {
                                  return (
                                    <td
                                      class="grid-table-td-head-style-2row"
                                      rowSpan="2"
                                    // onClick={()=> sortDataByColumn(contentItem.name, contentItem.type)}
                                    >
                                      {contentItem.label}
                                    </td>
                                  )
                                }
                              })
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(docList).length !== 0 &&
                            docList.map(docListItem => (
                              <tr
                                onClick={() => setSelectedItem(docListItem)}
                                style={{ background: selectedItem.id === docListItem.id ? "#CECECE" : null }}
                              >
                                <td
                                  class="td-style"
                                  style={{ "maxWidth": 34 }}
                                >
                                  {gridFormButtons.map(button =>
                                    <button
                                      className="approveSmallButton"
                                      onClick={() => buttonClick(button.name, docListItem)}
                                    >
                                      {button.label}
                                    </button>
                                  )}
                                </td>
                                {gridForm.sections.map(section => (
                                  section.contents.map(contentItem => {
                                    if (contentItem.show === true) {
                                      return (
                                        <td align="left" style={{ color: "black", fontSize: 12, "text-align": "center", "border-bottom": "1px solid grey" }}>
                                          {getGridFormItems(docListItem, contentItem, gridFormEnumData)}
                                        </td>
                                      )
                                    }
                                  })
                                ))}
                              </tr>
                            )
                            )}
                        </tbody>
                      </table>
                      {/* <table style={{ background: "white", marginBottom: "5px" }} width="100%">
                        <tr>
                          <td style={{ paddingLeft: "20px" }}>
                            <div style={{ minWidth: 90, color: "black" }}>Кол-во записей</div>
                          </td>
                          <td style={{ paddingLeft: "3px" }}>
                            <FormControl
                              variant="outlined"
                              style={{ minWidth: 30 }}
                            >
                              <GridSelect
                                onChange={handleChangeRowsPerPage}
                                style={{ height: 25, color: "black" }}
                                value={size}
                              >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                                <MenuItem value={200}>200</MenuItem>
                                <MenuItem value={500}>500</MenuItem>
                              </GridSelect>
                            </FormControl>
                          </td>
                          <td>
                            <Tooltip title="На первую страницу">
                              <IconButton onClick={() => KeyboardArrowFirstClick()}>
                                <FirstPageIcon style={{ fontSize: "large", color: "black" }} />
                              </IconButton>
                            </Tooltip>
                          </td>
                          <td>
                            <Tooltip title="На предыдущюю страницу">
                              <IconButton onClick={() => KeyboardArrowLeftClick(page)}>
                                <ArrowBackIosIcon style={{ fontSize: "medium", color: "black" }} />
                              </IconButton>
                            </Tooltip>
                          </td>
                          <td style={{ color: "black", fontSize: 16 }}>
                            <input style={{ maxWidth: 25 }} value={page} onChange={handlePageChange}></input>
                          </td>
                          <td style={{ paddingLeft: "3px" }}>
                            <Tooltip title="Перейти на указанную страницу">
                              <Button
                                onClick={() => GoToPage()}
                                variant="outlined"
                                style={{
                                  height: 22,
                                  backgroundColor: "#D1D6D6",
                                  fontSize: 12
                                }}
                              >перейти
                              </Button>
                            </Tooltip>
                          </td>
                          <td>
                            <Tooltip title="На следующюю страницу">
                              <IconButton onClick={() => KeyboardArrowRightClick(page)}>
                                <ArrowForwardIosIcon style={{ fontSize: "medium", color: "black" }} />
                              </IconButton>
                            </Tooltip>
                          </td>
                          <td class="pagination-rows-amount-style">Стр. {page} из {getPageAmount()} Общее кол-во {initialDocList.length}
                          </td>
                        </tr>
                      </table> */}
                    </div>
                    <table width="100%">
                      <tfoot height="44px" >
                        <tr>
                          <td width="50%" align="left">
                            <tr>
                              <td style={{ paddingLeft: "15px" }}>
                                <div style={{ color: "#868686" }}>Количество строк:</div>
                              </td>
                              <td style={{ paddingLeft: "3px" }}>
                                <FormControl>
                                  <GridSelect
                                    onChange={handleChangeRowsPerPage}
                                    style={{ height: 21, color: "#666666", marginLeft: "3px" }}
                                    value={size}
                                  >
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                    <MenuItem value={200}>200</MenuItem>
                                    <MenuItem value={500}>500</MenuItem>
                                  </GridSelect>
                                </FormControl>
                              </td>
                            </tr>
                          </td>
                          <td width="50%" align="right">
                            <tr>
                              <td>
                                <Tooltip title="На предыдущюю">
                                  <ArrowBackIosIcon style={{ fontSize: "small", color: "black", cursor: "pointer" }} onClick={() => KeyboardArrowLeftClick(page)} />
                                </Tooltip>
                              </td>
                              <td>
                                <div
                                  style={{
                                    cursor: "pointer",
                                    fontSize: 14,
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    height: "22px",
                                    width: "22px",
                                    color: page === 1 ? "white" : "black",
                                    background: page === 1 ? "#4FA4AD" : "white"
                                  }}
                                  onClick={() => GoToPage(1)}
                                >
                                  <div style={{ marginTop: "2px", marginRight: "1px" }}>1</div>
                                </div>
                              </td>
                              {page > 4 ?
                                <React.Fragment>
                                  <td>
                                    <div
                                      style={{
                                        fontSize: 14,
                                        verticalAlign: "middle",
                                        textAlign: "center",
                                        height: "22px",
                                        width: "22px",
                                        color: "black",
                                        background: "white"
                                      }}
                                    >
                                      ...
                                    </div>
                                  </td>
                                  {getPages(page - 1, page + 1)}
                                </React.Fragment>
                                :
                                getPages(2, 5)
                              }

                              {Math.floor(filteredDocList.length / size + 1) - page > 2 && filteredDocList.length / size > 5 &&
                                <td>
                                  <div
                                    style={{
                                      fontSize: 14,
                                      verticalAlign: "middle",
                                      textAlign: "center",
                                      height: "22px",
                                      width: "22px",
                                      color: "black",
                                      background: "white"
                                    }}
                                  >
                                    ...
                                  </div>
                                </td>
                              }
                              {filteredDocList.length / size > 1 && getPage(Math.floor(filteredDocList.length / size + 1))}
                              <td>
                                <Tooltip title="На предыдущюю">
                                  <ArrowForwardIosIcon style={{ fontSize: "small", color: "black", cursor: "pointer", marginLeft: "2px", marginRight: "40px" }} onClick={() => KeyboardArrowRightClick(page)} />
                                </Tooltip>
                              </td>
                            </tr>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </Paper>
                </Grid>
              </Grid>
            }
            {gridFormOrd !== null &&
              <div style={{ paddingLeft: 5, paddingRight: 5 }}>
                <div align="center" style={{ fontWeight: "bold" }}>{gridFormOrd.label}</div>
                <table className="main-table-style" style={{ width: "100%" }}>
                  <thead style={{ backgroundColor: "#cfd8dc" }}>
                    <tr>
                      {gridFormOrd.sections.map(section =>
                        section.contents.map(contentItem => {
                          if (contentItem.show === true) {
                            return (
                              <td class="main-table-header-td-style">
                                {contentItem.label}
                              </td>
                            )
                          }
                        })
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {docListOrders.length !== 0 &&
                      docListOrders.map(docListOrdItem => (
                        <tr>
                          {gridFormOrd.sections.map(section => (
                            section.contents.map(contentItem => {
                              if (contentItem.show === true) {
                                return (
                                  <td className="main-table-tbody-td-style">
                                    {getGridFormItems(docListOrdItem, contentItem, enumDataOrd)}
                                  </td>
                                )
                              }
                            })
                          ))}
                        </tr>
                      )
                      )}
                  </tbody>
                </table>
                <div style={{ fontSize: 14 }}>Ошибки: {ordError}</div>
              </div>
            }
            {gridFormOrdDs !== null &&
              <div style={{ paddingLeft: 5, paddingRight: 5 }}>
                <div align="center" style={{ fontWeight: "bold" }}>{gridFormOrdDs.label}</div>
                <table className="main-table-style" style={{ width: "100%" }}>
                  <thead style={{ backgroundColor: "#cfd8dc" }}>
                    <tr>
                      {gridFormOrdDs.sections.map(section =>
                        section.contents.map(contentItem => {
                          if (contentItem.show === true) {
                            return (
                              <td class="main-table-header-td-style">
                                {contentItem.label}
                              </td>
                            )
                          }
                        })
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {docListOrdersDs.length !== 0 &&
                      docListOrdersDs.map(docListOrdDsItem => (
                        <tr>
                          {gridFormOrdDs.sections.map(section => (
                            section.contents.map(contentItem => {
                              if (contentItem.show === true) {
                                return (
                                  <td className="main-table-tbody-td-style">
                                    {getGridFormItems(docListOrdDsItem, contentItem, enumDataOrdDs)}
                                  </td>
                                )
                              }
                            })
                          ))}
                        </tr>
                      )
                      )}
                  </tbody>
                </table>
                <div style={{ fontSize: 14 }}>Ошибки: {ordDsError}</div>
              </div>
            }
            {gridFormTrd !== null &&
              <div style={{ paddingLeft: 5, paddingRight: 5 }}>
                <div align="center" style={{ fontWeight: "bold" }}>{gridFormTrd.label}</div>
                <table className="main-table-style" style={{ width: "100%" }}>
                  <thead style={{ backgroundColor: "#cfd8dc" }}>
                    <tr>
                      {gridFormTrd.sections.map(section =>
                        section.contents.map(contentItem => {
                          if (contentItem.show === true) {
                            return (
                              <td class="main-table-header-td-style">
                                {contentItem.label}
                              </td>
                            )
                          }
                        })
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {docListTrades.length !== 0 &&
                      docListTrades.map(docListTrdItem => (
                        <tr>
                          {gridFormTrd.sections.map(section => (
                            section.contents.map(contentItem => {
                              if (contentItem.show === true) {
                                return (
                                  <td className="main-table-tbody-td-style">
                                    {getGridFormItems(docListTrdItem, contentItem, enumDataTrd)}
                                  </td>
                                )
                              }
                            })
                          ))}
                        </tr>
                      )
                      )}
                  </tbody>
                </table>
                <div style={{ paddingBottom: 5, fontSize: 14 }}>Ошибки: {trdError}</div>
              </div>
            }
            {gridFormPos !== null &&
              <div style={{ paddingLeft: 5, paddingRight: 5 }}>
                <div align="center" style={{ fontWeight: "bold" }}>{gridFormPos.label}</div>
                <table className="main-table-style" style={{ width: "100%" }}>
                  <thead style={{ backgroundColor: "#cfd8dc" }}>
                    <tr>
                      {gridFormPos.sections.map(section =>
                        section.contents.map(contentItem => {
                          if (contentItem.show === true) {
                            return (
                              <td class="main-table-header-td-style">
                                {contentItem.label}
                              </td>
                            )
                          }
                        })
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {docListPos.length !== 0 &&
                      docListPos.map(docListPosItem => (
                        <tr>
                          {gridFormPos.sections.map(section => (
                            section.contents.map(contentItem => {
                              if (contentItem.show === true) {
                                return (
                                  <td className="main-table-tbody-td-style">
                                    {getGridFormItems(docListPosItem, contentItem, enumDataPos)}
                                  </td>
                                )
                              }
                            })
                          ))}
                        </tr>
                      )
                      )}
                  </tbody>
                </table>
              </div>
            }
            {gridFormPosDs !== null &&
              <div style={{ paddingLeft: 5, paddingRight: 5, paddingBottom: 5 }}>
                <div align="center" style={{ fontWeight: "bold" }}>{gridFormPosDs.label}</div>
                <table className="main-table-style" style={{ width: "100%" }}>
                  <thead style={{ backgroundColor: "#cfd8dc" }}>
                    <tr>
                      {gridFormPosDs.sections.map(section =>
                        section.contents.map(contentItem => {
                          if (contentItem.show === true) {
                            return (
                              <td class="main-table-header-td-style">
                                {contentItem.label}
                              </td>
                            )
                          }
                        })
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {docListPosDs.length !== 0 &&
                      docListPosDs.map(docListPosDsItem => (
                        <tr>
                          {gridFormPosDs.sections.map(section => (
                            section.contents.map(contentItem => {
                              if (contentItem.show === true) {
                                return (
                                  <td className="main-table-tbody-td-style">
                                    {getGridFormItems(docListPosDsItem, contentItem, enumDataPosDs)}
                                  </td>
                                )
                              }
                            })
                          ))}
                        </tr>
                      )
                      )}
                  </tbody>
                </table>
              </div>
            }
          </div>
        </div>
      </Draggable>
    )
  }
  catch (error) {
    console.log("ERROR", error)
    return <div>error</div>
  }
}