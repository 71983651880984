import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Select from 'react-select'; // https://react-select.com/home
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from '@material-ui/core/Button';
// Icons
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// CUSTOM COMPONENTS
import ConfigurationFile from "../configuration/ConfigurationFile.json";
// Library
import 'react-toastify/dist/ReactToastify.css';
import Draggable from 'react-draggable';
import swal from 'sweetalert'; // https://sweetalert.js.org/guides/
var fetch = require('node-fetch');


const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    width: "530px",
    backgroundColor: "#eceff1",
    border: '1px solid #AFAFAF',
    borderRadius: "3px",
    boxShadow: theme.shadows[1],
    padding: 3,
    resize: "both",
    overflow: "auto",
    minWidth: "150px",
    minHeight: "150px",
  },
  modal2: {
    position: 'absolute',
    width: 400,
    // height: 200,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #AFAFAF',
    borderRadius: "7px",
    boxShadow: theme.shadows[1],
    padding: 3,
    resize: "both",
    overflow: "auto",
    minWidth: "150px",
    minHeight: "150px",
  }
}))
export default (props) => {
  const classes = useStyles()
  const [kseRESTApi] = useState(props.kseRESTApi) //Local KFB main REST
  const [token] = useState(props.token);
  const [userProfile] = useState(props.userProfile)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [enumData, setEnumData] = useState({})
  const [enumOptions, setEnumOptions] = useState({})
  const [selectedOptions, setSelectedOptions] = useState({})
  const [fieldValue, setFieldValue] = useState({ accountId: null })

  const [docList, setDocList] = useState([])
  const [selectedOrder, setSelectedOrder] = useState({})
  const [openModal, setOpenModal] = useState({})
  const [position, setPosition] = useState({ x: 0, y: 0 })

  useEffect(async () => {
    console.log("DIRECT ORDERS PROPS", props)
    let enumDataToCollect = [
      { enumName: "financeInstrumentId", enumDef: "3e819d7e-25d0-4a04-a3ff-092fd348a375" },
      { enumName: "accountId", enumDef: "c324d86f-3a3b-43b2-9514-d983b2982794" },
      { enumName: "organizationId", enumDef: "5b78d9dd-821d-4c6a-a00a-3af85224fbc4" },
      { enumName: "senderOrganizationId", enumDef: "5b78d9dd-821d-4c6a-a00a-3af85224fbc4" },
    ]
    let enums = await props.getEnumDataByList(enumDataToCollect)
    setEnumData(enums)
    setEnumOptions(await props.createEnumOptions(enums))
    // console.log("DORs ENUMS", await props.createEnumOptions(enums))
    updateDocList()
    props.setComponentIndex(props.getComponentIndex() + 1)
  }, [])
  async function updateDocList() {
    let docL = await props.fetchDocList("/api/Trading/GetDirectRequests")
    setDocList(docL)
    // console.log("DOCL D ORDERS", docL)
  }
  function swAllert(text, icon) {
    return (
      swal({
        text: text,
        icon: icon,
        buttons: { ok: "Ок" },
      })
    )
  }
  function getEnumLabel(name, id) {
    // console.log("D ORDs ENUMS", enumData)
    for (let d = 0; d < enumData[name].length; d++) {
      if (enumData[name][d].id === id) {
        return enumData[name][d].label
      }
    }
  }
  function getOrderColor(accepted, rejected) {
    if (accepted === false && rejected === false) {
      return "grey"
    }
    else if (accepted === true) {
      return "green"
    }
    else if (rejected === true) {
      return "red"
    }
  }
  // function acceptDirectOrder(){
  //   if(fieldValue.accountId !== null && fieldValue.accountId){
  //     swal({
  //       text: "Подтвердить данную завку?",
  //       icon: "warning",
  //       buttons: {yes: "Да", cancel: "Отмена"},
  //     })
  //     .then(async(click) => {
  //       if(click === "yes"){
  //         let body = {
  //           "requestId": fieldValue.id,
  //           "accountId": fieldValue.accountId,
  //           "accepted": true
  //         }
  //         console.log("BODY", body)
  //         await fetch(
  //           kseRESTApi + "/api/Trading/AcceptDirectRequestBid",
  //           {
  //             "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
  //             "method": "POST",
  //             "body": JSON.stringify(body) 
  //           }
  //         )
  //         .then(response => response.json())
  //         .then(async function(res){
  //           console.log("ACCEPT", res)
  //           if(res.isSuccess === true){
  //             updateDocList()
  //             props.callSuccessToast("Заявка подтверждена")
  //             setOpenModal(false)
  //             setFieldValue({})
  //           }
  //           else{
  //             props.callErrorToast(res.errors[0])
  //           }
  //         })
  //       }
  //     })
  //   }
  //   else{
  //     swAllert("Укажите счет", "warning")
  //   }
  // }
  // function rejectDirectOrder(){
  //   swal({
  //     text: "Отклонить данную завку?",
  //     icon: "warning",
  //     buttons: {yes: "Да", cancel: "Отмена"},
  //   })
  //   .then(async(click) => {
  //     if(click === "yes"){
  //       let body = {
  //         "requestId": fieldValue.id,
  //         "accountId": null,
  //         "accepted": false
  //       }
  //       console.log("BODY", body)
  //       await fetch(
  //         kseRESTApi + "/api/Trading/AcceptDirectRequestBid",
  //         {
  //           "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
  //           "method": "POST",
  //           "body": JSON.stringify(body) 
  //         }
  //       )
  //       .then(response => response.json())
  //       .then(async function(res){
  //         console.log("ACCEPT", res)
  //         if(res.isSuccess === true){
  //           updateDocList()
  //           props.callSuccessToast("Заявка отклонена")
  //           setOpenModal(false)
  //           setFieldValue({})
  //         }
  //         else{
  //           props.callErrorToast(res.errors[0])
  //         }
  //       })
  //     }
  //   })
  // }
  function showOrder(order) {
    // let orderDetail = {
    //   financeInstrumentId: order.financeInstrumentId,
    //   organizationId: order.organizationId,
    //   senderOrganizationId: order.senderOrganizationId
    // }
    // showEnumOption(orderDetail)
    // setFieldValue(order)
    // setOpenModal(true)
    props.openDirectOrderDetail(order)
  }
  // function showEnumOption(list){
  //   let newSelOpts = {...selectedOptions}
  //   for(let key in list){
  //     for(let d=0; d<enumOptions[key].length; d++){
  //       if(enumOptions[key][d].value === list[key]){
  //         newSelOpts[key] = enumOptions[key][d]
  //       }
  //     }
  //   }
  //   setSelectedOptions(newSelOpts)
  // }
  // function handleSelectChange(option){
  //   setSelectedOptions({...selectedOptions, [option.name]: option})
  //   setFieldValue({...fieldValue, [option.name]: option.value})
  //   console.log("OPT", option, fieldValue)
  // }
  // function closeModal(){
  //   setOpenModal(false)
  //   setFieldValue({})
  // }
  function getModalStyle() {
    const top = 15;
    const left = 25;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      zIndex: componentIndex
    };
  }
  function formClick() {
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 1)
    props.setComponentIndex(currIndex + 1)
  }
  function positionHandler(e, data) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 20 // Take 20% of width
    let maxDeviationX = (dimension.w / 100) * 70 // Take 70% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    // console.log("minX", minDeviationX, "maxX", maxDeviationX, "maxY", maxDeviationY)
    if (data.y < maxDeviationY) {
      // console.log("SET MIN Y", data.x)
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      // console.log("SET MIN X", data.x, minDeviationX)
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      // console.log("SET MAX X", data.x)
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
  }
  return (
    // <Draggable
    //   handle="p"
    //   onStart={() => formClick()}
    //   onMouseDown={() => formClick()}
    // // onClick={()=> formClick()}
    // >
    //   <div style={getModalStyle()} className={classes.modal}>
    <Draggable
      handle="p"
      onStart={() => formClick()}
      onStop={positionHandler}
      position={position}
    >
      <div
        className={classes.resizeForm}
        style={{ top: "15%", left: "20%", transform: "translate(-15%, -20%)", zIndex: componentIndex }}
        onClick={() => formClick()}
      >
        <p className="main-table-label-p-style">
          <table className="dragble-div-header-table-style">
            <tr>
              <td width="99%" className="dragble-div-header-td-style">Прямые сделки</td>
              <td onClick={() => props.setShowDirectOrders(false)}><IconButton size="small"><CloseIcon /></IconButton></td>
            </tr>
          </table>
        </p>
        <div style={{ height: "320px", overflow: "auto" }}>
          <table className="main-table-style" style={{ width: "100%" }}>
            <thead style={{ backgroundColor: "#cfd8dc" }}>
              <tr>
                <td class="main-table-header-td-style">№</td>
                <td class="main-table-header-td-style">Инструмент</td>
                <td class="main-table-header-td-style">Цена</td>
                <td class="main-table-header-td-style">Кол-во</td>
                <td class="main-table-header-td-style">B/S</td>
                <td class="main-table-header-td-style">Отправитель</td>
                <td class="main-table-header-td-style">Получатель</td>
                <td class="main-table-header-td-style">Принят</td>
                {/* <td class="main-table-header-td-style">Действие</td> */}
              </tr>
            </thead>
            {Object.keys(enumData).length > 0 && docList.map(order => {
              return (
                <tr style={{ cursor: "pointer" }} onClick={() => showOrder(order)}>
                  <td className="main-table-tbody-td-style">{order.id}</td>
                  <td className="main-table-tbody-td-style">{getEnumLabel("financeInstrumentId", order.financeInstrumentId)}</td>
                  <td className="main-table-tbody-td-style">{order.price}</td>
                  <td className="main-table-tbody-td-style">{order.amount}</td>
                  <td className="main-table-tbody-td-style">{order.bidDirection === 0 ? "B" : "S"}</td>
                  <td className="main-table-tbody-td-style">{getEnumLabel("organizationId", order.senderOrganizationId)}</td>
                  <td className="main-table-tbody-td-style">{getEnumLabel("organizationId", order.organizationId)}</td>
                  <td className="main-table-tbody-td-style">
                    <Checkbox
                      style={{ maxWidth: 20, height: 15, color: getOrderColor(order.accepted, order.rejected) }}
                      checked={order.accepted}
                    />
                  </td>
                  {/* <td className="main-table-tbody-td-style">
                      <Tooltip title="Подтвердить прямую заявку">
                        <CheckCircleOutlineIcon 
                          size="small" 
                          style={{color: order.organizationId === userProfile.organization.id ? "green" : "grey", cursor: "pointer"}}
                          onClick={()=> acceptDirectOrder(order.id)}
                        />
                      </Tooltip>
                    </td> */}
                </tr>
              )
            })}
          </table>
        </div>
      </div>
    </Draggable>
  )
}