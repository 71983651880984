import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
// Icons
import { IoMdClose } from 'react-icons/io';
import { MdExpandMore } from 'react-icons/md';
import { MdExpandLess } from 'react-icons/md';

// Library
import 'react-toastify/dist/ReactToastify.css';
import Draggable from 'react-draggable';

import 'react-tabs/style/react-tabs.css';
const useStyles = makeStyles((theme) => ({
  resizeForm: {
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    border: "1px solid #2B4D80",
    display: "flex",
    backgroundColor: "white",
    "flex-flow": "column",
    width: "40%",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "30px",
    background: "white",
    position: 'absolute'
  }

}))

//Home(props) - получаем переменные от родителя App.js 
export default (props) => {
  const classes = useStyles()
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [bid] = useState(props.bid)
  const [expanded, setExpanded] = useState(true)
  const [position, setPosition] = useState({ x: 0, y: 0 })

  useEffect(async () => {
    console.log("BID PROPERTIES PROPS", props)
    // let enumDataToCollect = [
    //   {enumName: "marketId", enumDef: "ab14dc6d-0139-4dd4-ab65-172cacb636f8"},
    //   {enumName: "marketSectorId", enumDef: "df59dde0-68fd-4a8b-84e1-aa33531d2fe6"},
    //   {enumName: "instrumentGroupId", enumDef: "3e66280f-9295-46af-8855-cfce4d98faf1"},
    //   {enumName: "currencyForTrade", enumDef: "3bac793e-bc73-44f4-a18f-8c1a55efe919"},
    //   {enumName: "currencyForClearing", enumDef: "3bac793e-bc73-44f4-a18f-8c1a55efe919"}
    // ]
    // let enums = await props.getEnumDataByList(enumDataToCollect)
    // console.log("INSTR PROPER ENUMS", enums)
    // setEnumData(enums)
    // let eOpts = await props.createEnumOptions(enums)
    // setEnumOptions(eOpts)
    props.setComponentIndex(props.getComponentIndex() + 1)
  }, [])

  function getModalStyle() {
    const top = 15;
    const left = 25;
    return {
      height: expanded === true ? "" : "40px",
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      zIndex: componentIndex
    }
  }
  function formClick() {
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 1)
    props.setComponentIndex(currIndex + 1)
  }
  function positionHandler(e, data) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 30 // Take 30% of width
    let maxDeviationX = (dimension.w / 100) * 60 // Take 60% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    // console.log("minX", minDeviationX, "maxX", maxDeviationX, "maxY", maxDeviationY)
    if (data.y < maxDeviationY) {
      // console.log("SET MIN Y", data.x)
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      // console.log("SET MIN X", data.x, minDeviationX)
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      // console.log("SET MAX X", data.x)
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
  }
  return (
    // <Draggable
    //   handle="p"
    //   onStart={() => formClick()}
    //   onMouseDown={() => formClick()}
    // // onClick={()=> formClick()}
    // >
    //   <div style={getModalStyle()} className={classes.resizeForm}>
    <Draggable
      handle="p"
      onStart={() => formClick()}
      onStop={positionHandler}
      position={position}
    >
      <div
        className={classes.resizeForm}
        style={{ top: "15%", left: "30%", transform: "translate(-15%, -30%)", zIndex: componentIndex }}
        onClick={() => formClick()}
      >
        <p className="main-table-label-p-style-new">
          <table className="dragble-div-header-table-style-new">
            <tr>
              <td width="99%" className="dragble-div-header-td-style-new">Свойства заявки</td>
              {/* <td onClick={() => props.closeBidProperties(bid.uuid)}><IconButton size="small"><CloseIcon /></IconButton></td> */}
              {expanded == false
                ?
                <td>
                  <MdExpandLess size={22} className="form-expand-icons-style" onClick={() => setExpanded(!expanded)} />
                </td>
                :
                <td>
                  <MdExpandMore size={22} className="form-expand-icons-style" onClick={() => setExpanded(!expanded)} />
                </td>
              }
              <td>
                <IoMdClose size={22} className="form-close-icons-style" style={{ marginRight: "5px" }} onClick={() => props.closeBidProperties(bid.uuid)} />
              </td>
            </tr>
          </table>
        </p>
        <div className="dragble-div-body-style">
          <table align="center" width="100%">
            <tr>
              <td className="properties-td-text-style">Номер</td>
              <td>
                {/* <input
                  value={bid.bidId}
                  disabled={true}
                /> */}
                <TextField
                  variant="outlined"
                  size="small"
                  value={bid.bidId}
                  disabled={true}
                />
              </td>
            </tr>
            <tr>
              <td>Инструмент</td>
              <td>
                <TextField
                  variant="outlined"
                  size="small"
                  value={bid.instrumentCode}
                  disabled={true}
                />
                {/* <input
                  value={bid.instrumentCode}
                  disabled={true}
                /> */}
              </td>
            </tr>
            <tr>
              <td>B/S</td>
              <td>
                <TextField
                  variant="outlined"
                  size="small"
                  value={bid.direction}
                  disabled={true}
                />
                {/* <input
                  value={bid.direction}
                  disabled={true}
                /> */}
              </td>
            </tr>
            <tr>
              <td>Цена</td>
              <td>
                <TextField
                  variant="outlined"
                  size="small"
                  value={bid.price}
                  disabled={true}
                />
                {/* <input
                  value={bid.price}
                  disabled={true}
                /> */}
              </td>
            </tr>
            <tr>
              <td>Количество</td>
              <td>
                <TextField
                  variant="outlined"
                  size="small"
                  value={bid.amount}
                  disabled={true}
                />
                {/* <input
                  value={bid.amount}
                  disabled={true}
                /> */}
              </td>
            </tr>
            <tr>
              <td>Объем</td>
              <td>
                <TextField
                  variant="outlined"
                  size="small"
                  value={bid.volume}
                  disabled={true}
                />
                {/* <input
                  value={bid.volume}
                  disabled={true}
                /> */}
              </td>
            </tr>
            <tr>
              <td>Доходность</td>
              <td>
                <TextField
                  variant="outlined"
                  size="small"
                  value={bid.profit}
                  disabled={true}
                />
                {/* <input
                  value={bid.profit}
                  disabled={true}
                /> */}
              </td>
            </tr>
            <tr>
              <td>Остаток</td>
              <td>
                <TextField
                  variant="outlined"
                  size="small"
                  value={bid.balanceAmount}
                  disabled={true}
                />
                {/* <input
                  value={bid.balanceAmount}
                  disabled={true}
                /> */}
              </td>
            </tr>
            <tr>
              <td>Состояние</td>
              <td>
                <TextField
                  variant="outlined"
                  size="small"
                  value={bid.status}
                  disabled={true}
                />
                {/* <input
                  value={bid.status}
                  disabled={true}
                /> */}
              </td>
            </tr>
            <tr>
              <td>Пользователь</td>
              <td>
                <TextField
                  variant="outlined"
                  size="small"
                  value={bid.userName}
                  disabled={true}
                />
                {/* <input
                  value={bid.userName}
                  disabled={true}
                /> */}
              </td>
            </tr>
            <tr>
              <td>Зарегистрирована</td>
              <td>
                <TextField
                  variant="outlined"
                  size="small"
                  value={bid.createdAt}
                  disabled={true}
                />
                {/* <input
                  value={bid.createdAt}
                  disabled={true}
                /> */}
              </td>
            </tr>
            <tr>
              <td>Торговый счёт</td>
              <td>
                <TextField
                  variant="outlined"
                  size="small"
                  value={bid.accountNo}
                  disabled={true}
                />
                {/* <input
                  value={bid.accountNo}
                  disabled={true}
                /> */}
              </td>
            </tr>
            <tr>
              <td>Дата/Время истечения</td>
              <td>
                <TextField
                  variant="outlined"
                  size="small"
                  value={bid.expiredAt}
                  disabled={true}
                />
                {/* <input
                  value={bid.expiredAt}
                  disabled={true}
                /> */}
              </td>
            </tr>
            <tr>
              <td>Весь объём</td>
              <td>
                <Checkbox
                  checked={bid.fullRequired === true ? true : false}
                  disabled={true}
                />
              </td>
            </tr>
          </table>
          <table width="100%">
            <tr>
              <td align="center">
                <button
                  className="cancelButton"
                  style={{ marginRight: 20 }}
                  onClick={() => props.closeBidProperties(bid.uuid)}
                >
                  Закрыть
                </button>
                {/* <Button
                  variant="outlined"
                  onClick={() => props.closeBidProperties(bid.uuid)}
                  style={{
                    margin: 3,
                    // color: button.fontColor,
                    // backgroundColor: button.backgroundColor,
                    height: 30,
                    fontSize: 12
                  }}
                >Закрыть
                </Button> */}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </Draggable>
  )
}