import React, { useCallback, useState, useRef, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
// import { Grid } from '@material-ui/core';
// import LinearProgress from '@material-ui/core/LinearProgress';
import Checkbox from '@material-ui/core/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
// import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
// Icons
import SearchIcon from '@mui/icons-material/Search';
import ChartIcon from '@mui/icons-material/InsertChartOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import { IoIosSettings } from 'react-icons/io';
import { IoMdClose } from 'react-icons/io';
// import { AiOutlineSearch } from 'react-icons/ai';
import { AiFillFilter } from 'react-icons/ai';
import { MdExpandMore } from 'react-icons/md';
import { MdExpandLess } from 'react-icons/md';
// import { BsArrowDown } from 'react-icons/bs';
// import { BsArrowUp } from 'react-icons/bs';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

//Style
import "../styles/generalStyles.css"
// Libraries
import Draggable from 'react-draggable'; // https://www.npmjs.com/package/react-draggable
import { Rnd } from "react-rnd";
import swal from 'sweetalert'; // https://sweetalert.js.org/guides/
import { v4 as uuidv4 } from 'uuid';
import { BorderBottom } from "@mui/icons-material";
var moment = require('moment');
// import { Resizable } from "re-resizable";
var fetch = require('node-fetch');
// var generator = require('generate-password');
// var invert = require('invert-color'); // https://www.npmjs.com/package/invert-color

const useStyles = makeStyles(() => ({
  resizeForm: {
    border: "1px solid grey",
    display: "flex",
    backgroundColor: "white",
    "flex-flow": "column",
    height: "320px",
    width: "90%",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "30px",
    background: "white",
    position: 'absolute',
  },
  menuIconStyle: {
    position: "absolute",
    top: 7,
    right: 10
  },
  tdBody: {
    cursor: "pointer",
    fontSize: 13,
    fontWeight: "light",
    height: "30px",
    minWidth: "1px",
    maxWidth: "150px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "elipsis '[..]'",
    overflow: "hidden",
    fontFamily: "Roboto",
    borderBottom: "1px solid #D9D9D9",
    padding: "3px 3px 3px 8px",
  }
}))

export default (props) => {
  const classes = useStyles()
  const [kseRESTApi] = useState(props.kseRESTApi)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [token] = useState(props.token)
  const [socket] = useState(props.socket)
  const [userProfile] = useState(props.userProfile)
  const [form, setForm] = useState(null)
  const [docList, setDocList] = useState([])
  const [initialDocList, setInitialDocList] = useState([])

  const [enumData, setEnumData] = useState({})

  const [updateState, setUpdateState] = useState(null)
  const [sortedColumn, setSortedColumn] = useState("null")
  const [sortedColumnOrder, setSortedColumnOrder] = useState(1)

  const [selectedInstrument, setSelectedInstrument] = useState(props.selectedInstrument)
  const [tableKeyHead, setTableKeyHead] = useState(getUUID())
  const [tableKeyBody, setTableKeyBody] = useState(getUUID())
  const [anchorEl, setAnchorEl] = useState(null)
  const openMenu = Boolean(anchorEl)
  const [searchValue, setSearchValue] = useState("")
  const [expanded, setExpanded] = useState(true)

  const [resizeDivIntsrtumentsId] = useState(getUUID())
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [size, setSize] = useState({ h: 320, w: 1200 })
  const [prevHeight, setPrevHeight] = useState(40)

  // Set data from props to state of component
  useEffect(async () => {
    console.log("INSTRUMENTS PROPS", props)
    let gForm = await props.fetchForm("c64984ab-55b3-4e61-aaf2-91b63992ea0e")
    let filteredForm = props.filterForm(gForm, props.settings)
    setForm(filteredForm)
    let enums = await props.getEnumDataByForm(gForm)
    setEnumData(enums)
    updateDocList()

    if (props.settings.size !== undefined) {
      setSize(props.settings.size)
      if (props.settings.size.h < 60) {
        setExpanded(false)
        setPrevHeight(600)
      }
    }
    if (props.settings.position !== undefined) {
      // setPosition(props.settings.position)
      positionHandler(null, props.settings.position, false)
    }
    props.setComponentIndex(props.getComponentIndex() + 1)
    setUpdateState(getUUID())
  }, [])
  useEffect(() => {
    // console.log("1CLK SELINSTR", selectedInstrument)
    if (selectedInstrument.id === null) {
      props.setSelectedInstrument({ id: null })
    }
    else {
      props.setSelectedInstrument(selectedInstrument)
    }
  }, [selectedInstrument])
  useEffect(async () => {
    if (props.updateFinanceInstrumentsHead !== null) {
      // console.log("HVAR", props.updateFinanceInstrumentsHead)
      let f = form
      if (f === null) {
        f = await props.fetchForm("c64984ab-55b3-4e61-aaf2-91b63992ea0e")
      }
      let filteredForm = props.filterForm(f, props.getInstrumentsSettingById(props.settings.id))
      setForm(filteredForm)
      setTableKeyHead(getUUID())
    }
  }, [props.updateFinanceInstrumentsHead])
  useEffect(async () => {
    if (props.updateFinanceInstrumentsBody !== null) {
      // console.log("HVAR", props.updateFinanceInstrumentsBody)
      updateDocList()
      setTableKeyBody(getUUID())
    }
  }, [props.updateFinanceInstrumentsBody])
  useEffect(async () => {
    if (props.updateFinanceInstrumentsZIndex !== null) {
      // console.log("HVAR", props.updateFinanceInstrumentsBody)
      props.setComponentIndex(props.getComponentIndex() + 1)
      setComponentIndex(props.getComponentIndex() + 1)
    }
  }, [props.updateFinanceInstrumentsZIndex])

  async function updateDocList() {
    await fetch(
      kseRESTApi + "/api/FinanceInstruments2/Filter", //"/api/FinanceInstruments2/FilterInit",
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
        "method": "POST",
        "body": JSON.stringify(props.getInstrumentFilters())
      }
    )
      .then(response => response.json())
      .then(function (res) {
        // console.log("Doc LIST INSTR", res.data)
        setInitialDocList(res.data)
        setDocList(res.data.sort(dynamicSort("orderNo", 1, "Int")))
        if (selectedInstrument !== null) {
          for (let i = 0; i < res.data.length; i++) {
            if (selectedInstrument.id === res.data[i].id) {
              // console.log("New Sel INSTR", res.data[i])
              setSelectedInstrument(res.data[i])
            }
          }
        }
        if (searchValue !== "") {
          filterInstruments(searchValue)
        }
        // loadFullDocList()
      })
      .catch(function (error) {
        return console.log("Collecting data error: ", error)
      })
  }
  // async function loadFullDocList(){
  //   await fetch(
  //     kseRESTApi + "/api/FinanceInstruments2/Filter", //"/api/FinanceInstruments2/Filter",
  //     {
  //       "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
  //       "method": "POST",
  //       "body": JSON.stringify(props.getInstrumentFilters())
  //     }
  //   )
  //     .then(response => response.json())
  //     .then(function (res) {
  //       console.log("Doc LIST INSTR", res.data)
  //       setInitialDocList(res.data)
  //       setDocList(res.data.sort(dynamicSort("orderNo", 1, "Int")))
  //       if (selectedInstrument !== null) {
  //         for (let i = 0; i < res.data.length; i++) {
  //           if (selectedInstrument.id === res.data[i].id) {
  //             // console.log("New Sel INSTR", res.data[i])
  //             setSelectedInstrument(res.data[i])
  //           }
  //         }
  //       }
  //       if (searchValue !== "") {
  //         filterInstruments(searchValue)
  //       }
  //       loadFullDocList()
  //     })
  //     .catch(function (error) {
  //       return console.log("Collecting data error: ", error)
  //     })
  // }
  function getContentTypeByName(name) {
    for (let s = 0; s < form.sections.length; s++) {
      for (let c = 0; c < form.sections[s].contents.length; c++) {
        if (name === form.sections[s].contents[c].name) {
          return form.sections[s].contents[c].type
        }
      }
    }
  }
  async function handleInstrumentContextMenuClick(event, docListItem) {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
    props.setSelectedInstrument(docListItem)
    formClick()
    // console.log("DBL SELINSTR", event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }
  // random UUID generator
  function getUUID() {
    return uuidv4()
  }

  // Create grid form components
  function getGridFormItems(docListItem, contentItem) {
    let value = docListItem[contentItem.name]
    let name = contentItem.name
    let type = contentItem.type
    if (type === "Enum") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="left" className={classes.tdBody} style={{ color: getColor(docListItem) }}>-</td>)
      }
      else {
        return (
          <td
            align="left"
            className={classes.tdBody}
            style={{ color: getColor(docListItem) }}
          >
            {getEnumLabel(name, value)}
          </td>
        )
      }
    }
    else if (type === "Bool") {
      return (
        <td
          align="center"
          className={classes.tdBody}
        >
          <Checkbox
            size="small"
            style={{ maxWidth: 20, height: 15, color: "#337E86" }}
            checked={(value === false || value === null || value === undefined) ? false : true}
          />
        </td>
      )
    }
    else if (type === "DateTime") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="right" className={classes.tdBody} style={{ color: getColor(docListItem) }}>-</td>)
      }
      else {
        let d = moment(value).format("DD.MM.YYYY HH:MM")
        // return d === "Invalid date" ? value : d
        return (
          <td
            align="right"
            className={classes.tdBody}
            style={{ color: getColor(docListItem) }}
          >
            {d === "Invalid date" ? value : d}
          </td>
        )
      }
    }
    else if (type === "Currency") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="right" className={classes.tdBody} style={{ color: getColor(docListItem) }}>-</td>)
      }
      else {
        let newCur = props.currencyBeautifier(value)
        // console.log("CUR", newCur)
        // return newCur
        return (
          <td
            align="right"
            className={classes.tdBody}
            style={{ color: getColor(docListItem) }}
          >
            {newCur}
          </td>
        )
      }
    }
    else if (type === "Int") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="right" style={{ color: getColor(docListItem) }}>-</td>)
      }
      else {
        let newInt = props.intBeautifier(value)
        return (
          <td
            align="right"
            className={classes.tdBody}
            style={{ color: getColor(docListItem) }}
          >
            {newInt}
          </td>
        )
      }
    }
    else {
      if (value === null || value === "" || value === undefined) {
        return (<td align="left" className={classes.tdBody} style={{ color: getColor(docListItem) }}>-</td>)
      }
      else {
        return (<td align="left" className={classes.tdBody} style={{ color: getColor(docListItem) }}>{value}</td>)
      }
    }
  }
  function getEnumLabel(name, id) {
    for (let d = 0; d < enumData[name].length; d++) {
      if (enumData[name][d].id === id) {
        return enumData[name][d].label
      }
    }
  }
  function dynamicSort(property, sortOrder, type) {
    if (type === "DateTime" || type === "Bool") {
      sortOrder = sortOrder * -1
    }
    if (type === "DateTime") {
      return function (a, b) {
        if (a[property] !== null && b[property] !== null) {
          let dateA = new Date(a[property].substring(0, 19))
          let timeInSecA = dateA.getTime() / 1000
          // console.log("timeInSecA", timeInSecA)
          let dateB = new Date(b[property].substring(0, 19))
          let timeInSecB = dateB.getTime() / 1000
          // console.log("timeInSecB", timeInSecB)
          var result = (timeInSecA < timeInSecB) ? -1 : (timeInSecA > timeInSecB) ? 1 : 0
          return result * sortOrder
        }
        else {
          if (a[property] === null) {
            return -1 * sortOrder
          }
          return 1 * sortOrder
        }
      }
    }
    else if (type === "Int" || type === "Text" || type === "Float" || type === "Bool") {
      return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
        return result * sortOrder
      }
    }
    else if (type === "Enum") {
      return function (a, b) {
        if (a[property] === null) {
          return 1 * sortOrder
        }
        else {
          let labelA = getEnumLabel(property, a[property])
          // console.log("A", property, a[property], labelA)
          let labelB = getEnumLabel(property, b[property])
          // console.log("labelB", labelB, property, b)
          var result = (labelA < labelB) ? -1 : (labelA > labelB) ? 1 : 0
          return result * sortOrder
        }

      }
    }
  }
  function sortDataByColumn(name, type) {
    // let contentItem = getContentItem(name)
    // let type = contentItem.type
    console.log("SORT", name, type)
    let sortOrder = 1
    if (sortedColumn === name) {
      sortOrder = sortedColumnOrder * -1
    }
    setSortedColumnOrder(sortOrder)
    setSortedColumn(name)
    let sortedDocList = docList.sort(dynamicSort(name, sortOrder, type))

    setDocList(sortedDocList)
    // fetchBySize(fetchForm, fetchTo, sortedDocList)
  }
  function instrumentClick(event, instrument) {
    console.log("CL EV", event.detail)
    if (event.detail === 1) {
      selectInstrument(instrument)
    }
    else if (event.detail === 2) {
      openQuotationForInstrument(instrument)
    }
    formClick()
  }
  function openQuotationForInstrument(instrument) {
    console.log("DB CLKICK", instrument)
    handleCloseMenu()
    props.openQuotationForInstrument(instrument)
  }
  function openChartForInstrument(instrument) {
    handleCloseMenu()
    props.openChartForInstrument(instrument)
  }
  function openInstrumentProperties(instrument, type) {
    handleCloseMenu()
    props.openInstrumentProperties(instrument, type)
  }
  // function showInstrumentProperties(){
  //   props.setShowPropertiesForInstrument(true)
  //   props.setShowPropertiesForInstrumentTaskType("edit")
  //   handleCloseMenu()
  // } 
  // function createInstrumentProp(){
  //   props.setShowPropertiesForInstrument(true)
  //   props.setShowPropertiesForInstrumentTaskType("create")
  //   handleCloseMenu()
  // }
  async function openTrades() {
    handleCloseMenu()
    await fetch(
      kseRESTApi + "/api/TradingScheduleManagement/SetStatusInstrument?instrumentId=" + selectedInstrument.id + "&statusId=1",
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token }
      }
    )
      .then(response => response.json())
      .then(function (res) {
        console.log("RES", res)
        if (res.isSuccess === true) {
          props.callSuccessToast("Торги открыты!", 1500)
        }
        else {
          props.callErrorToast(res.errors[0])
        }
      })
  }
  async function closeTrades() {
    handleCloseMenu()
    await fetch(
      kseRESTApi + "/api/TradingScheduleManagement/SetStatusInstrument?instrumentId=" + selectedInstrument.id + "&statusId=3",
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token }
      }
    )
      .then(response => response.json())
      .then(function (res) {
        console.log("RES", res)
        if (res.isSuccess === true) {
          props.callSuccessToast("Торги закрыты!", 1500)
        }
        else {
          props.callErrorToast(res.errors[0])
        }
      })
  }
  async function deleteInstrument() {
    handleCloseMenu()
    swal({
      text: "Вы действительно хотите удалить этот инструмент?",
      icon: "warning",
      buttons: { ok: "Да", cancel: "Отмена" },
    })
      .then(async (click) => {
        if (click === "ok") {
          await fetch(
            kseRESTApi + "/api/FinanceInstruments?id=" + selectedInstrument.id,
            {
              "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
              "method": "DELETE"
            }
          )
            .then(response => response.json())
            .then(function (res) {
              if (res.isSuccess === true) {
                updateDocList()
                props.callSuccessToast("Инструмент " + selectedInstrument.code + " удален!")
              }
              else {
                props.callErrorToast(res.errors[0])
              }
            })
        }
      })
  }

  function getBackground(instr, index) {
    if (selectedInstrument.id === instr.id) {
      return "#CECECE"
    }
    else {
      if (index % 2 === 0) {
        return "#FBFBFB"
      }
      else {
        return "#f7f7f7"
      }
    }
  }

  function getColor(instr) {
    if (selectedInstrument.id === instr.id) {
      if (instr.textColor === "black") {
        return "black"
      }
      else {
        return instr.textColor
      }
    }
    else{
      if (instr.textColor === "black") {
        return "#444"
      }
      else {
        return instr.textColor
      }
    }
  }
  function handleSearchChange(event) {
    // console.log("EVENT", event.target.value)
    setSearchValue(event.target.value)
    filterInstruments(event.target.value)
  }
  function filterInstruments(sValue) {
    if (sValue !== "") {
      let filteredDocList = []
      for (let i = 0; i < initialDocList.length; i++) {
        let searchField = sValue.toLowerCase()
        let code = initialDocList[i].code !== null ? initialDocList[i].code.toLowerCase() : ""
        let isin = initialDocList[i].isin !== null ? initialDocList[i].isin.toLowerCase() : ""
        let fullNameRus = initialDocList[i].fullNameRus !== null ? initialDocList[i].fullNameRus.toLowerCase() : ""
        let fullNameEng = initialDocList[i].fullNameEng !== null ? initialDocList[i].fullNameEng.toLowerCase() : ""
        let includeCode = code.includes(searchField)
        let includeIsin = isin.includes(searchField)
        let includeFullNameRus = fullNameRus.includes(searchField)
        let includeFullNameEng = fullNameEng.includes(searchField)
        if (includeCode === true || includeFullNameRus === true || includeFullNameEng === true || includeIsin === true) {
          filteredDocList.push(initialDocList[i])
        }
      }
      setDocList(filteredDocList)
    }
    else {
      setDocList(initialDocList)
    }
  }
  // function onKeyPressSearch(event){
  //   let code = event.charCode
  //   if(code === 13){
  //     console.log("ENTER")
  //     setSearchValue(event.target.value)
  //     filterInstruments(event.target.value)
  //   }
  // }
  function showInstrumentFilters() {
    props.setShowInstrumentFilters(true)
  }

  function formClick() {
    // console.log("CLICK")
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 2)
    props.setComponentIndex(currIndex + 2)

  }
  // function getModalStyle() {
  //   const top = 15;
  //   const left = 2;
  //   let hh = size.h
  //   let ww = size.w
  //   return {
  //     height: expanded === true ? "320px" : "40px",
  //     top: `${top}%`,
  //     left: `${left}%`,
  //     transform: `translate(-${top}%, -${left}%)`,
  //     zIndex: componentIndex
  //   };
  // }

  // Describe allowed positions for different screen sizes
  function positionHandler(e, data, updProfile) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 2 // Take 2% of width
    let maxDeviationX = (dimension.w / 100) * 90 // Take 90% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    // console.log("minX", minDeviationX, "maxX", maxDeviationX, "maxY", maxDeviationY)
    if (data.y < maxDeviationY) {
      // console.log("SET MIN Y", data.x)
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      // console.log("SET MIN X", data.x)
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      // console.log("SET MAX X", data.x)
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
    if (updProfile !== false) {
      let settings = {
        id: props.settings.id,
        type: props.settings.type,
        columns: props.settings.columns,
        position: { x: x, y: y },
        size: { h: size.h, w: size.w }
      }
      props.updateUserSettingsByType(settings)
    }

    filterInstruments(searchValue)
  }
  function sizeHandler() {
    var clientHeight = document.getElementById(resizeDivIntsrtumentsId).clientHeight;
    var clientWidth = document.getElementById(resizeDivIntsrtumentsId).clientWidth;
    let diffH = Math.abs(clientHeight - size.h)
    let diffW = Math.abs(clientWidth - size.w)
    // console.log("diffH", diffH, "diffW", diffW)
    if (diffH > 2 || diffW > 2) {
      // console.log("SIZE CHANGED", "H", clientHeight, "HS", size.h, "W", clientWidth, "WS", size.w)
      let settings = {
        id: props.settings.id,
        type: props.settings.type,
        columns: props.settings.columns,
        position: { x: position.x, y: position.y },
        size: { h: clientHeight, w: clientWidth }
      }
      props.updateUserSettingsByType(settings)
      setSize({ h: clientHeight, w: clientWidth })
      if (expanded === false) {
        setExpanded(true)
        setPrevHeight(size.h)
      }
    }
  }
  function expand() {
    // console.log("PREV H", prevHeight)
    setSize({ h: prevHeight, w: size.w })
    setExpanded(!expanded)
    setPrevHeight(size.h)
    let settings = {
      id: props.settings.id,
      type: props.settings.type,
      columns: props.settings.columns,
      position: { x: position.x, y: position.y },
      size: { h: prevHeight, w: size.w }
    }
    props.updateUserSettingsByType(settings)
  }
  function selectInstrument(docListItem) {
    setSelectedInstrument(docListItem)
    setTableKeyBody(getUUID())
  }
  function getWidth() {
    let w = "250%"
    for (let s = 0; s < form.sections.length; s++) {
      for (let c = 0; c < form.sections[s].contents.length; c++) {
        if (form.sections[s].contents[c].show === false) {
          w = "100%"
          break
        }
      }
    }
    return w
  }


  if (updateState !== null && form !== null) {
    try {
      return (
        <Draggable
          handle="p"
          onStart={() => formClick()}
          onStop={positionHandler}
          position={position}
        // onMouseDown={() => formClick()}
        >
          <div
            id={resizeDivIntsrtumentsId}
            onClick={() => sizeHandler()}
            className={classes.resizeForm}
            style={{ height: size.h, width: size.w, top: "15%", left: "2%", transform: "translate(-15%, -2%)", zIndex: componentIndex }}
          >
            <div className="main-table-label-div-style-new" onDoubleClick={() => expand()}>
              <p className="main-table-label-p-style-new">
                <table width="100%">
                  <tr>
                    <td width={"10%"} style={{ paddingLeft: 10 }}>Инструменты</td>
                    <td width={"85%"}>
                      <table width="100%">
                        <tr>
                          <td width={"1%"} />
                          <td width={"30%"}>
                            <TextField
                              id="input-with-icon-textfield"
                              placeholder="Поиск по инструментам"
                              style={{ width: "100%", background: "#F6F5F5" }}
                              value={searchValue}
                              onChange={handleSearchChange}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start" style={{ paddingLeft: "10px" }}>
                                    <SearchIcon fontSize="small" />
                                  </InputAdornment>
                                ),
                              }}
                              variant="standard"
                              size="small"
                            />
                          </td>
                          <td width={"2%"}>
                            <AiFillFilter size={22} style={{ cursor: "pointer", color: "868686", marginTop: 3 }} onClick={() => showInstrumentFilters()} />
                          </td>
                          <td width={"59%"}></td>
                        </tr>
                      </table>
                    </td>
                    {expanded == false
                      ?
                      <td>
                        <MdExpandLess size={22} className="form-expand-icons-style" onClick={() => expand()} />
                      </td>
                      :
                      <td>
                        <MdExpandMore size={22} className="form-expand-icons-style" onClick={() => expand()} />
                      </td>
                    }
                    <td>
                      <IoIosSettings size={22} className="form-settings-icons-style" onClick={() => props.showFormManagement(props.settings)} />
                    </td>
                    <td>
                      <IoMdClose size={22} className="form-close-icons-style" onMouseDown={() => props.closeInstrumentsTable(props.settings)} />
                    </td>
                  </tr>
                </table>
              </p>
            </div>
            {expanded === true &&
              <div style={{ height: "100%", width: "100%", overflow: "scroll" }}>
                <table className="main-table-style-new" width={getWidth()} id={tableKeyHead} key={tableKeyHead}>
                  <thead>
                    <tr>
                      <th class="main-table-number-style">
                        №
                      </th>
                      {form.sections.map(section =>
                        section.contents.map(contentItem => {
                          if (contentItem.show === true) {
                            return (
                              <th class="main-table-header-th-style-new" style={{textAlign: contentItem.type === "Currency" || contentItem.type === "DateTime" ? "right" : "left"}}>
                                {contentItem.label}
                              </th>
                            )
                          }
                        })
                      )}
                    </tr>
                  </thead>
                  <tbody style={{ background: "white" }} key={tableKeyBody}>
                    {Object.keys(docList).length > 0 &&
                      docList.map((docListItem, index) => (
                        <tr
                          style={{ background: getBackground(docListItem, index), color: docListItem.textColor }}
                          // onClick={() => selectInstrument(docListItem)}
                          // onDoubleClick={() => openQuotationForInstrument(docListItem)}
                          onMouseDown={(ev) => instrumentClick(ev, docListItem)}
                          onContextMenu={(ev) => handleInstrumentContextMenuClick(ev, docListItem)}
                        >
                          <td
                            align="center"
                            class="main-table-number-style"
                            style={{ borderRight: "1px solid rgb(217, 217, 217)" }}
                          >
                            {index + 1}
                          </td>
                          {form.sections.map(section => (
                            section.contents.map(contentItem => {
                              if (contentItem.show === true) {
                                return getGridFormItems(docListItem, contentItem)
                              }
                            })
                          ))}
                        </tr>
                      )
                      )}
                  </tbody>
                </table>
                <Menu
                  id="menu-instruments"
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    'aria-labelledby': tableKeyHead
                  }}
                >
                  <MenuItem onClick={() => openQuotationForInstrument(selectedInstrument)} style={{ color: "black" }}>
                    Котировка
                    <ListItemIcon style={{ color: "black" }}>
                      <AlignHorizontalLeftIcon fontSize="small" className={classes.menuIconStyle} />
                    </ListItemIcon>
                  </MenuItem>
                  <MenuItem onClick={() => openChartForInstrument(selectedInstrument)} style={{ color: "black" }}>
                    Диаграмма
                    <ListItemIcon style={{ color: "black" }}>
                      <ChartIcon fontSize="small" className={classes.menuIconStyle} />
                    </ListItemIcon>
                  </MenuItem>
                  {userProfile.role.name === "Administrator" &&
                    <React.Fragment>
                      <MenuItem
                        onClick={() => openTrades()}
                        style={{ color: "black" }}>
                        Открыть торги
                        <ListItemIcon style={{ color: "black" }}>
                          <LockOpenIcon fontSize="small" className={classes.menuIconStyle} />
                        </ListItemIcon>
                      </MenuItem>
                      <MenuItem
                        onClick={() => closeTrades()}
                        style={{ color: "black" }}>
                        Закрыть торги
                        <ListItemIcon style={{ color: "black" }}>
                          <LockIcon fontSize="small" className={classes.menuIconStyle} />
                        </ListItemIcon>
                      </MenuItem>
                      <MenuItem
                        onClick={() => openInstrumentProperties({}, "create")}
                        style={{ color: "black" }}>
                        Создать
                        <ListItemIcon style={{ color: "black" }}>
                          <AddCircleOutlineIcon fontSize="small" className={classes.menuIconStyle} />
                        </ListItemIcon>
                      </MenuItem>
                      <MenuItem
                        onClick={() => deleteInstrument()}
                        style={{ color: "black" }}>
                        Удалить
                        <ListItemIcon style={{ color: "black" }}>
                          <DeleteForeverIcon fontSize="small" className={classes.menuIconStyle} />
                        </ListItemIcon>
                      </MenuItem>
                    </React.Fragment>
                  }
                  <MenuItem onClick={() => openInstrumentProperties(selectedInstrument, (userProfile.role.name === "Administrator" ? "edit" : "view"))} style={{ color: "black" }}>
                    Свойства
                    <ListItemIcon style={{ color: "black" }}>
                      <SettingsIcon fontSize="small" className={classes.menuIconStyle} />
                    </ListItemIcon>
                  </MenuItem>
                </Menu>
              </div>
            }
            <CssBaseline />
          </div>
        </Draggable>
        // </Rnd>
      )
    }
    catch (er) {
      console.log("ERROR", er)
      return <div>Loading...</div>
    }
  }
  else return <div>Loading...</div>
}

