import React, { useCallback, useState, useRef, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';


//Style
import "../styles/generalStyles.css"
// Libraries

const useStyles = makeStyles(() => ({
  resizeForm: {
    border: "1px solid grey",
    display: "flex",
    backgroundColor: "white",
    "flex-flow": "column",
    height: "320px",
    width: "90%",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "30px",
    background: "white",
    position: 'absolute',
  },
}))

export default (props) => {
  const classes = useStyles()
  // Set data from props to state of component
  useEffect(async () => {
    console.log("RESIZE", props)
  }, [])
  // Query the element
  const ele = document.getElementById('resizeMe');

  // The current position of mouse
  let rx = 0;
  let ry = 0;

  // The dimension of the element
  let rw = 0;
  let rh = 0;
  // Handle the mousedown event
  // that's triggered when user drags the resizer
  const mouseDownHandler = function (e) {
    console.log("M DOWN", e.clientX, e.clientY)
    // Get the current mouse position
    rx = e.clientX;
    ry = e.clientY;

    // Calculate the dimension of element
    const styles = window.getComputedStyle(ele);
    rw = parseInt(styles.width, 10);
    rh = parseInt(styles.height, 10);

    // Attach the listeners to `document`
    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  const mouseMoveHandler = function (e) {
    // How far the mouse has been moved
    const dx = e.clientX - rx;
    const dy = e.clientY - ry;

    // Adjust the dimension of element
    ele.style.width = `${rw + dx}px`;
    ele.style.height = `${rh + dy}px`;
  };

  const mouseUpHandler = function () {
    // Remove the handlers of `mousemove` and `mouseup`
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);
  };

  if (ele !== null) {
    // Query all resizers
    const resizers = ele.querySelectorAll('div');

    // Loop over them
    [].forEach.call(resizers, function (resizer) {
      resizer.addEventListener('mousedown', mouseDownHandler);
    });
  }


  return (
    <div id="resizeMe" class="resizable" style={{ top: "15%", left: "2%", transform: "translate(-15%, -2%)"}}>
      Resize me
      <div className="resizer resizer-r"></div>
      <div className="resizer resizer-b"></div>
    </div>
  )
}

