import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
// Icons
// import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';
// import { BsArrowDown } from 'react-icons/bs';
import { IoMdClose } from 'react-icons/io';
// Form components
import Checkbox from '@material-ui/core/Checkbox';
import Draggable from 'react-draggable';
// import TextareaAutosize from '@mui/material/TextareaAutosize';
// import Select from 'react-select';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

//Style
import "../styles/generalStyles.css"
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";
var fetch = require('node-fetch');

function IntegerFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={" "}
      isNumericString
    />
  );
}
IntegerFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

const useStyles = makeStyles((theme) => ({
  resizeForm: {
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    border: "1px solid #2B4D80",
    display: "flex",
    backgroundColor: "white",
    boxShadow: theme.shadows[1],
    "flex-flow": "column",
    width: "70%",
    height: "400px",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "150px",
    position: 'absolute',
  },
  // modal: {
  //   position: 'absolute',
  //   width: 900,
  //   height: 400,
  //   backgroundColor:"#eceff1",
  //   border: '1px solid #AFAFAF',
  //   borderRadius: "7px",
  //   boxShadow: theme.shadows[1],
  //   padding: 3,
  //   resize: "both",
  //   overflow: "auto",
  //   minWidth: "150px",
  //   minHeight: "150px",
  // },
  dep: {
    position: 'absolute',
    width: 500,
    height: 300,
    backgroundColor: "#eceff1",
    border: '1px solid #AFAFAF',
    borderRadius: "7px",
    boxShadow: theme.shadows[1],
    padding: 3,
    resize: "both",
    overflow: "auto",
    minWidth: "150px",
    minHeight: "150px",
  },
  tdBody: {
    cursor: "pointer",
    fontSize: 13,
    fontWeight: "light",
    height: "30px",
    minWidth: "1px",
    maxWidth: "150px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "elipsis '[..]'",
    overflow: "hidden",
    fontFamily: "Roboto",
    borderBottom: "1px solid #D9D9D9"
  }
}))

export default (props) => {
  const classes = useStyles()
  const [kseRESTApi] = useState(props.kseRESTApi)
  const [token] = useState(props.token)
  const [userProfile] = useState(props.userProfile)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [form, setForm] = useState(null)
  const [docList, setDocList] = useState(null)
  const [enumData, setEnumData] = useState({})
  const [enumOptions, setEnumOptions] = useState({})
  const [selectedOptions, setSelectedOptions] = useState({})
  const [sortedColumn, setSortedColumn] = useState("null")
  const [sortedColumnOrder, setSortedColumnOrder] = useState(1)
  const [selectedItem, setSelectedItem] = useState({ id: null })
  const [position, setPosition] = useState({ x: 0, y: 0 })

  // Set data from props to state of component
  useEffect(async () => {
    console.log("CUR POS", props)
    let gForm = await props.fetchForm("79f4ab13-8221-422b-ba6a-2dec0bdfec14")
    setForm(gForm)
    let enums = await props.getEnumDataByForm(gForm)
    setEnumData(enums)
    console.log("CUR POS EN", enums)
    let eOpts = await props.createEnumOptions(enums)
    setEnumOptions(eOpts)
    let docL = await props.fetchDocList("/api/Accounts/ViewCurrencies")
    console.log("DOCL CUR POS", docL)
    // let instruments = await props.fetchDocList("/api/api/FinanceInstruments")
    setDocList(docL.sort(dynamicSort("id", 1, "Int")))
    props.setComponentIndex(props.getComponentIndex() + 1)
    // props.setShowAccountsCurrenciesDepositing(true)
  }, [])
  useEffect(async () => {
    if (props.updateAccountsCurrenciesPositions !== null) {
      let docL = await props.fetchDocList("/api/Accounts/ViewCurrencies")
      console.log("DOCL CUR POS", docL)
      setDocList(docL)
      let f = form
      if (f === null) {
        f = await props.fetchForm("79f4ab13-8221-422b-ba6a-2dec0bdfec14")
      }
      setForm(f)
      // setTableKey(getUUID())
    }
  }, [props.updateAccountsCurrenciesPositions])
  useEffect(async () => {
    if (props.updateAccountsCurrenciesPositionsZIndex !== null) {
      // console.log("HVAR", props.updateFinanceInstrumentsBody)
      props.setComponentIndex(props.getComponentIndex() + 1)
      setComponentIndex(props.getComponentIndex() + 1)
    }
  }, [props.updateAccountsCurrenciesPositionsZIndex])
  // Create grid form components
  function getGridFormItems(docListItem, contentItem) {
    let value = docListItem[contentItem.name]
    let name = contentItem.name
    let type = contentItem.type
    if (type === "Enum") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="left" className={classes.tdBody} style={{ color: getColor(docListItem) }}>-</td>)
      }
      else {
        return (
          <td
            align="left"
            className={classes.tdBody}
            style={{ color: getColor(docListItem) }}
          >
            {getEnumLabel(name, value)}
          </td>
        )
      }
    }
    else if (type === "Bool") {
      return (
        <td
          align="center"
          className={classes.tdBody}
        >
          <Checkbox
            size="small"
            style={{ maxWidth: 20, height: 15, color: "#337E86" }}
            checked={(value === false || value === null || value === undefined) ? false : true}
          />
        </td>
      )
    }
    else if (type === "DateTime") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="right" className={classes.tdBody} style={{ color: getColor(docListItem) }}>-</td>)
      }
      else {
        let d = moment(value).format("DD.MM.YYYY HH:MM")
        // return d === "Invalid date" ? value : d
        return (
          <td
            align="right"
            className={classes.tdBody}
            style={{ color: getColor(docListItem) }}
          >
            {d === "Invalid date" ? value : d}
          </td>
        )
      }
    }
    else if (type === "Currency") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="right" className={classes.tdBody} style={{ color: getColor(docListItem) }}>-</td>)
      }
      else {
        let newCur = props.currencyBeautifier(value)
        // console.log("CUR", newCur)
        // return newCur
        return (
          <td
            align="right"
            className={classes.tdBody}
            style={{ color: getColor(docListItem) }}
          >
            {newCur}
          </td>
        )
      }
    }
    else if (type === "Int") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="right" style={{ color: getColor(docListItem) }}>-</td>)
      }
      else {
        let newInt = props.intBeautifier(value)
        return (
          <td
            align="right"
            className={classes.tdBody}
            style={{ color: getColor(docListItem) }}
          >
            {newInt}
          </td>
        )
      }
    }
    else {
      if (value === null || value === "" || value === undefined) {
        return (<td align="left" className={classes.tdBody} style={{ color: getColor(docListItem) }}>-</td>)
      }
      else {
        return (<td align="left" className={classes.tdBody} style={{ color: getColor(docListItem) }}>{value}</td>)
      }
    }
  }
  function getBackground(item, index) {
    if (selectedItem.id === item.id) {
      return "#CECECE"
    }
    else {
      if (index % 2 === 0) {
        return "#FBFBFB"
      }
      else {
        return "#f7f7f7"
      }
    }
  }
  function getColor(item) {
    if (selectedItem.id === item.id) {
      if (item.textColor === "black") {
        return "black"
      }
      else {
        return item.textColor
      }
    }
    else {
      if (item.textColor === "black") {
        return "#444"
      }
      else {
        return item.textColor
      }
    }
  }
  function getEnumLabel(name, id) {
    for (let d = 0; d < enumData[name].length; d++) {
      if (enumData[name][d].id === id) {
        return enumData[name][d].label
      }
    }
  }
  function dynamicSort(property, sortOrder, type) {
    if (type === "DateTime" || type === "Bool") {
      sortOrder = sortOrder * -1
    }
    if (type === "DateTime") {
      return function (a, b) {
        if (a[property] !== null && b[property] !== null) {
          let dateA = new Date(a[property].substring(0, 19))
          let timeInSecA = dateA.getTime() / 1000
          // console.log("timeInSecA", timeInSecA)
          let dateB = new Date(b[property].substring(0, 19))
          let timeInSecB = dateB.getTime() / 1000
          // console.log("timeInSecB", timeInSecB)
          var result = (timeInSecA < timeInSecB) ? -1 : (timeInSecA > timeInSecB) ? 1 : 0
          return result * sortOrder
        }
        else {
          if (a[property] === null) {
            return -1 * sortOrder
          }
          return 1 * sortOrder
        }
      }
    }
    else if (type === "Int" || type === "Text" || type === "Float" || type === "Bool") {
      return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
        return result * sortOrder
      }
    }
    else if (type === "Enum") {
      return function (a, b) {
        if (a[property] === null) {
          return 1 * sortOrder
        }
        else {
          let labelA = getEnumLabel(property, a[property])
          // console.log("A", property, a[property], labelA)
          let labelB = getEnumLabel(property, b[property])
          // console.log("labelB", labelB, property, b)
          var result = (labelA < labelB) ? -1 : (labelA > labelB) ? 1 : 0
          return result * sortOrder
        }

      }
    }
  }
  function sortDataByColumn(name, type) {
    let sortOrder = 1
    if (sortedColumn === name) {
      sortOrder = sortedColumnOrder * -1
    }
    setSortedColumnOrder(sortOrder)
    setSortedColumn(name)
    let sortedDocList = docList.sort(dynamicSort(name, sortOrder, type))
    setDocList(sortedDocList)
    // fetchBySize(fetchForm, fetchTo, sortedDocList)
  }
  function getModalStyle() {
    const top = 15;
    const left = 10;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      zIndex: componentIndex
    };
  }
  function formClick() {
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 1)
    props.setComponentIndex(currIndex + 1)
  }

  function selecteItem(docListItem) {
    setSelectedItem(docListItem)
    // console.log("SEL IT", docListItem)
    // for(let i=0; i<instruments.length; i++){
    //   if(docListItem.financeInstrumentId === instruments[i].id){
    //     props.setSelectedFinanceInstrument(instruments[i])
    //   }
    // }
  }
  function positionHandler(e, data) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 10 // Take 10% of width
    let maxDeviationX = (dimension.w / 100) * 80 // Take 80% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    // console.log("minX", minDeviationX, "maxX", maxDeviationX, "maxY", maxDeviationY)
    if (data.y < maxDeviationY) {
      // console.log("SET MIN Y", data.x)
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      // console.log("SET MIN X", data.x, minDeviationX)
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      // console.log("SET MAX X", data.x)
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
  }
  return (
    // <Draggable
    //   handle="p"
    //   onStart={() => formClick()}
    //   onMouseDown={() => formClick()}
    // >
    //   <div style={getModalStyle()} className={classes.resizeForm}>
    <Draggable
      handle="p"
      onStart={() => formClick()}
      onStop={positionHandler}
      position={position}
    >
      <div
        className={classes.resizeForm}
        style={{ top: "15%", left: "10%", transform: "translate(-15%, -10%)", zIndex: componentIndex }}
        onClick={() => formClick()}
      >
        <p className="main-table-label-p-style-new">
          <table className="dragble-div-header-table-stylenew">
            <tr>
              {userProfile.role.name === "Administrator" &&
                <td width="4%" className="dragble-div-header-td-style-new">
                  <button onClick={() => props.setShowAccountsCurrenciesDepositing(true)}>+</button>
                </td>
              }
              <td width="99%" className="dragble-div-header-td-style-new">Позиции ДС</td>
              <td>
                <IoMdClose size={22} className="form-close-icons-style" style={{ marginRight: "5px" }} onClick={() => props.setShowAccountsCurrenciesPositions(false)} />
              </td>
            </tr>
          </table>
        </p>
        {docList !== null && form !== null &&
          <div className="dragble-div-body-style-new">
            <table className="main-table-style-new" style={{ width: "100%" }}>
              <thead style={{ backgroundColor: "#cfd8dc" }}>
                <tr>
                  <th
                    class="main-table-header-th-style-new"
                    style={{
                      borderRight: "1px solid #D9D9D9",
                      fontWeight: "bold",
                      color: "#6D6D6D",
                      background: "#F6F4F3",
                      textAlign: "center",
                      fontSize: "13px"
                    }}
                  >
                    №
                  </th>
                  {form.sections.map(section =>
                    section.contents.map(contentItem => {
                      if (contentItem.show === true) {
                        return (
                          <th class="main-table-header-th-style-new">
                            {contentItem.label}
                          </th>
                        )
                      }
                    })
                  )}
                </tr>
              </thead>
              <tbody>
                {Object.keys(docList).length !== 0 &&
                  docList.map((docListItem, index) => (
                    <tr
                      style={{ background: getBackground(docListItem, index) }}
                      onMouseDown={() => selecteItem(docListItem)}
                    >
                      <td
                        align="center"
                        class="main-table-number-style"
                        style={{ borderRight: "1px solid rgb(217, 217, 217)" }}
                      >
                        {index + 1}
                      </td>
                      {form.sections.map(section => (
                        section.contents.map(contentItem => {
                          if (contentItem.show === true) {
                            return getGridFormItems(docListItem, contentItem)
                          }
                        })
                      ))}
                    </tr>
                  )
                  )}
              </tbody>
            </table>
          </div>
        }
        {/* {showDepositing === true &&
          <Draggable handle="p">
            <div style={getModalStyleDep()} className={classes.dep}>
              <p className="main-table-label-p-style-new">
                <table className="dragble-div-header-table-style-new">
                  <tr>
                    <td width="99%" className="dragble-div-header-td-style-new">Депонирование</td>
                    <td onClick={() => setShowDepositing(false)}><IconButton size="small"><CloseIcon /></IconButton></td>
                  </tr>
                </table>
              </p>
              <table width="100%">
                <tr>
                  <td width="100%">
                    <Select
                      name={"accountId"}
                      placeholder={"Найти счет..."}
                      value={selectedOptions["accountId"]}
                      onChange={handleSelectChange}
                      options={enumOptions["accountId"]}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                  </td>
                </tr>
              </table>
              <table width="100%">
                <tr>
                  <td className="dragble-div-body-td-text-style-new" width="30%">Количество</td>
                  <td width="70%">
                    <input
                      type='number'
                      style={{ width: 120 }}
                      value={fieldValue.quantity}
                      onChange={handleIntChange}
                      name="quantity"
                    // InputProps={{inputComponent: IntegerFormat}}
                    />
                  </td>
                </tr>
              </table>
              <button
                onClick={() => replenish()}
              >Депонировать</button>
            </div>
          </Draggable>
        } */}
      </div>
    </Draggable>
  )
}

