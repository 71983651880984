import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
// Icons
import { IoMdClose } from 'react-icons/io';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
// CUSTOM COMPONENTS
import ConfigurationFile from "../configuration/ConfigurationFile.json";
// Library
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import Draggable from 'react-draggable';
import swal from 'sweetalert'; // https://sweetalert.js.org/guides/
var fetch = require('node-fetch');

const useStyles = makeStyles((theme) => ({
  resizeForm: {
    borderRadius: "3px",
    border: "1px solid #2B4D80",
    display: "flex",
    backgroundColor: "white",
    boxShadow: theme.shadows[1],
    "flex-flow": "column",
    width: "40%",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "150px",
    position: 'absolute',
  }
  // modal: {
  //   position: 'absolute',
  //   width: 550,
  //   // height: 200,
  //   backgroundColor:"#eceff1", // theme.palette.background.paper,
  //   borderRadius: "7px",
  //   border: '1px solid #78909c',
  //   boxShadow: theme.shadows[1],
  //   padding: 4
  //   // theme.spacing(1, 1, 1),
  // }
}))
function FloatFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      decimalSeparator={"."}
      thousandSeparator={" "}
      isNumericString
    />
  )
}
FloatFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}
function IntegerFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={" "}
      isNumericString
    />
  );
}
IntegerFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default (props) => {
  const classes = useStyles()
  const [token] = useState(props.token)
  const [kseRESTApi] = useState(props.kseRESTApi) //Local KFB main REST
  const [userProfile, setUserProfile] = useState(props.userProfile)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [enumData, setEnumData] = useState({})
  const [enumOptions, setEnumOptions] = useState({})
  const [selectedOptions, setSelectedOptions] = useState({})

  // FIELDS
  const [fieldValue, setFieldValue] = useState({
    instrumentId: props.selectedInstrument.id !== null ? props.selectedInstrument.id : null,
    fixingStatusId: null,
    priceForInstrument: null,
    priceForInstrumentStep: 1
  })
  // const [time, setTime] = useState(null)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  useEffect(async () => {
    console.log("OP FIX TRADES PROPS", props)
    let enumDataToCollect = [
      { enumName: "instrumentId", enumDef: "3e819d7e-25d0-4a04-a3ff-092fd348a375" },
      { enumName: "fixingStatusId", enumDef: "25996f57-b87b-45f8-83df-de45ef7f612e" }
    ]
    let enums = await props.getEnumDataByList(enumDataToCollect)
    // console.log("BUY ENUMS", enums)
    setEnumData(enums)
    let eOpts = await props.createEnumOptions(enums)
    setEnumOptions(eOpts)
    let sOpts = { ...selectedOptions }
    if (props.selectedInstrument.id !== null) {
      let f = "instrumentId"
      for (let d = 0; d < enums[f].length; d++) {
        if (enums[f][d].id === props.selectedInstrument.id) {
          sOpts[f] = { "value": enums[f][d].id, "label": enums[f][d].label, "name": f }
        }
      }
    }
    setSelectedOptions(sOpts)
    props.setComponentIndex(props.getComponentIndex() + 1)
  }, [])
  function swAllert(text, icon) {
    return (
      swal({
        text: text,
        icon: icon,
        buttons: { ok: "Ок" },
      })
    )
  }
  // random UUID generator
  function getUUID() {
    return uuidv4()
  }
  // INPUT HANDLERS
  // SELECT
  function handleSelectChange(option) {
    setSelectedOptions({ ...selectedOptions, [option.name]: option })
    console.log("OPT", option, fieldValue)
    setFieldValue({ ...fieldValue, [option.name]: option.value })
  }
  // INT
  const handleIntChange = (event) => {
    // console.log("EVENT", event.target.name, event.target.value)
    if (event.target.value !== "") {
      let stringNum = event.target.value.toString().replace(/ /g, '')
      let int = parseInt(stringNum)
      setFieldValue({ ...fieldValue, [event.target.name]: int })
    }
    else {
      setFieldValue({ ...fieldValue, [event.target.name]: null })
    }
  }
  function add(name, step) {
    let newPrice = fieldValue[name] + fieldValue[step]
    setFieldValue({ ...fieldValue, [name]: newPrice })
    // console.log("NEW PRICE", newPrice)
  }
  function reduce(name, step) {
    let newPrice = fieldValue[name] - fieldValue[step]
    setFieldValue({ ...fieldValue, [name]: newPrice })
    // console.log("NEW PRICE", newPrice)
  }
  async function openTrades() {
    console.log("FFF", fieldValue)
    // let fullDate = new Date(time)
    // var hours = fullDate.getHours()
    // var minutes = fullDate.getMinutes()
    // if(fieldValue.fixingStatusId !== null){
    if (fieldValue.instrumentId !== null) {
      if (fieldValue.priceForInstrument !== "") {
        let body = {
          "instrumentId": fieldValue.instrumentId,
          "fixingStatusId": fieldValue.fixingStatusId,
          "price": fieldValue.priceForInstrument,
          // "hh": hours,
          // "mm": minutes
        }
        console.log("BODY", body)
        await fetch(
          kseRESTApi + "/api/FixTrading/StartInitial",
          {
            "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
            "method": "POST",
            "body": JSON.stringify(body)
          }
        )
          .then(response => response.json())
          .then(function (res) {
            console.log("RES", res)
            if (res.isSuccess === true) {
              props.callSuccessToast("Торги открыты")
              props.setShowOpenFixingTrades(false)
            }
            else {
              props.callErrorToast(res.errors[0])
            }
          })
      } else { swAllert("Введите цену!", "warning") }
    } else { swAllert("Введите инструмент!", "warning") }
    // }else{swAllert("Введите статус!", "warning")}
  }
  function getModalStyle() {
    const top = 15;
    const left = 25;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      zIndex: componentIndex
    }
  }
  function formClick() {
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 1)
    props.setComponentIndex(currIndex + 1)
  }
  function positionHandler(e, data) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 12 // Take 12% of width
    let maxDeviationX = (dimension.w / 100) * 80 // Take 80% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    // console.log("minX", minDeviationX, "maxX", maxDeviationX, "maxY", maxDeviationY)
    if (data.y < maxDeviationY) {
      // console.log("SET MIN Y", data.x)
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      // console.log("SET MIN X", data.x, minDeviationX)
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      // console.log("SET MAX X", data.x)
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
  }
  return (
    // <Draggable
    //   handle="p"
    //   onStart={() => formClick()}
    //   onMouseDown={() => formClick()}
    // >
    //   <div style={getModalStyle()} className={classes.resizeForm}>
    <Draggable
      handle="p"
      onStart={() => formClick()}
      onStop={positionHandler}
      position={position}
    >
      <div
        className={classes.resizeForm}
        style={{ top: "15%", left: "12%", transform: "translate(-15%, -12%)", zIndex: componentIndex }}
        onClick={() => formClick()}
      >
        <p className="main-table-label-p-style-new">
          <table className="dragble-div-header-table-style-new">
            <tr>
              <td width="99%" sclassName="dragble-div-header-td-style-new">Открытие пред. торгов методом ФИКСИНГА</td>
              <td>
                <IoMdClose size={22} className="form-close-icons-style" style={{ marginRight: "5px" }} onClick={() => props.setShowOpenFixingTrades(false)} />
              </td>
            </tr>
          </table>
        </p>
        <div className="dragble-div-body-style-new">
          <div>
            <table width="100%">
              <tr>
                <td width="20%" className="dragble-div-body-td-text-style-new">Инструмент</td>
                <td>
                  <Select
                    width="80%"
                    name={"instrumentId"}
                    placeholder={"Найти инструмент..."}
                    value={selectedOptions["instrumentId"]}
                    onChange={handleSelectChange}
                    options={enumOptions["instrumentId"]}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  />
                </td>
              </tr>
            </table>
            <table align="center" width="100%">
              {/* Цена */}
              <tr>
                <td width="20%" className="dragble-div-body-td-text-style-new">Цена</td>
                <td>
                  <TextField
                    // variant="standart"
                    name="priceForInstrument"
                    placeholder="0"
                    value={fieldValue.priceForInstrument}
                    onChange={handleIntChange}
                    style={{ width: "150px" }}
                    InputProps={{ inputComponent: IntegerFormat }}
                  />
                  <AddIcon fontSize="small" className="add-icon-style" onClick={() => add("priceForInstrument", "priceForInstrumentStep")} />
                  <RemoveIcon fontSize="small" className="reduce-icon-style" onClick={() => reduce("priceForInstrument", "priceForInstrumentStep")} />
                </td>
              </tr>
              <tr>
                <td></td>
                <td className="dragble-div-body-td-simple-text-style-new">шаг: {fieldValue["priceForInstrumentStep"]}</td>
              </tr>
            </table>
            <table align="center" width="100%">
              <tr>
                <td align="center">
                  <button
                    className="cancelButton"
                    style={{ marginRight: 20 }}
                    onClick={() => props.setShowOpenFixingTrades(false)}
                  >
                    Отмена
                  </button>
                  <button
                    className="approveButton"
                    onClick={() => openTrades()}
                    style={{ marginRight: 20 }}
                  >
                    Открыть торги
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </Draggable>
  )
}