import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { Grid } from '@material-ui/core';
// import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
// import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';

// Icons
import PlayIcon from '@mui/icons-material/PlayCircleFilledWhite';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { IoIosSettings } from 'react-icons/io';
import { IoMdClose } from 'react-icons/io';
import { MdExpandMore } from 'react-icons/md';
import { MdExpandLess } from 'react-icons/md';

// Form components
// import TextField from '@material-ui/core/TextField';
// import Select from 'react-select'; // https://react-select.com/home
import Checkbox from '@material-ui/core/Checkbox';
// import Button from '@material-ui/core/Button';

//Style
import "../styles/generalStyles.css"

// Libraries
import swal from 'sweetalert'; // https://sweetalert.js.org/guides/
import Draggable from 'react-draggable';
import { v4 as uuidv4 } from 'uuid';
var moment = require('moment');
var fetch = require('node-fetch');
var generator = require('generate-password');

function IntegerFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={" "}
      isNumericString
    />
  );
}
IntegerFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
const useStyles = makeStyles((theme) => ({
  resizeForm: {
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    border: "1px solid #2B4D80",
    display: "flex",
    backgroundColor: "white",
    "flex-flow": "column",
    // width: "35%",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "30px",
    background: "white",
    position: 'absolute',
  },
  resizeFormCreate: {
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    border: "1px solid #B2C8AB",
    display: "flex",
    "flex-flow": "column",
    width: "95%",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "30px",
    background: "white",
    position: 'absolute',
  },
  menuIconStyle: {
    position: "absolute",
    top: 7,
    right: 10
  },
  tdBody: {
    cursor: "pointer",
    fontSize: 13,
    fontWeight: "light",
    height: "30px",
    minWidth: "1px",
    maxWidth: "150px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "elipsis '[..]'",
    overflow: "hidden",
    fontFamily: "Roboto",
    borderBottom: "1px solid #D9D9D9",
    padding: "3px 3px 3px 8px",
  }
}))
export default (props) => {
  const classes = useStyles()
  const [kseRESTApi] = useState(props.kseRESTApi)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [token] = useState(props.token)
  const [userProfile] = useState(props.userProfile)
  const [form, setForm] = useState(null)
  // const [docId, setDocId] = useState(props.userTask.docId)
  const [docList, setDocList] = useState([])
  const [enumData, setEnumData] = useState({})
  const [enumOptions, setEnumOptions] = useState({})
  const [selectedOptions, setSelectedOptions] = useState({})
  const [fieldValue, setFieldValue] = useState({
    marketId: null,
    marketSectorId: null,
    tradeStatusId: null
  })
  // const [time, setTime] = useState(null)
  const [updateState, setUpdateState] = useState(false)
  const [sortedColumn, setSortedColumn] = useState("null")
  const [sortedColumnOrder, setSortedColumnOrder] = useState(1)
  const [selectedSchedule, setSelectedSchedule] = useState({ id: null })
  const [anchorEl, setAnchorEl] = useState(null)
  const openMenu = Boolean(anchorEl)
  const [tableKey, setTableKey] = useState(getUUID())
  // const [showScheduleCreating, setShowScheduleCreating] = useState(false)
  // const [formType, setFormType] = useState("create")
  const [expanded, setExpanded] = useState(true)

  const [resizeDivScheduleId] = useState(getUUID())
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [size, setSize] = useState({ h: 320, w: 500 })
  const [prevHeight, setPrevHeight] = useState(40)

  // Set data from props to state of component
  useEffect(async () => {
    console.log("SCH MNGR", props)
    let enumDataToCollect = [
      { enumName: "marketId", enumDef: "ab14dc6d-0139-4dd4-ab65-172cacb636f8" },
      { enumName: "marketSectorId", enumDef: "df59dde0-68fd-4a8b-84e1-aa33531d2fe6" },
      { enumName: "tradeStatusId", enumDef: "e67a3f49-48e0-4d93-9b05-07ca0391d021" },
    ]
    let enums = await props.getEnumDataByList(enumDataToCollect)
    // console.log("ENS SCHEDULE", enums)
    setEnumData(enums)
    let eOpts = await props.createEnumOptions(enums)
    setEnumOptions(eOpts)

    let gForm = await props.fetchForm("91136086-8468-4294-abf4-aad629378098")
    console.log("FFF", gForm)
    // setForm(gForm)
    let filteredForm = props.filterForm(gForm, props.settings)
    setForm(filteredForm)
    // console.log("FFF", gForm)

    let docL = await props.fetchDocList("/api/TradingScheduleManagement/ShowList")
    console.log("DOCL SCHEDULE", docL)
    setDocList(docL.sort(dynamicSort("id", 1, "Int")))
    if (props.settings.position) {
      positionHandler(null, props.settings.position, false)
      // setPosition(props.settings.position)
    }
    if (props.settings.size !== undefined) {
      setSize(props.settings.size)
      if (props.settings.size.h < 60) {
        setExpanded(false)
        setPrevHeight(600)
      }
    }
    props.setComponentIndex(props.getComponentIndex() + 1)
    setUpdateState(getUUID())
  }, [])
  useEffect(async () => {
    if (props.updateTradingSchedule !== null) {
      let docL = await props.fetchDocList("/api/TradingScheduleManagement/ShowList")
      // console.log("DOCL SCHEDULE", docL)
      setDocList(docL.sort(dynamicSort("id", 1, "Int")))

      let f = form
      if (f === null) {
        f = await props.fetchForm("91136086-8468-4294-abf4-aad629378098")
      }
      let filteredForm = props.filterForm(f, props.getTradingScheduleSettingById(props.settings.id))
      setForm(filteredForm)
      // console.log("FFF", filteredForm)
      setTableKey(getUUID())
    }
  }, [props.updateTradingSchedule])
  useEffect(async () => {
    if (props.updateTradingScheduleZIndex !== null) {
      props.setComponentIndex(props.getComponentIndex() + 1)
      setComponentIndex(props.getComponentIndex() + 1)
    }
  }, [props.updateTradingScheduleZIndex])

  // random UUID generator
  function getUUID() {
    return uuidv4()
  }
  // random numbers generator
  function keyGen(length) {
    var password = generator.generate({
      length: length,
      numbers: true
    })
    return password
  }
  function swAllert(text, icon) {
    return (
      swal({
        text: text,
        icon: icon,
        buttons: { ok: "Ок" },
      })
    )
  }
  // INPUT HANDLERS
  // SELECT
  function handleSelectChange(option) {
    setSelectedOptions({ ...selectedOptions, [option.name]: option })
    setFieldValue({ ...fieldValue, [option.name]: option.value })
    console.log("OPT", option)
  }

  // Create grid form components
  function getGridFormItems(docListItem, contentItem) {
    let value = docListItem[contentItem.name]
    let name = contentItem.name
    let type = contentItem.type
    if (type === "Enum") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="left" className={classes.tdBody} style={{ color: getColor(docListItem) }}>-</td>)
      }
      else {
        return (
          <td
            align="left"
            className={classes.tdBody}
            style={{ color: getColor(docListItem) }}
          >
            {getEnumLabel(name, value)}
          </td>
        )
      }
    }
    else if (type === "Bool") {
      return (
        <td
          align="center"
          className={classes.tdBody}
        >
          <Checkbox
            size="small"
            style={{ maxWidth: 20, height: 15, color: "#337E86" }}
            checked={(value === false || value === null || value === undefined) ? false : true}
          />
        </td>
      )
    }
    else if (type === "DateTime") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="right" className={classes.tdBody} style={{ color: getColor(docListItem) }}>-</td>)
      }
      else {
        let d = moment(value).format("DD.MM.YYYY HH:MM")
        // return d === "Invalid date" ? value : d
        return (
          <td
            align="right"
            className={classes.tdBody}
            style={{ color: getColor(docListItem) }}
          >
            {d === "Invalid date" ? value : d}
          </td>
        )
      }
    }
    else if (type === "Currency") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="right" className={classes.tdBody} style={{ color: getColor(docListItem) }}>-</td>)
      }
      else {
        let newCur = props.currencyBeautifier(value)
        // console.log("CUR", newCur)
        // return newCur
        return (
          <td
            align="right"
            className={classes.tdBody}
            style={{ color: getColor(docListItem) }}
          >
            {newCur}
          </td>
        )
      }
    }
    else if (type === "Int") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="right" style={{ color: getColor(docListItem) }}>-</td>)
      }
      else {
        let newInt = props.intBeautifier(value)
        return (
          <td
            align="right"
            className={classes.tdBody}
            style={{ color: getColor(docListItem) }}
          >
            {newInt}
          </td>
        )
      }
    }
    else {
      if (value === null || value === "" || value === undefined) {
        return (<td align="left" className={classes.tdBody} style={{ color: getColor(docListItem) }}>-</td>)
      }
      else {
        return (<td align="left" className={classes.tdBody} style={{ color: getColor(docListItem) }}>{value}</td>)
      }
    }
  }
  function getEnumLabel(name, id) {
    for (let d = 0; d < enumData[name].length; d++) {
      if (enumData[name][d].id === id) {
        return enumData[name][d].label
      }
    }
  }
  function dynamicSort(property, sortOrder, type) {
    if (type === "DateTime" || type === "Bool") {
      sortOrder = sortOrder * -1
    }
    if (type === "DateTime") {
      return function (a, b) {
        if (a[property] !== null && b[property] !== null) {
          let dateA = new Date(a[property].substring(0, 19))
          let timeInSecA = dateA.getTime() / 1000
          // console.log("timeInSecA", timeInSecA)
          let dateB = new Date(b[property].substring(0, 19))
          let timeInSecB = dateB.getTime() / 1000
          // console.log("timeInSecB", timeInSecB)
          var result = (timeInSecA < timeInSecB) ? -1 : (timeInSecA > timeInSecB) ? 1 : 0
          return result * sortOrder
        }
        else {
          if (a[property] === null) {
            return -1 * sortOrder
          }
          return 1 * sortOrder
        }
      }
    }
    else if (type === "Int" || type === "Text" || type === "Float" || type === "Bool") {
      return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
        return result * sortOrder
      }
    }
    else if (type === "Enum") {
      return function (a, b) {
        if (a[property] === null) {
          return 1 * sortOrder
        }
        else {
          let labelA = getEnumLabel(property, a[property])
          // console.log("A", property, a[property], labelA)
          let labelB = getEnumLabel(property, b[property])
          // console.log("labelB", labelB, property, b)
          var result = (labelA < labelB) ? -1 : (labelA > labelB) ? 1 : 0
          return result * sortOrder
        }

      }
    }
  }
  function sortDataByColumn(name, type) {
    let sortOrder = 1
    if (sortedColumn === name) {
      sortOrder = sortedColumnOrder * -1
    }
    setSortedColumnOrder(sortOrder)
    setSortedColumn(name)
    let sortedDocList = docList.sort(dynamicSort(name, sortOrder, type))
    setDocList(sortedDocList)
  }

  async function executeScheduleItem() {
    // console.log("ITEM", id)
    setAnchorEl(null)
    await fetch(
      kseRESTApi + "/api/TradingScheduleManagement/Run?tradingScheduleId=" + selectedSchedule.id,
      {
        "headers": { "content-type": "application/json" },
      }
    )
      .then(response => response.json())
      .then(async function (res) {
        console.log("EXECUTE", res)
        if (res.isSuccess === true) {
          props.callSuccessToast("Задача запущена")
        }
        else {
          props.callErrorToast(res.errors[0])
        }
      })
  }
  async function deleteScheduleItem() {
    // console.log("DEL ITEM")
    swal({
      text: "ВНИМАНИЕ! Вы действительно удалить данную запись?",
      icon: "warning",
      buttons: { yes: "Да", cancel: "Отмена" },
    })
      .then(async (click) => {
        if (click === "yes") {
          setAnchorEl(null)
          await fetch(
            kseRESTApi + "/api/TradingScheduleManagement/Delete?id=" + selectedSchedule.id,
            {
              "headers": { "content-type": "application/json" },
              "method": "DELETE"
            }
          )
            .then(response => response.json())
            .then(async function (res) {
              console.log("DELETE", res)
              if (res.isSuccess === true) {
                let docL = await props.fetchDocList("/api/TradingScheduleManagement/ShowList")
                setDocList(docL.sort(dynamicSort("id", -1, "Int")))
                props.callSuccessToast("Задача удалена")
                setTableKey(getUUID())
              }
              else {
                props.callErrorToast(res.errors[0])
              }
            })
        }
      })
  }
  // async function saveScheduleTask(){
  //   let fullDate = new Date(time)
  //   var hours = fullDate.getHours()
  //   var minutes = fullDate.getMinutes()
  //   let body = {
  //     "marketId": fieldValue["marketId"],
  //     "marketSectorId": fieldValue["marketSectorId"],
  //     "tradeStatusId": fieldValue["tradeStatusId"],
  //     "hh": hours,
  //     "mm": minutes
  //   }
  //   console.log("BODY", body)
  //   await fetch(
  //     kseRESTApi + "/api/TradingScheduleManagement/" + (formType === "create" ? "CreateNew" : "Update?id=" + selectedSchedule.id),
  //     {
  //       "headers": { "content-type": "application/json" },
  //       "method": (formType === "create" ? "POST" : "PUT"),
  //       "body": JSON.stringify(body)
  //     }
  //   )
  //   .then(response => response.json())
  //   .then(async function(res){
  //     console.log("CREATE/UPDATE", res)
  //     if(res.isSuccess === true){
  //       let docL = await props.fetchDocList("/api/TradingScheduleManagement/ShowList")
  //       setDocList(docL.sort(dynamicSort("id", -1, "Int")))
  //       // setShowScheduleCreating(false)
  //       props.callSuccessToast(formType === "create" ? "Задача создана!" : "Задача обновлена!")
  //       setTableKey(getUUID())
  //     }
  //     else{
  //       props.callErrorToast(res.errors[0])
  //     }
  //   })
  // }
  async function handleContextMenuClick(event, docListItem) {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
    setSelectedSchedule(docListItem)
  }
  function createSchedule() {
    props.setShowScheduleCreateEditForm(true)
    props.setSelectedSchedule(null)
    setAnchorEl(null)
  }
  function editSchedule() {
    // console.log("EDIT", selectedSchedule)
    props.setSelectedSchedule(selectedSchedule)
    setAnchorEl(null)
    props.setShowScheduleCreateEditForm(true)

    // setFieldValue(selectedSchedule)
    // let newSelOptions = selectedOptions
    // for(let key in enumOptions){
    //   for(let i=0; i<enumOptions[key].length; i++){
    //     if(selectedSchedule[key] === enumOptions[key][i].value){
    //       newSelOptions[key] = enumOptions[key][i]
    //     }
    //   }
    // }
    // setSelectedOptions(newSelOptions)
    // let newTime = new Date("10 10 2022 "+ selectedSchedule.hh + ":" + selectedSchedule.mm + ":00")
    // setTime(newTime)
    // setFormType("edit")
  }
  // function getModalStyle() {
  //   const top = 10 + props.index * 3
  //   const left = 5 + props.index * 3
  //   return {
  //     height: expanded === true ? "320px" : "40px",
  //     top: `${top}%`,
  //     left: `${left}%`,
  //     transform: `translate(-${top}%, -${left}%)`,
  //     zIndex: componentIndex
  //   }
  // }
  function formClick() {
    // console.log("CLICK")
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 2)
    props.setComponentIndex(currIndex + 2)
  }

  function selectSchedule(item) {
    if (selectedSchedule.id === item.id) {
      setSelectedSchedule({ id: null })
    }
    else {
      setSelectedSchedule(item)
      props.setSelectedSchedule({})
    }
  }
  function getBackground(item, index) {
    if (selectedSchedule.id === item.id) {
      return "#CECECE"
    }
    else {
      if (index % 2 === 0) {
        return "#FBFBFB"
      }
      else {
        return "#f7f7f7"
      }
    }
  }
  function getColor(item) {
    if (selectedSchedule.id === item.id) {
      if (item.textColor === "black") {
        return "black"
      }
      else {
        return item.textColor
      }
    }
    else {
      if (item.textColor === "black") {
        return "#444"
      }
      else {
        return item.textColor
      }
    }
  }
  // Describe allowed positions for different screen sizes
  function positionHandler(e, data, updProfile) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 2 // Take 2% of width
    let maxDeviationX = (dimension.w / 100) * 90 // Take 90% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    if (data.y < maxDeviationY) {
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
    if (updProfile !== false) {
      let settings = {
        id: props.settings.id,
        type: props.settings.type,
        columns: props.settings.columns,
        position: { x: x, y: y },
        size: { h: size.h, w: size.w }
      }
      props.updateUserSettingsByType(settings)
    }
  }
  function sizeHandler() {
    var clientHeight = document.getElementById(resizeDivScheduleId).clientHeight;
    var clientWidth = document.getElementById(resizeDivScheduleId).clientWidth;
    let diffH = Math.abs(clientHeight - size.h)
    let diffW = Math.abs(clientWidth - size.w)
    // console.log("diffH", diffH, "diffW", diffW)
    if (diffH > 2 || diffW > 2) {
      // console.log("SIZE CHANGED", "H", clientHeight, "HS", size.h, "W", clientWidth, "WS", size.w)
      let settings = {
        id: props.settings.id,
        type: props.settings.type,
        columns: props.settings.columns,
        position: { x: position.x, y: position.y },
        size: { h: clientHeight, w: clientWidth }
      }
      props.updateUserSettingsByType(settings)
      setSize({ h: clientHeight, w: clientWidth })
      if (expanded === false) {
        setExpanded(true)
        setPrevHeight(size.h)
      }
    }
  }
  function expand() {
    // console.log("PREV H", prevHeight)
    setSize({ h: prevHeight, w: size.w })
    setExpanded(!expanded)
    setPrevHeight(size.h)
    let settings = {
      id: props.settings.id,
      type: props.settings.type,
      columns: props.settings.columns,
      position: { x: position.x, y: position.y },
      size: { h: prevHeight, w: size.w }
    }
    props.updateUserSettingsByType(settings)
  }
  if (updateState !== null) {
    try {
      return (form !== null &&
        <Draggable
          handle="p"
          onStart={() => formClick()}
          onStop={positionHandler}
          position={position}
          onMouseDown={() => formClick()}
        // onClick={()=> formClick()}
        >
          {/* <div style={getModalStyle()} className={classes.resizeForm}> */}
          <div
            id={resizeDivScheduleId}
            onClick={() => sizeHandler()}
            className={classes.resizeForm}
            style={{ height: size.h, width: size.w, top: "15%", left: "2%", transform: "translate(-15%, -2%)", zIndex: componentIndex }}
          >
            <div className="main-table-label-div-style-new" onDoubleClick={() => expand()}>
              <p className="main-table-label-p-style-new">
                <table>
                  <tr>
                    {userProfile.role.name === "Administrator" &&
                      <td width="4%" className="dragble-div-header-td-style-new">
                        <button onClick={() => createSchedule("create")}>+</button>
                      </td>
                    }
                    <td width={"95%"} style={{ paddingLeft: 15 }}>Расписание торгов</td>
                    {expanded == false
                      ?
                      <td>
                        <MdExpandLess size={22} className="form-expand-icons-style" onClick={() => expand()} />
                      </td>
                      :
                      <td>
                        <MdExpandMore size={22} className="form-expand-icons-style" onClick={() => expand()} />
                      </td>
                    }
                    <td>
                      <IoIosSettings size={22} className="form-settings-icons-style" onClick={() => props.showFormManagement(props.settings)} />
                    </td>
                    <td>
                      <IoMdClose size={22} className="form-close-icons-style" style={{ marginRight: "5px" }} onClick={() => props.closeTradingScheduleTable(props.settings)} />
                    </td>
                  </tr>
                </table>
              </p>
            </div>
            {expanded === true &&
              <div style={{ height: "100%", width: "100%", overflow: "scroll" }}>
                <table className="main-table-style-new" style={{ width: "100%" }} id={tableKey} key={tableKey}>
                  <thead>
                    <tr>
                      <th
                        class="main-table-header-th-style-new"
                        style={{
                          borderRight: "1px solid #D9D9D9",
                          fontWeight: "bold",
                          color: "#6D6D6D",
                          background: "#F6F4F3",
                          textAlign: "center",
                          fontSize: "13px"
                        }}
                      >
                        №
                      </th>
                      {form.sections.map(section =>
                        section.contents.map(contentItem => {
                          if (contentItem.show === true) {
                            return (
                              <th class="main-table-header-th-style-new">
                                {contentItem.label}
                              </th>
                            )
                          }
                        })
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(docList).length !== 0 &&
                      docList.map((docListItem, index) => (
                        <tr
                          // style={{background: selectedSchedule.id === docListItem.id ? "#C7EEFF" : "white"}}
                          style={{ background: getBackground(docListItem, index) }}
                          onMouseDown={() => setSelectedSchedule(docListItem)}
                          onContextMenu={(ev) => handleContextMenuClick(ev, docListItem)}
                        >
                          <td
                            align="center"
                            class="main-table-number-style"
                            style={{ borderRight: "1px solid rgb(217, 217, 217)" }}
                          >
                            {index + 1}
                          </td>
                          {form.sections.map(section => (
                            section.contents.map(contentItem => {
                              if (contentItem.show === true) {
                                // console.log("SHOW", contentItem.name, contentItem.show, form.defid)
                                return getGridFormItems(docListItem, contentItem)
                              }
                            })
                          ))}
                        </tr>
                      )
                      )}
                  </tbody>
                </table>
                <Menu
                  id="menu-instruments"
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={() => setAnchorEl(null)}
                  MenuListProps={{
                    'aria-labelledby': tableKey,
                  }}
                >
                  <MenuItem onClick={() => createSchedule("create")} style={{ color: "black" }}>
                    Создать
                    <ListItemIcon style={{ color: "black" }}>
                      <AddCircleOutlineIcon fontSize="small" className={classes.menuIconStyle} />
                    </ListItemIcon>
                  </MenuItem>
                  {/* <MenuItem onClick={() => editSchedule()} style={{ color: "black" }}>
                    Изменить
                    <ListItemIcon style={{ color: "black" }}>
                      <EditIcon fontSize="small" className={classes.menuIconStyle} />
                    </ListItemIcon>
                  </MenuItem> */}
                  <MenuItem onClick={() => executeScheduleItem()} style={{ color: "black" }}>
                    Выполнить
                    <ListItemIcon style={{ color: "black" }}>
                      <PlayIcon fontSize="small" className={classes.menuIconStyle} />
                    </ListItemIcon>
                  </MenuItem>
                  <MenuItem onClick={() => deleteScheduleItem()} style={{ color: "black" }}>
                    Удалить
                    <ListItemIcon style={{ color: "black" }}>
                      <DeleteIcon fontSize="small" className={classes.menuIconStyle} />
                    </ListItemIcon>
                  </MenuItem>
                </Menu>
              </div>
            }
          </div>
        </Draggable>
      )
    }
    catch (er) {
      console.log("ERROR", er)
      return <LinearProgress />
    }
  }
  else return <LinearProgress />
}

