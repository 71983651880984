import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { Grid } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
// Icons
// import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { BsArrowUp } from 'react-icons/bs';
import { BsArrowDown } from 'react-icons/bs';
import { IoIosSettings } from 'react-icons/io';
import { IoMdClose } from 'react-icons/io';
import { MdExpandMore } from 'react-icons/md';
import { MdExpandLess } from 'react-icons/md';

//Style
import "../styles/generalStyles.css"

// Libraries
import Draggable from 'react-draggable';
// import swal from 'sweetalert'; // https://sweetalert.js.org/guides/
import { v4 as uuidv4 } from 'uuid';
var fetch = require('node-fetch');
var generator = require('generate-password');
var moment = require('moment');
// var invert = require('invert-color'); // https://www.npmjs.com/package/invert-color

const useStyles = makeStyles(() => ({
  resizeForm: {
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    border: "1px solid #2B4D80",
    display: "flex",
    backgroundColor: "#F5F5F5",
    "flex-flow": "column",
    // height: "320px",
    // width: "73%",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "30px",
    background: "white",
    position: 'absolute',
  },
  tdBody: {
    cursor: "pointer",
    fontSize: 13,
    fontWeight: "light",
    height: "30px",
    minWidth: "1px",
    maxWidth: "150px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "elipsis '[..]'",
    overflow: "hidden",
    fontFamily: "Roboto",
    borderBottom: "1px solid #D9D9D9",
    padding: "3px 3px 3px 8px",
  }
}))

export default (props) => {
  const classes = useStyles()
  const [kseRESTApi] = useState(props.kseRESTApi)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [token] = useState(props.token)
  const [userProfile] = useState(props.userProfile)
  const [form, setForm] = useState(null)
  const [tableKeyHead, setTableKeyHead] = useState(getUUID())
  const [tableKeyBody, setTableKeyBody] = useState(getUUID())
  const [docList, setDocList] = useState([])
  const [enumData, setEnumData] = useState({})

  const [updateState, setUpdateState] = useState(false)
  const [sortedColumn, setSortedColumn] = useState("null")
  const [sortedColumnOrder, setSortedColumnOrder] = useState(1)
  const [selectedInstrument, setSelectedInstrument] = useState({ id: null })

  const [expanded, setExpanded] = useState(true)

  const [resizeDivFixingId] = useState(getUUID())
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [size, setSize] = useState({ h: 320, w: 700 })
  const [prevHeight, setPrevHeight] = useState(40)

  // Set data from props to state of component
  useEffect(async () => {
    console.log("FIXING PROPS", props)
    let gForm = await props.fetchForm("99fe7586-f875-427f-9fe8-47dc665da747")
    let filteredForm = props.filterForm(gForm, props.settings)
    // console.log("FILTERED", filteredForm)
    setForm(filteredForm)
    setEnumData(await props.getEnumDataByForm(gForm))
    updateDocList()
    if (props.settings.position) {
      positionHandler(null, props.settings.position, false)
      // setPosition(props.settings.position)
    }
    if (props.settings.size !== undefined) {
      setSize(props.settings.size)
      if (props.settings.size.h < 60) {
        setExpanded(false)
        setPrevHeight(600)
      }
    }
    props.setComponentIndex(props.getComponentIndex() + 1)
  }, [])
  useEffect(async () => {
    if (props.updateFinanceInstrumentsFixingHead !== null) {
      // console.log("HVAR", props.updateFinanceInstrumentsHead)
      let f = form
      if (f === null) {
        f = await props.fetchForm("99fe7586-f875-427f-9fe8-47dc665da747")
      }
      let filteredForm = props.filterForm(f, props.getFixingSettingById(props.settings.id))
      setForm(filteredForm)
      setTableKeyHead(getUUID())
    }
  }, [props.updateFinanceInstrumentsFixingHead])
  useEffect(async () => {
    if (props.updateFinanceInstrumentsFixingBody !== null) {
      // console.log("HVAR", props.updateFinanceInstrumentsBody)
      updateDocList()
      setTableKeyBody(getUUID())
    }
  }, [props.updateFinanceInstrumentsFixingBody])
  useEffect(async () => {
    if (props.updateFinanceInstrumentsFixingZIndex !== null) {
      props.setComponentIndex(props.getComponentIndex() + 1)
      setComponentIndex(props.getComponentIndex() + 1)
    }
  }, [props.updateFinanceInstrumentsFixingZIndex])
  async function updateDocList() {
    // console.log("Updating Doc LIST")
    let docL = await props.fetchDocList("/api/FixTrading/ViewFixingQuotes")
    if (sortedColumn !== "null") {
      setDocList(docL.sort(dynamicSort(sortedColumn, sortedColumnOrder, getContentTypeByName(sortedColumn))))
    }
    else {
      setDocList(docL.sort(dynamicSort("id", 1, "Int")))
    }
  }
  function getContentTypeByName(name) {
    for (let s = 0; s < form.sections.length; s++) {
      for (let c = 0; c < form.sections[s].contents.length; c++) {
        if (name === form.sections[s].contents[c].name) {
          return form.sections[s].contents[c].type
        }
      }
    }
  }

  // random UUID generator
  function getUUID() {
    return uuidv4()
  }
  // random numbers generator
  function keyGen(length) {
    var password = generator.generate({
      length: length,
      numbers: true
    })
    return password
  }
  // Create grid form components
  function getGridFormItems(docListItem, contentItem) {
    let value = docListItem[contentItem.name]
    let name = contentItem.name
    let type = contentItem.type
    if (type === "Enum") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="left" className={classes.tdBody} style={{ color: getColor(docListItem) }}>-</td>)
      }
      else {
        return (
          <td
            align="left"
            className={classes.tdBody}
            style={{ color: getColor(docListItem) }}
          >
            {getEnumLabel(name, value)}
          </td>
        )
      }
    }
    else if (type === "Bool") {
      return (
        <td
          align="center"
          className={classes.tdBody}
        >
          <Checkbox
            size="small"
            style={{ maxWidth: 20, height: 15, color: "#337E86" }}
            checked={(value === false || value === null || value === undefined) ? false : true}
          />
        </td>
      )
    }
    else if (type === "DateTime") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="right" className={classes.tdBody} style={{ color: getColor(docListItem) }}>-</td>)
      }
      else {
        let d = moment(value).format("DD.MM.YYYY HH:MM")
        // return d === "Invalid date" ? value : d
        return (
          <td
            align="right"
            className={classes.tdBody}
            style={{ color: getColor(docListItem) }}
          >
            {d === "Invalid date" ? value : d}
          </td>
        )
      }
    }
    else if (type === "Currency") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="right" className={classes.tdBody} style={{ color: getColor(docListItem) }}>-</td>)
      }
      else {
        let newCur = props.currencyBeautifier(value)
        // console.log("CUR", newCur)
        // return newCur
        return (
          <td
            align="right"
            className={classes.tdBody}
            style={{ color: getColor(docListItem) }}
          >
            {newCur}
          </td>
        )
      }
    }
    else if (type === "Int") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="right" style={{ color: getColor(docListItem) }}>-</td>)
      }
      else {
        let newInt = props.intBeautifier(value)
        return (
          <td
            align="right"
            className={classes.tdBody}
            style={{ color: getColor(docListItem) }}
          >
            {newInt}
          </td>
        )
      }
    }
    else {
      if (value === null || value === "" || value === undefined) {
        return (<td align="left" className={classes.tdBody} style={{ color: getColor(docListItem) }}>-</td>)
      }
      else {
        return (<td align="left" className={classes.tdBody} style={{ color: getColor(docListItem) }}>{value}</td>)
      }
    }
  }
  function getEnumLabel(name, id) {
    for (let d = 0; d < enumData[name].length; d++) {
      if (enumData[name][d].id === id) {
        return enumData[name][d].label
      }
    }
  }
  function dynamicSort(property, sortOrder, type) {
    if (type === "DateTime" || type === "Bool") {
      sortOrder = sortOrder * -1
    }
    if (type === "DateTime") {
      return function (a, b) {
        if (a[property] !== null && b[property] !== null) {
          let dateA = new Date(a[property].substring(0, 19))
          let timeInSecA = dateA.getTime() / 1000
          // console.log("timeInSecA", timeInSecA)
          let dateB = new Date(b[property].substring(0, 19))
          let timeInSecB = dateB.getTime() / 1000
          // console.log("timeInSecB", timeInSecB)
          var result = (timeInSecA < timeInSecB) ? -1 : (timeInSecA > timeInSecB) ? 1 : 0
          return result * sortOrder
        }
        else {
          if (a[property] === null) {
            return -1 * sortOrder
          }
          return 1 * sortOrder
        }
      }
    }
    else if (type === "Int" || type === "Text" || type === "Float" || type === "Bool") {
      return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
        return result * sortOrder
      }
    }
    else if (type === "Enum") {
      return function (a, b) {
        if (a[property] === null) {
          return 1 * sortOrder
        }
        else {
          let labelA = getEnumLabel(property, a[property])
          // console.log("A", property, a[property], labelA)
          let labelB = getEnumLabel(property, b[property])
          // console.log("labelB", labelB, property, b)
          var result = (labelA < labelB) ? -1 : (labelA > labelB) ? 1 : 0
          return result * sortOrder
        }

      }
    }
  }
  function sortDataByColumn(name, type) {
    let sortOrder = 1
    if (sortedColumn === name) {
      sortOrder = sortedColumnOrder * -1
    }
    setSortedColumnOrder(sortOrder)
    setSortedColumn(name)
    let sortedDocList = docList.sort(dynamicSort(name, sortOrder, type))

    setDocList(sortedDocList)
    // fetchBySize(fetchForm, fetchTo, sortedDocList)
  }
  async function stepUp(id) {
    await fetch(
      kseRESTApi + "/api/FixTrading/MakePositive?fixingInitialId=" + id,
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token }
      }
    )
      .then(response => response.json())
      .then(async function (res) {
        // console.log("RESP", res)
        if (res.isSuccess === true) {
          props.callSuccessToast("Шаг повышен")
          let docL = await props.fetchDocList("/api/FixTrading/ViewFixingQuotes")
          setDocList(docL.sort(dynamicSort("id", 1, "Int")))
        }
        else {
          props.callErrorToast(res.errors[0])
        }
      })
  }
  async function stepDown(id) {
    await fetch(
      kseRESTApi + "/api/FixTrading/MakeNegative?fixingInitialId=" + id,
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token }
      }
    )
      .then(response => response.json())
      .then(async function (res) {
        // console.log("RESP", res)
        if (res.isSuccess === true) {
          props.callSuccessToast("Шаг понижен")
          let docL = await props.fetchDocList("/api/FixTrading/ViewFixingQuotes")
          setDocList(docL.sort(dynamicSort("id", 1, "Int")))
        }
        else {
          props.callErrorToast(res.errors[0])
        }
      })
  }

  function formClick() {
    // console.log("CLICK")
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 2)
    props.setComponentIndex(currIndex + 2)
  }
  // function getModalStyle() {
  //   const top = 10 + props.index
  //   const left = 5 + props.index
  //   return {
  //     height: expanded === true ? "320px" : "40px",
  //     top: `${top}%`,
  //     left: `${left}%`,
  //     transform: `translate(-${top}%, -${left}%)`,
  //     zIndex: componentIndex
  //   };
  // }
  // Describe allowed positions for different screen sizes
  function positionHandler(e, data, updProfile) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 2 // Take 2% of width
    let maxDeviationX = (dimension.w / 100) * 90 // Take 90% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    if (data.y < maxDeviationY) {
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
    if (updProfile !== false) {
      let settings = {
        id: props.settings.id,
        type: props.settings.type,
        columns: props.settings.columns,
        position: { x: x, y: y },
        size: { h: size.h, w: size.w }
      }
      props.updateUserSettingsByType(settings)
    }
  }
  function sizeHandler() {
    var clientHeight = document.getElementById(resizeDivFixingId).clientHeight;
    var clientWidth = document.getElementById(resizeDivFixingId).clientWidth;
    let diffH = Math.abs(clientHeight - size.h)
    let diffW = Math.abs(clientWidth - size.w)
    // console.log("diffH", diffH, "diffW", diffW)
    if (diffH > 2 || diffW > 2) {
      // console.log("SIZE CHANGED", "H", clientHeight + 2, "HS", size.h, "W", clientWidth + 2, "WS", size.w)
      let settings = {
        id: props.settings.id,
        type: props.settings.type,
        columns: props.settings.columns,
        position: { x: position.x, y: position.y },
        size: { h: clientHeight, w: clientWidth }
      }
      props.updateUserSettingsByType(settings)
      setSize({ h: clientHeight, w: clientWidth })
      if (expanded === false) {
        setExpanded(true)
        setPrevHeight(size.h)
      }
    }
  }
  function getBackground(instr, index) {
    if (selectedInstrument.id === instr.id) {
      return "#CECECE"
    }
    else {
      if (index % 2 === 0) {
        return "#FBFBFB"
      }
      else {
        return "#f7f7f7"
      }
    }
  }
  function getColor(instr) {
    if (selectedInstrument.id === instr.id) {
      if (instr.textColor === "black") {
        return "black"
      }
      else {
        return instr.textColor
      }
    }
    else {
      if (instr.textColor === "black") {
        return "#444"
      }
      else {
        return instr.textColor
      }
    }
  }
  function selectInstrument(item) {
    if (selectedInstrument.id === item.id) {
      setSelectedInstrument({ id: null })
    }
    else {
      setSelectedInstrument(item)
    }
  }
  function expand() {
    // console.log("PREV H", prevHeight)
    setSize({ h: prevHeight, w: size.w })
    setExpanded(!expanded)
    setPrevHeight(size.h)
    let settings = {
      id: props.settings.id,
      type: props.settings.type,
      columns: props.settings.columns,
      position: { x: position.x, y: position.y },
      size: { h: prevHeight, w: size.w }
    }
    props.updateUserSettingsByType(settings)
  }
  if (updateState !== null) {
    try {
      return (form !== null &&
        <Draggable
          handle="p"
          onStart={() => formClick()}
          onStop={positionHandler}
          position={position}
          onMouseDown={() => formClick()}
        // onClick={()=> formClick()}
        >
          {/* <div style={getModalStyle()} className={classes.resizeForm}> */}
          <div
            id={resizeDivFixingId}
            onClick={() => sizeHandler()}
            className={classes.resizeForm}
            style={{ height: size.h, width: size.w, top: "15%", left: "2%", transform: "translate(-15%, -2%)", zIndex: componentIndex }}
          >
            <div className="main-table-label-div-style-new" onDoubleClick={() => expand()}>
              <p className="main-table-label-p-style-new">
                <table>
                  <tr>
                    <td width={"99%"} style={{ paddingLeft: 15 }}>
                      Инструменты фиксинг
                    </td>
                    {expanded == false
                      ?
                      <td>
                        <MdExpandLess size={22} className="form-expand-icons-style" onClick={() => expand()} />
                      </td>
                      :
                      <td>
                        <MdExpandMore size={22} className="form-expand-icons-style" onClick={() => expand()} />
                      </td>
                    }
                    <td>
                      <IoIosSettings size={22} className="form-settings-icons-style" onClick={() => props.showFormManagement(props.settings)} />
                    </td>
                    <td>
                      <IoMdClose size={22} className="form-close-icons-style" style={{ marginRight: "5px" }} onClick={() => props.closeFixingTable(props.settings)} />
                    </td>
                  </tr>
                </table>
              </p>
            </div>

            {expanded === true &&
              <div style={{ height: "100%", width: "100%", overflow: "scroll" }}>
                <table className="main-table-style-new" width="100%" id={tableKeyHead} key={tableKeyHead}>
                  <thead>
                    <tr>
                      <th
                        class="main-table-header-th-style-new"
                        style={{
                          borderRight: "1px solid #D9D9D9",
                          fontWeight: "bold",
                          color: "#6D6D6D",
                          background: "#F6F4F3",
                          textAlign: "center",
                          fontSize: "13px"
                        }}
                      >
                        №
                      </th>
                      {form.sections.map(section =>
                        section.contents.map(contentItem => {
                          if (contentItem.show === true) {
                            return (
                              <th class="main-table-header-th-style-new">
                                {contentItem.label}
                              </th>
                            )
                          }
                        })
                      )}
                      {userProfile.role.name === "Administrator" &&
                        <td class="main-table-header-th-style-new" colSpan={2}></td>
                      }
                    </tr>
                  </thead>
                  <tbody key={tableKeyBody}>
                    {docList.map((docListItem, index) => (
                      <tr
                        style={{ background: getBackground(docListItem, index), color: docListItem.textColor }}
                        onMouseDown={() => setSelectedInstrument(docListItem)}
                      >
                        <td
                          align="center"
                          class="main-table-number-style"
                          style={{ borderRight: "1px solid rgb(217, 217, 217)" }}
                        >
                          {index + 1}
                        </td>
                        {form.sections.map(section => (
                          section.contents.map(contentItem => {
                            if (contentItem.show === true) {
                              return getGridFormItems(docListItem, contentItem)
                            }
                          })
                        ))}
                        {userProfile.role.name === "Administrator" &&
                          <td className={classes.tdBody} align="center">
                            <Tooltip title="Повысить шаг">
                              <BsArrowUp size={16} onClick={() => stepUp(docListItem.id)} />
                            </Tooltip>
                          </td>
                        }
                        {userProfile.role.name === "Administrator" &&
                          <td className={classes.tdBody} align="center">
                            <Tooltip title="Понизить шаг">
                              <BsArrowDown size={16} onClick={() => stepDown(docListItem.id)} />
                            </Tooltip>
                          </td>
                        }
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            }
            <CssBaseline />
          </div>
        </Draggable>
      )
    }
    catch (er) {
      console.log("ERROR", er)
      return <LinearProgress />
    }
  }
}

