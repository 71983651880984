import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Authentication from "./components/Authentication.jsx";
import Home from "./components/Home.jsx";
import UpdatePassword from "./components/UpdatePassword.jsx";
import swal from 'sweetalert'; // https://sweetalert.js.org/guides/


export default function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [token, setToken] = useState(null);

  // process.env.NODE_ENV === "development" ?

  // PROD SERVER
  const [wsEndpoint] = useState("wss://ats.kse.kg:3120")
  const [kseRESTApi] = useState("https://ats.kse.kg/swg")

  // // TEST SERVER
  // const [wsEndpoint] = useState("wss://atstest.kse.kg:3120")
  // const [kseRESTApi] = useState("https://atstest.kse.kg/swg")
  
  // Local PC dev mode
  // const [wsEndpoint] = useState("ws://localhost:3120") //Local PC Socket
  // const [kseRESTApi] = useState("http://server-1:5002") //Local KFB main REST

  // // // Production Server
  // const [kseRESTApi] = useState("https://ats.kse.kg/swg") //KFB main REST
  // const [wsEndpoint] = useState("wss://ats.kse.kg:3120") //Socket

  // Intersoft Server
  // const [ip] = useState(window.location.hostname) //Server KFB main REST IP
  // const [wsEndpoint] = useState("ws://"+ ip +":3120") //WS Server
  // const [kseRESTApi] = useState("http://"+ ip +":5002") //Server KFB main REST

  async function authenticate(profile, token) {
    if (profile.settings !== null) {
      let settings = JSON.parse(profile.settings)
      profile.settings = settings
    }
    console.log("PROFILE", profile)
    setUserProfile(profile)
    setToken(token)
    setAuthenticated(true)
    if (profile.organization === null) {
      swal({
        text: "Внимание, у данного пользователя не указана организация, это может привести к некорректной работе системных функций!",
        icon: "warning",
        buttons: { ok: "Ок" }
      })
    }
    // else{
    //   await fetch(
    //     kseRESTApi + "/api/Organizations",
    //     {
    //       "headers": { "Authorization": "Bearer " + token },
    //       "method": "GET"
    //     }
    //   )
    //     .then(response => response.json())
    //     .then(async function (res) {
          
    //       let orgExist = false
    //       for(let i=0; i<res.data.length; i++){
    //         if(profile.organization.id === res.data[i].id){
    //           orgExist = true
    //           // console.log("ORG EXIST", res.data[i].name)
    //         }
    //       }
    //       if(orgExist === false){
    //         swal({
    //           text: "Внимание, у данного пользователя не указана организация, это может привести к некорректной работе системных функций!",
    //           icon: "warning",
    //           buttons: { ok: "Ок" }
    //         })
    //       }
    //     }
    //     )
    //     .catch(function (error) {
    //       console.log("Authentication error: ", error)
    //     })
    // }
  }
  async function fetchUserProfile(token) {
    // console.log("LOAD PROFILE")
    await fetch(
      kseRESTApi + "/api/users/GetUserInfo",
      {
        "headers": { "Authorization": "Bearer " + token },
        "method": "GET"
      }
    )
      .then(response => response.json())
      .then(async function (res) {
        console.log("PROFILE", res)
        authenticate(res, token)

      }
      )
      .catch(function (error) {
        console.log("Authentication error: ", error)
      })
  }
  function getProfile() {
    return userProfile
  }
  function setProfile(profile) {
    setUserProfile(profile)
  }
  return (
    authenticated === false ?
      <div>
        <Authentication
          // VARS
          kseRESTApi={kseRESTApi}
          // FUNCTIONS
          fetchUserProfile={fetchUserProfile}
        />
      </div>
      :
      <Grid container>
        <Grid item xs={12}>
          {userProfile.requiredAction === "UPDATEPASSWORD" ?
            <div>
              <UpdatePassword
                // VARS
                kseRESTApi={kseRESTApi}
                token={token}
                userProfile={userProfile}
                // FUNCTIONS
                fetchUserProfile={fetchUserProfile}
                setAuthenticated={setAuthenticated}
                setUserProfile={setUserProfile}
                setToken={setToken}
              />
            </div>
            :
            <Home
              // VARS
              wsEndpoint={wsEndpoint}
              kseRESTApi={kseRESTApi}
              userProfile={userProfile}
              token={token}
              // FUNCTIONS
              getProfile={getProfile}
              setProfile={setProfile}
              setAuthenticated={setAuthenticated}
              setUserProfile={setUserProfile}
            />
          }
        </Grid>
      </Grid>
  )
}
