import React, { useState, useEffect } from "react";
import MainForm from "./MainForm.jsx";
import Users from "./Users.jsx";
import Reports from "./Reports.jsx";
import ReportsV2 from "./ReportsV2.jsx";
import ExchangeORDAndTRD from "./ExchangeORDAndTRD.jsx";

export default function ComponentManager(props) {
  const [kseRESTApi] = useState(props.kseRESTApi)
  // const [task] = useState(props.task)
  const [taskVariables] = useState(props.taskVariables)
  const [userProfile] = useState(props.userProfile)

  useEffect(() => {
    // console.log("COMP MGR", props)
  }, [])

  function setComponentIndex(index) {
    props.setComponentIndex(index)
  }

  if (taskVariables.component === "mainForm") {
    return (
      <MainForm
        // VARIABLES
        // task={task}
        taskType={taskVariables.taskType}
        kseRESTApi={kseRESTApi}
        token={props.token}
        taskVariables={taskVariables}
        userProfile={userProfile}
        savedDocs={props.savedDocs}
        componentIndex={props.componentIndex}
        // FUNCTIONS
        setShowRepoProlongation={props.setShowRepoProlongation}
        setSelectedRepo={props.setSelectedRepo}
        getComponentIndex={props.getComponentIndex}
        setComponentIndex={props.setComponentIndex}
        getDocREST={props.getDocREST}
        setNewTask={props.setNewTask}
        closeTask={props.closeTask}
        getEnumDataByForm={props.getEnumDataByForm}
        createEnumOptions={props.createEnumOptions}
        fetchForm={props.fetchForm}
        fetchDocList={props.fetchDocList}
        callSuccessToast={props.callSuccessToast}
        callErrorToast={props.callErrorToast}
        fetchSavedFiles={props.fetchSavedFiles}
        saveFilesToDir={props.saveFilesToDir}
        setSavedDocs={props.setSavedDocs}
        updateRepoOrderList={props.updateRepoOrderList}
        getHW={props.getHW}
      />
    )
  }

  else if (taskVariables.component === "exchangeORDAndTRD") {
    return (
      <ExchangeORDAndTRD
        // VARIABLES
        taskType={taskVariables.taskType}
        kseRESTApi={kseRESTApi}
        token={props.token}
        taskVariables={taskVariables}
        userProfile={userProfile}
        componentIndex={props.componentIndex}
        // FUNCTIONS
        getComponentIndex={props.getComponentIndex}
        setComponentIndex={props.setComponentIndex}
        setNewTask={props.setNewTask}
        closeTask={props.closeTask}
        fetchForm={props.fetchForm}
        getEnumDataByForm={props.getEnumDataByForm}
        createEnumOptions={props.createEnumOptions}
        fetchDocList={props.fetchDocList}
        callSuccessToast={props.callSuccessToast}
        callErrorToast={props.callErrorToast}
        getHW={props.getHW}
        setUpdateAccountsCurrenciesPositions={props.setUpdateAccountsCurrenciesPositions}
        setUpdateAccountsInstrumentPositions={props.setUpdateAccountsInstrumentPositions}
      />
    )
  }
  else if (taskVariables.component === "users") {
    return (
      <Users
        // VARIABLES
        // task={task}
        kseRESTApi={kseRESTApi}
        token={props.token}
        taskVariables={taskVariables}
        userProfile={userProfile}
        componentIndex={props.componentIndex}
        // FUNCTIONS
        getComponentIndex={props.getComponentIndex}
        setComponentIndex={setComponentIndex}
        setNewTask={props.setNewTask}
        closeTask={props.closeTask}
        fetchForm={props.fetchForm}
        getEnumDataByForm={props.getEnumDataByForm}
        createEnumOptions={props.createEnumOptions}
        fetchDocList={props.fetchDocList}
        callSuccessToast={props.callSuccessToast}
        callErrorToast={props.callErrorToast}
        getHW={props.getHW}
      />
    )
  }
  else if (taskVariables.component === "reports") {
    return (
      <ReportsV2
        // VARIABLES
        // task={task}
        kseRESTApi={kseRESTApi}
        token={props.token}
        taskVariables={taskVariables}
        userProfile={userProfile}
        componentIndex={props.componentIndex}
        // FUNCTIONS
        getComponentIndex={props.getComponentIndex}
        setComponentIndex={props.setComponentIndex}
        setNewTask={props.setNewTask}
        closeTask={props.closeTask}
        getEnumDataByForm={props.getEnumDataByForm}
        getEnumDataByList={props.getEnumDataByList}
        createEnumOptions={props.createEnumOptions}
        fetchForm={props.fetchForm}
        fetchDocList={props.fetchDocList}
        callSuccessToast={props.callSuccessToast}
        callErrorToast={props.callErrorToast}
        getHW={props.getHW}
      />
    )
  }
  else { return <div></div> }
}