import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@mui/material/Button';
// import Button from '@material-ui/core/Button';
// Icons
// import { IoIosSettings } from 'react-icons/io';
import { IoMdClose } from 'react-icons/io';
import { MdExpandMore } from 'react-icons/md';
import { MdExpandLess } from 'react-icons/md';
import FileOpenIcon from '@mui/icons-material/FileOpen';
// Form components
import Checkbox from '@material-ui/core/Checkbox';
// Tab components
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
//Style
import "../styles/generalStyles.css"
// Libraries
import swal from 'sweetalert'; // https://sweetalert.js.org/guides/
import Draggable from 'react-draggable'; // https://www.npmjs.com/package/react-draggable
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";
var fetch = require('node-fetch');



const useStyles = makeStyles(() => ({
  resizeForm: {
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    border: "1px solid #2B4D80",
    display: "flex",
    backgroundColor: "white",
    "flex-flow": "column",
    width: "80%",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "30px",
    background: "white",
    position: 'absolute',
  },
  menuIconStyle: {
    position: "absolute",
    top: 7,
    right: 10
  },
  lotsLabel: {
    fontWeight: "bold",
    color: "white",
    background: "#418991",
    whiteSpace: "nowrap"
  },
  lot: {
    color: "#418991",
    background: "#D5E6E8",
    fontWeight: "bold",
    whiteSpace: "nowrap"
  },
  total: {
    fontWeight: "bold",
    fontSize: 14
  },
  tdBody: {
    cursor: "pointer",
    fontSize: 12,
    height: "30px",
    minWidth: "1px",
    maxWidth: "150px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "elipsis '[..]'",
    overflow: "hidden",
  }
}))
// ToolTip стили (подсказки)
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white
  },
  tooltip: {
    backgroundColor: "white",
    border: "1px solid #7A7A7A",
    fontFamily: 'Helvetica',
    color: "#7A7A7A",
    fontSize: 13,
    textAlign: "center",
    boxShadow: "4px 4px 4px 3px #C3C3C3"
  }
}))

export default (props) => {
  const classes = useStyles()
  const [kseRESTApi] = useState(props.kseRESTApi)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [token] = useState(props.token)
  const [userProfile] = useState(props.userProfile)
  const [lotsForm, setLotsForm] = useState(null)
  const [requestsForm, setRequestsForm] = useState(null)
  const [tableKey, setTableKey] = useState(getUUID())
  const [auction, setAuction] = useState(props.auction)
  const [docListLots, setDocListLots] = useState([])
  const [docListRequests, setDocListRequests] = useState([])
  const [stats, setStats] = useState(null)
  const [enumData, setEnumData] = useState({})
  const [selectedLot, setSelectedLot] = useState({ id: null })
  const [selectedRequest, setSelectedRequest] = useState({ id: null })

  const [updateState, setUpdateState] = useState(null)
  const [expanded, setExpanded] = useState(true)
  const [anchorEl, setAnchorEl] = useState(null)
  const openMenu = Boolean(anchorEl)
  const [position, setPosition] = useState({ x: 0, y: 0 })

  // Set data from props to state of component
  useEffect(async () => {
    console.log("Auction Details PROPS", props)
    let lotsForm = await props.fetchForm("d7714268-24d4-4c5d-9b58-e4f54b1b675d")
    setLotsForm(lotsForm)
    let requestsForm = await props.fetchForm("f1eb866c-3f44-4ec8-a338-3ca7a09e4116")
    setRequestsForm(requestsForm)

    let lotsEnums = await props.getEnumDataByForm(lotsForm)
    let reqEnums = await props.getEnumDataByForm(requestsForm)
    let enumDataToCollect = [
      { enumName: "auctionStatuses", enumDef: "ecb0c7a9-83f1-4535-9b45-a381dbc93966" },
      // { enumName: "financeInstrumentId", enumDef: "0706ddd1-f08c-4333-b66f-2e77f74d965e" }
    ]
    let enums = await props.getEnumDataByList(enumDataToCollect)
    for (let key in lotsEnums) {
      reqEnums[key] = lotsEnums[key]
    }
    for (let key in enums) {
      reqEnums[key] = enums[key]
    }
    console.log("EN AD", reqEnums)
    setEnumData(reqEnums)
    updateDocList()
    props.setComponentIndex(props.getComponentIndex() + 1)
    setUpdateState(getUUID())
  }, [])
  useEffect(() => {
    if (props.updateAuctionDetails !== null) {
      // console.log("HVAR", props.updateAuctionDetails)
      updateDocList()
    }
  }, [props.updateAuctionDetails])
  async function updateDocList() {
    let docL = await props.fetchDocList("/api/Auction/DetailsByRequests/" + auction.id)
    console.log("DOCL Auction Details", docL)
    try {
      setAuction(docL)
      setDocListLots(docL.lots)
      setSelectedLot(docL.lots[0])
      setDocListRequests(docL.requests.sort(dynamicSort("ordPrice", 1, "Float")))
      setStats(docL.stats)
      // setDocListRequests(docL.requests)
      setTableKey(getUUID())
    }
    catch (er) {
      props.callErrorToast("Ошибка сбора списочных данных!", er)
      props.closeAuctionDetails(props.auction.uuid)
    }
  }
  function dynamicSort(property, sortOrder, type) {
    // console.log("SORT", property)
    if (type === "DateTime" || type === "Bool") {
      sortOrder = sortOrder * -1
    }
    if (type === "DateTime") {
      return function (a, b) {
        if (a[property] !== null && b[property] !== null) {
          let dateA = new Date(a[property].substring(0, 19))
          let timeInSecA = dateA.getTime() / 1000
          // console.log("timeInSecA", timeInSecA)
          let dateB = new Date(b[property].substring(0, 19))
          let timeInSecB = dateB.getTime() / 1000
          // console.log("timeInSecB", timeInSecB)
          var result = (timeInSecA < timeInSecB) ? -1 : (timeInSecA > timeInSecB) ? 1 : 0
          return result * sortOrder
        }
        else {
          if (a[property] === null) {
            return -1 * sortOrder
          }
          return 1 * sortOrder
        }
      }
    }
    else if (type === "Int" || type === "Text" || type === "Float" || type === "Bool") {
      return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
        return result * sortOrder
      }
    }
    else if (type === "Enum") {
      return function (a, b) {
        if (a[property] === null) {
          return 1 * sortOrder
        }
        else {
          let labelA = getEnumLabel(property, a[property])
          let labelB = getEnumLabel(property, b[property])
          var result = (labelA < labelB) ? -1 : (labelA > labelB) ? 1 : 0
          return result * sortOrder
        }

      }
    }
  }
  // random UUID generator
  function getUUID() {
    return uuidv4()
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }
  async function handleContextMenuClick(event, docListLotsItem) {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
    setSelectedLot(docListLotsItem)
  }

  // Create grid form components
  function getGridFormItems(docListItem, contentItem) {
    let value = docListItem[contentItem.name]
    let name = contentItem.name
    let type = contentItem.type
    if (type === "Enum") {
      if (value === null || value === "" || value === undefined) {
        return "-"
      }
      else {
        return getEnumLabel(name, value)
      }
    }
    else if (type === "Bool") {
      return (
        <Checkbox
          size="small"
          style={{ maxWidth: 20, height: 15, color: value === false ? "grey" : "green" }}
          checked={(value === false || value === null || value === undefined) ? false : true}
        />
      )
    }
    else if (type === "DateTime") {
      if (value === null || value === "" || value === undefined) {
        return "-"
      }
      else {
        let d = moment(value).format("DD.MM.YYYY HH:MM")
        return d === "Invalid date" ? value : d
      }
    }
    else if (type === "Currency") {
      if (value === null || value === "" || value === undefined) {
        return "-"
      }
      else {

        let newCur = props.currencyBeautifier(value)
        return newCur
      }
    }
    else if (type === "Int") {
      if (value === null || value === "" || value === undefined) {
        return "-"
      }
      else {
        let newInt = props.intBeautifier(value)
        return newInt
      }
    }
    else {
      if (value === null || value === "" || value === undefined) {
        return "-"
      }
      else {
        return value
      }
    }
  }
  function getEnumLabel(name, id) {
    for (let d = 0; d < enumData[name].length; d++) {
      if (enumData[name][d].id === id) {
        return enumData[name][d].label
      }
    }
  }
  function dynamicSort(property, sortOrder, type) {
    if (type === "DateTime" || type === "Bool") {
      sortOrder = sortOrder * -1
    }
    if (type === "DateTime") {
      return function (a, b) {
        if (a[property] !== null && b[property] !== null) {
          let dateA = new Date(a[property].substring(0, 19))
          let timeInSecA = dateA.getTime() / 1000
          let dateB = new Date(b[property].substring(0, 19))
          let timeInSecB = dateB.getTime() / 1000
          var result = (timeInSecA < timeInSecB) ? -1 : (timeInSecA > timeInSecB) ? 1 : 0
          return result * sortOrder
        }
        else {
          if (a[property] === null) {
            return -1 * sortOrder
          }
          return 1 * sortOrder
        }
      }
    }
    else if (type === "Int" || type === "Text" || type === "Float" || type === "Bool") {
      return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
        return result * sortOrder
      }
    }
    else if (type === "Enum") {
      return function (a, b) {
        if (a[property] === null) {
          return 1 * sortOrder
        }
        else {
          let labelA = getEnumLabel(property, a[property])
          let labelB = getEnumLabel(property, b[property])
          var result = (labelA < labelB) ? -1 : (labelA > labelB) ? 1 : 0
          return result * sortOrder
        }

      }
    }
  }
  function formClick() {
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 1)
    props.setComponentIndex(currIndex + 1)
  }
  function getModalStyle() {
    const top = 12;
    const left = 7;
    return {
      height: expanded === true ? "650px" : "40px",
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      zIndex: componentIndex
    };
  }

  function getBackgroundLots(item) {
    if (selectedLot.id === item.id) {
      return "#CECECE"
    }
    else {
      return "white"
    }
  }
  function getBackgroundRequests(item) {
    if (selectedRequest.id === item.id) {
      return "#C7EEFF"
    }
    else {
      return item.bgColor
      // return "white"
    }
  }
  function getColor(item) {
    return item.textColor
  }
  function openAuctionBidForm() {
    if (auction.auctionStatusId === 1) {
      handleCloseMenu()
      props.setShowAuctionBidForm(true)
      props.setSelectedLot(selectedLot)
    }
    else {
      props.callErrorToast("На данном этапе заявки не принимаются!", 1200)
    }
  }
  function countField(f, competitive, lotId) {
    let fields = []
    for (let s = 0; s < f.sections.length; s++) {
      for (let c = 0; c < f.sections[s].contents.length; c++) {
        if (f.sections[s].contents[c].show === true) {
          let name = f.sections[s].contents[c].name
          if (name === "ordAmount" || name === "ordSum" || name === "dealAmount" || name === "dealSum") {
            let result = ""
            if (competitive === true) {
              result = lotId !== null ? countCompetitiveFieldsByLot(lotId, name) : countCompetitiveFields(name)
            }
            else if (competitive === false) {
              result = lotId !== null ? countNonCompetitiveFieldsByLot(lotId, name) : countNonCompetitiveFields(name)
            }
            else if (competitive === "both") {
              result = countCompetitiveFields(name) + countNonCompetitiveFields(name)
            }
            else if (competitive === "all") {
              result = countAllRequests(name)
            }
            fields.push(<td class={classes.total}>{props.currencyBeautifier(result)}</td>)
          }
          else { fields.push(<td />) }
        }

      }
    }
    return fields
  }
  function countCompetitiveFieldsByLot(lotId, field) {
    let counter = 0
    for (let i = 0; i < docListRequests.length; i++) {
      if (docListRequests[i].auctionLotId === lotId && docListRequests[i].nonCompetitive === false) {
        counter = counter + docListRequests[i][field]
      }
    }
    return counter
  }
  function countCompetitiveFields(field) {
    let counter = 0
    for (let i = 0; i < docListRequests.length; i++) {
      if (docListRequests[i].nonCompetitive === false) {
        counter = counter + docListRequests[i][field]
      }
    }
    return counter
  }
  function countNonCompetitiveFieldsByLot(lotId, field) {
    let counter = 0
    for (let i = 0; i < docListRequests.length; i++) {
      if (docListRequests[i].auctionLotId === lotId && docListRequests[i].nonCompetitive === true) {
        counter = counter + docListRequests[i][field]
      }
    }
    return counter
  }
  function countNonCompetitiveFields(field) {
    let counter = 0
    for (let i = 0; i < docListRequests.length; i++) {
      if (docListRequests[i].nonCompetitive === true) {
        counter = counter + docListRequests[i][field]
      }
    }
    return counter
  }
  function countAllRequests(field) {
    let counter = 0
    for (let i = 0; i < docListRequests.length; i++) {
      counter = counter + docListRequests[i][field]
    }
    return counter
  }
  async function deleteAuction() {
    swal({
      text: "Вы точно хотите очистить данный аукцион?",
      icon: "warning",
      buttons: { yes: "Да", cancel: "Отмена" }
    })
      .then(async (click) => {
        if (click === "yes") {
          await fetch(
            kseRESTApi + "/api/Auction/DeleteAuction/" + props.auction.id,
            {
              "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
              "method": "DELETE"
            }
          )
            .then(response => response.json())
            .then(function (res) {
              // console.log("RES APPROVE", res)
              if (res.isSuccess === true) {
                props.callSuccessToast("Аукцион очищен", 1100)
                updateDocList()
                props.setUpdateAuction(getUUID())
              }
              else {
                props.callErrorToast(res.errors[0])
              }
            })
        }
      })

  }
  async function preCalculate() {
    await fetch(
      kseRESTApi + "/api/Auction/PreCalculate/" + props.auction.id,
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token }
      }
    )
      .then(response => response.json())
      .then(function (res) {
        // console.log("RES PRECALC", res)
        if (res.isSuccess === true) {
          props.callSuccessToast("Предварительные расчеты проведены", 1100)
          updateDocList()
          props.setUpdateAuction(getUUID())
        }
        else {
          props.callErrorToast(res.errors[0])
        }
      })
  }
  async function approveByConfirmer() {
    await fetch(
      kseRESTApi + "/api/Auction/ApproveByConfirmer/" + props.auction.id,
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token }
      }
    )
      .then(response => response.json())
      .then(function (res) {
        // console.log("RES APPROVE", res)
        if (res.isSuccess === true) {
          props.callSuccessToast("Подтверждено конфирмером", 1100)
          updateDocList()
          props.setUpdateAuction(getUUID())
        }
        else {
          props.callErrorToast(res.errors[0])
        }
      })
  }
  async function closePreCalculate() {
    await fetch(
      kseRESTApi + "/api/Auction/ClosePreCalculate/" + props.auction.id,
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token }
      }
    )
      .then(response => response.json())
      .then(function (res) {
        // console.log("RES APPROVE", res)
        if (res.isSuccess === true) {
          props.callSuccessToast("Предварительные расчеты закрыты", 1100)
          updateDocList()
          props.setUpdateAuction(getUUID())
        }
        else {
          props.callErrorToast(res.errors[0])
        }
      })
  }
  async function deleteLotRequest(request) {
    // if (auction.auctionStatusId === 1) {
    // console.log("DEL REQ", request)
    swal({
      text: "Вы точно хотите снять заявку?",
      icon: "warning",
      buttons: { yes: "Да", cancel: "Отмена" }
    })
      .then(async (click) => {
        if (click === "yes") {
          await fetch(
            kseRESTApi + "/api/Auction/DeleteLotRequest/" + request.id,
            {
              "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
              "method": "DELETE"
            }
          )
            .then(response => response.json())
            .then(function (res) {
              // console.log("RES DEL", res)
              if (res.isSuccess === true) {
                props.callSuccessToast("Заявка снята!", 1100)
                updateDocList()
                props.setUpdateAuction(getUUID())
              }
              else {
                props.callErrorToast(res.errors[0])
              }
            })
        }
      })
    // }
    // else {
    //   props.callErrorToast("На данном этапе нельзя снять заявку!", 1200)
    // }
  }
  async function closeAuctionForced() {
    // console.log("CLOSE REQ", props.auction)
    swal({
      text: "Вы точно хотите закрыть прием заявок?",
      icon: "warning",
      buttons: { yes: "Да", cancel: "Отмена" }
    })
      .then(async (click) => {
        if (click === "yes") {
          await fetch(
            kseRESTApi + "/api/Auction/CloseAuctionForced/" + props.auction.id,
            {
              "headers": { "content-type": "application/json", "Authorization": "Bearer " + token }
            }
          )
            .then(response => response.json())
            .then(function (res) {
              // console.log("RES CLOSE", res)
              if (res.isSuccess === true) {
                props.callSuccessToast("Прием заявок закрыт!", 1100)
                updateDocList()
                props.setUpdateAuction(getUUID())
              }
              else {
                props.callErrorToast(res.errors[0])
              }
            })
        }
      })
  }
  async function downloadReport(format, repNum, repLabel) {
    await fetch(
      kseRESTApi + "/api/Auction/" + auction.id + "/" + selectedLot.id + "/summary-report-" + repNum + "/" + format,
      {
        "headers": { "Authorization": "Bearer " + token }
      }
    )
      .then(response => response.blob())
      .then(blob => {
        // console.log("RESP", blob)
        var url = window.URL.createObjectURL(blob)
        var a = document.createElement('a')
        a.href = url
        a.download = `${repLabel}.${format}`
        document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click()
        a.remove()  //afterwards we remove the element again         
      })
      .catch(error => console.error(error));
  }
  // Custom ToolTip
  function BootstrapTooltip(props) {
    const clsToolTip = useStylesBootstrap();
    return <Tooltip arrow classes={clsToolTip} {...props} />;
  }
  function positionHandler(e, data) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 10 // Take 10% of width
    let maxDeviationX = (dimension.w / 100) * 80 // Take 80% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    // console.log("minX", minDeviationX, "maxX", maxDeviationX, "maxY", maxDeviationY)
    if (data.y < maxDeviationY) {
      // console.log("SET MIN Y", data.x)
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      // console.log("SET MIN X", data.x, minDeviationX)
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      // console.log("SET MAX X", data.x)
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
  }

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (updateState !== null && lotsForm !== null) {
    try {
      return (
        <Draggable
          key={tableKey}
          handle="p"
          onStart={() => formClick()}
          onStop={positionHandler}
          position={position}
        >
          <div
            className={classes.resizeForm}
            style={{ top: "15%", left: "10%", width: '1150px', transform: "translate(-15%, -10%)", zIndex: componentIndex }}
            onClick={() => formClick()}
          >
            <div className="main-table-label-div-style-new" onDoubleClick={() => setExpanded(!expanded)}>
              <p className="main-table-label-p-style-new">
                <table className="main-table-label-div-style-new">
                  <tr>
                    <td width={"99%"}>
                      Детали аукциона ГЦБ
                    </td>
                    {expanded == false
                      ?
                      <td>
                        <MdExpandLess size={22} className="form-expand-icons-style" onClick={() => setExpanded(!expanded)} />
                      </td>
                      :
                      <td>
                        <MdExpandMore size={22} className="form-expand-icons-style" onClick={() => setExpanded(!expanded)} />
                      </td>
                    }
                    <td>
                      <IoMdClose size={22} className="form-close-icons-style" style={{ marginRight: "5px" }} onClick={() => props.closeAuctionDetails(props.auction.uuid)} />
                    </td>
                  </tr>
                </table>
              </p>
            </div>
            {expanded === true &&
              <div className="dragble-div-body-style-new">
                {selectedLot.auctionStatusId === 2 &&
                  <div style={{ fontWeight: "bold", paddingLeft: 20, color: "green", border: "1px solid green" }}>Идет прием приказов</div>
                }
                <div style={{ fontSize: '18px', fontWeight: "bold", paddingLeft: 10, marginTop: '5px', marginBottom: '8px' }}>Приказы на покупку</div>
                <table className="main-table-style auction-list" style={{ paddingBottom: '10px', borderRadius: '0px', boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px' }} width="100%" id={tableKey}>
                  <thead>
                    <tr>
                      {requestsForm.sections.map(section =>
                        section.contents.map(contentItem => {
                          if (contentItem.show === true) {
                            return (
                              <th class="main-table-header-th-style-new">
                                {contentItem.label}
                              </th>
                            )
                          }
                        })
                      )}
                      <th class="main-table-header-th-style-new"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className={classes.lotsLabel}>
                      <td>Конкурентные</td>
                      {requestsForm.sections.map(section => (
                        section.contents.map(contentItem => {
                          if (contentItem.show === true) {
                            return <td />
                          }
                        })
                      ))}
                    </tr>
                    {docListRequests.map(docListRequestsItem => (
                      docListRequestsItem.nonCompetitive === false && docListRequestsItem.auctionLotId === selectedLot.id &&
                      <tr
                        onMouseDown={() => setSelectedRequest(docListRequestsItem)}
                        style={{
                          background: getBackgroundRequests(docListRequestsItem),
                          fontWeight: 'bold',
                          color: docListRequestsItem.textColor
                        }}
                      >
                        {requestsForm.sections.map(section => (
                          section.contents.map(contentItem => {
                            if (contentItem.show === true) {
                              return (
                                <td align="left" className="main-table-tbody-td-style">
                                  {getGridFormItems(docListRequestsItem, contentItem)}
                                </td>
                              )
                            }
                          })
                        ))}
                        <td
                          align="left"
                          className="main-table-tbody-td-style"
                          style={{ width: "90px" }}
                        >
                          {auction.auctionStatusId === 1 &&
                            <IoMdClose size={22} className="form-close-icons-styleв" style={{ marginRight: "5px" }} onClick={() => deleteLotRequest(docListRequestsItem)} />
                          }
                        </td>
                      </tr>
                    ))}
                    <tr style={{ border: '1px solid #000' }}>
                      <td class={classes.total}>Итого по лоту:</td>
                      {countField(requestsForm, true, selectedLot.id)}
                    </tr>
                    <tr>
                      <td class={classes.total}>Итого по всем лотам:</td>
                      {countField(requestsForm, true, null)}
                    </tr>

                    <br />
                    <tr className={classes.lotsLabel}>
                      <td>Не конкурентные</td>
                      {requestsForm.sections.map(section => (
                        section.contents.map(contentItem => {
                          if (contentItem.show === true) {
                            return <td />
                          }
                        })
                      ))}
                    </tr>
                    {docListRequests.map(docListRequestsItem => (
                      docListRequestsItem.nonCompetitive === true && docListRequestsItem.auctionLotId === selectedLot.id &&
                      <tr
                        onMouseDown={() => setSelectedRequest(docListRequestsItem)}
                        style={{
                          background: getBackgroundRequests(docListRequestsItem),
                          fontWeight: 'bold',
                          color: docListRequestsItem.textColor
                        }}
                      >

                        {requestsForm.sections.map(section => (
                          section.contents.map(contentItem => {
                            if (contentItem.show === true) {
                              return (
                                <td align="left" className="main-table-tbody-td-style">
                                  {getGridFormItems(docListRequestsItem, contentItem)}
                                </td>
                              )
                            }
                          })
                        ))}
                        <td
                          align="left"
                          className="main-table-tbody-td-style"
                          style={{ width: "90px" }}
                        >
                          {auction.auctionStatusId === 1 &&
                            <IoMdClose size={22} className="form-close-icons-styleв" style={{ marginRight: "5px" }} onClick={() => deleteLotRequest(docListRequestsItem)} />
                          }
                          {/* {auction.auctionStatusId === 1 && <button className="approveSmallButton" onClick={() => deleteLotRequest(docListRequestsItem)}>снять</button>} */}
                        </td>
                      </tr>
                    ))}
                    <tr style={{ border: '1px solid #000' }}>
                      <td class={classes.total}>Итого по лоту:</td>
                      {countField(requestsForm, false, selectedLot.id)}
                    </tr>
                    <tr>
                      <td class={classes.total}>Итого по всем лотам:</td>
                      {countField(requestsForm, false, null)}
                    </tr>
                    <tr style={{ height: '20px' }}>
                      <td class={classes.total}>Всего по приказам:</td>
                      {countField(requestsForm, "all", null)}
                    </tr>
                  </tbody>
                </table>

                {auction.auctionStatusId === 1 && userProfile.role.name !== "Administrator" && userProfile.auctionParticipant === true &&
                  <Button style={{ background: "#337E86", margin: '6px 0' }} variant="contained" onClick={() => openAuctionBidForm()}>Подать заявку</Button>
                }
                {stats !== null &&
                  <table width="100%">
                    <tr>
                      <Box sx={{ width: '100%', typography: 'body1', marginTop: '20px' }}>
                        <TabContext value={value}>
                          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                              <Tab label="Общая информация" value="1" />
                              <Tab label="Цена и доходность" value="2" />
                              <Tab label="Сводные ведомости заявок" value="3" />
                            </TabList>
                          </Box>
                          <TabPanel value="1" >
                            <table style={{ width: '100%' }}>
                              <tr>
                                <td style={{ verticalAlign: 'top', width: '40%' }} >

                                  <tr>
                                    <td style={{ width: '100%' }}>
                                      <table>
                                        {/* {auction.auctionStatusId === 1 && <th class="main-table-header-th-style-new"></th>} */}
                                        {lotsForm.sections.map(section =>
                                          section.contents.map(contentItem => {
                                            if (contentItem.show === true) {
                                              return (
                                                <tr>
                                                  <td
                                                    // class="main-table-header-th-style-new"
                                                    style={{ margin: '4px 0', fontSize: '14px', fontWeight: 'bold' }}
                                                  >
                                                    {contentItem.label} :
                                                  </td>
                                                </tr>
                                              )
                                            }
                                          })
                                        )}
                                      </table>
                                    </td>

                                    <td style={{ width: '100%' }}>
                                      {docListLots.map((docListLotsItem, index) => (
                                        <table
                                          style={{
                                            background: '#fff'
                                          }}
                                        >

                                          {lotsForm.sections.map(section => (
                                            section.contents.map(contentItem => {
                                              if (contentItem.show === true) {
                                                return (
                                                  <tr>
                                                    <td
                                                      style={{ color: '#337E86', margin: '4px 0', fontSize: '14px', paddingLeft: '8px', textAlign: 'right', whiteSpace: 'nowrap' }}>
                                                      {getGridFormItems(docListLotsItem, contentItem)}
                                                    </td>
                                                  </tr>
                                                )
                                              }
                                            })
                                          ))}
                                        </table>
                                      ))}
                                    </td>
                                  </tr>
                                </td>
                                <td style={{ verticalAlign: 'top', width: '100%' }}>
                                  <table className="main-table-style" style={{ width: '80%' }}>
                                    <tbody>
                                      <tr>
                                        <td style={{ margin: '4px 0', fontSize: '14px', fontWeight: "bold" }}>Обьем спроса (сом) :</td>
                                        <td style={{ color: '#337E86', fontSize: '14px', textAlign: 'right' }}>{props.currencyBeautifier(stats.stat1.asks.total)}</td>
                                      </tr>
                                      <tr>
                                        <td style={{ margin: '4px 0', fontSize: '14px', fontWeight: "bold" }}>Конкур-е :</td>
                                        <td style={{ color: '#337E86', fontSize: '14px', textAlign: 'right' }}>{props.currencyBeautifier(stats.stat1.asks.comp)}</td>
                                      </tr>
                                      <tr>
                                        <td style={{ margin: '4px 0', fontSize: '14px', fontWeight: "bold" }}>Неконкур-е :</td>
                                        <td style={{ color: '#337E86', fontSize: '14px', textAlign: 'right' }}>{props.currencyBeautifier(stats.stat1.asks.nocomp)}</td>
                                      </tr>
                                      <tr>
                                        <td colSpan={2}>
                                          <hr />
                                        </td>
                                      </tr>

                                      {/* <tr>
                              <td className="main-table-tbody-td-style" style={{ fontWeight: "bold" }}>Спрос, сом</td>
                              <td className="main-table-tbody-td-style" style={{color:'#337E86'}}>{props.currencyBeautifier(stats.stat1.asks.comp)}</td>
                              <td className="main-table-tbody-td-style" style={{color:'#337E86'}}>{props.currencyBeautifier(stats.stat1.asks.nocomp)}</td>
                              <td className="main-table-tbody-td-style" style={{color:'#337E86'}}>{props.currencyBeautifier(stats.stat1.asks.total)}</td>
                            </tr> */}
                                      <tr>
                                        <td style={{ margin: '4px 0', fontSize: '14px', fontWeight: "bold" }}>Обьем удовлетв. спрос (сом) :</td>
                                        <td style={{ color: '#337E86', fontSize: '14px', textAlign: 'right' }}>{props.currencyBeautifier(stats.stat1.satisfieds.total)}</td>
                                      </tr>
                                      <tr>
                                        <td style={{ margin: '4px 0', fontSize: '14px', fontWeight: "bold" }}>Конкур-е :</td>
                                        <td style={{ color: '#337E86', fontSize: '14px', textAlign: 'right' }}>{props.currencyBeautifier(stats.stat1.satisfieds.comp)}</td>
                                      </tr>
                                      <tr>
                                        <td style={{ margin: '4px 0', fontSize: '14px', fontWeight: "bold" }}>Неконкур-е :</td>
                                        <td style={{ color: '#337E86', fontSize: '14px', textAlign: 'right' }}>{props.currencyBeautifier(stats.stat1.satisfieds.nocomp)}</td>
                                      </tr>
                                      {/* <tr> */}
                                      {/* <td className="main-table-tbody-td-style" style={{ fontWeight: "bold" }}>Удовлетв. спрос, сом</td> */}
                                      {/* <td className="main-table-tbody-td-style" style={{color:'#337E86'}}>{props.currencyBeautifier(stats.stat1.satisfieds.comp)}</td> */}
                                      {/* <td className="main-table-tbody-td-style" style={{color:'#337E86'}}>{props.currencyBeautifier(stats.stat1.satisfieds.nocomp)}</td> */}
                                      {/* <td className="main-table-tbody-td-style" style={{color:'#337E86'}}>{props.currencyBeautifier(stats.stat1.satisfieds.total)}</td> */}
                                      {/* </tr> */}
                                    </tbody>
                                  </table>
                                </td>

                              </tr>
                            </table>
                          </TabPanel>
                          <TabPanel value="2">
                            <table className="main-table-style">
                              <tbody>
                                <tr>
                                  <td style={{ width: '10%' }}>
                                    <tr>
                                      <td style={{ margin: '4px 0', fontSize: '14px', fontWeight: "bold" }}>Минимальная цена :</td>
                                      <td style={{ width: '150px', color: '#337E86', fontSize: '14px', textAlign: 'right' }}>{props.currencyBeautifier(stats.stat2.prices.min)}</td>
                                    </tr>
                                    <tr>
                                      <td style={{ margin: '4px 0', fontSize: '14px', fontWeight: "bold" }}>Максимальная цена :</td>
                                      <td style={{ width: '150px', color: '#337E86', fontSize: '14px', textAlign: 'right' }}>{props.currencyBeautifier(stats.stat2.prices.max)}</td>
                                    </tr>
                                    <tr>
                                      <td style={{ margin: '4px 0', fontSize: '14px', fontWeight: "bold" }}>Средневзвешенная цена :</td>
                                      <td style={{ width: '150px', color: '#337E86', fontSize: '14px', textAlign: 'right' }}>{props.currencyBeautifier(stats.stat2.prices.avg)}</td>
                                    </tr>
                                  </td>
                                  <td style={{ width: '10%' }}>
                                    <tr>
                                      <td style={{ margin: '4px 0', fontSize: '14px', fontWeight: "bold" }}>Минимальная доходность к погашению, % :</td>
                                      <td style={{ width: '150px', color: '#337E86', fontSize: '14px', textAlign: 'right' }}>{props.currencyBeautifier(stats.stat2.profits.min)}</td>
                                    </tr>
                                    <tr>
                                      <td style={{ margin: '4px 0', fontSize: '14px', fontWeight: "bold" }}>Максимальная доходность к погашению, % :</td>
                                      <td style={{ width: '150px', color: '#337E86', fontSize: '14px', textAlign: 'right' }}>{props.currencyBeautifier(stats.stat2.profits.max)}</td>
                                    </tr>
                                    <tr>
                                      <td style={{ margin: '4px 0', fontSize: '14px', fontWeight: "bold" }}>Средневзвешенная доходность к погашению, % :</td>
                                      <td style={{ width: '150px', color: '#337E86', fontSize: '14px', textAlign: 'right' }}>{props.currencyBeautifier(stats.stat2.profits.avg)}</td>
                                    </tr>
                                  </td>
                                </tr>

                                <tr>
                                  {/* <td className="main-table-tbody-td-style" style={{ fontWeight: "bold" }}>Цены аукциона, сом</td> */}
                                  {/* <td className="main-table-tbody-td-style">{props.currencyBeautifier(stats.stat2.prices.max)}</td> */}
                                  {/* <td className="main-table-tbody-td-style">{props.currencyBeautifier(stats.stat2.prices.min)}</td> */}
                                  {/* <td className="main-table-tbody-td-style">{props.currencyBeautifier(stats.stat2.prices.avg)}</td> */}
                                </tr>
                                <tr>
                                  {/* <td className="main-table-tbody-td-style" style={{ fontWeight: "bold" }}>Доходность к погашению, %</td> */}
                                  {/* <td className="main-table-tbody-td-style">{props.currencyBeautifier(stats.stat2.profits.max)}</td> */}
                                  {/* <td className="main-table-tbody-td-style">{props.currencyBeautifier(stats.stat2.profits.min)}</td> */}
                                  {/* <td className="main-table-tbody-td-style">{props.currencyBeautifier(stats.stat2.profits.avg)}</td> */}
                                </tr>
                              </tbody>
                            </table>
                          </TabPanel>
                          <TabPanel value="3">
                            {userProfile.role.name === "Administrator" && (auction.auctionStatusId === 5 || auction.auctionStatusId === 6 || auction.auctionStatusId === 7) &&
                              <table className="main-table-style">
                                {/* <tr>
                      <td colSpan="2" class="main-table-header-th-style-new" style={{ fontWeight: "bold", fontSize: "16px" }}>
                      Сводные ведомости заявок
                      </td>
                    </tr> */}
                                <tr>
                                  <td style={{ fontSize: '14px', fontWeight: 'bold', paddingRight: '20px' }}>Сводная ведомость заявок-1</td>
                                  <BootstrapTooltip title="Сводная ведомость заявок-1">

                                    <td className="main-table-tbody-td-style">

                                      <button
                                        className="approveButton"
                                        onMouseDown={() => downloadReport("docx", "1", "Сводная ведомость заявок-1")}
                                        style={{ width: "60px", background: "#1A58CB", height: "25px" }}
                                      >
                                        WORD
                                      </button>

                                    </td></BootstrapTooltip>
                                  <td className="main-table-tbody-td-style">
                                    <BootstrapTooltip title="Сводная ведомость заявок-1">
                                      <button
                                        className="approveButton"
                                        onMouseDown={() => downloadReport("pdf", "1", "Сводная ведомость заявок-1")}
                                        style={{ width: "50px", marginLeft: 10, background: "#EE8C31", height: "25px" }}
                                      >
                                        PDF
                                      </button>
                                    </BootstrapTooltip>
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ fontSize: '14px', fontWeight: 'bold', paddingRight: '20px' }}>Сводная ведомость заявок-2</td>
                                  <td className="main-table-tbody-td-style">
                                    <BootstrapTooltip title="Сводная ведомость заявок-2">
                                      <button
                                        className="approveButton"
                                        onMouseDown={() => downloadReport("docx", "2", "Сводная ведомость заявок-2")}
                                        style={{ width: "60px", background: "#1A58CB", height: "25px" }}
                                      >
                                        WORD
                                      </button>
                                    </BootstrapTooltip>
                                  </td>
                                  <td className="main-table-tbody-td-style">

                                    <BootstrapTooltip title="Сводная ведомость заявок-2">
                                      <button
                                        className="approveButton"
                                        onMouseDown={() => downloadReport("pdf", "2", "Сводная ведомость заявок-2")}
                                        style={{ width: "50px", marginLeft: 10, background: "#EE8C31", height: "25px" }}
                                      >
                                        PDF
                                      </button>
                                    </BootstrapTooltip>
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ fontSize: '14px', fontWeight: 'bold', paddingRight: '20px' }}>Сводная ведомость заявок - квалификация заявок</td>
                                  <td className="main-table-tbody-td-style">
                                    <BootstrapTooltip title="Сводная ведомость заявок - квалификация заявок">
                                      <button
                                        className="approveButton"
                                        onMouseDown={() => downloadReport("docx", "3", "Сводная ведомость заявок - квалификация заявок")}
                                        style={{ width: "60px", background: "#1A58CB", height: "25px" }}
                                      >
                                        WORD
                                      </button>
                                    </BootstrapTooltip>
                                  </td>
                                  <td className="main-table-tbody-td-style">
                                    <BootstrapTooltip title="Сводная ведомость заявок - квалификация заявок">
                                      <button
                                        className="approveButton"
                                        onMouseDown={() => downloadReport("pdf", "3", "Сводная ведомость заявок - квалификация заявок")}
                                        style={{ width: "50px", marginLeft: 10, background: "#EE8C31", height: "25px" }}
                                      >
                                        PDF
                                      </button>
                                    </BootstrapTooltip>
                                  </td>
                                </tr>
                              </table>

                              // <>
                              //   <button
                              //     className="approveButton"
                              //     onClick={() => downloadReport("docx")}
                              //     style={{ width: "270px", marginLeft: 10, background: "#1A58CB" }}
                              //   >
                              //     Загрузить сводную ведомость WORD
                              //   </button>
                              //   <button
                              //     className="approveButton"
                              //     onClick={() => downloadReport("pdf")}
                              //     style={{ width: "270px", marginLeft: 10, background: "#EE8C31" }}
                              //   >
                              //     Загрузить сводную ведомость PDF
                              //   </button>
                              // </>
                            }
                          </TabPanel>
                        </TabContext>
                      </Box>


                    </tr>
                  </table>
                }
                {userProfile.role.name === "Administrator" && auction.auctionStatusId === 1 &&
                  <>
                    <button
                      className="approveButton"
                      onClick={() => closeAuctionForced()}
                      style={{ marginLeft: 10, width: 300 }}
                    >
                      Приостановление приёма заявок
                    </button>
                    <button
                      className="approveButton"
                      onClick={() => deleteAuction()}
                      style={{ marginLeft: 10, width: 300 }}
                    >
                      Очистка аукциона
                    </button>
                  </>
                }
                {userProfile.role.name === "Administrator" && auction.auctionStatusId === 2 &&
                  <>
                    <button
                      className="approveButton"
                      onClick={() => preCalculate()}
                      style={{ marginLeft: 10 }}
                    >
                      Предварительные расчеты
                    </button>
                    <button
                      className="approveButton"
                      onClick={() => deleteAuction()}
                      style={{ marginLeft: 10, width: 300 }}
                    >
                      Очистка аукциона
                    </button>
                  </>
                }
                {userProfile.role.name === "Administrator" && auction.auctionStatusId === 4 &&
                  <button
                    className="approveButton"
                    onClick={() => closePreCalculate()}
                    style={{ width: "270px", marginLeft: 10 }}
                  >
                    Закрытие предварительных расчетов
                  </button>
                }
                <br />
                <div style={{ fontWeight: "bold" }}>Статус: {getEnumLabel("auctionStatuses", auction.auctionStatusId)}</div>

                <Menu
                  id="menu"
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    'aria-labelledby': tableKey
                  }}
                >
                  <MenuItem onClick={() => openAuctionBidForm()} style={{ color: "black" }}>
                    Подать заявку
                    <ListItemIcon style={{ color: "black" }}>
                      <FileOpenIcon fontSize="small" className={classes.menuIconStyle} />
                    </ListItemIcon>
                  </MenuItem>
                </Menu>
              </div>
            }
          </div>
        </Draggable>
      )
    }
    catch (er) {
      console.log("ERROR", er)
      return <div>Loading...</div>
    }
  }
  else return <div>Loading...</div>
}