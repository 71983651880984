import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { Grid } from '@material-ui/core';
// import LinearProgress from '@material-ui/core/LinearProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Modal from '@mui/material/Modal';
import CssBaseline from '@mui/material/CssBaseline';
import InputAdornment from '@mui/material/InputAdornment';

// Icons
import SearchIcon from '@mui/icons-material/Search';
import BlockIcon from '@mui/icons-material/Block';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import LockResetIcon from '@mui/icons-material/LockReset';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import RegUserIcon from '@material-ui/icons/PersonAdd';
import { IoIosSettings } from 'react-icons/io';
import { IoMdClose } from 'react-icons/io';
import EditIcon from '@mui/icons-material/Edit';
// Form components
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { MdExpandMore } from 'react-icons/md';
import { MdExpandLess } from 'react-icons/md';
// import { BsArrowDown } from 'react-icons/bs';

//Style
import "../styles/generalStyles.css"

// Libraries
import swal from 'sweetalert'; // https://sweetalert.js.org/guides/
import Draggable from 'react-draggable';
import { v4 as uuidv4 } from 'uuid';
// var generator = require('generate-password');
var moment = require('moment');

function getModalStyleResetPwd() {
  const top = 35;
  const left = 45;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  resizeForm: {
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    border: "1px solid #2B4D80",
    display: "flex",
    backgroundColor: "white",
    "flex-flow": "column",
    // width: "50%",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "30px",
    background: "white",
    position: 'absolute',
  },
  modalResetPwd: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #AFAFAF',
    borderRadius: "7px",
    boxShadow: theme.shadows[1],
    padding: 4
  },
  menuIconStyle: {
    position: "absolute",
    top: 7,
    right: 10
  },
  tdBody: {
    cursor: "pointer",
    fontSize: 13,
    fontWeight: "light",
    height: "30px",
    minWidth: "1px",
    maxWidth: "150px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "elipsis '[..]'",
    overflow: "hidden",
    fontFamily: "Roboto",
    borderBottom: "1px solid #D9D9D9",
    padding: "3px 3px 3px 8px",
  }
}))

export default (props) => {
  const classes = useStyles()
  const [kseRESTApi] = useState(props.kseRESTApi)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [token] = useState(props.token)
  const [userProfile] = useState(props.userProfile)
  const [form, setForm] = useState(null)
  const [tableKey, setTableKey] = useState(getUUID())
  const [docList, setDocList] = useState([])
  const [initialDocList, setInitialDocList] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [enumData, setEnumData] = useState({})

  const [updateState, setUpdateState] = useState(false)
  const [sortedColumn, setSortedColumn] = useState("null")
  const [sortedColumnOrder, setSortedColumnOrder] = useState(1)
  const [selectedUser, setSelectedUser] = useState({ id: null })
  const [anchorEl, setAnchorEl] = useState(null)
  const openMenu = Boolean(anchorEl)
  const [openModalResetPwd, setOpenModalResetPwd] = useState(false)
  const [modalStyleResetPwd] = useState(getModalStyleResetPwd)
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [expanded, setExpanded] = useState(true)

  const [resizeDivUsersId] = useState(getUUID())
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [size, setSize] = useState({ h: 320, w: 700 })
  const [prevHeight, setPrevHeight] = useState(40)
  // Set data from props to state of component
  useEffect(async () => {
    // console.log("USERS MAIN PROPS", props)
    let gForm = await props.fetchForm("b644d334-a43a-483f-b8df-44eac690dd8f")
    let filteredForm = props.filterForm(gForm, props.settings)
    setForm(filteredForm)
    let enums = await props.getEnumDataByForm(gForm)
    setEnumData(enums)
    console.log("USERS MAIN EN", enums)
    updateDocList()
    if (props.settings.position) {
      // setPosition(props.settings.position)
      positionHandler(null, props.settings.position, false)
    }
    if (props.settings.size !== undefined) {
      setSize(props.settings.size)
      if (props.settings.size.h < 60) {
        setExpanded(false)
        setPrevHeight(600)
      }
    }
    props.setComponentIndex(props.getComponentIndex() + 1)
  }, [])
  useEffect(async () => {
    if (props.updateMainUsers !== null && form !== null) {
      updateDocList()
      let f = form
      if (f === null) {
        f = await props.fetchForm("b644d334-a43a-483f-b8df-44eac690dd8f")
      }
      let filteredForm = props.filterForm(f, props.getUsersMainSettingById(props.settings.id))
      setForm(filteredForm)
      setTableKey(getUUID())
      console.log("UPD USERS")
    }
  }, [props.updateMainUsers])
  useEffect(async () => {
    if (props.updateMainUsersZIndex !== null) {
      props.setComponentIndex(props.getComponentIndex() + 1)
      setComponentIndex(props.getComponentIndex() + 1)
    }
  }, [props.updateMainUsersZIndex])
  async function updateDocList() {
    let docL = await props.fetchDocList("/api/users/GetUsers")
    let newDocL = sortUsers(docL)
    setDocList(newDocL)
    if(searchValue !== ""){
      filterUsers(searchValue)
    }
    setInitialDocList(newDocL)
    // console.log("Doc LIST USERS MAIN", newDocL)
    setTableKey(getUUID())
  }
  function sortUsers(list){
    let actUsrs = props.getActiveUsers()
    console.log("ACT USRS", actUsrs)
    let sortedUsers = []
    let passiveUsers = []
    let disabledUsers = []
    for(let i=0; i<list.length; i++){
      if(list[i].disabled === true){
        disabledUsers.push(list[i])
      }
      else{
        let isActive = false
        for (let a = 0; a < actUsrs.length; a++) {
          if (list[i].id === actUsrs[a]) {
            isActive = true
            break
          }
        }
        if(isActive === true){
          sortedUsers.push(list[i])
        }
        else{
          passiveUsers.push(list[i])
        }
      }
    }
    // console.log("SORTED", sortedUsers)
    for(let p=0; p<passiveUsers.length; p++){
      sortedUsers.push(passiveUsers[p])
    }
    for(let d=0; d<disabledUsers.length; d++){
      sortedUsers.push(disabledUsers[d])
    }
    return sortedUsers
  }
  function getContentTypeByName(name) {
    for (let s = 0; s < form.sections.length; s++) {
      for (let c = 0; c < form.sections[s].contents.length; c++) {
        if (name === form.sections[s].contents[c].name) {
          return form.sections[s].contents[c].type
        }
      }
    }
  }
  // random UUID generator
  function getUUID() {
    return uuidv4()
  }
  // Create grid form components
  function getGridFormItems(docListItem, contentItem) {
    let value = docListItem[contentItem.name]
    let name = contentItem.name
    let type = contentItem.type
    if (type === "Enum") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="left" className={classes.tdBody} style={{ color: getColor(docListItem) }}>-</td>)
      }
      else {
        return (
          <td
            align="left"
            className={classes.tdBody}
            style={{ color: getColor(docListItem) }}
          >
            {getEnumLabel(name, value)}
          </td>
        )
      }
    }
    else if (type === "Bool") {
      return (
        <td
          align="center"
          className={classes.tdBody}
        >
          <Checkbox
            size="small"
            style={{ maxWidth: 20, height: 15, color: "#337E86" }}
            checked={(value === false || value === null || value === undefined) ? false : true}
          />
        </td>
      )
    }
    else if (type === "DateTime") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="right" className={classes.tdBody} style={{ color: getColor(docListItem) }}>-</td>)
      }
      else {
        let d = moment(value).format("DD.MM.YYYY HH:MM")
        // return d === "Invalid date" ? value : d
        return (
          <td
            align="right"
            className={classes.tdBody}
            style={{ color: getColor(docListItem) }}
          >
            {d === "Invalid date" ? value : d}
          </td>
        )
      }
    }
    else if (type === "Currency") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="right" className={classes.tdBody} style={{ color: getColor(docListItem) }}>-</td>)
      }
      else {
        let newCur = props.currencyBeautifier(value)
        // console.log("CUR", newCur)
        // return newCur
        return (
          <td
            align="right"
            className={classes.tdBody}
            style={{ color: getColor(docListItem) }}
          >
            {newCur}
          </td>
        )
      }
    }
    else if (type === "Int") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="right" style={{ color: getColor(docListItem) }}>-</td>)
      }
      else {
        let newInt = props.intBeautifier(value)
        return (
          <td
            align="right"
            className={classes.tdBody}
            style={{ color: getColor(docListItem) }}
          >
            {newInt}
          </td>
        )
      }
    }
    else {
      if (value === null || value === "" || value === undefined) {
        return (<td align="left" className={classes.tdBody} style={{ color: getColor(docListItem) }}>-</td>)
      }
      else {
        return (<td align="left" className={classes.tdBody} style={{ color: getColor(docListItem) }}>{value}</td>)
      }
    }
  }
  function getEnumLabel(name, id) {
    let label = ""
    for (let d = 0; d < enumData[name].length; d++) {
      if (enumData[name][d].id === id) {
        // console.log("ORG", enumData[name][d].label)
        label = enumData[name][d].label
      }
    }
    return label
  }
  function dynamicSort(property, sortOrder, type) {
    if (type === "DateTime" || type === "Bool") {
      sortOrder = sortOrder * -1
    }
    if (type === "DateTime") {
      return function (a, b) {
        if (a[property] !== null && b[property] !== null) {
          let dateA = new Date(a[property].substring(0, 19))
          let timeInSecA = dateA.getTime() / 1000
          // console.log("timeInSecA", timeInSecA)
          let dateB = new Date(b[property].substring(0, 19))
          let timeInSecB = dateB.getTime() / 1000
          // console.log("timeInSecB", timeInSecB)
          var result = (timeInSecA < timeInSecB) ? -1 : (timeInSecA > timeInSecB) ? 1 : 0
          return result * sortOrder
        }
        else {
          if (a[property] === null) {
            return -1 * sortOrder
          }
          return 1 * sortOrder
        }
      }
    }
    else if (type === "Int" || type === "Text" || type === "Float" || type === "Bool") {
      return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
        return result * sortOrder
      }
    }
    else if (type === "Enum") {
      return function (a, b) {
        if (a[property] === null) {
          return 1 * sortOrder
        }
        else {
          let labelA = getEnumLabel(property, a[property])
          // console.log("A", property, a[property], labelA)
          let labelB = getEnumLabel(property, b[property])
          // console.log("labelB", labelB, property, b)
          var result = (labelA < labelB) ? -1 : (labelA > labelB) ? 1 : 0
          return result * sortOrder
        }

      }
    }
  }
  function getColor(docListItem) {
    let actUsrs = props.getActiveUsers()
    // console.log("actUsrs", actUsrs)
    let isActive = false
    for (let i = 0; i < actUsrs.length; i++) {
      if (docListItem.id === actUsrs[i]) {
        isActive = true
        break
      }
    }
    if (isActive === true) {
      // console.log("actUsrs", docListItem.id)
      return "green"
    }
    else {
      if (docListItem.disabled === true) {
        return "red"
      }
      else {
        return "#444"
      }
    }
    // return "green"
  }
  function getBackground(user, index) {
    if (selectedUser.id === user.id) {
      return "#CECECE"
    }
    else {
      if (index % 2 === 0) {
        return "#FBFBFB"
      }
      else {
        return "#f7f7f7"
      }
    }
  }

  function handlePasswordChange(event) {
    // console.log("PWD", event.target.value)
    setPassword(event.target.value)
  }
  function handleConfirmPasswordChange(event) {
    // console.log("PWD", event.target.value)
    setConfirmPassword(event.target.value)
  }
  async function handleContextMenuClick(event, docListItem) {
    // console.log(event.currentTarget)
    event.preventDefault()
    setAnchorEl(event.currentTarget)
    setSelectedUser(docListItem)
  }
  function blockUser() {
    setAnchorEl(null)
    swal({
      text: "Вы точно хотите заблокировать пользователя?",
      icon: "warning",
      buttons: { ok: "Да", cancel: "Отмена" }
    })
      .then(async (click) => {
        if (click === "ok") {
          await fetch(kseRESTApi + "/api/users/Block?userId=" + selectedUser.id,
            {
              "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
            }
          )
            .then(response => response.json())
            .then(function (res) {
              console.log("Block", res)
              if (res.isSuccess === true) {
                props.callSuccessToast("Пользователь заблокирован!")
                updateDocList()
              }
              else {
                props.callErrorToast(res.errors[0])
              }
            })
          // userBlockREST(selectedDoc.id, "Пользователь заблокирован")
        }
      })
  }
  async function unblockUser() {
    setAnchorEl(null)
    await fetch(kseRESTApi + "/api/users/UnBlock?userId=" + selectedUser.id,
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
      }
    )
      .then(response => response.json())
      .then(function (res) {
        console.log("Block", res)
        if (res.isSuccess === true) {
          props.callSuccessToast("Пользователь разблокирован!")
          updateDocList()
        }
        else {
          props.callErrorToast(res.errors[0])
        }
      })
  }
  function openModalResetPassword() {
    setAnchorEl(null)
    setOpenModalResetPwd(true)
  }
  async function resetPassword() {
    if (password !== "") {
      if (confirmPassword !== "") {
        if (password === confirmPassword) {
          let body = {
            userName: selectedUser.userName,
            password: password,
            confirmPassword: confirmPassword
          }
          await fetch(kseRESTApi + "/api/users/ResetPassword",
            {
              "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
              "method": "POST",
              "body": JSON.stringify(body)
            }
          )
            .then(response => response.json())
            .then(async function (res) {
              console.log("RESET", res)
              if (res.isSuccess === true) {
                setOpenModalResetPwd(false)
                setPassword("")
                setConfirmPassword("")
                if (res.isSuccess === true) {
                  // props.callSuccessToast("Пароль обновлен!")
                  await fetch(kseRESTApi + "/api/users/SetRequiredAction?value=" + "UPDATEPASSWORD&userName=" + selectedUser.userName,
                    {
                      "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
                    }
                  )
                    .then(response => response.json())
                    .then(async function (res) {
                      // console.log("RES", res)
                      if (res.isSuccess === true) {
                        props.callSuccessToast("Пароль обновлен!")
                      }
                      else {
                        props.callErrorToast(res.errors[0])
                      }
                    })
                    .catch(function (er) {
                      console.log("ERR", er)
                    })
                }
                else {
                  props.callErrorToast(res.errors[0])
                }
              }
            })
        } else { props.callErrorToast("Пароли не совпадают!") }
      } else { props.callErrorToast("Заполните поле Подтверждение пароля!") }
    } else { props.callErrorToast("Заполните поле Пароль!") }
  }
  async function createUser() {
    setAnchorEl(null)
    let taskVars = {
      key: getUUID(),
      component: "users",
      position: { x: 0, y: 0 },
      formDefId: "a25af3d7-b504-488a-8bfb-a8d9cf0594f0",
      buttons: "CreateUserBtns",
      gridFormDefId: null,
      gridFormButtons: null,
      docListApi: null,
      selectedDoc: null
    }
    props.openNewTask(taskVars)
  }
  async function editUser() {
    setAnchorEl(null)
    let taskVars = {
      key: getUUID(),
      component: "users",
      position: { x: 0, y: 0 },
      formDefId: "b4c609d3-2b1f-4e11-a8b4-2f16059053c8",
      selectedDoc: selectedUser,
      buttons: "UserEditBtns",
      gridFormDefId: null,
      gridFormButtons: null,
      docListApi: null
    }
    props.openNewTask(taskVars)
  }
  function selectUser(item) {
    if(selectedUser.id === item.id){
      setSelectedUser({ id: null })
    }
    else{
      setSelectedUser(item)
    }
  }

  function sendMessage() {
    setAnchorEl(null)
    props.setShowMails(true)
    props.setSelectedOrganization(selectedUser.organizationId)
  }
  // function getModalStyle() {
  //   const top = 10 + props.index * 3;
  //   const left = 5 + props.index * 3;
  //   return {
  //     height: expanded === true ? "320px" : "40px",
  //     top: `${top}%`,
  //     left: `${left}%`,
  //     transform: `translate(-${top}%, -${left}%)`,
  //     zIndex: componentIndex
  //   };
  // }
  function formClick() {
    // console.log("CLICK")
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 2)
    props.setComponentIndex(currIndex + 2)
  }

  // Describe allowed positions for different screen sizes
  function positionHandler(e, data, updProfile) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 2 // Take 2% of width
    let maxDeviationX = (dimension.w / 100) * 90 // Take 90% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    if (data.y < maxDeviationY) {
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
    if (updProfile !== false) {
      let settings = {
        id: props.settings.id,
        type: props.settings.type,
        columns: props.settings.columns,
        position: { x: x, y: y },
        size: { h: size.h, w: size.w }
      }
      props.updateUserSettingsByType(settings)
    }
  }
  function sizeHandler() {
    var clientHeight = document.getElementById(resizeDivUsersId).clientHeight;
    var clientWidth = document.getElementById(resizeDivUsersId).clientWidth;
    let diffH = Math.abs(clientHeight - size.h)
    let diffW = Math.abs(clientWidth - size.w)
    // console.log("diffH", diffH, "diffW", diffW)
    if (diffH > 2 || diffW > 2) {
      // console.log("SIZE CHANGED", "H", clientHeight + 2, "HS", size.h, "W", clientWidth + 2, "WS", size.w)
      let settings = {
        id: props.settings.id,
        type: props.settings.type,
        columns: props.settings.columns,
        position: { x: position.x, y: position.y },
        size: { h: clientHeight, w: clientWidth }
      }
      props.updateUserSettingsByType(settings)
      setSize({ h: clientHeight, w: clientWidth })
      if (expanded === false) {
        setExpanded(true)
        setPrevHeight(size.h)
      }
    }
  }
  function expand() {
    // console.log("PREV H", prevHeight)
    setSize({ h: prevHeight, w: size.w })
    setExpanded(!expanded)
    setPrevHeight(size.h)
    let settings = {
      id: props.settings.id,
      type: props.settings.type,
      columns: props.settings.columns,
      position: { x: position.x, y: position.y },
      size: { h: prevHeight, w: size.w }
    }
    props.updateUserSettingsByType(settings)
  }
  function handleSearchChange(event) {
    // console.log("EVENT", event.target.value)
    setSearchValue(event.target.value)
    filterUsers(event.target.value)
  }
  function filterUsers(sValue) {
    if (sValue !== "") {
      let filteredDocList = []
      // console.log("EVENT", sValue)
      for (let i = 0; i < initialDocList.length; i++) {
        let searchField = sValue.toLowerCase()

        let userName = initialDocList[i].userName !== null ? initialDocList[i].userName.toLowerCase() : ""
        let firstName = initialDocList[i].firstName !== null ? initialDocList[i].firstName.toLowerCase() : ""
        let lastName = initialDocList[i].lastName !== null ? initialDocList[i].lastName.toLowerCase() : ""
        let middleName = initialDocList[i].middleName !== null ? initialDocList[i].middleName.toLowerCase() : ""
        let orgName = initialDocList[i].organizationId !== null ? getEnumLabel("organizationId", initialDocList[i].organizationId).toLowerCase() : ""

        let includeUserName = userName.includes(searchField)
        let includeFirstName = firstName.includes(searchField)
        let includeLastName = lastName.includes(searchField)
        let includeMiddleName = middleName.includes(searchField)
        let includeOrgName = orgName.includes(searchField)

        if (includeUserName === true || includeFirstName === true || includeLastName === true || includeMiddleName === true || includeOrgName === true) {
          filteredDocList.push(initialDocList[i])
        }
      }
      setDocList(filteredDocList)
    }
    else {
      setDocList(initialDocList)
    }
  }

  if (updateState !== null && form !== null) {
    try {
      return (
        <Draggable
          handle="p"
          onStart={() => formClick()}
          onStop={positionHandler}
          position={position}
          onMouseDown={() => formClick()}
        // onClick={()=> formClick()}
        >
          {/* <div style={getModalStyle()} className={classes.resizeForm}> */}
          <div
            id={resizeDivUsersId}
            onClick={() => sizeHandler()}
            className={classes.resizeForm}
            style={{ height: size.h, width: size.w, top: "15%", left: "2%", transform: "translate(-15%, -2%)", zIndex: componentIndex }}
            key={updateState}
          >
            <div className="main-table-label-div-style-new" onDoubleClick={() => expand()}>
              <p className="main-table-label-p-style-new">
                <table width="100%">
                  <tr>
                    <td width={"10%"} style={{ paddingLeft: 10 }}>Пользователи</td>
                    <td width={"85%"}>
                      <table width="100%">
                        <tr>
                          <td width={"1%"} />
                          <td width={"40%"}>
                            <TextField
                              id="input-with-icon-textfield"
                              placeholder="Поиск"
                              style={{ width: "100%", background: "#F6F5F5" }}
                              value={searchValue}
                              onChange={handleSearchChange}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start" style={{ paddingLeft: "10px" }}>
                                    <SearchIcon fontSize="small" />
                                  </InputAdornment>
                                ),
                              }}
                              variant="standard"
                              size="small"
                            />
                          </td>
                          <td width={"59%"}></td>
                        </tr>
                      </table>
                    </td>
                    {expanded == false
                      ?
                      <td>
                        <MdExpandLess size={22} className="form-expand-icons-style" onClick={() => expand()} />
                      </td>
                      :
                      <td>
                        <MdExpandMore size={22} className="form-expand-icons-style" onClick={() => expand()} />
                      </td>
                    }
                    <td>
                      <IoIosSettings size={22} className="form-settings-icons-style" onClick={() => props.showFormManagement(props.settings)} />
                    </td>
                    <td>
                      <IoMdClose size={22} className="form-close-icons-style" onMouseDown={() => props.closeUsersMainTable(props.settings)} />
                    </td>
                  </tr>
                </table>
                {/* <table>
                  <tr>
                    <td width={"20%"} style={{ paddingLeft: 15 }}>Пользователи</td>
                    <td width={"30%"}>
                      <TextField
                        id="input-with-icon-textfield"
                        placeholder="Поиск"
                        style={{ width: "100%", background: "#F6F5F5" }}
                        value={searchValue}
                        // onChange={handleSearchChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" style={{ paddingLeft: "10px" }}>
                              <SearchIcon fontSize="small" />
                            </InputAdornment>
                          ),
                        }}
                        variant="standard"
                        size="small"
                      />
                    </td>
                    
                    {expanded == false
                      ?
                      <td>
                        <MdExpandLess size={22} className="form-expand-icons-style" onClick={() => expand()} />
                      </td>
                      :
                      <td>
                        <MdExpandMore size={22} className="form-expand-icons-style" onClick={() => expand()} />
                      </td>
                    }
                    <td>
                      <IoIosSettings size={22} className="form-settings-icons-style" onClick={() => props.showFormManagement(props.settings)} />
                    </td>
                    <td>
                      <IoMdClose size={22} className="form-close-icons-style" style={{ marginRight: "5px" }} onClick={() => props.closeUsersMainTable(props.settings)} />
                    </td>
                  </tr>
                </table> */}
              </p>
            </div>
            {expanded === true &&
              <div style={{ height: "100%", width: "100%", overflow: "scroll" }}>
                <table className="main-table-style-new" style={{ width: "100%" }} id={tableKey} key={tableKey}>
                  <thead>
                    <tr>
                      <th
                        class="main-table-header-th-style-new"
                        style={{
                          borderRight: "1px solid #D9D9D9",
                          fontWeight: "bold",
                          color: "#6D6D6D",
                          background: "#F6F4F3",
                          textAlign: "center",
                          fontSize: "13px"
                        }}
                      >
                        №
                      </th>
                      {form.sections.map(section =>
                        section.contents.map(contentItem => {
                          if (contentItem.show === true) {
                            return (
                              <th class="main-table-header-th-style-new">
                                {contentItem.label}
                              </th>
                            )
                          }
                        })
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {docList.length !== 0 &&
                      docList.map((docListItem, index) => (
                        <tr
                          id={docListItem.id}
                          // style={{background: selectedUser.id === docListItem.id ? "#C7EEFF" : "white"}}
                          style={{ background: getBackground(docListItem, index) }}
                          onMouseDown={() => setSelectedUser(docListItem)}
                          onContextMenu={(ev) => handleContextMenuClick(ev, docListItem)}
                        >
                          <td
                            align="center"
                            className={classes.tdBody}
                            style={{ borderRight: "1px solid #D9D9D9", marginRight: 3, fontWeight: "bold", color: "#6D6D6D", background: "#F6F4F3" }}
                          >
                            {index + 1}
                          </td>
                          {form.sections.map(section => (
                            section.contents.map(contentItem => {
                              if (contentItem.show === true) {
                                return getGridFormItems(docListItem, contentItem)
                              }
                            })
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
                <Modal
                  align="center"
                  // keepMounted
                  open={openModalResetPwd}
                  onClose={() => setOpenModalResetPwd(false)}
                >
                  <div style={modalStyleResetPwd} className={classes.modalResetPwd}>
                    <b id="simple-modal-title" style={{ fontSize: "17px", fontFamily: "Courier New", lineHeight: "3px", fontWeight: "bold" }}>Введите пароль</b>
                    <TextField
                      size="small"
                      variant="outlined"
                      autoFocus
                      defaultValue={password}
                      onChange={handlePasswordChange}
                      style={{ width: "68%" }}
                    />
                    <h3 id="simple-modal-title" style={{ fontSize: "17px", fontFamily: "Courier New", lineHeight: "3px", fontWeight: "bold" }}>Подтверждения пароля</h3>
                    <TextField
                      size="small"
                      variant="outlined"
                      defaultValue={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      style={{ width: "68%" }}
                    />
                    <br></br>
                    <div align="center">
                      <Button
                        variant="contained"
                        onClick={() => resetPassword()}
                        style={{
                          margin: 3,
                          height: 30,
                          fontSize: 12,
                          color: "white",
                          backgroundColor: "#2862F4"
                        }}
                      >Ок
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => setOpenModalResetPwd(false)}
                        style={{
                          margin: 3,
                          height: 30,
                          fontSize: 12,
                          color: "white",
                          backgroundColor: "#E73639"
                        }}
                      >Отмена
                      </Button>
                    </div>
                  </div>
                </Modal>
                <Menu
                  id="menu-main-users"
                  anchorEl={document.getElementById(selectedUser.id)}
                  open={openMenu}
                  onClose={() => setAnchorEl(null)}
                  MenuListProps={{
                    'aria-labelledby': tableKey,
                  }}
                >
                  <MenuItem onClick={() => editUser()} style={{ color: "black" }}>
                    Изменить
                    <ListItemIcon style={{ color: "black" }}>
                      <EditIcon fontSize="small" className={classes.menuIconStyle} />
                    </ListItemIcon>
                  </MenuItem>
                  <MenuItem onClick={() => blockUser()} style={{ color: "black" }}>
                    Заблокировать
                    <ListItemIcon style={{ color: "black" }}>
                      <BlockIcon fontSize="small" className={classes.menuIconStyle} />
                    </ListItemIcon>
                  </MenuItem>
                  <MenuItem onClick={() => unblockUser()} style={{ color: "black" }}>
                    Разблокировать
                    <ListItemIcon style={{ color: "black" }}>
                      <TaskAltIcon fontSize="small" className={classes.menuIconStyle} />
                    </ListItemIcon>
                  </MenuItem>
                  <MenuItem onClick={() => openModalResetPassword()} style={{ color: "black" }}>
                    Сбросиь пароль
                    <ListItemIcon style={{ color: "black" }}>
                      <LockResetIcon fontSize="small" className={classes.menuIconStyle} />
                    </ListItemIcon>
                  </MenuItem>
                  <MenuItem onClick={() => createUser()} style={{ color: "black" }}>
                    Создать
                    <ListItemIcon style={{ color: "black" }}>
                      <RegUserIcon fontSize="small" className={classes.menuIconStyle} />
                    </ListItemIcon>
                  </MenuItem>
                  <MenuItem onClick={() => sendMessage()} style={{ color: "black" }}>
                    Отпрваить сообщение
                    <ListItemIcon style={{ color: "black" }}>
                      <ForwardToInboxIcon fontSize="small" className={classes.menuIconStyle} />
                    </ListItemIcon>
                  </MenuItem>
                </Menu>
              </div>
            }
            <CssBaseline />
          </div>
        </Draggable>
      )
    }
    catch (er) {
      console.log("ERROR", er)
      return <div>Loading...</div>
    }
  }
  else return <div>Loading...</div>
}