import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from "@material-ui/core/Table"; // Material ui table for usual form
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import GridSelect from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
// import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Modal from '@mui/material/Modal';


// import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
// import ListItemButton from "@mui/material/ListItemButton";
// import InboxIcon from "@mui/icons-material/MoveToInbox";
// import MailIcon from "@mui/icons-material/Mail";


// Icons
import { IoMdClose } from 'react-icons/io';
// import FirstPageIcon from '@material-ui/icons/FirstPage';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
// import CloseIcon from '@material-ui/icons/Close';
// import { BsArrowDown } from 'react-icons/bs';
// Form components
import TextField from '@material-ui/core/TextField';
import Select from 'react-select'; // https://react-select.com/home
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// Accordion
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Custom components
// import "../../components/GridForm/GridFormCSS.css"
import Buttons from "../configuration/Buttons.json";
import GridFormButtons from '../configuration/GridFormButtons.json';
//Style
import "../styles/generalStyles.css"

// Libraries
import swal from 'sweetalert'; // https://sweetalert.js.org/guides/
import Draggable from 'react-draggable';
var moment = require('moment');


var fetch = require('node-fetch');
var generator = require('generate-password');

function FloatFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      decimalSeparator={"."}
      thousandSeparator={" "}
      isNumericString
    />
  );
}
FloatFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}
function IntegerFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={" "}
      isNumericString
    />
  );
}
IntegerFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

function getModalStyleResetPwd() {
  const top = 35;
  const left = 45;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  modalResetPwd: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #AFAFAF',
    borderRadius: "7px",
    boxShadow: theme.shadows[1],
    padding: 4
  },
  paper: {
    width: "100%",
    height: 150,
    overflow: 'auto',
  },
  button: {
    // width: "5%",
    margin: theme.spacing(0),
  },
  resizeForm: {
    // borderTopLeftRadius: "8px",
    // borderTopRightRadius: "8px",
    borderRadius: "3px",
    border: "1px solid #2B4D80",
    display: "flex",
    // backgroundColor:"#F5F5F5",
    "flex-flow": "column",
    // width: "1300px",
    // height: "600px",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "150px",
    background: "white",
    position: 'absolute',
  },
}))

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}
function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}
export default (props) => {
  // This.state
  const classes = useStyles()
  const [kseRESTApi] = useState(props.kseRESTApi)
  const [token] = useState(props.token)
  const [taskVariables] = useState(props.taskVariables)
  const [userProfile] = useState(props.userProfile)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex() + 1)
  const [Form, setForm] = useState(null)
  const [enumData, setEnumData] = useState([])
  const [enumOptions, setEnumOptions] = useState({})
  const [buttons, setButtons] = useState([])
  const [gridForm, setGridForm] = useState(null)
  const [gridFormButtons, setGridFormButtons] = useState([])
  const [selectedDoc, setSelectedDoc] = useState(props.taskVariables.selectedDoc)
  // const [docId, setDocId] = useState(props.userTask.docId)
  const [formType] = useState("edit")
  const [isSearchForm, setIsSearchForm] = useState(null)
  const [accordionExpanded, setAccordionExpanded] = useState(false)
  const [docList, setDocList] = useState(null)
  const [filteredDocList, setFilteredDocList] = useState(null)
  const [initialDocList, setInitialDocList] = useState(null)
  const [gridFormEnumData, setGridFormEnumData] = useState([])
  const [fieldValue, setFieldValue] = useState({})
  const [selectedOptions, setSelectedOptions] = useState([])

  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [showSnackBar, setShowSnackBar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState("")
  // const [sectionColor] = useState("#B2E0C9")
  const [updateState, setUpdateState] = useState(false)
  const [sortedColumn, setSortedColumn] = useState("null")
  const [sortedColumnOrder, setSortedColumnOrder] = useState(1)

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [modalStyleResetPwd] = useState(getModalStyleResetPwd)
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [orgUser, setOrgUser] = useState(false)
  // TRANSFER LIST
  const [transferList, setTransferList] = useState({ allowedMarkets: [], allowedMarketSectors: [], allowedInstruments: [] })
  const [checked, setChecked] = useState({ allowedMarkets: [], allowedMarketSectors: [], allowedInstruments: [] })
  const [selectedTransferList, setSelectedTransferList] = useState({ allowedMarkets: [], allowedMarketSectors: [], allowedInstruments: [] })
  const [transferListSearchFileds, setTransferListSearchFileds] = useState({})
  const [selectedTransferListSearchFileds, setSelectedTransferListSearchFileds] = useState({})
  const [autofocusFieldTrList, setAutofocusFieldTrList] = useState(null)
  const [autofocusFieldSelTrList, setAutofocusFieldSelTrList] = useState(null)


  // CHECKBOX LIST
  const [checkBoxList, setCheckBoxList] = useState({ allowedMarkets: [], allowedMarketSectors: [], allowedInstruments: [] })
  const [checkedCheckBoxListItems, setCheckedCheckBoxListItems] = useState({ allowedMarkets: [], allowedMarketSectors: [], allowedInstruments: [] })

  const [openSearch, setOpenSearch] = useState(false)
  const [accordionState, setAccordionState] = useState({})
  const [position, setPosition] = useState({ x: 0, y: 0 })
  // const [position, setPosition] = useState(props.taskVariables.position)
  // Set data from props to state of component
  useEffect(async () => {
    console.log("USERS PROPS", props)
    // fetchForm(taskVariables.formDefId)
    // console.log("BUTTONS", Buttons[userProfile.role.name][taskVariables.buttons], userProfile.role.name)
    let form = await props.fetchForm(taskVariables.formDefId)
    setForm(form)
    let newAcState = {}
    for (let s = 0; s < form.sections.length; s++) {
      if (s === 0) {
        newAcState[form.sections[s].name] = true
      }
      else {
        newAcState[form.sections[s].name] = false
      }
      setAccordionState(newAcState)
    }
    // console.log("US FORM", form)
    let enums = await props.getEnumDataByForm(form)
    setEnumData(enums)
    createTransferListOptions(form, enums)
    createCheckBoxListOptions(form, enums)
    console.log("US ENUMS", enums)
    setEnumOptions(await props.createEnumOptions(enums))
    setButtons(Buttons[userProfile.role.name][taskVariables.buttons])
    if (taskVariables.gridFormDefId !== null) {
      let gForm = await props.fetchForm(taskVariables.gridFormDefId)
      setGridForm(gForm)
      let gEnums = await props.getEnumDataByForm(gForm)
      setGridFormEnumData(gEnums)
      let docL = await props.fetchDocList(taskVariables.docListApi)
      console.log("DOCL USERS", docL)
      let sortedDocList = docL.sort(dynamicSort("disabled", -1, "Bool"))
      setFilteredDocList(sortedDocList)
      // console.log("ROUND", Math.round(sortedDocList.length / 10))
      setInitialDocList(sortedDocList)
      fetchBySize(0, size - 1, sortedDocList)
      setGridFormButtons(GridFormButtons[userProfile.role.name][taskVariables.gridFormButtons])
      setIsSearchForm(true)
    }
    else { setIsSearchForm(false) }
    if (selectedDoc !== undefined && selectedDoc !== null) {
      // setSelectedDoc(taskVariables.selectedDoc)
      setFieldValue(selectedDoc)
      // console.log("SDOC", selectedDoc)
    }
    if (props.taskVariables.position !== undefined && props.taskVariables.position !== null) {
      setPosition(props.taskVariables.position)
    }
    props.setComponentIndex(props.getComponentIndex() + 1)
    setUpdateState(getUUID())
  }, [])

  function leftChecked(name) {
    return intersection(checked[name], transferList[name])
  }
  function rightChecked(name) {
    return intersection(checked[name], selectedTransferList[name])
  }
  function handleExpandAccordion(name) {
    setAccordionState({ ...accordionState, [name]: !accordionState[name] })
  }

  function createTransferListOptions(form, enums) {
    let newTransferList = {}
    let newSelectedTransferList = {}
    for (let s = 0; s < form.sections.length; s++) {
      for (let c = 0; c < form.sections[s].contents.length; c++) {
        if (form.sections[s].contents[c].type === "TransferList") {
          let newTrItems = []
          let newSelTrItems = []
          let cName = form.sections[s].contents[c].name
          if (selectedDoc !== undefined && selectedDoc !== null) {
            // console.log("SET TR LIST SDOC", selectedDoc)
            if (selectedDoc[cName] !== null && selectedDoc[cName] !== undefined) {
              newSelTrItems = selectedDoc[cName]
              for (let f = 0; f < enums[cName].length; f++) {
                let include = true
                for (let sd = 0; sd < selectedDoc[cName].length; sd++) {
                  if (enums[cName][f].id === selectedDoc[cName][sd]) {
                    // console.log("EXCLUDE", enums[cName], selectedDoc[cName][sd], enums[cName][f].id === selectedDoc[cName][sd])
                    include = false
                    break
                  }
                }
                if (include === true) {
                  newTrItems.push(enums[cName][f].id)
                }
              }
            }
            else {
              for (let f = 0; f < enums[cName].length; f++) {
                newTrItems.push(enums[cName][f].id)
              }
            }
          }
          else {
            for (let d = 0; d < enums[cName].length; d++) {
              newTrItems.push(enums[cName][d].id)
            }
          }
          newTransferList[cName] = newTrItems
          newSelectedTransferList[cName] = newSelTrItems
        }
      }
    }
    setTransferList(newTransferList)
    setSelectedTransferList(newSelectedTransferList)
  }
  const handleCheckboxChange = (event) => {
    console.log("CHBX", event.target.name, event.target.checked)
    setFieldValue({ ...fieldValue, [event.target.name]: event.target.checked })
  }
  // Integer input handler
  const handleIntChange = (event) => {
    // console.log("EVENT", event.target.name, event.target.value)
    if (event.target.value !== "") {
      let stringNum = event.target.value.toString().replace(/ /g, '')
      let int = parseInt(stringNum)
      setFieldValue({ ...fieldValue, [event.target.name]: int })
    }
    else {
      setFieldValue({ ...fieldValue, [event.target.name]: null })
    }
  }
  // Float input handler
  const handleFloatChange = (event) => {
    // console.log("FLOAT CHANGE", event.target.name, event.target.value)
    if (event.target.value !== "") {
      let val = event.target.value.replace(/ /g, '')
      let newFields = fieldValue
      newFields[event.target.name] = parseFloat(val)
      setFieldValue(newFields)
    }
    else {
      setFieldValue({ ...fieldValue, [event.target.name]: null })
    }
  }
  // Text input handler
  function handleTextChange(event) {
    // console.log("EVENT", event.target.name, event.target.value)
    // fieldValue[event.target.name] = event.target.value
    // setFieldValue(fieldValue)
    // console.log("FIELDVALUE", fieldValue)

    setFieldValue({ ...fieldValue, [event.target.name]: event.target.value })
  }
  function handlePasswordChange(event) {
    console.log("PWD", event.target.value)
    setPassword(event.target.value)
  }
  function handleConfirmPasswordChange(event) {
    console.log("PWD", event.target.value)
    setConfirmPassword(event.target.value)
  }

  function handleSelectChange(option) {
    setSelectedOptions({ ...selectedOptions, [option.name]: option })
    setFieldValue({ ...fieldValue, [option.name]: option.value })
    console.log("OPT", option, fieldValue)
  }
  function handleDateTimeChange(event) {
    let date = moment(event.target.value).format()
    setFieldValue({ ...fieldValue, [event.target.name]: date })
    console.log("DATE CHANGE", date)
  }
  function getContentType(key) {
    for (let s = 0; s < Form.sections.length; s++) {
      for (let c = 0; c < Form.sections[s].contents.length; c++) {
        if (key === Form.sections[s].contents[c].name) {
          return Form.sections[s].contents[c].type
        }
      }
    }
  }
  // filter docList by filled parameters
  function filterDocList(fetchFrom, fetchTo, Data, fields) {
    var newDocList = []
    if (Object.keys(fields).length === 0) {
      newDocList = Data
    }
    else {
      for (let i = 0; i < Data.length; i++) {
        let match = false
        for (let key in fields) {
          if (fields[key] === null || fields[key] === "" || fields[key] === undefined) {
            match = true
          }
          else {
            if (Data[i][key] !== null) {
              let contentType = getContentType(key)
              if (contentType === "Text") {
                try {
                  let searchField = fields[key].toLowerCase()
                  let dataField = Data[i][key].toLowerCase()
                  let includeSearch = dataField.includes(searchField)
                  if (includeSearch === true) {
                    match = true
                  }
                  else {
                    match = false
                    break
                  }
                }
                catch (er) {
                  console.log("ERR", er)
                  match = true
                }
              }
              else if (contentType === "Int" || contentType === "Float") {
                if (fields[key] >= 0 || fields[key] < 0) {
                  let searchField = fields[key].toString()
                  let dataField = Data[i][key].toString()
                  let includeSearch = dataField.includes(searchField)
                  if (includeSearch === true) {
                    match = true
                  }
                  else {
                    match = false
                    break
                  }
                }
                else { match = true }
              }
              else if (contentType === "Enum") {

                if (fields[key] === Data[i][key]) {
                  // console.log("ENUMS", key, fields[key], Data[i][key])
                  match = true
                }
                else {
                  match = false
                  break
                }
              }
              else if (contentType === "DateTime") {
                let searchField = moment(fields[key]).format('YYYY-MM-DD')
                let dataField = moment(Data[i][key]).format('YYYY-MM-DD')
                // console.log("DATES", searchField, dataField, searchField.includes(dataField))
                if (searchField === "NaN-NaN-NaN") {
                  match = true
                }
                else {
                  if (searchField.includes(dataField) === true) {
                    match = true
                  }
                  else {
                    match = false
                    break
                  }
                }
              }
              else if (contentType === "Bool") {
                if (fields[key] === Data[i][key]) {
                  match = true
                }
                else {
                  match = false
                  break
                }
              }
            }
            else {
              match = false
              break
            }
          }
        }
        if (match === true) {
          newDocList.push(Data[i])
        }
      }
    }
    setFilteredDocList(newDocList)
    fetchBySize(fetchFrom, fetchTo, newDocList)
  }
  // get rows amount of filtered docList by size
  function fetchBySize(fetchFrom, fetchTo, Data) {
    // console.log("fetchFrom", "fetchTo")
    let newDocList = []
    for (let i = fetchFrom; i <= fetchTo; i++) {
      if (Data[i] !== undefined) {
        newDocList.push(Data[i])
      }
    }
    // setFilteredDocList(Data)
    setDocList(newDocList)
  }
  // Pagination functions
  // function KeyboardArrowFirstClick() {
  //   if (page !== 1) {
  //     setPage(1)
  //     fetchBySize(0, size - 1, filteredDocList)
  //   }
  //   else {
  //     setSnackBarMessage("Вы на первой странице!")
  //     setShowSnackBar(true)
  //   }
  // }
  function KeyboardArrowLeftClick(page) {
    if (page !== 1) {
      var prevPage = page - 1
      setPage(prevPage)
      let fetchFrom = ((prevPage - 1) * size) //10
      let fetchTo = (size * prevPage) - 1
      fetchBySize(fetchFrom, fetchTo, filteredDocList)
    }
    else {
      setSnackBarMessage("Вы на первой странице!")
      setShowSnackBar(true)
    }
  }
  function KeyboardArrowRightClick(page) {
    if (filteredDocList.length <= size * page) {
      // console.log("NO DATA")
      setSnackBarMessage("Больше нет данных!")
      setShowSnackBar(true)
    }
    else {
      setPage(page + 1)
      let fetchFrom = (size * page)
      let fetchTo = ((page + 1) * size) - 1
      fetchBySize(fetchFrom, fetchTo, filteredDocList)
    }
  }
  function handleChangeRowsPerPage(event) {
    setSize(event.target.value)
    setPage(1)
    fetchBySize(0, event.target.value - 1, filteredDocList)
  }
  function GoToPage(p) {
    setPage(p)
    if (p === 1) {
      fetchBySize(0, size - 1, filteredDocList)
    }
    else {
      let fetchFrom = p * size - size
      let fetchTo = p * size - 1
      fetchBySize(fetchFrom, fetchTo, filteredDocList)
    }
  }
  // function handlePageChange(event) {
  //   setPage(event.target.value)
  // }
  function handleCloseSnackBar() {
    setShowSnackBar(false)
  }
  // function getPageAmount() {
  //   let pagesFloat = (filteredDocList.length) / size
  //   let mathRoundOfPages = Math.round(pagesFloat)
  //   if (pagesFloat > mathRoundOfPages) {
  //     return mathRoundOfPages + 1
  //   }
  //   else {
  //     return mathRoundOfPages
  //   }
  // }
  // random UUID generator
  function getUUID() {
    const uuid = require("uuid")
    return uuid.v1()
  }
  // random numbers generator
  function keyGen(length) {
    var password = generator.generate({
      length: length,
      numbers: true
    })
    return password
  }
  function swAllert(text, icon) {
    return (
      swal({
        text: text,
        icon: icon,
        buttons: { ok: "Ок" },
      })
    )
  }

  // Collect data to save doc
  function getFieldValuesSaveDoc() {
    let docToSave = {}
    for (let s = 0; s < Form.sections.length; s++) {
      for (let c = 0; c < Form.sections[s].contents.length; c++) {
        let fieldName = Form.sections[s].contents[c].name
        if (Form.sections[s].contents[c].type === "Bool" && fieldValue[fieldName] === undefined) {
          docToSave[fieldName] = false
        }
        else if (Form.sections[s].contents[c].type === "TransferList") {
          docToSave[fieldName] = selectedTransferList[fieldName]
        }
        else if (Form.sections[s].contents[c].type === "CheckBoxList") {
          docToSave[fieldName] = checkedCheckBoxListItems[fieldName]
        }
        else {
          docToSave[fieldName] = fieldValue[fieldName]
        }
      }
    }
    return docToSave
  }
  // Collect data to update doc
  function getFieldValuesUpdateDoc() {
    let docToUpdate = {}
    // docToUpdate["created_at"] = selectedDoc.created_at
    // docToUpdate["id"] = parseInt(docId)
    for (let s = 0; s < Form.sections.length; s++) {
      for (let c = 0; c < Form.sections[s].contents.length; c++) {
        let fieldName = Form.sections[s].contents[c].name
        if (Form.sections[s].contents[c].type === "Bool" && (fieldValue[fieldName] === undefined || fieldValue[fieldName] === null)) {
          docToUpdate[fieldName] = false
        }
        else if (Form.sections[s].contents[c].type === "TransferList") {
          docToUpdate[fieldName] = selectedTransferList[fieldName]
        }
        else if (Form.sections[s].contents[c].type === "CheckBoxList") {
          docToUpdate[fieldName] = checkedCheckBoxListItems[fieldName]
        }
        else {
          docToUpdate[fieldName] = fieldValue[fieldName]
        }
      }
    }
    return docToUpdate
  }
  // Check all required fields to be filled
  function checkForRequieredFields() {
    let checkedSuccessfuly = null
    for (let s = 0; s < Form.sections.length; s++) {
      for (let c = 0; c < Form.sections[s].contents.length; c++) {
        let fieldName = Form.sections[s].contents[c].name
        if (Form.sections[s].contents[c].required === true) {
          if (fieldValue[fieldName] === undefined || fieldValue[fieldName] === null ||
            fieldValue[fieldName] === "NaN.NaN.NaN" || fieldValue[fieldName] === "") {
            checkedSuccessfuly = false
            props.callErrorToast("Внимание заполните поле \"" + Form.sections[s].contents[c].label + "\"", "warning")
            return checkedSuccessfuly
          }
          else {
            checkedSuccessfuly = true
          }
        }
        else {
          checkedSuccessfuly = true
        }
      }
    }
    return checkedSuccessfuly
  }
  // Requests
  async function getRequest(api) {
    let success = await fetch(kseRESTApi + api,
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
        "method": "GET"
      }
    )
      .then(response => response.json())
      .then(function (res) {
        console.log("RES", res)
        if (res.isSuccess === true) {
          return true
        }
        else {
          props.callErrorToast(res.errors[0])
          return false
        }
      })
    return success
  }
  async function postRequest(api, body) {
    let success = await fetch(kseRESTApi + api,
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
        "method": "POST",
        "body": body
      }
    )
      .then(response => response.json())
      .then(function (res) {
        // console.log("RESET", res)
        if (res.isSuccess === true) {
          return true
        }
        else {
          props.callErrorToast(res.errors[0])
          return false
        }
      })
    return success
  }
  async function putRequest(api, body) {
    let success = await fetch(kseRESTApi + api,
      {
        "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
        "method": "PUT",
        "body": body
      }
    )
      .then(response => response.json())
      .then(function (res) {
        // console.log("RESET", res)
        if (res.isSuccess === true) {
          return true
        }
        else {
          props.callErrorToast(res.errors[0])
          return false
        }
      })
    return success
  }

  async function buttonClick(buttonName, item) {
    // console.log("ITEM", item)
    if (buttonName === "findDocument") {
      // console.log("findDocument")
      if (openSearch === true) {
        filterDocList(0, size - 1, initialDocList, fieldValue)
      }
      setOpenSearch(!openSearch)
    }
    else if (buttonName === "showEditUserForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "users",
        position: position,
        formDefId: "b4c609d3-2b1f-4e11-a8b4-2f16059053c8",
        selectedDoc: item,
        buttons: "UserEditBtns",
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null
      }
      props.setNewTask(taskVars)
      console.log("button showEditUserForm: ", taskVars)
    }
    else if (buttonName === "showCreateUserForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "users",
        position: position,
        formDefId: "a25af3d7-b504-488a-8bfb-a8d9cf0594f0",
        buttons: "CreateUserBtns",
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null,
        selectedDoc: item
      }
      props.setNewTask(taskVars)
      console.log("button showCreateUserForm: ", taskVars)
    }
    else if (buttonName === "backToUserSearchForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "users",
        position: position,
        formDefId: "a0ea7b6a-c26a-4636-85fe-5b64c8b78cb2",
        buttons: "UserSearchBtns",
        gridFormDefId: "e5e74841-3d37-4835-8d15-9691d7902283",
        gridFormButtons: "UserGridBtns",
        docListApi: "/api/Users/GetUsers"
      }
      props.setNewTask(taskVars)
      console.log("button backToUserSearchForm: ")
    }
    else if (buttonName === "updateUser") {
      let updateBody = getFieldValuesUpdateDoc()
      updateBody.id = selectedDoc.id
      console.log("button updateUser: ", updateBody)
      let checkResult = checkForRequieredFields()
      if (checkResult === true) {
        let success = await putRequest("/api/users/Update", JSON.stringify(updateBody))
        if (success === true) {
          props.callSuccessToast("Пользователь успешно обновлен!")
          buttonClick("backToUserSearchForm")
        }
      }
    }
    else if (buttonName === "saveUser") {
      let saveBody = getFieldValuesSaveDoc()
      let checkResult = checkForRequieredFields()
      if (checkResult === true) {
        let success = await postRequest("/api/users/Registration", JSON.stringify(saveBody))
        if (success === true) {
          setOpen(false)
          props.callSuccessToast("Пользователь успешно создан!")
          buttonClick("backToUserSearchForm")
        }
      }
    }
    else if (buttonName === "resetPassword") {
      //Открывает модальное окно
      handleOpen()
      setOrgUser(false)
    }
    else if (buttonName === "newPassword") {
      if (password !== "") {
        if (confirmPassword !== "") {
          if (password === confirmPassword) {
            let updateBody = {
              userName: selectedDoc.userName,
              password: password,
              confirmPassword: confirmPassword
            }
            let success = await postRequest("/api/users/ResetPassword", JSON.stringify(updateBody))
            if (success === true) {
              let successReqAction = await getRequest("/api/users/SetRequiredAction?value=" + "UPDATEPASSWORD&userName=" + selectedDoc.userName)
              if (successReqAction === true) {
                setOpen(false)
                props.callSuccessToast("Пароль обновлен!")
                if (orgUser === true) {
                  buttonClick("backToOrganizationUsersSearchForm")
                }
                else {
                  buttonClick("backToUserSearchForm")
                }
              }
            }
          } else { props.callErrorToast("Пароли не совпадают!", "warning") }
        } else { props.callErrorToast("Заполните поле Подтверждение пароля!", "warning") }
      } else { props.callErrorToast("Заполните поле Пароль!", "warning") }

    }
    else if (buttonName === "blockUser") {
      swal({
        text: "Вы точно хотите заблокировать пользователя?",
        icon: "warning",
        buttons: { ok: "Да", cancel: "Отмена" }
      })
        .then(async (click) => {
          if (click === "ok") {
            let success = await getRequest("/api/users/Block?userId=" + selectedDoc.id)
            if (success === true) {
              props.callSuccessToast("Пользователь заблокирован!")
              buttonClick("backToUserSearchForm")
            }
            // userBlockREST(selectedDoc.id, "Пользователь заблокирован")
          }
        })
    }
    else if (buttonName === "unBlockUser") {
      swal({
        text: "Вы точно хотите разблокировать пользователя?",
        icon: "warning",
        buttons: { ok: "Да", cancel: "Отмена" }
      })
        .then(async (click) => {
          if (click === "ok") {
            let success = await getRequest("/api/users/Unblock?userId=" + selectedDoc.id)
            if (success === true) {
              props.callSuccessToast("Пользователь разблокирован!")
              buttonClick("backToUserSearchForm")
            }
          }
        })
    }

    else if (buttonName === "showCreateOrganizationUser") {
      let taskVars = {
        key: taskVariables.key,
        component: "users",
        position: position,
        formDefId: "a25af3d7-b504-488a-8bfb-a8d9cf0594f0",
        buttons: "CreateOrganizationUserBtns",
        selectedDoc: { organizationId: taskVariables.organization.id },
        organization: taskVariables.organization,
        gridFormDefId: null,
        gridFormButtons: null,
      }
      props.setNewTask(taskVars)
      console.log("button ", buttonName, taskVars)
    }
    else if (buttonName === "saveOrganizationUser") {
      let saveBody = getFieldValuesSaveDoc()
      let checkResult = checkForRequieredFields()
      if (checkResult === true) {
        let success = await postRequest("/api/users/Registration", JSON.stringify(saveBody))
        if (success === true) {
          setOpen(false)
          props.callSuccessToast("Пользователь успешно создан!")
          buttonClick("backToOrganizationUsersSearchForm")
        }
      }
    }
    else if (buttonName === "showOrganizationUserEditForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "users",
        position: position,
        formDefId: "b4c609d3-2b1f-4e11-a8b4-2f16059053c8",
        selectedDoc: item,
        organization: taskVariables.organization,
        buttons: "OrganizationUserEditBtns",
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null
      }
      props.setNewTask(taskVars)
      console.log("button showEditUserForm: ", taskVars)
    }
    else if (buttonName === "backToOrganizationEditForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "mainForm",
        position: position,
        formDefId: "2ceae15e-d185-4349-a506-65a0d72c0e6d",
        buttons: "OrganizationsEditFormBtns",
        selectedDoc: taskVariables.organization,
        gridFormDefId: null,
        gridFormButtons: null,
        docListApi: null
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "updateOrganizationUser") {
      let updateBody = getFieldValuesUpdateDoc()
      updateBody.id = selectedDoc.id
      let checkResult = checkForRequieredFields()
      if (checkResult === true) {
        let success = await putRequest("/api/users/Update", JSON.stringify(updateBody))
        if (success === true) {
          props.callSuccessToast("Пользователь успешно обновлен!")
          buttonClick("backToOrganizationUsersSearchForm")
        }
      }
    }
    else if (buttonName === "backToOrganizationUsersSearchForm") {
      let taskVars = {
        key: taskVariables.key,
        component: "users",
        position: position,
        formDefId: "a0ea7b6a-c26a-4636-85fe-5b64c8b78cb2",
        buttons: "OrganizationUsersSearchFormBtns",
        selectedDoc: null,
        organization: taskVariables.organization,
        gridFormDefId: "e5e74841-3d37-4835-8d15-9691d7902283",
        gridFormButtons: "OrganizationUsersGridBtns",
        docListApi: "/api/users/GetUsersByOrgId?orgId=" + taskVariables.organization.id
      }
      props.setNewTask(taskVars)
    }
    else if (buttonName === "resetOrganizationUserPassword") {
      //Открывает модальное окно
      handleOpen()
      setOrgUser(true)
    }
    else if (buttonName === "blockOrganizationUser") {
      swal({
        text: "Вы точно хотите заблокировать пользователя?",
        icon: "warning",
        buttons: { ok: "Да", cancel: "Отмена" }
      })
        .then(async (click) => {
          if (click === "ok") {
            let success = await getRequest("/api/users/Block?userId=" + selectedDoc.id)
            if (success === true) {
              props.callSuccessToast("Пользователь заблокирован!")
              buttonClick("backToOrganizationUsersSearchForm")
            }
            // userBlockREST(selectedDoc.id, "Пользователь заблокирован")
          }
        })
    }
    else if (buttonName === "unBlockOrganizationUser") {
      swal({
        text: "Вы точно хотите разблокировать пользователя?",
        icon: "warning",
        buttons: { ok: "Да", cancel: "Отмена" }
      })
        .then(async (click) => {
          if (click === "ok") {
            let success = await getRequest("/api/users/Unblock?userId=" + selectedDoc.id)
            if (success === true) {
              props.callSuccessToast("Пользователь разблокирован!")
              buttonClick("backToOrganizationUsersSearchForm")
            }
          }
        })
    }

    else if (buttonName === "close") {
      props.closeTask(taskVariables.key)
      console.log("button close: ")
      // props.setNewTask(null, {})
    }
    else {
      console.log("UNKNOWN Button ", buttonName)
    }
  }
  // Transfer List functions
  const handleToggle = (value, name) => () => {
    const currentIndex = checked[name].indexOf(value)
    const newChecked = [...checked[name]]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked({ ...checked, [name]: newChecked })
  }
  const handleAllRight = (name) => {
    setSelectedTransferList({ ...selectedTransferList, [name]: selectedTransferList[name].concat(transferList[name]) })
    setTransferList({ ...transferList, [name]: [] })
  }
  function handleCheckedRight(name) {
    // console.log("CHECKED RIGHT", name)
    setSelectedTransferList({ ...selectedTransferList, [name]: selectedTransferList[name].concat(leftChecked(name)) })
    setTransferList({ ...transferList, [name]: not(transferList[name], leftChecked(name)) })
    setChecked({ ...checked, [name]: not(checked[name], leftChecked(name)) })
  }
  function handleCheckedLeft(name) {
    setTransferList({ ...transferList, [name]: transferList[name].concat(rightChecked(name)) })
    setSelectedTransferList({ ...selectedTransferList, [name]: not(selectedTransferList[name], rightChecked(name)) })
    setChecked({ ...checked, [name]: not(checked[name], rightChecked(name)) })
  }
  const handleAllLeft = (name) => {
    setTransferList({ ...transferList, [name]: transferList[name].concat(selectedTransferList[name]) })
    setSelectedTransferList({ ...selectedTransferList, [name]: [] })
  }
  function getTransferList(items, name) {
    // console.log("G TR L", items, name)
    return (
      <Paper className={classes.paper}>
        <List dense component="div" role="list">
          {items.map((value) => {
            const labelId = `transfer-list-item-${value}-label`;
            return (
              <ListItem size="small" key={value} role="listitem" button onClick={handleToggle(value, name)}>
                <Checkbox
                  size="small"
                  checked={checked[name].indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
                {/* <ListItemText id={labelId} primary={value} /> */}
                <ListItemText id={labelId} primary={getTranserListItemLabel(name, value)} />
              </ListItem>
            )
          })}
          <ListItem />
        </List>
      </Paper>
    )
  }
  function getTranserListItemLabel(name, value) {
    for (let l = 0; l < enumData[name].length; l++) {
      if (enumData[name][l].id === value) {
        return enumData[name][l].label
      }
    }
  }
  function handleSearchTransferListChange(event) {
    let name = event.target.name
    let value = event.target.value
    setAutofocusFieldTrList(name)
    setTransferListSearchFileds({ ...transferListSearchFileds, [name]: value })
    let filteredTrListOpts = []
    for (let d = 0; d < enumData[name].length; d++) {
      let label = enumData[name][d].label.toLowerCase()
      let searchField = value.toLowerCase()
      let includeSearch = label.includes(searchField)
      if (includeSearch === true) {
        filteredTrListOpts.push(enumData[name][d].id)
      }
    }
    // console.log("N TR L", newTrListOpts)
    let newTrListOpts = []
    if (selectedTransferList[name].length > 0) {
      for (let i = 0; i < filteredTrListOpts.length; i++) {
        let include = true
        for (let s = 0; s < selectedTransferList[name].length; s++) {
          if (filteredTrListOpts[i] === selectedTransferList[name][s]) {
            include = false
            break
          }
        }
        if (include === true) {
          newTrListOpts.push(filteredTrListOpts[i])
        }
      }
    }
    else {
      newTrListOpts = filteredTrListOpts
    }
    setTransferList({ ...transferList, [name]: newTrListOpts })
  }
  function handleSearchSelectedTransferListChange(event) {
    let name = event.target.name
    let value = event.target.value
    setAutofocusFieldSelTrList(name)
    setSelectedTransferListSearchFileds({ ...selectedTransferListSearchFileds, [name]: value })
    let filteredTrListOpts = []
    for (let d = 0; d < enumData[name].length; d++) {
      let label = enumData[name][d].label.toLowerCase()
      let searchField = value.toLowerCase()
      let includeSearch = label.includes(searchField)
      if (includeSearch === true) {
        filteredTrListOpts.push(enumData[name][d].id)
      }
    }
    let newSelTrListOpts = []
    if (transferList[name].length > 0) {
      for (let i = 0; i < filteredTrListOpts.length; i++) {
        let include = true
        for (let s = 0; s < transferList[name].length; s++) {
          if (filteredTrListOpts[i] === transferList[name][s]) {
            include = false
            break
          }
        }
        if (include === true) {
          newSelTrListOpts.push(filteredTrListOpts[i])
        }
      }
    }
    else {
      newSelTrListOpts = filteredTrListOpts
    }
    setSelectedTransferList({ ...selectedTransferList, [name]: newSelTrListOpts })
  }

  // CheckBoxList 
  function createCheckBoxListOptions(form, enums) {
    console.log("CH BX L OPTS", form, enums)
    let newCheckBoxList = {}
    let newCheckedCheckBoxListItems = {}
    for (let s = 0; s < form.sections.length; s++) {
      for (let c = 0; c < form.sections[s].contents.length; c++) {
        if (form.sections[s].contents[c].type === "CheckBoxList") {
          let cName = form.sections[s].contents[c].name
          let newCheckBoxItems = [0]
          let newChItems = []

          // create checkBoxList from enums
          for (let f = 0; f < enums[cName].length; f++) {
            newCheckBoxItems.push(enums[cName][f].id)

          }
          newCheckBoxList[cName] = newCheckBoxItems

          if (selectedDoc !== undefined && selectedDoc !== null) {
            // console.log("SET TR LIST SDOC", selectedDoc)
            if (selectedDoc[cName] !== null && selectedDoc[cName] !== undefined) {
              newChItems = selectedDoc[cName]
            }
          }
          newCheckedCheckBoxListItems[cName] = newChItems
        }
      }
    }
    setCheckBoxList(newCheckBoxList)
    setCheckedCheckBoxListItems(newCheckedCheckBoxListItems)
  }
  function getCheckBoxList(items, name) {
    // console.log("G CH L", items, name)
    return (
      <Paper className={classes.paper}>
        <List dense component="div" role="list">
          {items.map((value) => {
            const labelId = `checkbox-list-item-${value}-label`;
            return (
              <ListItem size="small" style={{padding: 0, paddingLeft: 6}} key={value} role="listitem" button onClick={checkBoxListItemClick(value, name)}>
                <Checkbox
                  checked={checkedCheckBoxListItems[name].indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                  style={{ color: "#337E86", padding: 2}}
                />
                <ListItemText id={labelId} primary={getCheckBoxListItemLabel(name, value)} />
              </ListItem>
            )
          })}
          <ListItem />
        </List>
      </Paper>
    )
  }
  function getCheckBoxListItemLabel(name, value) {
    if (value === 0) {
      return "Все"
    }
    for (let l = 0; l < enumData[name].length; l++) {
      if (enumData[name][l].id === value) {
        return enumData[name][l].label
      }
    }
  }
  const checkBoxListItemClick = (value, name) => () => {
    let currentIndex = checkedCheckBoxListItems[name].indexOf(value)
    let newChecked = [...checkedCheckBoxListItems[name]]
    console.log("IND", currentIndex, value)
    if (currentIndex === -1) { // true
      if (value === 0) { // Add all
        newChecked = checkBoxList[name]
        // console.log("ADD ALL", value, name, checkBoxList[name])
      }
      else { // Add selected one
        newChecked.push(value)
      }
    }
    else { // false
      if (value === 0) { // Remove all
        newChecked = []
        console.log("REM ALL", value, name)
      }
      else { // Remove selected one
        newChecked.splice(currentIndex, 1)
      }
    }

    // if (currentIndex === -1) {
    //   newChecked.push(value)
    // } else {
    //   newChecked.splice(currentIndex, 1)
    // }
    console.log("CH", newChecked)
    setCheckedCheckBoxListItems({ ...checkedCheckBoxListItems, [name]: newChecked })
  }

  // Key press handlers for some fields
  function onKeyPressText(event) {
    let code = event.charCode
    if (code === 13) {
      for (let i = 0; i < buttons.length; i++) {
        if (buttons[i].name === "findDocument") {
          // console.log("CODE", code)
          handleTextChange(event)
          buttonClick("findDocument", null)
        }
      }
    }
  }
  function onKeyPressDateTime(event) {
    let code = event.charCode
    if (code === 13) {
      for (let i = 0; i < buttons.length; i++) {
        if (buttons[i].name === "findDocument") {
          // console.log("CODE", code)
          handleDateTimeChange(event)
          buttonClick("findDocument", null)
        }
      }
    }
  }
  function onKeyPressInt(event) {
    let code = event.charCode
    if (code === 13) {
      for (let i = 0; i < buttons.length; i++) {
        if (buttons[i].name === "findDocument") {
          // console.log("CODE", code)
          let stringNum = event.target.value.toString().replace(/ /g, '')
          let int = parseInt(stringNum)
          fieldValue[event.target.name] = int
          setFieldValue(fieldValue)
          filterDocList(0, size - 1, initialDocList, fieldValue)
        }
      }
    }
  }
  function onKeyPressFloat(event) {
    let code = event.charCode
    if (code === 13) {
      for (let i = 0; i < buttons.length; i++) {
        if (buttons[i].name === "findDocument") {
          let stringNum = event.target.value.toString().replace(/ /g, '')
          let float = parseFloat(stringNum)
          fieldValue[event.target.name] = float
          setFieldValue(fieldValue)
          filterDocList(0, size - 1, initialDocList, fieldValue)
        }
      }
    }
  }
  // Create grid form components
  function getGridFormItems(docListItem, contentItem) {
    let value = docListItem[contentItem.name]
    let name = contentItem.name
    let type = contentItem.type
    if (type === "Enum") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="center" style={{ color: "black", fontSize: 12 }}>-</td>)
      }
      else {
        for (let l = 0; l < gridFormEnumData[name].length; l++) {
          if (gridFormEnumData[name][l].id === parseInt(value)) {
            return gridFormEnumData[name][l].label
          }
        }
      }
    }
    else if (type === "Bool") {
      // console.log("ITEM", name, value, type)
      return (
        <Checkbox
          style={{ maxWidth: 20, height: 15, color: value === false ? "grey" : "green" }}
          checked={(value === false || value === null || value === undefined) ? false : true}
        />
      )
    }
    else if (type === "DateTime") {
      if (value === null || value === "" || value === undefined) {
        return (<td align="center" style={{ color: "black", fontSize: 12 }}>-</td>)
      }
      else {
        let d = moment(value).format("DD.MM.YYYY HH:MM")
        return d === "Invalid date" ? value : d
      }
    }
    else {
      if (value === null || value === "" || value === undefined) {
        return (<td align="center" style={{ color: "black", fontSize: 12 }}>-</td>)
      }
      else {
        return (<td>{value}</td>)
      }
    }
  }
  function checkToShowSection(section) {
    let showSection = false
    for (let i = 0; i < section.contents.length; i++) {
      if (section.contents[i].show === true) {
        showSection = true
        break
      }
    }
    return showSection
  }
  function checkSectionOnLastChild(i) {
    let lastSection = true
    if (i === gridForm.sections.length - 1) { // The last section
      return true
    }
    else {
      let nextS = i + 1
      // console.log("IT", i, gridForm.sections[nextS])
      for (let s = nextS; s < gridForm.sections.length; s++) {
        for (let c = 0; c < gridForm.sections[s].contents.length; c++) { // Check next section
          if (gridForm.sections[s].contents[c].show === true) {
            lastSection = false
            break
          }
        }
      }
    }
    return lastSection
  }
  function getForm() {
    return (
      <Grid container direction="row" justify="center">
        <Grid item xs={12}>
          <Table class="detail-table-style" width="100%">
            <Grid container direction="row" justify="center" alignItems="center">
              {Form.sections.map(section => {
                return sectionBuilder(section)
              })}
            </Grid>
          </Table>
        </Grid>
      </Grid>
    )
  }
  // Create sections with labels and call bodyBuilder function
  function sectionBuilder(section) {
    if (isSearchForm === false) {
      return (
        <Grid container direction="row" justify="center" spacing={0} style={{ marginTop: 5 }}>
          <Grid item xs={12}>
            <Accordion
              expanded={accordionState[section.name]}
              onChange={() => handleExpandAccordion(section.name)}
              style={{ background: accordionState[section.name] === false ? "#F6F6F6" : "white" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>{section.label}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {bodyBuilder(section)}
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      )
    }
    else {
      return (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                class="sectionBuilderStyle"
              >
                {section.label}
              </TableCell>
            </TableRow>
          </TableHead>
          {bodyBuilder(section)}
        </Table>
      )
    }

    // <Table size="small">
    //   <TableHead>
    //     <TableRow>
    //       <TableCell
    //         class="sectionBuilderStyle"
    //       >
    //         {section.label}
    //       </TableCell>
    //     </TableRow>
    //   </TableHead>
    //   {bodyBuilder(section)}
    // </Table>
  }
  // Create body of each section and call contentBuilder function
  function bodyBuilder(section) {
    return (
      <Table size="small">
        <TableBody>
          {section.contents.map(contentItem => {
            if (contentItem.show === true) {
              return (
                // contentItem.type === "TransferList" ?
                //   <table size="small" align="center">
                //     <tr>
                //       <th colSpan="3">{contentItem.label}</th>
                //     </tr>
                //     <tr>
                //       <td width="43%">поиск <input
                //         autoFocus={autofocusFieldTrList === contentItem.name ? true : false}
                //         name={contentItem.name}
                //         onChange={handleSearchTransferListChange}
                //         value={transferListSearchFileds[contentItem.name]}
                //       />
                //       </td>
                //       <td width="4%"></td>
                //       <td width="43%">поиск <input
                //         autoFocus={autofocusFieldSelTrList === contentItem.name ? true : false}
                //         name={contentItem.name}
                //         onChange={handleSearchSelectedTransferListChange}
                //         value={selectedTransferListSearchFileds[contentItem.name]}
                //       /></td>
                //     </tr>
                //     <tr>
                //       <td width="43%">
                //         <Grid item>{getTransferList(transferList[contentItem.name], contentItem.name)}</Grid>
                //       </td>
                //       <td width="4%">
                //         <Grid item>
                //           <Grid container direction="column">
                //             <Button
                //               variant="outlined"
                //               size="small"
                //               className={classes.button}
                //               onClick={() => handleAllRight(contentItem.name)}
                //               disabled={transferList[contentItem.name].length === 0}
                //             >
                //               ≫
                //             </Button>
                //             <Button
                //               variant="outlined"
                //               size="small"
                //               className={classes.button}
                //               onClick={() => handleCheckedRight(contentItem.name)}
                //               disabled={leftChecked(contentItem.name).length === 0}
                //             >
                //               &gt;
                //             </Button>
                //             <Button
                //               variant="outlined"
                //               size="small"
                //               className={classes.button}
                //               onClick={() => handleCheckedLeft(contentItem.name)}
                //               disabled={rightChecked(contentItem.name).length === 0}
                //             >
                //               &lt;
                //             </Button>
                //             <Button
                //               variant="outlined"
                //               size="small"
                //               className={classes.button}
                //               onClick={() => handleAllLeft(contentItem.name)}
                //               disabled={selectedTransferList[contentItem.name].length === 0}
                //             >
                //               ≪
                //             </Button>
                //           </Grid>
                //         </Grid>
                //       </td>
                //       <td width="43%">
                //         <Grid item>{getTransferList(selectedTransferList[contentItem.name], contentItem.name)}</Grid>
                //       </td>
                //     </tr>
                //   </table>
                contentItem.type === "CheckBoxList" ?
                  <table width="100%" align="center">
                    <tr>
                      <th align="center">{contentItem.label}</th>
                    </tr>
                    <tr>
                      <td width="100%">
                        <Grid item>{getCheckBoxList(checkBoxList[contentItem.name], contentItem.name)}</Grid>
                      </td>
                    </tr>
                  </table>
                  :
                  <Table size="small">
                    <TableRow>
                      <TableCell
                        width="40%"
                        class="bodyBuilderStyle">
                        {contentItem.label}
                      </TableCell>
                      <TableCell
                        width="60%"
                        align="left"
                      // class="bodyBuilderStyle">
                      >
                        {contentBuilder(contentItem)}
                      </TableCell>
                    </TableRow>
                  </Table>
              )
            }
          })}
        </TableBody>
      </Table>
    )
  }
  // Creating components by it's type
  function contentBuilder(contentItem) {
    // console.log("FV", fieldValue)
    if (contentItem.type === "Text") {
      return (
        <TextField
          name={contentItem.name}
          value={(fieldValue[contentItem.name])}
          onChange={handleTextChange}
          onKeyPress={onKeyPressText}
          placeholder={contentItem.placeholder}
          style={{ width: "100%" }}
          disabled={(formType === "view" || contentItem.edit === false) ? true : false}
        />
      )
    }
    else if (contentItem.type === "Enum") {
      let selectedOption = {
        "value": "",
        "label": "Пусто",
        "name": contentItem.name
      }
      if (fieldValue[contentItem.name]) {
        for (let i = 0; i < enumOptions[contentItem.name].length; i++) {
          if (fieldValue[contentItem.name] === enumOptions[contentItem.name][i].value) {
            selectedOption = enumOptions[contentItem.name][i]
            break
          }
        }
      }
      return (
        <Select
          name={contentItem.name}
          value={selectedOption}
          onChange={handleSelectChange}
          options={enumOptions[contentItem.name]}
          isDisabled={(formType === "view" || contentItem.edit === false) ? true : false}
          menuPortalTarget={document.body}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        />
      )
    }
    else if (contentItem.type === "Bool") {
      return (
        <Checkbox
          style={{ maxWidth: 20, height: 15, color: (formType === "view" || contentItem.edit === false) ? "grey" : "green" }}
          name={contentItem.name}
          onChange={handleCheckboxChange}
          disabled={(formType === "view" || contentItem.edit === false) ? true : false}
          checked={(fieldValue[contentItem.name] === false || fieldValue[contentItem.name] === null || fieldValue[contentItem.name] === undefined) ? false : true}
        />
      )
    }
    else if (contentItem.type === "Int") {
      return (
        <TextField
          name={contentItem.name}
          value={(fieldValue[contentItem.name])}
          onChange={handleIntChange}
          onKeyPress={onKeyPressInt}
          disabled={(formType === "view" || contentItem.edit === false) ? true : false}
          style={{ width: "100%" }}
          InputProps={{ inputComponent: IntegerFormat }}
        />
      )
    }
    else if (contentItem.type === "Float") {
      // console.log("FLOAT VAL", fieldValue[contentItem.name])
      return (
        <TextField
          name={contentItem.name}
          // value={(fieldValue[contentItem.name])}
          // onChange={handleFloatChange}
          onKeyPress={onKeyPressFloat}
          defaultValue={fieldValue[contentItem.name]}
          onBlur={handleFloatChange}
          style={{ width: "100%" }}
          disabled={(formType === "view" || contentItem.edit === false) ? true : false}
          InputProps={{ inputComponent: FloatFormat }}
        />
      )
    }
    else if (contentItem.type === "DateTime") {
      return (
        <TextField
          onKeyPress={onKeyPressDateTime}
          type="date"
          name={contentItem.name}
          onBlur={handleDateTimeChange}
          style={{ width: "100%" }}
          defaultValue={(fieldValue[contentItem.name] !== undefined) ? moment(fieldValue[contentItem.name]).format('YYYY-MM-DD') : ""}
          disabled={(formType === "view" || contentItem.edit === false) ? true : false}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )
    }
  }
  function getEnumLabel(name, id) {
    for (let d = 0; d < enumData[name].length; d++) {
      if (enumData[name][d].id === id) {
        return enumData[name][d].label
      }
    }
  }
  function dynamicSort(property, sortOrder, type) {
    if (type === "DateTime" || type === "Bool") {
      sortOrder = sortOrder * -1
    }
    if (type === "DateTime") {
      return function (a, b) {
        if (a[property] !== null && b[property] !== null) {
          let dateA = new Date(a[property].substring(0, 19))
          let timeInSecA = dateA.getTime() / 1000
          // console.log("timeInSecA", timeInSecA)
          let dateB = new Date(b[property].substring(0, 19))
          let timeInSecB = dateB.getTime() / 1000
          // console.log("timeInSecB", timeInSecB)
          var result = (timeInSecA < timeInSecB) ? -1 : (timeInSecA > timeInSecB) ? 1 : 0
          return result * sortOrder
        }
        else {
          if (a[property] === null) {
            return -1 * sortOrder
          }
          return 1 * sortOrder
        }
      }
    }
    else if (type === "Int" || type === "Text" || type === "Float" || type === "Bool") {
      return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
        return result * sortOrder
      }
    }
    else if (type === "Enum") {
      return function (a, b) {
        if (a[property] === null) {
          return 1 * sortOrder
        }
        else {
          let labelA = getEnumLabel(property, a[property])
          // console.log("A", property, a[property], labelA)
          let labelB = getEnumLabel(property, b[property])
          // console.log("labelB", labelB, property, b)
          var result = (labelA < labelB) ? -1 : (labelA > labelB) ? 1 : 0
          return result * sortOrder
        }

      }
    }
  }
  function sortDataByColumn(name, type) {
    let sortOrder = 1
    if (sortedColumn === name) {
      sortOrder = sortedColumnOrder * -1
    }
    setSortedColumnOrder(sortOrder)
    setSortedColumn(name)
    let sortedDocList = filteredDocList.sort(dynamicSort(name, sortOrder, type))
    setPage(1)
    let fetchFrom = 0
    let fetchTo = size - 1
    setFilteredDocList(sortedDocList)
    fetchBySize(fetchFrom, fetchTo, sortedDocList)
  }
  function getModalStyle() {
    const top = 15;
    const left = 10;
    return {
      width: isSearchForm === true ? "1300px" : "900px",
      height: "600px",
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      zIndex: componentIndex
    }
  }
  function formClick() {
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 1)
    props.setComponentIndex(currIndex + 1)
    // console.log("CLICK")
  }
  function getPages(from, to) {
    let pArr = []
    for (let i = from; i <= to; i++) {
      if (i < Math.floor(filteredDocList.length / size) + 1) {
        pArr.push(
          getPage(i)
        )
      }
    }
    return pArr
  }
  function getPage(num) {
    return (
      <td>
        <div
          style={{
            cursor: "pointer",
            fontSize: 14,
            verticalAlign: "middle",
            textAlign: "center",
            height: "22px",
            width: "22px",
            color: page === num ? "white" : "black",
            background: page === num ? "#4FA4AD" : "white"
          }}
          onClick={() => GoToPage(num)}
        >
          <div style={{ marginTop: "2px", marginRight: "1px" }}>{num}</div>
        </div>
      </td>
    )
  }
  function positionHandler(e, data) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 2 // Take 2% of width
    let maxDeviationX = (dimension.w / 100) * 90 // Take 90% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    // console.log("minX", minDeviationX, "maxX", maxDeviationX, "maxY", maxDeviationY)
    if (data.y < maxDeviationY) {
      // console.log("SET MIN Y", data.x)
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      // console.log("SET MIN X", data.x, minDeviationX)
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      // console.log("SET MAX X", data.x)
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
  }
  function getWidth() {
    let w = isSearchForm === true ? "1000px" : "700px"
    if (gridForm !== null) {
      let l = 0
      for (let s = 0; s < gridForm.sections.length; s++) {
        for (let c = 0; c < gridForm.sections[s].contents.length; c++) {
          // console.log("LAB", gridForm.sections[s].contents[c].label, gridForm.sections[s].contents[c].label.length)
          if (gridForm.sections[s].contents[c].show === true) {
            l += gridForm.sections[s].contents[c].label.length * 21
          }
        }
      }
      if (l > 1300) {
        l = 1300
      }
      if (l < 650) {
        l = 650
      }
      // console.log("LLL", l)
      w = l
    }
    // if (docList !== null && gridForm !== null) {
    //   try {
    //     w = document.getElementById(mainFormGridId).clientWidth;
    //   }
    //   catch (er) {
    //     console.log("ER", er)
    //   }
    // }
    return w
  }
  if (updateState !== null && Form !== null) {
    try {
      return (
        <Draggable
          handle="p"
          onStart={() => formClick()}
          onStop={positionHandler}
          position={position}
        >
          {/* <div style={getModalStyle()} className={classes.resizeForm} onClick={() => formClick()}> */}
          <div
            className={classes.resizeForm}
            style={{ height: "600px", width: getWidth(), top: "15%", left: "2%", transform: "translate(-15%, -2%)", zIndex: componentIndex }}
            onClick={() => formClick()}
          >
            <p className="main-table-label-p-style-new">
              <table className="dragble-div-header-table-style-new">
                <tr>
                  <td width="99%" className="dragble-div-header-td-style-new">{Form.label}</td>
                  <td>
                    <IoMdClose size={22} className="form-close-icons-style" style={{ marginRight: "5px" }} onClick={() => buttonClick("close")} />
                  </td>
                </tr>
              </table>
            </p>
            <div className="dragble-div-body-style-new">
              {/* Create grid table with data from doclist */}
              {/* {isSearchForm !== null && isSearchForm === true
                ?
                <Grid container direction="row" justify="center" spacing={0} style={{ paddingTop: 10 }}>
                  <Grid item xs={8}>
                    <Accordion expanded={accordionExpanded} onChange={() => handleExpandAccordion()}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography>Поиск</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {getForm()}
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
                :
                isSearchForm !== null && getForm()
              } */}
              {isSearchForm !== null && isSearchForm === true
                ?
                <Grid container direction="row" justify="center" spacing={0} style={{ paddingTop: 10 }}>
                  <Grid item xs={8}>
                    <Drawer anchor={"bottom"} open={openSearch} onClose={() => setOpenSearch(false)}>
                      <table width="100%">
                        <tr>
                          <td align="center">
                            <table width="60%" style={{ paddingBottom: 70 }}>
                              <tr>
                                <td width="60%" align="center"> {getForm()}</td>
                              </tr>
                              <tr>
                                <td align="center">
                                  {buttons.map((button, index) => (
                                    <button
                                      className="mainFormButton"
                                      style={{
                                        border: `1px solid ${button.backgroundColor}`,
                                        background: button.backgroundColor,
                                        color: button.fontColor
                                      }}
                                      onClick={() => buttonClick(button.name, null)}
                                    >{button.label}
                                    </button>
                                    // <Button
                                    //   name={button.name}
                                    //   variant="outlined"
                                    //   onClick={() => buttonClick(button.name, null)}
                                    //   style={{
                                    //     margin: 3,
                                    //     color: button.fontColor,
                                    //     backgroundColor: button.backgroundColor,
                                    //     height: 30,
                                    //     fontSize: 12
                                    //   }}
                                    // >{button.label}
                                    // </Button>
                                  ))}
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </Drawer>
                  </Grid>
                </Grid>
                :
                isSearchForm !== null && getForm()
              }
              <Grid container direction="row" justify="center" spacing={0}>
                {buttons.map((button, index) => (
                  <button
                    className="mainFormButton"
                    style={{
                      border: `1px solid ${button.backgroundColor}`,
                      background: button.backgroundColor,
                      color: button.fontColor
                    }}
                    onClick={() => buttonClick(button.name, null)}
                  >{button.label}
                  </button>
                  // <Button
                  //   name={button.name}
                  //   variant="outlined"
                  //   onClick={() => buttonClick(button.name, null)}
                  //   style={{
                  //     margin: 3,
                  //     color: button.fontColor,
                  //     backgroundColor: button.backgroundColor,
                  //     height: 30,
                  //     fontSize: 12
                  //   }}
                  // >{button.label}
                  // </Button>
                ))}
              </Grid>
              <Grid>
                {/* Create grid table with data from doclist */}
                {docList !== null && gridForm !== null &&
                  <Grid container direction="row" justify="center" style={{ paddingTop: 10 }}>
                    <Grid item sm={"auto"}>
                      <Paper>
                        <div style={{ height: "400px", width: "100%", overflow: "auto" }}>
                          <table className="grid-table-style">
                            <thead className="grid-table-thead-style" style={{ position: "sticky", top: 0, zIndex: 9999 }}>
                              {/* 1st Row Sections Labels */}
                              <tr>
                                <td colSpan="1" className="grid-table-td-head-first-child"></td>
                                {gridForm.sections.map((section, index) => {
                                  let showSection = checkToShowSection(section)
                                  if (showSection === true) {
                                    let lastSection = checkSectionOnLastChild(index)
                                    return (
                                      <td
                                        class={lastSection === true ? "grid-table-td-head-last-child" : "grid-table-thead-style"}
                                        colSpan={section.contents.length}
                                      >{section.label}</td>
                                    )
                                  }
                                })}
                              </tr>
                              {/* 2nd Row Sections Contents labels */}
                              <tr>
                                <td
                                  class="grid-table-td-head-style-2row"
                                  rowSpan="2"
                                  style={{ minWidth: "85px", width: "85px" }}
                                >
                                  Действие
                                </td>
                                {gridForm.sections.map(section =>
                                  section.contents.map(contentItem => {
                                    if (contentItem.show === true) {
                                      return (
                                        <td
                                          class="grid-table-td-head-style-2row"
                                          rowSpan="2"
                                          onClick={() => sortDataByColumn(contentItem.name, contentItem.type)}
                                        >
                                          {contentItem.label}
                                          {/* <BsArrowDown
                                          size={13}
                                          style={{
                                            cursor: "pointer",
                                            color: sortedColumn === contentItem.name ? "green" : "black",
                                            position: "absolute",
                                            marginTop: 2
                                          }}
                                          onClick={() => sortDataByColumn(contentItem.name, contentItem.type)}
                                        /> */}
                                        </td>
                                      )
                                    }
                                  })
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {Object.keys(docList).length !== 0 &&
                                docList.map(docListItem => (
                                  <tr style={{ "height": 30 }}>
                                    <td
                                      class="grid-table-td-style"
                                      style={{ "maxWidth": 34 }}
                                    >
                                      {gridFormButtons !== "null" &&
                                        gridFormButtons.map(button =>
                                          <button
                                            className="approveSmallButton"
                                            onClick={() => buttonClick(button.name, docListItem)}
                                          >
                                            {button.label}
                                          </button>
                                          // <Button
                                          //   name={button.name}
                                          //   variant="outlined"
                                          //   value={button.name}
                                          //   onClick={() => buttonClick(button.name, docListItem)}
                                          //   style={{
                                          //     // margin: 1,
                                          //     height: 20,
                                          //     fontSize: 10,
                                          //     // backgroundColor: button.backgroundColor,
                                          //     // color: button.fontColor
                                          //     backgroundColor: "white",
                                          //     color: "#418991",
                                          //     borderColor: "#4A99A1"
                                          //   }}
                                          // >{button.label}
                                          // </Button>
                                        )}
                                    </td>
                                    {gridForm.sections.map(section => (
                                      section.contents.map(contentItem => {
                                        if (contentItem.show === true) {
                                          return (
                                            <td align="left" style={{ color: "black", fontSize: 12, "text-align": "center", "border-bottom": "1px solid grey" }}>
                                              {getGridFormItems(docListItem, contentItem)}
                                            </td>
                                          )
                                        }
                                      })
                                    ))}
                                  </tr>
                                )
                                )}
                            </tbody>
                          </table>
                        </div>
                        <table width="100%">
                          <tfoot height="44px" >
                            <tr>
                              <td width="50%" align="left">
                                <tr>
                                  <td style={{ paddingLeft: "15px" }}>
                                    <div style={{ color: "#868686" }}>Количество строк:</div>
                                  </td>
                                  <td style={{ paddingLeft: "3px" }}>
                                    <FormControl>
                                      <GridSelect
                                        onChange={handleChangeRowsPerPage}
                                        style={{ height: 21, color: "#666666", marginLeft: "3px" }}
                                        value={size}
                                      >
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                        <MenuItem value={100}>100</MenuItem>
                                        <MenuItem value={200}>200</MenuItem>
                                        <MenuItem value={500}>500</MenuItem>
                                      </GridSelect>
                                    </FormControl>
                                  </td>
                                </tr>
                              </td>
                              <td width="50%" align="right">
                                <tr>
                                  <td>
                                    <Tooltip title="На предыдущюю">
                                      <ArrowBackIosIcon style={{ fontSize: "small", color: "black", cursor: "pointer" }} onClick={() => KeyboardArrowLeftClick(page)} />
                                    </Tooltip>
                                  </td>
                                  <td>
                                    <div
                                      style={{
                                        cursor: "pointer",
                                        fontSize: 14,
                                        verticalAlign: "middle",
                                        textAlign: "center",
                                        height: "22px",
                                        width: "22px",
                                        color: page === 1 ? "white" : "black",
                                        background: page === 1 ? "#4FA4AD" : "white"
                                      }}
                                      onClick={() => GoToPage(1)}
                                    >
                                      <div style={{ marginTop: "2px", marginRight: "1px" }}>1</div>
                                    </div>
                                  </td>
                                  {page > 4 ?
                                    <React.Fragment>
                                      <td>
                                        <div
                                          style={{
                                            fontSize: 14,
                                            verticalAlign: "middle",
                                            textAlign: "center",
                                            height: "22px",
                                            width: "22px",
                                            color: "black",
                                            background: "white"
                                          }}
                                        >
                                          ...
                                        </div>
                                      </td>
                                      {getPages(page - 1, page + 1)}
                                    </React.Fragment>
                                    :
                                    getPages(2, 5)
                                  }

                                  {Math.floor(filteredDocList.length / size + 1) - page > 2 && filteredDocList.length / size > 5 &&
                                    <td>
                                      <div
                                        style={{
                                          fontSize: 14,
                                          verticalAlign: "middle",
                                          textAlign: "center",
                                          height: "22px",
                                          width: "22px",
                                          color: "black",
                                          background: "white"
                                        }}
                                      >
                                        ...
                                      </div>
                                    </td>
                                  }
                                  {filteredDocList.length / size > 1 && getPage(Math.floor(filteredDocList.length / size + 1))}
                                  <td>
                                    <Tooltip title="На предыдущюю">
                                      <ArrowForwardIosIcon style={{ fontSize: "small", color: "black", cursor: "pointer", marginLeft: "2px", marginRight: "40px" }} onClick={() => KeyboardArrowRightClick(page)} />
                                    </Tooltip>
                                  </td>
                                </tr>
                              </td>
                            </tr>
                          </tfoot>
                        </table>

                        {/* <tfoot>
                          <tr>
                            <td style={{ paddingLeft: "20px" }}>
                              <div style={{ minWidth: 90, color: "black" }}>Кол-во записей</div>
                            </td>
                            <td style={{ paddingLeft: "3px" }}>
                              <FormControl
                                variant="outlined"
                                style={{ minWidth: 30 }}
                              >
                                <GridSelect
                                  onChange={handleChangeRowsPerPage}
                                  style={{ height: 25, color: "black" }}
                                  value={size}
                                >
                                  <MenuItem value={10}>10</MenuItem>
                                  <MenuItem value={20}>20</MenuItem>
                                  <MenuItem value={50}>50</MenuItem>
                                  <MenuItem value={100}>100</MenuItem>
                                  <MenuItem value={200}>200</MenuItem>
                                  <MenuItem value={500}>500</MenuItem>
                                </GridSelect>
                              </FormControl>
                            </td>

                            <td>
                              <Tooltip title="На первую страницу">
                                <IconButton onClick={() => KeyboardArrowFirstClick()}>
                                  <FirstPageIcon style={{ fontSize: "large", color: "black" }} />
                                </IconButton>
                              </Tooltip>
                            </td>
                            <td>
                              <Tooltip title="На предыдущюю">
                                <IconButton onClick={() => KeyboardArrowLeftClick(page)}>
                                  <ArrowBackIosIcon style={{ fontSize: "medium", color: "black" }} />
                                </IconButton>
                              </Tooltip>
                            </td>
                            <td style={{ color: "black", fontSize: 16 }}>
                              <input style={{ maxWidth: 25 }} value={page} onChange={handlePageChange}></input>
                            </td>
                            <td style={{ paddingLeft: "3px" }}>
                              <Tooltip title="Перейти на указанную страницу">
                                <Button
                                  onClick={() => GoToPage()}
                                  variant="outlined"
                                  style={{
                                    height: 22,
                                    backgroundColor: "#D1D6D6",
                                    fontSize: 12
                                  }}
                                >перейти
                                </Button>
                              </Tooltip>
                            </td>
                            <td>
                              <Tooltip title="На следующюю страницу">
                                <IconButton onClick={() => KeyboardArrowRightClick(page)}>
                                  <ArrowForwardIosIcon style={{ fontSize: "medium", color: "black" }} />
                                </IconButton>
                              </Tooltip>
                            </td>
                            <td class="pagination-rows-amount-style">Стр. {page} из {getPageAmount()} Общее кол-во {initialDocList.length}
                            </td>
                          </tr>
                        </tfoot> */}
                      </Paper>
                    </Grid>
                  </Grid>
                }
                <Snackbar
                  open={showSnackBar}
                  onClose={() => handleCloseSnackBar()}
                  autoHideDuration={1200}
                  message={snackBarMessage}
                />
                <Modal
                  align="center"
                  // keepMounted
                  open={open}
                  onClose={handleClose}
                >
                  <div style={modalStyleResetPwd} className={classes.modalResetPwd}>
                    <b id="simple-modal-title" style={{ fontSize: "17px", fontFamily: "Courier New", lineHeight: "3px", fontWeight: "bold" }}>Введите пароль</b>
                    <TextField
                      size="small"
                      variant="outlined"
                      autoFocus
                      defaultValue={password}
                      onChange={handlePasswordChange}
                      style={{ width: "68%" }}
                    />
                    <h3 id="simple-modal-title" style={{ fontSize: "17px", fontFamily: "Courier New", lineHeight: "3px", fontWeight: "bold" }}>Подтверждения пароля</h3>
                    <TextField
                      size="small"
                      variant="outlined"
                      defaultValue={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      style={{ width: "68%" }}
                    />
                    <br></br>
                    <div align="center">
                      <Button
                        variant="contained"
                        onClick={() => buttonClick("newPassword")}
                        style={{
                          margin: 3,
                          height: 30,
                          fontSize: 12,
                          color: "white",
                          backgroundColor: "#2862F4"
                        }}
                      >Ок
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => handleClose()}
                        style={{
                          margin: 3,
                          height: 30,
                          fontSize: 12,
                          color: "white",
                          backgroundColor: "#E73639"
                        }}
                      >Отмена
                      </Button>
                    </div>
                  </div>
                </Modal>
              </Grid>
            </div>
          </div >
        </Draggable >
      )
    }
    catch (er) {
      console.log("ERROR", er)
      return <LinearProgress />
    }
  }
  else return <div>Loading...</div>
}

