import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import TextField from '@material-ui/core/TextField';

// Icons
import { IoMdClose } from 'react-icons/io';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
// CUSTOM COMPONENTS
import ConfigurationFile from "../configuration/ConfigurationFile.json";
// Library
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import Draggable from 'react-draggable';
import swal from 'sweetalert'; // https://sweetalert.js.org/guides/
var fetch = require('node-fetch');

const useStyles = makeStyles((theme) => ({
  resizeForm: {
    borderRadius: "3px",
    border: "1px solid #2B4D80",
    display: "flex",
    backgroundColor: "white",
    boxShadow: theme.shadows[1],
    "flex-flow": "column",
    width: "530px",
    resize: "both",
    overflow: "hidden",
    minWidth: "150px",
    minHeight: "150px",
    position: 'absolute',
  },
}))
function FloatFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      decimalSeparator={"."}
      thousandSeparator={" "}
      isNumericString
    />
  )
}
FloatFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}
function IntegerFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={" "}
      isNumericString
    />
  );
}
IntegerFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default (props) => {
  const classes = useStyles()
  const [token] = useState(props.token)
  const [kseRESTApi] = useState(props.kseRESTApi) //Local KFB main REST
  const [userProfile, setUserProfile] = useState(props.userProfile)
  const [componentIndex, setComponentIndex] = useState(props.getComponentIndex())
  const [enumData, setEnumData] = useState({})
  const [enumOptions, setEnumOptions] = useState({})
  const [selectedOptions, setSelectedOptions] = useState({})
  const [bidDirection, setBidDirection] = useState(null)
  const [tableKey, setTableKey] = useState(null)

  // FIELDS
  const [fieldValue, setFieldValue] = useState({
    tradingAccount: null,
    organizationId: userProfile.organization.id,
    instrumentId: props.selectedInstrument.id !== null ? props.selectedInstrument.id : null,
    priceForInstrument: null,
    priceForInstrumentStep: 1,
    amountOfInstrument: null,
    amountOfInstrumentStep: 1
  })
  const [position, setPosition] = useState({ x: 0, y: 0 })

  useEffect(async () => {
    console.log("FIXING ORDER PROPS", props)
    let enumDataToCollect = [
      { enumName: "instrumentId", enumDef: "3e819d7e-25d0-4a04-a3ff-092fd348a375" },
      { enumName: "tradingAccount", enumDef: "c324d86f-3a3b-43b2-9514-d983b2982794" },
      { enumName: "organizationId", enumDef: "5b78d9dd-821d-4c6a-a00a-3af85224fbc4" }
    ]
    let enums = await props.getEnumDataByList(enumDataToCollect)
    console.log("FIX ENUMS", enums)
    setEnumData(enums)
    let eOpts = await props.createEnumOptions(enums)
    setEnumOptions(eOpts)
    let sOpts = { ...selectedOptions }
    let n = "organizationId"
    for (let d = 0; d < enums[n].length; d++) {
      if (enums[n][d].id === fieldValue.organizationId) {
        sOpts[n] = { "value": enums[n][d].id, "label": enums[n][d].label, "name": n }
      }
    }
    if (props.selectedInstrument.id !== null) {
      let f = "instrumentId"
      for (let d = 0; d < enums[f].length; d++) {
        if (enums[f][d].id === props.selectedInstrument.id) {
          sOpts[f] = { "value": enums[f][d].id, "label": enums[f][d].label, "name": f }
        }
      }
    }
    setSelectedOptions(sOpts)
    props.setComponentIndex(props.getComponentIndex() + 1)
  }, [])
  function swAllert(text, icon) {
    return (
      swal({
        text: text,
        icon: icon,
        buttons: { ok: "Ок" },
      })
    )
  }
  // random UUID generator
  function getUUID() {
    return uuidv4()
  }
  // INPUT HANDLERS
  // SELECT
  function handleSelectChange(option) {
    setSelectedOptions({ ...selectedOptions, [option.name]: option })
    console.log("OPT", option, fieldValue)
    if (option.name === "instrumentId") {
      let newFields = { ...fieldValue }
      newFields[option.name] = option.value
      for (let d = 0; d < enumData[option.name].length; d++) {
        if (enumData[option.name][d].id === option.value) {
          newFields["priceForInstrument"] = enumData[option.name][d].currentPrice
        }
      }
      // console.log("OPT", newFields)
      setFieldValue(newFields)
    }
    else { setFieldValue({ ...fieldValue, [option.name]: option.value }) }
  }
  // INT
  const handleIntChange = (event) => {
    // console.log("EVENT", event.target.name, event.target.value)
    if (event.target.value !== "") {
      let stringNum = event.target.value.toString().replace(/ /g, '')
      let int = parseInt(stringNum)
      setFieldValue({ ...fieldValue, [event.target.name]: int })
    }
    else {
      setFieldValue({ ...fieldValue, [event.target.name]: null })
    }
  }

  const handleFloatChange = (event) => {
    // console.log("FLOAT CHANGE", event.target.name, event.target.value)
    if (event.target.value !== "") {
      let val = event.target.value.replace(/ /g, '')
      let newFields = fieldValue
      newFields[event.target.name] = parseFloat(val)
      setFieldValue(newFields)
      // console.log("FLOAT NEW VAL", event.target.name, val, fieldValue)
    }
    else {
      setFieldValue({ ...fieldValue, [event.target.name]: null })
    }
  }
  function add(name, step) {
    let newVal = fieldValue[name] + fieldValue[step]
    setFieldValue({ ...fieldValue, [name]: newVal })
    // console.log("NEW VAL", newVal)
    setTableKey(getUUID())
  }
  function reduce(name, step) {
    let newVal = fieldValue[name] - fieldValue[step]
    setFieldValue({ ...fieldValue, [name]: newVal })
    setTableKey(getUUID())
    // console.log("NEW Val", newVal)
  }
  async function sendOrder() {
    console.log("FFF", fieldValue)
    if (fieldValue.tradingAccount !== null) {
      if (fieldValue.instrumentId !== null) {
        if (fieldValue.orderDirection !== null) {
          if (fieldValue.amountOfInstrument > 0) {
            let body = {
              "accountId": fieldValue.tradingAccount,
              "organizationId": fieldValue.organizationId,
              "instrumentId": fieldValue.instrumentId,
              "direction": bidDirection === "buy" ? 0 : 1, // 0 Buy 1 Sell
              "amount": fieldValue["amountOfInstrument"]
            }
            console.log("BODY", body)
            await fetch(
              kseRESTApi + "/api/FixTrading/CreateRequest",
              {
                "headers": { "content-type": "application/json", "Authorization": "Bearer " + token },
                "method": "POST",
                "body": JSON.stringify(body)
              }
            )
              .then(response => response.json())
              .then(function (res) {
                console.log("RES", res)
                if (res.isSuccess === true) {
                  props.callSuccessToast("Заявка создана")
                  props.setShowCreateFixingOrder(false)
                  props.setUpdateBidsBody(getUUID())
                }
                else {
                  props.callErrorToast(res.errors[0])
                }
              })
          } else { swAllert("Введите количество!", "warning") }
        } else { swAllert("Укажите тип заявки (Покупка/Продажа)!", "warning") }
      } else { swAllert("Введите инструмент!", "warning") }
    } else { swAllert("Введите торговый счёт!", "warning") }
  }
  function getModalStyle() {
    const top = 15;
    const left = 25;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      zIndex: componentIndex
    };
  }
  function formClick() {
    let currIndex = props.getComponentIndex()
    setComponentIndex(currIndex + 1)
    props.setComponentIndex(currIndex + 1)
  }
  function positionHandler(e, data) {
    let x = data.x
    let y = data.y
    let dimension = props.getHW()
    let minDeviationX = -(dimension.w / 100) * 30 // Take 30% of width
    let maxDeviationX = (dimension.w / 100) * 60 // Take 60% of width
    let maxDeviationY = -(dimension.h / 100) * 15 // Take 15% of height 
    // console.log("minX", minDeviationX, "maxX", maxDeviationX, "maxY", maxDeviationY)
    if (data.y < maxDeviationY) {
      // console.log("SET MIN Y", data.x)
      y = maxDeviationY
    }
    if (data.x < minDeviationX) {
      // console.log("SET MIN X", data.x, minDeviationX)
      x = minDeviationX
    }
    if (data.x > maxDeviationX) {
      // console.log("SET MAX X", data.x)
      x = maxDeviationX
    }
    setPosition({ x: x, y: y })
  }
  return (
    // <Draggable
    //   handle="p"
    //   onStart={() => formClick()}
    //   onMouseDown={() => formClick()}
    // // onClick={()=> formClick()}
    // >
    //   <div style={getModalStyle()} className={classes.resizeForm}>
    <Draggable
      handle="p"
      onStart={() => formClick()}
      onStop={positionHandler}
      position={position}
    >
      <div
        className={classes.resizeForm}
        style={{ top: "15%", left: "30%", transform: "translate(-15%, -30%)", zIndex: componentIndex }}
        onClick={() => formClick()}
        key={tableKey}
      >
        <p className="main-table-label-p-style-new">
          <table className="dragble-div-header-table-style-new">
            <tr>
              <td width="99%" sclassName="dragble-div-header-td-style-new">Ввод заявки методом ФИКСИНГА</td>
              <td>
                <IoMdClose size={22} className="form-close-icons-style" style={{ marginRight: "5px" }} onClick={() => props.setShowCreateFixingOrder(false)} />
              </td>
            </tr>
          </table>
        </p>
        <div className="dragble-div-body-style-new">
          <table align="center" width="100%">
            <tr>
              <td width="50%">
                <Select
                  name={"tradingAccount"}
                  placeholder={"Торговый счет..."}
                  value={selectedOptions["tradingAccount"]}
                  onChange={handleSelectChange}
                  options={enumOptions["tradingAccount"]}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
              </td>
              <td width="50%">
                <Select
                  name={"organizationId"}
                  placeholder={"Выбрать..."}
                  value={selectedOptions["organizationId"]}
                  onChange={handleSelectChange}
                  options={enumOptions["organizationId"]}
                  isDisabled={true}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
              </td>
            </tr>
          </table>
          <table width="100%">
            <tr>
              <td>
                <Select
                  name={"instrumentId"}
                  placeholder={"Найти инструмент..."}
                  value={selectedOptions["instrumentId"]}
                  onChange={handleSelectChange}
                  options={enumOptions["instrumentId"]}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
              </td>
            </tr>
          </table>
          <table align="center" width="100%">
            <tr>
              <td align="center">
                <Button
                  style={{
                    margin: 3,
                    height: 30,
                    fontSize: 12,
                    width: "47%",
                    color: bidDirection === "buy" ? "white" : "#595959",
                    borderColor: bidDirection === "buy" ? "#153236" : "#42A5AF",
                    borderRadius: "0%",
                    backgroundColor: "#42A5AF",
                    fontWeight: "bold",
                    fontFamily: "Helvetica"
                  }}
                  variant="outlined"
                  onClick={() => setBidDirection("buy")}
                >
                  Покупка
                </Button>
                <Button
                  style={{
                    margin: 3,
                    height: 30,
                    fontSize: 12,
                    width: "47%",
                    color: bidDirection === "sell" ? "white" : "#595959",
                    borderColor: bidDirection === "sell" ? "#153236" : "#42A5AF",
                    borderRadius: "0%",
                    backgroundColor: "#42A5AF",
                    fontWeight: "bold",
                    fontFamily: "Helvetica"
                  }}
                  variant="outlined"
                  onClick={() => setBidDirection("sell")}
                >Продажа
                </Button>
              </td>
            </tr>
          </table>

          <div overflow="auto">
            <table align="center" width="100%">
              <tr>
                <td className="dragble-div-body-td-text-style" style={{ width: 165 }}>Цена за инструмент</td>
                <td>
                  <div style={{ verticalAlign: "middle", height: "25px", cursor: "pointer", display: "flex", flexDirection: "row" }}>
                    <div>
                      <TextField
                        // variant="standart"
                        name="priceForInstrument"
                        placeholder="0.0"
                        defaultValue={fieldValue.priceForInstrument}
                        onBlur={handleFloatChange}
                        style={{ width: "150px" }}
                        InputProps={{ inputComponent: FloatFormat }}
                      />
                    </div>
                    <div style={{ paddingLeft: "5px" }}>
                      <AddIcon fontSize="medium" onClick={() => add("priceForInstrument", "priceForInstrumentStep")} />
                    </div>
                    <div style={{ paddingLeft: "10px" }}>
                      <RemoveIcon fontSize="medium" onClick={() => reduce("priceForInstrument", "priceForInstrumentStep")} />
                    </div>
                  </div>
                </td>
                <td className="dragble-div-body-td-text-style" style={{ paddingLeft: "15px" }}>Сумма: {parseFloat((fieldValue.priceForInstrument * fieldValue.amountOfInstrument).toFixed(2))}</td>
              </tr>
              <tr>
                <td></td>
                <td className="dragble-div-body-td-simple-text-style">шаг: {fieldValue["priceForInstrumentStep"]}</td>
              </tr>
              {/* Количество */}
              <tr>
                <td className="dragble-div-body-td-text-style">Количество</td>
                <td>
                  <TextField
                    name="amountOfInstrument"
                    placeholder="0"
                    value={fieldValue.amountOfInstrument}
                    onChange={handleIntChange}
                    style={{ width: "150px" }}
                    InputProps={{ inputComponent: IntegerFormat }}
                  />
                  <AddIcon fontSize="medium" className="add-icon-style" onClick={() => add("amountOfInstrument", "amountOfInstrumentStep")} />
                  <RemoveIcon fontSize="medium" className="reduce-icon-style" onClick={() => reduce("amountOfInstrument", "amountOfInstrumentStep")} />
                </td>
              </tr>
              <tr>
                <td></td>
                <td className="dragble-div-body-td-simple-text-style">шаг: {fieldValue["amountOfInstrumentStep"]}</td>
              </tr>
            </table>
          </div>
          <table align="center" width="100%">
            <tr>
              <td align="center">
                <button
                  className="cancelButton"
                  style={{ marginRight: 20 }}
                  onClick={() => props.setShowCreateFixingOrder(false)}
                >
                  Отмена
                </button>
                <button
                  className="approveButton"
                  onClick={() => sendOrder()}
                  style={{ marginRight: 20 }}
                >
                  Отправить заявку
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </Draggable>
  )
}